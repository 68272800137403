import React from "react";
import "./SessionExpire.css";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useUserLoginMutation } from "../../Redux/Services/authApi";
import AppButton from "../../Components/AppButton/AppButton";
import { useNavigate } from "react-router-dom";

export default function SessionExpire() {
  //clear token from store
  let navigate = useNavigate();

  const gohome = () => {
    let path = "/";
    navigate(path);
  };

  useEffect(() => {
    // dispatch(userLoginAction({ token: null }));
  }, []);

  const [{ isLoading }] = useUserLoginMutation();

  return (
    <div
      className="sessionexpired"
      style={{ background: "url(../Assets/login/back.png)" }}
    >
      <Container>
        <Row className="align-items-center text-start">
          <Col
            sm="12"
            lg="6"
            md="6"
            className=" flex-column align-items-center text-center  d-flex justify-content-center"
          >
            <div className="sessionexpired-form">
              <h1 className="sessionexpired-heading my-2">
                Your Session Has Expired
              </h1>

              <h3 className="sessionexpired-subtext description my-4 ">
                Your Session has expired due to your inactivity.
                <br />
                Not to worry, simply login again.
              </h3>
              <div className="w-75 mx-auto">
                <AppButton
                  isLoading={isLoading}
                  variant={"primary"}
                  title={"GO HOME"}
                  onClick={gohome}
                />
              </div>
            </div>
          </Col>

          <Col
            sm="12"
            lg="6"
            md="6"
            className="mx-auto d-flex my-2 justify-content-center"
          >
            <img
              className="sessionexpiredImage img-fluid"
              src="../Assets/login/loginImage.png"
              alt=""
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
