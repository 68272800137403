import React from "react";
import { AiFillExclamationCircle, AiOutlineCheckCircle } from "react-icons/ai";
import { FiMessageCircle, FiTrendingUp } from "react-icons/fi";
import { HiOutlineBookOpen } from "react-icons/hi";
//   import "../Dashboard/dashboard.css";
export default function InternalCards({data=[]}) {
  const cardsStyle = [
    {
      id: 1,
      cardName:"Total Orders",
    value:data?.order_count,
    icon: <HiOutlineBookOpen size={30} className={"openImg"} />,
    },
    {
      id: 2,
      cardName:"Total Parcels",
      value:data?.parcel_count,
      icon: <FiTrendingUp size={30} className={"openImg"} />,
    },
    {
      id: 3,
      cardName:"Automation Completed",
      value:data?.completed,
      icon: <AiFillExclamationCircle size={30} className={"openImg"} />,
    },
    {
      id: 4,
      cardName:"Automation Failed",
      value:data?.failed,
      icon: <AiOutlineCheckCircle size={30} className={"openImg"} />,
    },
    {
      id: 5,
      cardName:"Automation Not Initialized",
      value:data?.not_initialized,
      icon: <FiMessageCircle size={30} className={"openImg"} />,
    },
  ];
  return (
        <div className="row ">
            {cardsStyle.map((cardstyleinfo) => (
        <div key={cardstyleinfo?.id} className="col-lg-2.5 col-md-4 col-sm-12 mb-1  p-1 ">
            <div className=" bg-blue-300 border rounded-4 text-break text-nowrap py-2  px-2 p-1  ">
                <div className="d-flex align-items-center justify-content-between">
                <h6 className="fw-bold mt-2">{cardstyleinfo?.cardName}</h6>{cardstyleinfo.icon}
                </div>
                <h6 className="fw-bold mt-2" style={{fontSize:"1rem"}}> {cardstyleinfo?.value!==undefined?cardstyleinfo?.value:0}</h6>
            </div>
        </div>
            ))}
        </div>
  );
}
