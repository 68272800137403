import AppTable from "../../../Components/AppTable/AppTable";
import AppModal from "../../../Components/AppModal/AppModal";
import {
  FaSortAmountDown,
  FaSearch,
  FaSortAmountUp,
} from "react-icons/fa";
import NotificationModal from "../Notification/NotificationModal";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import SearchColumns from "../../../Components/SearchColumns";
import {
  Input,
  notification,
  Switch,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import {
  useNotificationListQuery,
  useUpdateNotificationStatusMutation,
} from "../../../Redux/Services/notification";
// import {
//     data,
//     preDefinedData,
// } from "../../../ConfigData/UserTypeConfig";

export default function Notification({ refresh }) {
  const [showHide, setShowHide] = useState(null);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [editId, setEditId] = useState(null);
  const [initialState, setInitialState] = useState(false);
  //set  ascend or descend sorter state
  const [getsortorder, setSortOrder] = useState(false);
  const [getsortfield, setSortField] = useState("");

  //calling rtk query hook
  const updateNotificationStatusApi = useUpdateNotificationStatusMutation();
  const [updateNotificationStatus] = updateNotificationStatusApi;

  function afterClose() {
    setInitialState(!initialState);
  }

  function closeModal(data) {
    setShowHide(data);
  }

  const sort = (sortOrder, sortfield) => {
    // console.log(sortOrder, sortfield);
    if (sortOrder) {
      setSortOrder(sortOrder);
      setSortField(sortfield);
    } else {
      setSortOrder(false);
    }
  };

  const {notificationModule } = useSelector((state) => state.flagRoles);
  //create function for customer fields
  function matchColumn(value) {
    // if (authColumn.indexOf(value) === -1) {
    //   return false;
    // } else {
    //   return true;
    // }
  }
  const preDefinedData = [
    {
      title: (
        <span>
          Header{" "}
          {getsortfield === "title" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "title" ? (
              <span className="sort-svg">
            <FaSortAmountUp className="text-info" /></span>
            ) : getsortorder === "descend" && getsortfield === "title" ? (
                <span className="sort-svg">
            <FaSortAmountDown className="text-info" /></span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "title",
      key: "title",
      align: "center",
      sorter: (a, b) => a.title.localeCompare(b.title),
      width: 250,
      ...SearchColumns("title", "Title"),
      editable: true,
    },
    {
      title: (
        <span>
          Description{" "}
          {getsortfield === "description" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "description" ? (
              <span className="sort-svg">
            <FaSortAmountUp className="text-info" /></span>
            ) : getsortorder === "descend" && getsortfield === "description" ? (
                <span className="sort-svg">
            <FaSortAmountDown className="text-info" /></span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "description",
      key: "description",
      align: "center",
      sorter: (a, b) => a.description.localeCompare(b.description),
      ...SearchColumns("description", "Description"),
      editable: true,
    },
    {
      title: <span>Icon</span>,
      dataIndex: "logo",
      key: "logo",
      sorter: true,
      align: "center",
      width: 250,
      render: (value) => <img src={atob(value)} width="20" alt="" height="20" />,
    },
    {
      title: <span>Active Status</span>,
     className: `${notificationModule[2].active_status ? "" : "d-none"}`,
			width: matchColumn(" ") && 100,
      dataIndex: "is_active",
      key: "is_active",
      render: (value, row) => (
        <div className="d-flex justify-content-center">
          <Switch
            onClick={(e) => switchHandler(e, row)}
            className="my-switch"
            size="small"
            checked={value}
          />
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "edit",
      key: "edit",
      className: `${notificationModule[1].action ? "" : "d-none"}`,
			width: matchColumn(" ") && 50,
      align: "center",
      render: (value, record) => (
        <EditOutlined
          onClick={() => {
            editHandler(record.id);
          }}
          size={50}
        />
      ),
    },
  ];

  //redux data store
  const { token, user } = useSelector((state) => state.auth);

  const notificationApi = useNotificationListQuery(token);

  useEffect(() => {
    if (notificationApi.isSuccess) {
      setData(notificationApi.data?.data);
    }
  }, [notificationApi]);

  //switch handler
  function switchHandler(value, row) {
    const data = {
      is_active: value === true ? 1 : 0,
      updated_by: user?.user_id,
    };
    updateNotificationStatus({
      data,
      id: row.id,
      token: token,
    });
  }
  //update notification
  useEffect(() => {
    if (updateNotificationStatusApi[1].isSuccess) {
      ///after submit details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: updateNotificationStatusApi[1].data?.message,
      });
    }
  }, [updateNotificationStatusApi[1].isLoading]);
  //edit Notification module
  const editHandler = (id) => {
    setShowHide(true);
    // //sending id to edit vendor modal
    setEditId(id);
  };

  useEffect(() => {
    setSearch("");
    //request
    if (refresh === "notification") {
      notificationApi.refetch();
    }
  }, [refresh]);

  return (
    <>
      <div className="d-flex align-items-center float-end w-25 mb-1 ">
        <AppModal
          showModal={showHide}
          onClose={closeModal}
          afterClose={afterClose}
          element={
            <NotificationModal
              //passing row customer editId
              editId={editId}
              afterClose={initialState}
              setShowHide={setShowHide}
            />
          }
          width={960}
        />
        <div className=" me-1 w-100 ">
          <Input
            placeholder="Search here"
            className="global-search "
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            prefix={<FaSearch color="lightgray" />}
          />
        </div>
      </div>
      <div style={{ clear: "both" }}></div>
      <AppTable
        sort={sort}
        loading={notificationApi.isLoading}
        dataSource={data}
        columns={preDefinedData}
        search={search}
      />
    </>
  );
}
