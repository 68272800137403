import React, { useState } from "react";
import { Alert, Collapse, Popconfirm } from "antd";
import AppTable from "../AppTable/AppTable";

import { FaPlus, FaStarOfLife } from "react-icons/fa";
// import "./MultiHoaContact.css";
import {
  CaretRightOutlined,
  EditOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import AppInput from "../AppInput/AppInput";
import { MdOutlineContactSupport, MdOutlineDelete } from "react-icons/md";
// import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { useEffect } from "react";
import { removeBlank } from "../../Controllers/Functions";
import AppNumeric from "../AppNumeric/AppNumeric";
import AppSelect from "../AppSelect/AppSelect";
const randomPass = require("secure-random-password");
const { Panel } = Collapse;
export default function MultiNotes({
  afterClose = false,
  addFormVal = false,
  initailValArr = [],
  updateContact = false,
  deleteContact = false,
  whileEdit = false,
  _id,
}) {
  const [active, setActive] = useState("0");
  const initailVal = {
    note:""
  };
  const [values, setValues] = useState(initailVal);
  // const [,setShowDate] = useState(true);
  const [data, setData] = useState(initailValArr);
  const [addRowToggler, setAddRowToggler] = useState(false);

  const [editId, setEditId] = useState(null);
  const [getErr, setGetErr] = useState(false);

  const { note} = values;

  //edit row
  const editHandler = (record) => {
    setEditId(record);
    setAddRowToggler(true);
    setValues(record);
  };

  const columns = [
    {
      title: <span>Notes</span>,
      dataIndex: "note",
      key: "note",
      align: "center",
      render:(value, data)=>{
        return value || data?.content
      }
    },
    // {
    //   title: <span>is </span>,
    //   dataIndex: "phone",
    //   key: "phone",
    //   align: "left",
    // },
    // {
    //     title: <span>Email</span>,
    //     dataIndex: "email",
    //     key: "email",
    //     align: "left",
    // },
    // {
    //     title: <span>Contact Method</span>,
    //     dataIndex: "contact_method_id",
    //     key: "contact_method_id",
    //     align: "left",
    //     render:(value)=>{
    //       console.log(contact_methods?.find(object=> object.id == value), 80);
    //       return contact_methods?.find(object=> object.id == value)?.name;
    //     }
    //   },
    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      align: "center",
      width: 130,
      render: (_, record) => {
        return (
          <>
            <span>
              <EditOutlined
                style={{ cursor: "pointer" }}
                onClick={() => editHandler(record)}
                size={50}
              />
            </span>

            <Popconfirm
              getPopupContainer={(trigger) => trigger.parentElement}
              title="Are you sure you want to delete？"
              onConfirm={() => {
                deleteHandler(record);
              }}
              placement="topLeft"
              icon={
                <QuestionCircleOutlined
                  style={{
                    color: "red",
                  }}
                />
              }
              okText="Yes"
              cancelText="No"
            >
              <span className={"ms-2 "}>
                <MdOutlineDelete style={{ cursor: "pointer" }} size={15} />
              </span>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const inputs = [
    {
      id: 1,
      name: "note",
      _id: "note",
      type: "text",
      value: note || "",
      maxLength:200,
      placeholder: "Enter Note",
      errorMessage: "Please enter Note!",
    //   icon: <FaStarOfLife size={5} color="red" />,
    },
    // {
    //   id: 454545,
    //   name: "phone",
    //   _id: "phone",
    //   type: "text",
    //   value: phone || "",
    //   column: "3",
    //   maxLength: 10,
    //   placeholder: "Enter Phone",
    //   errorMessage: "Please enter phone!",
    // },
    // {
    //     id: 4546456,
    //     name: "email",
    //     _id: "email",
    //     type: "email",
    //     value: email || "",
    //     column: "3",
    //     placeholder: "Enter Email",
    //     errorMessage: "Please enter email!",
    //   },
    //   {
    //     id: 4547357,
    //     name: "contact_method_id",
    //     _id: "contact_method_id",
    //     type: "select",
    //     value: contact_method_id || "",
    //     column: "3",
    //     options: contact_methods,
    //     errorMessage: "Please enter email!",
    //   },
   
  ];

  const onChange = (e, date, dateFormat) => {
      setGetErr(false)
      const { name, value } = e.target;
      setValues({
        ...values,
        [name]: value,
      })
  };

  //sending request

  const handleSubmit = () => {
    if (editId) {
      const newData = data.filter((ele) => {
        return (ele.temp_id || ele.id) !== (editId.temp_id || editId.id);
      });
      setData([...newData, { ...values, note:values?.note}]);
      updateContact && updateContact({ ...values, note:values?.note});
      setAddRowToggler(false);
      setEditId(null);
      // setValues(initailVal);
    } else {
      //remove blank values
      removeBlank(values);
      const randomId = randomPass.randomPassword({
        length: 9,
        characters: randomPass.digits,
      });
      setData([
        ...data,
        {
          ...values,
          note:values?.note,
        },
      ]);
      //while edit and ading new record directly
      whileEdit({ 
        ...values, 
        note:values?.note,
        temp_id: randomId 
    });
      setAddRowToggler(false);
    }
  };

  const cancelHandler = () => {
    setValues(initailVal);
    addFormVal(data);
    setAddRowToggler(false);
  };

  const addNewRecord = () => {
    setAddRowToggler(!addRowToggler);
    setValues({ ...initailVal})
  };

  const deleteHandler = (id) => {
    setData(
      data.filter((ele) => {
        return (ele?.id || ele?.temp_id) !== (id?.id || id?.temp_id);
      })
    );
    //sending delete id
    deleteContact(id);
  };

  useEffect(() => {
    setAddRowToggler(false);
    setData(initailValArr);
    setValues(initailVal);
    setEditId(null);
    setActive("0");
  }, [afterClose]);

  useEffect(() => {
    addFormVal && addFormVal(data);
  }, [data]);

//   const selectHandler = (property, value, arrtibute) => {
// 		setValues({
// 			...values,
// 			contact_method_name: value,
// 			contact_method_id: arrtibute?.getAttribute("data_name"),
// 		});
// 	};

  return (
    <Collapse
      className="m-0 py-0 gray-table site-collapse-custom-collapse"
      expandIcon={({ isActive }) => (
        <CaretRightOutlined
          style={{ fontSize: 14 }}
          className="mt-1"
          rotate={isActive ? 90 : 0}
        />
      )}
      expandIconPosition={"end"}
      activeKey={active}
      accordion
      onChange={(value) => {
        setActive(value);
      }}
    >
      <Panel
        className="my-1 border-0 site-collapse-custom-panel "
        header={
          <h6 className=" mb-0 fw-bold me-2 d-flex align-items-center gap-1">
            <span>Notes</span>
          </h6>
        }
        key="0"
      >
        {active === "0" && (
          <div className="gray-table-col  mb-2 p-2" id={_id}>
            <div
              className={`d-flex ${"justify-conten"} align-items-center my-2 contact-forms`}
            >
              
              {/* {inputs.map((input) => (
                <>
                  {input.type === "date" && (
                    <AppInput
                      defaultSet={afterClose}
                      key={input.id}
                      {...input}
                      value={values[input.name]}
                      onChange={(name, date, dateFormat) => {
                        // setShowDate(true);

                        if (dateFormat) {
                          getDate && getDate(date);
                          setValues({ ...values, verify_date: date });
                        }


                      }}
                    />
                  )}
                </>
              ))} */}
              {!addRowToggler && (
                <div className={`add_icon ms-auto`} onClick={addNewRecord}>
                  <FaPlus />
                </div>
              )}
            </div>

            <AppTable
              pagination={false}
              dataSource={data}
              columns={columns}
            />

            {getErr && (
              <div className="mt-3">
                <Alert
                  message={getErr}
                  className="py-1 mx-3"
                  type="error"
                  showIcon
                />{" "}
              </div>
            )}
            {/* //loading  */}
            {/* {emailCheckApi[1].isLoading && <AppSpinner size="small" />} */}

            {addRowToggler && (
              <div className="row bg-white p-2 mx-auto shadow-sm" id="addRow">
                {inputs.map((input) => (
                  <>
                    {input.type === "text" && (
                      <AppInput
                        defaultSet={afterClose}
                        key={input.id}
                        {...input}
                        value={values[input.name]}
                        onChange={onChange}
                      />
                    )}
                    {/* {input.type === "email" && (
                      <AppInput
                        defaultSet={afterClose}
                        key={input.id}
                        {...input}
                        value={values[input.name]}
                        onChange={onChange}
                      />
                    )}
                     {input.type === "select" && (
                      <AppSelect
                        defaultSet={afterClose}
                        key={input.id}
                        {...input}
                        value={values[input.name]}
                        onChange={selectHandler}
                        options={
                          <>
															<option value={""}>
																Select Contact Method
															</option>
															{input.options.map((ele) => {
																return (
																	<option
																		key={ele.id}
																		data_name={ele.id}
																		value={ele.id}
																	>
																		{ele.name}
																	</option>
																);
															})}
														</>}
                      />
                    )} */}
                    {/* {input.name === "fees" && (
                      <AppNumeric
                        {...input}
                        type="text"
                        defaultSet={afterClose}
                        value={values[input.name]}
                        thousandSeparator={true}
                        placeholder={input.placeholder}
                        prefix={"$"}
                        decimalScale={7}
                        onChange={(value) => {
                          setValues({ ...values, fees: value });
                        }}
                      />
                    )} */}
                  </>
                ))}
                <div className="col-12 col-lg-1 mt-3 p-0">
                  <div className="d-flex">
                    <span
                      onClick={handleSubmit}
                      className={`btn btn-primary  btn-sm edit-buttons ${!note ? "disabled" : "btn-success"
                        } `}
                    >
                      {editId?.id ? "Update" : "Save"}
                    </span>
                    <span
                      onClick={cancelHandler}
                      className="btn btn-primary ms-1 btn-sm edit-buttons"
                    >
                      Cancel
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </Panel>
    </Collapse>
  );
}
