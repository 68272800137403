import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json")
export const countyJurisdiction = createApi({
    reducerPath: "countyJurisdiction",
    // tagTypes: ["countyJurisdiction"],
    baseQuery: fetchBaseQuery({ baseUrl: config.url }),
    endpoints: (builder) => ({
        countyJurisdiction: builder.mutation({
            query: (data) => ({
                url: `/county-jurisdiction-list/${data.state_id}`,
                method: "GET",
                mode: "cors",
                headers: { Authorization: `Bearer ${data.token}` },
            }),
            // providesTags: ["countyJurisdiction"],
        }),
    }),
});

export const { useCountyJurisdictionMutation } =
    countyJurisdiction;
