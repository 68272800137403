import React from "react";
import AppDragTable from "../../Components/AppDragTable/AppDragTable";

import "./dashboard.css"

import DashboardNewDesign from "./dashCard";
import { useSelector } from "react-redux";

export default function MyDashBoard() {
  const { labelList } = useSelector((state) => state.labelList);
  return (
    <>
      <h5 className="settings-container">
        {labelList["cs.settings.header"]} |{" "}
        {labelList["cs.dashboard.menu.dashboard"]}
      </h5>
      <div>
      <DashboardNewDesign/>
      </div>
    </>
  );
}