import { Drawer} from "antd";
import React from "react";
import ParcelAccordian from "./ParcelAccordian";

const ViewParcel = ({
  open,
  setOpen,
  onChange,
  list = [],
  onselect,
  parcelStatusListRefetch,
}) => {
  return (
    <>
      <Drawer
        placement="right"
        onClose={() => setOpen(false)}
        maskClosable={false}
        open={open}
        className="order view-parcel"
      >
        {/* <div className=" mx-2">
          <Input
            placeholder="Search here..."
            className="global-search "
            // value={search}
            // onChange={(e) => setSearch(e.target.value)}
            prefix={<FaSearch color="lightgray" />}
          />
        </div> */}
        {/* <div className="hig-priority my-2">
          <ParcelAccordian
            setOpen={setOpen}
            afterClose={open}
            title={"High Priority"}
            data={highPriority}
          />
        </div>
        <div className="on-hold my-2">
          <ParcelAccordian
            setOpen={setOpen}
            afterClose={open}
            title={"On Hold"}
            data={onHold}
          />
        </div> */}
        <div className="all parcels my-2">
          <ParcelAccordian
            parcelStatusListRefetch={parcelStatusListRefetch}
            setOpen={setOpen}
            onChange={onChange}
            onselect={onselect}
            afterClose={open}
            title={"All Parcels"}
            data={list}
          />
        </div>
      </Drawer>
    </>
  );
};

export default ViewParcel;
