import React, { useState } from "react";
import { Tabs } from "antd";
import ManualEntry from "./ManualEntry/ManualEntry";
import PropertyBrowser from "./PropertyBrowser/PropertyBrowser";
const { TabPane } = Tabs;

export const OrderTab = () => {
	const [key, setKey] = useState("manualentry");
	return (
		<>
			<Tabs
				activeKey={key}
				className=""
				defaultActiveKey="1"
				onChange={(k) => {
					setKey(k);
				}}
			>
				{
					<TabPane tab="Manual Entry" key="manualentry">
						<ManualEntry refresh={key} />{" "}
					</TabPane>
				}
				{
					<TabPane tab="Property Browser" key="propertybrowser">
						<PropertyBrowser refresh={key} />
					</TabPane>
				}
			</Tabs>
		</>
	);
};

export default OrderTab;
