import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json");
export const userInfo = createApi({
  reducerPath: "userInfo",
  tagTypes: ["userInfo"],
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  endpoints: (builder) => ({
    getUser: builder.query({
      query: (data) => ({
        url: `/user-info/${data.id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      providesTags: ["userInfo"],
    }),
    customerWiseUserList: builder.mutation({
			query: (data) => ({
			  url: `/customerwise-user-list`,
			  mode: "cors",
			  method: "POST",
			  body: data.data,
			  headers: { Authorization: `Bearer ${data.token}` },
			}),
      invalidatesTags: ["userInfo"],
		  }),
    updateUser: builder.mutation({
      query: (data) => ({
        url: `/update-user/${data.id}`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["userInfo"],
    }),
    usersList: builder.query({
      query: (token) => ({
        url: `/user-list`,
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ["userInfo"],
    }),

    editUser: builder.query({
      query: (data) => ({
        url: `/edit-user/${data.id}`,
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    addUser: builder.mutation({
      query: (data) => ({
        url: `/add-user`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["userInfo"],
    }),
    updateUserInfo: builder.mutation({
      query: (data) => ({
        url: `/update-user-info/${data.id}`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["userInfo"],
    }),

    updateUserStatus: builder.mutation({
      query: (data) => ({
        url: `/update-user-status/${data.id}`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["userInfo"],
    }),

    usersProductType: builder.mutation({
      query: (data) => ({
        url: `/user-product-type/${data.id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
  
  }),
});

export const {
  useGetUserQuery,
  useUpdateUserMutation,
  useUsersListQuery,
  useEditUserQuery,
  useAddUserMutation,
  useUpdateUserInfoMutation,
  useUpdateUserStatusMutation,
  useUsersProductTypeMutation,
  useCustomerWiseUserListMutation
} = userInfo;

//userTypeList
export const userType = createApi({
  reducerPath: "userType",
  tagTypes: ["userType"],
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  endpoints: (builder) => ({
    userTypeList: builder.query({
      query: (token) => ({
        url: `/usertype-list`,
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ["userType"],
    }),
    addUserType: builder.mutation({
      query: (data) => ({
        url: `/add-usertype`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["userType"],
    }),
    updateUserType: builder.mutation({
      query: (data) => ({
        url: `/update-usertype/${data.id}`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["userType"],
    }),
    deleteUserType: builder.mutation({
      query: (data) => ({
         url: `/delete-usertype/${data.id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["userType"],
    }),
  }),
});

export const {
  useAddUserTypeMutation,
  useUpdateUserTypeMutation,
  useUserTypeListQuery,
  useDeleteUserTypeMutation,
} = userType;
