import AppTable from "../../../Components/AppTable/AppTable";
import AppModal from "../../../Components/AppModal/AppModal";
import { TaxCollectorModal } from "./TaxCollectorModal";
import { EditOutlined } from "@ant-design/icons";
import {
	FaPlus,
	FaSearch,
	FaSortAmountDown,
	FaSortAmountUp,
} from "react-icons/fa";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
	useTaxCollectorListQuery,
	useTaxCollectorTableFilterListMutation,
} from "../../../Redux/Services/TaxCollector";
import AppSpinner from "../../../Components/AppSpinner/AppSpinner";
import { Button, Input } from "antd";
import "./TaxCollector.css";
import { useEffect } from "react";
import SearchColumns from "../../../Components/SearchColumns";
import { FiFilter, FiRotateCw } from "react-icons/fi";
import { useGetCountyListMutation } from "../../../Redux/Services/county";
import AppFilterSelect from "../../../Components/AppFilterSelect/AppFilterSelect";

export const TaxCollector = ({ refresh, updateTab }) => {
	const [editId, setEditId] = useState(null);
	const [showHide, setShowHide] = useState(null);
	const [data, setData] = useState();
	const [countyOptions, setCountyOptions] = useState([]);
	const [open, setOpen] = useState(false);
	const [filetrVal, setFiletrVal] = useState({
		state: "",
		county: "",
		"tax Collector": "",
	});
	const [search, setSearch] = useState("");
	const [initialStateVal, setInitialStateVal] = useState(false);
	const [preDefualtStateValues] = useState({
		state_id: "",
		county_id: "",
		name: "",
		selDisplay: "",
		selReadOnly: "",
		mail_to: "",
		inst_per_year: "",
		due_date: "",
		website: "",
		current_year_bill: "",
		is_physical: false,
		extractor_name: "",
		is_third_party: true,
		third_party_payee: "",
		allow_previous_bills: true,
		mailing_phone_number: "",
		mailing_address_one: "",
		mailing_address_two: "",
		mailing_state_id: "",
		mailing_city: "",
		mailing_zip_five: "",
		mailing_zip_four: "",
		mailing_contact_position: "",
		mailing_email: "",
		physical_phone_number: "",
		physical_address_one: "",
		physical_address_two: "",
		physical_state_id: "",
		physical_city: "",
		physical_zip_five: "",
		physical_zip_four: "",
		physical_contact_position: "",
		physical_email: "",
		payment_plan: "",
		account_number: "",
		is_active: true,
		tax_collector_bill_cycle: [],
	});

	//customer list fetch with rtk query

	const AddVendorHandler = () => {
		setShowHide(true);
		setEditId(null);
	};

	function afterClose() {
		setInitialStateVal(!initialStateVal);
	}

	function closeModal(data) {
		setShowHide(data);
	}

	//set state for ascend or descend sorter state
	const [getsortorder, setSortOrder] = useState(false);
	const [getsortfield, setSortField] = useState("");

	//redux data store
	const { token } = useSelector((state) => state.auth);

	const { taxCollectorModule } = useSelector((state) => state.flagRoles);

	//calling rtk query hook for geting Taxcollector list
	const TaxCollector = useTaxCollectorListQuery(token);

	//calling rtk query hook for geting County list
	const countyList2 = useGetCountyListMutation();
	const [getCountyList] = countyList2;

	// useEffect for stateslist
	useEffect(() => {
		if (countyList2[1].isSuccess) {
			setCountyOptions(countyList2[1].data?.data);
		} else {
			setCountyOptions([]);
		}
	}, [countyList2[1].isLoading]);

	const sort = (sortOrder, sortfield) => {
		// console.log(sortOrder, sortfield);
		if (sortOrder) {
			setSortOrder(sortOrder);
			setSortField(sortfield);
		} else {
			setSortOrder(false);
		}
	};

	//edit taxcollector module
	const editHandler = (id) => {
		setShowHide(true);

		// //sending id to edit vendor modal
		setEditId(id);
	};
	//create function for customer fields
	function matchColumn(value) {
		// if (authColumn.indexOf(value) === -1) {
		//   return false;
		// } else {
		//   return true;
		// }
	}

	const columns = [
		{
			title: (
				<span>
					State{" "}
					{getsortfield === "state_name" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "state_name" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "state_name" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "state_name",
			key: "state_name",
			align: "center",
			sorter: (a, b) => a.state_name.localeCompare(b.state_name),
			width: 200,
			...SearchColumns("state_name", "State Name"),
		},
		{
			title: (
				<span>
					County{" "}
					{getsortfield === "county_name" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "county_name" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "county_name" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "county_name",
			key: "county_name",
			align: "center",
			sorter: (a, b) => a.county_name.localeCompare(b.county_name),
			width: 250,
			...SearchColumns("county_name", "County Name"),
			render: (value) => <div style={{ wordBreak: "break-word" }}>{value}</div>
		},
		{
			title: (
				<span>
					Tax Collector{" "}
					{getsortfield === "name" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "name" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "name" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "name",
			key: "name",
			align: "center",
			sorter: (a, b) => a.name.localeCompare(b.name),
			width: 300,
			...SearchColumns("name", " Tax Collector Name"),
			render: (value) => <div style={{ wordBreak: "break-word" }}>{value}</div>
		},
		{
			title: (
				<span>
					Website{" "}
					{getsortfield === "website" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "website" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "website" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "website",
			key: "website",
			align: "center",
			sorter: (a, b) => a.website.localeCompare(b.website),
			...SearchColumns("website", "Website"),
			width: 200,
			render: (value) => <div style={{ wordBreak: "break-word" }}>{value}</div>

		},
		{
			title: (
				<span>
					Extractor Name{" "}
					{getsortfield === "extractor_name" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "extractor_name" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" &&
						getsortfield === "extractor_name" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "extractor_name",
			className: `${taxCollectorModule[1].action ? "" : "border-endstyle"}`,
			key: "extractor_name",
			align: "center",
			sorter: (a, b) => a.extractor_name.localeCompare(b.extractor_name),

			...SearchColumns("extractor_name", "Extractor Name"),
			width: 250,
			render: (value) => <div style={{ wordBreak: "break-word" }}>{value}</div>
		},

		{
			title: "Action",
			dataIndex: "edit",
			className: `${taxCollectorModule[1].action ? " " : "d-none"}`,
			width: matchColumn(" ") && 100,
			align: "center",
			key: "edit",
			render: (value, record) => {
				return (
					<>
						{
							<EditOutlined
								size={50}
								onClick={() => {
									editHandler(record.id);
								}}
							/>
						}
					</>
				);
			},
		},
	];

	useEffect(() => {
		if (TaxCollector.isSuccess) {
			const newData = TaxCollector.data?.data?.map(
				({ website, extractor_name, ...ele }) => {
					return {
						...ele,
						website: website || " ",
						extractor_name: extractor_name || " ",
					};
				}
			);
			setData(newData);
		}
	}, [TaxCollector]);

	const [taxCollectorTableFilterList, taxCollectorTableFilterListResult] =
		useTaxCollectorTableFilterListMutation();

	//state list
	const { stateList } = useSelector((state) => state.stateList);

	useEffect(() => {
		if (taxCollectorTableFilterListResult.isSuccess) {
			const newData = taxCollectorTableFilterListResult.data?.data?.map(
				({ website, extractor_name, ...ele }) => {
					return {
						...ele,
						website: website || " ",
						extractor_name: extractor_name || " ",
					};
				}
			);
			setData(newData);
		} else {
			setData([]);
		}
	}, [taxCollectorTableFilterListResult.isLoading]);

	//options for showing filters
	const options = [
		{
			name: "state",
			value: filetrVal.state,
			data: stateList?.map(({ state_name, id }) => {
				return { name: state_name, id };
			}),
		},
		{
			name: "county",
			value: filetrVal.county,
			data: countyOptions?.map(({ county_name, id }) => {
				return { name: county_name, id };
			}),
		},
	];

	//function
	function clearState() {
		setCountyOptions([]);
		//reset
		if (filetrVal.state) {
			TaxCollector.refetch();
		}

		setFiletrVal({
			...filetrVal,
			state: "",
			county: "",
		});
	}
	//function
	function clearCounty() {
		//reset
		if (filetrVal.state && filetrVal.county) {
			taxCollectorTableFilterList({
				data: { state_id: filetrVal.state },
				token,
			});
		}
		setFiletrVal({
			...filetrVal,
			county: "",
		});
	}
	function clearFilter() {
		setOpen(false);
		clearState();
	}
	useEffect(() => {
		//request
		if (refresh === "taxcollector") {
			TaxCollector.refetch();
		}
		setCountyOptions([]);
		clearFilter();
	}, [refresh]);
	return (
		<>
			{TaxCollector.isLoading ? (
				<AppSpinner />
			) : (
				<>
					<div className="d-flex align-items-center  mb-1 gap-2 justify-content-end">
						<AppModal
							showModal={showHide}
							onClose={closeModal}
							afterClose={afterClose}
							width={"90%"}
							height={500}
							confirmProp
							element={
								<TaxCollectorModal
									//passing tab of customers
									updateTab={updateTab}
									//passing row customer editId
									editId={editId}
									initialValues={preDefualtStateValues}
									setShowHide={setShowHide}
									setOpen={clearFilter}
									//passing deafult dependency to initial state
									afterClose={initialStateVal}
								/>
							}
						/>

						{/* changing input values globaly  */}
						<>
							<div className=" me-1 w-25 ">
								<Input
									placeholder="Search here"
									className="global-search "
									value={search}
									onChange={(e) => setSearch(e.target.value)}
									prefix={<FaSearch color="lightgray" />}
								/>
							</div>
							<div className="filter-button ">
								<Button
									className="filter-btn"
									onClick={() => {
										setOpen(!open);
										setFiletrVal({
											state: "",
											county: "",
											"tax Collector": "",
										});
										if (open) {
											TaxCollector.refetch();
										}
									}}
									type={open ? "primary" : "default"}
								>
									<FiFilter color={open ? "#fff" : ""} />{" "}
									<span className="ms-1">Filter</span>
								</Button>
							</div>
							{taxCollectorModule.map((item) => {
								if (item.add_button) {
									return (
										<div className="add_icon" onClick={AddVendorHandler}>
											<FaPlus />
										</div>
									);
								}
								return null;
							})}
						</>
					</div>
					<div style={{ clear: "both" }}></div>
					{open && (
						<div className="my-2 p-2 bg-white border shadow-sm d-flex align-items-center ">
							<div className="row w-100">
								{options?.map(({ value, name, data }, index) => {
									return (
										<div className="col-12 col-lg-3">
											<AppFilterSelect
												name={name}
												value={value}
												options={data}
												onClear={() => {
													if (name === "state") {
														clearState();
													} else if (name === "county") {
														clearCounty();
													}
												}}
												onChange={(e) => {
													setFiletrVal({
														...filetrVal,
														[name]: e.target.value,
													});

													if (name === "state" && e.target.value) {
														//fetching county list
														getCountyList({
															state_id: { state_id: e.target.value },
															token,
														});
														taxCollectorTableFilterList({
															data: { state_id: e.target.value },
															token,
														});
													}
													if (name === "state" && !e.target.value) {
														clearState();
													}

													if (name === "county" && e.target.value) {
														taxCollectorTableFilterList({
															data: { county_id: e.target.value },
															token,
														});
													}
													if (name === "county" && !e.target.value) {
														clearCounty();
													}
												}}
											/>
										</div>
									);
								})}
							</div>

							<div className="d-flex justify-centent-end ms-md-2">
								<Button
									className="reset-btn"
									onClick={() => {
										clearState();
									}}
									type="default"
								>
									<FiRotateCw /> <span className="ms-1"> Reset</span>
								</Button>
							</div>
						</div>
					)}
					<div className="seprate">
						<AppTable
							sort={sort}
							loading={
								taxCollectorTableFilterListResult.isLoading ||
								TaxCollector.isFetching
							}
							search={search}
							dataSource={data}
							columns={columns}
						/>
					</div>
				</>
			)}
		</>
	);
};
