export const dataTEst = {
  "input_Order": {
      "order_Number": "Galveston01Aug23",
      "completed_date": "",
      "parcel_cad_property_id": "211182,220914",
      "seller_Name": "OZUNA REBECCA & SALOMON RODRIGUEZ",
      "buyer_Name": "",
      "client_Name": "Alamo Title - San Antonio",
      "closer_name": "Brenda Bicoy",
      "branch_name": "Main",
      "property_Address": "718 22ND AVE N TEXAS CITY TX 77590",
      "legal_Description": "ABST 167 PAGE 4 & 5 LOT 25 BLK 21 MAINLAND PARK SEC D",
      "stellarorderidentifier": "CS-20238-1092",
      "file_Number": "Galveston01Aug23",
      "issue_Date": "",
      "comments": "",
      "cert_Fee": "$50",
      "no_of_Parcels": 2,
      "disclaimer": ""
  },
  "parcel_Information": [
      {
          "parcel_id": 2090,
          "parcel_year": 2022,
          "parcel_No": "211182",
          "legal_Description": "ABST 167 PAGE 4 & 5 LOT 25 BLK 21 MAINLAND PARK SEC D",
          "geographic_Id": "4900-0021-0025-000",
          "account_Id": "",
          "owner": [
              {
                  "parcel_id": 2090,
                  "owner_Name": "OZUNA REBECCA & SALOMON RODRIGUEZ",
                  "address": "718 22ND AVE N,TEXAS CITY,TX,77590-5626",
                  "percentage": "100%"
              }
          ],
          "acreage": "0.1853",
          "deed_Ref": "2022028315/2022028315/2022028315/04062022",
          "subdivision": "S4900",
          "property_County": "Galveston",
          "property_State": "Texas",
          "property_Address": "718 22ND AVE N TEXAS CITY TX 77590",
          "assessed_Information": [
              {
                  "parcel_id": 2090,
                  "parcel_assessed_year": "",
                  "assessment_Year": 2023,
                  "appraised": "$159980",
                  "land_Value": "$9660",
                  "agricultural_Value": "$0",
                  "improvement_Value": "$150320",
                  "capped_Value": "$0",
                  "exemption_Amount": "",
                  "total_assessed": "$159980",
                  "is_proposed": true,
                  "est_tax_without_exemption": "$5,725.04",
                  "net_Taxable": "$159980"
              },
              {
                  "parcel_id": 2090,
                  "parcel_assessed_year": "",
                  "assessment_Year": 2022,
                  "appraised": "$136650",
                  "land_Value": "$9660",
                  "agricultural_Value": "$0",
                  "improvement_Value": "$126990",
                  "capped_Value": "$0",
                  "exemption_Amount": "",
                  "total_assessed": "$136650",
                  "is_proposed": false,
                  "est_tax_without_exemption": "$4,890.16",
                  "net_Taxable": "$136650"
              }
          ],
          "tax_Information": {
              "parcel_id": 2090,
              "inst_Per_Year": "",
              "due_by_date_one": "08-31-2023",
              "due_by_date_two": "09-30-2023",
              "totalAmt": {
                  "basetax": "$451.40",
                  "basedue": "$6581.02",
                  "duedate1": "$644.64",
                  "duedate2": "$650.02"
              },
              "collectorInfo": [
                  {
                      "name": "Galveston County Tax Office",
                      "account_number": "211182",
                      "payable_to": "Payable To:Galveston County Tax Office",
                      "payee": "Galveston County Tax Office",
                      "mail_To": "722 Moody,Galveston,Texas,77550",
                      "phone_No": "+14097662481",
                      "comments": "",
                      "bill_summary": [
                          {
                              "taxyear": "2022",
                              "basetax": "$1549.14",
                              "basedue": "$0",
                              "duedate1": "$0",
                              "duedate2": "$0",
                              "status": "Paid"
                          },
                          {
                              "taxyear": "Prior Year",
                              "basetax": "$0",
                              "basedue": "$3064.81",
                              "duedate1": "$0",
                              "duedate2": "$0",
                              "status": "Due"
                          },
                          {
                              "taxyear": "Collector Total:",
                              "basetax": "$225.7",
                              "basedue": "$3290.5099999999998",
                              "duedate1": "$322.32",
                              "duedate2": "$325.01",
                              "status": ""
                          }
                      ]
                  },
                  {
                      "name": "Texas City ISD Tax Office",
                      "account_number": "211182",
                      "payable_to": "Payable To:Texas City ISD Tax Office",
                      "payee": "Texas City ISD Tax Office",
                      "mail_To": "1700 9th Avenue N,Texas City,Texas,77590",
                      "phone_No": "+14099160153",
                      "comments": "",
                      "bill_summary": [
                          {
                              "taxyear": "2022",
                              "basetax": "$3341.03",
                              "basedue": "$1791.89",
                              "duedate1": "$1306.09",
                              "duedate2": "$0",
                              "status": "Due"
                          },
                          {
                              "taxyear": "Prior Year",
                              "basetax": "$0",
                              "basedue": "$3064.81",
                              "duedate1": "$0",
                              "duedate2": "$0",
                              "status": "Due"
                          },
                          {
                              "taxyear": "Collector Total:",
                              "basetax": "$225.7",
                              "basedue": "$3290.5099999999998",
                              "duedate1": "$322.32",
                              "duedate2": "$325.01",
                              "status": ""
                          }
                      ]
                  }
              ]
          },
          "jurisdiction_info": {
              "total_taxrate": "$3.578600",
              "juri_data": [
                  {
                      "collector_name": "Galveston County Tax Office",
                      "tax_rate": "$1.133650",
                      "jurisdiction_data": [
                          {
                              "name": "Texas City",
                              "tax_year": 2022,
                              "tax_rate": "$0.490000",
                              "billyear": 2022,
                              "baseamount": "$669.59",
                              "estimated_tax_without_exemption": "$669.59"
                          },
                          {
                              "name": "Galveston County",
                              "tax_year": 2022,
                              "tax_rate": "$0.367587",
                              "billyear": 2022,
                              "baseamount": "$502.31",
                              "estimated_tax_without_exemption": "$502.31"
                          },
                          {
                              "name": "Coll Of The Mainland",
                              "tax_year": 2022,
                              "tax_rate": "$0.267620",
                              "billyear": 2022,
                              "baseamount": "$365.70",
                              "estimated_tax_without_exemption": "$365.7"
                          },
                          {
                              "name": "Co Road & Flood",
                              "tax_year": 2022,
                              "tax_rate": "$0.008443",
                              "billyear": 2022,
                              "baseamount": "$11.54",
                              "estimated_tax_without_exemption": "$11.54"
                          }
                      ]
                  },
                  {
                      "collector_name": "Texas City ISD Tax Office",
                      "tax_rate": "$2.444950",
                      "jurisdiction_data": [
                          {
                              "name": "Texas City ISD",
                              "tax_year": 2022,
                              "tax_rate": "$1.311300",
                              "billyear": 2022,
                              "baseamount": "$1791.89",
                              "estimated_tax_without_exemption": "$1,791.89"
                          }
                      ]
                  }
              ]
          },
          "bonds": [],
          "exemption": [
              {
                  "name": "Texas City",
                  "hom": "20% $5,000 min",
                  "ov65": "$20,000.00",
                  "dp": "$10,000.00",
                  "10to30": "",
                  "31to50": "",
                  "51to70": "",
                  "71to100": ""
              },
              {
                  "name": "Galveston County",
                  "hom": "20% $5,000 min",
                  "ov65": "$60,000.00",
                  "dp": "$60,000.00",
                  "10to30": "",
                  "31to50": "",
                  "51to70": "",
                  "71to100": ""
              },
              {
                  "name": "Coll Of The Mainland",
                  "hom": "20% $5,000 min",
                  "ov65": "$24,000.00",
                  "dp": "$24,000.00",
                  "10to30": "",
                  "31to50": "",
                  "51to70": "",
                  "71to100": ""
              },
              {
                  "name": "Co Road & Flood",
                  "hom": "20% $5,000 min + $3,000",
                  "ov65": "$60,000.00",
                  "dp": "$60,000.00",
                  "10to30": "",
                  "31to50": "",
                  "51to70": "",
                  "71to100": ""
              },
              {
                  "name": "Texas City ISD",
                  "hom": "20% $40,000 min",
                  "ov65": "$10,000.00",
                  "dp": "$10,000.00",
                  "10to30": "",
                  "31to50": "",
                  "51to70": "",
                  "71to100": ""
              }
          ]
      },
      {
          "parcel_id": 2091,
          "parcel_year": 2022,
          "parcel_No": "220914",
          "legal_Description": "ABST 126 PAGE 1 LOT 10 S POLLITT 3RD EXTN",
          "geographic_Id": "5918-0000-0010-000",
          "account_Id": "",
          "owner": [
              {
                  "parcel_id": 2091,
                  "owner_Name": "MACK EARNEST",
                  "address": "3610 SEALY ST,GALVESTON,TX,77550-4031",
                  "percentage": "100%"
              }
          ],
          "acreage": "0.2662",
          "deed_Ref": "006-67-0547//006-67-0547/10111989",
          "subdivision": "S5918",
          "property_County": "Galveston",
          "property_State": "Texas",
          "property_Address": "5414 PHILLIPS TEXAS CITY TX 77591",
          "assessed_Information": [
              {
                  "parcel_id": 2091,
                  "parcel_assessed_year": "",
                  "assessment_Year": 2023,
                  "appraised": "$19130",
                  "land_Value": "$19130",
                  "agricultural_Value": "$0",
                  "improvement_Value": "$0",
                  "capped_Value": "$0",
                  "exemption_Amount": "",
                  "total_assessed": "$19130",
                  "is_proposed": true,
                  "est_tax_without_exemption": "$702.22",
                  "net_Taxable": "$19130"
              },
              {
                  "parcel_id": 2091,
                  "parcel_assessed_year": "",
                  "assessment_Year": 2022,
                  "appraised": "$19130",
                  "land_Value": "$19130",
                  "agricultural_Value": "$0",
                  "improvement_Value": "$0",
                  "capped_Value": "$0",
                  "exemption_Amount": "",
                  "total_assessed": "$19130",
                  "is_proposed": false,
                  "est_tax_without_exemption": "$702.22",
                  "net_Taxable": "$19130"
              }
          ],
          "tax_Information": {
              "parcel_id": 2091,
              "inst_Per_Year": "",
              "due_by_date_one": "08-31-2023",
              "due_by_date_two": "09-30-2023",
              "totalAmt": {
                  "basetax": "$451.40",
                  "basedue": "$6581.02",
                  "duedate1": "$644.64",
                  "duedate2": "$650.02"
              },
              "collectorInfo": [
                  {
                      "name": "Galveston County Tax Office",
                      "account_number": "220914",
                      "payable_to": "Payable To:Galveston County Tax Office",
                      "payee": "Galveston County Tax Office",
                      "mail_To": "722 Moody,Galveston,Texas,77550",
                      "phone_No": "+14097662481",
                      "comments": "",
                      "bill_summary": [
                          {
                              "taxyear": "2022",
                              "basetax": "$225.70",
                              "basedue": "$225.70",
                              "duedate1": "$322.32",
                              "duedate2": "$325.01",
                              "status": "Due"
                          },
                          {
                              "taxyear": "Prior Year",
                              "basetax": "$0",
                              "basedue": "$3064.81",
                              "duedate1": "$0",
                              "duedate2": "$0",
                              "status": "Due"
                          },
                          {
                              "taxyear": "Collector Total:",
                              "basetax": "$225.7",
                              "basedue": "$3290.5099999999998",
                              "duedate1": "$322.32",
                              "duedate2": "$325.01",
                              "status": ""
                          }
                      ]
                  },
                  {
                      "name": "Texas City ISD Tax Office",
                      "account_number": "220914",
                      "payable_to": "Payable To:Texas City ISD Tax Office",
                      "payee": "Texas City ISD Tax Office",
                      "mail_To": "1700 9th Avenue N,Texas City,Texas,77590",
                      "phone_No": "+14099160153",
                      "comments": "",
                      "bill_summary": [
                          {
                              "taxyear": "2022",
                              "basetax": "$476.55",
                              "basedue": "$476.55",
                              "duedate1": "$4035.01",
                              "duedate2": "$325.01",
                              "status": "Due"
                          },
                          {
                              "taxyear": "Prior Year",
                              "basetax": "$0",
                              "basedue": "$3064.81",
                              "duedate1": "$0",
                              "duedate2": "$0",
                              "status": "Due"
                          },
                          {
                              "taxyear": "Collector Total:",
                              "basetax": "$225.7",
                              "basedue": "$3290.5099999999998",
                              "duedate1": "$322.32",
                              "duedate2": "$325.01",
                              "status": ""
                          }
                      ]
                  }
              ]
          },
          "jurisdiction_info": {
              "total_taxrate": "$3.670776",
              "juri_data": [
                  {
                      "collector_name": "Galveston County Tax Office",
                      "tax_rate": "$1.179738",
                      "jurisdiction_data": [
                          {
                              "name": "Texas City",
                              "tax_year": 2022,
                              "tax_rate": "$0.490000",
                              "billyear": 2022,
                              "baseamount": "$93.74",
                              "estimated_tax_without_exemption": "$93.74"
                          },
                          {
                              "name": "Drainage #2",
                              "tax_year": 2022,
                              "tax_rate": "$0.046088",
                              "billyear": 2022,
                              "baseamount": "$8.82",
                              "estimated_tax_without_exemption": "$8.82"
                          },
                          {
                              "name": "Galveston County",
                              "tax_year": 2022,
                              "tax_rate": "$0.367587",
                              "billyear": 2022,
                              "baseamount": "$70.32",
                              "estimated_tax_without_exemption": "$70.32"
                          },
                          {
                              "name": "Coll Of The Mainland",
                              "tax_year": 2022,
                              "tax_rate": "$0.267620",
                              "billyear": 2022,
                              "baseamount": "$51.20",
                              "estimated_tax_without_exemption": "$51.2"
                          },
                          {
                              "name": "Co Road & Flood",
                              "tax_year": 2022,
                              "tax_rate": "$0.008443",
                              "billyear": 2022,
                              "baseamount": "$1.62",
                              "estimated_tax_without_exemption": "$1.62"
                          }
                      ]
                  },
                  {
                      "collector_name": "Texas City ISD Tax Office",
                      "tax_rate": "$2.491038",
                      "jurisdiction_data": [
                          {
                              "name": "La Marque ISD (Dissolved)",
                              "tax_year": 2022,
                              "tax_rate": "$0.000000",
                              "billyear": 2022,
                              "baseamount": "",
                              "estimated_tax_without_exemption": "$0"
                          },
                          {
                              "name": "Texas City ISD",
                              "tax_year": 2022,
                              "tax_rate": "$1.311300",
                              "billyear": 2022,
                              "baseamount": "$250.85",
                              "estimated_tax_without_exemption": "$250.85"
                          }
                      ]
                  }
              ]
          },
          "bonds": [],
          "exemption": [
              {
                  "name": "Texas City",
                  "hom": "20% $5,000 min",
                  "ov65": "$20,000.00",
                  "dp": "$10,000.00",
                  "10to30": "",
                  "31to50": "",
                  "51to70": "",
                  "71to100": ""
              },
              {
                  "name": "Drainage #2",
                  "hom": "",
                  "ov65": "$10,000.00",
                  "dp": "$10,000.00",
                  "10to30": "",
                  "31to50": "",
                  "51to70": "",
                  "71to100": ""
              },
              {
                  "name": "Galveston County",
                  "hom": "20% $5,000 min",
                  "ov65": "$60,000.00",
                  "dp": "$60,000.00",
                  "10to30": "",
                  "31to50": "",
                  "51to70": "",
                  "71to100": ""
              },
              {
                  "name": "Coll Of The Mainland",
                  "hom": "20% $5,000 min",
                  "ov65": "$24,000.00",
                  "dp": "$24,000.00",
                  "10to30": "",
                  "31to50": "",
                  "51to70": "",
                  "71to100": ""
              },
              {
                  "name": "Co Road & Flood",
                  "hom": "20% $5,000 min + $3,000",
                  "ov65": "$60,000.00",
                  "dp": "$60,000.00",
                  "10to30": "",
                  "31to50": "",
                  "51to70": "",
                  "71to100": ""
              },
              {
                  "name": "La Marque ISD (Dissolved)",
                  "hom": "",
                  "ov65": "",
                  "dp": "",
                  "10to30": "",
                  "31to50": "",
                  "51to70": "",
                  "71to100": ""
              },
              {
                  "name": "Texas City ISD",
                  "hom": "20% $40,000 min",
                  "ov65": "$10,000.00",
                  "dp": "$10,000.00",
                  "10to30": "",
                  "31to50": "",
                  "51to70": "",
                  "71to100": ""
              }
          ]
      }
  ],
  "parcel_information_comments": [
      {
          "parcel_cad_id": "211182",
          "parcel_comments": ""
      },
      {
          "parcel_cad_id": "220914",
          "parcel_comments": "<b>Property is currently being assessed as Vacant Land - Please Verify No improved structures are situated on the land.</b><br>"
      }
  ],
  "total_tax_summary_bill_information": {
      "parcel_total_tax_summary": [
          {
              "name": "Galveston County Tax Office",
              "account_No": "211182",
              "payable_to": "Galveston County Tax Office",
              "payee": "Galveston County Tax Office",
              "mail_To": "722 Moody,Galveston,Texas,77550",
              "phone_No": "+14097662481",
              "comments": "",
              "total_bill_summary": [
                  {
                      "bill_name": "Collector Total:",
                      "taxyear": "$2022",
                      "basetax": "$1549.14",
                      "basedue": "$0.00",
                      "duedate1": "$0.00",
                      "duedate2": "$0.00"
                  },
                  {
                      "bill_name": "Grand Collector Total:",
                      "taxyear": "$2022",
                      "basetax": "$0.00",
                      "basedue": "$0.00",
                      "duedate1": "$0.00",
                      "duedate2": "$0.00"
                  },
                  {
                      "bill_name": "Prior Collector Total:",
                      "taxyear": "$2022",
                      "basetax": "$0.00",
                      "basedue": "$0.00",
                      "duedate1": "$0.00",
                      "duedate2": "$0.00"
                  }
              ]
          },
          {
              "name": "Texas City ISD Tax Office",
              "account_No": "211182",
              "payable_to": "Texas City ISD Tax Office",
              "payee": "Texas City ISD Tax Office",
              "mail_To": "1700 9th Avenue N,Texas City,Texas,77590",
              "phone_No": "+14099160153",
              "comments": "",
              "total_bill_summary": [
                  {
                      "bill_name": "Collector Total:",
                      "taxyear": "$2022",
                      "basetax": "$3341.03",
                      "basedue": "$1791.89",
                      "duedate1": "$1306.09",
                      "duedate2": "$0.00"
                  },
                  {
                      "bill_name": "Grand Collector Total:",
                      "taxyear": "$2022",
                      "basetax": "$0.00",
                      "basedue": "$0.00",
                      "duedate1": "$0.00",
                      "duedate2": "$0.00"
                  },
                  {
                      "bill_name": "Prior Collector Total:",
                      "taxyear": "$2022",
                      "basetax": "$0.00",
                      "basedue": "$0.00",
                      "duedate1": "$0.00",
                      "duedate2": "$0.00"
                  }
              ]
          },
          {
              "name": "Galveston County Tax Office",
              "account_No": "220914",
              "payable_to": "Galveston County Tax Office",
              "payee": "Galveston County Tax Office",
              "mail_To": "722 Moody,Galveston,Texas,77550",
              "phone_No": "+14097662481",
              "comments": "",
              "total_bill_summary": [
                  {
                      "bill_name": "Collector Total:",
                      "taxyear": "$2022",
                      "basetax": "$225.70",
                      "basedue": "$225.70",
                      "duedate1": "$322.32",
                      "duedate2": "$325.01"
                  },
                  {
                      "bill_name": "Grand Collector Total:",
                      "taxyear": "$2022",
                      "basetax": "$225.70",
                      "basedue": "$3290.51",
                      "duedate1": "$322.32",
                      "duedate2": "$325.01"
                  },
                  {
                      "bill_name": "Prior Collector Total:",
                      "taxyear": "$2022",
                      "basetax": "$0.00",
                      "basedue": "$3064.81",
                      "duedate1": "$0.00",
                      "duedate2": "$0.00"
                  }
              ]
          },
          {
              "name": "Texas City ISD Tax Office",
              "account_No": "220914",
              "payable_to": "Texas City ISD Tax Office",
              "payee": "Texas City ISD Tax Office",
              "mail_To": "1700 9th Avenue N,Texas City,Texas,77590",
              "phone_No": "+14099160153",
              "comments": "",
              "total_bill_summary": [
                  {
                      "bill_name": "Collector Total:",
                      "taxyear": "$2022",
                      "basetax": "$476.55",
                      "basedue": "$476.55",
                      "duedate1": "$4035.01",
                      "duedate2": "$325.01"
                  },
                  {
                      "bill_name": "Grand Collector Total:",
                      "taxyear": "$2022",
                      "basetax": "$0.00",
                      "basedue": "$0.00",
                      "duedate1": "$0.00",
                      "duedate2": "$0.00"
                  },
                  {
                      "bill_name": "Prior Collector Total:",
                      "taxyear": "$2022",
                      "basetax": "$0.00",
                      "basedue": "$3354.48",
                      "duedate1": "$0.00",
                      "duedate2": "$0.00"
                  }
              ]
          }
      ],
      "parcelids": "211182,220914,545545,545,45245,1545,1545,1545454,54514558,4545145,1545,15,41,5",
      "parcel_total_taxes_summary": {
          "bill_name": "Total Taxes:",
          "total_summary_tax_amount": "$5,818.12",
          "total_summary_total_due": "$12,203.94",
          "total_summary_total_balance": "$5,985.74",
          "total_summary_total_next_balance": "$975.03"
      }
  },
  "hoa_info": [
      {
          "property_address": "718 22ND AVE N TEXAS CITY TX 77590",
          "owner": "OZUNA REBECCA & SALOMON RODRIGUEZ",
          "mailing_address": "718 22ND AVE N,TEXAS CITY,TX,77590-5626",
          "legal_description": "ABST 167 PAGE 4 & 5 LOT 25 BLK 21 MAINLAND PARK SEC D",
          "tax_account_number": "",
          "parcel_id": "211182",
          "acreage": "0.1853",
          "subcode": "S4900",
          "hoa": "HOA NOT FOUND - VERIFY HOA ADDENDUM DOCUMENT",
          "date_verified": "",
          "collection_cycle": "",
          "collector_mgmt": "",
          "contact": "",
          "hoa_phone": "",
          "hoa_email": "",
          "hoa_notes": "<p>RESEARCH CONDUCTED THROUGH VARIOUS RESOURCES SUCH AS DEED RESTRICTIONS, ONLINE INFORMATION, ETC.. HAS INDICATED THIS SUBDIVISION MAY NOT BE PART OF A HOMEOWNERS ASSOCIATION</p><p><br></p><p>*** IF ADDITIONAL INFORMATION IS AVAILABLE IN THE HOA ADDENDUM, OR FROM THE SELLER OR REALTOR/AGENT, PLEASE CONTACT US SO WE MAY REVIEW AND UPDATE OUR DATABASE *****</p>",
          "mgmt_notes": "",
          "hoa_fees": [],
          "hoa_not_found_comments": "",
          "completed_Date": "",
          "hoa_address": "   Texas ",
          "collector_mgmt_address": "    ",
          "hoa_collector_phone": "",
          "hoa_collector_contact": "",
          "hoa_collector_website": "",
          "hoa_collector_email": "",
          "hoa_mgmt_fees": [],
          "hoa_order_fees_list": []
      },
      {
          "property_address": "5414 PHILLIPS TEXAS CITY TX 77591",
          "owner": "MACK EARNEST",
          "mailing_address": "3610 SEALY ST,GALVESTON,TX,77550-4031",
          "legal_description": "ABST 126 PAGE 1 LOT 10 S POLLITT 3RD EXTN",
          "tax_account_number": "",
          "parcel_id": "220914",
          "acreage": "0.2662",
          "subcode": "S5918",
          "hoa": "Researching HOA",
          "date_verified": "",
          "collection_cycle": "",
          "collector_mgmt": "Researching HOA",
          "contact": "",
          "hoa_phone": "",
          "hoa_email": "",
          "hoa_notes": "<b>THE TAX SERVICE IS CURRENTLY RESEARCHING, THROUGH VARIOUS RESOURCES SUCH AS DEED RESTRICTIONS, ONLINE INFORMATION, ETC... TO DETERMINE IF THIS PROPERTY IS PART OF AND IS ASSESSED MAINTENANCE FEES BY A MANDATORY HOMEOWNERS ASSOCIATION. A COMPLETED HOA CERTIFICATE WILL BE EMAILED DIRECTLY TO YOU ONCE RESEARCH HAS BEEN CONCLUDED</b>",
          "mgmt_notes": "",
          "hoa_fees": [],
          "hoa_not_found_comments": "",
          "completed_Date": "",
          "hoa_address": "    ",
          "collector_mgmt_address": "    ",
          "hoa_collector_phone": "",
          "hoa_collector_contact": "",
          "hoa_collector_website": "",
          "hoa_collector_email": "",
          "hoa_mgmt_fees": [],
          "hoa_order_fees_list": []
      }
  ]
}