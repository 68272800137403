import React, { useState } from "react";
import { Alert, Popconfirm } from "antd";
import AppTable from "../AppTable/AppTable";

import { FaPlus, FaStarOfLife } from "react-icons/fa";
import { EditOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { MdOutlineDelete } from "react-icons/md";
import { useEffect } from "react";
import { removeBlank } from "../../Controllers/Functions";
import AppTextArea from "../AppTextArea/AppText";
const randomPass = require("secure-random-password");

export default function MultiBillCycle({
  afterClose = false,
  addFormVal = false,
  initailValArr = [],
  updateContact = false,
  deleteContact = false,
  whileEdit = false,
  _id,
}) {
  const initailVal = {
    bill_cycle: "",
  };
  const [values, setValues] = useState(initailVal);
  const [data, setData] = useState(initailValArr);
  const [addRowToggler, setAddRowToggler] = useState(false);

  const [editId, setEditId] = useState(null);
  const [getErr, setGetErr] = useState(false);

  const { bill_cycle } = values;

  //edit row
  const editHandler = (record) => {
    setEditId(record);
    setAddRowToggler(true);
    setValues(record);
  };

  const columns = [
    {
      title: <span>Description</span>,
      dataIndex: "bill_cycle",
      key: "bill_cycle",
    },

    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      width: 130,
      align: "center",
      render: (_, record) => {
        return (
          <>
            <span>
              <EditOutlined
                style={{ cursor: "pointer" }}
                onClick={() => editHandler(record)}
                size={50}
              />
            </span>
            <Popconfirm
              getPopupContainer={(trigger) => trigger.parentElement}
              title="Are you sure you want to delete？"
              onConfirm={() => {
                deleteHandler(record);
              }}
              placement="topLeft"
              icon={
                <QuestionCircleOutlined
                  style={{
                    color: "red",
                  }}
                />
              }
              okText="Yes"
              cancelText="No"
            >
              <span className={"ms-2 "}>
                <MdOutlineDelete style={{ cursor: "pointer" }} size={15} />
              </span>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const inputs = [
    {
      id: 545785,
      name: "bill_cycle",
      _id: "bill_cycle",
      type: "textarea",
      column: "8",
      value: bill_cycle || "",
      placeholder: "Enter Bill Cycle ",
      errorMessage: "Please enter bill cycle!",
      required: true,
      icon: <FaStarOfLife size={5} color="red" />,
    },
  ];

  function handleSubmit() {
    if (editId) {
      const newData = data.filter((ele) => {
        return (ele?.temp_id || ele?.id) !== (editId?.temp_id || editId?.id);
      });
      updateContact && updateContact(values);
      setData([...newData, values]);
      setAddRowToggler(false);
      setEditId(null);
      setValues(initailVal);
      setGetErr(false);
    } else {
      //remove blank values
      removeBlank(values);
      const randomId = randomPass.randomPassword({
        length: 9,
        characters: randomPass.digits,
      });
      setData([
        ...data,
        {
          ...values,
          temp_id: randomId,
        },
      ]);
      //while edit and ading new record directly
      whileEdit({ ...values, temp_id: randomId });
      setValues(initailVal);
      setAddRowToggler(false);
      setEditId(null);
      setGetErr(false);
    }
  }

  const cancelHandler = () => {
    setValues(initailVal);
    addFormVal(data);
    setEditId(null);
    setAddRowToggler(false);
    setGetErr(false);
  };

  const addNewRecord = () => {
    setAddRowToggler(!addRowToggler);
    setEditId(null);
    setGetErr(false);
  };

  const deleteHandler = (id) => {
    setData(
      data.filter((ele) => {
        return (ele?.id || ele?.temp_id) !== (id?.id || id?.temp_id);
      })
    );
    //sending delete id
    if (id?.id) {
      deleteContact(id);
    }
    setGetErr(false);
  };

  useEffect(() => {
    setAddRowToggler(false);
    setData(initailValArr);
    setValues(initailVal);
    setEditId(null);
    setGetErr(false);
  }, [afterClose]);

  useEffect(() => {
    addFormVal && addFormVal(data);
  }, [data]);

  return (
    <div className="gray-table-col rounded-2 mb-2 p-2" id={_id}>
      <div
        className={`d-flex ${"justify-content-between"} align-items-center my-2 contact-forms`}
      >
        <h6 className="fs-18 fw-bold me-2"> </h6>
        {!addRowToggler && (
          <div className={`add_icon`} onClick={addNewRecord}>
            <FaPlus />
          </div>
        )}
      </div>

      <AppTable
        pagination={false}
        dataSource={data}
        columns={columns}
      />

      {getErr && (
        <div className="mt-3">
          <Alert message={getErr} className="py-1 mx-3" type="error" showIcon />{" "}
        </div>
      )}

      {addRowToggler && (
        <div
          className="row bg-white p-2 mx-auto shadow-sm align-items-center"
          id="addRow"
        >
          {inputs.map((input) => (
            <div key={input}>
              <div className="row">
                <div className="d-flex gap-2 align-items-center">
                  <label htmlFor="">Bill Cycle</label>
                  <AppTextArea
                    key={input.type}
                    {...input}
                    onChange={(e) => {
                      setValues({ ...values, bill_cycle: e.target.value });
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
          <div className="col-12 col-lg-1 mt-3 p-0">
            <div className="d-flex">
              <span
                onClick={handleSubmit}
                className={`btn btn-primary  btn-sm edit-buttons ${
                  !bill_cycle ? "disabled" : "btn-success"
                } `}
              >
                {editId?.id ? "Update" : "Save"}
              </span>
              <span
                onClick={cancelHandler}
                className="btn btn-primary ms-1 btn-sm edit-buttons"
              >
                Cancel
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
