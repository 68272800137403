import { useEffect, useState } from "react";
import { Form, Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import "./MyAccount.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Result } from "antd";
import FormInput from "../../Components/AppInput/AppInput";
import MyButton from "../../Components/AppButton/AppButton";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "antd";
import {
  useGetUserQuery,
  useUpdateUserInfoMutation,
} from "../../Redux/Services/User";
import {
  userDetailsQuickUpdateAction,
  userLoginAction,
} from "../../Redux/reducers/authReducer";
import AppSpinner from "../../Components/AppSpinner/AppSpinner";
import {
  useFirstLoginUpdatePassMutation,
  useUserResetPasswordMutation,
} from "../../Redux/Services/authApi";
import { useCookies } from "react-cookie";

const CryptoJS = require("crypto-js");
const MyAccount = ({ resetPass, setShowHide, firstTimePass, afterClose }) => {
  const [values, setValues] = useState({});
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState("");
  const [, setCookies, removeCookie] = useCookies(["user"]);
  // const [, setRefresh] = useCookies(["refresh"]);
  //react router dom
  const { reset_token, user_id } = useParams();
  //redux data store
  const { user, token } = useSelector((state) => state.auth);
  //calling rtk query hook
  const { data, isLoading, error, isSuccess, isFetching, refetch } =
    useGetUserQuery({
      id: user_id || (token && user.user_id),
      token: reset_token || (token && token),
    });

  useEffect(() => {
    refetch();
  }, [afterClose]);
  //default values useEffect

  //user data update
  const updateUserData = useUpdateUserInfoMutation();
  const [updateUser] = updateUserData;
  const dispatch = useDispatch();

  //user resetPassword
  const userResetPass = useUserResetPasswordMutation();
  const [userResetPassword] = userResetPass;

  //user firstTime password update
  const firstTimePassApi = useFirstLoginUpdatePassMutation();
  const [firstLoginUpdatePass] = firstTimePassApi;
  //labellist store
  const { labelList } = useSelector((state) => state.labelList);
  //useEffect
  useEffect(() => {
    if (isSuccess) {
      const { middle_name, first_name, last_name, company_name, email } =
        data.data[0];
      setValues({
        ...values,
        first_name: first_name,
        middle_name: middle_name,
        last_name: last_name,
        company_name: company_name,
        email: email,
      });
    }
  }, [isSuccess]);

  //useEffect after updates values
  useEffect(() => {
    if (updateUserData[1].isSuccess) {
      removeCookie("user");
      setSuccess(updateUserData[1].data?.message);
      const { first_name, last_name } = values;
      dispatch(
        userLoginAction({
          token: token,
          user: { ...user, first_name: first_name, last_name: last_name },
        })
      );
      //updating quick detail with user details
      dispatch(userDetailsQuickUpdateAction({ first_name: first_name }));
      const localData = {
        access_token: token,
        data: [{ ...user, first_name: first_name, last_name: last_name }],
      };

      let encrypted = CryptoJS.AES.encrypt(
        JSON.stringify(localData),
        "$2b$10$hcPg5zgrb0tt8cg9UPgT1ex7c4VTc22bALT8pjNcfNMRbXZToEI46"
      ).toString();

      setCookies("user", encrypted, {
        path: "/",
        maxAge: 14400,
      });
      //close to modal
      setTimeout(() => {
        setShowHide(false);
        setSuccess(false);
      }, 3000);
    }
  }, [updateUserData[1].isLoading]);

  //after restpassword
  useEffect(() => {
    if (userResetPass[1].isSuccess) {
      setSuccess(userResetPass[1].data.message);
      dispatch(userLoginAction([]));
      //updating quick detail with user details
      dispatch(userDetailsQuickUpdateAction({ first_name: "fsf" }));
    }
  }, [userResetPass[1].isLoading]);

  //useNavigate
  const navigate = useNavigate();
  //after restpassword
  useEffect(() => {
    if (firstTimePassApi[1].isSuccess) {
      setSuccess(firstTimePassApi[1].data.message);
    }
    if (firstTimePassApi[1].isError) {
      setErrors(firstTimePassApi[1].error?.data?.error?.password[0])
    }
  }, [firstTimePassApi[1].isLoading]);

  const disableInputs = [
    {
      id: 1,
      _id: "company_name",
      name: "company_name",
      type: "text",
      row: true,
      value: values.company_name || "",
      column: "6",
      placeholder: "Company Name",
      errorMessage: "Please enter your company name!",
      label: labelList["cs.myaccount.company"],
      required: true,
      disabled: true,
    },
    {
      id: 2,
      name: "email",
      _id: "email",
      type: "email",
      value: values.email || "",
      column: "6",
      placeholder: labelList["cs.myaccount.username"],
      errorMessage: "Please enter your username!",
      label: labelList["cs.myaccount.username"],
      required: true,
      disabled: true,
    },
    {
      id: 3,
      name: "first_name",
      type: "text",
      _id: "first_name",
      value: values.first_name || "",
      column: "4",
      placeholder: "First Name",
      errorMessage: "Please enter your firstname!",
      required: true,
      subhead: "Contact",
      disabled: resetPass || firstTimePass,
    },
    {
      id: 4,
      name: "middle_name",
      _id: "middle_name",
      type: "text",
      column: "4",
      value: values.middle_name || "",
      placeholder: "Middle Name",
      errorMessage: "Please enter your middlename!",
      required: false,
      disabled: resetPass || firstTimePass,
    },
    {
      id: 5,
      name: "last_name",
      type: "text",
      _id: "last_name",
      column: "4",
      value: values.last_name || "",
      placeholder: "Lastname",
      placeholder: "Last Name",
      required: true,
      disabled: resetPass || firstTimePass,
    },
  ];
  const inputs = [
    {
      id: 6,
      name: "current_password",
      type: "password",
      _id: "current_password",
      column: "12",
      value: values.current_password || "",
      placeholder: "Current Password",
      errorMessage: "Please enter your current password!",
      // label: "Current Password",
      subhead: "Enter New Password",
      required: values.password && true,
      hide: resetPass || firstTimePass,
    },
    {
      id: 7,
      name: "password",
      type: "password",
      _id: "password",
      value: values.password || "",
      placeholder: "Enter New Password",
      column: "12",
      errorMessage:
        "Password must be at least 8 characters in length and must include at least One Upper Case Letter, One Number, and One Special Character!",
      // label: "New Password",
      pattern: `^(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`,
      required: values.current_password && true,
    },
    {
      id: 8,
      name: "password_verfiy",
      type: "password",
      _id: "password_verfiy",
      column: "12",
      value: values.password_verfiy || "",
      placeholder: "Enter Confirm Password",
      errorMessage: "Please confirm password!",
      pattern: `^(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`,
      required: values.current_password && true,
    },
  ];
  //reset password page and my accound form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (values.password !== values.password_verfiy) {
      setErrors("Passwords Do Not Match Please Confirm!");
    } else {
      var userData = values;
      delete userData.current_password;
      delete userData.password_verfiy;

      if (!resetPass && !firstTimePass) {
        //when my account updatations
        updateUser({ data: userData, id: user.user_id, token: token });
      } else if (firstTimePass) {
        //when user firstTime Login
        firstLoginUpdatePass({
          password: values.password,
          id: user.user_id,
          token: token,
        });
      } else {
        //when resetpassword updatations
        userResetPassword({ password: values.password, token: reset_token });
      }
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    if (e.target.type !== "password") {
      setValues({
        ...values,
        [name]: value.replace(/[^a-zA-Z" "]/gi, ""),
      });
    }
    setValues({
      ...values,
      [name]: value,
    });
    setErrors("");
  };
  const handleReset = (e) => {
    e.preventDefault();
    const { middle_name, first_name, last_name } = data.data[0];
    setValues({
      ...values,
      first_name: first_name,
      middle_name: middle_name,
      last_name: last_name,
      password: "",
      current_password: "",
      password_verfiy: "",
    });
  };
  const loginHandler = () => {
    navigate("/");
    dispatch(userLoginAction([]));
    //updating quick detail with user details
    dispatch(userDetailsQuickUpdateAction({ first_name: "shf" }));
  };
  console.log("path name", window.location.pathname)
  return (
    <>
      <Container className="my-account  ">
        {errors && (
          <div className="my-4">
            <Alert message={errors} type="error" closable showIcon />
          </div>
        )}
        <Row>
          <Col lg="12" className="mx-auto">
            <div>
              <div className=" border-bottom border-2 align-items-center d-flex justify-content-between">
                {window.location.pathname.split("/")[1] === "first-time-login" ?
                  <div className="d-flex flex-column gap-2">
                    <h5 >SET UP A NEW PASSWORD  </h5>
                    <h5>My Account</h5>
                  </div>

                  : <h5> My Account </h5>}
                {/* <AvtarPro initialState={"fhjhfdsj"}/> */}
              </div>

              <Form onSubmit={handleSubmit}>
                {isFetching && <AppSpinner />}
                <div className={`${isFetching ? "invisible" : ""} `}>
                  <Row>
                    {disableInputs.map((input) => (
                      <FormInput
                        key={input.id}
                        {...input}
                        onChange={onChange}
                      />
                    ))}
                  </Row>
                  {success ? (
                    <>
                      <Result status="success" title={success} />

                      {(firstTimePass || resetPass) && (
                        <p className="text-center">
                          <span
                            onClick={loginHandler}
                            style={{ cursor: "pointer" }}
                            className="login-back text-decoration-none"
                          >
                            <FaArrowLeft /> Back to Login
                          </span>
                        </p>
                      )}
                    </>
                  ) : (
                    <>
                      <Row>
                        {inputs.map((input) => (
                          <FormInput
                            key={input.id}
                            {...input}
                            onChange={onChange}
                          />
                        ))}
                      </Row>
                      <Col className="my-3">
                        <div className="d-flex justify-content-center">
                          {resetPass || firstTimePass ? (
                            <>
                              <MyButton
                                isLoading={userResetPass[1].isLoading}
                                type="submit"
                                title={"UPDATE PASSWORD"}
                                variant={"primary-md"}
                              />
                            </>
                          ) : (
                            <>
                              <div className="me-md-2 ">
                                <MyButton
                                  onClick={handleReset}
                                  type="button"
                                  title={"CLEAR"}
                                  variant={"secondary-md"}
                                />
                              </div>
                              <MyButton
                                isLoading={updateUserData[1].isLoading}
                                type="submit"
                                title={"SAVE"}
                                variant={"primary-md"}
                              />
                            </>
                          )}
                        </div>
                      </Col>
                    </>
                  )}
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container >
    </>
  );
};

export default MyAccount;
