import { createSlice } from "@reduxjs/toolkit";
const OrderData = createSlice({
    name: "OrderData",
    initialState: {
        orderDATA: {},
        isDownloadingCertificate: false,
        bakendAutomation: false,
        showalertAssign: true,
        viewPdfDocument: null,
        orderlistPagination: { page_number: 1, page_size: 200, pageCount: 600 }
    },
    reducers: {
        addOrderData: (state, action) => {
            state.orderDATA = action.payload;
        },
        bakendAutomationHandler: (state, action) => {
            state.bakendAutomation = action.payload
        },
        showalertAssignHandler: (state, action) => {
            state.showalertAssign = action.payload
        },
        certificateDownloadLoadingHandler: (state, action) => {
            state.isDownloadingCertificate = action.payload
        },
        viewPdfDocuementHandler: (state, action) => {
            state.viewPdfDocument = action.payload
        },
        orderPaginationHandler: (state, action) => {
            state.orderlistPagination = action.payload
        }
    },
});

export const { addOrderData, bakendAutomationHandler, showalertAssignHandler, certificateDownloadLoadingHandler, viewPdfDocuementHandler, orderPaginationHandler } = OrderData.actions;

export default OrderData.reducer;


