import { createSlice } from "@reduxjs/toolkit";

const hoaTypeList = createSlice({
  name: "hoaTypeList",
  initialState: {
    hoaTypeList: [],
  },
  reducers: {
    hoaTypeListAction: (state, action) => {
      state.cycleList = action.payload;
    },
  },
});

export const {hoaTypeListAction} = hoaTypeList.actions;

export default hoaTypeList.reducer;
