import { Table } from "antd";
import React, { useMemo } from "react";

const AutomationData = ({ data = [] }) => {
    const columns = useMemo(() => [
        {
            title: "Fee",
            dataIndex: "fee",
            align: "center",
        },
        {
            title: "Tax Due",
            dataIndex: "taxDue",
            align: "center",
        },
        {
            title: "Tax Due Date2",
            dataIndex: "taxDue2",
            align: "center",
        },
        {
            title: "Due Date",
            dataIndex: "dueDate",
            align: "center",
        },
        {
            title: "Due Date2",
            dataIndex: "dueDate2",
            align: "center",
        },
        {
            title: "Tax Rate",
            dataIndex: "taxRate",
            align: "center",
        },
        {
            title: "Tax Type",
            dataIndex: "taxType",
            align: "center",
        },
        {
            title: "Tax Year",
            dataIndex: "taxYear",
            align: "center",
        },
        {
            title: "Taxable",
            dataIndex: "taxable",
            align: "center",
        },
        {
            title: "Assessed",
            dataIndex: "assessed",
            align: "center",
        },
        {
            title: "Total Tax",
            dataIndex: "totalTax",
            align: "center",
        },
        {
            title: "Exemption",
            dataIndex: "exemption",
            align: "center",
        },
        {
            title: "Base Tax Due",
            dataIndex: "baseTaxDue",
            align: "center",
        },
        {
            title: "Estimated Tax",
            dataIndex: "estimatedTax",
            align: "center",
        },
        {
            title: "Jurisdiction",
            dataIndex: "jurisdiction",
            align: "center",
        },
        {
            title: "Jurisdiction Code",
            dataIndex: "jurisdictionCode",
            align: "center",
        },
        {
            title: "Penalty & Interest",
            dataIndex: "penaltyAndInterest",
            align: "center",
        },
    ], []);
    // const dataSource = [{
    //     "fee": "0.00",
    //     "taxDue": "0.00",
    //     "dueDate": "06/30/2023",
    //     "taxDue2": "",
    //     "taxRate": "0.465000",
    //     "taxType": "Ad Valorem",
    //     "taxYear": "2022",
    //     "taxable": "0",
    //     "assessed": "240885",
    //     "dueDate2": "07/31/2023",
    //     "totalTax": "0.00",
    //     "exemption": "",
    //     "baseTaxDue": "0.00",
    //     "estimatedTax": "0.00",
    //     "jurisdiction": "CITY OF BERTRAM",
    //     "jurisdictionCode": "CBE",
    //     "penaltyAndInterest": "0.00"
    // },]
    return <div className="gray-table-col  mt-4">
        <Table dataSource={data || []} pagination={false} columns={columns} />
    </div>;
};

export default AutomationData;
