import { Checkbox, Divider, Empty } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import "./MultiCheckList.css";
const CheckboxGroup = Checkbox.Group;
const _ = require("lodash");

export default function MultiCheckList({
  maxHeight = 110,
  title = false,
  afterClose = false,
  plainOptions = [],
  defaultCheckedList = [],
  getSelectVal = false,
  heading = false,
  required = false,
  disabled = false,
  checkbox = true,
  placeholder = "",
  clearValues = false
}) {

  const [checkedList, setCheckedList] = useState(plainOptions);
  const [indeterminate, setIndeterminate] = useState(null);
  const [checkAll, setCheckAll] = useState(null);
  const [search, setSearch] = useState("");
  const onChange = (list) => {
    if (checkbox) {
      setCheckedList(list);

      setCheckAll(list.length === plainOptions.length);

      //getting values with id
      const res = [];
      _.filter(plainOptions, (v) => _.includes(list, v.name)).map(
        ({ id, ...rest }) => {
          res.push(id);
        }
      );
      setIndeterminate(!!list.length && list.length < plainOptions.length);
      getSelectVal && getSelectVal(res);
    }
  };
  //storing selectd values
  const res = [];
  _.filter(plainOptions, (v) => _.includes(defaultCheckedList, v.id)).map(
    ({ name, ...rest }) => {
      res.push(name);
    }
  );

  const onCheckAllChange = (e) => {
    setCheckedList(
      e.target.checked
        ? plainOptions.map(({ id, name }) => {
            return name;
          })
        : []
    );

    const res = [];
    plainOptions.map(({ id, name }) => {
      res.push(id);
    });

    getSelectVal && getSelectVal(e.target.checked ? res : []);

    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  useEffect(() => {
    setCheckedList(res);
    if (res.length === plainOptions.length) {
      setCheckAll(true);
      setIndeterminate(false);
    } else if (res.length !== plainOptions.length) {
      setIndeterminate(true);
      setCheckAll(false);
    }
    if (defaultCheckedList.length === 0) {
      setIndeterminate(false);
      setCheckAll(false);
    }
  }, [afterClose, clearValues]);

  // useEffect(() => {
  //   if (defaultCheckedList.length === 0) {
  //     setIndeterminate(false);
  //     setCheckAll(false);
  //   }
  //   if (defaultCheckedList.length === plainOptions.length) {
  //     setCheckAll(true);
  //     setIndeterminate(false);
  //   } else if (
  //     defaultCheckedList.length !== plainOptions.length &&
  //     defaultCheckedList.length > 1
  //   ) {
  //     setIndeterminate(true);
  //     setCheckAll(false);
  //   }
  // }, [defaultCheckedList]);

  const newData = _.uniq(
    plainOptions.map(({ id, name }) => {
      return name;
    })
  );

  return (
    <>
      <label className="mt-3  heading-5 text-dark">
        {heading || ""}
        {required && <span className="text-danger">*</span>}
      </label>
      <div className="treeselect-input-box p-1 my-2 position-relative">
        <input
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          value={search || ""}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          type="text"
          name="multislect-tree-selct"
          placeholder={`Search ${placeholder || ""}`}
          className=" py-0  w-100 ms-3 treeselect-input"
        />
        <div className="search-icon-tree ">
          <FaSearch color="lightgray" />
        </div>
      </div>
      <div className={`border p-2 ${!checkbox ? "show-check" : ""}`}>
        {plainOptions.length > 0 ? (
          <>
            {checkbox && (
              <>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  {title || "Select All"}
                </Checkbox>
                <Divider style={{ margin: "5px 0px" }} />
              </>
            )}
          </>
        ) : (
          <div className="my-2">
            <Empty description={<span>{heading} List Not Found</span>} />
          </div>
        )}

        <CheckboxGroup
          options={newData.filter((ele) => {
            return ele.toLowerCase().includes(search.toLowerCase());
          })}
          value={checkedList}
          onChange={onChange}
          style={{ maxHeight: maxHeight, overflowY: "auto" }}
        />
      </div>
    </>
  );
}
