import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json");
export const vendors = createApi({
  reducerPath: "vendors",
  tagTypes: ["vendors"],
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  endpoints: (builder) => ({
    vendorsList: builder.query({
      query: (token) => ({
        url: `/vendor-list`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ["vendors"],
    }),
    integrationList: builder.mutation({
      query: ({token}) => ({
        url: `/integrationbridge-list`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    addVendors: builder.mutation({
      query: (data) => ({
        url: `/add-vendor`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["vendors"],
    }),
   integrationWiseCustomerList : builder.mutation({
      query: (data) => ({
        url: `/integrationbridge-wise-customer-list `,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    editVendors: builder.query({
      query: (data) => ({
        url: `/edit-vendor/${data.id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    updateVendors: builder.mutation({
      query: (data) => ({
        url: `/update-vendor/${data.id}`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["vendors"],
    }),
    updateVendorsStatus: builder.mutation({
      query: (data) => ({
        url: `/update-vendor-status/${data.id}`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["vendors"],
    }),
    updateVendorsContacts: builder.mutation({
      query: (data) => ({
        url: `/update-vendor-contact/${data.id}`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    deleteVendorsContacts: builder.mutation({
      query: (data) => ({
        url: `/delete-vendor-contact/${data.id}`,
        method: "POST",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    addvendorContact: builder.mutation({
      query: (data) => ({
        url: `/add-vendor-contact`,
        method: "POST",
        mode: "cors",
        body:data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    vendorCustomerList: builder.query({
      query: (data) => ({
        url: `/vendor-customer-branch-list`,
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
  }),
});
export const {
  useVendorsListQuery,
  useAddVendorsMutation,
  useEditVendorsQuery,
  useUpdateVendorsMutation,
  useUpdateVendorsStatusMutation,
  // useVendorCustomerListQuery,
  useUpdateVendorsContactsMutation,
  useDeleteVendorsContactsMutation,
  useAddvendorContactMutation,
  useIntegrationListMutation,
  useIntegrationWiseCustomerListMutation,
  
} = vendors;
