import React, { useState } from "react";
import { Alert,  Popconfirm } from "antd";
import AppTable from "../AppTable/AppTable";
import { FaPlus, FaStarOfLife } from "react-icons/fa";
import {
  EditOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import AppInput from "../AppInput/AppInput";
import { MdOutlineDelete } from "react-icons/md";
import { useEffect } from "react";
import { removeBlank } from "../../Controllers/Functions";
const randomPass = require("secure-random-password");

export default function MultiLinkParcels({
  afterClose = false,
  addFormVal = false,
  initailValArr = [],
  updateLinkParcels = false,
  deleteLinkParcels = false,
  whileEdit = false,
  disabled = false,
}) {
  const initailVal = {
    linked_parcel: "",
  };
  const [values, setValues] = useState(initailVal);
  const [addRowToggler, setAddRowToggler] = useState(false);
  const [data, setData] = useState(initailValArr);
  const [editId, setEditId] = useState(null);
  const [getErr, setGetErr] = useState(false);
  const { linked_parcel } = values;
  //edit row
  const editHandler = (record) => {
    setEditId(record);
    setAddRowToggler(true);
    setValues(record);
  };
  const columns = [
    {
      title: <span>Name</span>,
      dataIndex: "linked_parcel",
      key: "linked_parcel",
    },

    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      align: "center",
      className: `${!disabled ? "" : "d-none"}`,
      width: !disabled && 50,
      render: (_, record) => {
        return (
          <>
            <span>
              <EditOutlined
                style={{ cursor: "pointer" }}
                onClick={() => editHandler(record)}
                size={50}
              />
            </span>
            <Popconfirm
              getPopupContainer={(trigger) => trigger.parentElement}
              title="Are you sure you want to delete？"
              onConfirm={() => {
                deleteHandler(record);
              }}
              placement="topLeft"
              icon={
                <QuestionCircleOutlined
                  style={{
                    color: "red",
                  }}
                />
              }
              okText="Yes"
              cancelText="No"
            >
              <span className={"ms-2 "}>
                <MdOutlineDelete style={{ cursor: "pointer" }} size={15} />
              </span>
            </Popconfirm>
          </>
        );
      },
    },
  ];
  const inputs = [
    {
      id: 545785,
      name: "linked_parcel",
      type: "text",
      column: "12",
      value: linked_parcel || "",
      placeholder: "Enter Linked Parcel Name",
      errorMessage: "Please  linked parcel name!",
      required: true,
      icon: <FaStarOfLife size={5} color="red" />,
    },
  ];
  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    setGetErr(false);
  };

  //sending request

  const handleSubmit = () => {
    if (editId) {
      const newData = data.filter((ele) => {
        return (ele.temp_id || ele.id) !== (editId.temp_id || editId.id);
      });

      setData([...newData, values]);
      updateLinkParcels && updateLinkParcels(values);
      setAddRowToggler(false);
      setEditId(null);
      setValues(initailVal);
    } else {
      //remove blank values
      removeBlank(values);
      const randomId = randomPass.randomPassword({
        length: 9,
        characters: randomPass.digits,
      });
      setData([
        ...data,
        {
          ...values,
          temp_id: randomId,
        },
      ]);
      //while edit and ading new record directly
      whileEdit && whileEdit({ ...values, temp_id: randomId });
      setValues(initailVal);

      setAddRowToggler(false);
    }
  };
  const addNewRecord = () => {
    setAddRowToggler(!addRowToggler);
    setEditId(null);
    setGetErr(false);
  };

  const cancelHandler = () => {
    setValues(initailVal);
    addFormVal(data);
    setEditId(null);
    setAddRowToggler(false);
    setGetErr(false);
  };

  const deleteHandler = (id) => {
    setData(
      data.filter((ele) => {
        return (ele?.id || ele?.temp_id) !== (id?.id || id?.temp_id);
      })
    );
    //sending delete id
    deleteLinkParcels && deleteLinkParcels(id);
    setGetErr(false);
  };

  useEffect(() => {
    setAddRowToggler(false);
    setData(initailValArr);
    setValues(initailVal);
    setEditId(null);
    setGetErr(false);
  }, [afterClose]);

  useEffect(() => {
    addFormVal && addFormVal(data);
  }, [data]);

  return (
    <div className="gray-table-col  mb-2 p-2">
      <div
        className={`d-flex ${"justify-content-between"} align-items-center my-2 contact-forms`}
      >
        <h6 className="heading-5 text-dark fw-bold me-2">Linked Parcels</h6>
        {!addRowToggler && !disabled && (
          <div className={`add_icon`} onClick={addNewRecord}>
            <FaPlus />
          </div>
        )}
      </div>

      <AppTable pagination={false} dataSource={data} columns={columns} />

      {getErr && (
        <div className="mt-3">
          <Alert message={getErr} className="py-1 mx-3" type="error" showIcon />{" "}
        </div>
      )}
      {/* //loading  */}
      {/* {emailCheckApi[1].isLoading && <AppSpinner size="small" />} */}

      {addRowToggler && (
        <div className="row  p-2 mx-auto shadow-sm" id="addRow">
          {inputs.map((input) => (
            <>
              {input.type === "text" && (
                <AppInput
                  defaultSet={afterClose}
                  key={input.id}
                  {...input}
                  value={values[input.name]}
                  onChange={onChange}
                />
              )}
            </>
          ))}
          <div className="col-12 col-lg-3 mt-3 p-0">
            <div className="d-flex">
              <span
                onClick={handleSubmit}
                className={`btn btn-primary  btn-sm edit-buttons ${
                  !linked_parcel ? "disabled" : "btn-success"
                } `}
              >
                {editId?.id ? "Update" : "Save"}
              </span>
              <span
                onClick={cancelHandler}
                className="btn btn-primary ms-1 btn-sm edit-buttons"
              >
                Cancel
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
