import AppTable from "../../../../Components/AppTable/AppTable";
import AppModal from "../../../../Components/AppModal/AppModal";
import { CustomerModal } from "./CustomerModal";
import { EditOutlined } from "@ant-design/icons";
import {
  FaPlus,
  FaSearch,
  FaSortAmountDown,
  FaSortAmountUp,
} from "react-icons/fa";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  useCustomerListQuery,
  useUpdateCustomerStatusMutation,
} from "../../../../Redux/Services/Customers";
import AppSpinner from "../../../../Components/AppSpinner/AppSpinner";
import {Input, notification, Switch } from "antd";

import { useEffect } from "react";
import SearchColumns from "../../../../Components/SearchColumns";

export const Customer = ({ refresh, updateTab }) => {
  const [editId, setEditId] = useState(null);
  const [showHide, setShowHide] = useState(null);
  const [data, setData] = useState();
  const [search, setSearch] = useState("");
  const [initialStateVal, setInitialStateVal] = useState(false);
  //set  ascend or descend sorter state
  const [getsortorder, setSortOrder] = useState(false);
  const [getsortfield, setSortField] = useState("");
  const sort = (sortOrder, sortfield) => {
    if (sortOrder) {
      setSortOrder(sortOrder);
      setSortField(sortfield);
    } else {
      setSortOrder(false);
    }
  };
  //customer
  const [customerValues] = useState({
    vendor_id: "",
    customer_type: "",
    name: "",
    address_one: "",
    address_two: "",
    state_id: "",
    city: "",
    // county_id: "",
    is_private_label: false,
    customer_email: "",
    phone_number: "",
    website: "",
    is_active: true,
    zip_five: "",
    private_label_display_name: "",
    logo: "",
    county_id_list: [],
    customer_product_price: [],
    product_list_values: [],
    contact_list_values: [],
    branch_name: "",
    customer_product_price_values: [],
  });

  //customer list fetch with rtk query

  const AddVendorHandler = () => {
    setShowHide(true);
    setEditId(null);
  };

  function afterClose() {
    setInitialStateVal(!initialStateVal);
  }

  function closeModal(data) {
    setShowHide(data);
  }

  //redux data store
  const { token, user } = useSelector((state) => state.auth);

  const { customerModule,tableColumn} = useSelector((state) => state.flagRoles);
  //calling rtk query hook for geting customerType list
  const Customer = useCustomerListQuery(token);

  //calling rtk query hook for geting VendorsType list
  const updateCustomerStatusApi = useUpdateCustomerStatusMutation();
  const [updateCustomerStatus] = updateCustomerStatusApi;

  //edit vendor module
  const editHandler = (e) => {
    setShowHide(true);
    // //sending id to edit vendor modal
    setEditId(e.id);
  };
  //create function for customer fields
  function matchColumn(value) {
    // if (authColumn.indexOf(value) === -1) {
    //   return false;
    // } else {
    //   return true;
    // }
  }
 
  const columns = [
  
    {
      title: (
        <span>
          Vendor Name{" "}
          {getsortfield === "vendor_name" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "vendor_name" ? (
              <span className="sort-svg">
            <FaSortAmountUp className="text-info" /></span>
            ) : getsortorder === "descend" && getsortfield === "vendor_name" ? (
                <span className="sort-svg">
            <FaSortAmountDown className="text-info" /></span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "vendor_name",
      key: "vendor_name",
      className: `${tableColumn[0].vendor ?" " :"d-none border-style" }`,
      align: "center",
      sorter: (a, b) => a.vendor_name.localeCompare(b.vendor_name),
      width: matchColumn(" ") && 200,
      ...SearchColumns("vendor_name", "Vendor Name"),
    },
    {
      title: (
        <span>
          Customer Name{" "}
          {getsortfield === "name" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "name" ? (
              <span className="sort-svg">
            <FaSortAmountUp className="text-info" /></span>
            ) : getsortorder === "descend" && getsortfield === "name" ? (
                <span className="sort-svg">
            <FaSortAmountDown className="text-info" /></span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "name",
      className: `${tableColumn[1].customer ? tableColumn[0].vendor ?" ":"border-style": "d-none"}`,
      key: "name",
      align: "center",
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: matchColumn(" ") && 200,
      ...SearchColumns("name", "Customer Name"),
    },

    {
      title: (
        <span>
          State Name{" "}
          {getsortfield === "state_name" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "state_name" ? (
              <span className="sort-svg">
            <FaSortAmountUp className="text-info" /></span>
            ) : getsortorder === "descend" && getsortfield === "state_name" ? (
                <span className="sort-svg">
                  <FaSortAmountDown className="text-info" />
                  </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "state_name",
      className: `${customerModule[2].active_status ? "": "border-endstyle"}`,
      key: "state_name",
      align: "center",
      sorter: (a, b) => a.state_name.localeCompare(b.state_name),
       width: matchColumn(" ") && 200,
      ...SearchColumns("state_name", "State Name"),
    },
    {
      title: <span>Active Status</span>,
      className: `${customerModule[2].active_status ? "" : "d-none"}`,
      dataIndex: "is_active",
      key: "is_active",
      align: "center",
      width: matchColumn(" ") && 100,
      render: (value, row) => (
        <div className="d-flex justify-content-center">
          <Switch
            onClick={(e) => switchHandler(e, row)}
            className="my-switch"
            size="small"
            checked={value}
          />
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "edit",
      align: "center",
      className: `${customerModule[1].action ? "" : "d-none"}`,
      width: matchColumn(" ") && 50,
      key: "edit",
      render: (value, row) => {
        return (
          <>{<EditOutlined size={50} onClick={() => editHandler(row)} />}</>
        );
      },
    },
  ];
 
  useEffect(() => {
    if (Customer.isSuccess) {
      const newData = Customer.data?.data.map((ele) => {
        return { ...ele, state_name: ele.state_name || " " };
      });
      setData(newData);
    }
  }, [Customer]);

  //switch handler
  function switchHandler(value, row) {
    const data = {
      is_active: value === true ? 1 : 0,
      updated_by: user?.user_id,
    };
    updateCustomerStatus({
      data,
      id: row.id,
      token: token,
    });
  }
  //update Customers details
  useEffect(() => {
    if (updateCustomerStatusApi[1].isSuccess) {
      ///after update details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: updateCustomerStatusApi[1].data?.message,
      });
    }
    if (updateCustomerStatusApi[1].error) {
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description:
          updateCustomerStatusApi[1].error.data.message ||
          updateCustomerStatusApi[1].error.error ||
          updateCustomerStatusApi[1].error.data?.msg.sqlMessage,
      });
    }
  }, [updateCustomerStatusApi[1].isLoading]);

  useEffect(() => {
    setSearch("");
    //request
    if (refresh === "customers") {
      Customer.refetch();
    }
  }, [refresh]);
  return (
    <>
      {Customer.isLoading ? (
        <AppSpinner />
      ) : (
        <>
          <div className="d-flex align-items-center float-end w-25 mb-1 ">
            <AppModal
              confirmProp={true}
              showModal={showHide}
              onClose={closeModal}
              afterClose={afterClose}
              width={"90%"}
              height={500}
              element={
                <CustomerModal
                  //passing tab of customers
                  updateTab={updateTab}
                  //passing row customer editId
                  editId={editId}
                  initialValues={customerValues}
                  setShowHide={setShowHide}
                  //passing deafult dependency to initial state
                  afterClose={initialStateVal}
                />
              }
            />

            {/* changing input values globaly  */}
            <div className=" me-1 w-100 ">
              <Input
                placeholder="Search here"
                className="global-search "
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                prefix={<FaSearch color="lightgray" />}
              />
              </div>
              {customerModule.map((item,index) => {
							if (item.add_button) {
								return (
                  <div key={index} className="add_icon" onClick={AddVendorHandler}>
										<FaPlus />
									</div>
								);
                }
                return null;
						})}
          </div>
          <div style={{ clear: "both" }}></div>
          <AppTable
            sort={sort}
            loading={updateCustomerStatusApi[1].isLoading}
            search={search}
            dataSource={data}
            columns={columns}
          />
        </>
      )}
    </>
  );
};
