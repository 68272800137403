import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useDispatch } from "react-redux";
import { bakendAutomationHandler, certificateDownloadLoadingHandler } from "../Redux/reducers/Order";
import { useState } from "react";
const config = require("../config.json")

const useAutomatiomStatusCheck = (editId, token, callback, setStatus, storeCertificate, withoutGenerateCerticate) => {
    const dispatch = useDispatch()
    const [isLoopRunning, setIsLoopRunning] = useState(false)
    const callStatus = async (orderID) => {
        try {
            if (!orderID) return null
            const { data: { data, status_id, generate_certificate_flag }, } = await axios.get(
                `${config.url}/check-automation-flag-status/${orderID}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            return { data, status_id, generate_certificate_flag };
        } catch (error) {
            console.log("Automation error", error)
        }
    };
    // const [bakendAutomation, setBakendAutomation] = useState(false);

    const { mutateAsync: mutateAsyncCallBakendAutomation, } = useMutation(callStatus, {
        async onSuccess({ data, status_id, generate_certificate_flag }) {
            if (data) {
                setIsLoopRunning(true)
            }
            if (callback && !data) {

                if (isLoopRunning && !data && generate_certificate_flag) {
                    await withoutGenerateCerticate()
                }
                setIsLoopRunning(false)
                if (setStatus) {
                    setStatus(status_id)
                }

                // //if certifciate store in db if flag is false
                if (storeCertificate && !generate_certificate_flag) {
                    //add delay to wait for certificate status to be updated 
                    storeCertificate()
                    callback()
                }
            }

            //if true run in loop in each 10 sec
            if (data && editId && window.location.pathname === "/order-editor") {
                setTimeout(() => {
                    mutateAsyncCallBakendAutomation(editId)
                }, 10000);
            }

            dispatch(bakendAutomationHandler(data))

        }
    });

    return { mutateAsyncCallBakendAutomation }
};

export { useAutomatiomStatusCheck };
