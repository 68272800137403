import React, { useEffect, useState } from 'react'
import { useActiveMaintanceQuery } from '../../Redux/Services/notification';

export default function NoticeNotification() {
    const [activeMaintanceData, setActiveMaintanceData] = useState(false);

    const notificationApi = useActiveMaintanceQuery();


    //useEffct for active mainatnce
    useEffect(() => {
        if (notificationApi.isSuccess) {
            if (notificationApi.data?.data) {
                setActiveMaintanceData({
                    ...notificationApi?.data?.data[0],
                    logo: atob(notificationApi?.data?.data[0].logo),
                });
            }
        }
        console.log("test")
    }, [notificationApi.isFetching]);

    useEffect(() => {
        setActiveMaintanceData()
    }, [])

    return activeMaintanceData &&
        <div
            className="notification d-flex pt-3 ps-3 w-100"
            style={{
                background:
                    activeMaintanceData?.colour_code || "rgb(6 23 40 / 53%)",
            }}
        >
            <img
                src={activeMaintanceData?.logo || "../Assets/login/logo.png"}
                className="me-2"
                width="50"
                height="50"
            />
            <div>
                <h3>{activeMaintanceData?.title}</h3>
                <p>{activeMaintanceData?.description}</p>
            </div>
        </div>



}
