import React, { memo } from "react";
import MultiFileUpload from "../../../../../Components/MultifileUpload/MultiFileUpload";

export default memo(function OrderDocuments({
  mode,
  whileupdate,
  onview,
  deleteFile,
  onChange,
  isLoading,
  initialFiles = [],
}) {


  return (
    <div>
      {" "}
      {!isLoading && (
        <MultiFileUpload
          // liststyle={parcelController.orderTwoView ? "horizontal" : null}
          edit={mode}
          whileupdate={whileupdate}
          onview={onview}
          deleteFile={deleteFile}
          onChange={onChange}
          limit={100}
          accepts={["application/pdf", "image/jpeg", "image/png"]}
          initialFiles={initialFiles}
        />
      )}
    </div>
  );
});
