import {  Upload } from "antd";
import "./AppFIle.css";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

export const AppFile = ({
  column,
  size,
  fileVal,
  initailVal,
  afterClose,
  label,
  required,
  _id,
}) => {
  const [imgPreview, setImgPreview] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileError, setFileError] = useState(false);
  const props = {
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    listType: "picture",
    accept: "image/jpg,image/png,image/jpeg",
    beforeUpload(file, fileList) {
      //file error and format
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";

      //filesize error size props using for size
      const isLt2M = file.size < size;
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => {
          const img = document.createElement("img");
          img.src = reader.result;
          if (isJpgOrPng && isLt2M) {
            setImgPreview(img.src);
            setFileName(file.name);
            //sending fileValue
            fileVal({ logo: img.src, name: file.name });
            setFileError(false);
          } else if (!isLt2M) {
            setFileError(`File must smaller than ${size / 1000}kb!`);
            setImgPreview("");
            setFileName("");
          } else {
            setFileError("You can only upload JPG/JPEG/PNG file!");
            setImgPreview("");
            setFileName("");
          }
        };
      });
    },
  };
  //fileClose Handler
  const fileCloseHandler = (e) => {
    fileVal("");
    setFileName("");
    setImgPreview("");
  };
  useEffect(() => {
    if (initailVal) {
      setFileName(initailVal?.fileName);
      setImgPreview(initailVal?.imgPreview);
    }
  }, [initailVal]);

  useEffect(() => {
    // fileVal("");
    setFileName("");
    setImgPreview("");
    setFileError(false);
  }, [afterClose]);
  return (
    <>
      <div className={`col-12 col-md-${column} file`}>
        <div className="row mt-3">
          <label htmlFor="" className="heading-5 text-dark">
            {label} { required && (<span className="text-danger">*</span>)}
          </label>
        </div>
        <div className="w-25  h-100 ">
          <Upload id={_id} maxCount={1} {...props}>
            <div className="text-center d-flex justify-content-center  w-100 h-100 mb-3 ">
              <span className="btn btn-secondary form-control d-inline-block text-dark btn-sm w-100  file-button">
                Choose File
              </span>
            </div>
          </Upload>
        </div>
        {
          <div className="d-flex file-flag align-items-center">
            {fileError && (
              <span className="fs-10 text-danger">{fileError}</span>
            )}
            <div className="file-preview me-1 mt-1 ">
              {imgPreview && !fileError && (
                <img
                  src={imgPreview}
                  alt=""
                  width={"100%"}
                  className="img-fluid"
                />
              )}
            </div>
            {imgPreview && !fileError && (
              <>
                <span className="filename">{fileName} </span>{" "}
                <span
                  className="text-danger cursor-pointer d-inline-block ms-1 close-file"
                  onClick={fileCloseHandler}
                >
                  x
                </span>
              </>
            )}
          </div>
        }
      </div>
    </>
  );
};

{
  /* //file previews */
}
// {preview && inputProps.type === "file" && (
//     <>
//       <div className="file-preview">
//         <img src={preview} alt="" className="img-fluid" />
//       </div>
//     </>
//   )}
// <span className="text-danger"> {fileError}</span>
