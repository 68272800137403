import { createSlice } from "@reduxjs/toolkit";

const customerTypeList = createSlice({
  name: "customerTypeList",
  initialState: {
    customerTypeList: [],
  },
  reducers: {
    customerTypeListAction: (state, action) => {
      state.customerTypeList = action.payload;
    },
  },
});

export const {customerTypeListAction} = customerTypeList.actions;

export default customerTypeList.reducer;
