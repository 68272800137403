import AppTable from "../../../../Components/AppTable/AppTable";
import { FaSearch, FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  useBatchListQuery,
  useDeleteCadBatchMutation,
} from "../../../../Redux/Services/Cad";
import { QuestionCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import {  Popconfirm } from "antd";
import AppSpinner from "../../../../Components/AppSpinner/AppSpinner";
import { Input, notification } from "antd";
import { useEffect } from "react";
import { Row } from "react-bootstrap";
import SearchColumns from "../../../../Components/SearchColumns";

export const Batch = ({ refresh, optionsShow = true, childTab }) => {
  const [data, setData] = useState();
  const [search, setSearch] = useState("");
  //set  ascend or descend sorter state
  const [getsortorder, setSortOrder] = useState(false);
  const [getsortfield, setSortField] = useState("");
  //redux data store
  const { token } = useSelector((state) => state.auth);
  const { cadModule } = useSelector((state) => state.flagRoles);

  //calling rtk query hook for getting cad list
  const batchList = useBatchListQuery(token);

  //calling rtk query hook for getting delete batchlist
  const DeleteBatchcad = useDeleteCadBatchMutation();
  const [GetDeleteBatchcad] = DeleteBatchcad;

  const deleteHandler = (id) => {
    setData(
      data.filter((ele) => {
        return (ele?.id || ele?.temp_id) !== (id?.id || id?.temp_id);
      })
    );
    GetDeleteBatchcad({
      id: id,
      token: token,
    });
  };

  useEffect(() => {
    if (DeleteBatchcad[1].isSuccess) {
      ///after update details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: DeleteBatchcad[1].data?.message,
      });
      batchList.refetch();
    }
    if (DeleteBatchcad[1].error) {
      /// erros details
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description:
          DeleteBatchcad[1].error.data.message ||
          DeleteBatchcad[1].error.error ||
          DeleteBatchcad[1].error.data?.msg.sqlMessage,
      });
    }
  }, [DeleteBatchcad[1].isLoading]);

  useEffect(() => {
    if (batchList.isSuccess) {
      setData(batchList.data?.data);
    }
  }, [batchList, search]);

  const sort = (sortOrder, sortfield) => {
    // console.log(sortOrder, sortfield);
    if (sortOrder) {
      setSortOrder(sortOrder);
      setSortField(sortfield);
    } else {
      setSortOrder(false);
    }
  };

  //create function for customer fields
  function matchColumn(value) {
    // if (authColumn.indexOf(value) === -1) {
    //   return false;
    // } else {
    //   return true;
    // }
  }

  const columns = [
    {
      // id: "2",
      title: (
        <span>
          Batch Name{" "}
          {getsortfield === "name" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "name" ? (
              <span className="sort-svg">
                <FaSortAmountUp className="text-info" />
             </span>
            ) : getsortorder === "descend" && getsortfield === "name" ? (
                <span className="sort-svg">
                  <FaSortAmountDown className="text-info" />
                  </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: 600,
      align: "center",
      ...SearchColumns("name", "Batch Name"),
    },
    {
      // id: "3",
      title: (
        <span>
          Created Date{" "}
          {getsortfield === "created_at" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "created_at" ? (
              <span className="sort-svg">
                <FaSortAmountUp className="text-info" />
              </span>
            ) : getsortorder === "descend" && getsortfield === "created_at" ? (
                <span className="sort-svg">
                  <FaSortAmountDown className="text-info" />
                  </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a, b) => a.created_at.localeCompare(b.created_at),
      width: 600,
      align: "center",
      ...SearchColumns("created_at", "Created Date"),
    },

    {
      title: <span>Actions</span>,
     className: `${cadModule[1].action ? "" : "d-none"}`,
			width: matchColumn(" ") && 100,
      align: "center",
      dataIndex: "operation",
      key: "operation",
      render: (_, record) => (
        <Popconfirm
          title="Are you sure you want to delete？"
          onConfirm={() => {
            deleteHandler(record.id);
          }}
          getPopupContainer={(trigger) => trigger.parentElement}
          placement="topLeft"
          icon={
            <QuestionCircleOutlined
              style={{
                color: "red",
              }}
            />
          }
          okText="Yes"
          cancelText="No"
        >
          <span className="gap-1">
            <DeleteOutlined
              className="text-danger"
              style={{ cursor: "pointer" }}
            />
          </span>
        </Popconfirm>
      ),
    },
  ];

  useEffect(() => {
    if (batchList.isFetching) {
      console.log("data is fetching");
    } else if (batchList.isLoading) {
      <AppSpinner />;
    }
    if (batchList.isSuccess) {
      setData(batchList.data?.data);
      //setColumn(columns);
    }
  }, [batchList]);

  useEffect(() => {
    setSearch("");
    //request
    if (refresh === "cads") {
      batchList.refetch();
    }
  }, [refresh]);

  useEffect(() => {
    setSearch("");
    //request
    if (childTab === "cads") {
      batchList.refetch();
    }
  }, [childTab]);

  return (
    <>
      {DeleteBatchcad[1].isLoading && <AppSpinner />}
      <>
        <div className="d-flex align-items-center  mb-1 gap-2 justify-content-end">
          {/* changing input values globaly  */}{" "}
          <div className=" me-1 col-3 col-lg-3 ">
            <Input
              placeholder="Search here"
              className="global-search "
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              prefix={<FaSearch color="lightgray" />}
            />
          </div>
        </div>

        <div style={{ clear: "both" }}></div>
        <Row>
          <AppTable
            loading={batchList.isLoading}
            search={search}
            dataSource={data}
            columns={columns}
            sort={sort}
          />
        </Row>
      </>
    </>
  );
};
