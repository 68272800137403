import AppTable from "../../../../Components/AppTable/AppTable";
import { FaSearch, FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
	useBatchTaxCadListQuery,
	useDeleteTaxCadBatchMutation,
} from "../../../../Redux/Services/Taxcad";
import {
	QuestionCircleOutlined,
	DeleteOutlined,
} from "@ant-design/icons";

import { Form, Popconfirm } from "antd";
import AppSpinner from "../../../../Components/AppSpinner/AppSpinner";
import { Input, notification } from "antd";
import { useEffect } from "react";
import {
	Row,
} from "react-bootstrap";
import SearchColumns from "../../../../Components/SearchColumns";

export const Batch = ({ childTab }) => {
	const [data, setData] = useState();
	const [search, setSearch] = useState("");
	const [form] = Form.useForm();

	//set  ascend or descend sorter state
	const [getsortorder, setSortOrder] = useState(false);
	const [getsortfield, setSortField] = useState("");

	//redux data store
	const { token } = useSelector((state) => state.auth);
	const { taxcadModule } = useSelector((state) => state.flagRoles);



	// /calling rtk query hook for getting taxcad batch list
	const batchList = useBatchTaxCadListQuery(token);
		useEffect(() => {
		if (batchList.isSuccess) {
			setData(batchList.data?.data);
		}
	}, [batchList]);

	//calling rtk query  for delete tax cad data
	const DeleteTaxcadBatch = useDeleteTaxCadBatchMutation();
	const [GetDeleteTaxCadBatch] = DeleteTaxcadBatch;

	useEffect(() => {
		batchList.refetch();
	}, [data]);

	//delete batch taxcad list
	const handleDelete = (key) => {
		const newData = data.filter((item) => item.key !== key);
		GetDeleteTaxCadBatch({
			id: key,
			token: token,
		});
		setData(newData);
		batchList.refetch();
	};

	//useeffect for tax cad batch list
	useEffect(() => {
		if (DeleteTaxcadBatch[1].isSuccess) {
			notification.open({
				type: "success",
				style: { color: "green", marginTop: "50px" },
				description: DeleteTaxcadBatch[1].data?.message,
			});
		}
		if (DeleteTaxcadBatch[1].error) {
			notification.open({
				type: "error",
				style: { color: "red", marginTop: "50px" },
				description:
					DeleteTaxcadBatch[1].error.data.message ||
					DeleteTaxcadBatch[1].error.error ||
					DeleteTaxcadBatch[1].error.data?.msg.sqlMessage,
			});
		}
	}, [DeleteTaxcadBatch[1].isLoading]);

	//for sorting fields
	const sort = (sortOrder, sortfield) => {
		if (sortOrder) {
			setSortOrder(sortOrder);
			setSortField(sortfield);
		} else {
			setSortOrder(false);
		}
	};

	//create function for batch fields
	function matchColumn(value) {
		// if (authColumn.indexOf(value) === -1) {
		// 	return false;
		// } else {
		// 	return true;
		// }
	}

	const columns = [
	
		{
			// id: "2",
			title: (
				<span>
					Batch Name{" "}
					{getsortfield === "name" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "name" ? (
						<FaSortAmountUp className="text-info" />
					) : getsortorder === "descend" && getsortfield === "name" ? (
						<FaSortAmountDown className="text-info" />
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "name",
			key: "name",
			sorter: (a, b) => a.name.localeCompare(b.name),
			width: 600,
			...SearchColumns("name", "Batch Name"),
			align:"center"
		},
		{
			// id: "3",
			title: (
				<span>
					Created Date{" "}
					{getsortfield === "created_at" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "created_at" ? (
						<FaSortAmountUp className="text-info" />
					) : getsortorder === "descend" && getsortfield === "created_at" ? (
						<FaSortAmountDown className="text-info" />
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "created_at",
			key: "created_at",
			sorter: (a, b) => a.created_at.localeCompare(b.created_at),
			align:"center",
			width: 600,
			...SearchColumns("created_at", "Created Date"),
		},
		{
			title: <span>Actions</span>,
			className: `${taxcadModule[1].action ? "" : "d-none"}`,
			width: matchColumn(" ") && 50,
			align: "center",
			dataIndex: "operation",
			key: "operation",
			render: (_, record) => {
				return (
					<>
						<Popconfirm
							title="Are you sure you want to delete？"
							onConfirm={() => {
								handleDelete(record.id);
							}}
							getPopupContainer={(trigger) => trigger.parentElement}
							placement="topLeft"
							icon={
								<QuestionCircleOutlined
									style={{
										color: "red",
									}}
								/>
							}
							okText="Yes"
							cancelText="No"
						>
							<span className="gap-1">
								<DeleteOutlined
									className="text-danger"
									style={{ cursor: "pointer" }}
								/>
							</span>
						</Popconfirm>
					</>
				);
			},
		},
	];

	useEffect(() => {
		setSearch("");
		if (childTab === "Batch") {
			batchList.refetch();
		}
	}, [childTab]);

	return (
		<>
			{batchList.isLoading ? (
				<AppSpinner />
			) : (
				<div className="d-flex align-items-center  mb-1 gap-2 justify-content-end">
					{/* changing input values globaly  */}{" "}
					<div className=" me-1 w-25 ">
						<Input
							placeholder="Search here"
							className="global-search "
							value={search}
							onChange={(e) => setSearch(e.target.value)}
							prefix={<FaSearch color="lightgray" />}
						/>
					</div>
				</div>
			)}
			<div style={{ clear: "both" }}></div>
			<Row>
				<AppTable
					loading={batchList.isLoading && <AppSpinner />}
					search={search}
					dataSource={data}
					columns={columns}
					sort={sort}
					form={form}
				/>
			</Row>
		</>
	);
};
