import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json")
export const hoatypes = createApi({
  reducerPath: "hoatypes",
  tagTypes: ["hoatypes"],
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  endpoints: (builder) => ({
    addHoaType: builder.mutation({
      query: (data) => ({
        url: `/add-hoa-type`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["hoatypes"],
    }),
    HoaTypeList: builder.query({
      query: (token) => ({
        url: `/hoa-type-list`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ["hoatypes"],
    }),
    HoaTypeListPost: builder.mutation({
      query: (token) => ({
        url: `/hoa-type-list`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ["hoatypes"],
    }),

    editHoaType: builder.query({
      query: (data) => ({
        url: `/edit-hoa-type/${data.id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      providesTags: ["hoatypes"],
    }),
    updateHoaType: builder.mutation({
      query: (data) => ({
        url: `/update-hoa-type/${data.id}`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["hoatypes"],
    }),
    deleteHoaType: builder.mutation({
      query: (data) => ({
         url: `/delete-hoa-type/${data.id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
       invalidatesTags: ["hoatypes"],
    }),
  
    HoaFeeTypeList: builder.query({
      query: (token) => ({
        url: `/hoa-fees-list`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ["hoatypes"],
    }),
    addHoaFeeType: builder.mutation({
      query: (data) => ({
        url: `/add-hoa-fees-type`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["hoatypes"],
    }),
    editHoaFeeType: builder.query({
      query: (data) => ({
        url: `/edit-hoa-fees-type/${data.id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      providesTags: ["hoatypes"],
    }),
    updateHoaFeeType: builder.mutation({
      query: (data) => ({
        url: `/update-hoa-fees-type/${data.id}`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["hoatypes"],
    }),
    deleteHoaFeeType: builder.mutation({
      query: (data) => ({
         url: `/delete-hoa-fees-type/${data.id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
       invalidatesTags: ["hoatypes"],
    }),
    HoaStatusList: builder.query({
      query: (token) => ({
        url: `/hoa-status-list`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ["hoatypes"],
    }),
    addHoaStatus: builder.mutation({
      query: (data) => ({
        url: `/add-hoa-status`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["hoatypes"],
    }),
    editHoaStatus: builder.query({
      query: (data) => ({
        url: `/edit-hoa-status/${data.id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      providesTags: ["hoatypes"],
    }),
    updateHoaStatus: builder.mutation({
      query: (data) => ({
        url: `/update-status-hoa/${data.id}`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["hoatypes"],
    }),

  }),
});

export const {
    useAddHoaTypeMutation,
    useHoaTypeListQuery,
    useUpdateHoaTypeMutation,
    useEditHoaTypeQuery,
    useHoaFeeTypeListQuery,
    useAddHoaFeeTypeMutation,
    useEditHoaFeeTypeQuery,
    useUpdateHoaFeeTypeMutation,
    useHoaStatusListQuery,
    useAddHoaStatusMutation,
    useEditHoaStatusQuery,
    useUpdateHoaStatusMutation, 
    useDeleteHoaFeeTypeMutation, 
    useDeleteHoaTypeMutation, 
    useHoaTypeListPostMutation
} = hoatypes;
