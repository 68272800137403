
import MyButton from "../../../../Components/AppButton/AppButton";
import { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import { Alert, notification } from "antd";
import AppTextArea from "../../../../Components/AppTextArea/AppText";

export const NoticeFlagModal = ({ afterClose, setShowHide, comments }) => {
  const [values, setValues] = useState({
    name: "",
  });
  //status
  const [getError, setGetError] = useState(null);

  //clear error function
  function clearStatus() {
    setGetError(null);
  }

  //setDefault values
  useEffect(() => {
    setValues({
      name: "",
    });
  }, [afterClose]);

  

  const inputs = [
    {
      id: 1,
      name: "name",
      type: "text",
      placeholder: "Enter Comment",
      errorMessage: "Please enter comment!",
      label: "Comment",
      required: true,
    },
  ];

  const handleReset = (e) => {
    e.preventDefault();
    setValues({
      name: "",
    });
    // claer status
    clearStatus();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (values.name) {
      //snding name to parent comp
      comments(values.name);
      setShowHide(false);
    }
  };

  return (
    <div>
      <div className=" border-bottom border-2">
        <h5>Add Comment</h5>
      </div>
      <div className="my-3">
        {getError && (
          <Alert type="error" message={getError} closable showIcon />
        )}
      </div>
      <form onSubmit={handleSubmit}>
        {/* {addNewUserType[1].isLoading && <AppSpinner />} */}
        <div className={` `}>
          {inputs.map((input) => (
            <AppTextArea
              key={input.id}
              required={true}
              label={"Comment"}
              errorMessage={"please enter comment!"}
              placeholder="Enter Comment"
              value={values.name}
              onChange={(e) => {
                setValues({ ...values, name: e.target.value });
                clearStatus();
              }}
            />
          ))}
          <Col>
            <div className="d-flex justify-content-center">
              <div className="me-md-2 my-3 mt-4 ">
                <MyButton
                  onClick={handleReset}
                  title={"CLEAR"}
                  variant={"secondary-md"}
                />
              </div>
              <div className="me-md-2 my-3 mt-4 ">
                <MyButton title={"SAVE"} variant={"primary-md"} />
              </div>
            </div>
          </Col>
        </div>
      </form>
    </div>
  );
};
