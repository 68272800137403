import {
  Table,
  Input,
  Form,
  Checkbox,
} from "antd";
import React, { useEffect, useState, memo } from "react";
import "../AppButton/AppButton";
import "./AppTable.css";
import { Resizable } from "react-resizable";
import "./test.css";

const ResizableTitle = (props) => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: false,
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};
//component satrt from here
const NormalTable = ({
  rowClassName,
  columns = [],
  dataSource = [],
  onChange = "",
  search = "",
  loading = false,
  expandable = false,
  form,
  activeState = true,
  sort = false,
  isEditing = false,
  checkList = false,
  defaultColumns = false,
  filtersData = false,
  rowSelection = false,
  pagination = true,
  currentPage = false,
  pageCount = false,
  defaultPageSize = 50,
  onpage = false,
  scroll,
  pageSizeOptions = ["50", "100", "150", "200"],
  defaultExpandAllRows=false
}) => {
  const rowSelectionBox = {
    onChange: (selectedRowKeys, selectedRows) => {
      onChange && onChange(selectedRows);
    },
  };
  const [preveData, setPreveData] = useState();
  const [, setSearchText] = useState("");
  const [checkColumn] = useState([]);
  const [pageactive, setPageactive] = useState(1);

  useEffect(() => {
    setSearchText("");
  }, []);

  // console.log("test", "color:red");
  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    required,
    minLength,
    maxLength,
    tets,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
      inputType === "checkbox" ? (
        <Checkbox defaultChecked="true" />
      ) : (
        <Input type={inputType || "text"} />
      );

    return (
      <td {...restProps}>
        {editing ? (
          inputType === "checkbox" ? (
            <Form.Item
              name={dataIndex}
              style={{
                margin: 0,
              }}
              valuePropName="checked"
            >
              {inputNode}
            </Form.Item>
          ) : (
            <Form.Item
              name={dataIndex}
              style={{
                margin: 0,
              }}
              rules={[
                {
                  type: inputType === "email" ? "email" : false,
                  required: required,
                  message: `Please Input ${inputType === "email" ? "valid" : ""
                    } ${dataIndex}!`,
                },
                {
                  min: minLength || false,
                  message: `${dataIndex} must be in ${minLength} digits!`,
                },
                {
                  max: maxLength || false,
                  message: `${dataIndex} max in ${maxLength} digits!`,
                },
              ]}
            >
              {inputNode}
            </Form.Item>
          )
        ) : (
          children
        )}
      </td>
    );
  };

  //useEffect for search globaly
  useEffect(() => {
    setSearchText(search);
    if (!search) {
      setPreveData(dataSource);
    } else if (search) {
      function filterData(data, filterValues) {
        return data.filter((value) => {
          return filterValues
            .trim()
            .split(", ")
            .every((filterValue) => checkValue(value, filterValue));
        });
      }

      function checkValue(value, filterValue) {
        if (typeof value === "string") {
          return value.toLowerCase().includes(filterValue.toLowerCase());
        } else if (
          typeof value === "object" &&
          value !== null &&
          Object.keys(value).length > 0
        ) {
          if (Array.isArray(value)) {
            return value.some((v) => checkValue(v, filterValue));
          } else {
            return Object.values(value).some((v) => checkValue(v, filterValue));
          }
        } else {
          return false;
        }
      }

      setPreveData(filterData(dataSource, search));
    }
  }, [search, dataSource]);

  const handleChange = (pagination, filters, sorter, extra) => {
    filtersData && filtersData(extra)
    //passing sorter values form child to parent with help of function
    sort && sort(sorter.order, sorter.field);
  };
  const [newCols, setNewCols] = useState(columns);

  useEffect(() => {
    setNewCols(columns);
  }, [columns]);

  const handleResize =
    (index) =>
      (_, { size }) => {
        const newColumns = [...columns];
        newColumns[index] = {
          ...newColumns[index],
          width: size.width,
        };
        setNewCols(newColumns);
      };
  // const mergeColumns = newCols.map((col, index) => ({
  //   ...col,
  //   onHeaderCell: (column) => ({
  //     width: column.width,
  //     onResize: handleResize(index),
  //   }),
  // }));

  //merged columns
  const mergedColumns = newCols.map((col, index) => {
    if (!col.editable) {
      return {
        ...col,
        onHeaderCell: (column) => ({
          width: column.width,
          onResize: handleResize(index),
        }),
      };
    }

    return {
      ...col,
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: handleResize(index),
      }),
      onCell: (record) => ({
        record,
        inputType:
          col.inputType === "checkbox"
            ? "checkbox"
            : col.inputType === "number"
              ? "number"
              : col.inputType === "email"
                ? "email"
                : "text",
        // inputType: col.dataIndex === "id" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        minLength: col.minLength,
        maxLength: col.maxLength,
        required: col.required,
        editing: isEditing && isEditing(record),
      }),
    };
  });

  return (
    <div className="app-table">
      <div>
        <div className="table">
          <Form form={form} component={false}>
            <Table
              scroll={scroll}
              // onRow={(record, rowIndex) => {
              //   return {
              //     onClick: (event) => {
              //       if (
              //         activeState &&
              //         record.dataIndex !== "Total" &&
              //         record.dataIndex !== "Grand"
              //       ) {
              //         setActive(record.id || record?.temp_id);
              //       }
              //     }, // click row
              //   };
              // }}
              // rowClassName={(record, index) => {
              //   return (record?.id || record?.vendor_id || record?.temp_id) ===
              //     active
              //     ? "active editable-row"
              //     : "editable-row";
              // }}
              rowKey={"id"}
              sortDirections={["ascend", "descend", "ascend"]}
              onChange={handleChange}

              showSorterTooltip={false}
              rowSelection={rowSelection && { ...rowSelectionBox }}
              loading={loading}
              expandable={expandable}
              rowClassName={rowClassName}
              //if column is editable then checkbox will not work
              columns={isEditing || !checkList ? mergedColumns : checkColumn}
              pagination={
                pagination
                  ? {
                    pageSizeOptions,
                    showPageSizeOptions: true,
                    showSizeChanger: true,
                    defaultPageSize,
                    total: pageCount,
                    current: currentPage || pageactive,
                    onChange: (page) => {
                      onpage && onpage(page);
                      setPageactive(page);
                      // currentPage!==page if()
                    },
                  }
                  : false
              }
              summary={(data) => {
                filtersData && filtersData(data)
                return null
              }}
              defaultExpandAllRows={defaultExpandAllRows}
              dataSource={preveData || dataSource}
              components={{
                header: {
                  cell: ResizableTitle,
                },
                body: {
                  cell: EditableCell,
                },
              }}

            />
          </Form>
        </div>
      </div>
    </div>
  );
};
export default memo(NormalTable);
