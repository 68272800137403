import React, { useEffect } from 'react'
import { useCopyHoaFeesToParcelMutation, useCopyHoaToParcelMutation } from '../Redux/Services/Hoa'
import { useSelector } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin, notification } from 'antd'
import { FaRegCopy } from "react-icons/fa";
const useHoaCopyToParcel = () => {
    const [updateHoaToParcel, updateHoaToParcelResult] = useCopyHoaToParcelMutation()
    const [updateHoaFeesToParcel, updateHoaFeesToParcelResult] = useCopyHoaFeesToParcelMutation()
    //redux store
    const { token } = useSelector((state) => state.auth);
    //redux
    const { bakendAutomation } = useSelector((state) => state?.OrderData);
    //calling redux stor for vendors list
    const { parceData } = useSelector((state) => state.parcelReducer);
    useEffect(() => {
        if (updateHoaFeesToParcelResult.isSuccess) {
            notification.open({
                type: "success",
                style: { color: "green", marginTop: "50px" },
                description: updateHoaFeesToParcelResult?.data?.message,
            });
        }
    }, [updateHoaFeesToParcelResult.isSuccess, updateHoaFeesToParcelResult?.data])

    function CopyHoaToParcels() {
        return (
            <div className={`border add_icon  border-${bakendAutomation ? "secondary" : "primary"}`} onClick={updateHoaToParcelResult?.isLoading ? undefined : () => updateHoaToParcel({
                parcel_id: parceData?.id,
                token,
            }).then(() => {
                updateHoaFeesToParcel({
                    parcel_id: parceData?.id,
                    token,
                })
            })}>
                {!updateHoaToParcelResult?.isLoading && !updateHoaFeesToParcelResult?.isLoading ? <FaRegCopy
                    color={bakendAutomation ? "#808080" : "#3388FF"}
                /> : <Spin
                    indicator={
                        <LoadingOutlined
                            style={{
                                fontSize: 24,
                            }}
                            spin
                        />
                    }
                />}
            </div>
        )
    }
    return { CopyHoaToParcels }
}

export default useHoaCopyToParcel