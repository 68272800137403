import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json");
export const county = createApi({
  reducerPath: "county",
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  endpoints: (builder) => ({
    getCountyList: builder.mutation({
      query: (data) => ({
        url: `/county-list`,
        mode: "cors",
        method: "POST",
        body: data.state_id,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    getcountyBaseCustomerAndState: builder.mutation({
      query: (data) => ({
        url: `/order-customer-wise-county-list/${data.customerId}/${data.stateId}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
  }),
});

export const {
  useGetCountyListMutation,
  useGetcountyBaseCustomerAndStateMutation,
} = county;
