import React from "react";
import ReactDOM from "react-dom/client";
// import "antd/dist/antd.css";
import "./Style/Common/common.css";
import "./Style/Responsive/Responsive.css";

import App from "./App";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Provider } from "react-redux";
import { store } from "./Redux/store";
import { CookiesProvider } from "react-cookie";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <DndProvider backend={HTML5Backend}>
    <Provider store={store}>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </Provider>
  </DndProvider>
);
