import { Layout, Menu } from "antd";
import "./aside.css";
import React, { useEffect, useState } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { items } from "../../ConfigData/AsideConfig";
import MyHeader from "../Header/Header";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { useCookies } from "react-cookie";
import { getCookie, userLoginAction } from "../../Redux/reducers/authReducer";
import { useGetStatesQuery } from "../../Redux/Services/state";
import { stateListAction } from "../../Redux/reducers/statelist";
import { useGetCountyListMutation } from "../../Redux/Services/county";
import { countyListAction } from "../../Redux/reducers/countyList";
import { useVendorsListQuery } from "../../Redux/Services/Vendors";
import { vendorsListAction } from "../../Redux/reducers/vendorsList";
import { customerTypeListAction } from "../../Redux/reducers/customerTypeList";
import {
  useCustomerListQuery,
  useCustomerTypeListQuery,
} from "../../Redux/Services/Customers";
import { useBranchListQuery } from "../../Redux/Services/Branch";
import { branchListAction } from "../../Redux/reducers/branchList";
import { useUserTypeListQuery, userType } from "../../Redux/Services/User";
import { userPrdefaulHoaStatustAction, userPrdefaulTaxStatustAction, userTypeListAction } from "../../Redux/reducers/userTypeList";
import { customerListAction } from "../../Redux/reducers/customerList";
import { useFetchLabelListQuery } from "../../Redux/Services/Labels";
import { labelListAction } from "../../Redux/reducers/labelList";
import { flagRolesAction } from "../../Redux/reducers/flagRoles";
import { useCloserListQuery } from "../../Redux/Services/closer";
import { closerListAction } from "../../Redux/reducers/closerList";
import { useGetProductTypeQuery } from "../../Redux/Services/productType";
import { productTypeListAction } from "../../Redux/reducers/productTypeList";
import { taxcollectorListAction } from "../../Redux/reducers/taxcollectorList";
import { useTaxCollectorListQuery } from "../../Redux/Services/TaxCollector";
import { useTaxCollectorTypeListQuery } from "../../Redux/Services/TaxCollectorType";
import { taxcollectorTypeListAction } from "../../Redux/reducers/taxcollectorTypeList";
import { annotationtypeListAction } from "../../Redux/reducers/annotationtypeList";
import { useAnnotationTypeListQuery } from "../../Redux/Services/annotationtype";
import { useCycleListQuery } from "../../Redux/Services/Cycles";
import { cycleListAction } from "../../Redux/reducers/cycleList";
import { useHoaMgmtlistQuery } from "../../Redux/Services/HaoMgmt";
import { hoaCollectorListAction } from "../../Redux/reducers/hoaCollectorList";
import { useJurisdictionListQuery } from "../../Redux/Services/Jurisdiction";
import { juridictionsListAction } from "../../Redux/reducers/jurisdictionList";
import { useIntegrationBridgeListQuery } from "../../Redux/Services/IntegrationBridge";
import { integrationBridgeListAction } from "../../Redux/reducers/IntegrationBridgeList";
import { useGetPreDefaultUserTypesQuery } from "../../Redux/Services/ReportsListApi";
import { addOrderData, viewPdfDocuementHandler } from "../../Redux/reducers/Order";
import { parcelListHandler } from "../../Redux/reducers/ParcelReducer/Parcel.reducer";
const config = require("../../config.json")
const { Sider, Content } = Layout;

const CryptoJS = require("crypto-js");

const Aside = () => {
  const [, setCookies] = useCookies(["user", "automation"]);
  const [collapsed, setCollapsed] = useState(true);
  // const [cookies]= useCookies(["user"])
  //calling to the auth store
  const { token, user, quickUserUpdate } = useSelector((state) => state.auth);
  const { authRoles } = useSelector((state) => state.flagRoles);

  const dispatch = useDispatch();

  //calling rtk query for statelist
  //calling rtk query hook for geting State list
  const getStateList = useGetStatesQuery(token);

  //calling rtk query hook for geting branch list
  const branchList = useBranchListQuery(token);

  //calling rtk query hook for geting branch list
  const userTypeListFetch = useUserTypeListQuery(token);
  const getPredufaultTaxStatusUserTypes = useGetPreDefaultUserTypesQuery({ defualtType: 0, token })
  const getPredufaultHoaUserTypes = useGetPreDefaultUserTypesQuery({ defualtType: 1, token })
  //calling rtk query hook for geting cycle list
  const cycleListApi = useCycleListQuery(token);

  //calling rtk query hook for geting juridictions list
  const juridictionsApi = useJurisdictionListQuery(token);

  //calling rtk query hook for geting HoaCollector list
  const hoaCollectorListApi = useHoaMgmtlistQuery(token);

  //calling rtk query hook for geting State list
  const fetchCountyList = useGetCountyListMutation();
  const [getCountyList] = fetchCountyList;

  //useEffect for cycle list
  useEffect(() => {
    if (cycleListApi.isSuccess) {
      dispatch(cycleListAction(cycleListApi.data?.data));
    }
  }, [cycleListApi]);

  //useEffect for jurisdiction list
  useEffect(() => {
    if (juridictionsApi.isSuccess) {
      dispatch(juridictionsListAction(juridictionsApi.data?.data));
    }
  }, [juridictionsApi]);

  //useEffect for cycle list
  useEffect(() => {
    if (hoaCollectorListApi.isSuccess) {
      dispatch(
        hoaCollectorListAction(
          hoaCollectorListApi.data?.data?.map(({ id, name }) => {
            return { id, name };
          })
        )
      );
    }
  }, [hoaCollectorListApi]);

  //calling rtk query for vendors list
  const vendorsLsit = useVendorsListQuery(token);
  useEffect(() => {
    if (vendorsLsit.isSuccess) {
      dispatch(
        vendorsListAction(
          vendorsLsit.data?.data.filter((ele) => {
            return ele.is_active === 1;
          })
        )
      );
    }
  }, [vendorsLsit]);

  //calling rtk query for customerType list
  const customerTypeLsit = useCustomerTypeListQuery(token);
  useEffect(() => {
    if (customerTypeLsit.isSuccess) {
      dispatch(customerTypeListAction(customerTypeLsit.data?.data));
    }
  }, [customerTypeLsit]);

  //calling rtk query for customerType list
  const customerList = useCustomerListQuery(token);
  useEffect(() => {
    if (customerList.isSuccess) {
      dispatch(
        customerListAction(
          customerList.data?.data.filter((ele) => {
            return ele.is_active === 1;
          })
        )
      );
    }
  }, [customerList]);

  //useEfffect for creating for when statlist is loaded
  useEffect(() => {
    if (getStateList.isSuccess) {
      dispatch(stateListAction(getStateList.data?.data));
      const state_id = { state_id: Number(7) };
      getCountyList({
        state_id,
        token: token,
      });
    }
  }, [getStateList]);

  //useEfffect for creating for when countyList is loaded
  useEffect(() => {
    if (fetchCountyList[1].isSuccess) {
      dispatch(countyListAction(fetchCountyList[1].data?.data));
    }
  }, [fetchCountyList[1].isLoading]);

  //useEfffect for creating for when branchlist is loaded
  useEffect(() => {
    if (branchList.isSuccess) {
      dispatch(
        branchListAction(
          branchList.data?.data.filter((ele) => {
            return ele.is_active === 1;
          })
        )
      );
    }
  }, [branchList]);

  //add customer options list
  //useEfffect for creating for when userTypeList is loaded
  useEffect(() => {
    if (userTypeListFetch.isSuccess) {
      dispatch(userTypeListAction(userTypeListFetch.data?.data));
    }
  }, [userTypeListFetch]);

  //useEfffect for creating for when userTypeList is loaded
  useEffect(() => {
    if (getPredufaultTaxStatusUserTypes.isSuccess) {
      dispatch(userPrdefaulTaxStatustAction(getPredufaultTaxStatusUserTypes.data?.data));
    }
  }, [getPredufaultTaxStatusUserTypes]);

  //useEfffect for creating for when userTypeList is loaded
  useEffect(() => {
    if (getPredufaultHoaUserTypes.isSuccess) {
      dispatch(userPrdefaulHoaStatustAction(getPredufaultHoaUserTypes.data?.data));
    }
  }, [getPredufaultHoaUserTypes]);

  //  //label list fetch
  const labelListFetch = useFetchLabelListQuery(token);

  //  //label list fetch
  const integrationBridge = useIntegrationBridgeListQuery(token);

  //useEfffect for creating for when integrationBridge list is loaded
  useEffect(() => {
    if (integrationBridge.isSuccess) {
      dispatch(integrationBridgeListAction(integrationBridge.data?.data));
    }
  }, [integrationBridge]);

  //useEfffect for creating for when label list is loaded
  useEffect(() => {
    if (labelListFetch.isSuccess) {
      dispatch(labelListAction(labelListFetch.data?.data));
    }
  }, [labelListFetch]);

  // closer list fetch
  const closerListFetch = useCloserListQuery(token);
  //useEfffect for creating for when closer list is loaded
  useEffect(() => {
    if (closerListFetch.isSuccess) {
      dispatch(closerListAction(closerListFetch.data?.data));
    }
  }, [closerListFetch]);

  //calling rtk query for productType list
  const productTypeLsit = useGetProductTypeQuery(token);
  useEffect(() => {
    if (productTypeLsit.isSuccess) {
      dispatch(productTypeListAction(productTypeLsit.data?.data));
    }
  }, [productTypeLsit]);

  //calling rtk query for taxcollector list
  const taxcollectorLsit = useTaxCollectorListQuery(token);
  useEffect(() => {
    if (taxcollectorLsit.isSuccess) {
      dispatch(taxcollectorListAction(taxcollectorLsit.data?.data));
    }
  }, [taxcollectorLsit]);

  //calling rtk query for taxcollectortype list
  const taxcollectorTypeLsit = useTaxCollectorTypeListQuery(token);
  useEffect(() => {
    if (taxcollectorTypeLsit.isSuccess) {
      dispatch(taxcollectorTypeListAction(taxcollectorTypeLsit.data?.data));
    }
  }, [taxcollectorTypeLsit]);

  //calling rtk query for annotationtype list
  const annotationtypeLsit = useAnnotationTypeListQuery(token);
  useEffect(() => {
    if (annotationtypeLsit.isSuccess) {
      dispatch(annotationtypeListAction(annotationtypeLsit.data?.data));
    }
  }, [annotationtypeLsit]);

  //creating flags
  let flags = [];
  let settingsTabs = [];
  let tabs = [];
  let userRoutes = [];
  let authColumn = [];
  let fieldFlag = [];
  let useTypeListFlag = [];
  let noticeFlag = [];
  //flags for add & edit labels based on user roles
  let add_button;
  let edit_keylabels;
  let edit_valuelabels;
  let add_labels;

  //settings flags
  let tableColumn = [];
  let vendorModule = [];
  let customerModule = [];
  let branchModule = [];
  let usersModule = [];
  let typeModule = [];
  let userTypeModule = [];

  //tools flags
  let labelModule = [];
  let hoaMgmtModule = [];
  let hoaModule = [];
  let taxCollectorModule = [];
  let jurisdictionModule = [];
  let parcelStatusModule = [];
  let orderStatusModule = [];
  let hoaStatusModule = [];
  let exemptionModule = [];
  let notificationModule = [];
  let commentsModule = [];
  let cadModule = [];
  let taxcadModule = [];
  let subdivisionModule = [];

  //order flags
  let orderModule = [];
  let orderListToolbar = [];
  let internalOrder = [];
  let newInterNalOrder = {};
  let newInterNalParcel = {};
  let parcelEditInOrder = [];

  //flags for support tabs
  let supportTabs = [];

  //flags for type tabs
  let typeTabs = [];

  //flags for jurisdiction tabs

  let jurisdictionTabs = [];

  //flags for tools tabs
  let toolsTabs = [];

  //flags for reports tabs
  let reportsTabs = [];
  //flags for reports orders status tabs
  let orderStatusTabs = [];
  let employeeOrderStatusTabs = [];

  let parcelTabs = [];

  //orders table
  let orderTableColumn = [];

  //switch case for userBase menus
  let cadTabs = [];
  let taxcadTabs = [];
  function switchFun(val) {
    switch (val) {
      //super Admin
      case 1:
        //falgs for sidemenu
        flags = [
          "myDashboard",
          "orderWizrd",
          "orders",
          "orderEditor",
          "reports",
          "tools",
          "settings",
          "notice",
        ];
        //flags for userroutes
        userRoutes = [
          "dashboard",
          "notice",
          "order-editor",
          "tools",
          "settings",
          "reports",
          "order-wizard",
          "orders",
        ];
        //tabs for settings menu
        settingsTabs = [
          "vendors",
          "customers",
          "branches",
          "users",
          // "support",
          "type",
        ];

        //flags for settings-table-column listing in vendor,customer,branch &user
        tableColumn = [{ vendor: true }, { customer: true }, { branch: true }];

        //settings-vendor menu flags
        vendorModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];
        //settings-customer menu flags
        customerModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];
        //settings-branch menu flags
        branchModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];
        //settings-users menu flags
        usersModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];

        //flags  for settings-type menu
        typeTabs = [
          "Customer Type",
          "Transaction Type",
          "User Type",
          "Product Type",
          "Comment Type",
          "Contact Type",
          "Tax Collector Type",
          "Jurisdiction Type",
          "Cycles",
          "HOA Type",
          "Third Party Document",
          "HOA Fee Type",
        ];
        //settings-type menu action flags
        typeModule = [{ add_button: true }, { action: true }];
        userTypeModule = [{ add_button: false }, { action: false }];

        //flags for userType dropdown
        useTypeListFlag = [
          "Super Admin",
          "Vendor Admin",
          "Vendor User",
          "Customer Admin",
          "Customer User",
          "Branch Admin",
          "Branch User",
          "Vendor HOA Admin",
          "Vendor Accountant",
          "Customer Power User",
          "Closer",
          "Not A User",
          "Vendor Manager",
          "Vendor Examiner",
          "Vendor Customer User",
          "Customer Manager",
          "Customer Accountant",
          "Customer HOA",
        ];

        //tabs for tools menu
        toolsTabs = [
          "labels",
          "hoa",
          "hoa-mgmt",
          "exemptions",
          "taxcollector",
          "jurisdiction",
          "activeCounty",
          "closer",
          "status",
          "notification",
          "annotations",
          "cads",
          "taxcad",
          "orderstatus",
          "hoastatus",
          "exemption",
          "subdivision"
        ];

        //tools-labels menu flags
        labelModule = [{ add_button: true }];

        //tools-hoa-mgmt menu flags
        hoaMgmtModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];

        //tools-hoa menu flags
        hoaModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];
        subdivisionModule = [
          { add_button: true },
          { action: true },
          { active_status: true },

        ];

        //tools-taxcollector  menu flags
        taxCollectorModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];

        //tools-jurisdiction  menu flags
        jurisdictionModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];
        //tools-parcelstatus  menu flags
        parcelStatusModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];

        //tools-order status  menu flags
        orderStatusModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];

        //tools-hoa status  menu flags
        hoaStatusModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];

        //tools-exemption module  menu flags
        exemptionModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];

        //tools-notification  menu flags
        notificationModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];

        //tools-comments  menu flags
        commentsModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];

        //tools-cad  menu flags
        cadModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];

        //tools-taxcad  menu flags
        taxcadModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];

        //flags for cad & taxcad tabs
        cadTabs = ["List", "Batch"];
        taxcadTabs = ["List", "Batch"];

        //tabs for report menu
        reportsTabs = [
          "Notice",
          "OrderReport",
          "InvoiceReport",
          "OrdersStatus",
          "Employee",
          "Payment"
        ];
        orderStatusTabs = ["Tax Status", "HOA Status"];
        employeeOrderStatusTabs = ["Tax Status", "HOA Status"];

        //flags for order menu
        orderModule = [
          { add_button: true },
          { export_button: true },
          { qualia_button: true },
        ];

        //flags for orderlist toolbar in order table
        orderListToolbar = [
          { edit_order: true },
          { clone_order: true },
          { email_pdf: true },
          { stopAutomationBtn: true },
          { download_pdf: true },
          { assign_order: true },
          { hoa_assign_order: true },
          { delete_order: true },
          { update_order: true },
          { history: true },
          { assign_me: true },
          { add_notice_flag: true },
          { hoa_orders_toggle: true },
        ];
        // orders table
        orderTableColumn = [{ notice: true }];
        newInterNalOrder = {
          DeleiverEmail: ["Email", "StopAutomation", "Deliver"],
          AddClone: ["Add", "Clone", "View"],
          InputHistory: ["Input", "History", "JSON"],
          Validation: ["Validation", "Conflicts"],
          DeleteEdit: ["Delete", "Edit"],
          DownloadView: ["Download", "View"],
          AutomationSend: ["Automation", "Send"],
          Assign: ["Assign"],
        };
        //flags for internal order
        internalOrder = [
          { jsonData: true },
          { orderSentFastrack: true },
          { inputRequest: true },
          { deliver_certificate: true },
          { order_summary: true },
          { clone_order: true },
          { run_validation: true },
          { assignMe: true },
          { conflicts_action_required: true },
          { delete_order: true },
          { download_pdf: true },
          { initialize_automation: true },
          { email_pdf: true },
          { stopAutomationBtn: true },
          { history: true },
          { view_documents: true },
          { view_parcel: true },
          { edit_order: true },
          { add_parcel: true },
          // flags for edit order
          { order_information: true },
          { upload_file: true },
          { display_comments: true },
          { read_comments: true },
          { additional_notes: true },
          // flags for hoa
          { hoa: true },
          { hoa_assigned: true },
          { hoa_due_date: true },
          { hoa_status: true },
          //flag for assign me button
          { assign_me_btn: true }
        ];

        newInterNalParcel = {
          DeleiverEmail: ["Email"],
          AddClone: ["Add", "View"],
          Validation: ["Validation", "Conflicts"],
          DeleteEdit: ["Delete", "Edit"],
          DownloadView: ["Download", "View"],
          History: ["History"],
        };
        //flags for parcel edit in order
        parcelEditInOrder = [
          { run_validation: true },
          { assignMe: true },
          { conflicts_action_required: true },
          { delete_parcel: true },
          { download_pdf: true },
          { email_pdf: true },
          { stopAutomationBtn: true },
          { view_parcel: true },
          { view_documents: true },
          { history: true },
          { add_parcel: true },
          { edit_parcel: true },

          // flags for taxcollector in parcel edit
          { edit_taxcollector: true },
          { delete_taxcollector: true },
          { taxcollector: true },
          { taxcollector_bills: true },
          { display_comments: true },
          { read_comments: true },
          { supporting_docs: true },
          //flags for hoa in parcel
          { hoa_add_button: true },
          { hoa_edit_button: true },
          { hoa_delete_button: true },
          { hoa_email: true },
          { hoa_download: true },
        ];

        supportTabs = ["Vendor", "Customer", "Branch"];
        noticeFlag = ["Notice to Purchaser"];
        jurisdictionTabs = ["Tax Collector", "Jurisdiction", "Mapping"];
        parcelTabs = ["Property Browser", "Manual Entry"];
        authColumn = ["edit", "is_active"];
        break;
      //vendor admin
      case 2:
        flags = [
          "myDashboard",
          "orders",
          "reports",
          "tools",
          "settings",
          // "notice",
        ];
        //flags for userroutes
        userRoutes = [
          "dashboard",
          "order-editor",
          "tools",
          "notice",
          "settings",
          "reports",
          "order-wizard",
          "orders",
        ];
        //flags for userType dropdown
        useTypeListFlag = [
          "Vendor User",
          "Customer Admin",
          "Customer User",
          "Branch Admin",
          "Branch User",
          "Vendor HOA Admin",
          "Vendor Accountant",
          "Customer Power User",
          "Closer",
          "Not A User",
          "Vendor Manager",
          "Vendor Examiner",
          "Vendor Customer User",
          "Customer Manager",
          "Customer Accountant",
          "Customer HOA",
        ];

        //flags for settings tab
        settingsTabs = ["vendors", "customers", "branches", "users", "type"];
        tableColumn = [{ vendor: true }, { customer: true }, { branch: true }];

        customerModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];
        branchModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];
        usersModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];

        //settings-type menu flags
        typeTabs = [
          "Customer Type",
          "Transaction Type",
          "User Type",
          "Product Type",
          "Comment Type",
          "Contact Type",
          "Tax Collector Type",
          "Jurisdiction Type",
          "Cycles",
          "HOA Type",
          "Third Party Document",
          "HOA Fee Type",
        ];
        typeModule = [{ add_button: true }, { action: true }];

        //tabs for tools menu
        toolsTabs = [
          "labels",
          "hoa",
          "hoa-mgmt",
          "exemptions",
          "taxcollector",
          "jurisdiction",
          "activeCounty",
          "closer",
          "status",
          "notification",
          "annotations",
          "cads",
          "taxcad",
          "orderstatus",
          "hoastatus",
          "exemption",
          "subdivision"
        ];

        //flags for cad & taxcad
        cadTabs = ["List", "Batch"];
        taxcadTabs = ["List", "Batch"];

        //tools-labels menu flags
        labelModule = [{ add_button: true }];

        //tools-hoa-mgmt menu flags
        hoaMgmtModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];

        //tools-hoa menu flags
        hoaModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];
        subdivisionModule = [
          { add_button: true },
          { action: true },
          { active_status: true },

        ];
        
        

        //settings-vendor menu flags
        vendorModule = [
          { add_button: false },
          { action: false },
          { active_status: true },
        ];
        //tools-taxcollector  menu flags
        taxCollectorModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];

        //tools-jurisdiction  menu flags
        jurisdictionModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];
        //tools-parcelstatus  menu flags
        parcelStatusModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];

        //tools-order status  menu flags
        orderStatusModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];

        //tools-hoa status  menu flags
        hoaStatusModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];

        //tools-exemption module  menu flags
        exemptionModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];

        //tools-notification  menu flags
        notificationModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];

        //tools-comments  menu flags
        commentsModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];

        //tools-cad  menu flags
        cadModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];

        //tools-taxcad  menu flags
        taxcadModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];
        //tabs for report menu
        reportsTabs = [
          "Notice",
          "OrderReport",
          "InvoiceReport",
          "OrdersStatus",
          "Employee",
          "Payment"
        ];
        orderStatusTabs = ["Tax Status", "HOA Status"];
        employeeOrderStatusTabs = ["Tax Status", "HOA Status"];

        //flags for order menu
        orderModule = [{ add_button: true }, { export_button: true }];

        //flags for orderlist toolbar in order table
        orderListToolbar = [
          { edit_order: true },
          { clone_order: true },
          { email_pdf: true },
          { stopAutomationBtn: true },
          { download_pdf: true },
          { assign_order: true },
          { hoa_assign_order: true },
          { delete_order: true },
          { update_order: true },
          { history: true },
          { assign_me: true },
          { add_notice_flag: true },
          { hoa_orders_toggle: true },
        ];
        // orders table
        orderTableColumn = [{ notice: true }];
        newInterNalOrder = {
          DeleiverEmail: ["Email", "StopAutomation", "Deliver"],
          AddClone: ["Add", "Clone", "View"],
          InputHistory: ["Input", "History", "JSON"],
          Validation: ["Validation", "Conflicts"],
          DeleteEdit: ["Delete", "Edit"],
          DownloadView: ["Download", "View"],
          AutomationSend: ["Automation", "Send"],
          Assign: ["Assign"],
        };
        //flags for internal order
        internalOrder = [
          { deliver_certificate: true },
          { order_summary: true },
          { clone_order: true },
          { run_validation: true },
          { assignMe: true },
          { conflicts_action_required: true },
          { delete_order: true },
          { download_pdf: true },
          { initialize_automation: true },
          { email_pdf: true },
          { stopAutomationBtn: true },
          { history: true },
          { view_documents: true },
          { view_parcel: true },
          { edit_order: true },
          { add_parcel: true },
          // flags for edit order
          { order_information: true },
          { upload_file: true },
          { display_comments: true },
          { read_comments: true },
          { additional_notes: true },
          // flags for hoa
          { hoa: true },
          { hoa_assigned: true },
          { hoa_due_date: true },
          { hoa_status: true },
          //flag for assign me button
          { assign_me_btn: true }
        ];
        newInterNalParcel = {
          DeleiverEmail: ["Email"],
          AddClone: ["Add", "View"],
          Validation: ["Validation", "Conflicts"],
          DeleteEdit: ["Delete", "Edit"],
          DownloadView: ["Download", "View"],
          History: ["History"],
        };
        //flags for parcel edit in order
        parcelEditInOrder = [
          { run_validation: true },
          { assignMe: true },
          { conflicts_action_required: true },
          { delete_parcel: true },
          { download_pdf: true },
          { email_pdf: true },
          { stopAutomationBtn: true },
          { view_parcel: true },
          { view_documents: true },
          { history: true },
          { add_parcel: true },
          { edit_parcel: true },

          // flags for taxcollector in parcel edit
          { edit_taxcollector: true },
          { delete_taxcollector: true },
          { taxcollector: true },
          { taxcollector_bills: true },
          { display_comments: true },
          { read_comments: true },
          { supporting_docs: true },
          //flags for hoa in parcel
          { hoa_add_button: true },
          { hoa_edit_button: true },
          { hoa_delete_button: true },
          { hoa_email: true },
          { hoa_download: true },
        ];

        tabs = ["Customers", "Branches", "Users"];
        supportTabs = ["Customer", "Branch"];
        jurisdictionTabs = ["Tax Collector", "Jurisdiction", "Mapping"];
        parcelTabs = ["Property Browser", "Manual Entry"];
        authColumn = ["edit", "is_active"];
        break;
      //vendor user
      case 3:
        //flags for side menu
        flags = [
          "myDashboard",
          "orders",
          "reports",
          // "tools",
          "settings",
        ];
        //flags for userroutes
        userRoutes = [
          "dashboard",
          "notice",
          "order-editor",
          // "tools",
          "settings",
          "reports",
          "order-wizard",
          "orders",
        ];

        //flags for userType dropdown
        // useTypeListFlag = [
        // 	"Vendor Admin",
        // 	"Vendor User",
        // 	"Customer Admin",
        // 	"Customer User",
        // 	"Branch Admin",
        // 	"Branch User",
        // 	"HOA Admin",
        // 	"Accountant",
        // 	"Customer Power User",
        // 	"Closer",
        // 	"Not A User",
        // 	"Vendor Manager",
        // 	"Vendor Examiner",
        // 	"Vendor Customer User",
        // 	"Customer Manager",
        // 	"Customer Accoutant",
        // 	"Customer HOA"
        // ];

        //flags for settings tab
        settingsTabs = ["vendors", "customers", "branches", "users", "type"];
        vendorModule = [
          { add_button: false },
          { action: false },
          { active_status: false },
        ];
        customerModule = [
          { add_button: false },
          { action: false },
          { active_status: false },
        ];
        branchModule = [
          { add_button: false },
          { action: false },
          { active_status: false },
        ];
        usersModule = [
          { add_button: false },
          { action: false },
          { active_status: false },
        ];
        tableColumn = [{ vendor: false }, { customer: true }, { branch: true }];

        //settings-type menu flags
        typeTabs = [
          "Customer Type",
          "Transaction Type",
          "User Type",
          "Product Type",
          "Comment Type",
          "Contact Type",
          "Tax Collector Type",
          "Jurisdiction Type",
          "Cycles",
          "HOA Type",
          "Third Party Document",
          "HOA Fee Type",
        ];
        typeModule = [{ add_button: false }, { action: false }];

        //flags for report menu
        reportsTabs = [
          "Notice",
          "OrderReport",
          "InvoiceReport",
          "OrdersStatus",
          "Employee",
          "Payment"
        ];
        orderStatusTabs = ["Tax Status", "HOA Status"];
        employeeOrderStatusTabs = ["Tax Status", "HOA Status"];

        //flags for order menu
        orderModule = [{ add_button: true }, { export_button: true }];

        //flags for orderlist toolbar in order table
        orderListToolbar = [
          { edit_order: true },
          { clone_order: false },
          { email_pdf: true },
          { stopAutomationBtn: true },
          { download_pdf: true },
          { assign_order: false },
          { delete_order: false },
          { update_order: false },
          { history: true },
          { assign_me: false },
          { add_notice_flag: true },
          { hoa_orders_toggle: true },
        ];
        // orders table
        orderTableColumn = [{ notice: true }];
        newInterNalOrder = {
          DeleiverEmail: ["Email", "StopAutomation", "Deliver"],
          AddClone: ["View"],
        };
        //flags for internal order
        internalOrder = [
          { deliver_certificate: true },
          { order_summary: false },
          { clone_order: false },
          { run_validation: false },
          { conflicts_action_required: false },
          { delete_order: false },
          { download_pdf: false },
          { initialize_automation: false },
          { email_pdf: false },
          { history: false },
          { view_documents: false },
          { view_parcel: false },
          { edit_order: false },
          { add_parcel: false },
          // flags for edit order
          { order_information: false },
          { upload_file: false },
          { display_comments: false },
          { read_comments: false },
          { additional_notes: false },
          // flags for hoa
          { hoa: false },
          { hoa_assigned: false },
          { hoa_due_date: false },
          { hoa_status: false },
          //flag for assign me button
          { assign_me_btn: true }
        ];
        newInterNalParcel = {};
        //flags for parcel edit in order
        parcelEditInOrder = [
          { run_validation: false },
          { conflicts_action_required: false },
          { delete_parcel: false },
          { download_pdf: false },
          { email_pdf: false },
          { view_parcel: false },
          { view_documents: false },
          { history: false },
          { add_parcel: false },
          { edit_parcel: false },

          // flags for taxcollector in parcel edit
          { edit_taxcollector: false },
          { delete_taxcollector: false },
          { taxcollector: false },
          { taxcollector_bills: false },
          { display_comments: false },
          { read_comments: false },
          { supporting_docs: false },
          //flags for hoa in parcel edit
          { hoa_add_button: true },
          { hoa_edit_button: true },
          { hoa_delete_button: true },
          { hoa_email: true },
          { hoa_download: true },
        ];

        tabs = ["Customers", "Branches", "Users"];

        jurisdictionTabs = ["Tax Collector", "Jurisdiction", "Mapping"];
        supportTabs = ["Customer", "Branch"];

        parcelTabs = ["Property Browser", "Manual Entry"];
        authColumn = ["edit", "is_active"];
        break;
      //Customer Admin
      case 4:
        //flags for side menu
        flags = [
          "myDashboard",
          "orders",
          "reports",
          // "notice",
        ];
        //flags for userroutes
        userRoutes = [
          "dashboard",
          "notice",
          "order-editor",
          "reports",
          "order-wizard",
          "orders",
        ];

        //flags for userType dropdown
        // useTypeListFlag = [
        // 	"Vendor Admin",
        // 	"Vendor User",
        // 	"Customer Admin",
        // 	"Customer User",
        // 	"Branch Admin",
        // 	"Branch User",
        // 	"HOA Admin",
        // 	"Accountant",
        // 	"Customer Power User",
        // 	"Closer",
        // 	"Not A User",
        // 	"Vendor Manager",
        // 	"Vendor Examiner",
        // 	"Vendor Customer User",
        // 	"Customer Manager",
        // 	"Customer Accoutant",
        // 	"Customer HOA"
        // ];

        //tabs for report menu
        reportsTabs = ["Notice"];

        //flags for order menu
        orderModule = [{ add_button: true }, { export_button: true }];

        //flags for orderlist toolbar in order table
        orderListToolbar = [
          { edit_order: true },
          { clone_order: false },
          { email_pdf: true },
          { stopAutomationBtn: true },
          { download_pdf: true },
          { assign_order: false },
          { delete_order: true },
          { update_order: true },
          { history: true },
          { assign_me: false },
          { add_notice_flag: true },
          { hoa_orders_toggle: true },
        ];
        // orders table
        orderTableColumn = [{ notice: true }];
        newInterNalOrder = {
          DeleiverEmail: ["Email", "StopAutomation", "Deliver"],
          AddClone: ["Add", "View"],
          InputHistory: ["Input", "History", "JSON"],
          DeleteEdit: ["Edit"],
          DownloadView: ["Download", "View"],
          AutomationSend: ["Automation"],
        };

        //flags for internal order
        internalOrder = [
          { deliver_certificate: true },
          { order_summary: true },
          { clone_order: false },
          { run_validation: false },
          { conflicts_action_required: false },
          { delete_order: false },
          { download_pdf: true },
          { initialize_automation: true },
          { email_pdf: true },
          { stopAutomationBtn: true },
          { history: true },
          { view_documents: true },
          { view_parcel: true },
          { edit_order: true },
          { add_parcel: true },
          // flags for edit order
          { order_information: true },
          { upload_file: true },
          { display_comments: false },
          { read_comments: false },
          { additional_notes: true },
          // flags for hoa
          { hoa: false },
          { hoa_assigned: false },
          { hoa_due_date: false },
          { hoa_status: false },
        ];
        newInterNalParcel = {
          DeleiverEmail: ["Email"],
          AddClone: ["Add", "View"],
          DeleteEdit: ["Delete", "Edit"],
          DownloadView: ["Download", "View"],
        };
        //flags for parcel in order
        parcelEditInOrder = [
          { run_validation: false },
          { conflicts_action_required: false },
          { delete_parcel: true },
          { download_pdf: true },
          { email_pdf: true },
          { stopAutomationBtn: true },
          { view_parcel: true },
          { view_documents: true },
          { history: false },
          { add_parcel: true },
          { edit_parcel: false },

          // flags for taxcollector in parcel
          { edit_taxcollector: false },
          { delete_taxcollector: false },
          { taxcollector: false },
          { taxcollector_bills: false },
          { display_comments: false },
          { read_comments: false },
          { supporting_docs: false },
          //flags for hoa in parcel
          { hoa_add_button: false },
          { hoa_edit_button: false },
          { hoa_delete_button: false },
        ];

        noticeFlag = ["Notice to Purchaser"];
        jurisdictionTabs = ["Tax Collector", "Jurisdiction", "Mapping"];
        parcelTabs = ["Property Browser", "Manual Entry"];
        authColumn = ["edit", "is_active"];
        break;
      //Customer User
      case 5:
        //flags for side menu
        flags = [
          //"myDashboard",
          "orders",
          "reports",
          // "notice",
        ];
        //flags for userroutes
        userRoutes = [
          // "dashboard",
          "notice",
          "order-editor",
          "reports",
          "order-wizard",
          "orders",
        ];

        //flags for userType dropdown
        // useTypeListFlag = [
        // 	"Vendor Admin",
        // 	"Vendor User",
        // 	"Customer Admin",
        // 	"Customer User",
        // 	"Branch Admin",
        // 	"Branch User",
        // 	"HOA Admin",
        // 	"Accountant",
        // 	"Customer Power User",
        // 	"Closer",
        // 	"Not A User",
        // 	"Vendor Manager",
        // 	"Vendor Examiner",
        // 	"Vendor Customer User",
        // 	"Customer Manager",
        // 	"Customer Accoutant",
        // 	"Customer HOA"
        // ];

        //tabs for report menu
        reportsTabs = ["Notice"];

        //flags for order menu
        orderModule = [
          { add_button: true },
          { export_button: true },
          { qualia_button: false },
        ];
        // orders table
        orderTableColumn = [{ notice: false }];
        //flags for orderlist toolbar in order table
        orderListToolbar = [
          { edit_order: true },
          { clone_order: false },
          { email_pdf: true },
          { stopAutomationBtn: true },
          { download_pdf: true },
          { assign_order: false },
          { delete_order: true },
          { update_order: true },
          { history: true },
          { assign_me: false },
          { add_notice_flag: true },
          { hoa_orders_toggle: false },
        ];

        newInterNalOrder = {
          DeleiverEmail: ["Email", "StopAutomation", "Deliver"],
          AddClone: ["Add", "View"],
          InputHistory: ["Input", "History", "JSON"],
          DeleteEdit: ["Edit"],
          DownloadView: ["Download", "View"],
          AutomationSend: ["Automation", "Send"],
        };
        //flags for internal order
        internalOrder = [
          { deliver_certificate: true },
          { order_summary: true },
          { clone_order: false },
          { run_validation: false },
          { conflicts_action_required: false },
          { delete_order: false },
          { download_pdf: true },
          { initialize_automation: true },
          { email_pdf: true },
          { stopAutomationBtn: true },
          { history: true },
          { view_documents: true },
          { view_parcel: true },
          { edit_order: true },
          { add_parcel: true },
          // flags for edit order
          { order_information: true },
          { upload_file: true },
          { display_comments: false },
          { read_comments: false },
          { additional_notes: true },
          // flags for hoa
          { hoa: false },
          { hoa_assigned: false },
          { hoa_due_date: false },
          { hoa_status: false },
          //flag for assign me button
          { assign_me_btn: false }
        ];
        newInterNalParcel = {
          DeleiverEmail: ["Email"],
          AddClone: ["Add", "View"],
          DeleteEdit: ["Delete", "Edit"],
          DownloadView: ["Download", "View"],
        };
        //flags for parcel in order
        parcelEditInOrder = [
          { run_validation: false },
          { conflicts_action_required: false },
          { delete_parcel: true },
          { download_pdf: true },
          { email_pdf: true },
          { stopAutomationBtn: true },
          { view_parcel: true },
          { view_documents: true },
          { history: false },
          { add_parcel: true },
          { edit_parcel: false },

          // flags for taxcollector in parcel
          { edit_taxcollector: false },
          { delete_taxcollector: false },
          { taxcollector: false },
          { taxcollector_bills: false },
          { display_comments: false },
          { read_comments: false },
          { supporting_docs: false },
          //flags for hoa in parcel
          { hoa_add_button: false },
          { hoa_edit_button: false },
          { hoa_delete_button: false },
        ];

        noticeFlag = ["Notice to Purchaser"];
        jurisdictionTabs = ["Tax Collector", "Jurisdiction", "Mapping"];
        parcelTabs = ["Property Browser", "Manual Entry"];
        break;
      //Branch Admin
      case 6:
        flags = ["myDashboard", "orders", "settings"];
        userRoutes = [
          "dashboard",
          "notice",
          "order-editor",
          "order-wizard",
          "orders",
          "settings",
        ];
        settingsTabs = ["users"];
        //settings-users menu flags
        usersModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];

        //flags for settings-table-column listing in vendor,customer,branch &user
        tableColumn = [
          { vendor: false },
          { customer: false },
          { branch: false },
        ];

        //flags for order menu
        orderModule = [
          { add_button: true },
          { export_button: true },
          { qualia_button: true },
        ];

        //flags for orderlist toolbar in order table
        orderListToolbar = [
          { edit_order: true },
          { clone_order: false },
          { email_pdf: true },
          { stopAutomationBtn: true },
          { download_pdf: true },
          { assign_order: false },
          { delete_order: true },
          { update_order: true },
          { history: true },
          { assign_me: true },
          { add_notice_flag: true },
          { hoa_orders_toggle: true },
        ];
        // orders table
        orderTableColumn = [{ notice: true }];
        newInterNalOrder = {
          DeleiverEmail: ["Email", "StopAutomation", "Deliver"],
          AddClone: ["Add", "Clone", "View"],
          InputHistory: ["Input", "History", "JSON"],
          Validation: ["Validation", "Conflicts"],
          DeleteEdit: ["Delete", "Edit"],
          DownloadView: ["Download", "View"],
          AutomationSend: ["Automation", "Send"],
          Assign: ["Assign"],
        };
        //flags for internal order
        internalOrder = [
          { deliver_certificate: true },
          { order_summary: true },
          { clone_order: true },
          { run_validation: true },
          { assignMe: true },
          { conflicts_action_required: true },
          { delete_order: true },
          { download_pdf: true },
          { initialize_automation: true },
          { email_pdf: true },
          { stopAutomationBtn: true },
          { history: true },
          { view_documents: true },
          { view_parcel: true },
          { edit_order: true },
          { add_parcel: true },
          // flags for edit order
          { order_information: true },
          { upload_file: true },
          { display_comments: true },
          { read_comments: true },
          { additional_notes: false },
          // flags for hoa
          { hoa: true },
          { hoa_assigned: true },
          { hoa_due_date: true },
          { hoa_status: true },
          //flag for assign me btn
          { assign_me_btn: true }
        ];
        newInterNalParcel = {};
        //flags for parcel edit in order
        parcelEditInOrder = [
          { run_validation: false },
          { conflicts_action_required: false },
          { delete_parcel: false },
          { download_pdf: false },
          { email_pdf: false },
          { view_parcel: false },
          { view_documents: false },
          { history: false },
          { add_parcel: false },
          { edit_parcel: false },

          // flags for taxcollector in parcel edit
          { edit_taxcollector: false },
          { delete_taxcollector: false },
          { taxcollector: false },
          { taxcollector_bills: false },
          { display_comments: false },
          { read_comments: false },
          { supporting_docs: false },
          //flags for hoa in parcel
          { hoa_add_button: false },
          { hoa_edit_button: false },
          { hoa_delete_button: false },
        ];

        noticeFlag = ["Notice to Purchaser"];
        jurisdictionTabs = ["Tax Collector", "Jurisdiction", "Mapping"];

        //flags for userType dropdown
        useTypeListFlag = ["Branch User"];

        parcelTabs = ["Property Browser", "Manual Entry"];
        authColumn = ["edit", "is_active"];
        break;
      //Branch User
      case 7:
        flags = ["myDashboard", "orders", "settings"];
        userRoutes = [
          "dashboard",
          "notice",
          "order-editor",
          "settings",
          "order-wizard",
          "orders",
        ];

        //flags for userType dropdown
        // useTypeListFlag = [
        // 	"Vendor Admin",
        // 	"Vendor User",
        // 	"Customer Admin",
        // 	"Customer User",
        // 	"Branch Admin",
        // 	"Branch User",
        // 	"HOA Admin",
        // 	"Accountant",
        // 	"Customer Power User",
        // 	"Closer",
        // 	"Not A User",
        // 	"Vendor Manager",
        // 	"Vendor Examiner",
        // 	"Vendor Customer User",
        // 	"Customer Manager",
        // 	"Customer Accoutant",
        // 	"Customer HOA"
        // ];

        settingsTabs = ["users"];
        //settings-users menu flags
        usersModule = [
          { add_button: false },
          { action: false },
          { active_status: false },
        ];

        //flags for settings-table-column listing in vendor,customer,branch &user
        tableColumn = [
          { vendor: false },
          { customer: false },
          { branch: true },
        ];

        //flags for order menu
        orderModule = [
          { add_button: true },
          { export_button: true },
          { qualia_button: true },
        ];

        //flags for orderlist toolbar in order table
        orderListToolbar = [
          { edit_order: true },
          { clone_order: false },
          { email_pdf: true },
          { stopAutomationBtn: true },
          { download_pdf: true },
          { assign_order: false },
          { delete_order: true },
          { update_order: true },
          { history: true },
          { assign_me: false },
          { add_notice_flag: true },
          { hoa_orders_toggle: true },
        ];
        // orders table
        orderTableColumn = [{ notice: true }];
        newInterNalOrder = {
          DeleiverEmail: ["Email", "StopAutomation", "Deliver"],
          AddClone: ["Add", "View"],
          InputHistory: ["Input", "History", "JSON"],
          DeleteEdit: ["Edit"],
          DownloadView: ["Download", "View"],
          AutomationSend: ["Automation", "Send"],
          Assign: ["Assign"],
        };
        //flags for internal order
        internalOrder = [
          { deliver_certificate: true },
          { order_summary: true },
          { clone_order: false },
          { run_validation: false },
          { conflicts_action_required: false },
          { delete_order: false },
          { download_pdf: true },
          { initialize_automation: true },
          { email_pdf: true },
          { stopAutomationBtn: true },
          { history: true },
          { view_documents: true },
          { view_parcel: true },
          { edit_order: true },
          { add_parcel: true },
          // flags for edit order
          { order_information: true },
          { upload_file: true },
          { display_comments: false },
          { read_comments: false },
          { additional_notes: true },
          // flags for hoa
          { hoa: false },
          { hoa_assigned: false },
          { hoa_due_date: false },
          { hoa_status: false },
          //flag for assign me btn
          { assign_me_btn: true }
        ];
        newInterNalParcel = {
          DeleiverEmail: ["Email"],
          AddClone: ["Add", "View"],
          DeleteEdit: ["Delete", "Edit"],
          DownloadView: ["Download", "View"],
        };
        //flags for parcel in order
        parcelEditInOrder = [
          { run_validation: false },
          { conflicts_action_required: false },
          { delete_parcel: true },
          { download_pdf: true },
          { email_pdf: true },
          { stopAutomationBtn: true },
          { view_parcel: true },
          { view_documents: true },
          { history: false },
          { add_parcel: true },
          { edit_parcel: false },

          // flags for taxcollector in parcel
          { edit_taxcollector: false },
          { delete_taxcollector: false },
          { taxcollector: false },
          { taxcollector_bills: false },
          { display_comments: false },
          { read_comments: false },
          { supporting_docs: false },
          //flags for hoa in parcel
          { hoa_add_button: false },
          { hoa_edit_button: false },
          { hoa_delete_button: false },
        ];

        noticeFlag = ["Notice to Purchaser"];
        jurisdictionTabs = ["Tax Collector", "Jurisdiction", "Mapping"];
        parcelTabs = ["Property Browser", "Manual Entry"];
        authColumn = ["edit", "is_active"];
        break;
      //Vendor HOA Admin
      case 8:
        //flags for side menu
        flags = ["myDashboard", "orders", "reports", "tools", "settings"];
        //flags for user routes
        userRoutes = [
          "dashboard",
          "notice",
          "order-editor",
          "reports",
          "tools",
          "settings",
          "order-wizard",
          "orders",
        ];
        //flags for userType dropdown
        // useTypeListFlag = [
        // 	"Vendor Admin",
        // 	"Vendor User",
        // 	"Customer Admin",
        // 	"Customer User",
        // 	"Branch Admin",
        // 	"Branch User",
        // 	"HOA Admin",
        // 	"Accountant",
        // 	"Customer Power User",
        // 	"Closer",
        // 	"Not A User",
        // 	"Vendor Manager",
        // 	"Vendor Examiner",
        // 	"Vendor Customer User",
        // 	"Customer Manager",
        // 	"Customer Accoutant",
        // 	"Customer HOA"
        // ];

        //flags for settings tab
        settingsTabs = ["type"];

        //settings-type menu flags
        typeTabs = ["HOA Type", "Third Party Document", "HOA Fee Type"];
        typeModule = [{ add_button: true }, { action: true }];

        //tabs for report menu
        reportsTabs = ["Notice"];
        //tabs for tools menu
        toolsTabs = [
          "hoa",
          "hoa-mgmt",
          // "hoastatus",
        ];

        //tools-hoa-mgmt menu flags
        hoaMgmtModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];

        //tools-hoa menu flags
        hoaModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];
        subdivisionModule = [
          { add_button: true },
          { action: true },
          { active_status: true },

        ]

        //flags for order menu
        orderModule = [
          { add_button: true },
          { export_button: true },
          { qualia_button: true },
        ];

        //flags for orderlist toolbar in order table
        orderListToolbar = [
          { edit_order: true },
          { clone_order: false },
          { email_pdf: true },
          { stopAutomationBtn: true },
          { download_pdf: true },
          { assign_order: true },
          { hoa_assign_order: true },
          { delete_order: true },
          { update_order: true },
          { history: true },
          { assign_me: true },
          { add_notice_flag: true },
          { hoa_orders_toggle: true },
        ];
        // orders table
        orderTableColumn = [{ notice: true }];
        newInterNalOrder = {
          DeleiverEmail: ["Email", "StopAutomation", "Deliver"],
          AddClone: ["Add", "Clone", "View"],
          InputHistory: ["Input", "History", "JSON"],
          Validation: ["Validation", "Conflicts"],
          DeleteEdit: ["Delete", "Edit"],
          DownloadView: ["Download", "View"],
          AutomationSend: ["Automation", "Send"],
          Assign: ["Assign"],
        };
        //flags for internal order
        internalOrder = [
          { deliver_certificate: true },
          { order_summary: true },
          { clone_order: true },
          { run_validation: true },
          { assignMe: true },
          { conflicts_action_required: true },
          { delete_order: true },
          { download_pdf: true },
          { initialize_automation: true },
          { email_pdf: true },
          { stopAutomationBtn: true },
          { history: true },
          { view_documents: true },
          { view_parcel: true },
          { edit_order: true },
          { add_parcel: true },
          // flags for edit order
          { order_information: true },
          { upload_file: true },
          { display_comments: true },
          { read_comments: true },
          { additional_notes: false },
          // flags for hoa
          { hoa: true },
          { hoa_assigned: true },
          { hoa_due_date: true },
          { hoa_status: true },
          //flag for assign me btn
          { assign_me_btn: true }
        ];
        newInterNalParcel = {
          DeleiverEmail: ["Email"],
          AddClone: ["Add", "View"],
          Validation: ["Validation", "Conflicts"],
          DeleteEdit: ["Delete", "Edit"],
          DownloadView: ["Download", "View"],
          History: ["History"],
        };
        //flags for parcel in order
        parcelEditInOrder = [
          { run_validation: true },
          { assignMe: true },
          { conflicts_action_required: true },
          { delete_parcel: true },
          { download_pdf: true },
          { email_pdf: true },
          { stopAutomationBtn: true },
          { view_parcel: true },
          { view_documents: true },
          { history: true },
          { add_parcel: true },
          { edit_parcel: true },

          // flags for taxcollector in parcel
          { edit_taxcollector: true },
          { delete_taxcollector: true },
          { taxcollector: true },
          { taxcollector_bills: true },
          { display_comments: true },
          { read_comments: true },
          { supporting_docs: true },
          //flags for hoa in parcel
          { hoa_add_button: true },
          { hoa_edit_button: true },
          { hoa_delete_button: true },
          { hoa_email: true },
          { hoa_download: true },
        ];

        tabs = ["Customers", "Branches", "Users"];
        supportTabs = ["Customer", "Branch"];
        jurisdictionTabs = ["Tax Collector", "Jurisdiction", "Mapping"];
        parcelTabs = ["Property Browser", "Manual Entry"];
        break;
      //Vendor Accountant
      case 9:
        //flags for side menu
        flags = ["myDashboard", "reports"];
        //flags for user routes
        userRoutes = ["dashboard", "notice", "reports"];
        //flags for userType dropdown
        // useTypeListFlag = [
        // 	"Vendor Admin",
        // 	"Vendor User",
        // 	"Customer Admin",
        // 	"Customer User",
        // 	"Branch Admin",
        // 	"Branch User",
        // 	"HOA Admin",
        // 	"Accountant",
        // 	"Customer Power User",
        // 	"Closer",
        // 	"Not A User",
        // 	"Vendor Manager",
        // 	"Vendor Examiner",
        // 	"Vendor Customer User",
        // 	"Customer Manager",
        // 	"Customer Accoutant",
        // 	"Customer HOA"
        // ];

        //tabs for report menu
        reportsTabs = [
          "Notice",
          "OrderReport",
          "InvoiceReport",
          "OrdersStatus",
          "Employee",
          "Payment"
        ];
        orderStatusTabs = ["Tax Status", "HOA Status"];
        employeeOrderStatusTabs = ["Tax Status", "HOA Status"];

        tabs = ["Customers", "Branches", "Users"];
        supportTabs = ["Customer", "Branch"];
        jurisdictionTabs = ["Tax Collector", "Jurisdiction", "Mapping"];
        parcelTabs = ["Property Browser", "Manual Entry"];
        break;
      //Customer Power User
      case 10:
        flags = [
          "myDashboard",
          "orderWizrd",
          "orders",
          "orderEditor",
          "reports",
        ];
        userRoutes = [
          "dashboard",
          "notice",
          "order-editor",
          "reports",
          "order-wizard",
          "orders",
        ];
        //flags for userType dropdown
        // useTypeListFlag = [
        // 	"Vendor Admin",
        // 	"Vendor User",
        // 	"Customer Admin",
        // 	"Customer User",
        // 	"Branch Admin",
        // 	"Branch User",
        // 	"HOA Admin",
        // 	"Accountant",
        // 	"Customer Power User",
        // 	"Closer",
        // 	"Not A User",
        // 	"Vendor Manager",
        // 	"Vendor Examiner",
        // 	"Vendor Customer User",
        // 	"Customer Manager",
        // 	"Customer Accoutant",
        // 	"Customer HOA"
        // ];

        break;
      //Closer
      case 11:
        flags = [
          "myDashboard",
          "orderWizrd",
          "orders",
          "orderEditor",
          "reports",
        ];
        userRoutes = [
          "dashboard",
          "notice",
          "order-editor",
          "reports",
          "order-wizard",
          "orders",
        ];
        //flags for userType dropdown
        // useTypeListFlag = [
        // 	"Vendor Admin",
        // 	"Vendor User",
        // 	"Customer Admin",
        // 	"Customer User",
        // 	"Branch Admin",
        // 	"Branch User",
        // 	"HOA Admin",
        // 	"Accountant",
        // 	"Customer Power User",
        // 	"Closer",
        // 	"Not A User",
        // 	"Vendor Manager",
        // 	"Vendor Examiner",
        // 	"Vendor Customer User",
        // 	"Customer Manager",
        // 	"Customer Accoutant",
        // 	"Customer HOA"
        // ];

        break;
      //Not a User
      case 12:
        flags = [
          "myDashboard",
          "orderWizrd",
          "orders",
          "orderEditor",
          "reports",
        ];
        userRoutes = [
          "dashboard",
          "notice",
          "order-editor",
          "reports",
          "order-wizard",
          "orders",
        ];
        //flags for userType dropdown
        useTypeListFlag = [
          "Vendor Admin",
          "Vendor User",
          "Customer Admin",
          "Customer User",
          "Branch Admin",
          "Branch User",
          "HOA Admin",
          "Accountant",
          "Customer Power User",
          "Closer",
          "Not A User",
          "Vendor Manager",
          "Vendor Examiner",
          "Vendor Customer User",
          "Customer Manager",
          "Customer Accoutant",
          "Customer HOA",
        ];

        break;
      //Vendor Manager
      case 13:
        //flags for side menu
        flags = [
          "myDashboard",
          "orders",
          "reports",
          "tools",
          "settings",
          // "notice",
        ];
        //flags for userroutes
        userRoutes = [
          "dashboard",
          "notice",
          "order-editor",
          "tools",
          "settings",
          "reports",
          "order-wizard",
          "orders",
        ];

        //flags for userType dropdown
        useTypeListFlag = ["Vendor Examiner"];

        //tabs for settings menu
        settingsTabs = [
          "branches",
          "users",
          // "support",
        ];
        //flags for settings-table-column listing in vendor,customer,branch &user
        tableColumn = [{ vendor: false }, { customer: true }, { branch: true }];

        //settings-branch menu flags
        branchModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];
        //settings-users menu flags
        usersModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];

        //tabs for tools menu
        toolsTabs = ["taxcollector", "jurisdiction", "annotations"];

        //tools-taxcollector  menu flags
        taxCollectorModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];

        //tools-jurisdiction  menu flags
        jurisdictionModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];

        //tools-comments  menu flags
        commentsModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];

        //tabs for report menu
        reportsTabs = ["Notice", "OrderReport", "OrdersStatus", "Employee", "Payment"];
        orderStatusTabs = ["Tax Status", "HOA Status"];
        employeeOrderStatusTabs = ["Tax Status", "HOA Status"];

        //flags for order menu
        orderModule = [
          { add_button: true },
          { export_button: true },
          { qualia_button: true },
        ];

        //flags for orderlist toolbar in order table
        orderListToolbar = [
          { edit_order: true },
          { clone_order: true },
          { email_pdf: true },
          { stopAutomationBtn: true },
          { download_pdf: true },
          { assign_order: true },
          { hoa_assign_order: true },
          { delete_order: true },
          { update_order: true },

          { history: true },
          { assign_me: true },
          { add_notice_flag: true },
          { hoa_orders_toggle: true },
        ];
        // orders table
        orderTableColumn = [{ notice: true }];
        newInterNalOrder = {
          DeleiverEmail: ["Email", "StopAutomation", "Deliver"],
          AddClone: ["Add", "Clone", "View"],
          InputHistory: ["Input", "History", "JSON"],
          Validation: ["Validation", "Conflicts"],
          DeleteEdit: ["Delete", "Edit"],
          DownloadView: ["Download", "View"],
          AutomationSend: ["Automation", "Send"],
          Assign: ["Assign"],
        };
        //flags for internal order
        internalOrder = [
          { deliver_certificate: true },
          { order_summary: true },
          { clone_order: true },
          { run_validation: true },
          { assignMe: true },
          { conflicts_action_required: true },
          { delete_order: true },
          { download_pdf: true },
          { initialize_automation: true },
          { email_pdf: true },
          { stopAutomationBtn: true },
          { history: true },
          { view_documents: true },
          { view_parcel: true },
          { edit_order: true },
          { add_parcel: true },
          // flags for edit order
          { order_information: true },
          { upload_file: true },
          { display_comments: true },
          { read_comments: true },
          { additional_notes: false },
          // flags for hoa
          { hoa: true },
          { hoa_assigned: true },
          { hoa_due_date: true },
          { hoa_status: true },
        ];
        newInterNalParcel = {
          DeleiverEmail: ["Email"],
          AddClone: ["Add", "View"],
          Validation: ["Validation", "Conflicts"],
          DeleteEdit: ["Delete", "Edit"],
          DownloadView: ["Download", "View"],
          History: ["History"],
        };
        //flags for parcel in order
        parcelEditInOrder = [
          { run_validation: true },
          { assignMe: true },
          { conflicts_action_required: true },
          { delete_parcel: true },
          { download_pdf: true },
          { email_pdf: true },
          { stopAutomationBtn: true },
          { view_parcel: true },
          { view_documents: true },
          { history: true },
          { add_parcel: true },
          { edit_parcel: true },

          // flags for taxcollector in parcel
          { edit_taxcollector: true },
          { delete_taxcollector: true },
          { taxcollector: true },
          { taxcollector_bills: true },
          { display_comments: true },
          { read_comments: true },
          { supporting_docs: true },
          //flags for hoa in parcel
          { hoa_add_button: true },
          { hoa_edit_button: true },
        ];

        noticeFlag = ["Notice to Purchaser"];
        jurisdictionTabs = ["Tax Collector", "Jurisdiction", "Mapping"];
        parcelTabs = ["Property Browser", "Manual Entry"];
        break;
      //Vendor Examiner
      case 14:
        //flags for side menu
        flags = [
          "myDashboard",
          "orders",
          "reports",
          "tools",
          "settings",
          // "notice",
        ];
        //flags for userroutes
        userRoutes = [
          "dashboard",
          "notice",
          "order-editor",
          "tools",
          "settings",
          "reports",
          "order-wizard",
          "orders",
        ];

        //flags for userType dropdown
        useTypeListFlag = ["Vendor User"];

        //tabs for settings menu
        settingsTabs = [
          "users",
          // "support",
        ];
        //flags for settings-table-column listing in vendor,customer,branch &user
        tableColumn = [{ vendor: false }, { customer: true }, { branch: true }];

        //settings-branch menu flags
        branchModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];
        //settings-users menu flags
        usersModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];

        //tabs for tools menu
        toolsTabs = ["taxcollector", "jurisdiction"];

        //tools-taxcollector  menu flags
        taxCollectorModule = [
          { add_button: false },
          { action: false },
          { active_status: false },
        ];

        //tools-jurisdiction  menu flags
        jurisdictionModule = [
          { add_button: false },
          { action: false },
          { active_status: false },
        ];

        //tabs for report menu
        reportsTabs = ["Notice"];

        //flags for order menu
        orderModule = [
          { add_button: true },
          { export_button: true },
          { qualia_button: true },
        ];

        //flags for orderlist toolbar in order table
        orderListToolbar = [
          { edit_order: true },
          { clone_order: true },
          { email_pdf: true },
          { stopAutomationBtn: true },
          { download_pdf: true },
          { assign_order: true },
          { hoa_assign_order: true },
          { delete_order: false },
          { update_order: true },
          { history: true },
          { assign_me: true },
          { add_notice_flag: true },
          { hoa_orders_toggle: true },
        ];
        // orders table
        orderTableColumn = [{ notice: true }];
        newInterNalOrder = {
          DeleiverEmail: ["Email", "StopAutomation", "Deliver"],
          AddClone: ["Add", "Clone", "View"],
          InputHistory: ["Input", "History", "JSON"],
          Validation: ["Validation", "Conflicts"],
          DeleteEdit: ["Delete", "Edit"],
          DownloadView: ["Download", "View"],
          AutomationSend: ["Automation", "Send"],
          Assign: ["Assign"],
        };
        //flags for internal order
        internalOrder = [
          { deliver_certificate: true },
          { order_summary: true },
          { clone_order: true },
          { run_validation: true },
          { assignMe: true },
          { conflicts_action_required: true },
          { delete_order: true },
          { download_pdf: true },
          { initialize_automation: true },
          { email_pdf: true },
          { stopAutomationBtn: true },
          { history: true },
          { view_documents: true },
          { view_parcel: true },
          { edit_order: true },
          { add_parcel: true },
          // flags for edit order
          { order_information: true },
          { upload_file: true },
          { display_comments: true },
          { read_comments: true },
          { additional_notes: false },
          // flags for hoa
          { hoa: true },
          { hoa_assigned: true },
          { hoa_due_date: true },
          { hoa_status: true },
          //flag for assign me btn
          { assign_me_btn: true }
        ];
        newInterNalParcel = {
          DeleiverEmail: ["Email"],
          AddClone: ["Add", "View"],
          Validation: ["Validation", "Conflicts"],
          DeleteEdit: ["Delete", "Edit"],
          DownloadView: ["Download", "View"],
          History: ["History"],
        };
        //flags for parcel in order
        parcelEditInOrder = [
          { run_validation: true },
          { assignMe: true },
          { conflicts_action_required: true },
          { delete_parcel: true },
          { download_pdf: true },
          { email_pdf: true },
          { stopAutomationBtn: true },
          { view_parcel: true },
          { view_documents: true },
          { history: true },
          { add_parcel: true },
          { edit_parcel: true },

          // flags for taxcollector in parcel
          { edit_taxcollector: true },
          { delete_taxcollector: true },
          { taxcollector: true },
          { taxcollector_bills: true },
          { display_comments: true },
          { read_comments: true },
          { supporting_docs: true },
          //flags for hoa in parcel
          { hoa_add_button: true },
          { hoa_edit_button: true },
        ];
        noticeFlag = ["Notice to Purchaser"];
        jurisdictionTabs = ["Tax Collector", "Jurisdiction", "Mapping"];
        parcelTabs = ["Property Browser", "Manual Entry"];
        break;
      //Vendor Customer User
      case 15:
        //flags for side menu
        flags = [
          // "myDashboard",
          "orders",
          "reports",
          // "notice",
        ];
        //flags for userroutes
        userRoutes = [
          // "dashboard",
          "order-editor",
          "notice",
          "reports",
          "order-wizard",
          "orders",
        ];

        //flags for userType dropdown
        useTypeListFlag = [
          "Vendor Admin",
          "Vendor User",
          "Customer Admin",
          "Customer User",
          "Branch Admin",
          "Branch User",
          "HOA Admin",
          "Accountant",
          "Customer Power User",
          "Closer",
          "Not A User",
          "Vendor Manager",
          "Vendor Examiner",
          "Vendor Customer User",
          "Customer Manager",
          "Customer Accoutant",
          "Customer HOA",
        ];

        //tabs for report menu
        reportsTabs = ["Notice"];

        //flags for order menu
        orderModule = [
          { add_button: true },
          { export_button: true },
          { qualia_button: true },
        ];

        //flags for orderlist toolbar in order table
        orderListToolbar = [
          { edit_order: true },
          { clone_order: false },
          { email_pdf: true },
          { stopAutomationBtn: true },
          { download_pdf: true },
          { assign_order: false },
          { delete_order: true },
          { update_order: true },
          { history: true },
          { assign_me: false },
          { add_notice_flag: true },
          { hoa_orders_toggle: true },
        ];
        // orders table
        orderTableColumn = [{ notice: true }];
        newInterNalOrder = {
          DeleiverEmail: ["Email", "StopAutomation", "Deliver"],
          AddClone: ["Add", "View"],
          InputHistory: ["Input", "History", "JSON"],
          DeleteEdit: ["Edit"],
          DownloadView: ["Download", "View"],
          AutomationSend: ["Automation", "Send"],
          Assign: ["Assign"],
        };
        //flags for internal order
        internalOrder = [
          { deliver_certificate: true },
          { order_summary: true },
          { clone_order: false },
          { run_validation: false },
          { conflicts_action_required: false },
          { delete_order: false },
          { download_pdf: true },
          { initialize_automation: true },
          { email_pdf: true },
          { stopAutomationBtn: true },
          { history: true },
          { view_documents: true },
          { view_parcel: true },
          { edit_order: true },
          { add_parcel: true },
          // flags for edit order
          { order_information: true },
          { upload_file: true },
          { display_comments: false },
          { read_comments: false },
          { additional_notes: true },
          // flags for hoa
          { hoa: false },
          { hoa_assigned: false },
          { hoa_due_date: false },
          { hoa_status: false },
          //flag for assign me button
          { assign_me_btn: true }
        ];
        newInterNalParcel = {
          DeleiverEmail: ["Email"],
          AddClone: ["Add", "View"],
          DeleteEdit: ["Delete", "Edit"],
          DownloadView: ["Download", "View"],
        };
        //flags for parcel in order
        parcelEditInOrder = [
          { run_validation: false },
          { conflicts_action_required: false },
          { delete_parcel: true },
          { download_pdf: true },
          { email_pdf: true },
          { stopAutomationBtn: true },
          { view_parcel: true },
          { view_documents: true },
          { history: false },
          { add_parcel: true },
          { edit_parcel: false },

          // flags for taxcollector in parcel
          { edit_taxcollector: false },
          { delete_taxcollector: false },
          { taxcollector: false },
          { taxcollector_bills: false },
          { display_comments: false },
          { read_comments: false },
          { supporting_docs: false },
          //flags for hoa in parcel
          { hoa_add_button: false },
          { hoa_edit_button: false },
          { hoa_delete_button: false },
        ];

        noticeFlag = ["Notice to Purchaser"];
        jurisdictionTabs = ["Tax Collector", "Jurisdiction", "Mapping"];
        parcelTabs = ["Property Browser", "Manual Entry"];
        break;
      //Customer Manager
      case 16:
        //flags for side menu
        flags = [
          "myDashboard",
          "orders",
          "reports",
          // "notice",
        ];
        //flags for userroutes
        userRoutes = [
          "dashboard",
          "notice",
          "order-editor",
          "reports",
          "order-wizard",
          "orders",
        ];

        //flags for userType dropdown
        useTypeListFlag = [
          "Vendor Admin",
          "Vendor User",
          "Customer Admin",
          "Customer User",
          "Branch Admin",
          "Branch User",
          "HOA Admin",
          "Accountant",
          "Customer Power User",
          "Closer",
          "Not A User",
          "Vendor Manager",
          "Vendor Examiner",
          "Vendor Customer User",
          "Customer Manager",
          "Customer Accoutant",
          "Customer HOA",
        ];

        //tabs for report menu
        reportsTabs = ["Notice"];

        //flags for order menu
        orderModule = [
          { add_button: true },
          { export_button: true },
          { qualia_button: true },
        ];

        //flags for orderlist toolbar in order table
        orderListToolbar = [
          { edit_order: true },
          { clone_order: false },
          { email_pdf: true },
          { stopAutomationBtn: true },
          { download_pdf: true },
          { assign_order: false },
          { delete_order: true },
          { update_order: true },
          { history: true },
          { assign_me: false },
          { add_notice_flag: true },
          { hoa_orders_toggle: true },
        ];
        // orders table
        orderTableColumn = [{ notice: true }];
        newInterNalOrder = {
          DeleiverEmail: ["Email", "StopAutomation", "Deliver"],
          AddClone: ["Add", "View"],
          InputHistory: ["Input", "History", "JSON"],
          DeleteEdit: ["Edit"],
          DownloadView: ["Download", "View"],
          AutomationSend: ["Automation", "Send"],
          Assign: ["Assign"],
        };
        //flags for internal order
        internalOrder = [
          { deliver_certificate: true },
          { order_summary: true },
          { clone_order: false },
          { run_validation: false },
          { conflicts_action_required: false },
          { delete_order: false },
          { download_pdf: true },
          { initialize_automation: true },
          { email_pdf: true },
          { stopAutomationBtn: true },
          { history: true },
          { view_documents: true },
          { view_parcel: true },
          { edit_order: true },
          { add_parcel: true },
          // flags for edit order
          { order_information: true },
          { upload_file: true },
          { display_comments: false },
          { read_comments: false },
          { additional_notes: true },
          // flags for hoa
          { hoa: false },
          { hoa_assigned: false },
          { hoa_due_date: false },
          { hoa_status: false },
          //flag for assign me btn
          { assign_me_btn: true }
        ];
        newInterNalParcel = {
          DeleiverEmail: ["Email"],
          AddClone: ["Add", "View"],
          DeleteEdit: ["Delete", "Edit"],
          DownloadView: ["Download", "View"],
        };
        //flags for parcel in order
        parcelEditInOrder = [
          { run_validation: false },
          { conflicts_action_required: false },
          { delete_parcel: true },
          { download_pdf: true },
          { email_pdf: true },
          { stopAutomationBtn: true },
          { view_parcel: true },
          { view_documents: true },
          { history: false },
          { add_parcel: true },
          { edit_parcel: false },

          // flags for taxcollector in parcel
          { edit_taxcollector: false },
          { delete_taxcollector: false },
          { taxcollector: false },
          { taxcollector_bills: false },
          { display_comments: false },
          { read_comments: false },
          { supporting_docs: false },
          //flags for hoa in parcel
          { hoa_add_button: false },
          { hoa_edit_button: false },
          { hoa_delete_button: false },
        ];

        noticeFlag = ["Notice to Purchaser"];
        jurisdictionTabs = ["Tax Collector", "Jurisdiction", "Mapping"];
        parcelTabs = ["Property Browser", "Manual Entry"];
        break;
      //Customer Accountant
      case 17:
        //flags for side menu
        flags = ["myDashboard", "reports"];
        //flags for userroutes
        userRoutes = ["notice", "dashboard", "reports"];

        //flags for userType dropdown
        useTypeListFlag = [
          "Vendor Admin",
          "Vendor User",
          "Customer Admin",
          "Customer User",
          "Branch Admin",
          "Branch User",
          "HOA Admin",
          "Accountant",
          "Customer Power User",
          "Closer",
          "Not A User",
          "Vendor Manager",
          "Vendor Examiner",
        ];

        //tabs for report menu
        reportsTabs = [
          "Notice",
          "OrderReport",
          "InvoiceReport",
          "OrdersStatus",
          "Employee",
          "Payment"
        ];
        orderStatusTabs = ["Tax Status", "HOA Status"];
        employeeOrderStatusTabs = ["Tax Status", "HOA Status"];
        noticeFlag = ["Notice to Purchaser"];
        jurisdictionTabs = ["Tax Collector", "Jurisdiction", "Mapping"];
        parcelTabs = ["Property Browser", "Manual Entry"];
        break;
      //Customer HOA
      case 18:
        //flags for side menu
        flags = ["myDashboard", "orders", "tools", "settings"];
        //flags for userroutes
        userRoutes = [
          "dashboard",
          "notice",
          "tools",
          "settings",
          "order-editor",
          "orders",
        ];

        //flags for userType dropdown
        useTypeListFlag = [
          "Vendor Admin",
          "Vendor User",
          "Customer Admin",
          "Customer User",
          "Branch Admin",
          "Branch User",
          "HOA Admin",
          "Accountant",
          "Customer Power User",
          "Closer",
          "Not A User",
          "Vendor Manager",
          "Vendor Examiner",
          "Vendor Customer User",
          "Customer Manager",
          "Customer Accoutant",
          "Customer HOA",
        ];

        // flags for settings menu
        settingsTabs = ["type"];

        //flags  for settings-type menu
        typeTabs = ["HOA Type", "Third Party Document", "HOA Fee Type"];
        //settings-type menu action flags
        typeModule = [{ add_button: true }, { action: true }];

        //tabs for tools menu
        toolsTabs = ["hoa", "hoa-mgmt"];

        //tools-hoa-mgmt menu flags
        hoaMgmtModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];

        //tools-hoa menu flags
        hoaModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];
        subdivisionModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];



        //flags for order menu
        orderModule = [
          { add_button: true },
          { export_button: true },
          { qualia_button: true },
        ];

        //flags for orderlist toolbar in order table
        orderListToolbar = [
          { edit_order: true },
          { clone_order: false },
          { email_pdf: true },
          { stopAutomationBtn: true },
          { download_pdf: true },
          { assign_order: true },
          { hoa_assign_order: true },
          { delete_order: true },
          { update_order: true },
          { history: true },
          { assign_me: true },
          { add_notice_flag: true },
          { hoa_orders_toggle: true },
        ];
        // orders table
        orderTableColumn = [{ notice: true }];
        newInterNalOrder = {
          DeleiverEmail: ["Email", "StopAutomation", "Deliver"],
          AddClone: ["Add", "Clone", "View"],
          InputHistory: ["Input", "History", "JSON"],
          Validation: ["Validation", "Conflicts"],
          DeleteEdit: ["Delete", "Edit"],
          DownloadView: ["Download", "View"],
          AutomationSend: ["Automation", "Send"],
          Assign: ["Assign"],
        };
        //flags for internal order
        internalOrder = [
          { deliver_certificate: true },
          { order_summary: true },
          { clone_order: true },
          { run_validation: true },
          { assignMe: true },
          { conflicts_action_required: true },
          { delete_order: true },
          { download_pdf: true },
          { initialize_automation: true },
          { email_pdf: true },
          { stopAutomationBtn: true },
          { history: true },
          { view_documents: true },
          { view_parcel: true },
          { edit_order: true },
          { add_parcel: true },
          // flags for edit order
          { order_information: true },
          { upload_file: true },
          { display_comments: true },
          { read_comments: true },
          { additional_notes: false },
          // flags for hoa
          { hoa: true },
          { hoa_assigned: true },
          { hoa_due_date: true },
          { hoa_status: true },
          //flag for assign me btn
          { assign_me_btn: true }
        ];
        newInterNalParcel = {
          DeleiverEmail: ["Email"],
          AddClone: ["Add", "View"],
          Validation: ["Validation", "Conflicts"],
          DeleteEdit: ["Delete", "Edit"],
          DownloadView: ["Download", "View"],
          History: ["History"],
        };
        //flags for parcel edit in order
        parcelEditInOrder = [
          { run_validation: true },
          { assignMe: true },
          { conflicts_action_required: true },
          { delete_parcel: true },
          { download_pdf: true },
          { email_pdf: true },
          { stopAutomationBtn: true },
          { view_parcel: true },
          { view_documents: true },
          { history: true },
          { add_parcel: true },
          { edit_parcel: true },

          // flags for taxcollector in parcel edit
          { edit_taxcollector: true },
          { delete_taxcollector: true },
          { taxcollector: false },
          { taxcollector_bills: true },
          { display_comments: true },
          { read_comments: true },
          { supporting_docs: true },
          //flags for hoa in parcel
          { hoa_add_button: true },
          { hoa_edit_button: true },
          { hoa_delete_button: true },
          { hoa_email: true },
          { hoa_download: true },
        ];

        noticeFlag = ["Notice to Purchaser"];
        jurisdictionTabs = ["Tax Collector", "Jurisdiction", "Mapping"];
        parcelTabs = ["Property Browser", "Manual Entry"];
        break;

      //Vendor Orders
      case 20:
        //flags for side menu
        flags = [
          //"myDashboard",
          "orders",
          //"reports",
          //"tools",
          // "settings",
          // "notice",
        ];
        //flags for userroutes
        userRoutes = [
          //"dashboard",
          //"notice",
          "order-editor",
          //"tools",
          //"settings",
          //"reports",
          "order-wizard",
          "orders",
        ];

        //flags for userType dropdown
        useTypeListFlag = ["Vendor User"];

        //tabs for settings menu
        settingsTabs = [
          "users",
          // "support",
        ];
        //flags for settings-table-column listing in vendor,customer,branch &user
        tableColumn = [{ vendor: false }, { customer: true }, { branch: true }];

        //settings-branch menu flags
        branchModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];
        //settings-users menu flags
        usersModule = [
          { add_button: true },
          { action: true },
          { active_status: true },
        ];

        //tabs for tools menu
        toolsTabs = ["taxcollector", "jurisdiction"];

        //tools-taxcollector  menu flags
        taxCollectorModule = [
          { add_button: false },
          { action: false },
          { active_status: false },
        ];

        //tools-jurisdiction  menu flags
        jurisdictionModule = [
          { add_button: false },
          { action: false },
          { active_status: false },
        ];

        //tabs for report menu
        reportsTabs = ["Notice"];

        //flags for order menu
        orderModule = [
          { add_button: true },
          { export_button: true },
          { qualia_button: false },
        ];

        //flags for orderlist toolbar in order table
        orderListToolbar = [
          { edit_order: true },
          { clone_order: true },
          { email_pdf: true },
          { stopAutomationBtn: true },
          { download_pdf: true },
          { assign_order: true },
          { hoa_assign_order: true },
          { delete_order: false },
          { update_order: true },
          { history: true },
          { assign_me: true },
          { add_notice_flag: true },
          { hoa_orders_toggle: false },
        ];
        // orders table
        orderTableColumn = [{ notice: true }];
        newInterNalOrder = {
          DeleiverEmail: ["Email", "StopAutomation", "Deliver"],
          AddClone: ["Add", "Clone", "View"],
          InputHistory: ["Input", "History", "JSON"],
          Validation: ["Validation", "Conflicts"],
          DeleteEdit: ["Delete", "Edit"],
          DownloadView: ["Download", "View"],
          AutomationSend: ["Automation", "Send"],
          Assign: ["Assign"],
        };
        //flags for internal order
        internalOrder = [
          { deliver_certificate: true },
          { order_summary: true },
          { clone_order: true },
          { run_validation: true },
          { assignMe: true },
          { conflicts_action_required: true },
          { delete_order: true },
          { download_pdf: true },
          { initialize_automation: true },
          { email_pdf: true },
          { stopAutomationBtn: true },
          { history: true },
          { view_documents: true },
          { view_parcel: true },
          { edit_order: true },
          { add_parcel: true },
          // flags for edit order
          { order_information: true },
          { upload_file: true },
          { display_comments: true },
          { read_comments: true },
          { additional_notes: false },
          // flags for hoa
          { hoa: true },
          { hoa_assigned: true },
          { hoa_due_date: true },
          { hoa_status: true },
          //flag for assign me btn
          { assign_me_btn: true }
        ];
        newInterNalParcel = {
          DeleiverEmail: ["Email"],
          AddClone: ["Add", "View"],
          Validation: ["Validation", "Conflicts"],
          DeleteEdit: ["Delete", "Edit"],
          DownloadView: ["Download", "View"],
          History: ["History"],
        };
        //flags for parcel in order
        parcelEditInOrder = [
          { run_validation: true },
          { assignMe: true },
          { conflicts_action_required: true },
          { delete_parcel: true },
          { download_pdf: true },
          { email_pdf: true },
          { stopAutomationBtn: true },
          { view_parcel: true },
          { view_documents: true },
          { history: true },
          { add_parcel: true },
          { edit_parcel: true },

          // flags for taxcollector in parcel
          { edit_taxcollector: true },
          { delete_taxcollector: true },
          { taxcollector: true },
          { taxcollector_bills: true },
          { display_comments: true },
          { read_comments: true },
          { supporting_docs: true },
          //flags for hoa in parcel
          { hoa_add_button: true },
          { hoa_edit_button: true },
        ];
        noticeFlag = ["Notice to Purchaser"];
        jurisdictionTabs = ["Tax Collector", "Jurisdiction", "Mapping"];
        parcelTabs = ["Property Browser", "Manual Entry"];
        break;
    }
  }

  // ( || "/forgot-password" || "/resetpassword")
  useEffect(() => {
    document.addEventListener("click", () => {
      if (getCookie("user")) {
        //if automation not available
        // if (!getCookie("automation")) {
        //   //login req for automation
        //   loginTest();
        // }
        let encrypted = CryptoJS.AES.encrypt(
          JSON.stringify({ token: token, user: user }),
          "$2b$10$hcPg5zgrb0tt8cg9UPgT1ex7c4VTc22bALT8pjNcfNMRbXZToEI46"
        ).toString();
        //cookies set
        setCookies("user", encrypted, {
          path: "/",
          maxAge: 14400,
        });
        dispatch(
          userLoginAction({
            token: token,
            user: user,
            mode: false,
          })
        );
      } else {
        window.location.href = "/session-expired";
      }
    });
    switchFun(user?.user_type_id);
    dispatch(
      flagRolesAction({
        flags,
        tabs,
        add_button,
        vendorModule,
        customerModule,
        branchModule,
        usersModule,
        typeModule,
        userTypeModule,
        tableColumn,
        labelModule,
        hoaModule,
        hoaMgmtModule,
        taxCollectorModule,
        jurisdictionModule,
        parcelStatusModule,
        orderStatusModule,
        hoaStatusModule,
        exemptionModule,
        notificationModule,
        commentsModule,
        cadModule,
        taxcadModule,
        orderModule,
        orderListToolbar,
        internalOrder,
        newInterNalOrder,
        newInterNalParcel,
        parcelEditInOrder,
        add_labels,
        edit_keylabels,
        edit_valuelabels,
        userRoutes,
        settingsTabs,
        authColumn,
        fieldFlag,
        supportTabs,
        useTypeListFlag,
        typeTabs,
        toolsTabs,
        jurisdictionTabs,
        noticeFlag,
        reportsTabs,
        parcelTabs,
        cadTabs,
        taxcadTabs,
        orderStatusTabs,
        orderTableColumn,
        employeeOrderStatusTabs,
        subdivisionModule
      })
    );
    //  calling and setiing value for Switch case
  }, [quickUserUpdate]);
  //create a filter for menus
  const newArr = [];
  // getSwitchVal(user?.user_type_id);

  //menus base on flags
  for (const iterator in authRoles) {
    const filterMenus = items.filter((ele) => {
      return ele.menu.match(authRoles[iterator]);
    });

    filterMenus.forEach((e) => newArr.push(e));
  }

  //create session expire
  // console.log(getCookie("user"))

  return (
    <>
      <MyHeader />
      <Layout>
        <Sider trigger={null} collapsible collapsed={collapsed}>
          <Menu className="position-relative my-3">
            <div className="float-end sidebar-icon">
              <div className="trigger" onClick={() => setCollapsed(!collapsed)}>
                {!collapsed ? (
                  <FaAngleLeft color="#fff" />
                ) : (
                  <FaAngleRight color="#fff" />
                )}
              </div>
            </div>
          </Menu>
          <Menu
            onClick={() => {
              dispatch(addOrderData({}))
              dispatch(parcelListHandler([]))
              dispatch(viewPdfDocuementHandler(null))
            }}
            theme="dark"
            defaultSelectedKeys={["/"]}
            selectedKeys={[
              window.location.pathname === "/notice"
                ? "/dashboard"
                : window.location.pathname === "/order-editor"
                  ? "/order"
                  : window.location.pathname.split("/")[1] === "order-editor"
                    ? "/order"
                    : window.location.pathname.split("/")[1] === "order-editor" || window.location.pathname.split("/")[1] === `order-status`
                      ?
                      "/order"
                      : window.location.pathname,
            ]}
            mode="inline"
            items={newArr}
          />
        </Sider>
        <Layout className="site-layout">
          <Content className="site-layout-background position-relative">
            <div className="outlet">
              <p className="ms-auto d-flex justify-content-end" style={{ fontSize: 13, color: "gray", width: 100 }}>V {config.version}</p>
              <Outlet />
            </div>
            {/* <Footer>
              <div className="bg-white m-0 text-center py-3 border-top h6">
                <FaRegCopyright /> 2022 CertSimple
              </div>
            </Footer> */}
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default Aside;
