import { Button } from "antd";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import AppSelect from "../../../Components/AppSelect/AppSelect";
import AppSpinner from "../../../Components/AppSpinner/AppSpinner";
import { useExaminerOrderQuery } from "../../../Redux/Services/Orders";

export default function ExaminerAssignModal({
  showModalInitalState,
  value,
  onSubmit,
  title,
  onChange,
  isLoading,
}) {
  const [examinerOptionsData, setExaminerOptionsData] = useState([]);

  const { token } = useSelector((state) => state.auth);

  //calling rtk query for examiner list
  const orderExaminerApi = useExaminerOrderQuery(token);

  ///useEffect for examiner list
  useEffect(() => {
    if (orderExaminerApi.isSuccess) {
      setExaminerOptionsData(orderExaminerApi.data.data);
    }
    if (orderExaminerApi.isError) {
      setExaminerOptionsData([]);
    }
  }, [orderExaminerApi.isFetching]);

  useEffect(() => {
    orderExaminerApi.refetch();
  }, [showModalInitalState]);

  return (
    <div className="position-relative">
      {(orderExaminerApi.isFetching || isLoading) && <AppSpinner />}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(value);
        }}
        className="py-3"
      >
        <AppSelect
          value={value}
          required
          label={`${title} Examiner`}
          errorMessage="Please select examiner!"
          classStyle={"border p-1 mt-3 w-100 "}
          defaultSet={showModalInitalState}
          onChange={onChange}
          options={
            <>
              <option value={""}>Select Examiner</option>
              {examinerOptionsData?.map((ele) => {
                if (!isLoading || !orderExaminerApi.isFetching) {
                  return (
                    <option key={ele.id} value={ele.id}>
                      {ele.user_name}
                    </option>
                  );
                }
                return null;
              })}
            </>
          }
        />
        <Button
          className="fs-12 text-white mt-2"
          type="primary"
          size="small"
          htmlType="submit"
        >
          Assign
        </Button>
      </form>
    </div>
  );
}
