import { Col, Row, Alert, notification } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import AppButton from "../../../Components/AppButton/AppButton";
import AppInput from "../../../Components/AppInput/AppInput";
import AppSwitch from "../../../Components/AppSwitch/AppSwitch";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import InputColor from "../../../Components/InputColor/InputColor";
import { convertBase64 } from "../../../Controllers/Functions";
import { AppFile } from "../../../Components/AppFile/AppFile";
import AppSelect from "../../../Components/AppSelect/AppSelect";
import { useEditactiveCountyQuery, useUpdateactiveCountyMutation } from "../../../Redux/Services/ActiveCountyApi";
import { useGetCountyListMutation } from "../../../Redux/Services/county";
import AppSpinner from "../../../Components/AppSpinner/AppSpinner";
import { MdOutlineTextRotateVertical } from "react-icons/md";
export default function ActiveCountyModal({ afterClose, setShowHide, editId,initialValues }) {
  const [values, setValues] = useState({
    state_id: "",
    county_id: "",
    activity_status: "",
  });
  //status
  const [getError, setGetError] = useState(null);
  const [,setGetSuccess] = useState(null);
  //setfile Error
  const [,setFileError] = useState(null);

  const [stateoptions, setStateOptions] = useState([
    { id: "", state_name: "" },
  ]);
  const [countyOptions, setCountyOptions] = useState([]);
    //calling redux stor for state list
    const { stateList } = useSelector((state) => state.stateList);

    const countyList2 = useGetCountyListMutation();
    const [getCountyList] = countyList2;

  //clear error function
  function clearStatus() {
    setGetError(null);
    setGetSuccess(null);
  }

  //SETdEFAULT
  useEffect(() => {
    setCountyOptions([]);
  setStateOptions (stateList);
    setValues({
      state_id: "",
      county_id: "",
      activity_status: "",
    });
    setFileError(null);
  }, [afterClose]);

  useEffect(() => {
    if (countyList2[1].isSuccess) {
      setCountyOptions(countyList2[1].data?.data);
    }
  }, [countyList2[1].isLoading]);

  const handleReset = (e) => {
    setValues({
      ...values,
      state_id:"",county_id:"",activity_status:""
    });
    if (editId) {
      editNotificationApi .refetch();
    }
    clearStatus();
  };

  // calling redux store
  const { token, user } = useSelector((state) => state.auth);

  //calling rtk query hook for update
  const updateNotificationApi = useUpdateactiveCountyMutation();
  const [updateNotification] = updateNotificationApi;
  //calling rtk query hook for geting Notification
  const editNotificationApi = useEditactiveCountyQuery(
    { id: editId, token: token },
    { skip: !editId }
  );

  //useEffect for editNotification
  useEffect(() => {
    if (editNotificationApi.isSuccess) {
      //filter values from user branchList and customer list for initials values
      setValues(editNotificationApi.data?.data[0]);

      clearStatus();
    }
    if (editNotificationApi.isError) {
      var errorData = [];
      for (const prop in editNotificationApi[1].error.data?.error) {
        errorData.push(editNotificationApi[1].error.data?.error[prop]);
      }
      setGetError(
        errorData[0] ||
          editNotificationApi.error.data.message ||
          editNotificationApi.error.data ||
          editNotificationApi.error.error ||
          editNotificationApi.error.data?.msg.sqlMessage
      );
    }
  }, [editNotificationApi]);

  //update notification
  useEffect(() => {
    if (updateNotificationApi[1].isSuccess) {
      setShowHide(false);
      clearStatus();
      ///after submit details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: updateNotificationApi[1].data?.message,
      });
    }
    if (updateNotificationApi[1].error) {
      var errorData = [];
      for (const prop in updateNotificationApi[1].error.data?.error) {
        errorData.push(updateNotificationApi[1].error.data?.error[prop]);
      }
      setGetError(
        errorData[0] ||
          updateNotificationApi[1].error.data.message ||
          updateNotificationApi[1].error.error ||
          updateNotificationApi[1].error.data?.msg.sqlMessage
      );
    }
  }, [updateNotificationApi[1].isLoading]);

  const appSwitchHandler = (checked, value) => {
    setValues({ ...values, [checked]: value === true ? 1 : 0 });
    //claser status values
    clearStatus();
  };

  const inputs = [
    {
        id: 1,
  
        name: "state_id",
  
        type: "select",
  
        value: values.state_id || "",
  
        column: "3",
  
        placeholder: "Select State",
  
        errorMessage: "Please select state!",
        disabled:true,
        label: "State",
  
        options: stateoptions,
  
        required: true,
  
        tab: false,
      },
  
      {
        id: 6,
  
        name: "county_id",
  
        type: "select",
  
        column: "3",
  
        value: values.county_id || "",
        disabled: true,
  
        placeholder: "Select County Name",
  
        errorMessage: "Please select county!",
  
        label: "County",
  
        options: countyOptions,
  
        required: true,
  
        tab: false,
      },
    {
      id: 13,
      name: "activity_status",
      type: "switch",
      checked: values.activity_status || "",
      column: "4 ",
      placeholder: "Active",
      label: "Active",
      required: true,
      margin: "3",
    },
  ];

  //colorchange
  const selectHandler = (property, value) => {
    clearStatus();

    //calling rtk  query for geting county list for options
    const state_id = { state_id: Number(value) };
    if (property === "state_id") {
      if (value) {
         getCountyList({ state_id, token: token });
        setValues({
          ...values,
          [property]: value,
        });
      } else {
        setCountyOptions([]);
        setValues({
          ...values,
          [property]: value,
          county_id: "",
        });
      }
    }
    setValues({ ...values, [property]: value });
  };

  //adding new color Notification with name and color
  const handleSubmit = (e) => {
    e.preventDefault();
    if (editId) {
      updateNotification({
        data: {
          ...values,
          updated_by: user?.user_id,
        },
        id: editId,
        token: token,
      });
    }
  };
  //useEffect for editCustomer refecth
  useEffect(() => {
    editNotificationApi.refetch();
    getCountyList({state_id:values?.state_id, token: token });
    setValues({
      ...values,
    });

    clearStatus();
  }, [afterClose]);

  return (
    <>
      <div className="my-4 border-bottom border-2 ">
        <h5>Edit Active County</h5>
      </div>
      <div className="my-3">
        {getError && (
          <Alert type="error" message={getError} closable showIcon />
        )}
      </div>
      <Form onSubmit={handleSubmit}>
      {(editNotificationApi .isFetching || updateNotificationApi [1].isLoading) && (
          <AppSpinner />
        )}
        <Row className="w-100  ">
        <div className="col-12 col-lg-12">
              <div className="row">
          {inputs.map((input) => (
            <>
               {input.type === "select" &&
                      input.name === "state_id" &&
                      input.tab === false && (
                        <AppSelect
                          defaultSet={afterClose}
                          key={input.id}
                          {...input}
                          onChange={selectHandler}
                          options={
                            <>
                              <option value={""}>Select State</option>
                              {input?.options?.map((ele) => {
                                return (
                                  <option key={ele.id} value={ele.id}>
                                    {ele.state_name}
                                  </option>
                                );
                              })}
                            </>
                          }
                        />
                      )}

                    {input.type === "select" &&
                      input.name === "county_id" &&
                      input.tab === false && (
                        <AppSelect
                          defaultSet={afterClose}
                          key={input.id}
                          {...input}
                          onChange={selectHandler}
                          options={
                            <>
                              <option value={""}>Select County</option>
                              {input?.options?.map((ele) => {
                                return (
                                  <option key={ele.id} value={ele.id}>
                                    {ele.county_name}
                                  </option>
                                );
                              })}
                            </>
                          }
                        />
                      )}
              {input.type === "switch" &&(
                <AppSwitch
                  className="mt-5"
                  key={input.id}
                  {...input}
                  value={values[input.name]}
                  onChange={appSwitchHandler}
                />
              )}
            </>
          ))}
          </div>
          </div>
        </Row>

        <Row>
          <Col className="my-3">
            <div className="d-flex justify-content-center">
              <div className="me-md-2 ">
                <AppButton
                  onClick={handleReset}
                  title={"CLEAR"}
                  variant={"secondary-md"}
                />
              </div>

              <AppButton title={"SAVE"} variant={"primary-md"} />
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
}