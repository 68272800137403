import React, { useState } from "react";
import { Radio } from "antd";
import { Row, Col } from "react-bootstrap";
import { CustomerType } from "./CustomerType/CustomerType";
import { useSelector } from "react-redux";
import { UserType } from "./UserType/UserType";
import { ProductType } from "./ProductType/ProductType";
import { Cycles } from "./Cycles/Cycles";
import { TaxCollectorType } from "./TaxCollectorType/TaxCollectorType";
import { ContactType } from "./ContactType/ContactType";
import { AnnotationType } from "./AnnotationType/AnnotationType";
import { HoaType } from "./HoaType/HoaType";
import { ThirdPartyDocument } from "./ThirdPartyDocuments/ThirdPatyDoc";
import { useEffect } from "react";
import { JurisdictionsType } from "./JurisdictionsType/JurisdictionsType";
import { HoaFeeType } from "./HoaFeeType/HoaFeeType";
import {TransactionType} from "./TransactionType/TransactionType";

export const Type = ({ refresh }) => {
  const [key, setKey] = useState("Customer Type");
  const OnradiohandleChange = (e) => {
    setKey(e.target.value);
  };

  //redux store
  const { typeTabs } = useSelector((state) => state.flagRoles);
  useEffect(() => {
    setKey(typeTabs[0]);
  }, [typeTabs]);
  return (
    <Row className="non-seprate">
      <Col lg="12">
        <Radio.Group onChange={OnradiohandleChange} value={key}>
          {/* tabs showing base on users roles  */}
          {typeTabs &&
            typeTabs.map((ele) => {
              return (
                <Radio key={ele} value={ele} defaultChecked>
                  {ele}
                </Radio>
              );
            })}
        </Radio.Group>
        <div className="non-seprate"></div>
        {key === "Customer Type" && (
          <CustomerType refresh={refresh} childTab={key} />
        )}
          {key === "Transaction Type" && (
          <TransactionType refresh={refresh} childTab={key} />
        )}
        {key === "User Type" && <UserType refresh={refresh} childTab={key} />}
        {key === "Product Type" && (
          <ProductType refresh={refresh} childTab={key} />
        )}
        {key === "Tax Collector Type" && (
          <TaxCollectorType refresh={refresh} childTab={key} />
        )}
        {key === "Jurisdiction Type" && (
          <JurisdictionsType refresh={refresh} childTab={key} />
        )}
        {key === "Contact Type" && (
          <ContactType refresh={refresh} childTab={key} />
        )}
        {key === "Cycles" && <Cycles refresh={refresh} childTab={key} />}
        {key === "Comment Type" && (
          <AnnotationType refresh={refresh} childTab={key} />
        )}
        {key === "HOA Type" && <HoaType refresh={refresh} childTab={key} />}
        {key === "Third Party Document" && (
          <ThirdPartyDocument refresh={refresh} childTab={key} />
        )}
        {key === "HOA Fee Type" && (
          <HoaFeeType refresh={refresh} childTab={key} />
        )}
      </Col>
    </Row>
  );
};
