import { createSlice } from "@reduxjs/toolkit";

const cycleList = createSlice({
  name: "cycleList",
  initialState: {
    cycleList: [],
  },
  reducers: {
    cycleListAction: (state, action) => {
      state.cycleList = action.payload;
    },
  },
});

export const {cycleListAction} = cycleList.actions;

export default cycleList.reducer;
