import React, { useState } from "react";
import {
	FiMessageCircle,
	FiPause,
	FiTrendingUp,
	FiUserCheck,
} from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import { HiOutlineBookOpen } from "react-icons/hi";
import {
	AiFillExclamationCircle,
	AiOutlineCheckCircle,
	AiTwotoneCalendar,
} from "react-icons/ai";
import { RiFileTextLine } from "react-icons/ri";
import { DiGitPullRequest } from "react-icons/di";
import { Card, Col, Row } from "react-bootstrap";
import { BiMap, BiCommentEdit, BiCommentMinus } from "react-icons/bi";
import { BsFillInfoSquareFill } from "react-icons/bs";
import { FiBarChart2 } from "react-icons/fi";
import { useContext } from "react";
import "./dashboard.css";
import { GlobalContext } from "../../App";
import { useOrdersDueDateStatusMutation } from "../../Redux/Services/Orders";
export default function StatusCards(props) {
	const { data = [] } = props;
	const navigate = useNavigate();
	//context api
	const { state } = useContext(GlobalContext);
	const [globalVal, setGlobalVal] = state;
	

	const cardsStyle = [
		{
			id: 1,
			openIcon: <HiOutlineBookOpen size={30} className={"openImg"} />,
		},
		{
			id: 2,
			InProgressIcon: <FiTrendingUp size={30} className={"openImg"} />,
		},
		{
			id: 3,
			HoldIcon: <AiFillExclamationCircle size={30} className={"openImg"} />,
		},
		{
			id: 4,
			cmpleteIcon: <AiOutlineCheckCircle size={30} className={"openImg"} />,
		},
		{
			id: 5,
			pendingIcon: <FiMessageCircle size={30} className={"openImg"} />,
		},
		{
			id: 6,
			ClarifyIcon: <FiPause size={30} className={"openImg"} />,
		},

		{
			id: 7,
			QcIcon: <FiUserCheck size={30} className={"openImg"} />,
		},
		{
			id: 8,
			closeIcon: <RiFileTextLine size={30} className={"openImg"} />,
		},
		{
			id: 9,
			ReqdIcon: <DiGitPullRequest size={30} className={"openImg"} />,
		},
		{
			id: 10,
			DuetodayIcon: <AiTwotoneCalendar size={30} className={"openImg"} />,
		},
		{
			id: 11,
			AceragaAgIcon: <BiMap size={30} className={"openImg"} />,
		},
		{
			id: 12,
			InfoReqIcon: <BsFillInfoSquareFill size={30} className={"openImg"} />,
		},
		{
			id: 13,
			TaxUpdateIcon: <FiBarChart2 size={30} className={"openImg"} />,
		},
		{
			id: 14,
			HoaOpenIcon: <BiCommentMinus size={30} className={"openImg"} />,
		},
		{
			id: 15,
			HoaUpdateIcon: <BiCommentEdit size={30} className={"openImg"} />,
		},
	];

	const handleOrderByStatus = (status_name) =>
	{
		//notice based status
		if (status_name === "Hold" || status_name === "Rush" || status_name === "Delinquent/TaxSuit") {
			navigate("/order", {
				state: {
					notice_name: status_name,
				},
			});
			setGlobalVal({
				...globalVal,
				hoaOrdersListToggle: false,
				orderAllStatusToggle: false,
			});
		} else if (status_name === "HOA Pending" || status_name === "HOA Update") {
			navigate("/order", {
				state: {
					hoastatus_name: status_name,
				},
			});
			setGlobalVal({
				...globalVal,
				hoaOrdersListToggle: true,
				orderAllStatusToggle: false,
			});
		} 

		else if (status_name === "Overdue") {
			navigate("/order", {
				state: {
					over_due: status_name,
				},
			});
			setGlobalVal({
				...globalVal,
				hoaOrdersListToggle: false,
				orderAllStatusToggle: false,
			});
		}
		else if (status_name==="Due Today") {
			navigate("/order", {
				state: {
					due_today:status_name
				},
			});
			setGlobalVal({
				...globalVal,
				hoaOrdersListToggle: false,
				orderAllStatusToggle: false,
			});
		}

		else {
			navigate("/order", {
				state: {
					status_name: status_name,
				},
			});
			setGlobalVal({
				...globalVal,
				hoaOrdersListToggle: false,
				orderAllStatusToggle: false,
			});
		}
	};

	return (
		<>
			<div className="row  status-cards-row p-2">
				<>
					{cardsStyle.map((cardstyleinfo) => (
						<>
							{cardstyleinfo.openIcon && (
								<div className={"col-lg-2 p-1 m-0"} key={cardstyleinfo?.id}>
									<Card
										onClick={() => handleOrderByStatus("Open")}
										className="dash-cards"
										//   style={{ backgroundColor:""}}
									>
										<Row className="p-0 m-2">
											<Col lg={8} className="">
												<h5 className="mt-0 mb-0 header-title-open">
													{"Open"}
												</h5>
												<h4 className="headding-3"> {data?.length!==0?data?.open_orders:0}</h4>
											</Col>
											<Col lg={4} className={"text-center align-item-center"}>
												{cardstyleinfo.openIcon}
											</Col>
										</Row>
									</Card>
								</div>
							)}
							{cardstyleinfo.InProgressIcon && (
								<div className={"col-lg-2 p-1 m-0"} key={cardstyleinfo?.id}>
									<Card
										className="dash-cards"
										onClick={() => handleOrderByStatus("Pending")}
										//   style={{ backgroundColor:""}}
									>
										<Row className="p-0 m-2">
											<Col lg={8} className="flex-left">
												<h5 className="mt-0 mb-0 header-title-open">
													{"Pending"}
													{"  "}
												</h5>
												<h4 className="headding-3">
													{" "}
													{data?.length!==0?data?.pending_orders:0}
												</h4>
											</Col>
											<Col lg={4} className={"text-center"}>
												{cardstyleinfo.InProgressIcon}
											</Col>
										</Row>
									</Card>
								</div>
							)}
							{cardstyleinfo.HoldIcon && (
								<div className={"col-lg-2 p-1 m-0"} key={cardstyleinfo?.id}>
									<Card
										onClick={() => handleOrderByStatus("Hold")}
										className="dash-cards"
										//   style={{ backgroundColor:""}}
									>
										<Row className="p-0 m-2">
											<Col lg={8} className="flex-left">
												<h5 className="mt-0 mb-0 header-title-open">
													{"On Hold"}
													{"  "}
												</h5>
												<h4 className="headding-3"> {data?.length!==0?data?.on_hold_orders:0}</h4>
											</Col>
											<Col lg={4} className={"text-center"}>
												{cardstyleinfo.HoldIcon}
											</Col>
										</Row>
									</Card>
								</div>
							)}
							{cardstyleinfo.ClarifyIcon && (
								<div className={"col-lg-2 p-1 m-0"} key={cardstyleinfo?.id}>
									<Card
										onClick={() => handleOrderByStatus("Delinquent/TaxSuit")}
										className="dash-cards"
										//   style={{ backgroundColor:""}}
									>
										<Row className="p-0 m-2">
											<Col lg={8} className="flex-left">
												<h5 className="mt-0 mb-0 header-title-open">
													{"Delinquent"}
													{"  "}
												</h5>
												<h4 className="headding-3">
													{" "}
													{data?.length!==0?data?.delinquent_orders:0}
												</h4>
											</Col>
											<Col lg={4} className={"text-center"}>
												{cardstyleinfo.ClarifyIcon}
											</Col>
										</Row>
									</Card>
								</div>
							)}
							{cardstyleinfo.pendingIcon && (
								<div className={"col-lg-2 p-1 m-0"} key={cardstyleinfo?.id}>
									<Card
										onClick={() => handleOrderByStatus("Overdue")}
										className="dash-cards"
										//   style={{ backgroundColor:""}}
									>
										<Row className="p-0 m-2">
											<Col lg={8} className="flex-left">
												<h5 className="mt-0 mb-0 header-title-open">
													{"Overdue "}
													{"  "}
												</h5>
												<h4 className="headding-3"> {data?.length!==0?data?.overdue_orders:0}</h4>
											</Col>
											<Col lg={4} className={"text-center"}>
												{cardstyleinfo.pendingIcon}
											</Col>
										</Row>
									</Card>
								</div>
							)}
							{cardstyleinfo.QcIcon && (
								<div className={"col-lg-2 p-1 m-0"} key={cardstyleinfo?.id}>
									<Card
										onClick={() => handleOrderByStatus("Rejected")}
										className="dash-cards"
										//   style={{ backgroundColor:""}}
									>
										<Row className="p-0 m-2">
											<Col lg={8} className="flex-left">
												<h5 className="mt-0 mb-0 header-title-open">
													{"Rejected"}
													{"  "}
												</h5>
												<h4 className="headding-3"> {data?.length!==0?data?.rejected_orders:0}</h4>
											</Col>
											<Col lg={4} className={"text-center"}>
												{cardstyleinfo.QcIcon}
											</Col>
										</Row>
									</Card>
								</div>
							)}
							{cardstyleinfo.cmpleteIcon && (
								<div className={"col-lg-2 p-1 m-0"} key={cardstyleinfo?.id}>
									<Card
										onClick={() => handleOrderByStatus("Completed")}
										className="dash-cards"
										//   style={{ backgroundColor:""}}
									>
										<Row className="p-0 m-2">
											<Col lg={8} className="flex-left">
												<h5 className="mt-0 mb-0 header-title-open">
													{"Completed"}
													{"  "}
												</h5>
												<h4 className="headding-3">
													{" "}
													{data?.length!==0?data?.completed_orders:0}
												</h4>
											</Col>
											<Col lg={4} className={"text-center"}>
												{cardstyleinfo.cmpleteIcon}
											</Col>
										</Row>
									</Card>
								</div>
							)}
							{cardstyleinfo.closeIcon && (
								<div className={"col-lg-2 p-1 m-0"} key={cardstyleinfo?.id}>
									<Card
										onClick={() => handleOrderByStatus("Rush")}
										className="dash-cards"
										//   style={{ backgroundColor:""}}
									>
										<Row className="p-0 m-2">
											<Col lg={8} className="flex-left">
												<h5 className="mt-0 mb-0 header-title-open">
													{"Rush Orders"}
													{"  "}
												</h5>
												<h4 className="headding-3"> {data?.length!==0?data?.rush_orders:0}</h4>
											</Col>
											<Col lg={4} className={"text-center"}>
												{cardstyleinfo.closeIcon}
											</Col>
										</Row>
									</Card>
								</div>
							)}
							{cardstyleinfo.ReqdIcon && (
								<div className={"col-lg-2 p-1 m-0"} key={cardstyleinfo?.id}>
									<Card
										onClick={() => handleOrderByStatus("Req’d Info")}
										className="dash-cards"
										//   style={{ backgroundColor:""}}
									>
										<Row className="p-0 m-2">
											<Col lg={8} className="flex-left">
												<h5 className="mt-0 mb-0 header-title-open">
													{"Req’d Info"}
													{"  "}
												</h5>
												<h4 className="headding-3"> {data?.length!==0?data?.reqd_info:0}</h4>
											</Col>
											<Col lg={4} className={"text-center"}>
												{cardstyleinfo.ReqdIcon}
											</Col>
										</Row>
									</Card>
								</div>
							)}
							{cardstyleinfo.DuetodayIcon && (
								<div className={"col-lg-2 p-1 m-0"} key={cardstyleinfo?.id}>
									<Card
										onClick={() => handleOrderByStatus("Due Today")}
										className="dash-cards"
										//   style={{ backgroundColor:""}}
									>
										<Row className="p-0 m-2">
											<Col lg={8} className="flex-left">
												<h5 className="mt-0 mb-0 header-title-open">
													{"Due Today"}
													{"  "}
												</h5>
												<h4 className="headding-3"> {data?.length!==0?data?.due_today:0}</h4>
											</Col>
											<Col lg={4} className={"text-center"}>
												{cardstyleinfo.DuetodayIcon}
											</Col>
										</Row>
									</Card>
								</div>
							)}
							{cardstyleinfo.AceragaAgIcon && (
								<div className={"col-lg-2 p-1 m-0"} key={cardstyleinfo?.id}>
									<Card
										onClick={() => handleOrderByStatus("Acreage")}
										className="dash-cards"
										//   style={{ backgroundColor:""}}
									>
										<Row className="p-0 m-2">
											<Col lg={10} className="flex-left">
												<h5 className="mt-0 mb-0 header-title-open">
													{"Acreage/Ag Check"}
													{"  "}
												</h5>
												<h4 className="headding-3"> {data?.length!==0?data?.acreage_ag:0}</h4>
											</Col>
											<Col lg={2} className={"text-center"}>
												{cardstyleinfo.AceragaAgIcon}
											</Col>
										</Row>
									</Card>
								</div>
							)}
							{cardstyleinfo.InfoReqIcon && (
								<div className={"col-lg-2 p-1 m-0"} key={cardstyleinfo?.id}>
									<Card
										onClick={() => handleOrderByStatus("Info rec’d")}
										className="dash-cards"
										//   style={{ backgroundColor:""}}
									>
										<Row className="p-0 m-2">
											<Col lg={8} className="flex-left">
												<h5 className="mt-0 mb-0 header-title-open">
													{"Info rec’d"}
													{"  "}
												</h5>
												<h4 className="headding-3"> {data?.length!==0?data?.info_recd:0}</h4>
											</Col>
											<Col lg={4} className={"text-center"}>
												{cardstyleinfo.InfoReqIcon}
											</Col>
										</Row>
									</Card>
								</div>
							)}
							{cardstyleinfo.TaxUpdateIcon && (
								<div className={"col-lg-2 p-1 m-0"} key={cardstyleinfo?.id}>
									<Card
										onClick={() => handleOrderByStatus("Tax Update")}
										className="dash-cards"
										//   style={{ backgroundColor:""}}
									>
										<Row className="p-0 m-2">
											<Col lg={8} className="flex-left">
												<h5 className="mt-0 mb-0 header-title-open">
													{"Tax Update"}
													{"  "}
												</h5>
												<h4 className="headding-3"> {data?.length!==0?data?.tax_update:0}</h4>
											</Col>
											<Col lg={4} className={"text-center"}>
												{cardstyleinfo.TaxUpdateIcon}
											</Col>
										</Row>
									</Card>
								</div>
							)}
							{cardstyleinfo.HoaOpenIcon && (
								<div className={"col-lg-2 p-1 m-0"} key={cardstyleinfo?.id}>
									<Card
										onClick={() => handleOrderByStatus("HOA Pending")}
										className="dash-cards"
										//   style={{ backgroundColor:""}}
									>
										<Row className="p-0 m-2">
											<Col lg={8} className="flex-left">
												<h5 className="mt-0 mb-0 header-title-open">
													{"HOA Pending"}
													{"  "}
												</h5>
												<h4 className="headding-3"> {data?.length!==0 ? data?.hoa_pending : 0}</h4>
											</Col>
											<Col lg={4} className={"text-center"}>
												{cardstyleinfo.HoaOpenIcon}
											</Col>
										</Row>
									</Card>
								</div>
							)}
							{cardstyleinfo.HoaUpdateIcon && (
								<div className={"col-lg-2 p-1 m-0"} key={cardstyleinfo?.id}>
									<Card
										onClick={() => handleOrderByStatus("HOA Update")}
										className="dash-cards"
										//   style={{ backgroundColor:""}}
									>
										<Row className="p-0 m-2">
											<Col lg={8} className="flex-left">
												<h5 className="mt-0 mb-0 header-title-open">
													{"HOA Update"}
													{"  "}
												</h5>
												<h4 className="headding-3"> {data?.length!==0? data?.hoa_update: 0}</h4>
											</Col>
											<Col lg={4} className={"text-center"}>
												{cardstyleinfo.HoaUpdateIcon}
											</Col>
										</Row>
									</Card>
								</div>
							)}
						</>
					))}
				</>
			</div>
		</>
	);
}
