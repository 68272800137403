import { LoadingOutlined } from "@ant-design/icons";
import { Collapse, Spin } from "antd";
import React from "react";
import { MdOutlineCloudDone, MdOutlineCloudOff, MdOutlineNotStarted } from "react-icons/md";
import { useLocation } from "react-router-dom";
import "../../order.css";
import "./parcelcard.css";
import AppTable from "../../../../Components/AppTable/AppTable";
import { priceFormater } from "../../../../Controllers/Functions";
const { Panel } = Collapse;

const ParcelCard = ({
  status = "Paid",
  parcel = "1",
  res,
  number = "",
  hoaData = false,
  automation = false,
}) => {
  let { state } = useLocation();
  let productType = state?.orderData?.product_type_id;
  return (
    <div className="parcel-card gray-table-col">
      <Collapse
        className="rounded-3 border-0 text-white"
        expandIcon={() => <></>}
        accordion
        defaultActiveKey={"1"}
      >
        <Panel
          className="main-bg-color rounded-3 "
          header={`Parcel ${parcel}`}
          style={{ fontSize: 18 }}
          key="1"
        >
          {automation && (
            <div className="border p-1 bg-light d-flex justify-content-end">
              {automation === 1 ? <div>
                <span className="fs-11 text-dark d-inline-block me-1 fw-bold">
                  Automation Not Initiated
                </span>
                <MdOutlineNotStarted size={22} color="#FF7000" />{" "}
              </div> : automation === 3 ? (
                <div>
                  <span className="fs-11 text-dark d-inline-block me-1 fw-bold">
                    Automation Success
                  </span>
                  <MdOutlineCloudDone size={22} color="green" />
                </div>
              ) : automation === 2 ? (
                <div>
                  <span className="fs-11 text-dark d-inline-block me-1 fw-bold">
                    Automation In Progress
                  </span>
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          fontSize: 24,
                        }}
                        spin
                      />
                    }
                  />
                </div>
              ) : (
                automation === 4 && (
                  <div>
                    <span className="fs-11 text-dark d-inline-block me-1 fw-bold">
                      Automation Failed
                    </span>
                    <MdOutlineCloudOff size={22} color="red" />
                  </div>
                )
              )}
            </div>
          )}
          <div className="row p-2 ">
            <div className={`col-12 col-md-${!res && "6"} mb-2`}>
              <div className="d-flex align-items-center gap-2">
                <h6 className="main-text-color m-0">Parcel:</h6>
                <h6 className="fs-13 ">{number}</h6>
              </div>
            </div>
            <div className={`col-12 col-md-${!res && "6"} mb-2`}>
              <div className="d-flex align-items-center gap-2">
                <h6 className="main-text-color m-0">Status:</h6>
                <h6 className="fs-13 ">{status}</h6>
              </div>
            </div>
            <div className={`col-12 col-md-${!res && "12"} mb-2`}>
              <div className="d-flex align-items-center gap-2">
                <h6 className="main-text-color m-0">GEO ID:</h6>
                <h6 className="fs-13 ">{hoaData?.geographic_id || " "}</h6>
              </div>
            </div>
            {/* <div className={`col-12 col-md-${!res && "12"} mb-2`}>
              <div className="d-flex align-items-center gap-2">
                <h6 className="main-text-color m-0">Owner Information:</h6>
                <h6 className="fs-13 ">{hoaData?.ownername_list || " "}</h6>
              </div>
            </div> */}
            <div className={`col-12 col-md-${!res && "12"} mb-2`}>
              <div className="d-flex align-items-center gap-2">
                <h6 className="main-text-color m-0">Legal Description:</h6>
                <h6 className="fs-13 ">{hoaData?.legal_description || " "}</h6>
              </div>
            </div>
            {hoaData?.parcel_jurisdiction_info?.length > 0 && (
              <div className="col-12 mb-2 ">
                <AppTable
                  // sort={sort}
                  pagination={false}
                  dataSource={hoaData?.parcel_jurisdiction_info}
                  columns={[
                    {
                      title: "Name",
                      dataIndex: "tax_collector_name",
                      align: "center",
                      width: 200,
                    },
                    {
                      title: "Total",
                      dataIndex: "total_tax",
                      align: "center",
                      render: (value) =>
                        value ? priceFormater(value) : <span>&nbsp;</span>,
                      width: 100,
                    },
                    {
                      title: "Due",
                      dataIndex: "total_due",
                      render: (value) =>
                        value ? priceFormater(value) : <span>&nbsp;</span>,
                      align: "center",
                      width: 100,
                    },
                    {
                      title: "Balance",
                      dataIndex: "balance",
                      render: (value) =>
                        value ? priceFormater(value) : <span>&nbsp;</span>,
                      align: "center",
                      width: 100,
                    },
                    {
                      title: "Next Balance",
                      dataIndex: "next_balance",
                      render: (value) =>
                        value ? priceFormater(value) : <span>&nbsp;</span>,
                      align: "center",
                      width: 100,
                    },

                    {
                      title: "Status",
                      dataIndex: "status",
                      align: "center",
                      width: 50,
                    },
                  ]}
                />
              </div>
            )}

            <div className="col-12">
              {" "}
              <div className="border-bottom " />
            </div>
            {hoaData?.is_hoa === 1 ? (
              <>
                {" "}
                <div className="col-12 mb-2 ">
                  <div className="d-flex align-items-center gap-2">
                    <h6 className="main-text-color m-0">HOA Status : </h6>
                    <h6 className="fs-13 ">{hoaData?.hoa_status_name}</h6>
                  </div>
                </div>
                <div className="col-12 mb-2 ">
                  <AppTable
                    // sort={sort}
                    pagination={false}
                    dataSource={hoaData?.parcel_hoa_list}
                    columns={[
                      {
                        title: "Name",
                        dataIndex: "hoa_name",
                        align: "center",
                      },
                      {
                        title: "Collector Name",
                        dataIndex: "hoa_collector_name",
                        align: "center",
                      },
                      {
                        title: "Date",
                        dataIndex: "hoa_date_verified",
                        align: "center",
                      },
                    ]}
                  />
                </div>
              </>
            ) : (
              (productType === 5 ||
                productType === 4 ||
                productType === 9 ||
                productType === 2) && (
                <h6 className="text-danger text-center my-2">
                  HOA Details Not Found
                </h6>
              )
            )}
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default ParcelCard;
