import React from 'react'
import "./xml.css"
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';
export default function XmlDataFile({ data = false }) {
    return (
        <div className='xmldata'>{data ? String(data).slice(0, 1) !== "<" ? <><JSONPretty data={JSON.parse(data)} theme={{
            main: 'line-height:1.3;color:#000;background:#fff;overflow:auto;',
            error: 'line-height:1.3;color:#000;background:#fff;overflow:auto;',
            key: 'color:#000;',
            string: 'color:#000;',
            value: 'color:#000;',
            boolean: 'color:#000;',
        }} themeClassName="custom-json-pretty"></JSONPretty></> : <pre>{data}</pre> : <h3>No Data Found</h3>}</div>
    )
}