import React, { useState } from "react";
import { Dropdown, Layout, Menu, Tooltip } from "antd";
import "./Header.css";
import { CgNotes } from "react-icons/cg";
import {
  FaRegUserCircle,
  FaSignOutAlt,
  FaChevronDown,
  FaUserCircle,
} from "react-icons/fa";
import { TiAdjustBrightness } from "react-icons/ti";
import { Link } from "react-router-dom";
import MyAccount from "../../View/MyAccount/MyAccount";
import AppModal from "../../Components/AppModal/AppModal";
import { useDispatch, useSelector } from "react-redux";
import { darkMode as darkModeAction } from "../../Redux/reducers/themeChanger";
import { NavLink } from "react-router-dom";
const { Header } = Layout;
export default function MyHeader() {
  const [showHide, setShowHide] = useState(null);
  const [darkMode, setDarkMode] = useState(true);
  const [initalState, setInitalState] = useState(false);
  //hide useinfo for resetpassword page
  const pathName =
    "/" + window.location.pathname.split("/")[1] === "/resetpassword";

  const showModal = () => {
    setShowHide(true);
  };
  function closeModal(data) {
    setShowHide(data);
    setInitalState(!initalState);
  }

  //calling to store

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  // const navigate = useNavigate();
  // const logoutHandler = () => {
  //   navigate("/logout");
  // };
  //labellist store
  const { labelList } = useSelector((state) => state.labelList);
  //calling to store
  const { dark } = useSelector((state) => state.theme);
  const modeHandler = () => {
    setDarkMode(!darkMode);
    dispatch(darkModeAction(darkMode));
  };
  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <a href="#" onClick={showModal}>
              <FaRegUserCircle size={14} />{" "}
              {labelList["cs.dashboard.myaccount"]}
            </a>
          ),
        },
        {
          key: "3",
          label: (
            <NavLink to="/notice">
              <CgNotes size={14} /> Notice to Purchaser
            </NavLink>
          ),
        },
        {
          key: "2",
          label: (
            <a href={"/logout"}>
              <FaSignOutAlt size={14} /> {labelList["cs.dashboard.logout"]}
            </a>
          ),
        },
      ]}
    />
  );
  return (
    <>
      <AppModal
        showModal={showHide}
        onClose={closeModal}
        element={
          <MyAccount afterClose={initalState} setShowHide={setShowHide} />
        }
      />
      <Header className={`w-100  px-2 `}>
        <div className="logo  d-flex justify-content-between align-items-center">
          <Link to={"/"}>
            <img src="../../Assets/img/Stellarlogo.png" alt="logo" width="260px" className="my-2"/>
          </Link>
          {!pathName && (
            <>
              <div className="ms-auto me-2 d-flex  align-items-center">
              
                <Tooltip
                  placement="left"
                  // title={"Dark Mode Disabled"}
                  title={dark ? "Light Mode" : "Dark Mode"}
                >
                  <span onClick={modeHandler}>
                    {dark ? (
                      <TiAdjustBrightness
                        color="white"
                        size={30}
                        cursor="pointer"
                      />
                    ) : (
                      <img
                        src="../Assets/img/night-mode.png"
                        width={22}
                        className="cursor-pointer"
                        alt=""
                      />
                    )}
                  </span>
                </Tooltip>
              </div>
              <Dropdown overlay={menu} trigger={["click"]}>
                <div className="d-flex align-items-center justify-content-center">
                  <div className="avtar d-flex align-items-center justify-content-center rounded-pill   me-1">
                    {localStorage.getItem("avtar")?.length === 0 ? (
                      <FaUserCircle color="gray" size={25} />
                    ) : (
                      <img
                        src={atob(localStorage.getItem("avtar"))}
                        width={"100%"}
                        alt=""
                      />
                    )}
                  </div>
                  <a
                    className="text-dark avtar-name "
                    onClick={(e) => e.preventDefault()}
                  >
                    {user && (
                      <>{`${user.first_name} ${user.last_name || " "}`}</>
                    )}


                    <FaChevronDown />

                  </a>
                  <br />

                </div>

              </Dropdown>

            </>

          )}
        </div>
        <div className="d-flex justify-content-end"></div>
      </Header>
    </>
  );
}
