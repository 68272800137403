import React from "react";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import AppButton from "../../Components/AppButton/AppButton";

export default function Logout() {
  const [, , removeCookie] = useCookies(["user"]);
  //logout
  useEffect(() => {
    setTimeout(() => {
      removeCookie("user");
      localStorage.clear()
       
    }, 10);
  }, []);
  //refresh page

  const gohome = () => {
    window.location.href = "/";
  };
  return (
    <div
      className="pagenotfound"
      style={{ background: "url(../Assets/login/back.png)" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6">
            <div
              className="d-flex justify-content-center align-items-center "
              style={{ height: "100vh" }}
            >
              <div className="row">
                <div className="col-12 mx-auto">
                  <div
                    className=" p-5 shadow-sm  rounded-3  w-100"
                    style={{ background: "rgba(211,242,213,.25)" }}
                  >
                    <h4 className="my-2">All done! Have a good time 😊</h4>
                    {/* <a href={"/login"}>Go Login</a> */}
                    <div className="w-100 my-3">
                      <AppButton
                        variant={"primary"}
                        title={"GO HOME"}
                        onClick={gohome}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
            <img
              className="pagenotfoundImage img-fluid"
              src="../Assets/login/loginImage.png"
              alt=""
              width={"100%"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
