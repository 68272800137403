import React, { memo, useMemo, useState } from "react";
import { Button, Popconfirm } from "antd";
import { FaPlus } from "react-icons/fa";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { MdOutlineDelete } from "react-icons/md";
import { useEffect } from "react";
import {
  priceFormater,
  removeBlank,
} from "../../Controllers/Functions";
import { useCallback } from "react";
import InlineTable from "../antd/Tables/InlineTable";
const randomPass = require("secure-random-password");

export default function NoteFees({
  afterClose = false,
  initailValArr = [],
  updateDescription = false,
  deleteHandler = false,
  showLabel = true,
  isedit = false,

}) {
  const initailVal = {
    indexes:"",      // Number field
    content:"",    // string field
  };
  const [values, setValues] = useState(initailVal);
  const [data, setData] = useState(initailValArr);

  useEffect(() => {
    setData(initailValArr);
    setValues(initailVal);
  }, [afterClose]);

  useEffect(() => {
    setData(initailValArr);
  }, [initailValArr]);

  const juriListOptionsFilter = [];
  data?.map(({ hoa_fees_type }) => {
    juriListOptionsFilter.push(hoa_fees_type);
  });

  const handleAdd = () => {
    const randomId = randomPass.randomPassword({
      length: 9,
      characters: randomPass.digits,
    });

    const newData = {
      tempId: randomId,
      hoa_fees_type: "",
    };
    setData([...data, newData]);
    updateDescription && updateDescription(newData);
  };

  const cols = [
    {
      title: "Notes",
      dataIndex: "content",
      key: "content",
      type: "text",
      align: "center",
      render: (value) => value || <>&nbsp;</>,
      width: 100,
      editable: () => {
        return !isedit;
      },
    },
    {
      title: "Sort",
      dataIndex: "indexes",
      key: "indexes",
      align: "center",
      type: "notprice",
      render: (value) => value || <>&nbsp;</>,
      width: 25,
      editable: () => {
        return !isedit;
      },
    },
    {
      title: "Action",
      dataIndex: "operation",
      className: `${!isedit ? "" : "d-none"}`,
      width: !isedit && 50,
      align: "center",
      render: (_, record) =>
        data.length >= 1 ? (
          <Popconfirm
            getPopupContainer={(trigger) => trigger.parentElement}
            title="Are you sure you want to delete？"
            onConfirm={() => handleDelete(record)}
            placement="topLeft"
            icon={
              <QuestionCircleOutlined
                style={{
                  color: "red",
                }}
              />
            }
            okText="Yes"
            cancelText="No"
          >
            <Button type="text" htmlType="button" size="small">
              <MdOutlineDelete color="red" />
            </Button>
          </Popconfirm>
        ) : null,
    },
  ];

  const handleDelete = (record) => {
    deleteHandler && deleteHandler(record);
    const newData = data?.filter(
      (item) => (item?.id || item?.tempId) !== (record.id || record.tempId)
    );
    setData(newData);
  };

  const oneEditFun = useCallback(
    async (value, id, type) => {
      removeBlank(value);
      if (value?.id) {
        setValues({ ...values, ...value });
      } else {
        const { id, ...rest } = values;
        setValues({ ...rest, ...value });
      }
      try {
        const { hoa_fees_type_id, ...rest } = value;
        if (type === "select") {
          updateDescription && updateDescription(rest);
        } else if (type !== "select") {
          updateDescription &&
            updateDescription({
              ...rest,
            });
        }
      } catch (error) {
        console.log(error);
      }
    },
    [data, updateDescription]
  );

  const data1 = useMemo(() => data, [data]);

  return (
    <div className="gray-table-col  mb-2 p-2 ">
      <div
        className={`d-flex ${"justify-conten"} align-items-center my-2 contact-forms`}
      >
        <div className="date align-items-center d-flex gap-1">
          {showLabel ? <h6 className="text-primary mb-0 border d-inline-block p-2 px-3 rounded bg-white shadow-sm">
            Notes
          </h6> : <div></div>}
        </div>
        {!isedit && (
          <div className={`add_icon ms-auto`} onClick={handleAdd}>
            <FaPlus />
          </div>
        )}
      </div>

      <div className="bg-white shadow-sm rounded border">
        <InlineTable
          onEdit={oneEditFun}
          setData={setData}
          data={data1}
          cols={cols}
        />
      </div>
    </div>
  );
}
