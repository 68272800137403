import React, { useEffect, useState } from "react";
import AppTable from "../../../../Components/AppTable/AppTable";
import { FaSearch, FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import AppModal from "../../../../Components/AppModal/AppModal";
import VendorModal from "./VendorModal";
import { useSelector } from "react-redux";
import {
	useUpdateVendorsStatusMutation,
	useVendorsListQuery,
} from "../../../../Redux/Services/Vendors";
import AppSpinner from "../../../../Components/AppSpinner/AppSpinner";
import { Input, notification, Switch } from "antd";
import "./vendor.css";
import { FaPlus } from "react-icons/fa";
import { EditOutlined } from "@ant-design/icons";
import SearchColumns from "../../../../Components/SearchColumns";

export default function Vendors({ refresh }) {
	const [showHide, setShowHide] = useState(false);
	const [initialStateVal, setInitialStateVal] = useState(false);
	const [data, setData] = useState();
	const [editId, setEditId] = useState(null);
	const [search, setSearch] = useState("");
	const [vendorDefaultState] = useState({
		name: "",
		address_one: "",
		address_two: "",
		// county_id: "",
		city: "",
		state_id: "",
		vendor_email: "",
		phone_number: "",
		contact_name: "",
		contact_position: "",
		logo: "",
		is_active: true,
		is_auto_delivery: true,
		zip_five: "",
		zip_four: "",
		integration_bridge: "",
		partner_id: "",
		product_type: "",
		county_id_list: [],
		contact_list_values: [],
		product_list_values: [],
		customer_auto_delivery_lists:[],
		website: "",
		integration_id:1
	});

	//set  ascend or descend sorter state
	const [getsortorder, setSortOrder] = useState(false);
	const [getsortfield, setSortField] = useState("");

	//redux data store
	const { token, user } = useSelector((state) => state.auth);
	const { vendorModule, tableColumn } = useSelector(
		(state) => state.flagRoles
	);

	//calling rtk query hook for geting VendorsType list
	const Vendors = useVendorsListQuery(token);
	//create function for branch fields
	function matchColumn(value) {
		// if (authColumn.indexOf(value) === -1) {
		// 	return false;
		// } else {
		// 	return true;
		// }
	}

	const sort = (sortOrder, sortfield) => {
		// console.log(sortOrder, sortfield);
		if (sortOrder) {
			setSortOrder(sortOrder);
			setSortField(sortfield);
		} else {
			setSortOrder(false);
		}
	};
	//calling rtk query hook for geting VendorsType list
	const updateVendorsStatusApi = useUpdateVendorsStatusMutation();
	const [updateVendorsStatus] = updateVendorsStatusApi;

	var columns = [
		{
			title: <span>
				Vendor Name{" "}
				{getsortfield === "vendor_name" && !getsortorder ? (
					<FaSortAmountUp />
				) : getsortorder === "ascend" && getsortfield === "vendor_name" ? (
					<span className="sort-svg">
						<FaSortAmountUp className="text-info" />
					</span>
				) : getsortorder === "descend" && getsortfield === "vendor_name" ? (
					<span className="sort-svg">
						<FaSortAmountDown className="text-info" />
					</span>
				) : (
					<FaSortAmountUp />
				)}
			</span>,
			dataIndex: "vendor_name",
			key: "vendor_name",
			align: "center",
			className: `${tableColumn[0].vendor ? " " : " d-none"}`,
			width: matchColumn(" ") && 200,
			sorter: (a, b) => a.vendor_name.localeCompare(b.vendor_name),
			...SearchColumns("vendor_name", "Vendor Name"),
		},
		{
			title: (
				<span>
					State Name{" "}
					{getsortfield === "state_name" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "state_name" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "state_name" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "state_name",
			key: "state_name",
			editable: true,
			width: matchColumn(" ") && 200,
			align: "center",
			sorter: (a, b) => a.state_name.localeCompare(b.state_name),
			className: `${vendorModule[2]?.active_status ? "" : "border-style border-endstyle"}`,
			...SearchColumns("state_name", "State Name"),
		},
		{
			title: <span>Active Status</span>,
			className: `${vendorModule[2]?.active_status ? "" : "d-none"}`,
			dataIndex: "is_active",
			key: "is_active",
			align: "center",
			width: matchColumn(" ") && 150,
			render: (value, row) => (
				<div className="d-flex justify-content-center">
					<Switch
						onClick={(e) => switchHandler(e, row)}
						className="my-switch"
						size="small"
						checked={value}
					/>
				</div>
			),
		},
		{
			title: "Action",
			dataIndex: "edit",
			key: "edit",
			className: `${vendorModule[1].action ? "" : "d-none"}`,
			width: matchColumn("edit") && 50,
			align: "center",
			render: (value, row) => {
				return (
					<>
						<EditOutlined size={50} onClick={() => editHandler(row)} />
					</>
				);
			},
		},
	];


	//create flag for initail state
	const afterClose = () => {
		setInitialStateVal(!initialStateVal);
		// setPreDefualtStateValues(preDefualtStateValues)
	};
	
	//assinging data in to new state
	useEffect(() => {
		if (Vendors.isSuccess) {
			const newData = Vendors.data?.data.map((ele) => {
				return { ...ele, state_name: ele.state_name || " " };
			});
			setData(newData);
		}
	}, [Vendors]);

	function closeModal(data) {
		setShowHide(data);
	}

	//switch handler
	function switchHandler(value, row) {
		const data = {
			is_active: value === true ? 1 : 0,
			updated_by: user?.user_id,
		};
		updateVendorsStatus({
			data,
			id: row.vendor_id,
			token: token,
		});
	}
	//edit vendor module
	const editHandler = (e) => {
		setShowHide(true);
		// //sending id to edit vendor modal
		setEditId(e.vendor_id);
	};

	//addVendor Handler function with dependency
	const AddVendorHandler = () => {
		setShowHide(true);
		setEditId(null);
	};

	//update branch details
	useEffect(() => {
		if (updateVendorsStatusApi[1].isSuccess) {
			///after update details
			notification.open({
				type: "success",
				style: { color: "green", marginTop: "50px" },
				description: updateVendorsStatusApi[1].data?.message,
			});
		}
		if (updateVendorsStatusApi[1].error) {
			///error details  details
			notification.open({
				type: "error",
				style: { color: "red", marginTop: "50px" },
				description:
					updateVendorsStatusApi[1].error.data.message ||
					updateVendorsStatusApi[1].error.error ||
					updateVendorsStatusApi[1].error.data?.msg.sqlMessage,
			});
		}
	}, [updateVendorsStatusApi[1].isLoading]);

	useEffect(() => {
		setSearch("");
		//request
		if (refresh === "vendors") {
			Vendors.refetch();
		}
	}, [refresh]);

	return (
		<>
			{Vendors.isLoading ? (
				<AppSpinner />
			) : (
				<div className="vendor">
					<div className="d-flex align-items-center float-end w-25 mb-1 contacts-form ">
						<AppModal
							confirmProp={true}
							showModal={showHide}
							onClose={closeModal}
							width={"90%"}
							afterClose={afterClose}
							// passing props for default values set
							element={
								<VendorModal
									AddVendorHandler={AddVendorHandler}
									editId={editId}
									afterClose={initialStateVal}
									setShowHide={setShowHide}
									initialValues={vendorDefaultState}
								/>
							}
						/>

						{/* changing input values globaly  */}
						<div className=" me-1 w-100 ">
							<form>
								<Input
									placeholder="Search here"
									className="global-search "
									value={search}
									onChange={(e) => setSearch(e.target.value)}
									prefix={<FaSearch color="lightgray" />}
								/>
							</form>
						</div>

						{vendorModule.map((item, index) => {
							if (item.add_button) {
								return (
									<div key={index} className="add_icon" onClick={AddVendorHandler}>
										<FaPlus />
									</div>
								);
							}
							return null;
						})}
					</div>
					<div style={{ clear: "both" }}></div>

					<AppTable
						activeState={false}
						sort={sort}
						loading={updateVendorsStatusApi[1].isLoading}
						search={search}
						columns={columns}
						dataSource={data}
					/>
				</div>
			)}
		</>
	);
}
