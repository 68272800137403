import { Tooltip } from 'antd'
import React from 'react'

const OrderBtns = ({ children, title = "", placement = "top" }) => {
    return (
        <Tooltip
            overlayInnerStyle={{
                fontSize: 10,
            }}
            placement={placement}
            title={title}
        >
            <div className="border d-flex align-items-center border-primary rounded-2 p-1 gap-2">{children}</div>
        </Tooltip>
    )
}

export default OrderBtns