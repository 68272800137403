import React, { useState } from "react";
import { Alert, Collapse, Popconfirm } from "antd";
import AppTable from "../AppTable/AppTable";

import { FaPlus, FaStarOfLife } from "react-icons/fa";
import "./MultiHoaContact.css";
import {
  CaretRightOutlined,
  EditOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import AppInput from "../AppInput/AppInput";
import { MdOutlineContactSupport, MdOutlineDelete } from "react-icons/md";
// import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { useEffect } from "react";
import { removeBlank } from "../../Controllers/Functions";
import AppNumeric from "../AppNumeric/AppNumeric";
import AppSelect from "../AppSelect/AppSelect";
const randomPass = require("secure-random-password");
const { Panel } = Collapse;
export default function MultiHoaContact({
  options,
  afterClose = false,
  addFormVal = false,
  initailValArr = [],
  updateContact = false,
  deleteContact = false,
  whileEdit = false,
  _id,
}) {
  const [active, setActive] = useState("2");
  const contact_methods = [{id:1, name:"mobile"}, {id:2, name:"Email"}, {id:3, name:"Fax"}]
  const initailVal = {
    name: "",
    phone: "",
    email: "",
    contact_method_id:"",
  };
  const [values, setValues] = useState(initailVal);
  // const [,setShowDate] = useState(true);
  const [data, setData] = useState(initailValArr);
  const [addRowToggler, setAddRowToggler] = useState(false);

  const [editId, setEditId] = useState(null);
  const [getErr, setGetErr] = useState(false);

  const { name, phone, email, contact_method_id} = values;

  //edit row
  const editHandler = (record) => {
    console.log(record, 50)
    setEditId(record);
    setAddRowToggler(true);
    setValues(record);
  };

  const columns = [
    {
      title: <span>Name</span>,
      dataIndex: "name",
      key: "name",
      align: "left",
    },
    {
      title: <span>Phone</span>,
      dataIndex: "phone",
      key: "phone",
      align: "left",
    },
    {
        title: <span>Email</span>,
        dataIndex: "email",
        key: "email",
        align: "left",
    },
    {
        title: <span>Contact Method</span>,
        dataIndex: "contact_method_id",
        key: "contact_method_id",
        align: "left",
        render:(value)=>{
          console.log(options?.data?.data?.find(object=> object.id == value), 80);
          return options?.data?.data?.find(object=> object.id == value)?.name;
        }
      },
    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      align: "center",
      width: 130,
      render: (_, record) => {
        return (
          <>
            <span>
              <EditOutlined
                style={{ cursor: "pointer" }}
                onClick={() => editHandler(record)}
                size={50}
              />
            </span>

            <Popconfirm
              getPopupContainer={(trigger) => trigger.parentElement}
              title="Are you sure you want to delete？"
              onConfirm={() => {
                deleteHandler(record);
              }}
              placement="topLeft"
              icon={
                <QuestionCircleOutlined
                  style={{
                    color: "red",
                  }}
                />
              }
              okText="Yes"
              cancelText="No"
            >
              <span className={"ms-2 "}>
                <MdOutlineDelete style={{ cursor: "pointer" }} size={15} />
              </span>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const inputs = [
    {
      id: 1,
      name: "name",
      _id: "name",
      type: "text",
      value: name || "",
      column: "3",
      placeholder: "Enter Name",
      errorMessage: "Please enter name!",
    //   icon: <FaStarOfLife size={5} color="red" />,
    },
    {
      id: 454545,
      name: "phone",
      _id: "phone",
      type: "text",
      value: phone || "",
      column: "3",
      maxLength: 10,
      placeholder: "Enter Phone",
      errorMessage: "Please enter phone!",
    },
    {
        id: 4546456,
        name: "email",
        _id: "email",
        type: "email",
        value: email || "",
        column: "3",
        placeholder: "Enter Email",
        errorMessage: "Please enter email!",
      },
      {
        id: 4547357,
        name: "contact_method_id",
        _id: "contact_method_id",
        type: "select",
        value: contact_method_id || "",
        column: "3",
        options: options?.data?.data,
        errorMessage: "Please enter email!",
      },
   
  ];

  const onChange = (e, date, dateFormat) => {
      setGetErr(false)
      const { name, value } = e.target;
      setValues({
        ...values,
        [name]: value,
      })
  };

  //sending request

  const handleSubmit = () => {
    if (editId) {
      const newData = data.filter((ele) => {
        return (ele.temp_id || ele.id) !== (editId.temp_id || editId.id);
      });

      console.log(newData, 185 ,"MultihoaContac.js")
      setData([...newData, { ...values, name:values?.name, phone:values?.phone, email:values?.email, contact_method_id:values?.contact_method_id}]);
      updateContact && updateContact({ ...values, name:values?.name, phone:values?.phone, email:values?.email, contact_method_id:values?.contact_method_id });
      setAddRowToggler(false);
      setEditId(null);
      // setValues(initailVal);
    } else {
      //remove blank values
      removeBlank(values);
      const randomId = randomPass.randomPassword({
        length: 9,
        characters: randomPass.digits,
      });
      setData([
        ...data,
        {
          ...values,
          name:values?.name,
          phone:values?.phone, 
          email:values?.email, 
          contact_method_id:values?.contact_method_id,
          temp_id: randomId,
        },
      ]);
      //while edit and ading new record directly
      whileEdit({ ...values, name:values?.name, 
        phone:values?.phone,
         email:values?.email, 
         contact_method_id:values?.contact_method_id, 
         temp_id: randomId });
      setAddRowToggler(false);
    }
  };

  const cancelHandler = () => {
    setValues(initailVal);
    addFormVal(data);
    setAddRowToggler(false);
  };

  const addNewRecord = () => {
    setAddRowToggler(!addRowToggler);
    setValues({ ...initailVal})
  };

  const deleteHandler = (id) => {
    setData(
      data.filter((ele) => {
        return (ele?.id || ele?.temp_id) !== (id?.id || id?.temp_id);
      })
    );
    //sending delete id
    deleteContact(id);
  };

  useEffect(() => {
    setAddRowToggler(false);
    setData(initailValArr);
    setValues(initailVal);
    setEditId(null);
    setActive("2");
  }, [afterClose]);

  useEffect(() => {
    addFormVal && addFormVal(data);
  }, [data]);

  const selectHandler = (property, value, arrtibute) => {
		setValues({
			...values,
			contact_method_name: value,
			contact_method_id: arrtibute?.getAttribute("data_name"),
		});
	};

  return (
    <Collapse
      className="m-0 py-0 gray-table site-collapse-custom-collapse"
      expandIcon={({ isActive }) => (
        <CaretRightOutlined
          style={{ fontSize: 14 }}
          className="mt-1"
          rotate={isActive ? 90 : 0}
        />
      )}
      expandIconPosition={"end"}
      activeKey={active}
      accordion
      onChange={(value) => {
        setActive(value);
      }}
    >
      <Panel
        className="my-1 border-0 site-collapse-custom-panel "
        header={
          <h6 className=" mb-0 fw-bold me-2 d-flex align-items-center gap-1">
            <MdOutlineContactSupport size={15} /> <span>Contact</span>
          </h6>
        }
        key="1"
      >
        {active === "1" && (
          <div className="gray-table-col  mb-2 p-2" id={_id}>
            <div
              className={`d-flex ${"justify-conten"} align-items-center my-2 contact-forms`}
            >
              
              {/* {inputs.map((input) => (
                <>
                  {input.type === "date" && (
                    <AppInput
                      defaultSet={afterClose}
                      key={input.id}
                      {...input}
                      value={values[input.name]}
                      onChange={(name, date, dateFormat) => {
                        // setShowDate(true);

                        if (dateFormat) {
                          getDate && getDate(date);
                          setValues({ ...values, verify_date: date });
                        }


                      }}
                    />
                  )}
                </>
              ))} */}
              {!addRowToggler && (
                <div className={`add_icon ms-auto`} onClick={addNewRecord}>
                  <FaPlus />
                </div>
              )}
            </div>

            <AppTable
              pagination={false}
              dataSource={data}
              columns={columns}
            />

            {getErr && (
              <div className="mt-3">
                <Alert
                  message={getErr}
                  className="py-1 mx-3"
                  type="error"
                  showIcon
                />{" "}
              </div>
            )}
            {/* //loading  */}
            {/* {emailCheckApi[1].isLoading && <AppSpinner size="small" />} */}

            {addRowToggler && (
              <div className="row bg-white p-2 mx-auto shadow-sm" id="addRow">
                {inputs.map((input) => (
                  <>
                    {input.type === "text" && (
                      <AppInput
                        defaultSet={afterClose}
                        key={input.id}
                        {...input}
                        value={values[input.name]}
                        onChange={onChange}
                      />
                    )}
                    {input.type === "email" && (
                      <AppInput
                        defaultSet={afterClose}
                        key={input.id}
                        {...input}
                        value={values[input.name]}
                        onChange={onChange}
                      />
                    )}
                     {input.type === "select" && (
                      <AppSelect
                        defaultSet={afterClose}
                        key={input.id}
                        {...input}
                        value={values[input.name]}
                        onChange={selectHandler}
                        options={
                          <>
															<option value={""}>
																Select Contact Method
															</option>
															{input.options.map((ele) => {
																return (
																	<option
																		key={ele.id}
																		data_name={ele.id}
																		value={ele.id}
																	>
																		{ele.name}
																	</option>
																);
															})}
														</>}
                      />
                    )}
                    {/* {input.name === "fees" && (
                      <AppNumeric
                        {...input}
                        type="text"
                        defaultSet={afterClose}
                        value={values[input.name]}
                        thousandSeparator={true}
                        placeholder={input.placeholder}
                        prefix={"$"}
                        decimalScale={7}
                        onChange={(value) => {
                          setValues({ ...values, fees: value });
                        }}
                      />
                    )} */}
                  </>
                ))}
                <div className="col-12 col-lg-1 mt-3 p-0">
                  <div className="d-flex">
                    <span
                      onClick={handleSubmit}
                      className={`btn btn-primary  btn-sm edit-buttons ${!phone ||!email || !name ? "disabled" : "btn-success"
                        } `}
                    >
                      {editId?.id ? "Update" : "Save"}
                    </span>
                    <span
                      onClick={cancelHandler}
                      className="btn btn-primary ms-1 btn-sm edit-buttons"
                    >
                      Cancel
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </Panel>
    </Collapse>
  );
}
