import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { Collapse, Tooltip } from 'antd'

import React, { useContext, useEffect, useMemo, useState } from 'react'
import { FaPlus } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { GlobalContext } from '../../../../../App';
import { scrollComp } from '../../../../../Controllers/Functions';
import { useParcelTaxCollectorListMutation } from '../../../../../Redux/Services/taxcollectorInfo';
import { TaxCollectorDetails } from './TaxCollectorDetails';
import { parcelContext } from '../../OrderEditor';
const { Panel } = Collapse
export const MyTaxCollector = ({
    data,
    editId,
    info,
    parcelList,
    refresh,
    toolsData,
    setKey,
    parcelAutomationStatus,
    automationStart,
    setProcessbar = false,
    validateConflicts = false,
    juriupdateAutomationResult = false,
}) => {
    //context api
    const { state } = useContext(GlobalContext);
    const [globalVal, setGlobalVal] = state;

    //context api
    const { controller } = useContext(parcelContext);
    const [parcelController, setParcelController] = controller;

    //states
    const [mode, setMode] = useState("view");
    const [addMode, seAddMode] = useState(false);

    const [taxDataList, setTaxListData] = useState([]);
    const [selected, setSelected] = useState(0);
    const [taxcollectorbutton, setTaxCollectorButton] = useState(false)
    const handleSelect = (e) => {
        setSelected(e.selected);
    };


    const { token } = useSelector((state) => state.auth);
    const { parcelEditInOrder } = useSelector((state) => state.flagRoles);

    //getting tax collector list
    const [taxCollectorsListApiCall, taxCollectorsListApi] =
        useParcelTaxCollectorListMutation();

    //useEffects
    useEffect(() => {
        if (taxCollectorsListApi.isSuccess) {
            setTaxListData(taxCollectorsListApi.data?.data);
            setSelected(0)
            setMode("view")

        }

        if (taxCollectorsListApi.isError) {
            setTaxListData([]);
        }
        //global spinner
        setGlobalVal({ ...globalVal, loader: taxCollectorsListApi.isLoading });
    }, [taxCollectorsListApi.isLoading]);

    //tax list call
    function refecthTax() {
        taxCollectorsListApi.isLoading = true
        taxCollectorsListApiCall({ id: data.id, year: data.year, token });
    }

    useEffect(() => {
        const { state_id, county_id, year, id } = data;
        if (id) {
            refecthTax()
        }
        seAddMode(false)
    }, [data.id])

    useEffect(() => {
        parcelEditInOrder.map((item) => {
            if (item.taxcollector)
                setTaxCollectorButton(true);
        })
    }, [parcelEditInOrder])

    const modeMemo = useMemo(() => mode, [mode])

    //status comperision
    // const isCompleteOrder = String(parcelController?.orderData?.status_id) === "6" || String(parcelController?.orderData?.status_id) === "15"


    return (
        <div className='mt-2'>
            <div id={"parceltax "}>
                <Collapse

                    className="rounded-3 border-0 text-white "
                    expandIconPosition='end'

                    expandIcon={() => (
                        <div onClick={(e) => e.stopPropagation()}>
                            {mode !== "add" ? (
                                taxcollectorbutton &&
                                (<div
                                    className={`add_icon text-white border-white mt-1`}
                                    onClick={() => {
                                        setMode("add");
                                        seAddMode(false)

                                    }}
                                // className={`add_icon text-white border-${!isCompleteOrder ? "white" : "secondary cursor-disabled"} mt-1`}
                                // onClick={!isCompleteOrder ? () => {
                                //     setMode("add");
                                //     seAddMode(false)

                                // } : undefined}
                                >
                                    <FaPlus
                                        // color={!isCompleteOrder ? "#fff" : "#808080"}
                                        color={"#fff"}
                                    />
                                </div>)
                            ) : (
                                <div
                                    className="add_icon text-white border-white mt-1"
                                    onClick={() => {
                                        setMode("view");
                                        seAddMode(true)
                                    }}
                                >
                                    <MdClose size={20} />
                                </div>
                            )}
                        </div>

                    )}
                    accordion
                    defaultActiveKey={"1"}

                >
                    <Panel

                        className="main-bg-color rounded-3  "
                        header={
                            <div
                                className=" d-flex align-items-center "
                                style={{ fontSize: 18, marginBottom: 0 }}
                            >
                                TaxCollector Information
                            </div>
                        }
                        //
                        key="1"

                    >
                        {
                            taxDataList.length === 0 && <div className="wrapper">
                                <div className="main " >
                                    <TaxCollectorDetails
                                        info={info}
                                        toolsData={toolsData}
                                        data={data}
                                        editId={null}
                                        setKey={setKey}
                                        setMode={setMode}
                                        mode={modeMemo}
                                        addMode={addMode}
                                        taxlistRecorods={taxDataList}
                                        refetchTaxList={refecthTax}
                                        refresh={refresh}
                                        parcelAutomationStatus={parcelAutomationStatus}
                                        setProcessbar={setProcessbar}
                                        //api call automation start
                                        automationStart={automationStart}
                                        //api call conflicts refecth
                                        validateConflicts={validateConflicts}
                                        //automation start result for fetching details of tax
                                        juriupdateAutomationResult={juriupdateAutomationResult}
                                    />
                                </div>
                            </div>
                        }
                        {taxDataList.length > 0 && <TabStrip
                            selected={selected}
                            onSelect={handleSelect}
                            // scrollable={true}
                            style={{
                                width: "100%",
                                userSelect: "none",
                            }}
                        >
                            {taxDataList.map((ele, index) => {
                                return <TabStripTab
                                    title={ele.tax_collector_name}
                                    key={index}
                                    className="w-100"
                                >
                                    {!taxCollectorsListApi.isLoading && <div className="wrapper">
                                        <div className="main " >
                                            <TaxCollectorDetails
                                                info={info}
                                                toolsData={toolsData}
                                                data={data}
                                                editId={ele.id}
                                                setKey={setKey}
                                                setMode={setMode}
                                                mode={modeMemo}
                                                addMode={addMode}
                                                taxlistRecorods={taxDataList}
                                                refetchTaxList={refecthTax}
                                                refresh={refresh}
                                                parcelAutomationStatus={parcelAutomationStatus}
                                                setProcessbar={setProcessbar}
                                                //api call automation start
                                                automationStart={automationStart}
                                                //api call conflicts refecth
                                                parcelList={parcelList}
                                                validateConflicts={validateConflicts}
                                                //automation start result for fetching details of tax
                                                juriupdateAutomationResult={juriupdateAutomationResult}
                                            />
                                        </div>
                                    </div>}
                                </TabStripTab>

                            }
                            )}
                        </TabStrip>}
                    </Panel>
                </Collapse>
            </div>
        </div>

    )
}
