import AppTable from "../../../Components/AppTable/AppTable";
import AppModal from "../../../Components/AppModal/AppModal";
import { EditOutlined } from "@ant-design/icons";
import { FaSearch, FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import { useState } from "react";
import { useSelector } from "react-redux";
import AppSpinner from "../../../Components/AppSpinner/AppSpinner";
import { Input, Button, Form } from "antd";
import { FaPlus } from "react-icons/fa";
import { useEffect } from "react";
import { } from "../../../Redux/Services/Jurisdiction";
//import {JurisdictionModal} from "./JurisdictionModal";
import HoaMgmtModal from "./HoaModal";
import {
	useHoaFilterListMutation,
	useHoaListQuery,
	useHoaSelectFilterListMutation,
	useHoaSubdFiletrListBaseNameMutation,
	useHoaSubdFiletrListMutation,
	useHOCFilterListMutation,
	useSubDivisionFilterListMutation,
} from "../../../Redux/Services/Hoa";
import AppFilterSelect from "../../../Components/AppFilterSelect/AppFilterSelect";
import { useGetCountyListMutation } from "../../../Redux/Services/county";
import { FiFilter, FiRotateCw } from "react-icons/fi";
import SearchColumns from "../../../Components/SearchColumns";
import { dateFormat } from "../../../Controllers/Functions";

const Hoa = ({ refresh, optionsShow = true, parerentData = [] }) => {
	const [showHide, setShowHide] = useState(false);
	const [subd, setSubd] = useState(false);
	const [subdSearch, setSubdSearch] = useState("");
	const [initialStateVal, setInitialStateVal] = useState(false);
	const [data, setData] = useState([]);
	const [editId, setEditId] = useState(null);
	const [search, setSearch] = useState("");
	const [open, setOpen] = useState(false);
	const [hoaValues] = useState({
		cycle_id: "",
		hoa_collector_id: "",
		annotation_id: "",
		name: "",
		website: "",
		state_id: "",
		selDisplay: "",
		selReadOnly: "",
		county_id: "",
		is_direct_contacts: false,
		is_master: false,
		is_association: false,
		display_oncert_comment: "",
		read_only_comment: "",
		is_mailing_address: false,
		is_self_collected: false,
		master_association: [],
		sub_association: [],
		mailing_phone_number: "",
		mailing_address_one: "",
		mailing_address_two: "",
		mailing_state_id: "",
		mailing_city: "",
		mailing_zip_five: "",
		mailing_zip_four: "",
		mailing_contact_position: "",
		mailing_fax: "",
		hoa_type: "",
		fax: "",
		physical_phone_number: "",
		physical_address_one: "",
		physical_address_two: "",
		physical_state_id: "",
		physical_city: "",
		physical_zip_five: "",
		physical_zip_four: "",
		physical_contact_position: "",
		physical_fax: "",
		comments: "",
		hoa_verified_date: "",
		contact_verified_date: "",
		show_on_certificate: false,
		hoa_information_comments: [],
		hoa_information_management_fees: [],
		hoa_information_emails: [],
		hoa_collector_notes:[],
		hoa_subdivisions: []
	});

	//set  ascend or descend sorter state
	const [getsortorder, setSortOrder] = useState(false);
	const [getsortfield, setSortField] = useState("");
	const [countyOptions, setCountyOptions] = useState([]);
	const [subdivisionOptions, setSubdivisionOptions] = useState([]);

	const [hocOptions, setHocOptions] = useState([]);
	const [filetrVal, setFiletrVal] = useState({
		state: "",
		county: "",
		subdivision: "",
		hoa: "",
	});
	//redux data store
	const { token } = useSelector((state) => state.auth);
	const { hoaModule } = useSelector((state) => state.flagRoles);

	const [form] = Form.useForm();
	//calling rtk query hook for geting Hoa list
	const hoaList = useHoaListQuery(token);

	const [hoaFilterList, hoaFilterListResult] = useHoaFilterListMutation();
	

	//filter table data base on subd code
	const [HoaSubdFiletrList, HoaSubdFiletrListResult] =
		useHoaSubdFiletrListMutation();

	//filter table data base on subd name
	const [HoaSubdFiletrListBaseName, HoaSubdFiletrListBaseNameResult] =
		useHoaSubdFiletrListBaseNameMutation();

	//edit vendor module
	//edit vendor module
	const editHandler = (e) => {
		setShowHide(true);
		// //sending id to edit vendor modal
		setEditId(e.id);
	};

	//addVendor Handler function with dependency
	const AddVendorHandler = () => {
		setShowHide(true);
		setEditId(null);
	};
	//create function for customer fields
	function matchColumn(value) {
		// if (authColumn.indexOf(value) === -1) {
		// 	return false;
		// } else {
		// 	return true;
		// }
	}
	function closeModal(data) {
		setShowHide(data);
	}
	//create flag for initail state
	const afterClose = () => {
		setInitialStateVal(!initialStateVal);
		// setPreDefualtStateValues(preDefualtStateValues)
	};
	const columns = [
		{
			title: (
				<span>
					{subd ? "Subdivision Name" : "State"}{" "}
					{getsortfield === (subd ? "subdivision_name" : "state_name") &&
						!getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" &&
						getsortfield === (subd ? "subdivision_name" : "state_name") ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" &&
						getsortfield === (subd ? "subdivision_name" : "state_name") ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: subd ? "subdivision_name" : "state_name",
			key: subd ? "subdivision_name" : "state_name",
			sorter: (a, b) =>
				a[subd ? "subdivision_name" : "state_name"]?.localeCompare(
					b[subd ? "subdivision_name" : "state_name"]
				),
			width: subd ? 200 : 160,
			align: "center",
			...SearchColumns(
				subd ? "subdivision_name" : "state_name",
				subd ? "Subdivision Name" : "State Name"
			),
		},
		{
			title: (
				<span>
					{subd ? "Subdivision Code" : "County"}{" "}
					{getsortfield === (subd ? "subdivision_code" : "county_name") &&
						!getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" &&
						getsortfield === (subd ? "subdivision_code" : "county_name") ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" &&
						getsortfield === (subd ? "subdivision_code" : "county_name") ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: subd ? "subdivision_code" : "county_name",
			key: subd ? "subdivision_code" : "county_name",
			sorter: (a, b) =>
				a[subd ? "subdivision_code" : "county_name"]?.localeCompare(
					b[subd ? "subdivision_code" : "county_name"]
				),
			width: subd ? 200 : 160,
			align: "center",
			...SearchColumns(
				subd ? "subdivision_code" : "county_name",
				subd ? "Subdivision Code" : "County Name"
			),
		},
		{
			title: (
				<span>
					HOA Name{" "}
					{getsortfield === "name" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "name" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "name" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "name",
			key: "name",
			align: "center",
			sorter: (a, b) => a.name.localeCompare(b.name),
			width: 200,
			...SearchColumns("name", "HOA Name"),
		},
		{
			title: (
				<span>
					Billing Cycle{" "}
					{getsortfield === "billing_cycle" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "billing_cycle" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "billing_cycle" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "billing_cycle",
			key: "billing_cycle",
			align: "center",
			sorter: (a, b) => a.billing_cycle.localeCompare(b.billing_cycle),
			width: 150,
			...SearchColumns("billing_cycle", "Billing Cycle"),
		},
		{
			title: (
				<span>
					HOA Collector{" "}
					{getsortfield === "hoa_collector" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "hoa_collector" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "hoa_collector" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "hoa_collector",
			className: !optionsShow ? "d-none" : "",
			key: "hoa_collector",
			align: "center",
			sorter: (a, b) => a.hoa_collector.localeCompare(b.hoa_collector),
			width: 200,
			...SearchColumns("hoa_collector", "HOA Collector"),
		},

		{
			title: (
				<span>
					Fees Verified Date{" "}
					{getsortfield === "hoa_verified_date" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" &&
						getsortfield === "hoa_verified_date" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" &&
						getsortfield === "hoa_verified_date" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "hoa_verified_date",
			key: "hoa_verified_date",
			align: "center",
			sorter: (a, b) => a.hoa_verified_date.localeCompare(b.hoa_verified_date),
			width: 160,
			...SearchColumns("hoa_verified_date", "Date Verified"),
			// render(value) {
			// 	return dateFormat(value)
			// }
		},
		{
			title: (
				<span>
					Contact Verified Date{" "}
					{getsortfield === "contact_verified_date" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" &&
						getsortfield === "contact_verified_date" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" &&
						getsortfield === "contact_verified_date" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "contact_verified_date",
			key: "contact_verified_date",
			align: "center",
			sorter: (a, b) =>
				a.contact_verified_date.localeCompare(b.contact_verified_date),
			width: 200,
			...SearchColumns("contact_verified_date", "Contact Verified Date"),
			// render(value) {
			// 	return dateFormat(value)
			// }
		},
		{
			title: (
				<span>
					Website{" "}
					{getsortfield === "website" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "website" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "website" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "website",
			key: "website",
			align: "center",
			sorter: (a, b) => a.website.localeCompare(b.website),
			width: 50,
			...SearchColumns("website", "Website"),
			render(value) {
				return <div className="w-100 ">
					{value}
				</div>
			}
		},

		{
			title: <span>Actions</span>,
			dataIndex: "edit",
			align: "center",
			key: "edit",
			className: `${hoaModule[1].action ? "" : "d-none"}`,
			width: 50,
			render: (value, row) => (
				<EditOutlined
					onClick={() => editHandler(row)}
					cursor={"pointer"}
					size={"20px"}
					color="gray"
				/>
			),
		},
	];

	useEffect(() => {
		setSubd(false);
		if (hoaList.isSuccess) {
			const newData = hoaList.data?.data.map(
				({
					website,
					hoa_verified_date,
					contact_verified_date,
					hoa_collector,
					billing_cycle,
					...ele
				}) => {
					return {
						...ele,
						website: website || " ",
						hoa_verified_date: hoa_verified_date || " ",
						contact_verified_date: contact_verified_date || " ",
						hoa_collector: hoa_collector || " ",
						billing_cycle: billing_cycle || " ",
					};
				}
			);
			if (optionsShow) {
				setData(newData);
			} else {
				setData(
					parerentData.map(
						({
							website,
							hoa_verified_date,
							contact_verified_date,
							hoa_collector,
							billing_cycle,
							...ele
						}) => {
							return {
								...ele,
								website: website || " ",
								hoa_verified_date: hoa_verified_date || " ",
								contact_verified_date: contact_verified_date || " ",
								hoa_collector: hoa_collector || " ",
								billing_cycle: billing_cycle || " ",
							};
						}
					)
				);
			}
		}
	}, [hoaList]);

	//base on state data
	useEffect(() => {
		if (hoaFilterListResult.isSuccess) {
			const newData = hoaFilterListResult.data?.data.map(
				({
					website,
					hoa_verified_date,
					contact_verified_date,
					hoa_collector,
					billing_cycle,
					...ele
				}) => {
					return {
						...ele,
						website: website || " ",
						hoa_verified_date: hoa_verified_date || " ",
						contact_verified_date: contact_verified_date || " ",
						hoa_collector: hoa_collector || " ",
						billing_cycle: billing_cycle || " ",
					};
				}
			);
			setData(newData);
		}
	}, [hoaFilterListResult.isLoading]);

	//base on subd code
	useEffect(() => {
		if (HoaSubdFiletrListResult.isSuccess) {
			const newData = HoaSubdFiletrListResult.data?.data.map(
				({
					website,
					hoa_verified_date,
					contact_verified_date,
					hoa_collector,
					billing_cycle,
					...ele
				}) => {
					return {
						...ele,
						website: website || " ",
						hoa_verified_date: hoa_verified_date || " ",
						contact_verified_date: contact_verified_date || " ",
						hoa_collector: hoa_collector || " ",
						billing_cycle: billing_cycle || " ",
					};
				}
			);
			setData(newData);
		} else {
			setData([]);
		}
	}, [HoaSubdFiletrListResult.isLoading]);

	//base on subd name
	useEffect(() => {
		if (HoaSubdFiletrListBaseNameResult.isSuccess) {
			const newData = HoaSubdFiletrListBaseNameResult.data?.data.map(
				({
					website,
					hoa_verified_date,
					contact_verified_date,
					hoa_collector,
					billing_cycle,
					...ele
				}) => {
					return {
						...ele,
						website: website || " ",
						hoa_verified_date: hoa_verified_date || " ",
						contact_verified_date: contact_verified_date || " ",
						hoa_collector: hoa_collector || " ",
						billing_cycle: billing_cycle || " ",
					};
				}
			);
			setData(newData);
		} else {
			setData([]);
		}
	}, [HoaSubdFiletrListBaseNameResult.isLoading]);

	//calling rtk query hook for geting County list
	const countyList2 = useGetCountyListMutation();
	const [getCountyList] = countyList2;

	//calling rtk query hook for geting subdivision list
	const [subDivisionFilterList, subDivisionFilterListResult] =
		useSubDivisionFilterListMutation();

	//calling rtk query hook for geting HOA CO list
	const [HOCFilterList, HOCFilterListResult] = useHOCFilterListMutation();

	//calling rtk query hook for geting HOA CO list
	const [HoaSelectFilterList, HoaSelectFilterListResult] =
		useHoaSelectFilterListMutation();

	// useEffect for stateslist
	useEffect(() => {
		if (countyList2[1].isSuccess) {
			setCountyOptions(countyList2[1].data?.data);
		}
	}, [countyList2[1].isLoading]);

	// useEffect for stateslist
	useEffect(() => {
		if (subDivisionFilterListResult.isSuccess) {
			setSubdivisionOptions(subDivisionFilterListResult.data?.data);
		}
	}, [subDivisionFilterListResult.isLoading]);

	// useEffect for hoc list
	useEffect(() => {
		if (HOCFilterListResult.isSuccess) {
			setHocOptions(HOCFilterListResult.data?.data);
		}
	}, [HOCFilterListResult.isLoading]);

	const sort = (sortOrder, sortfield) => {
		// console.log(sortOrder, sortfield);
		if (sortOrder) {
			setSortOrder(sortOrder);
			setSortField(sortfield);
		} else {
			setSortOrder(false);
		}
	};

	//base on state data
	useEffect(() => {
		if (HoaSelectFilterList.isSuccess) {
			const newData = HoaSelectFilterList.data?.data.map(
				({
					website,
					hoa_verified_date,
					contact_verified_date,
					hoa_collector,
					...ele
				}) => {
					return {
						...ele,
						website: website || " ",
						hoa_verified_date: hoa_verified_date || " ",
						contact_verified_date: contact_verified_date || " ",
						hoa_collector: hoa_collector || " ",
					};
				}
			);
			setData(newData);
		}
	}, [HoaSelectFilterList.isLoading]);

	//base on state data
	useEffect(() => {
		if (HoaSelectFilterListResult.isSuccess) {
			const newData = HoaSelectFilterListResult.data?.data.map(
				({
					website,
					hoa_verified_date,
					hoa_collector,
					contact_verified_date,
					...ele
				}) => {
					return {
						...ele,
						website: website || " ",
						hoa_verified_date: hoa_verified_date || " ",
						contact_verified_date: contact_verified_date || " ",
						hoa_collector: hoa_collector || " ",
					};
				}
			);
			setData(newData);
		}
	}, [HoaSelectFilterListResult.isLoading]);

	//state list
	const { stateList } = useSelector((state) => state.stateList);

	//options for showing filters
	const options = [
		{
			name: "state",
			value: filetrVal.state,
			data: stateList?.map(({ state_name, id }) => {
				return { name: state_name, id };
			}),
		},
		{
			name: "county",
			value: filetrVal.county,
			data: countyOptions?.map(({ county_name, id }) => {
				return { name: county_name, id };
			}),
		},
		{
			name: "subdivision",
			value: filetrVal.subdivision,
			data: subdivisionOptions,
			disabled: subd,
		},
		{
			name: "HOA Collector",
			value: filetrVal["HOA Collector"],
			data: hocOptions,
			disabled: subd,
		},
	];

	//function
	function clearState() {
		setCountyOptions([]);
		setSubdivisionOptions([]);
		setHocOptions([]);
		//reset
		if (filetrVal.state) {
			hoaList.refetch();
			setSubd(false);
			form.resetFields();
			setSubdSearch("");
		}
		setFiletrVal({
			...filetrVal,
			state: "",
			["HOA Collector"]: "",
			county: "",
			subdivision: "",
		});
	}
	//function
	function clearCounty() {
		//reset

		setSubdivisionOptions([]);
		if (filetrVal.state && filetrVal.county) {
			hoaFilterList({
				data: { state_id: filetrVal.state },
				token,
			});
			HOCFilterList({
				data: { state_id: filetrVal.state },
				token,
			});
			///clear subd filters
			setSubd(false);
			setSubdSearch("");
		}
		setFiletrVal({
			...filetrVal,
			["HOA Collector"]: "",
			county: "",
			subdivision: "",
		});
		form.resetFields();
	}
	//function
	function clearSubdivision() {
		//reset

		if (filetrVal.county && filetrVal.subdivision) {
			setHocOptions([]);
			hoaFilterList({
				data: { county_id: filetrVal.county },
				token,
			});
			HOCFilterList({
				data: { county_id: filetrVal.county },
				token,
			});
		}
		setFiletrVal({
			...filetrVal,
			subdivision: "",
			["HOA Collector"]: "",
		});
	}
	//function
	function clearHoc() {
		//reset
		if (
			!filetrVal.subdivision &&
			!filetrVal.county &&
			filetrVal.state &&
			filetrVal["HOA Collector"]
		) {
			hoaFilterList({
				data: { state_id: filetrVal.state },
				token,
			});
		} else if (
			!filetrVal.subdivision &&
			filetrVal.county &&
			filetrVal["HOA Collector"]
		) {
			hoaFilterList({
				data: { county_id: filetrVal.county },
				token,
			});
		} else if (
			filetrVal.subdivision &&
			filetrVal.county &&
			filetrVal["HOA Collector"]
		) {
			hoaFilterList({
				data: {
					subdivision_id: filetrVal.subdivision,
				},
				token,
			});
		}

		setFiletrVal({
			...filetrVal,
			["HOA Collector"]: "",
		});
	}

	useEffect(() => {
		setSubd(false);
		setSubdSearch("");
		if (refresh === "hoa" || !optionsShow) {
			hoaList.refetch();
		}
		setOpen(false);
		clearState();
	}, [refresh]);

	return (
		<>
			{hoaList.isLoading ? (
				<AppSpinner />
			) : (
				<>
					<div className="d-flex align-items-center  mb-1 gap-2 justify-content-end">
						<AppModal
							showModal={showHide}
							onClose={closeModal}
							width={"90%"}
							height={400}
							confirmProp={true}
							afterClose={afterClose}
							// passing props for default values set
							element={
								<HoaMgmtModal
									setOpen={setOpen}
									optionsShow={optionsShow}
									AddVendorHandler={AddVendorHandler}
									editId={editId}
									afterClose={initialStateVal}
									setShowHide={setShowHide}
									initialValues={hoaValues}
								/>
							}
						/>
						{/* changing input values globaly  */}
						{optionsShow && (
							<>
								<div className=" me-1 w-25 ">
									<Input
										placeholder="Search here"
										className="global-search "
										value={search}
										onChange={(e) => setSearch(e.target.value)}
										prefix={<FaSearch color="lightgray" />}
									/>
								</div>
								<div className="filter-button ">
									<Button
										className="filter-btn"
										onClick={() => {
											setOpen(!open);
											setFiletrVal({ state: "", county: "" });
											if (open) {
												hoaList.refetch();
											}
										}}
										type={open ? "primary" : "default"}
									>
										<FiFilter color={open ? "#fff" : ""} />{" "}
										<span className="ms-1">Filter</span>
									</Button>
								</div>

								{hoaModule.map((item, index) => {
									if (item.add_button) {
										return (
											<div key={index} className="add_icon" onClick={AddVendorHandler}>
												<FaPlus />
											</div>
										);
									}
									return null;
								})}
							</>
						)}
					</div>
					<div style={{ clear: "both" }}></div>
					{/* //fileter options for table data */}
					{open && (
						<div className="p-2 bg-white border shadow-sm">
							<div className="my-2  d-flex align-items-center ">
								<div className="row w-100">
									{options?.map(({ value, name, data, disabled }, index) => {
										return (
											<div className="col-12 col-lg-3">
												<AppFilterSelect
													name={name}
													disabled={disabled}
													value={value}
													options={data}
													onClear={() => {
														if (name === "state") {
															clearState();
														} else if (name === "county") {
															clearCounty();
														} else if (name === "subdivision") {
															clearSubdivision();
														} else if (name === "HOA Collector") {
															setFiletrVal({
																...filetrVal,
																["HOA Collector"]: "",
															});
															clearHoc();
														}
													}}
													onChange={(e) => {
														setFiletrVal({
															...filetrVal,
															[name]: e.target.value,
														});

														if (name === "state" && e.target.value) {
															setSubd(false);
															setFiletrVal({
																...filetrVal,
																["HOA Collector"]: "",
																county: "",
																state: e.target.value,
																subdivision: "",
															});
															//fetching county list
															getCountyList({
																state_id: { state_id: e.target.value },
																token,
															});
															//fetching data base
															hoaFilterList({
																data: { state_id: e.target.value },
																token,
															});

															//fetching data base
															HOCFilterList({
																data: { state_id: e.target.value },
																token,
															});
														}

														if (name === "state" && !e.target.value) {
															clearState();
														}

														if (name === "county" && e.target.value) {
															setSubd(false);
															//fetching data base
															subDivisionFilterList({
																id: e.target.value,
																token,
															});
															hoaFilterList({
																data: {
																	county_id: e.target.value,
																},
																token,
															});

															HOCFilterList({
																data: {
																	county_id: e.target.value,
																},
																token,
															});
														}
														if (name === "county" && !e.target.value) {
															clearCounty();
														}

														if (name === "subdivision" && e.target.value) {
															//fetching data base
															HOCFilterList({
																data: { subdivision_id: e.target.value },
																token,
															});

															hoaFilterList({
																data: {
																	subdivision_id: e.target.value,
																},
																token,
															});
														}
														if (name === "subdivision" && !e.target.value) {
															clearSubdivision();
														}
														if (name === "HOA Collector" && e.target.value) {
															//fetching data base
															if (filetrVal.county && filetrVal.state) {
																hoaFilterList({
																	data: {
																		hoa_collector_id: e.target.value,
																		county_id: filetrVal.county,
																	},
																	token,
																});
															}
															if (!filetrVal.county) {
																hoaFilterList({
																	data: {
																		hoa_collector_id: e.target.value,
																		state_id: filetrVal.state,
																	},
																	token,
																});
															}
														}
														if (name === "HOA Collector" && !e.target.value) {
															clearHoc();
														}
													}}
												/>
											</div>
										);
									})}
								</div>

								<div className="d-flex justify-centent-end ms-md-2">
									<Button
										className="reset-btn"
										onClick={() => {
											clearState();
										}}
										type="default"
									>
										<FiRotateCw /> <span className="ms-1"> Reset</span>
									</Button>
								</div>
							</div>
							{(filetrVal?.county || filetrVal?.state) && (
								<div className="d-flex align-items-center gap-1 search-filter-form">
									<select
										value={subd}
										onChange={(e) => {
											const { value } = e.target;
											setData([]);
											setSubd(value);
											// clearSubdivision();
											// clearHoc();
											form.resetFields();
											if (!value) {
												if (filetrVal.county && filetrVal.state) {
													hoaFilterList({
														data: { county_id: filetrVal.county },
														token,
													});
												} else if (!filetrVal.county && filetrVal.state) {
													hoaFilterList({
														data: { state_id: filetrVal.state },
														token,
													});
												}
												setSubd(false);
											}
											// clearHoc();
										}}
										className="border p-1"
									>
										<option value="">Select SUBD</option>
										<option value="name">SUBD_NAME</option>
										<option value="code">SUBD_CODE</option>
									</select>
									<Form
										form={form}
										name="basic"
										className="d-flex gap-1"
										onFinish={(value) => {
											if (subd === "name") {
												// sending req base on name
												if (filetrVal.county && filetrVal.state) {
													HoaSubdFiletrListBaseName({
														data: {
															name: value.subdSearch,
															county_id: filetrVal.county,
															state_id: filetrVal.state,
														},
														token,
													});
												} else if (filetrVal.state) {
													HoaSubdFiletrListBaseName({
														data: {
															name: value.subdSearch,
															state_id: filetrVal.state,
														},
														token,
													});
												}
											} else if (subd === "code") {
												// sending req base on code
												if (filetrVal.county && filetrVal.state) {
													HoaSubdFiletrList({
														data: {
															code: value.subdSearch,
															county_id: filetrVal.county,
															state_id: filetrVal.state,
														},
														token,
													});
												} else if (!filetrVal.county && filetrVal.state) {
													HoaSubdFiletrList({
														data: {
															code: value.subdSearch,
															state_id: filetrVal.state,
														},
														token,
													});
												}
											}
											// console.log({
											//   [subd]: value.subdSearch,
											//   county_id: filetrVal.county,
											// });
										}}
										autoComplete="off"
									>
										{subd && (
											<Form.Item
												value={subdSearch}
												name="subdSearch"
												placeholder="Search here"
												rules={[
													{
														required: true,
														message: "Search value should not blank!",
													},
												]}
											>
												<Input
													className="global-search "
													placeholder="Search here"
													prefix={<FaSearch color="lightgray" />}
												/>
											</Form.Item>
										)}
										{subd && (
											<Button htmlType="submit" type="primary">
												<span className="ms-1"> SUBMIT</span>
											</Button>
										)}
									</Form>
								</div>
							)}
						</div>
					)}
					<AppTable
						sort={sort}
						loading={
							hoaList.isFetching ||
							hoaFilterListResult.isLoading ||
							HoaSelectFilterListResult.isLoading ||
							subDivisionFilterListResult.isLoading ||
							HoaSubdFiletrListBaseNameResult.isLoading ||
							HoaSubdFiletrListResult.isLoading
						}
						pagination={optionsShow}
						search={search}
						dataSource={data}
						columns={columns}
					/>
				</>
			)}
		</>
	);
};

export default Hoa;
