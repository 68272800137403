import { Collapse } from "antd";
import React from "react";
import ManualEntry from "../../NewParcel/ManualEntry/ManualEntry";
const { Panel } = Collapse;
export default function ParcelInfo({
  info,
  mode,
  setKey,
  refresh,
  validateConflicts,
  data,
  parcelInfoResult,
  toolsData,  
  EditHandlerComp,
  refecthParcelStatus,
  setShowYearFlag,
}) {


  return (
    <div className="row mt-2">
      <Collapse
        className="rounded-3 border-0 text-white "
        expandIcon={() => <></>}
        accordion
        defaultActiveKey={"1"}
      >
        <Panel
          className="main-bg-color rounded-3  "
          header={`Parcel Information`}
          style={{ fontSize: 18 }}
          key="1"
        >
          <ManualEntry
          validateConflicts={validateConflicts}
          parcelInfoResult={parcelInfoResult}
            refecthParcelStatus={refecthParcelStatus}
            EditHandlerComp={EditHandlerComp}
            toolsData={toolsData}
            data={data}
            setShowYearFlag={setShowYearFlag}
            refresh={refresh}
            editId={info.id}
            mode={mode}
            setKey={setKey}
          />
        </Panel>
      </Collapse>
    </div>
  );
}
