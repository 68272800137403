import { useState } from "react";
import "./AppInput.css";
import { Row } from "react-bootstrap";

import { BsExclamationCircle } from "react-icons/bs";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { useEffect } from "react";
// import { DatePicker } from '@progress/kendo-react-dateinputs';
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import '@progress/kendo-theme-default/dist/all.css';
dayjs.extend(customParseFormat);
const dateFormat = 'MM/DD/YYYY';
const AppInput = (props) => {
  const [focused, setFocused] = useState(false);
  const [showHide, setShowHide] = useState(true);
  const {
    subhead,
    column,
    label,
    errorMessage,
    onChange,
    defaultSet,
    id,
    maxLength,
    preview,
    hide,
    color,
    textColor,
    margin,
    getErrorFocus,
    disabledDate,
    usaFormat = false,
    ...inputProps
  } = props;

  const handleFocus = (e) => {

    setFocused(true);
  };
  const [date, setDate] = useState()

  useEffect(() => {
    setFocused(false);

  }, [defaultSet]);


  useEffect(() => {
    if (inputProps.type === "date" && inputProps.value) {
      const dateValue = String(inputProps.value)
      if (dateValue?.split("-")[0]?.length === 4) {
        setDate()
      } else if (dateValue) {
        setDate(dateValue.replace(/-/g, "/"))
      }
    } else if (inputProps.type === "date" && !inputProps.value) {
      setDate()
    }
  }, [inputProps.value, defaultSet]);


  const handleChange = (e) => {
    if (e.target.value) {
      // let formatedDate = new Date(e.target.value).toLocaleDateString("en-US", {
      //   day: '2-digit',
      //   month: '2-digit',
      //   year: 'numeric',
      // })
      // formatedDate = String(formatedDate).replace(/\//g, "-")
      onChange(e)
    }
  }

  const dateChange = (name, value) => {
    if (value) {
      setDate(value)
      onChange(inputProps.name, value?.replace(/\//g, "-"), true)
    } else {
      setDate()
      onChange(inputProps.name, value, true)
    }
  }


  return (
    <>
      {subhead && <h6 className="mt-3">{subhead} {subhead === "Enter New Password" && <span className="text-danger fs-10 d-block mt-1">
        <BsExclamationCircle
          className="alertIcon"
          style={{ marginBottom: "3px" }}
        />{"Password must be at least 8 characters in length and must include at least One Upper Case Letter, One Number, and One Special Character!"}</span>}</h6>}
      {!hide && (
        <div
          className={`  col-12 app-input ${margin} col-md-${column} ${inputProps.disabled && "disable-input"
            }`}
        >
          <div className={` position-relative `}>
            {label && (
              <Row className="mt-3">
                <label style={{ fontSize: textColor ? 14 : 12 }} className={`ms-1 heading-5 ${textColor || "text-dark"} `}>
                  {label}
                  {inputProps.required && label && !color && (
                    <span className="text-danger">*</span>
                  )}
                  {inputProps.isEdit && (
                    <span className="text-dark fw-bold"> :</span>
                  )}
                </label>
              </Row>
            )}
            {!inputProps.isEdit ? (
              inputProps?.type === "date" && usaFormat ?
                <DatePicker name={inputProps.name} value={date ? dayjs(date, dateFormat) : ""} placeholder={"MM/DD/YYYY"} disabled={inputProps.disabled} onChange={dateChange} format={dateFormat} disabledDate={disabledDate}  />
                //  <DatePicker
                //   onChange={handleChange}
                //   // {...inputProps}
                //   name={inputProps.name}
                //   // required={inputProps?.required}
                //   value={inputProps.value ? new Date(inputProps.value) : ""}
                //   onBlur={handleFocus}
                //   disabled={inputProps.disabled}
                //   format="MM-dd-yyyy" // Set the desired date format
                //   placeholder="MM/DD/YYYY"
                //   // valid={true}
                //   focused={focused.toString()}
                // /> 
                :
                <input
                  maxLength={maxLength || 50}
                  id={inputProps.name}
                  className={`input-field mt-0 pb-2 w-100  heading-5 ${inputProps.icon && "icon-input"
                    }`}
                  {...inputProps}
                  type={
                    inputProps.type === "password" && showHide
                      ? "password"
                      : inputProps.type === "number"
                        ? "number"
                        : inputProps.type === "email"
                          ? "email"
                          : inputProps.type === "date"
                            ? "date"
                            : inputProps.type === "datetime"
                              ? "datetime-local"
                              : "text"
                  }
                  onChange={onChange}
                  onBlur={handleFocus}
                  disabled={inputProps.disabled}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                    if (inputProps.type === "date") {
                      e.preventDefault();
                    }
                  }}
                  onFocus={() =>
                    (inputProps.name === "confirmPassword" ||
                      inputProps.name === "newPassword") &&
                    setFocused(true)
                  }
                  focused={focused.toString()}
                />
            ) : (
              <div className={`mx-1 heading-5 mt-0 ${textColor || ""}`} style={{ marginTop: 16, fontSize: textColor ? 14 : 12, wordBreak: "break-all" }}>
                {inputProps.value || "NA"}
              </div>
            )}

            <div className={`input-icon`}>
              {inputProps?.icon && (
                <>
                  <span>{inputProps.icon}</span>
                </>
              )}
              {inputProps?.type === "password" && (
                <span
                  className="passIcon"
                  onClick={() => setShowHide(!showHide)}
                >
                  {showHide ? <FaEyeSlash /> : <FaEye />}
                </span>
              )}
            </div>

            <span className="input-error">
              <BsExclamationCircle
                className="alertIcon"
                style={{ marginBottom: "3px" }}
              />
              {errorMessage}
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default AppInput;
