import {
  CloseOutlined,
  EditOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  Alert,
  notification,
  Popconfirm,
  Tooltip,
  Spin,
} from "antd";
import React, { useContext, useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";

import { AiOutlineAudit, AiOutlineEdit, AiOutlineEye } from "react-icons/ai";
import { FaPlus } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";
import {
  MdDeleteOutline,
} from "react-icons/md";
import { VscRunErrors } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import { GlobalContext } from "../../../../App";
import {
  useAddParcelDoucmentMutation,
  useDeleteParcelMutation,
  useParcelAutomationStartMutation,
  useParcelAuditLogsListMutation,
  useUpdateParcelCommentsMutation,
} from "../../../../Redux/Services/Parcels";
import { parcelContext } from "../OrderEditor";

import ParcelInfo from "./Parcel_info/ParcelInfo";
import { TaxCollectorDetails } from "./Tax_Collector_info/TaxCollectorDetails";

import {
  useAutomationStatusUpdateMutation,
  useTestOrderDetailsMutation,
} from "../../../../Redux/Services/TaxCollector";
import { useUpdateParcelJuriAutomationMutation } from "../../../../Redux/Services/Jurisdiction";

import SupportDocs from "./SupportingDocs/SupportDocs";
import { useLocation } from "react-router-dom";
import { dateFun, scrollComp } from "../../../../Controllers/Functions";
import "./parcel.css";
import Conflicts from "../Conflicts/Conflicts";
import {
  useAfterUpdateConflictsMutation,
  useAutoConflictsStatusUpdateMutation,
  useReviewConflictsMutation,
  useUpdateCalculationsMutation,
  useValidateConflictsMutation,
} from "../../../../Redux/Services/Orders";
import { CgViewSplit } from "react-icons/cg";

import MyHoa from "./MyHOA/MyHoa";
import AppModal from "../../../../Components/AppModal/AppModal";
import AuditLogs from "../../AuditLogs/AuditLogs";
import { BsFilePdf } from "react-icons/bs";
import { MyTaxCollector } from "./Tax_Collector_info/MyTaxCollector";
import { addParcelData, parcelViewDocHandler } from "../../../../Redux/reducers/ParcelReducer/Parcel.reducer";
import { useAutomatiomStatusCheck } from "../../../../Hooks/useAutomatiomStatusCheck";
import OrderBtns from "../../../../Components/OrderBtns";
import { useAutomationStop } from "../../../../Hooks/useAutomationStop";
import { useHoaAutoUpdateStatusMutation, useHoaValidationMutation } from "../../../../Redux/Services/Hoa";
import useSendFeedBack from "../../../../Hooks/useSendFeedBack";

const config = require("../../../../config.json");

export default function ParcelsMain({
  info,
  setKey,
  setNewParcelTabFlag,
  refresh,
  parcelInfo,
  parcelInfoResult,
  addCertificateDocumentsResult,
  parcelDocumentCountApi,
  parcelDocumentCountResult,
  setOpen,
  getParcelStatusInfo,
  getParcelStatusInfoResult,
  parcelList,
  setConflictProcess,
  conflictsProcess,
  conflicts,
  parcelHoaList,
  parcelHoaListResult,
  conflictsResult,
  getParcelHoaStatus,
  getParcelHoaStatusResult,
  SendEmailComp,
  downloadCertificateApiResult,
  certficateDownload,
  parcelStatusList = false,
  validateConflicts: orderValidateConflicts,
}) {
  const dispatch = useDispatch()
  //context api
  const { controller } = useContext(parcelContext);
  const [parcelController, setParcelController] = controller;
  //redux
  const { orderDATA, isDownloadingCertificate, bakendAutomation } = useSelector((state) => state.OrderData);
  const { parcelViwDoc } = useSelector((state) => state.parcelReducer);
  //context api
  const { state } = useContext(GlobalContext);
  const [globalVal, setGlobalVal] = state;
  const { parceData } = useSelector((state) => state.parcelReducer);
  const progressData = {
    initiated: true,
    progress: false,
    failed: false,
    success: false,
  };
  const [processbar, setProcessbar] = useState(progressData);
  //states
  const [getParcelData, setGetParcelData] = useState({ year_list: [] });

  const [parcelYears, setParcelYears] = useState([]);
  const [toolsData, setToolsData] = useState({ year: "" });
  const [showYearFlag, setShowYearFlag] = useState(true);
  const [showSearchErr, setShowSearchErr] = useState(false);
  const [delayDom, setDelayDom] = useState(false);

  const [validateConflictsRunnig, setValidateConflictsRunnig] = useState(false);
  const [conflictsNotification, setConflictsNotification] = useState(false);
  const [automationReqCount, setAutomationReqCount] = useState(0);
  const [, setEditable] = useState(false);
  const [parcelConflicts, setParcelConflicts] = useState([]);
  const [showAuditLogModal, setShowAuditLogModal] = useState(false);
  const [auditLogData, setAuditLogData] = useState([]);

  const { token, user } = useSelector((state) => state.auth);
  //redux
  const { parcelEditInOrder, newInterNalParcel } = useSelector((state) => state.flagRoles);

  const location = useLocation();
  //third party
  const [orderDetailsTest, orderDetailsTestResult] =
    useTestOrderDetailsMutation();


  const { SendFeedBackComponent } = useSendFeedBack()
  //update automation status
  const [automationStatusUpdate, automationStatusUpdateResult] =
    useAutomationStatusUpdateMutation();

  //audit logs
  const [auditLogsListCall, auditLogsListResult] =
    useParcelAuditLogsListMutation();

  //api
  const [addAutomationDocuments, addAutomationDocumentsResult] =
    useAddParcelDoucmentMutation();

  //update automation juri data
  const [juriupdateAutomation, juriupdateAutomationResult] =
    useUpdateParcelJuriAutomationMutation();

  //parcel automation start
  const [automationStart, automationStartResult] =
    useParcelAutomationStartMutation();




  //getting Delete parcel api
  const [parcelDelete, parcelDeleteResult] = useDeleteParcelMutation();
  const { StopAutomationComp } = useAutomationStop(token);


  //update parcel coments info
  const [updateParcelComments, updateParcelCommentsResult] =
    useUpdateParcelCommentsMutation();


  useEffect(() => {
    if (updateParcelCommentsResult.isSuccess) {
      parcelInfo({
        data: {
          parcel_id: info.id,
          year: info.parcel_year || parcelController.year,
        },
        token,
      });
    }
  }, [updateParcelCommentsResult.isLoading])

  useEffect(() => {
    if (downloadCertificateApiResult.isSuccess) {
      setValidateConflictsRunnig(false)
      getParcelStatusFun()
    }
  }, [downloadCertificateApiResult.isLoading])
  //update conflicts info
  const [validateConflicts, validateConflictsResult] =
    useValidateConflictsMutation();


  const [updateCalculationsApi,] =
    useUpdateCalculationsMutation();

  //update status base on conflict for auto update status info
  const [autoStatusUpdate, autoStatusUpdateResult] =
    useAutoConflictsStatusUpdateMutation();

  //review conflicts
  const [reviewConflicts, reviewConflictsResult] = useReviewConflictsMutation();

  //delete parcel
  const deleteOrderHandler = () => {
    //calling parcel delete api
    parcelDelete({
      data: {
        parcel_id: [getParcelData.id],
      },
      token,
    });
  };

  function getParcelStatusFun() {
    //gtting parcel status info
    getParcelStatusInfo({ token, parcel_id: info.id });
  }

  useEffect(() => {
    if (automationStatusUpdateResult.isSuccess) {
      setAutomationReqCount(0);
    }
    //global spinner
    setGlobalVal({
      ...globalVal,
      loader: automationStatusUpdateResult.isLoading,
    });
  }, [automationStatusUpdateResult.isLoading]);

  //auditlist
  useEffect(() => {
    if (auditLogsListResult.isSuccess) {
      setAuditLogData(auditLogsListResult.data?.data);
      setShowAuditLogModal(true);
    } else {
      setAuditLogData([]);
      setShowAuditLogModal(false);
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: auditLogsListResult.isLoading });
  }, [auditLogsListResult.isLoading]);

  //auto status update
  useEffect(() => {
    if (autoStatusUpdateResult.isSuccess) {
      //get parcel status
      getParcelStatusFun();
      //save certificate store in DB
      setTimeout(() => {
        parcelController?.storeCertificateInDbFun && parcelController?.storeCertificateInDbFun();

      }, 5000);
    }
  }, [autoStatusUpdateResult.isLoading]);
  //disable spinner of validation errors
  useEffect(() => {
    if (addCertificateDocumentsResult?.isSuccess || addCertificateDocumentsResult?.isError) {
      setValidateConflictsRunnig(false)
    }
  }, [addCertificateDocumentsResult.isLoading]);


  //setParcel Status
  useEffect(() => {
    if (getParcelStatusInfoResult.isSuccess) {
      setGetParcelData({
        ...getParcelData,
        status_id: getParcelStatusInfoResult.data.status,
      });
      setConflictsNotification(!getParcelStatusInfoResult?.data?.is_fixed);
    }
  }, [getParcelStatusInfoResult.isLoading]);

  // useEffect for validation parcel
  useEffect(() => {
    if (validateConflictsResult.isLoading === true) {
      setValidateConflictsRunnig(true)
    }

    if (validateConflictsResult.isSuccess) {
      //calling conflicts api
      // getParcelStatusFun()

      if (parcelController?.parcelTwoView) {
        conflicts({ data: { parcel_id: getParcelData.id }, token });
      }

      //download certificate
      certficateDownload()

      // setTimeout(() => {
      //   //run new api
      //   updateCalculationsApi({ order_id: location?.state?.id, token })

      //   //auto update status
      //   autoStatusUpdate({ data: { order_id: location?.state?.id }, token, isManual: validateConflictsResult?.data?.isManual });
      // }, 5000);

    }

    if (validateConflictsResult.isError) {
      setValidateConflictsRunnig(false)
    }

    setConflictProcess(validateConflictsResult.isLoading);
  }, [validateConflictsResult.isLoading]);

  const [HoaValidateConflicts, HoaValidateConflictsResult] = useHoaValidationMutation()
  const [HoaAutoStatusUpdate, HoaAutoStatusUpdateResult] = useHoaAutoUpdateStatusMutation()

  // useEffect for validation parcel
  useEffect(() => {
    if (HoaValidateConflictsResult.isSuccess) {
      //calling conflicts api
      setTimeout(() => {
        conflicts({ data: { parcel_id: getParcelData.id }, token });
        //auto update status
        HoaAutoStatusUpdate({ data: { order_id: orderDATA?.id }, token });
      }, 3000);
      //global spinner
      setGlobalVal({ ...globalVal, loader: HoaValidateConflictsResult.isLoading });
    }
  }, [HoaValidateConflictsResult.isLoading]);

  //auto status update
  useEffect(() => {
    if (HoaAutoStatusUpdateResult.isSuccess) {
      //get parcel status
      getParcelStatusFun();
      //save certificate store in DB
      // parcelController?.storeCertificateInDbFun &&
      //   parcelController?.storeCertificateInDbFun();
      certficateDownload()
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: HoaAutoStatusUpdateResult.isLoading });
  }, [HoaAutoStatusUpdateResult.isLoading]);

  //here duplicate api ran
  // useEffect
  //isSuccess
  //original api
  const [ConflictsListForUpdate, ConflictsListForUpdateResult] = useAfterUpdateConflictsMutation()
  //update conflicts info
  // const [UpdateConflictValidateConflicts, UpdateConflictValidateConflictsResult] =
  //   useValidateConflictsMutation();

  // //duplicate ran validation for only conflicts
  // useEffect(() => {
  //   if (UpdateConflictValidateConflictsResult.isSuccess) {
  //     conflicts({ data: { parcel_id: getParcelData.id }, token });
  //   }
  //   setGlobalVal({ ...globalVal, loader: UpdateConflictValidateConflictsResult.isLoading });
  // }, [UpdateConflictValidateConflictsResult.isLoading])

  useEffect(() => {
    if (ConflictsListForUpdateResult.isSuccess) {
      const newData = ConflictsListForUpdateResult.data.data;
      setParcelConflicts(newData);
      if (ConflictsListForUpdateResult?.data?.is_fixed) {

        //flag available run this validation
        validateConflicts({
          data: { order_id: location?.state?.id },
          token,
        })
        // .then(() => {
        //   //refetch updated parcel
        //   getParcelStatusFun()
        //   // parcelInfo({
        //   //   data: {
        //   //     parcel_id: info.id,
        //   //     year: info.parcel_year || parcelController.year,
        //   //   },
        //   //   token,
        //   // })
        // });
        // orderValidateConflicts({
        //   data: { order_id: location?.state?.id },
        //   token,
        // });
      }
      //  else {
      //   if (parcelController?.parcelTwoView) {
      //     conflicts({ data: { parcel_id: getParcelData.id }, token });
      //   }
      // }
    }
    setGlobalVal({ ...globalVal, loader: ConflictsListForUpdateResult.isLoading });
  }, [ConflictsListForUpdateResult.isLoading])

  //update btn response
  useEffect(() => {
    if (reviewConflictsResult.isSuccess) {
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: reviewConflictsResult.data?.message,
      });
      //calling conflicts api
      //i will run duplicate
      ConflictsListForUpdate({ data: { parcel_id: getParcelData.id }, token })

    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: reviewConflictsResult.isLoading });
  }, [reviewConflictsResult.isLoading]);

  //automation start api
  useEffect(() => {

    if (automationStartResult.isSuccess) {
      //check after order automation test
      setParcelController({ ...parcelController, isAutomationRunning: true })
      setTimeout(() => {
        orderDetailsTest({
          data: { order_id: automationStartResult.data?.data },
          token,
        });
      }, 5000);
    }
    if (automationStartResult.isError) {
      setProcessbar({ ...processbar, progress: false });
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description:
          automationStartResult.error?.data?.message || "Automation Faild",
      });
    }
  }, [automationStartResult.isLoading]);

  useEffect(() => {
    if (orderDetailsTestResult.isLoading) {
      //processbar
      setProcessbar({
        ...progressData,
        progress: true,
      });
    }
    if (orderDetailsTestResult.isSuccess) {
      const { OrderStatus, result, automation_ref_no, supporting_files } =
        orderDetailsTestResult.data.data;

      setValidateConflictsRunnig(true)

      if (OrderStatus === "3") {
        setProcessbar({
          ...progressData,
          success: true,
        });
        //when  finished update the status
        automationStatusUpdate({
          data: { automation_status_id: 3, automation_json: result },
          token,
          id: getParcelData.id,
        });
        //calling conflicts api
        setTimeout(() => {
          validateConflicts({
            data: { order_id: location?.state?.id },
            token,
          });

        }, config.validationDelay);
        //when  finished update the juri data
        juriupdateAutomation({
          data: {
            taxes: result?.taxes,
            automation_tax_error: result?.taxError,
            priorYears: result?.priorYears,
            parcel_id: getParcelData?.id,
            DeliquentTax: result?.delinquentStatus
          },
          token,
        });

        try {
          if (supporting_files?.length) {
            let docs = supporting_files?.map(({ file, file_name }) => {
              return {
                document_classification_id: 2,
                document: `data:application/pdf;base64,${file}`,
                name: file_name + "_" + dateFun("datetime"),
                type: "application/pdf",
              };
            });

            // //adding supporting docs
            addAutomationDocuments({
              data: { document_list: docs, parcel_id: getParcelData.id },
              token,
            });
          }
        } catch (error) {
          console.log(error)
        }

      } else if (OrderStatus === "2") {
        //making max request
        if (automationReqCount <= config.automationStatusCount) {
          setTimeout(() => {
            orderDetailsTest({
              data: { order_id: automation_ref_no },
              token,
            });
          }, automationReqCount <= 3 ? 20000 : 10000);

          setAutomationReqCount(automationReqCount + 1);
          setProcessbar({
            ...progressData,
            progress: true,
          });
        } else {
          setProcessbar({
            ...progressData,
            failed: true,
          });
          // //when request limit finished
          automationStatusUpdate({
            data: { automation_status_id: 4 },
            token,
            id: getParcelData.id,
          }).then(() => {

            juriupdateAutomation({
              data: {
                automation_tax_error: "Automation Timed Out!",
                parcel_id: getParcelData?.id,
              },
              token,
            });
            setValidateConflictsRunnig(true)
            setTimeout(() => {
              //calling conflicts api
              validateConflicts({
                data: { order_id: location?.state?.id },
                token,
              });
            }, config.validationDelay)
          });
        }
      } else if (OrderStatus === "4") {
        //when request limit finished
        automationStatusUpdate({
          data: { automation_status_id: 4 },
          token,
          id: getParcelData.id,
        }).then(() => {

          juriupdateAutomation({
            data: {
              automation_tax_error: "Automation Timed Out!",
              parcel_id: getParcelData?.id,
            },
            token,
          });
          setValidateConflictsRunnig(true)
          setTimeout(() => {
            //calling conflicts api
            validateConflicts({
              data: { order_id: location?.state?.id },
              token,
            });
          }, config.validationDelay)
        })
        //processbar
        setProcessbar({ ...progressData, failed: true });
      }
    }
  }, [orderDetailsTestResult.isLoading]);

  ///parcel delete
  useEffect(() => {
    if (parcelDeleteResult.isSuccess) {
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: parcelDeleteResult.data.message,
      });
      orderValidateConflicts({
        data: { order_id: location?.state?.id },
        token,
      });

      //navigate to summary
      setKey("summary");
      parcelStatusList({ id: location.state.id, token });

    } else if (parcelDeleteResult.isError) {
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description: parcelDeleteResult.error?.data?.message,
      });
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: parcelDeleteResult.isLoading });
  }, [parcelDeleteResult.isLoading]);


  useEffect(() => {
    if (parcelInfoResult.isSuccess) {
      const {
        automation_status_id,
        automation_ref_no,
        year_list,
        year,
        status_id,
        id,
      } = parcelInfoResult.data?.data;
      //setiing parcel data
      dispatch(addParcelData(parcelInfoResult.data?.data))

      setGetParcelData({
        ...parcelInfoResult.data?.data,
        id,
        year,
        year_list: Array.from(year_list)?.sort((a, b) => {
          return b - a;
        }),
      });

      //gtting parcel status info
      // if (!conflictsProcess) {
      //   getParcelStatusFun();
      // }

      setParcelController({
        ...parcelController,
        refetch: false,
        parcelId: id,
        // parcelTwoView: false,
        // parcelPdfDoc: false,
      });
      setToolsData({ ...toolsData, year });

      setParcelYears(parcelInfoResult.data?.data);

      //optimise state
      setParcelOpData(parcelInfoResult.data?.data);

      if (parcelController?.parcelTwoView) {
        conflicts({ data: { parcel_id: id }, token });
      }
      //calling conflicts api


    }

    if (parcelInfoResult.error) {
      setGetParcelData({ ...getParcelData, error: "server unreachable" });
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: parcelInfoResult.isLoading });
  }, [parcelInfoResult.isLoading]);

  ///conflicts status
  useEffect(() => {
    if (conflictsResult.isSuccess) {
      const newData = conflictsResult.data.data;
      setParcelConflicts(newData);

      //if get flag

      // let isError = newData.filter(({ is_fixed }) => {
      //   return !is_fixed;
      // });

      // if (isError.length) {
      //   setConflictsNotification(true);
      // } else {
      //   setConflictsNotification(false);
      // }
    }
    if (conflictsResult.isError) {
      setParcelConflicts([]);
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: conflictsResult.isLoading });
  }, [conflictsResult.isLoading]);

  useEffect(() => {
    setShowYearFlag(true);
    setToolsData({ ...toolsData, year: "" });

    if (refresh !== "summary" && (info.id !== parcelController?.parcelId)) {
      setParcelYears([]);
      //delay in the dom load
      setDelayDom(true)
      //parcel info
      setTimeout(() => {
        setDelayDom(false)
      }, 1000);
      // parcelInfo({
      //   data: {
      //     parcel_id: info.id,
      //     year: info.parcel_year || parcelController.year,
      //   },
      //   token,
      // })
    }

  }, [refresh, info.id, bakendAutomation, parcelController?.parcelId]);

  // console.log("parcel key", refresh)

  //refecth t=>{he parcel info
  useEffect(() => {
    if (parcelController?.refetch) {
      parcelInfo({
        data: { parcel_id: info.id, year: parcelController.year },
        token,
      });
      //gtting parcel status info
      // getParcelStatusFun();
      //refetch parcel list after doing any changes in parcel
      if (parcelList.length > 5) {
        parcelStatusList({ id: location.state.id, token });
      }
    }
    if (parcelController.mode === "view") {
      setEditable(false);
    } else if (parcelController.mode === "edit") {
      setEditable(true);
    }
  }, [parcelController.refetch]);

  //disables error message
  useEffect(() => {
    if (showSearchErr) {
      setTimeout(() => {
        setShowSearchErr(false);
      }, 5000);
    }
  }, [showSearchErr]);

  useEffect(() => {
    if (parcelController.parcelPdfDoc?.base64) {
      scrollComp("parcelembed");
    }
  }, [parcelController.parcelPdfDoc]);

  //edithandlerComp
  const EditHandlerComp = () => {
    return (
      <>
        {" "}
        {parcelController.mode === "view" ? (
          <Tooltip
            overlayInnerStyle={{
              fontSize: 10,
            }}
            placement="bottom"
            title={"Edit"}
          >
            <div

              className={`border add_icon  border-primary`}
              onClick={() => {
                setParcelController({
                  ...parcelController,
                  mode: "edit",
                });
                setShowSearchErr(false);
              }}
            >
              <AiOutlineEdit size={18}
                // color={!isCompleteOrder ? "#3388FF" : "#808080"} 
                color={"#3388FF"}
              />
            </div>
          </Tooltip>
        ) : (
          <Tooltip
            overlayInnerStyle={{
              fontSize: 10,
            }}
            placement="bottom"
            title={"close"}
          >
            <div
              className="add_icon "
              onClick={() => {
                parcelInfo({
                  data: {
                    parcel_id: info.id,
                    year: info.parcel_year || parcelController.year,
                  },
                  token,
                });
                setParcelController({
                  ...parcelController,
                  mode: "view",
                });
                setShowYearFlag(true);
                setShowSearchErr(false);
                setToolsData({
                  ...toolsData,
                  year: parcelYears?.year,
                });
                setGetParcelData({
                  ...getParcelData,
                  year_list: parcelYears?.year_list,
                });
              }}
            >
              <CloseOutlined size={18} color="#0d6efd" />
            </div>
          </Tooltip>
        )}
      </>
    );
  };

  //optimize
  const [parcelOpData, setParcelOpData] = useState({});
  const parcelMemoData = useMemo(() => parcelOpData, [parcelOpData]);
  const orderMemoData = useMemo(
    () => parcelController?.orderData,
    [parcelController?.orderData]
  );

  if (delayDom) return null

  return (
    <>
      {/* //history  */}
      <AppModal
        width={"85%"}
        onClose={(value) => {
          setShowAuditLogModal(value);
        }}
        showModal={showAuditLogModal}
        element={
          <div className="p-3">
            <AuditLogs title="Parcel" data={auditLogData} />
          </div>
        }
      />

      {showSearchErr && <h6 className="text-danger">{showSearchErr}</h6>}
      {getParcelData?.error ? (
        <div className=" mb-2">
          <Alert message={getParcelData?.error} type="error" showIcon />
        </div>
      ) : (
        <>
          {" "}
          {parcelController?.toolbar && (
            <div className="row flex-wrap bg-white p-2 align-items-center">
              <div
                className={`col-12 col-md-2 d-flex gap-2 align-items-center`}
              >
                {showYearFlag ? (
                  <>
                    <select
                      onChange={(e) => {
                        //adding year default
                        setToolsData({ ...toolsData, year: e.target.value });
                        //search year data
                        parcelInfo({
                          data: {
                            parcel_id: getParcelData.id,
                            year: e.target.value,
                          },
                          token,
                        });
                        //gtting parcel status info
                        getParcelStatusFun();
                      }}
                      value={toolsData.year}
                      className="border rounded-3 border-secondary w-75 p-1 border-2"
                    >
                      {getParcelData?.year_list?.map((ele) => {
                        return (
                          <option value={ele} key={ele}>
                            {ele}
                          </option>
                        );
                      })}
                    </select>

                    {getParcelData?.year_list.length < 5 && (
                      <Tooltip
                        overlayInnerStyle={{
                          fontSize: 10,
                        }}
                        placement="right"
                        title={"Add Prior CAD Year"}
                      >
                        <div
                          onClick={() => {
                            setShowYearFlag(false);
                            setShowSearchErr(false);
                            setParcelController({
                              ...parcelController,
                              mode: "addyear",
                            });

                            let currentYear = new Date().getFullYear();
                            const options = [];
                            for (
                              let i = currentYear;
                              i > currentYear - 5;
                              i--
                            ) {
                              if (
                                !getParcelData.year_list.includes(Number(i))
                              ) {
                                options.push(i);
                              }
                            }
                            setGetParcelData({
                              ...getParcelData,
                              year_list: options?.sort((a, b) => {
                                return b - a;
                              }),
                            });
                            setToolsData({ ...toolsData, year: options[0] });
                          }}
                          // className={`border add_icon  border-${!isCompleteOrder ? "primary" : "secondary"}`}
                          className={`border add_icon  border-primary`}
                        >
                          <FaPlus
                            // color={!isCompleteOrder ? "#3388FF" : "#808080"}
                            color={"#3388FF"}
                          />
                        </div>
                      </Tooltip>
                    )}
                  </>
                ) : (
                  <select
                    onChange={(e) => {

                      setToolsData({ ...toolsData, year: e.target.value });
                    }}
                    className="border rounded-3 border-secondary w-75 p-1 border-2 w-100 "
                  >
                    {getParcelData?.year_list?.map((ele) => {
                      return (
                        <option value={ele} key={ele}>
                          {ele}
                        </option>
                      );
                    })}
                  </select>
                )}
              </div>
              {(!parcelInfoResult.isLoading && !getParcelStatusInfoResult?.isLoading) && <>
                {(conflictsNotification || bakendAutomation) && (
                  <div className="col-12 col-lg-3 px-0">
                    <h6 className={`animate-pop fs-10 mb-0 d-inline-block ${bakendAutomation ? "text-orange" : ""}`}>
                      {bakendAutomation ? "Backend automation running!" : "Please Review the conflicts"}
                    </h6>
                  </div>
                )}

                {!bakendAutomation && !conflictsNotification && !conflictsResult.isLoading && !parcelInfoResult.isLoading && (
                  <div className="col-12 col-lg-2 px-0">
                    <h6 className="fs-10 mb-0 d-inline-block fw-bold text-success">
                      No conflicts found
                    </h6>
                  </div>
                )}

              </>}
              <div className="col-12 col-md-3 ms-auto d-flex align-items-center justify-content-end ">
                <div className="action-buttons d-flex align-items-center justify-content-end  gap-2">

                  {/* // StopAutomation */}
                  <StopAutomationComp callback={() => {

                    validateConflicts({
                      data: { order_id: location?.state?.id },
                      token,
                    });
                  }} />
                  {/* validation  */}
                  {newInterNalParcel?.Validation?.length > 0 &&
                    <OrderBtns title="Validation/Conflicts">
                      {newInterNalParcel?.Validation?.includes("Validation") && !conflictsProcess && !validateConflictsRunnig && <div
                        onClick={bakendAutomation ? undefined : () => {
                          //validatio start
                          //calling api for parcel api validate
                          setValidateConflictsRunnig(true)
                          validateConflicts({

                            data: { order_id: location?.state?.id, isManual: true },
                            token,
                          });
                        }}
                        className={`border add_icon  border-${bakendAutomation ? "secondary" : "primary"}`}
                      >
                        <VscRunErrors
                          color={bakendAutomation ? "#808080" : "#3388FF"}
                        />
                      </div>}

                      {newInterNalParcel?.Validation?.includes("Conflicts") && <>
                        {(conflictsProcess || validateConflictsRunnig) ? <Spin
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: 24,
                              }}
                              spin
                            />
                          }
                        />
                          : <div
                            onClick={async () => {
                              // if (!parcelController.parcelConflicts) {
                              //   conflicts({ data: { parcel_id: getParcelData.id }, token });
                              // }
                              conflicts({ data: { parcel_id: getParcelData.id }, token });
                              setParcelController({
                                ...parcelController,
                                parcelTwoView: true,
                                parcelConflicts: true
                              });
                            }}
                            className="add_icon border-danger test-svg"
                          >
                            <CgViewSplit color="red" />
                          </div>}
                      </>}
                    </OrderBtns>
                  }
                  {/* // History */}
                  {newInterNalParcel?.History?.length > 0 &&
                    <OrderBtns title="History">
                      {newInterNalParcel?.History?.includes("History") && <div
                        className="add_icon"
                        onClick={() => {
                          auditLogsListCall({
                            id: getParcelData.id,
                            token,
                          });
                        }}
                      >
                        <AiOutlineAudit color="#3388FF" cursor="pointer" />
                      </div>}
                    </OrderBtns>
                  }
                  <SendFeedBackComponent parcel_id={parceData?.id} />
                  {/* // deliver/email */}
                  {newInterNalParcel?.DeleiverEmail?.length > 0 &&
                    <OrderBtns title="Email">
                      {newInterNalParcel?.DeleiverEmail?.includes("Email") && <SendEmailComp />}
                    </OrderBtns>
                  }
                  {/* Download/View  */}
                  {newInterNalParcel?.DownloadView?.length > 0 &&
                    <OrderBtns title="Download">
                      {newInterNalParcel?.DownloadView?.includes("Download") && <div
                        onClick={() => {
                          //this function as props we are calling from orderEditor.js
                          certficateDownload(true);
                        }}
                        className="add_icon "
                      >
                        {isDownloadingCertificate ? <Spin
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: 24,
                              }}
                              spin
                            />
                          }
                        /> : <FiDownload color={
                          !bakendAutomation
                            ? "#3388FF"
                            : "#808080"
                        } />}
                      </div>}
                    </OrderBtns>
                  }



                  {/* DeleteEdit  */}
                  {newInterNalParcel?.DeleteEdit?.length > 0 &&
                    <OrderBtns title="Delete">
                      {newInterNalParcel?.DeleteEdit?.includes("Delete") && <Popconfirm
                        getPopupContainer={(trigger) => trigger.parentElement}
                        title="Are you sure you want to delete？"
                        onConfirm={deleteOrderHandler}
                        placement="bottom"
                        icon={
                          <QuestionCircleOutlined
                            style={{
                              color: "red",
                            }}
                          />
                        }
                        okText="Yes"
                        cancelText="No"
                      >
                        <div
                          className={`border add_icon  border-primary`}
                        >
                          <MdDeleteOutline
                            color={"#3388FF"}
                          />
                        </div>
                      </Popconfirm>}
                    </OrderBtns>
                  }
                  {/* //add/clone  */}
                  {newInterNalParcel?.AddClone?.length > 0 &&
                    <OrderBtns title={`Add${parcelList?.length > 5 ? "/View Parcel's" : ""}`}>
                      {newInterNalParcel?.AddClone?.includes("Add") && <div
                        onClick={true ? () => {
                          setKey("new_parcel");
                          setNewParcelTabFlag(true);
                        } : undefined}
                        className={`border add_icon  border-${true ? "primary" : "secondary"}`}
                      // className={`border add_icon  border-primary`}
                      >
                        <FaPlus
                          color={true ? "#3388FF" : "#808080"}
                        // color={"#3388FF"}
                        />
                      </div>}
                      {newInterNalParcel?.AddClone?.includes("View") && parcelList?.length > 5 && <div className="add_icon">
                        <AiOutlineEye
                          onClick={() => setOpen(true)}
                          color="#0d6efd"
                        />
                      </div>}
                    </OrderBtns>
                  }
                </div>
              </div>
            </div>
          )}
          {/* //parcel info  */}
          <div
            className="row align-items-stretch manual-entry"
            id="parcelembed"
          >
            {parcelController?.parcelTwoView && (
              <div className="col-12 col-lg-6">
                <Conflicts
                  viewPdfDocument={parcelViwDoc}
                  onchek={(value) => {
                    reviewConflicts({
                      data: { user_id: user?.user_id, order_id: location?.state?.id, id: value }, token,
                    });
                  }}
                  onclose={(value, type) => {
                    if (value) {

                      dispatch(parcelViewDocHandler(null))
                    }
                    setParcelController({
                      ...parcelController,
                      ...(value ? { parcelTwoView: parcelController.parcelConflicts ? true : false, } : { parcelConflicts: parcelViwDoc && value ? true : false, parcelTwoView: parcelViwDoc }),

                    });
                  }}
                  // onclose={() => {
                  //   setParcelController({
                  //     ...parcelController,
                  //     parcelPdfDoc: false,
                  //     parcelTwoView: false
                  //   });
                  // }}
                  isconflicts={parcelController.parcelConflicts}
                  conflicts={parcelConflicts}
                  orderContorller={{
                    pdfDoc: parcelController.parcelPdfDoc,
                  }}
                />
              </div>
            )}
            <div
              className={`col-12  col-lg-${parcelController?.parcelTwoView
                ? "6 mt-2 parcel-two-view"
                : null
                }`}
            >


              <ParcelInfo
                ///controlling contentex api states
                setKey={setKey}
                info={info}
                validateConflicts={validateConflicts}
                toolsData={toolsData}
                mode={parcelController.mode}
                parcelInfoResult={parcelInfoResult}
                refecthParcelStatus={getParcelStatusFun}
                data={getParcelData}
                refresh={refresh}
                EditHandlerComp={EditHandlerComp}
                setShowYearFlag={setShowYearFlag}
              />
              {/* //Tax Collector info  */}

              {parcelController.mode !== "addyear" &&
                orderDATA?.product_type_id !== 4 &&
                orderDATA?.product_type_id !== 9 &&
                orderDATA?.product_type_id !== 5 &&
                orderDATA?.product_type_id && (parceData?.id === parcelController?.parcelId) && (

                  <MyTaxCollector
                    info={info}
                    toolsData={toolsData}
                    data={getParcelData}
                    setKey={setKey}
                    refresh={refresh}
                    parcelList={parcelList}
                    parcelAutomationStatus={processbar}
                    setProcessbar={setProcessbar}
                    //api call automation start
                    automationStart={automationStart}
                    //api call conflicts refecth
                    validateConflicts={validateConflicts}
                    //automation start result for fetching details of tax
                    juriupdateAutomationResult={juriupdateAutomationResult}
                  />
                )}
              {/* //Supporting Documents info in full page  */}
              {(
                parcelController.mode !== "addyear")
                && (
                  <div className="mt-2">
                    {parcelEditInOrder.map((item, index) => {
                      if (item.supporting_docs) {
                        return (
                          <SupportDocs
                            key={index}
                            parcelDocumentCountApi={parcelDocumentCountApi}
                            parcelDocumentCountResult={parcelDocumentCountResult}
                            addAutomationDocumentsResult={addAutomationDocumentsResult}
                            parceldata={getParcelData}
                          />
                        );
                      }
                    })}
                  </div>
                )}

              {/* //Hoa Enformation  */}
              {parcelController.mode !== "addyear" &&
                (orderDATA?.product_type_id === 5 ||
                  orderDATA?.product_type_id === 4 ||
                  orderDATA?.product_type_id === 9 ||
                  orderDATA?.product_type_id === 10 ||
                  orderDATA?.product_type_id === 2) && (parceData?.id === parcelController?.parcelId) && (

                  <MyHoa
                    getParcelHoaStatus={getParcelHoaStatus}
                    getParcelHoaStatusResult={getParcelHoaStatusResult}
                    parcelHoaList={parcelHoaList}
                    parcelHoaListResult={parcelHoaListResult}
                    validateConflicts={HoaValidateConflicts}
                    HoaValidateConflictsResult={HoaValidateConflictsResult}
                    autoStatusUpdateResult={HoaAutoStatusUpdateResult}
                    parcelMemoData={parcelMemoData}
                    orderMemoData={orderMemoData}
                  />
                )}
            </div>
          </div>
        </>
      )}
    </>
  );
}
