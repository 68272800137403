import React from "react";
import { useCallback } from "react";
import update from "immutability-helper";
import { Card } from "../MultifileUpload/Comps/Card";
import AppCheckbox from "../AppCheckbox/AppCheckbox";
import { useState } from "react";
import { useEffect } from "react";
import { MdDeleteOutline } from "react-icons/md";
import { AiOutlineFolderView } from "react-icons/ai";
import "./merge.css";
import { Empty, Popconfirm } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import base64ToBlob from "base64toblob";
export default function MergeDocs({
  list,
  setList,
  onChange = false,
  onview = false,
  edit = true,
  showCheckBox = true,
  ondelete = false,
}) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [activeRow, setActiveRow] = useState(false);
  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setList((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })
    );
  }, []);
  const renderCard = useCallback((card, index) => {
    return (
      <Card
        key={card.id}
        index={index}
        id={card.id || card.tempId}
        name={card.name}
        moveCard={moveCard}
      />
    );
  }, []);

  const deleteHandler = (deleteName, id) => {
    // const afterDeleted = files.filter(({ name }) => {
    //   return name !== deleteName;
    // });
    // setFiles(afterDeleted);
    // onChange && onChange(afterDeleted);
    // setErrors(false);
    // setFileLimit(false);
    // //delete file
    // if (id) {
    //   deleteFile && deleteFile(id);
    // }
    // const updated = afterDeleted.filter(({ id }) =s> {
    //   return !id;
    // });
    // //while update
    // whileupdate && whileupdate(updated);
    // // if (files.length === 1) {
    // //   setErrors([]);
    // //   setFileLimit(false);
    // // }
    setActiveRow(false);
  };

  useEffect(() => {
    setSelectedOptions([]);
  }, [list]);

  return (
    <div className="merge">
      {list?.length > 0 && (
        <div className="fw-bold">
          <AppCheckbox
            label="Select All PDF's"
            disabled={!showCheckBox}
            checked={list?.length === selectedOptions.length}
            handleChange={(_, value) => {
              if (value) {
                setSelectedOptions(list);
              } else {
                setSelectedOptions([]);
              }
            }}
          />
        </div>
      )}
      {list.map((card, i) => {
        return (
          <div
          
            key={card.name}
            className={`${activeRow === card.id ? "bg-primary text-white" : "bg-white"
              } d-flex align-items-center border px-2 my-1 w-50`}
          >
            <div>
              <AppCheckbox
                disabled={!showCheckBox}
                checked={selectedOptions.find(({ id }) => id === card.id)}
                handleChange={(_, value) => {
                  if (!selectedOptions.includes(card.name) && value) {
                    setSelectedOptions([...selectedOptions, card]);
                  } else {
                    setSelectedOptions(
                      selectedOptions.filter((ele) => {
                        return ele.id !== card.id;
                      })
                    );
                  }
                }}
              />
            </div>
            <div> {renderCard(card, i)}</div>
            <div
              className="d-flex ms-auto gap-1 align-items-centre justify-content-end"
              style={{ width: 65 }}
            >
              {card?.id && (
                <AiOutlineFolderView
                  cursor={"pointer"}
                  onClick={() => {
                    setActiveRow(card?.id);
                    // triggerBase64Download(atob(base64), name);
                    onview && onview(card?.base64, card?.type, card?.name);
                  }}
                  size={20}
                  color={activeRow === card?.name ? "#fff" : "green"}
                  className="ms-1"
                />
              )}

              <div className="delete">
                {edit && (
                  <Popconfirm
                    getPopupContainer={(trigger) => trigger.parentElement}
                    title="Are you sure you want to delete？"
                    onConfirm={() => {
                      ondelete(card.id);
                    }}
                    placement="bottomRight"
                    icon={
                      <QuestionCircleOutlined
                        style={{
                          color: "red",
                        }}
                      />
                    }
                    okText="Yes"
                    cancelText="No"
                  >
                    <MdDeleteOutline cursor={"pointer"} size={18} color="red" />
                  </Popconfirm>
                )}
              </div>
            </div>
          </div>
        );
      })}
      {selectedOptions.length > 1 && (
        <button
          onClick={() => {
            onChange &&
              onChange(
                selectedOptions.map(({ base64, type }) =>
                  base64ToBlob(base64.split(",")[1], type)
                )
              );
          }}
          className="btn btn-success btn-sm my-2 text-white "
          style={{ fontSize: 10 }}
        >
          Merge Pdf's With Cert
        </button>
      )}
    </div>
  );
}
