import FormInput from "../../../Components/AppInput/AppInput";
import MyButton from "../../../Components/AppButton/AppButton";
import AppSwitch from "../../../Components/AppSwitch/AppSwitch";
import { useSelector } from "react-redux/es/exports";
import AppSpinner from "../../../Components/AppSpinner/AppSpinner";
import { Col, Row } from "react-bootstrap";
import { Alert, notification } from "antd";
import { useState, useEffect } from "react";
import {
  useAddAnnotationMutation,
  useEditAnnotationQuery,
  useUpdateAnnotationMutation,
} from "../../../Redux/Services/Annotation";
import AppSelect from "../../../Components/AppSelect/AppSelect";
import AppTextEditor from "../../../Components/AppTextEditor/AppTextEditor";

export const AnnotationModal = ({
  editId,
  initialValues,
  // setGetError,
  setShowHide,
  afterClose,
}) => {
  //   values assign
  const [values, setValues] = useState(initialValues);
  const [comments, setComments] = useState("");
  const [getError, setGetError] = useState(false);
  const [annotationTypeListData, setannotationTypeListData] = useState([
    { id: "", name: "" },
  ]);

  //calling redux stor for annotationtype list
  const { annotationtypeList } = useSelector(
    (state) => state.annotationtypeList
  );

  //calling redux store
  const { token } = useSelector((state) => state.auth);

  //calling rtk query hook for adding annotation
  const addNewAnnotation = useAddAnnotationMutation();
  const [addAnnotation] = addNewAnnotation;

  //calling rtk query hook for geting edit annotation
  const editAnnotationQuery = useEditAnnotationQuery(
    { id: editId, token: token },
    { skip: !editId }
  );

  //calling rtk query hook for update annotation
  const updateAnnotationData = useUpdateAnnotationMutation();
  const [updateAnnotation] = updateAnnotationData;

  //clear error function
  function clearStatus() {
    setGetError(null);
  }
  //function for modal close
  // modal will be close
  function closeModal() {
    clearStatus();
    setShowHide(false);
  }

  //create function for annotation fields
  // function matchFieldFlag(value) {
  //   if (fieldFlag.indexOf(value) === -1) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }

  //useEffect for setting default values
  useEffect(() => {
    setannotationTypeListData(annotationtypeList);

    setValues({
      ...initialValues,
      comment_type_id: annotationtypeList[0]?.id,
    });
    setComments("");
  }, [afterClose]);

  //useEffect for edit TaxCollector
  useEffect(() => {
    if (editAnnotationQuery.isSuccess) {
      const { comments, ...othersData } = editAnnotationQuery.data?.data[0];

      var newArr = {
        ...othersData,
      };

      setValues(newArr);
      setComments(comments);
    }
    if (editAnnotationQuery.isError) {
      setGetError(
        editAnnotationQuery.error?.data?.message ||
          editAnnotationQuery.error.data ||
          editAnnotationQuery.error.error ||
          editAnnotationQuery.error.data?.msg.sqlMessage
      );
    }
  }, [editAnnotationQuery.isFetching]);

  useEffect(() => {
    if (updateAnnotationData[1].isSuccess) {
      closeModal();
      ///after update details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: updateAnnotationData[1].data?.message,
      });
    }
    if (updateAnnotationData[1].error) {
      //here created for in loop for catch server errors
      var errorData = [];
      for (const prop in updateAnnotationData[1].error.data?.error) {
        errorData.push(updateAnnotationData[1].error.data?.error[prop]);
      }

      setGetError(
        errorData[0] ||
          updateAnnotationData[1].error?.data?.message ||
          updateAnnotationData[1].error.error ||
          updateAnnotationData[1].error.data?.msg.sqlMessage
      );
    }
  }, [updateAnnotationData[1].isLoading]);

  //useEffet for  add annotation
  useEffect(() => {
    if (addNewAnnotation[1].isSuccess) {
      closeModal();
      ///after submit details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: addNewAnnotation[1].data?.message,
      });
    }
    if (addNewAnnotation[1].error) {
      //here created for in loop for catch server errors
      var errorData = [];
      for (const prop in addNewAnnotation[1].error.data?.error) {
        errorData.push(addNewAnnotation[1].error.data?.error[prop]);
      }

      setGetError(
        errorData[0] ||
          addNewAnnotation[1].error?.data?.message ||
          addNewAnnotation[1].error.error ||
          addNewAnnotation[1].error.data?.msg.sqlMessage
      );
    }
  }, [addNewAnnotation[1].isLoading]);

  const inputs = [
    {
      id: 1,
      name: "comment_type_id",
      type: "select",
      placeholder: "Select Annotation Type",
      errorMessage: "Please select annotation type!",
      label: "Comment Type",
      value: values?.comment_type_id || "",
      options: annotationTypeListData,
      required: true,
      column: "6",
    },
    {
      id: 2,
      name: "title",
      type: "text",
      placeholder: "Enter Title",
      errorMessage: "Please Enter Title!",
      label: "Title",
      required: true,
      column: "6",
    },
    {
      id: 3,
      name: "comments",
      type: "editor",
      placeholder: "Enter Comments",
      errorMessage: "Please enter comments!",
      Value: values.comments || "",
      column: "12",
    },

    {
      id: 5,
      name: "read_only",
      checked: values.read_only || "",
      type: "switch",
      column: "3",
      label: "Read Only",
      required: true,
      // margin: "5",
    },
  ];

  //select handler
  const selectHandler = (property, value) => {
    clearStatus();
    setValues({ ...values, [property]: value });
  };

  //AppSwitch box
  const appSwitchHanler = (property, value) => {
    setValues({ ...values, [property]: value });
    clearStatus();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // if edit id not exists then add annotaion
    if (comments === "<pre><br></pre>" || !comments) {
      setGetError("Comment is required");
    } else {
      if (!editId) {
        addAnnotation({
          data: {
            ...values,
            comments,
          },
          token: token,
        });
      }
      if (editId) {
        updateAnnotation({
          data: {
            ...values,
            comments,
          },
          id: editId,
          token: token,
        });
      }
    }
  };

  //useEfect for Refecth edit details
  useEffect(() => {
    if (editId) {
      editAnnotationQuery.refetch();
    }
    //all alert messages cleared!
    clearStatus();
  }, [afterClose, editId]);

  const onChange = async (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });

    //claser status values
    clearStatus();
  };

  const handleReset = (e) => {
    e.preventDefault();
    setValues(initialValues);
    if (editId) {
      editAnnotationQuery.refetch();
    }
    clearStatus();
    setComments("");
  };
  return (
    <div className="position-relative">
      <div className=" border-bottom border-2">
        <h5>{editId ? "Edit Comment" : "Add Comment"}</h5>
      </div>
      <form onSubmit={handleSubmit} className="pb-3">
        {(addNewAnnotation[1].isFetching ||
          updateAnnotationData[1].isLoading ||
          editAnnotationQuery.isFetching) && <AppSpinner />}
        <Row
          className={`${editAnnotationQuery.isFetching ? "invisible" : ""} `}
        ></Row>
        {getError && (
          <>
            <div className="my-2">
              <Alert showIcon message={getError} type="error" />
            </div>
          </>
        )}
        <div className="col-12 col-lg-12">
          <div className="row">
            {inputs.map((input) => (
              <>
                {input.type === "text" ? (
                  <FormInput
                    key={input.id}
                    defaultSet={afterClose}
                    {...input}
                    value={values[input.name]}
                    onChange={onChange}
                  />
                ) : (
                  ""
                )}
                {/* //appSelcet for  annotation type name  */}
                {input.type === "select" && input.name === "comment_type_id" && (
                  <AppSelect
                    key={input.id}
                    {...input}
                    onChange={selectHandler}
                    options={input.options.map((ele) => {
                      return (
                        <option key={ele.id} value={ele.id}>
                          {ele.name}
                        </option>
                      );
                    })}
                  />
                )}

                {input.type === "switch" && (
                  <AppSwitch
                    key={input.id}
                    value={values[input.name]}
                    onChange={appSwitchHanler}
                    {...input}
                  />
                )}
                {input.type === "editor" && input.name === "comments" && (
                  <div className="my-2">
                    <AppTextEditor
                      value={comments}
                      onChange={(content) => {
                        {
                          setComments(content);
                          setGetError(false);
                        }
                      }}
                    />
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
        <Row
          className={`${editAnnotationQuery.isFetching ? "invisible" : ""} `}
        ></Row>
        <Col className="my-3">
          <div className="d-flex justify-content-center">
            <div className="me-md-2 ">
              <MyButton
                onClick={handleReset}
                title={"CLEAR"}
                variant={"secondary-md"}
              />
            </div>
            {!editId ? (
              <MyButton title={"SAVE"} variant={"primary-md"} />
            ) : (
              <MyButton title={"UPDATE"} variant={"primary-md"} />
            )}
          </div>
        </Col>
      </form>
    </div>
  );
};
