import { useCallback, useContext, useEffect, useState } from "react";
import {
  FaFileExcel,
  FaSearch,
  FaSortAmountDown,
  FaSortAmountUp,
} from "react-icons/fa";
import { useSelector } from "react-redux";
import "../Cads.css";
import {
  QuestionCircleOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { FaPlus } from "react-icons/fa";
import { Alert, Popconfirm } from "antd";
import { Input, notification, Button } from "antd";
import {
  useAddCadMutation,
  useCadListMutation,
  useBatchListQuery,
  useDeleteSingleCadListMutation,
  useCadFilterListMutation,
  useFullCadListMutation,
  useCadSearchListMutation,
} from "../../../../Redux/Services/Cad";
import { Row } from "react-bootstrap";
import ListModal from "./ListModal";
import { useGetCountyListMutation } from "../../../../Redux/Services/county";
import AppModal from "../../../../Components/AppModal/AppModal";
import AppExcelUpload from "../../../../Components/AppExcelUpload/AppExcelUpload";
import { scrollComp } from "../../../../Controllers/Functions";
import AppFilterSelect from "../../../../Components/AppFilterSelect/AppFilterSelect";
import SearchColumns from "../../../../Components/SearchColumns";
import { FiFilter, FiRotateCw } from "react-icons/fi";

import upload_order_file from "../../../../Images/Sample Parcel CAD Template.xlsx";
import { GlobalContext } from "../../../../App";
import { AiOutlineClose } from "react-icons/ai";
import { useMemo } from "react";
import MyTable from "../../../../Components/AppTable/MyTable";
import AppSpinner from "../../../../Components/AppSpinner/AppSpinner";
export const List = ({ refresh, optionsShow = true, childTab, updateTab }) => {
  //context api
  const { state } = useContext(GlobalContext);
  const [globalVal, setGlobalVal] = state;
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [showHide, setShowHide] = useState(null);
  const [allrecord, setAllrecords] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [pagesize, setPageSize] = useState(200);
  const [currentPage, setCurrentPage] = useState(1);
  //redux
  //set  ascend or descend sorter state
  const [editId, setEditId] = useState(null);
  //redux data store
  const { token } = useSelector((state) => state.auth);
  const { cadModule } = useSelector((state) => state.flagRoles);
  const [upload] = useState(false);

  const [getError, setGetError] = useState(false);
  const [initialState, setInitialState] = useState(false);
  const [open, setOpen] = useState(true);
  const [countyOptions, setCountyOptions] = useState([]);
  const [batchOptions, setBatchOptions] = useState([]);
  const [stateCode, setStateCode] = useState("TX");
  const [countyName, setCountyName] = useState("");

  //state list
  const { stateList } = useSelector((state) => state.stateList);
  //county list
  const { countyList } = useSelector((state) => state.countyList);

  //tax-cad list
  // const [taxcadValues, setTaxCadValues] = useState({
  // 	state: "",
  // 	county: "",
  // 	tax_year: "",
  // 	tax_collector: "",
  // 	due_date: "",
  // 	cad_property_id: "",
  // 	cad_geo_id: "",
  // 	tax_assessor_prop_id: "",
  // 	cross_tax_id: "",
  // 	manual_bill_indicator: "",
  // 	tax_suite_indicator: "",
  // 	payment_plan: "",
  // 	discount_plan: "",
  // 	bill_type_one: "",
  // 	jurisdiction_name_one: "",
  // 	jurisdiction_code_one: "",
  // 	current_bill_base_tax: "",
  // 	current_base_tax_due: "",
  // 	pi_collection_fees: "",
  // 	current_pay_off_month_one: "",
  // 	current_pay_off_month_two: "",
  // 	bill_type_two: "",
  // 	prior_bill_years: "",
  // 	jurisdiction_name_two: "",
  // 	jurisdiction_code_two: "",
  // 	combined_bases_tax_due: "",
  // 	prior_year_pay_off_month_one: "",
  // 	prior_year_pay_off_month_two: "",

  // });

  const [cadvalues] = useState({
    name: "",
    state: "",
    county: "",
    tax_year: "",
    parcel_type: "",
    cad_property_id: "",
    cad_geo_id: "",
    tax_assessor_id: "",
    cross_tax_id: "",
    subdivision: "",
    full_address: "",
    situs_number: "",
    situs_pre_dir: "",
    situs_name: "",
    situs_suffix: "",
    situs_post_dir: "",
    situs_city: "",
    situs_state: "",
    situs_zip: "",
    owner_name: "",
    owner_dba_name: "",
    exemptions: "",
    address_one: "",
    address_two: "",
    address_three: "",
    ownership: "",
    mail_address_two: "",
    mail_address_city: "",
    mail_address_state: "",
    mail_address_zip: "",
    legal: "",
    acreage: "",
    deed_reference: "",
    taxing_unit_list: "",
    land: "",
    improvement: "",
    appraised: "",
    assessed: "",
    cap_loss: "",
    agricultural: "",
    last_ag_value: "",
    ag_cleared: false,
    parcel_comments: "",
    tax_suit_indicator: "",
    tax_suite_fee_comments: "",
    feature_review: false,
    linked_parcels: false,
    parcel_is_same_record: false,
    is_proposed: false,
    run_date: "",
    add_linked_parcels: [],
    linked_parcel_information_list: [],
    delete_linked_parcels: [],
    edit_linked_parcels: [],
    // batch_id: "",
  });

  // edit taxcad list module

  const editHandler = (e) => {
    setShowHide(true);
    setEditId(e.id);
  };

  //calling rtk query hook for geting County list
  const countyList2 = useGetCountyListMutation();

  //calling rtk query hook for geting search list
  const [searchCad, searchCadResult] = useCadSearchListMutation();

  const [getCountyList] = countyList2;
  // useEffect for countylist
  useEffect(() => {
    if (countyList2[1].isSuccess) {
      setCountyOptions(countyList2[1].data?.data);
    } else {
      setCountyOptions([]);
    }
  }, [countyList2[1].isLoading]);

  //calling rtk query for batchcadlist query
  const batchList = useBatchListQuery(token);
  useEffect(() => {
    if (batchList.isSuccess) {
      setBatchOptions(batchList.data?.data);
    }
    //global spinner
    // setGlobalVal({ ...globalVal, loader: batchList.isFetching });
  }, [batchList.isFetching, batchList.data?.data, batchList.isSuccess]);

  const [filetrVal, setFiletrVal] = useState({
    state: "Texas",
    county: "",
    batch: "",
  });

  function refecthList() {
    // taxcadListFecth({ data: { page_no: 1, count: pagesize }, token });
    // getTaxcadListFilter({data:{id:filetrVal.batch,page_no: 1, count: pagesize},token})
    // taxcadFilterList({data:{state:filetrVal.state,page_no: 1, count: pagesize},token})
  }
  //filter options
  console.log(stateList);
  const options = [
    {
      name: "state",
      value: stateList?.filter(({ state_name }) => {
        return state_name === filetrVal.state;
      })[0]?.id,
      data: stateList?.map(({ state_name, id, short_code }) => {
        return { name: state_name, id, state_code: short_code };
      }),
      disabled: filetrVal.batch,
    },

    {
      name: "county",
      value: countyOptions?.filter(({ county_name }) => {
        return county_name === filetrVal.county;
      })[0]?.id,
      data: countyOptions?.map(({ county_name, id }) => {
        return { name: county_name, id };
      }),
      disabled: filetrVal.batch,
    },
    {
      name: "batch",
      value: filetrVal.batch,
      data: batchOptions?.map(({ name, id }) => {
        return { name: name, id };
      }),
      disabled: filetrVal.state || filetrVal.county,
    },
  ];

  //calling rtk query for tax-cad-list
  const [taxcadListFecth, taxcadList] = useFullCadListMutation();
  useEffect(() => {
    if (taxcadList.isSuccess) {
      if (taxcadList.data?.data?.cad_list?.length) {
        setPageCount(taxcadList.data?.data?.total_record_count);
      } else {
        setPageCount(0);
      }
      // setPageCount(taxcadList.data?.data?.page_count);
      // scrollComp("table-data");
      const newData = taxcadList.data?.data?.cad_list.map(
        ({
          state,
          county,
          legal,
          owner_name,
          subdivision,
          tax_year,
          cad_property_id,
          cad_geo_id,
          full_address,
          ...ele
        }) => {
          return {
            ...ele,
            state: state || " ",
            county: county || " ",
            legal: legal || " ",
            tax_year: tax_year || " ",
            full_address: full_address || " ",
            cad_property_id: cad_property_id || " ",
            cad_geo_id: cad_geo_id || " ",
            owner_name: owner_name || " ",
            subdivision: subdivision || " ",
          };
        }
      );
      setData(newData);
    }
  }, [taxcadList.isLoading]);

  useEffect(() => {
    if (searchCadResult.isSuccess) {
      // setPageCount(searchCadResult.data?.data?.page_count);
      // scrollComp("table-data");

      if (searchCadResult.data?.data?.cad_list.length) {
        setPageCount(searchCadResult.data?.data?.total_record_count);
      } else {
        setPageCount(0);
      }

      const newData = searchCadResult.data?.data?.cad_list.map(
        ({
          state,
          county,
          legal,
          owner_name,
          subdivision,
          tax_year,
          cad_property_id,
          cad_geo_id,
          full_address,
          ...ele
        }) => {
          return {
            ...ele,
            state: state || " ",
            county: county || " ",
            legal: legal || " ",
            tax_year: tax_year || " ",
            full_address: full_address || " ",
            cad_property_id: cad_property_id || " ",
            cad_geo_id: cad_geo_id || " ",
            owner_name: owner_name || " ",
            subdivision: subdivision || " ",
          };
        }
      );
      setData(newData);
    }
  }, [searchCadResult.isLoading]);

  useEffect(() => {
    setCountyOptions(countyList);
  }, [countyList]);

  //calling rtk query hook for upload tax-cad data
  const [addTaxCadFile, addTaxCadFileResult] = useAddCadMutation();

  //error
  useEffect(() => {
    if (getError) {
      scrollComp("cadError");
    }
  }, [getError]);
  //useEffect for upload-tax-cad file
  useEffect(() => {
    if (addTaxCadFileResult.isSuccess) {
      ///after submit details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: addTaxCadFileResult.data?.message,
      });
      setGetError(false);
    }
    if (addTaxCadFileResult.isError) {
      setGetError(
        addTaxCadFileResult.error?.data?.error ||
          addTaxCadFileResult.error?.data?.msg ||
          addTaxCadFileResult.error.data?.message?.sqlMessage ||
          (addTaxCadFileResult.error.error && "server unreachable!")
      );
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: addTaxCadFileResult.isLoading });
  }, [addTaxCadFileResult.isLoading]);

  const DeleteSingleTaxcad = useDeleteSingleCadListMutation();
  const [GetDeleteSingleTaxCad] = DeleteSingleTaxcad;

  //useEffect for delete
  useEffect(() => {
    if (DeleteSingleTaxcad[1].isSuccess) {
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: DeleteSingleTaxcad[1].data?.message,
      });
    }
    if (DeleteSingleTaxcad[1].error) {
      /// erros details
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description:
          DeleteSingleTaxcad[1].error?.data?.message ||
          DeleteSingleTaxcad[1].error?.error ||
          DeleteSingleTaxcad[1].error.data?.msg?.sqlMessage,
      });
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: DeleteSingleTaxcad[1].isLoading });
  }, [DeleteSingleTaxcad[1].isLoading]);

  function closeModal(data) {
    setShowHide(data);
  }

  function afterClose() {
    setInitialState(!initialState);
  }

  //uploading tax cad file
  const handleUpload = (file) => {
    const new_taxcad = new FormData();
    new_taxcad.append("file", file);
    addTaxCadFile({
      data: new_taxcad,
      token: token,
    });
  };

  //create function for customer fields
  function matchColumn(value) {
    // if (authColumn.indexOf(value) === -1) {
    //   return false;
    // } else {
    //   return true;
    // }
  }
  //calling rtk query hook for getting tax-cad list based on batch-id
  const taxcadListFilter = useCadListMutation();
  const [getTaxcadListFilter] = taxcadListFilter;

  useEffect(() => {
    if (taxcadListFilter[1].isSuccess) {
      setCurrentPage(taxcadListFilter[1].data?.data?.page_no);
      if (taxcadListFilter[1].data?.data?.cad_list?.length) {
        setPageCount(taxcadListFilter[1].data?.data?.total_record_count);
      } else {
        setPageCount(0);
      }
      const newData = taxcadListFilter[1].data?.data?.cad_list?.map(
        ({
          state,
          county,
          legal,
          owner_name,
          subdivision,
          tax_year,
          cad_property_id,
          cad_geo_id,
          full_address,
          ...ele
        }) => {
          return {
            ...ele,
            state: state || " ",
            county: county || " ",
            legal: legal || " ",
            tax_year: tax_year || " ",
            full_address: full_address || " ",
            cad_property_id: cad_property_id || " ",
            cad_geo_id: cad_geo_id || " ",
            owner_name: owner_name || " ",
            subdivision: subdivision || " ",
          };
        }
      );
      setData(newData);
    } else {
      setData([]);
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: taxcadListFilter[1].isLoading });
  }, [taxcadListFilter[1].isLoading]);

  //calling rtk query for taxcad-list filter based on state & count
  const [taxcadFilterList, TaxCadFilterListResult] = useCadFilterListMutation();
  useEffect(() => {
    if (TaxCadFilterListResult.isSuccess) {
      setCurrentPage(TaxCadFilterListResult.data?.data.page_no);
      if (TaxCadFilterListResult.data?.data?.cad_filter_result.length) {
        setPageCount(TaxCadFilterListResult.data?.data?.total_record_count);
      } else {
        setPageCount(0);
      }
      const newData = TaxCadFilterListResult.data?.data?.cad_filter_result?.map(
        ({
          state,
          county,
          legal,
          owner_name,
          subdivision,
          tax_year,
          cad_property_id,
          cad_geo_id,
          full_address,
          ...ele
        }) => {
          return {
            ...ele,
            state: state || " ",
            county: county || " ",
            legal: legal || " ",
            tax_year: tax_year || " ",
            full_address: full_address || " ",
            cad_property_id: cad_property_id || " ",
            cad_geo_id: cad_geo_id || " ",
            owner_name: owner_name || " ",
            subdivision: subdivision || " ",
          };
        }
      );
      setData(newData);
    } else {
      setData([]);
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: TaxCadFilterListResult.isLoading });
  }, [TaxCadFilterListResult.isLoading]);

  const columns = [
    // 	{
    // 	title: (
    // 		<span>
    // 			State{" "}
    // 			{getsortfield === "state" && !getsortorder ? (
    // 				<FaSortAmountUp />
    // 			) : getsortorder === "ascend" && getsortfield === "state" ? (
    // 				<FaSortAmountUp className="text-info" />
    // 			) : getsortorder === "descend" && getsortfield === "state" ? (
    // 				<FaSortAmountDown className="text-info" />
    // 			) : (
    // 				<FaSortAmountUp />
    // 			)}
    // 		</span>
    // 	),
    // 	dataIndex: "state",
    // 	key: "state",
    // 	sorter: (a, b) => a.state.localeCompare(b.state),
    // 	editable: true,
    // 	width: 200,
    // 	...SearchColumns("state", " State Name"),
    // },
    // {
    // 	title: (
    // 		<span>
    // 			County{" "}
    // 			{getsortfield === "county" && !getsortorder ? (
    // 				<FaSortAmountUp />
    // 			) : getsortorder === "ascend" && getsortfield === "county" ? (
    // 				<FaSortAmountUp className="text-info" />
    // 			) : getsortorder === "descend" && getsortfield === "county" ? (
    // 				<FaSortAmountDown className="text-info" />
    // 			) : (
    // 				<FaSortAmountUp />
    // 			)}
    // 		</span>
    // 	),
    // 	dataIndex: "county",
    // 	key: "county",
    // 	sorter: (a, b) => a.county.localeCompare(b.county),
    // 	editable: true,
    // 	width: 200,
    // 	...SearchColumns("county", "County Name"),
    // },

    {
      // id: "2",
      title: ({ sortColumns }) => {
        const sortedColumn = sortColumns?.find(
          ({ column }) => column.key === "tax_year"
        );
        return (
          <div>
            Year{" "}
            {sortedColumn ? (
              sortedColumn.order === "ascend" ? (
                <span className="sort-svg">
                  <FaSortAmountUp color="#307BF6" />
                </span>
              ) : (
                <span className="sort-svg">
                  <FaSortAmountDown color="#307BF6" />
                </span>
              )
            ) : (
              <FaSortAmountUp />
            )}
          </div>
        );
      },
      // title: (
      //   <span>
      //     Year{" "}
      //     {getsortfield === "tax_year" && !getsortorder ? (
      //       <FaSortAmountUp />
      //     ) : getsortorder === "ascend" && getsortfield === "tax_year" ? (
      //       <FaSortAmountUp className="text-info" />
      //     ) : getsortorder === "descend" && getsortfield === "tax_year" ? (
      //       <FaSortAmountDown className="text-info" />
      //     ) : (
      //       <FaSortAmountUp />
      //     )}
      //   </span>
      // ),
      dataIndex: "tax_year",
      key: "tax_year",
      sorter: (a, b) => a.tax_year.localeCompare(b.tax_year),
      editable: true,
      ...SearchColumns("tax_year", "Tax Year", allrecord),
      width: 100,
      align: "center",
    },
    {
      // id: "3",

      title: ({ sortColumns }) => {
        const sortedColumn = sortColumns?.find(
          ({ column }) => column.key === "cad_property_id"
        );
        return (
          <div>
            Cad Property ID{" "}
            {sortedColumn ? (
              sortedColumn.order === "ascend" ? (
                <span className="sort-svg">
                  <FaSortAmountUp color="#307BF6" />
                </span>
              ) : (
                <span className="sort-svg">
                  <FaSortAmountDown color="#307BF6" />
                </span>
              )
            ) : (
              <FaSortAmountUp />
            )}
          </div>
        );
      },

      dataIndex: "cad_property_id",
      key: "cad_property_id",
      sorter: (a, b) => a.cad_property_id.localeCompare(b.cad_property_id),
      editable: true,
      width: 260,
      ...SearchColumns("cad_property_id", "Cad Property Id", allrecord),
      align: "center",
    },
    {
      // id: "4",

      title: ({ sortColumns }) => {
        const sortedColumn = sortColumns?.find(
          ({ column }) => column.key === "cad_geo_id"
        );
        return (
          <div>
            Geo ID{" "}
            {sortedColumn ? (
              sortedColumn.order === "ascend" ? (
                <span className="sort-svg">
                  <FaSortAmountUp color="#307BF6" />
                </span>
              ) : (
                <span className="sort-svg">
                  <FaSortAmountDown color="#307BF6" />
                </span>
              )
            ) : (
              <FaSortAmountUp />
            )}
          </div>
        );
      },
      dataIndex: "cad_geo_id",
      key: "cad_geo_id",
      sorter: (a, b) => a.cad_geo_id.localeCompare(b.cad_geo_id),
      editable: true,
      width: 260,
      ...SearchColumns("cad_geo_id", "Cad Geo Id", allrecord),
      align: "center",
    },
    {
      // id: "5",

      title: ({ sortColumns }) => {
        const sortedColumn = sortColumns?.find(
          ({ column }) => column.key === "full_address"
        );
        return (
          <div>
            Address{" "}
            {sortedColumn ? (
              sortedColumn.order === "ascend" ? (
                <span className="sort-svg">
                  <FaSortAmountUp color="#307BF6" />
                </span>
              ) : (
                <span className="sort-svg">
                  <FaSortAmountDown color="#307BF6" />
                </span>
              )
            ) : (
              <FaSortAmountUp />
            )}
          </div>
        );
      },
      dataIndex: "full_address",
      key: "full_address",
      sorter: (a, b) => a.full_address.localeCompare(b.full_address),
      editable: true,
      width: 260,
      ...SearchColumns("full_address", "Address", allrecord),
      align: "center",
    },

    {
      title: ({ sortColumns }) => {
        const sortedColumn = sortColumns?.find(
          ({ column }) => column.key === "owner_name"
        );
        return (
          <div>
            Owner{" "}
            {sortedColumn ? (
              sortedColumn.order === "ascend" ? (
                <span className="sort-svg">
                  <FaSortAmountUp color="#307BF6" />
                </span>
              ) : (
                <span className="sort-svg">
                  <FaSortAmountDown color="#307BF6" />
                </span>
              )
            ) : (
              <FaSortAmountUp />
            )}
          </div>
        );
      },
      dataIndex: "owner_name",
      key: "owner_name",
      sorter: (a, b) => a.owner_name.localeCompare(b.owner_name),
      editable: true,
      width: 260,
      ...SearchColumns("owner_name", "Owner Name", allrecord),
      align: "center",
    },
    {
      title: ({ sortColumns }) => {
        const sortedColumn = sortColumns?.find(
          ({ column }) => column.key === "legal"
        );
        return (
          <div>
            Legal{" "}
            {sortedColumn ? (
              sortedColumn.order === "ascend" ? (
                <span className="sort-svg">
                  <FaSortAmountUp color="#307BF6" />
                </span>
              ) : (
                <span className="sort-svg">
                  <FaSortAmountDown color="#307BF6" />
                </span>
              )
            ) : (
              <FaSortAmountUp />
            )}
          </div>
        );
      },
      dataIndex: "legal",
      key: "legal",
      sorter: (a, b) => a.legal.localeCompare(b.legal),
      editable: true,
      width: 260,
      ...SearchColumns("legal", "Legal", allrecord),
      align: "center",
    },
    {
      title: <span>Actions</span>,
      className: `${cadModule[1].action ? "" : "d-none"}`,
      width: matchColumn(" ") && 100,
      align: "center",
      dataIndex: "operation",
      key: "operation",
      render: (_, record) => {
        return (
          <>
            {<EditOutlined size={50} onClick={() => editHandler(record)} />}
            {"  "}
            <Popconfirm
              title="Are you sure you want to delete？"
              onConfirm={() => {
                handleDelete(record);
              }}
              getPopupContainer={(trigger) => trigger.parentElement}
              placement="topLeft"
              icon={
                <QuestionCircleOutlined
                  style={{
                    color: "red",
                    cursor: "pointer",
                  }}
                />
              }
              okText="Yes"
              cancelText="No"
            >
              <span className="gap-2">
                <DeleteOutlined
                  className="text-danger"
                  style={{ cursor: "pointer" }}
                />
              </span>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const cols = useMemo(() => columns, [allrecord]);
  const data1 = useMemo(() => data, [data]);

  //sample upload
  const downloadFile = () => {
    window.location.href = upload_order_file;
  };

  //ondrop
  const onDrop = (e) => {};

  //onchange of file-upload
  const onChange = (info) => {
    if (info.file !== "uploading") {
    }

    handleUpload(info.file);
  };

  function clearFilter() {
    setOpen(false);
    clearState();
    setCurrentPage(1);
    setSearch("");
    setAllrecords(false);
  }
  //function for clear state  & reset
  function clearState() {
    setCountyOptions([]);
    setCurrentPage(1);
    //reset
    if (filetrVal.state) {
      refecthList();
      setSearch("");
      setAllrecords(false);
    }
    // else if(filetrVal.batch){
    //   refecthList();
    // }

    setFiletrVal({
      ...filetrVal,
      state: "",
      county: "",
      //  batch: "",
    });
    setData([]);
  }

  //function for clear reset
  // function clearReset() {
  //   setCountyOptions([]);
  //   setCurrentPage(1);
  //   //reset
  //   if (filetrVal.state || filetrVal.batch) {
  //     refecthList();
  //     setSearch("");
  //     setAllrecords(false);
  //   }

  //   setFiletrVal({
  //     ...filetrVal,
  //     state: "",
  //     county: "",
  //     batch: "",
  //   });
  // }

  //function -reset countyplist
  function clearCounty() {
    //reset
    setCurrentPage(1);
    if (filetrVal.state) {
      setSearch("");
      setAllrecords(false);
      // refecthList();
    }
    setFiletrVal({
      ...filetrVal,
      county: "",
    });
    setData([]);
  }

  //function-reset batch
  function clearBatch() {
    //reset
    setSearch("");
    setAllrecords(false);

    if (filetrVal.batch) {
      refecthList();
    }
    setFiletrVal({
      ...filetrVal,
      batch: "",
    });
    setData([]);
  }
  //addTaxCad Handler function with dependency
  const AddTaxCadHandler = () => {
    setShowHide(true);
    setEditId(null);
  };

  //for radio & main tab
  useEffect(() => {
    // setAllrecords(false);
    // setSearch("");
    if (refresh === "cads" && !search) {
      // refecthList();
      // setCurrentPage(1);
      // setFiletrVal({ batch: "", county: "", state: "" });
    }
    // setCountyOptions([]);
    // setData([])
    setOpen(true);
  }, [refresh]);

  useEffect(() => {
    //request
    if (childTab === "cads" && !search) {
      // refecthList();
      setSearch("");
      setGetError(false);
    }
  }, [childTab]);
  // console.log(columnSearch)

  const onpageChnage = useCallback(
    (value, pageSi) => {
      setCurrentPage(value);
      setPageSize(pageSi);

      // taxcadListFecth({
      //   data: { page_no: value, count: pageSi },
      //   token,
      // });

      if (filetrVal.state && !filetrVal.county) {
        // taxcadFilterList({ data: { state: filetrVal.state, page_no: value, count: pageSi }, token })
        console.log("county should not be");
      } else if (filetrVal.state !== "" && !filetrVal.county === "") {
        taxcadFilterList({
          data: {
            county: filetrVal.county,
            state: filetrVal.state,
            page_no: value,
            count: pageSi,
          },
          token,
        });
      } else if (filetrVal.batch) {
        getTaxcadListFilter({
          data: { id: filetrVal.batch, page_no: value, count: pageSi },
          token,
        });
      }
    },
    [currentPage, pagesize, filetrVal]
  );

  const loader =
    DeleteSingleTaxcad.isLoading ||
    searchCadResult.isLoading ||
    // taxcadList.isLoading ||
    batchList.isLoading;

  const loadingTable = useMemo(() => loader, [loader]);
  const pageCountMemo = useMemo(() => pageCount, [pageCount]);
  const currentPageMemo = useMemo(() => currentPage, [currentPage]);

  function refecthList() {
    taxcadFilterList({
      data: {
        county: filetrVal.county,
        state: filetrVal.state,
        page_no: 1,
        count: pagesize,
        table_name: `${stateCode}_${filetrVal.county}`,
      },
      token,
    });
  }

  useEffect(() => {
    if (filetrVal.state) {
      getCountyList({
        state_id: { state_id: 51 },
        token: token,
      });
      if (filetrVal.state && filetrVal.county) {
        taxcadFilterList({
          data: {
            county: filetrVal.county,
            state: filetrVal.state,
            page_no: 1,
            count: pagesize,
            table_name: `${stateCode}_${filetrVal.county}`,
          },
          token,
        });
      }
    }
  }, []);
  //delete taxcad data
  const handleDelete = (record) => {
  

    GetDeleteSingleTaxCad({
      id: record?.id,
      token: token,
      data: { table_name: `${stateCode}_${record?.county}` },
    }).then(()=>{
      taxcadFilterList({
        data: {
          county: record?.county,
          state: filetrVal.state,
          page_no: 1,
          count: pagesize,
          table_name: `${stateCode}_${record?.county}`,
        },
        token,
      });
    })

  };
  console.log("filter county", filetrVal.county);
  return (
    <>
      {taxcadList.isLoading && <AppSpinner position="fixed" />}
      <AppModal
        confirmProp={true}
        showModal={showHide}
        onClose={closeModal}
        afterClose={afterClose}
        width={"90%"}
        height={400}
        element={
          <ListModal
            editId={editId}
            table_name={`${stateCode}_${filetrVal.county}`}
            refecthList={refecthList}
            initialValues={cadvalues}
            setShowHide={setShowHide}
            afterClose={initialState}
            setOpen={clearFilter}
          />
        }
      />
      <div className="mt-3" id="cadError">
        {getError && <Alert message={getError} type="error" showIcon />}
      </div>

      <div className="d-flex align-items-center  mb-1 gap-2 justify-content-end">
        {/* <AppSwitch label="All Record" /> */}

        {allrecord ? (
          <Button
            onClick={() => {
              setSearch("");
              setAllrecords(false);
              setCurrentPage(1);
              // taxcadListFecth({
              //   data: { page_no: 1, count: 200 },
              //   token,
              // });

              setData([]);
            }}
            type="primary"
            htmlType="button"
            size="small"
          >
            Cancel
          </Button>
        ) : (
          <Button
            disabled={!filetrVal.county}
            onClick={
              filetrVal.county
                ? undefined
                : () => {
                    setAllrecords(true);
                    setSearch("");
                    setFiletrVal({ state: "", county: "", batch: "" });
                    setCurrentPage(1);
                    taxcadListFecth({
                      data: {
                        page_no: "",
                        count: "",
                        table_name: `${stateCode}_${filetrVal.county}`,
                      },
                      token,
                    });
                  }
            }
            type="primary"
            htmlType="button"
            size="small"
          >
            All Records
          </Button>
        )}
        <>
          {/* <div id="">
            <FaFileExcel
              className="btn btn-md"
              type="button"
              id="template"
              onClick={downloadFile}
              title="Sample Template"
              style={{ color: "green" }}
              size={42}
            />
          </div> */}
          <AppExcelUpload
            showRemoveIcon={false}
            margin={"0"}
            upload={upload}
            onChange={onChange}
            onDrop={onDrop}
            //showRemoveIcon={false}
            multiple={false}
          />
        </>

        {/* changing input values globaly  */}
        <>
          {optionsShow && (
            <>
              {" "}
              <div className=" col-3 col-lg-3 d-flex">
                <Input
                  placeholder="Search here"
                  className="global-search py-0 pe-0"
                  value={search}
                  onKeyUp={() => {
                    if (!search) {
                      setAllrecords(false);
                      setCurrentPage(1);
                      setData([]);
                      // taxcadListFecth({
                      //   data: { page_no: 1, count: 200 },
                      //   token,
                      // });
                      if (filetrVal.county && filetrVal.state) {
                        taxcadFilterList({
                          data: {
                            county: filetrVal.county,
                            state: filetrVal.state,
                            page_no: 1,
                            count: 200,
                            table_name: `${stateCode}_${filetrVal.county}`,
                          },
                          token,
                        });
                      }
                    }
                  }}
                  onChange={(e) => setSearch(e.target.value)}
                  suffix={
                    <>
                      {" "}
                      {search && (
                        <Button
                          className="m-0"
                          onClick={() => {
                            if (search) {
                              setData([]);
                              setAllrecords(false);
                              setCurrentPage(1);
                              // taxcadListFecth({
                              //   data: { page_no: 1, count: 200 },
                              //   token,
                              // });
                              if (filetrVal.county && filetrVal.state) {
                                taxcadFilterList({
                                  data: {
                                    county: filetrVal.county,
                                    state: filetrVal.state,
                                    page_no: 1,
                                    count: 200,
                                    table_name: `${stateCode}_${filetrVal.county}`,
                                  },
                                  token,
                                });
                              }

                              setSearch("");
                            }
                          }}
                          type="ghost"
                        >
                          <AiOutlineClose />
                        </Button>
                      )}
                      <Button
                        disabled={!search || searchCadResult.isLoading}
                        type="primary"
                        onClick={() => {
                          // if  search avail
                          if (search) {
                            setAllrecords(false);
                            setCurrentPage(1);
                            searchCad({
                              data: {
                                cad_search: search,
                                page_no: 1,
                                count: 200,
                                ...(filetrVal.state &&
                                  filetrVal.county && {
                                    state: filetrVal.state,
                                  }),
                                ...(filetrVal.state &&
                                  filetrVal.county && {
                                    county: filetrVal.county,
                                  }),
                                table_name: `${stateCode}_${filetrVal.county}`,
                              },
                              token,
                            });
                          }
                        }}
                      >
                        <FaSearch color="#fff" />
                      </Button>
                    </>
                  }
                />
              </div>
              <div className="filter-button ">
                <Button
                  className="filter-btn"
                  onClick={() => {
                    setOpen(!open);
                    setFiletrVal({ state: "", county: "", batch: "" });
                    if (open) {
                      refecthList();
                    }
                  }}
                  type={open ? "primary" : "default"}
                >
                  <FiFilter color={open ? "#fff" : ""} />{" "}
                  <span className="ms-1">Filter</span>
                </Button>
              </div>
              {cadModule.map((item, index) => {
                if (item.add_button) {
                  return (
                    <div
                      key={index}
                      className="add_icon"
                      onClick={AddTaxCadHandler}
                    >
                      <FaPlus />
                    </div>
                  );
                }
                return null;
              })}
            </>
          )}
        </>
      </div>
      <div style={{ clear: "both" }}></div>
      {open && (
        <div className="my-2 p-2 bg-white border shadow-sm d-flex align-items-center ">
          <div className="row w-100">
            {options?.map(
              ({ value, name, data, disabled, state_code }, index) => {
                return (
                  <div className="col-12 col-lg-3">
                    <AppFilterSelect
                      name={name}
                      value={value}
                      options={data}
                      disabled={disabled}
                      onClear={() => {
                        if (name === "state") {
                          clearState();
                        } else if (name === "county") {
                          clearCounty();
                        } else if (name === "batch" && filetrVal.batch) {
                          clearBatch();
                        }
                      }}
                      onChange={(e) => {
                        setFiletrVal({
                          ...filetrVal,
                          [name]:
                            e.target?.selectedOptions[0].getAttribute(
                              "data_name"
                            ),
                        });

                        if (name === "state" && e.target.value) {
                          clearState();
                          setFiletrVal({
                            state: ""
                              ? "Texas"
                              : e.target?.selectedOptions[0].getAttribute(
                                  "data_name"
                                ),
                            batch: "",
                            county: "",
                          });
                          const selectedState = stateList.find(
                            (state) =>
                              state.state_name ===
                              e.target?.selectedOptions[0].getAttribute(
                                "data_name"
                              )
                          );
                          // console.log(selectedState, "state_name")
                          setStateCode(selectedState?.state_code || "");
                          //fetching county list
                          console.log(stateCode);
                          getCountyList({
                            state_id: { state_id: e.target.value },
                            token: token,
                          });
                          setSearch("");
                          setAllrecords(false);
                        }
                        if (name === "state" && !e.target.value) {
                          clearState();
                        }

                        if (name === "county" && e.target.value) {
                          setSearch("");
                          setAllrecords(false);
                          setCurrentPage(1);
                          setCountyName(e.target.value);
                          taxcadFilterList({
                            data: {
                              county:
                                e.target?.selectedOptions[0].getAttribute(
                                  "data_name"
                                ),
                              state: filetrVal.state,
                              page_no: 1,
                              count: pagesize,
                              table_name: `${stateCode}_${e.target?.selectedOptions[0].getAttribute(
                                "data_name"
                              )}`,
                            },
                            token,
                          });
                        }
                        if (name === "county" && !e.target.value) {
                          clearCounty();
                        }

                        if (name === "batch" && e.target.value) {
                          setCurrentPage(1);
                          setFiletrVal({
                            batch: e.target.value,
                            sate: "",
                            county: "",
                          });
                          setCountyOptions([]);
                          getTaxcadListFilter({
                            id: e.target.value,
                            token,
                          });
                        }
                        if (name === "batch" && !e.target.value) {
                          clearBatch();
                        }
                      }}
                    />
                  </div>
                );
              }
            )}
          </div>

          <div className="d-flex justify-centent-end ms-md-2">
            <Button
              className="reset-btn"
              onClick={() => {
                // clearReset();
                setFiletrVal({ state: "", county: "", batch: "" });
                setData([]);
                setSearch("");
              }}
              type="default"
            >
              <FiRotateCw /> <span className="ms-1"> Reset</span>
            </Button>
          </div>
        </div>
      )}
      <Row>
        <MyTable
          loading={loadingTable}
          pageCount={data1?.length ? !allrecord && pageCountMemo : undefined}
          dataSource={data1}
          currentPage={!allrecord && !search && currentPageMemo}
          defaultPageSize="200"
          columns={cols}
          onpage={!allrecord && !search && onpageChnage}
        />
      </Row>
    </>
  );
};
