import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json");
export const branchProductList = createApi({
  reducerPath: "branchProductList",
  tagTypes: ["branchProductList"],
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  endpoints: (builder) => ({
    branchProductList: builder.mutation({
      query: (data) => ({
        url: `/branch-product-price/${data.id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
  }),
});

export const {useBranchProductListMutation} = branchProductList;
