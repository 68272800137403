import { Card, Col, DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import InternalCards from "./InternalCards";
import {
  useAutomationCountListMutation,
  useTenDaysAtomationCountListQuery,
} from "../../Redux/Services/internalOrdersApi";
import { useSelector } from "react-redux";
import AppSpinner from "../../Components/AppSpinner/AppSpinner";
import AppTable from "../../Components/AppTable/AppTable";
import SearchColumns from "../../Components/SearchColumns";
import { FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
const { RangePicker } = DatePicker;
const InternalOrdersCount = () => {
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const [data1, setData] = useState([]);
  const [tenDaysData, setTenDaysData] = useState([]);
  const [getsortorder, setSortOrder] = useState(false);
  const [getsortfield, setSortField] = useState("");
  const { token } = useSelector((state) => state.auth);

  //sort table data
  const sort = (sortOrder, sortfield) => {
    // console.log(sortOrder, sortfield);
    if (sortOrder) {
      setSortOrder(sortOrder);
      setSortField(sortfield);
    } else {
      setSortOrder(false);
    }
  };

  //date handler
  function handleDateRangeChange(dates) {
    setSelectedDateRange(dates);
  }
  const [automationCountList, AutomationCountListResult] =
    useAutomationCountListMutation();
  useEffect(() => {
    if (AutomationCountListResult.isSuccess) {
      const newData = AutomationCountListResult.data?.data?.map(
        ({
          order_count,
          parcel_count,
          not_initialized,
          completed,
          failed,
          ...others
        }) => {
          return {
            ...others,
            order_count: order_count,
            parcel_count: parcel_count,
            not_initialized: not_initialized,
            completed: completed,
            failed: failed,
          };
        }
      );
      setData(newData);
    } else {
      setData([]);
    }
  }, [AutomationCountListResult.isLoading]);
  console.log(data1[0]);
  useEffect(() => {
    if (selectedDateRange !== null && selectedDateRange !== undefined) {
      automationCountList({
        data: {
          start_date: selectedDateRange[0]?.format("YYYY-MM-DD"),
          end_date: selectedDateRange[1]?.format("YYYY-MM-DD"),
        },
        token: token,
      });
    }
  }, [selectedDateRange, token]);

  useEffect(() => {
    if (!token|| token) {
      automationCountList({
        data: {
          start_date: null,
          end_date: null,
        },
        token: token,
      });
    }
  }, [token]);

  const tenDaysAtomationCountList = useTenDaysAtomationCountListQuery(token);
  useEffect(() => {
    if (tenDaysAtomationCountList?.isSuccess) {
      setTenDaysData(tenDaysAtomationCountList?.data?.data);
    }
  }, [tenDaysAtomationCountList?.isLoading]);

  const column2 = [
    {
      title: (
        <span>
          Date{" "}
          {getsortfield === "date" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "date" ? (
            <FaSortAmountUp className="text-info" />
          ) : getsortorder === "descend" && getsortfield === "date" ? (
            <FaSortAmountDown className="text-info" />
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "date",
      key: "date",
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      width: 100,
      align: "center",
      ...SearchColumns("date", "Date"),
    },
    {
      title: (
        <span>
          Orders Count{" "}
          {getsortfield === "order_count" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "order_count" ? (
            <FaSortAmountUp className="text-info" />
          ) : getsortorder === "descend" && getsortfield === "order_count" ? (
            <FaSortAmountDown className="text-info" />
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "order_count",
      key: "order_count",
      sorter: (a, b) => Number(a.order_count) - Number(b.order_count),
      // width: 100,
      align: "center",
      ...SearchColumns("order_count", "Order Count"),
    },
    {
      title: (
        <span>
          Parcel Count{" "}
          {getsortfield === "parcel_count" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "parcel_count" ? (
            <FaSortAmountUp className="text-info" />
          ) : getsortorder === "descend" && getsortfield === "parcel_count" ? (
            <FaSortAmountDown className="text-info" />
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "parcel_count",
      key: "parcel_count",
      sorter: (a, b) => a.parcel_count - b.parcel_count,
      // width: 100,
      align: "center",
      ...SearchColumns("parcel_count", "Parcel Count"),
    },
    {
      title: (
        <span>
          Automation Not Initialized{" "}
          {getsortfield === "not_initialized" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" &&
            getsortfield === "not_initialized" ? (
            <FaSortAmountUp className="text-info" />
          ) : getsortorder === "descend" &&
            getsortfield === "not_initialized" ? (
            <FaSortAmountDown className="text-info" />
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "not_initialized",
      key: "not_initialized",
      sorter: (a, b) => a.not_initialized - b.not_initialized,
      // width: 130,
      align: "center",
      ...SearchColumns("not_initialized", "Not Initialized"),
    },
    {
      title: (
        <span>
          Completed{" "}
          {getsortfield === "completed" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "completed" ? (
            <FaSortAmountUp className="text-info" />
          ) : getsortorder === "descend" && getsortfield === "completed" ? (
            <FaSortAmountDown className="text-info" />
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "completed",
      key: "completed",
      sorter: (a, b) => a.completed - b.completed,
      // width: 120,
      align: "center",
      ...SearchColumns("completed", "Completed"),
    },
    {
      title: (
        <span>
          Failed{" "}
          {getsortfield === "failed" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "failed" ? (
            <FaSortAmountUp className="text-info" />
          ) : getsortorder === "descend" && getsortfield === "failed" ? (
            <FaSortAmountDown className="text-info" />
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "failed",
      key: "failed",
      sorter: (a, b) => a.failed - b.failed,
      // width: 145,
      align: "center",
      ...SearchColumns("failed", "Failed"),
    },
  ];

  return (
    <div className="container-fluid">
      {AutomationCountListResult.isLoading ||
        (tenDaysAtomationCountList.isLoading && <AppSpinner />)}
      <h5 className="mt-2">Internal Orders </h5>
      <div className="row  mt-2 mb-2 ">
        <div className=" col-lg-4 col-md-6 col-lg-12 d-flex align-items-center mb-1 justify-content-end ">
          <RangePicker
            value={selectedDateRange}
            format={"YYYY-MM-DD"}
            onChange={handleDateRangeChange}
            allowClear={true}
          />
        </div>
      </div>
      <div>
        <InternalCards data={data1[0]} />
      </div>
      <Card className="dounut-barchart-row">
        <h5 align="left" className="h-5 ">
          Last 10 Days Automation Orders Count
        </h5>
        <div className="row  p-2 ">
          <AppTable
            dataSource={tenDaysData}
            columns={column2}
            pagination={true}
            defaultPageSize={10}
            pageSizeOptions={["5", "10", "15", "20", "25"]}
            loading={tenDaysAtomationCountList?.isLoading}
            sort={sort}
          />
        </div>
      </Card>
    </div>
  );
};
export default InternalOrdersCount;
