import { createSlice } from "@reduxjs/toolkit";

const taxcollectorTypeList = createSlice({
  name: "taxcollectorTypeList",
  initialState: {
    taxcollectorList: [],
  },
  reducers: {
    taxcollectorTypeListAction: (state, action) => {
      state.taxcollectorTypeList = action.payload;
    },
  },
});

export const {taxcollectorTypeListAction} = taxcollectorTypeList.actions;

export default taxcollectorTypeList.reducer;