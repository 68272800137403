import { useState } from "react";
import CurrencyFormat from "react-currency-format";
import { saveAs } from 'file-saver';
const _ = require("lodash");
//1. functon for base64 converter
export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export function useForceUpdate() {
  let [value, setState] = useState(true);
  return () => setState(!value);
}

//scrollToView
export const scrollComp = (COMP) => {
  document.getElementById(COMP).scrollIntoView({ behavior: "smooth" });
};

export const removeBlank = (values) => {
  for (const key in values) {
    if (values[key] === "" || values[key] === null) {
      delete values[key];
    }
  }
};

export const removeArrayBlank = (values) => {
  try {
    values?.map((ele) => {
      for (const key in ele) {
        if (ele[key] === "" || ele[key] === null || ele[key] === undefined) {
          delete ele[key];
        }
      }
    });
  } catch (error) {
    console.log(error)
  }
};

//update array of objects
export function updateArrayOfObject(value = {}, array = []) {
  const index = array.findIndex(item => (item?.tempId || item?.id) === (value?.tempId || value?.id))
  if (index !== -1) {
    array[index] = value
  }
  return array
}

export const filterArray = (arr1, arr2) => {
  const filtered = arr1.filter((el) => {
    return arr2.indexOf(el) === -1;
  });
  return filtered;
};

export const asendingList = (data, name) => {
  return _.orderBy(data, [(item) => _.get(item, name).toLowerCase()]);
};

///retun id
export const returnArryObjID = (data, name) => {
  const res = [];
  data.map((ele) => {
    res.push(ele[name]);
  });
  return res;
};

export function convertDateFormat(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [mnth, day, date.getFullYear()].join("-");
}

export function dateFun(format) {
  let date = new Date();
  switch (format) {
    case "date":
      return convertDateFormat(date);
    case "datetime":
      return (
        convertDateFormat(date) +
        "-" +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds()
      );
    default:
      return date;
  }
}

export function discountByPercent(originalPrice, discountPercentage) {
  const month = new Date().getMonth() + 1
  // Calculate the discount amount
  const discountAmount = (originalPrice * discountPercentage) / 100;
  if (month === 10 || month === 11 || month === 12) {
    return originalPrice - discountAmount;
  } else {
    return originalPrice + discountAmount;
  }
}

//add date and tine in us format
export const usDateTimeFormat = (originalDate) => {
  if (!originalDate) {
    return null
  }
  try {
    const date = new Date(originalDate)
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
      timeZone: 'UTC', // You may adjust the time zone as needed
    };


    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
    const customFormattedDate = formattedDate.replace(/\//g, '-');
    return customFormattedDate
  } catch (error) {
    console.log(error)
  }

}

//price format
export function priceFormater(value, showNa = false, toFixedVal = true) {
  if (!value) {
    return " "
  } else {
    return (
      <CurrencyFormat
        value={
          toFixedVal
            ? Number(String(value)?.replace(/[|&;$%@"<>()+,]/g, "")).toFixed(2)
            : Number(String(value)?.replace(/[|&;$%@"<>()+,]/g, ""))
        }
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$"}
      />
    );
  }
}

//price format
export function priceFormater2(value, showNa = false, toFixedVal = true) {
  if (!value) {
    return " "
  } else {
    return (
      <CurrencyFormat
        value={
          Number(String(value)?.replace(/[|&;$%@"<>()+,]/g, "")).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
        }
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$"}
      />
    );
  }
}

export function checkIsHoaProduct(id) {
  switch (id) {
    case 2:
      return true
    case 4:
      return true
    case 5:
      return true
    case 9:
      return true
    case 10:
      return true
    default:
      return false
  }
}

//price format
export function roundedPrice(value) {
  if (!value) {
    return " "
  } else {
    return (
      <CurrencyFormat
        value={
          Math.round(Number(String(value)?.replace(/[|&;$%@"<>()+,]/g, ""))).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })

        }
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$"}
      />
    );
  }
}

//price format with excel
export function priceDecimalCoverter(value) {
  if (!value) {
    return " ";
  } else {
    return `$${Number(value?.replace(/[|&;$%@"<>()+,]/g, "")).toFixed(2)}`;
  }
}

//price format
export function mobileFormat(value) {
  if (!value) {
    return "--";
  } else {
    return (
      <CurrencyFormat
        displayType={"text"}
        value={value?.replace(/[|&;$%@"<>()+,-]/g, "").slice(1)}
        format={"(###) ###-####"}
      />
    );
  }
}

// export function mobileFormat(value) {
//   if (!value) {
//     return "--";
//   }

//   // Remove all non-numeric characters
//   const numericValue = value.replace(/\D/g, "");

//   // Ensure the value is of sufficient length
//   // if (numericValue.length !== 10) {
//   //   return "Invalid Number"; // Optional error message for incorrect numbers
//   // }

//   return (
//     <CurrencyFormat
//       displayType={"text"}
//       value={numericValue}
//       format="(###) ###-####"
//     />
//   );
// }


//perscent format
export function percentFormat(value) {
  if (!value) {
    return " ";
  } else {
    return (
      <CurrencyFormat
        displayType={"text"}
        value={Number(value?.replace(/[|&;$%@"<>()+,]/g, ""))}
        suffix="%"
      />
    );
  }
}

//check if values is changed
export function compareChange(before = [], obj = {}, keyname) {
  let findexIndexVal = before.findIndex((item) => item?.id === obj?.id);
  let obj1 = before[findexIndexVal];
  let result = [];

  if (findexIndexVal !== -1) {
    for (const key in obj1) {
      if (!(obj[key] === obj1[key])) {
        result.push(key);
      }
    }
  }

  return result.includes(keyname);
}

// export function checkObjValidValue(value = []) {
//   value.forEach((ele) => {
//     if (Object.keys(ele).length === 1) {
//       let index = value.findIndex(ele)
//     } else {
//       return true;
//     }
//   });
// }

// export function downloadBase64PDF(base64Data, fileName) {
//   console.log(base64Data, fileName, 292)
//   try {
//     // Clean the base64 string
//     // const cleanedBase64Data = base64Data.replace(/[^A-Za-z0-9+/=]/g, '');

//     // Decode the base64 string
//     const byteCharacters = atob(base64Data)
//     console.log(byteCharacters)
//     // Create an array for the decoded data
//     const byteNumbers = new Array(byteCharacters.length);
//     for (let i = 0; i < byteCharacters.length; i++) {
//         byteNumbers[i] = byteCharacters.charCodeAt(i);
//     }

//     // Create a Uint8Array from the byte numbers
//     const byteArray = new Uint8Array(byteNumbers);

//     // Create a Blob object from the Uint8Array
//     const blob = new Blob([byteArray], { type: 'application/pdf' });

//     // Create a download link element
//     const link = document.createElement('a');
//     link.href = URL.createObjectURL(blob);
//     link.download = fileName;

//     // Append the link to the body (necessary for Firefox)
//     document.body.appendChild(link);

//     // Programmatically click the link to trigger the download
//     link.click();

//     // Remove the link from the document
//     document.body.removeChild(link);

//   } catch (error) {
//     console.error('Failed to download PDF:', error);
//   }
// }
export function downloadBase64PDF(base64Data, fileName) {
  try {
    // Decode the base64 string
    const byteCharacters = atob(base64Data);

    // Create an array for the decoded data
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    // Create a Uint8Array from the byte numbers
    const byteArray = new Uint8Array(byteNumbers);

    // Create a Blob object from the Uint8Array
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    // Create a download link element
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;

    // Append the link to the body (necessary for Firefox)
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  } catch (error) {
    console.error('Failed to download PDF:', error);
  }
}

export function downloadPDF(base64String, fileName) {
  try {
    const linkSource = `data:application/pdf;base64,${base64String}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  } catch (error) {
    console.error('Failed to download PDF:', error);
  }
}

export function downloadPdf(base64String, fileName) {
  const downloadLink = document.createElement("a");
  downloadLink.href = atob(base64String);
  downloadLink.download = fileName;
  downloadLink.click();
}


// export function downloadJSON(jsonData, filename) {
//   const element = document.createElement('a');
//   const file = new Blob([JSON.stringify(jsonData)], { type: 'application/json' });
//   element.href = URL.createObjectURL(file);
//   element.download = filename;
//   document.body.appendChild(element); // Required for this to work in Firefox
//   element.click();
// }

export function downloadJSON(jsonData, filename) {
  const jsonString = JSON.stringify(jsonData, null, 2);
  const blob = new Blob([jsonString], { type: 'application/json' });
  saveAs(blob, filename);
}

export function dateFormat(value) {
  if (value === " " || !value) {
    return null
  } else {
    const inputDate = String(value);
    const parts = inputDate?.split("-");
    const formattedDate = `${parts[1]}-${parts[0]}-${parts[2]}`;
    return formattedDate
  }
}

export function getRowIds(rowSelected = {}, data = []) {
  let newArr = []
  Object.keys(rowSelected).forEach((e) => {
    newArr.push(e)
  })
  const fileterValue = data.filter((ele, i) => {
    return newArr.includes(String(i))
  })
  return fileterValue.map((ele) => {
    return ele.id
  })
} 