import React from 'react'
import AppModal from '../../../../../Components/AppModal/AppModal';

const ViewNoticeFlagModal = ({ values }) => {
    return (
        <div className="p-3">
            <div style={{ maxHeight: 300, overflow: "auto" }}>
                <h5 className="border-bottom mb-2">Notice Flags</h5>
                <table className="table border">
                    <thead>
                        <tr>
                            <th scope="col">Sr No</th>
                            <th scope="col">Name</th>
                            <th scope="col">Comments</th>
                            <th scope="col">Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {values?.map((ele, index) => {
                            return (
                                <tr key={ele.id}>
                                    <td>{index + 1}</td>
                                    <td>{ele.name}</td>
                                    <td>{ele.comments}</td>
                                    <td>{ele.created_at}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ViewNoticeFlagModal