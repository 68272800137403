import FormInput from "../../../../Components/AppInput/AppInput";
import MyButton from "../../../../Components/AppButton/AppButton";
import { useSelector } from "react-redux/es/exports";
import { Col, Row } from "react-bootstrap";
import { Alert, notification } from "antd";
import { useState, useEffect } from "react";
import {
  useUpdateTaxCadListMutation,
  useEditTaxCadQuery,
  useAddSingleTaxCadMutation,
} from "../../../../Redux/Services/Taxcad";
import AppSpinner from "../../../../Components/AppSpinner/AppSpinner";
import {
  scrollComp,
} from "../../../../Controllers/Functions";
import AppSwitch from "../../../../Components/AppSwitch/AppSwitch";
// const _ = require("lodash");

export const ListModal = ({
  editId,
  initialValues,
  afterClose,
  setShowHide,
  setOpen,
}) => {
  const [getError, setGetError] = useState(false);
  //   values assign
  const [values, setValues] = useState(initialValues);

  //calling redux store
  const { token } = useSelector((state) => state.auth);
  // const { fieldFlag } = useSelector((state) => state.flagRoles);

  //calling rtk query hook for geting customerType list
  const editTaxCadQuery = useEditTaxCadQuery(
    { id: editId, token: token },
    { skip: !editId }
  );


  //calling rtk query hook for update taxcad list
  const UpdateTaxCadList = useUpdateTaxCadListMutation();
  const [UpdateNewTaxCadData] = UpdateTaxCadList;

  //calling rtk query hook for adding  single tax cad
  const addNewTaxCad = useAddSingleTaxCadMutation();
  const [addTaxCad] = addNewTaxCad;
  useEffect(() => {
    if (addNewTaxCad[1].isSuccess) {
      closeModal();
      setOpen(false);
      ///after submit details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: addNewTaxCad[1].data?.message,
      });
    }
    if (addNewTaxCad[1].error) {
      //here created for in loop for catch server error
      setGetError(
        addNewTaxCad[1].error?.data?.error ||
        addNewTaxCad[1].error?.data?.message ||
        addNewTaxCad[1].error.error ||
        addNewTaxCad[1].error.data?.msg.sqlMessage
      );
    }
  }, [addNewTaxCad[1].isLoading]);

  //clear error function
  function clearStatus() {
    setGetError(false);
  }
  //function for modal close
  function closeModal() {
    clearStatus();
    // //it will be close to the modal
    setShowHide(false);
  }


  useEffect(() => {
    if (UpdateTaxCadList[1].isSuccess) {
      setOpen(false);
      closeModal();
      ///after update details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: UpdateTaxCadList[1].data?.message,
      });
    }
    if (UpdateTaxCadList[1].error) {
      /// erros details
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description:
          UpdateTaxCadList[1].error.data.message ||
          UpdateTaxCadList[1].error.error ||
          UpdateTaxCadList[1].error.data?.msg.sqlMessage,
      });
    }
  }, [UpdateTaxCadList[1].isLoading]);

  //useEffect for setting default values
  useEffect(() => {
    setValues(initialValues);
  }, [afterClose]);

  //useEfect for Refecth edit details
  useEffect(() => {
    if (editId) {
      editTaxCadQuery.refetch();
    }
    //all alert messages cleared!
    clearStatus();
  }, [afterClose]);

  const inputs = [
    {
      id: 1,
      name: "state",
      type: "text",
      value: values.state || "",
      placeholder: "Enter State ",
      errorMessage: "Please enter state name!",
      label: "State ",
      column: "3",
      disabled: editId && true,
      required: !editId && true,
    },
    {
      id: 2,
      name: "county",
      type: "text",
      value: values.county || "",
      placeholder: "Enter county",
      errorMessage: "Please enter county name!",
      label: "County ",
      column: "3",
      disabled: editId && true,
      required: !editId && true,
    },
    {
      id: 3,
      name: "tax_year",
      type: "text",
      value: values.tax_year || "",
      label: "Tax Year ",
      placeholder: "Enter Tax Year",
      column: "3",
      errorMessage: "Please enter valid tax year!",
      required: true,
      maxLength: 4,
      minLength: 4,
    },
    {
      id: 4,
      name: "tax_collector",
      type: "text",
      value: values.tax_collector || "",
      placeholder: "Enter Tax Collector",
      errorMessage: "Please enter tax collector!",
      label: "Tax Collector ",
      column: "3",
      required: true,
    },
    {
      id: 5,
      name: "due_date",
      type: "date",
      usaFormat: true,
      value: values.due_date || false,
      placeholder: "Enter Due Date",
      errorMessage: "Please enter due date!",
      label: "Due Date:",
      column: "3",
      required: false,
    },
    {
      id: 6,
      name: "cad_property_id",
      type: "text",
      value: values.cad_property_id || false,
      placeholder: "Enter Cad Property Id",
      errorMessage: "Please enter cad property id!",
      label: "Cad Property Id ",
      column: "3",
      required: true,
    },
    {
      id: 7,
      name: "cad_geo_id",
      _id: "cad_geo_id",
      type: "text",
      column: "3",
      value: values.cad_geo_id || false,
      errorMessage: "Please enter cad geo id!",
      placeholder: "Enter Cad Geo Id",
      label: "Cad Geo Id",
    },
    {
      id: 8,
      name: "tax_assessor_prop_id",
      type: "text",
      _id: "tax_assessor_prop_id",
      placeholder: "Enter Tax Assessor Property Id ",
      errorMessage: "Please enter assessor property id!",
      label: "Assessor Property Id",
      column: "3",
    },
    {
      id: 9,
      name: "cross_tax_id",
      type: "text",
      _id: "cross_tax_id",
      placeholder: "Enter Cross Tax Id",
      errorMessage: "Please enter cross tax id!",
      label: "cross Tax  Id",
      column: "3",
    },
    {
      id: 10,
      name: "manual_bill_indicator",
      type: "text",
      _id: "manual_bill_indicator",
      placeholder: "Enter Manual Bill Indicator",
      errorMessage: "Please enter manual bill indicator!",
      label: "Manual Bill Indicator",
      column: "3",
    },
    {
      id: 11,
      name: "tax_suite_indicator",
      type: "text",
      _id: "tax_suite_indicator",
      placeholder: "Enter Tax Suite Indicator",
      errorMessage: "Please enter tax suite indicator!",
      label: "Tax Suite Indicator",
      column: "3",
    },
    {
      id: 12,
      name: "payment_plan",
      type: "text",
      _id: "payment_plan",
      placeholder: "Enter Payment Plan",
      errorMessage: "Please enter payment_plan!",
      label: "Payment Plan",
      column: "3",
    },
    {
      id: 13,
      name: "discount_plan",
      type: "text",
      _id: "discount_plan",
      placeholder: "Enter Discount Plan",
      errorMessage: "Please enter discount_plan!",
      label: "Discount Plan",
      column: "3",
    },
    {
      id: 14,
      name: "bill_type_one",
      type: "text",
      _id: "bill_type_one",
      placeholder: "Enter Bill Type One",
      errorMessage: "Please enter bill_type_one!",
      label: "Bill Type One",
      column: "3",
    },
    {
      id: 15,
      name: "jurisdiction_name_one",
      type: "text",
      _id: "jurisdiction_name_one",
      placeholder: "Enter Jurisdiction Name One",
      errorMessage: "Please enter jurisdiction name one!",
      label: "Jurisdiction Name One",
      column: "3",
    },
    {
      id: 16,
      name: "jurisdiction_code_one",
      type: "text",
      _id: "jurisdiction_code_one",
      placeholder: "Enter Jurisdiction Code One",
      errorMessage: "Please enter jurisdiction code one!",
      label: "Jurisdiction Code One",
      column: "3",
    },
    {
      id: 17,
      name: "current_bill_base_tax",
      type: "text",
      _id: "current_bill_base_tax",
      placeholder: "Enter Current Bill Base Tax",
      errorMessage: "Please enter current bill base tax!",
      label: "Current Bill Base Tax",
      column: "3",
    },
    {
      id: 18,
      name: "current_base_tax_due",
      type: "text",
      _id: "current_base_tax_due",
      placeholder: "Enter Current Base Tax Due",
      errorMessage: "Please enter current base tax due!",
      label: "Current Base Tax Due",
      column: "3",
    },
    {
      id: 19,
      name: "pi_collection_fees",
      type: "text",
      _id: "pi_collection_fees",
      placeholder: "Enter Pi Collection Fees",
      errorMessage: "Please enter pi collection fees!",
      label: "Pi Collection Fees",
      column: "3",
    },
    {
      id: 22,
      name: "bill_type_two",
      type: "text",
      _id: "bill_type_two",
      placeholder: "Enter Bill Type Two",
      errorMessage: "Please enter bill type two!",
      label: "Bill Type Two",
      column: "3",
    },
    {
      id: 23,
      name: "prior_bill_years",
      type: "text",
      _id: "prior_bill_years",
      placeholder: "Enter Prior Bill Years",
      errorMessage: "Please enter prior bill years!",
      label: "Prior Bill Years",
      column: "3",
    },
    {
      id: 24,
      name: "jurisdiction_name_two",
      type: "text",
      _id: "jurisdiction_name_two",
      placeholder: "Enter Jurisdiction Name Two",
      errorMessage: "Please enter jurisdiction name two!",
      label: "Jurisdiction Name Two",
      column: "3",
    },

    {
      id: 24,
      name: "jurisdiction_code_two",
      type: "text",
      _id: "jurisdiction_code_two",
      placeholder: "Enter Jurisdiction Code Two",
      errorMessage: "Please enter jurisdiction code two!",
      label: "Jurisdiction Code Two",
      column: "3",
    },
    {
      id: 25,
      name: "combined_bases_tax_due",
      type: "text",
      _id: "combined_bases_tax_due",
      placeholder: "Enter Combined Bases Tax Due",
      errorMessage: "Please enter combined bases tax due!",
      label: "Combined Bases Tax Due",
      column: "3",
    },
    {
      id: 20,
      name: "current_pay_off_month_one",
      type: "text",
      _id: "current_pay_off_month_one",
      placeholder: "Enter Current Pay Off Month One",
      errorMessage: "Please  enter current pay off month one!",
      label: "Current Pay Off Month One",
      column: "3",
    },
    {
      id: 21,
      name: "current_pay_off_month_two",
      type: "text",
      _id: "current_pay_off_month_two",
      placeholder: "Enter Current Pay Off Month Two",
      errorMessage: "Please enter current pay off month two!",
      label: "Current Pay Off Month Two",
      column: "3",
    },

    {
      id: 26,
      name: "prior_year_pay_off_month_one",
      type: "text",
      _id: "prior_year_pay_off_month_one",
      placeholder: "Enter Prior Year Pay Off Month One",
      errorMessage: "Please enter prior year pay off month one!",
      label: "Prior Year Pay Off Month One",
      column: "3",
    },
    {
      id: 27,
      name: "prior_year_pay_off_month_two",
      type: "text",
      column: "3",
      _id: "prior_year_pay_off_month_two",
      placeholder: "Enter Prior Year Pay Off Month Two",
      errorMessage: "Please enter prior year pay off month two !",
      label: "Prior Year Pay Off Month Two",
    },
  ];

  const handleReset = (e) => {
    e.preventDefault();
    setValues(initialValues);
    if (editId) {
      editTaxCadQuery.refetch();
    }
    clearStatus();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!editId) {
      addTaxCad({
        data: {
          ...values,
        },
        // id: editId,
        token: token,
      });
    }
    if (editId) {
      UpdateNewTaxCadData({
        data: {
          ...values,
        },
        id: editId,
        token: token,
      });
    }
  };

  const onChange = async (e, date, dateFormat) => {
    if (dateFormat) {
      setValues({
        ...values,
        [e]: date,
      });
    } else {
      const { name, value } = e.target;
      setValues({
        ...values,
        [name]: value,
      });
      //number only
      if (name === "tax_year") {
        setValues({
          ...values,
          [name]: value.replace(/[^0-9.]/gi, ""),
        });
      }
    }
    //claser status values
    clearStatus();
  };

  //useEffect for edit-taxcad
  useEffect(() => {
    if (editTaxCadQuery.isSuccess) {
      setValues(editTaxCadQuery.data?.data[0]);
    }
    if (editTaxCadQuery.isError) {
      setGetError(
        editTaxCadQuery.error?.data?.message ||
        editTaxCadQuery.error.data ||
        (editTaxCadQuery.error.error && "server unreachable!") ||
        editTaxCadQuery.error.data?.msg.sqlMessage
      );
    }
  }, [editTaxCadQuery.isFetching]);

  //AppSwitch box
  const appSwitchHanler = (property, value) => {
    setValues({ ...values, [property]: value });
  };

  //error
  useEffect(() => {
    if (getError) {
      scrollComp("taxcadError");
    }
  }, [getError]);

  return (
    <div className="position-relative">
      <div className=" border-bottom border-2" id="customerError">
        <h5>{editId ? "Edit Tax Cad" : "Add Tax Cad"}</h5>
      </div>
      {getError && (
        <div className="mt-3">
          <Alert message={getError} type="error" showIcon />{" "}
        </div>
      )}
      <form onSubmit={handleSubmit}>
        {(UpdateTaxCadList[1].isLoading ||
          editTaxCadQuery.isFetching ||
          addNewTaxCad[1].isLoading) && <AppSpinner />}
        <Row className={`${editTaxCadQuery.isFetching ? "invisible" : ""} `}>
          <div className="col-12 col-lg-12">
            <div className="row">
              {inputs.map((input) => (
                <>
                  {input.type === "switch" && <AppSwitch
                    key={input.id}
                    onChange={appSwitchHanler}
                    {...input}
                  />}

                  {(input.type === "text" ||
                    input.type === "date" ||
                    input.type === "email" ||
                    input.type === "tel") && (
                      <FormInput
                        key={input.id}
                        defaultSet={afterClose}
                        {...input}
                        value={values[input.name]}
                        onChange={onChange}
                      />
                    )}
                </>
              ))}
            </div>
          </div>
        </Row>
        <Row className={`${editTaxCadQuery.isFetching ? "invisible" : ""} `}>
          <Col className="my-3">
            <div className="d-flex justify-content-center">
              <div className="me-md-2 ">
                <MyButton
                  onClick={handleReset}
                  title={"CLEAR"}
                  variant={"secondary-md"}
                />
              </div>
              {!editId ? (
                <MyButton title={"SUBMIT"} variant={"primary-md"} />
              ) : (
                <MyButton title={"SUBMIT"} variant={"primary-md"} />
              )}
            </div>
          </Col>
        </Row>
      </form>
    </div>
  );
};
