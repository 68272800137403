import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json");
export const supportCustomer = createApi({
  reducerPath: "supportCustomers",
  tagTypes: ["supportCustomer"],
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  endpoints: (builder) => ({
    supportCustomerList: builder.query({
      query: (token) => ({
        url: `/support-customer-list`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ["supportCustomer"],
    }),

    updateSupportCustomerStatus: builder.mutation({
      query: (data) => ({
        url: `/update-support-customer-status/${data.id}`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["supportCustomer"],
    }),
  }),
});

export const {
    useSupportCustomerListQuery,
    useUpdateSupportCustomerStatusMutation
} = supportCustomer;