import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json")
export const customerStateCounty = createApi({
  reducerPath: "customerStateCounty",
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  endpoints: (builder) => ({
    customerStateCountyList: builder.mutation({
      query: (data) => ({
        url: `/enabled-state-county-customer/${data.id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
  }),
});

export const { useCustomerStateCountyListMutation } =
  customerStateCounty;
