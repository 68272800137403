import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import React, { useState } from "react";
import { addDays } from "date-fns";
import { BsExclamationCircle } from "react-icons/bs";
import { Row } from "react-bootstrap";
import "./AppDateRangePicker.css";

export default function AppDateRangePicker(props) {
	const {
		placeholder,
		subhead,
		column,
		label,
		name,
		errorMessage,
		id,
		hide,
		date,
		margin,
        months,
        ranges,
        onChange,
		...inputProps
	} = props;

	
	const [state, setState] = useState([
		{
			startDate: new Date(),
			endDate: addDays(new Date(), 7),
			key: "selection",
		},
	]);
	return (
		<div className={` col-12 col-md-${column}`}>
			{/* {subhead && <h6 className="mt-3">{subhead}</h6>} */}
			<Row className="mt-2">
				<label className="ms-1  heading-5 text-dark mb-2">
					{label}{" "}
					{inputProps.required && <span className="text-danger">*</span>}
					{inputProps.lableIcon}
				</label>
            </Row>
            <div className="date-container">
                	<DateRangePicker
				onChange={onChange}
				showSelectionPreview={true}
				moveRangeOnFirstSelection={false}
				months={months}
				ranges={ranges}
				direction="horizontal"
				preventSnapRefocus={true}
				calendarFocus="backwards"
			/>
            </div>
		

			<span className="input-error">
				<BsExclamationCircle className="alertIcon" />
				{errorMessage}
			</span>
		</div>
	);
}
