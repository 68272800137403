import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json");
export const jurisdiction = createApi({
  reducerPath: "jurisdiction",
  tagTypes: ["jurisdiction"],
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  endpoints: (builder) => ({
    jurisdictionList: builder.query({
      query: (token) => ({
        url: `/jurisdiction-list`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ["jurisdiction"],
    }),
    editJurisdiction: builder.query({
      query: (data) => ({
        url: `/edit-jurisdiction/${data.id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    //jurisdication bonds info
    jurisdictionBonsInfo: builder.mutation({
      query: (data) => ({
        url: `/jurisdiction-bonds-info/${data.id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    updateJurisdiction: builder.mutation({
      query: (data) => ({
        url: `/update-jurisdiction/${data.id}`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["jurisdiction"],
    }),
    updateJurisdictionStatus: builder.mutation({
      query: (data) => ({
        url: `/update-jurisdiction-status/${data.id}`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["jurisdiction"],
    }),
    addJurisdiction: builder.mutation({
      query: (data) => ({
        url: `/add-jurisdiction`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["jurisdiction"],
    }),
    searchJurisdiction: builder.mutation({
      query: (data) => ({
        url: `/search-jurisdiction-list`,
        method: "POST",
        mode: "cors",
        body: data.value,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    autoUpdateJuriData: builder.mutation({
      query: (data) => ({
        url: `/auto-update-jurisdiction-details/${data.id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    juriTypeListSelect: builder.query({
      query: (token) => ({
        url: `/jurisdiction-type-list`,
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ["jurisdiction"],
    }),
    deleteJuriTypeList: builder.mutation({
      query: (data) => ({
        url: `/delete-jurisdiction-type/${data.id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      providesTags: ["jurisdiction"],
    }),
    juriReadOnlyList: builder.query({
      query: (token) => ({
        url: `/jurisdiction-read-only-comment-list`,
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    juriDisplayCertList: builder.query({
      query: (token) => ({
        url: `/jurisdiction-display-cert-comment-list`,
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    juriTaxBaseList: builder.mutation({
      query: (data) => ({
        url: `/tax-collector-list-based-juri/${data.id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),

    juriFilterTableList: builder.mutation({
      query: (data) => ({
        url: `/jurisdiction-list-based-state/${data.id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    juriFilterTableListCountyBase: builder.mutation({
      query: (data) => ({
        url: `/jurisdiction-list-based-county/${data.id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    juriFilterTableListTaxBase: builder.mutation({
      query: (data) => ({
        url: `/jurisdiction-list-based-tax-collector/${data.id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    multiCountyJuriList: builder.mutation({
      query: (data) => ({
        url: `/county-jurisdiction-list/${data.id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    updateJuriType: builder.mutation({
      query: (data) => ({
        url: `/update-jurisdiction-type/${data.id}`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["jurisdiction"],
    }),
    addJuriType: builder.mutation({
      query: (data) => ({
        url: `/add-jurisdiction-type`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["jurisdiction"],
    }),
    parcelTaxJuriList: builder.mutation({
      query: (data) => ({
        url: `/parcel-jurisdiction-list`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    //juri info fecth base on juri code for parcel tax collector
    juriInfoSelect: builder.mutation({
      query: (data) => ({
        url: `/parcel-jurisdiction-code`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    //juri info fecth through the automation
    updateParcelJuriAutomation: builder.mutation({
      query: (data) => ({
        url: `/update-parcel-jurisdiction`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    juriInfoSearch: builder.mutation({
      query: (data) => ({
        url: `/search-jurisdiction-info`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
  }),
});

export const {
  useAddJurisdictionMutation,
  useEditJurisdictionQuery,
  useJurisdictionListQuery,
  useUpdateJurisdictionMutation,
  useUpdateJurisdictionStatusMutation,
  useSearchJurisdictionMutation,
  useJuriTypeListSelectQuery,
  useAutoUpdateJuriDataMutation,
  useJuriReadOnlyListQuery,
  useJuriDisplayCertListQuery,
  useJuriTaxBaseListMutation,
  useJuriFilterTableListMutation,
  useJuriFilterTableListCountyBaseMutation,
  useJuriFilterTableListTaxBaseMutation,
  useMultiCountyJuriListMutation,
  useUpdateJuriTypeMutation,
  useAddJuriTypeMutation,
  useParcelTaxJuriListMutation,
  useJuriInfoSelectMutation,
  useJuriInfoSearchMutation,
  useUpdateParcelJuriAutomationMutation,
  useDeleteJuriTypeListMutation,
  useJurisdictionBonsInfoMutation
} = jurisdiction;
