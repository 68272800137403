import { createSlice } from "@reduxjs/toolkit";

const internalOrdersList = createSlice({
  name: "internalOrdersList",
  initialState: {
    internalOrdersList: [],
  },
  reducers: {
    internalOrdersListAction: (state, action) => {
      state.internalOrdersList = action.payload;
    },
  },
});

export const {internalOrdersListAction} = internalOrdersList.actions;

export default internalOrdersList.reducer;