import React, { useState } from "react";
import { Radio } from "antd";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { memo } from "react";
import { TaxStatusReport } from "./TaxStatusReport/taxStatusReportTab";
import { HOAStatusReport } from "./HoaStatusReport/hoaStatusReport";

const OrderStatusReportTab = ({ refresh }) => {
  const [key, setKey] = useState("Tax Status");
  const [dependency, setDependency] = useState(false);
  const OnradiohandleChange = (e) => {
    setKey(e.target.value);
  };

  //redux store
  const {  orderStatusTabs  } = useSelector((state) => state.flagRoles);

  useEffect(() => {
    setDependency(!dependency);
  }, [refresh]);

  useEffect(() => {
    setKey( orderStatusTabs[0]);
  }, [dependency]);

  return (
    <>
      <Row className="seprate">
        <Col lg="12">
          <Radio.Group onChange={OnradiohandleChange} value={key}>
            {/* tabs showing base on users roles  */}
            { orderStatusTabs  &&
               orderStatusTabs.map((ele) => {
                return (
                  <Radio key={ele} value={ele} defaultChecked>
                    {ele}
                  </Radio>
                );
              })}
          </Radio.Group>
          <div className="seprate"></div>
          {key === "Tax Status" && <TaxStatusReport refresh={refresh} childTab={key} key={key} />}
          {key === "HOA Status" && <HOAStatusReport refresh={refresh} childTab={key} key={key} />}
        </Col>
      </Row>
      
    </>
  );
};

export default memo(OrderStatusReportTab);
