import { createSlice } from "@reduxjs/toolkit";

const labelList = createSlice({
  name: "labelList",
  initialState: {
    labelList: [],
  },
  reducers: {
      labelListAction: (state, action) => {
      state.labelList = action.payload;
    },
  },
});

export const {labelListAction} = labelList.actions;

export default labelList.reducer;
