import React from "react";
import { Button, Input, Select, Space } from "antd";
import { useRef, useState } from "react";
import Form, { useForm } from "antd/es/form/Form";
import { PlusOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import AppCheckbox from "../AppCheckbox/AppCheckbox";
let index = 0;
export const AntdMultiSelectDropdown = ({
  random,
  setValues,
  values,
  showCheckBox,
  selected = [],
  options = [],
  onChangeVal = function () { },
}) => {
  const [items, setItems] = useState(["jack", "lucy"]);
  const [seletItems, setSeletItems] = useState([]);
  const [error, setError] = useState(false);
  const [form] = useForm();
  const inputRef = useRef(null);

  //useEffect for email
  useEffect(() => {
    setSeletItems(selected);
    setItems(options);
    onChangeVal(selected);
    form.resetFields();
    setError(false);
  }, [random]);

  const onChange = (value) => {
    form.resetFields();
    setError(false);
    setSeletItems(value);
    onChangeVal(value);
  };
  const onFinish = ({ email }) => {
    // console.log("Success:", values);
    if (!items.includes(email)) {
      setItems([...items, email || `New item ${index++}`]);
      onChangeVal([...seletItems, email]);
      setSeletItems([...seletItems, email]);
      setTimeout(() => {
        inputRef.current?.focus();
        form.resetFields();
      }, 0);
    } else {
      setError("Email Already Exist!");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      <div className=" row align-item-center">
        <div className="col-10">
          <Select
            className="w-100 my-2"
            mode="multiple"
            allowClear
            style={{
              width: 700,
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 8) {
                setSeletItems([...seletItems]);
              }
            }}
            value={seletItems}
            onChange={onChange}
            placeholder="Please Select Email"
            dropdownRender={(menu) => <>{menu}</>}
            options={items.map((item) => ({
              label: item,
              value: item,
            }))}
          />
        </div>
        {<div className="col-2 d-flex align-items-center justify-content-center">
          <AppCheckbox label={<span className="text-success fw-bold fs-7">Deliver</span>} handleChange={(_, value) => { setValues({ ...values, is_checkbox: value }) }} checked={values?.is_checkbox} />
        </div>}
      </div>

      <Form
        form={form}
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        onChange={() => setError(false)}
      >
        <Form.Item
          name="email"
          type="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please Enter Valid Email!",
            },
          ]}
        >
          <div className="d-flex align-items-center gap-2 w-75">
            <Input placeholder="Enter Email" />{" "}
            <Button type="text" htmlType="submit" >
              <PlusOutlined /> Add Email
            </Button>
          </div>
        </Form.Item>
        <Space>
          {error && (
            <span
              style={{ color: "red", display: "block", marginTop: "-20px" }}
            >
              {error}
            </span>
          )}{" "}
        </Space>
        <Space> </Space>
      </Form>
    </>
  );
};
