import { each, groupBy } from "@antv/util";
import { FiFilter, FiRotateCw } from "react-icons/fi";
import { useEffect, useState } from "react";
import { Button, Alert, Card, Empty, Input, Col, Row, Radio } from "antd";
import { Excel } from "antd-table-saveas-excel";
import { useSelector } from "react-redux";
import AppSpinner from "../../../../Components/AppSpinner/AppSpinner";
import { FiRefreshCcw } from "react-icons/fi";
import { scrollComp } from "../../../../Controllers/Functions";
import SearchColumns from "../../../../Components/SearchColumns";
import "../../Reports.css";
import { DatePicker } from "antd";
import { useEmployeeOrdersTaxStatusListMutation } from "../../../../Redux/Services/ReportsListApi";
import { FaSearch, FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import AppTable from "../../../../Components/AppTable/AppTable";
import UserTypesData from "../../OrderStatusReport/UserTypesData";
import TaxExpandableTable from "./TaxStatusExpandableTable";
import * as XLSX from "xlsx";
import moment from "moment-business-days";
const { RangePicker } = DatePicker;

export const EmployeeTaxStatusReport = ({ refresh, childTab }) => {
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const { userPrdefault } = useSelector((state) => state?.userTypeList);
  const [userTypeId, setUserTypeId] = useState(userPrdefault?.taxStatus || []);
  const [optionKey, setOptionKey] = useState(1);
  const [options, setOptions] = useState([
    {
      id:1,
      label:"Completed Date",
      value:1
    },
    {
      id:2,
      label:"Delivered Date",
      value:0
    },
  ]);
  const OnradiohandleChange = (e) => {
    setOptionKey(e.target.value);
    EmployeeordersTaxStatusList({
      data: {
        is_completed:e.target.value,
        start_date: selectedDateRange[0]?.format("YYYY-MM-DD"),
        end_date: selectedDateRange[1]?.format("YYYY-MM-DD"),
        userTypeId,
      },
      token: token,
    });
  };
  //set  ascend or descend sorter state
  const [getsortorder, setSortOrder] = useState(false);
  const [getsortfield, setSortField] = useState("");
  const [getError, setGetError] = useState(false);
  const [totalCount, setTotalCount] = useState([]);
  const [autoTotalCount, setAutoTotalCount] = useState([]);
  //calling redux stor for vendors list
  const { token } = useSelector((state) => state.auth);

  const [EmployeeordersTaxStatusList, EmployeeOrdersTaxStatusListResult] =
    useEmployeeOrdersTaxStatusListMutation();
  useEffect(() => {
    if (EmployeeOrdersTaxStatusListResult.isSuccess) {
      const newData = EmployeeOrdersTaxStatusListResult.data?.data?.map(
        ({
          id,
          examiner_id,
          Employee_FirstName,
          Employee_LastName,
          Open,
          Pending,
          InActive,
          Completed,
          TotalOrders,
          ...others
        }) => {
          return {
            ...others,
            id,
            key: id,
            examiner_id: examiner_id,
            Employee_FirstName: Employee_FirstName,
            Employee_LastName: Employee_LastName,
            Open: Open,
            Pending: Pending,
            InActive: InActive,
            Completed: Completed,
            TotalOrders: TotalOrders,
          };
        }
      );
      const newCountData = EmployeeOrdersTaxStatusListResult.data?.total?.map(
        ({
          Total_orders,
          Completed_orders,
          Delivered_count,

          ...others
        }) => {
          return {
            ...others,
            Total_orders: Total_orders,
            Delivered_count: Delivered_count,
            Completed_orders: Completed_orders,
          };
        }
      );
      const newAutoCountData =
        EmployeeOrdersTaxStatusListResult.data?.automation_total?.map(
          ({
            Total_orders,
            Completed_orders,
            Delivered_count,

            ...others
          }) => {
            return {
              ...others,
              Total_orders: Total_orders,
              Delivered_count: Delivered_count,
              Completed_orders: Completed_orders,
            };
          }
        );
      setData(newData);
      setTotalCount(newCountData);
      setAutoTotalCount(newAutoCountData);
    } else {
      setData([]);
      setTotalCount([]);
      setAutoTotalCount([]);
    }
  }, [EmployeeOrdersTaxStatusListResult.isLoading]);

  //for radio & main tab
  useEffect(() => {
    setSearch("");
    if (refresh === "employee") {
      EmployeeordersTaxStatusList({
        data: {
          is_completed:optionKey,
          start_date: selectedDateRange[0]?.format("YYYY-MM-DD"),
          end_date: selectedDateRange[1]?.format("YYYY-MM-DD"),
          userTypeId,
        },
        token: token,
      });
    }
  }, [refresh]);



  //date handler
  function handleDateRangeChange(dates) {
    setSelectedDateRange(dates);
    EmployeeordersTaxStatusList({
      data: {
        is_completed:optionKey,
        start_date: dates[0]?.format("YYYY-MM-DD"),
        end_date: dates[1]?.format("YYYY-MM-DD"),
        userTypeId,
      },
      token: token,
    });
  }

  const Excelcolumns = [
    {
      title: `Examiner Id`,
      dataIndex: "examiner_id",
      key: "examiner_id",
    },
    {
      title: `Employee FirstName`,
      dataIndex: "Employee_FirstName",
      key: "Employee_FirstName",
    },
    {
      title: "Employee LastName",
      dataIndex: "Employee_LastName",
      key: "Employee_LastName",
    },
    {
      title: "Completed",
      dataIndex: "Completed",
      key: "Completed",
    },
    {
      title: "Delivered",
      dataIndex: "delivered",
      key: "delivered",
    },
    {
      title: "TotalOrders",
      dataIndex: "TotalOrders",
      key: "TotalOrders",
    },
    {
      title: "file_info",
      dataIndex: "file_info",
      key: "file_info",
      // render: (file_info) => file_info?.map((info)=>`${info?.file_number}`).join('\n'),
    },
    // {
    // 	title: "File Number",
    // 	dataIndex: "file_number",
    // 	key: "file_number",
    // },
  ];

  const filtercolumns = Excelcolumns.filter((column) => {
    const dataIndex = column.dataIndex;
    return data?.every((item) => item[dataIndex] !== null);
  });

  useEffect(() => {
    //request
    if (childTab === "TaxStatus") {
      setGetError(false);
    }
  }, [childTab]);

  const ExportExcelButton = () => {
    const flatData = data?.map((item) => {
      const dynamicData = {};
      Excelcolumns?.forEach((column) => {
        if (column.dataIndex === "file_info") {
          const file_info = item[column?.dataIndex];
          if (file_info && file_info.length > 0) {
            const file_number = file_info
              ?.map((info) => info?.file_number)
              .join(", ");
            dynamicData["file_number"] = file_number;
            dynamicData["examiner_id"] = item
              ? item[column.dataIndex][0]?.examiner_id
              : "";
          } else {
            dynamicData["file_number"] = "";
            dynamicData["examiner_id"] = "";
          }
        } else {
          dynamicData[column.dataIndex] = item[column.dataIndex] || "";
        }
      });
      return dynamicData;
    });

    const ws = XLSX.utils.json_to_sheet(flatData, {
      header: Object.keys(flatData[0]),
    });
    Object.keys(ws)?.forEach((cellIndex) => {
      const cell = ws[cellIndex];
      if (cell && cell.t === "s" && cell.v.includes("|")) {
        cell.v = cell.v.replace(/\|/g, "<br/>");
        console.log(cell.v);
        cell.s = { allignment: { wrapText: true } };
      }
    });

    //   const maxFileNoLength=Math.max(...data?.map((item)=>item.file_info[0]?.file_number.length));
    //  const fileNoColumnWidth=Math.max(20,maxFileNoLength*5)
    //   ws["!cols"]=[
    //    {wch:20}, {wch:20}, {wch:20}, {wch:20}, {wch:20}, {wch:20}, {wch:fileNoColumnWidth},
    //    console.log(maxFileNoLength)
    //   ]
    //   ws["!rows"]=Array(maxFileNoLength).fill({hpt:30})
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "sheet 1");
    XLSX.writeFile(wb, "reports.xlsx");
  };

  const columns = [
    {
      title: (
        <span>
          Employee FirstName{" "}
          {getsortfield === "Employee_FirstName" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" &&
            getsortfield === "Employee_FirstName" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" &&
            getsortfield === "Employee_FirstName" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "Employee_FirstName",
      key: "Employee_FirstName",
      sorter: (a, b) =>
        a.Employee_FirstName.localeCompare(b.Employee_FirstName),
      width: 200,
      align: "center",
      ...SearchColumns("Employee_FirstName", "Employee First Name"),
    },
    {
      title: (
        <span>
          Employee LastName{" "}
          {getsortfield === "Employee_LastName" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" &&
            getsortfield === "Employee_LastName" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" &&
            getsortfield === "Employee_LastName" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "Employee_LastName",
      key: "Employee_LastName",
      sorter: (a, b) =>
        a?.Employee_LastName?.localeCompare(b?.Employee_LastName),
      width: 200,
      align: "center",
      ...SearchColumns("Employee_LastName", "Employee LastName"),
    },

    ...(optionKey === 1 ? [
      {
        title: (
          <span>
            Completed{" "}
            {getsortfield === "Completed" && !getsortorder ? (
              <FaSortAmountUp />
            ) : getsortorder === "ascend" && getsortfield === "Completed" ? (
              <span className="sort-svg">
                <FaSortAmountUp className="text-info" />
              </span>
            ) : getsortorder === "descend" && getsortfield === "Completed" ? (
              <span className="sort-svg">
                <FaSortAmountDown className="text-info" />
              </span>
            ) : (
              <FaSortAmountUp />
            )}
          </span>
        ),
        dataIndex: "Completed",
        key: "Completed",
        sorter: (a, b) => a?.Completed - b?.Completed,
        width: 200,
        align: "center",
        ...SearchColumns("Completed", "Completed"),
      }]:
      [{
        title: (
          <span>
            Delivered{" "}
            {getsortfield === "delivered" && !getsortorder ? (
              <FaSortAmountUp />
            ) : getsortorder === "ascend" && getsortfield === "delivered" ? (
              <span className="sort-svg">
                <FaSortAmountUp className="text-info" />
              </span>
            ) : getsortorder === "descend" && getsortfield === "delivered" ? (
              <span className="sort-svg">
                <FaSortAmountDown className="text-info" />
              </span>
            ) : (
              <FaSortAmountUp />
            )}
          </span>
        ),
        dataIndex: "delivered",
        key: "delivered",
        sorter: (a, b) => a?.delivered - b?.delivered,
        width: 200,
        align: "center",
        ...SearchColumns("delivered", "delivered"),
      }]),
    {
      title: (
        <span>
          TotalOrders{" "}
          {getsortfield === "TotalOrders" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "TotalOrders" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" && getsortfield === "TotalOrders" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "TotalOrders",
      key: "TotalOrders",
      sorter: (a, b) => a?.TotalOrders - b?.TotalOrders,
      width: 200,
      align: "center",
      ...SearchColumns("TotalOrders", "TotalOrders"),
    },
  ];

  //function

  //function
  const sort = (sortOrder, sortfield) => {
    // console.log(sortOrder, sortfield);
    if (sortOrder) {
      setSortOrder(sortOrder);
      setSortField(sortfield);
    } else {
      setSortOrder(false);
    }
  };

  //error
  useEffect(() => {
    if (getError) {
      scrollComp("EmployeeOdersStatusReportError");
    }
  }, [getError]);

  const handleClick = () => {
    const excel = new Excel();
    excel
      .addSheet("test")
      .addColumns(filtercolumns)
      .addDataSource(data, {
        str2Percent: true,
      })
      .saveAs("OrderStatusReport.xlsx");
  };
  const defaultExpandable = {
    expandedRowRender: (ele) => {
      return (
        <div className="gray-table-col ">
          <TaxExpandableTable optionKey={optionKey} search={search} TaxExpandData={ele?.file_info} />
        </div>
      );
    },
  };

  const handleChange = (value) => {
    setUserTypeId(value);
    EmployeeordersTaxStatusList({
      data: {
        is_completed:optionKey,
        start_date: selectedDateRange[0]?.format("YYYY-MM-DD"),
        end_date: selectedDateRange[1]?.format("YYYY-MM-DD"),
        userTypeId:value,
      },
      token: token,
    });
  };

  function rowClassNameTableSuccess(record) {
    return record &&
      (record?.Employee_FirstName === "Sysyem" ||
        record?.Employee_LastName === "Admin") &&
      data?.length > 0
      ? "totalRow-success"
      :     (record?.Employee_FirstName === "Unassigned" &&
      record?.Employee_LastName === "User") &&
    data?.length > 0? "totalRow-unassinged-count":""
  }

  const disabledDate = (current) => {
		return current && current > moment().endOf("day");
	  };


  return (
    <>
      <div id="OdersStatusReportError">
        {getError && <Alert message={getError} type="error" showIcon />}
      </div>
      {EmployeeOrdersTaxStatusListResult.isLoading && <AppSpinner />}
      <div className="d-flex align-items-center  mb-1 gap-2 justify-content-end">
        {/* <UserTypesData onChange={handleChange} value={userTypeId} /> */}
        <>
          <div
            className="file-icons"
            data-toggle="tooltip"
            title="Excel Export"
          >
            <Button
              size="small"
              type="primary"
              onClick={ExportExcelButton}
              disabled={data?.length === 0}
            >
              Export Data
            </Button>
          </div>
          <div className=" me-1 w-25 ">
            <Input
              placeholder="Search here"
              className="global-search "
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              prefix={<FaSearch color="lightgray" />}
            />
          </div>

          <div className="filter-button "></div>
          <FiRefreshCcw
            type="button"
            id="template"
            onClick={() => {
              setSelectedDateRange([]);
              setUserTypeId([])
            }}
            title="Refresh"
            size={20}
          />
        </>
      </div>
      <div style={{ clear: "both" }}></div>
      <div className="p-2 bg-white border shadow-sm mb-2">
        <div className="my-2  d-flex align-items-center ">
          <div className="row w-100 ">
            <UserTypesData onChange={handleChange} value={userTypeId} />
          </div>
          <div className="container"style={{width:"30rem",marginLeft:0}}>
          <div className="row">
            <div className="col-lg-12 mr-2 d-flex flex-row">
            <Radio.Group onChange={OnradiohandleChange} value={optionKey} className="d-flex flex-row ">
            { 
               options?.map((ele) => {
                return (
                  <Radio key={ele.id} value={ele.value} defaultChecked>
                    {ele.label}
                  </Radio>
                )})}
                  
          </Radio.Group>
            </div>
            </div>
            <div className="row mt-1">
              <div className="col-lg-12 d-flex flex-column">
              <RangePicker
                value={selectedDateRange}
                format={"YYYY-MM-DD"}
                onChange={handleDateRangeChange}
                allowClear={false}
                disabledDate={disabledDate}
              />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ clear: "both" }}></div>
      <AppTable
        sort={sort}
        //  loading={ordersListApiResult.isLoading || statusColorApi.isFetching}
        search={search}
        columns={columns}
        dataSource={data}
        rowClassName={data?.length > 0 ? rowClassNameTableSuccess : ""}
        expandable={defaultExpandable}
        defaultExpandable
      />
      <Row>
        <Col className="col-lg-4 total-count-row">
          <Card className="d-flex m-1 total-count-cards-report">
            <Row className="text-center p-3 ">
              <Col className="col align-item-center text-center mx-1">
                {"Total Delivered Orders"}
              </Col>
              <Col className="col align-item-center text-center px-5 mx-1">
                {(totalCount && totalCount[0]?.total_Delivered_orders) || 0}
              </Col>
            </Row>
          </Card>
        </Col>
        <Col className="col-lg-4 total-count-row">
          <Card className="d-flex m-1 total-count-cards-report">
            <Row className="text-center p-3 ">
              <Col className="col align-item-center text-center mx-1">
                {"Manual Completed Orders"}
              </Col>
              <Col className="col align-item-center text-center px-5 mx-1">
                {(totalCount && totalCount[0]?.manual_completed_orders) || 0}
              </Col>
            </Row>
          </Card>
        </Col>
        <Col className="col-lg-4 total-count-row">
          <Card className="d-flex m-1 total-count-cards-report">
            <Row className="text-center p-3 ">
              <Col className="col align-item-center text-center mx-1">
                {"Automatic Completed Orders"}
              </Col>
              <Col className="col align-item-center text-center px-5 mx-1">
                {(totalCount && totalCount[0]?.automation_completed_orders) || 0}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
