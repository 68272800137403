import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json")
export const cycles = createApi({
  reducerPath: "cycles",
  tagTypes: ["cycles"],
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  endpoints: (builder) => ({
    addCycle: builder.mutation({
      query: (data) => ({
        url: `/add-cycle`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["cycles"],
    }),
    cycleList: builder.query({
      query: (token) => ({
        url: `/cycle-list`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ["cycles"],
    }),
    cycleListPost: builder.mutation({
      query: (token) => ({
        url: `/cycle-list`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ["cycles"],
    }),

    editCycle: builder.query({
      query: (data) => ({
        url: `/edit-cycle/${data.id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      providesTags: ["cycles"],
    }),
    updateCycle: builder.mutation({
      query: (data) => ({
        url: `/update-cycle/${data.id}`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["cycles"],
    }),
    deleteCycleType: builder.mutation({
      query: (data) => ({
         url: `/delete-cycle/${data.id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["cycles"],
    }),
  }),
});

export const {
    useAddCycleMutation,
    useCycleListQuery,
    useUpdateCycleMutation,
    useEditCycleQuery,
    useDeleteCycleTypeMutation,
    useCycleListPostMutation
} = cycles;
