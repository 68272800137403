import React, { useState } from "react";
import { FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import NormalTable from "../../../Components/AppTable/AppTable";
import SearchColumns from "../../../Components/SearchColumns";
import { Tabs, Tag } from "antd";
import "../Reports.css";
const { TabPane } = Tabs;
export default function PaymentLogs({
  data = [],
  manualLogData = [],
  title = "",
  loading,
  rowClassName,
  activeTab,
  setActiveTab,
  // defaultExpandAllRows=false,
  isSubmit = false,
}) {
  //set  ascend or descend sorter state
  const [getsortorder, setSortOrder] = useState(false);
  const [getsortfield, setSortField] = useState("");
  //create function for settings tabs
  const sort = (sortOrder, sortfield) => {
    // console.log(sortOrder, sortfield);
    if (sortOrder) {
      setSortOrder(sortOrder);
      setSortField(sortfield);
    } else {
      setSortOrder(false);
    }
  };

  const columns = [
    {
      title: (
        <span>
          Customer Name{" "}
          {getsortfield === "customer_name" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "customer_name" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" && getsortfield === "customer_name" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "customer_name",
      activeTab: "customer_name",
      sorter: (a, b) => a.customer_name.localeCompare(b.customer_name),
      width: 200,
      align: "center",
      ...SearchColumns("customer_name", "Customer Name"),
    },
    {
      title: <span>Total Count</span>,
      dataIndex: "total_records",
      activeTab: "total_records",
      // sorter: (a, b) => a.total_recordslocaleCompare(b.total_records),
      width: 200,
      align: "center",
      ...SearchColumns("total_records", "Total Count"),
    },
    {
      title: <span>Accepted Count</span>,
      dataIndex: "accepted_count",
      key: "accepted_count",
      //sorter: (a, b) => a.accepted_count.localeCompare(b.accepted_count),
      width: 200,
      align: "center",
      ...SearchColumns("accepted_count", "Accepted Count"),
    },
    {
      title: <span>Rejected Count</span>,
      dataIndex: "rejected_count",
      key: "rejected_count",
      // sorter: (a, b) => a.rejected_count.localeCompare(b.rejected_count),
      width: 200,
      align: "center",
      ...SearchColumns("rejected_count", "Rejected Count"),
    },
    {
      title: <span>Document Name</span>,
      dataIndex: "document_name",
      key: "document_name",
      // sorter: (a, b) => a.document_name.localeCompare(b.document_name),
      width: 200,
      align: "center",
      ...SearchColumns("document_name", "Document Name"),
    },
    {
      title: <span>Uploaded Date</span>,
      dataIndex: "uploaded_date",
      key: "uploaded_date",
      //sorter: (a, b) => a.uploaded_date.localeCompare(b.uploaded_date),
      width: 200,
      align: "center",
      ...SearchColumns("uploaded_date", "Uploaded Date"),
    },

    {
      title: <span>Uploaded By</span>,
      dataIndex: "uploaded_by",
      key: "uploaded_by",
      align: "center",
      width: 200,
    },
  ];

  const columnsManualLog = [
    {
      title: (
        <span>
          Customer Name{" "}
          {getsortfield === "customer_name" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "customer_name" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" && getsortfield === "customer_name" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "customer_name",
      key: "customer_name",
      sorter: (a, b) => a.customer_name.localeCompare(b.customer_name),
      width: 200,
      align: "center",
      ...SearchColumns("customer_name", "Customer Name"),
    },
    {
      title: <span>Total Count</span>,
      dataIndex: "total_records",
      key: "total_records",
      // sorter: (a, b) => a.total_recordslocaleCompare(b.total_records),
      width: 200,
      align: "center",
      ...SearchColumns("total_records", "Total Count"),
    },
    {
      title: <span>Accepted Count</span>,
      dataIndex: "accepted_count",
      key: "accepted_count",
      //sorter: (a, b) => a.accepted_count.localeCompare(b.accepted_count),
      width: 200,
      align: "center",
      ...SearchColumns("accepted_count", "Accepted Count"),
    },
    {
      title: <span>Rejected Count</span>,
      dataIndex: "rejected_count",
      key: "rejected_count",
      // sorter: (a, b) => a.rejected_count.localeCompare(b.rejected_count),
      width: 200,
      align: "center",
      ...SearchColumns("rejected_count", "Rejected Count"),
    },
    {
      title: <span>Uploaded Date</span>,
      dataIndex: "uploaded_date",
      key: "uploaded_date",
      //sorter: (a, b) => a.uploaded_date.localeCompare(b.uploaded_date),
      width: 200,
      align: "center",
      ...SearchColumns("uploaded_date", "Uploaded Date"),
    },

    {
      title: <span>Uploaded By</span>,
      dataIndex: "uploaded_by",
      key: "uploaded_by",
      align: "center",
      width: 200,
    },
  ];

  const RejectListColumn=[
    {
      title:"GF Number",
      dataIndex:'gf_no',
      key:'gf_no',
      align: "center",
      width: 100,
      // render:(value)=><
  },
  {
    title:"Reference Number",
    dataIndex:'ref_no',
    key:'ref_no',
    align: "center",
    width: 100,
},
  ]

  const defaultExpandable = {
    expandedRowRender: (ele) => {
      return (
        <>
          <div className="gray-table-col border p-1 m-1 ">
          
            <pre className="log-message-pre">
              Upload could fail due to the following reasons: <br />
              1.Incorrect file numbers  2.Payment status mismatch  3.Amount
              discrepancy
            </pre>
            <div className="row">
              <div className="col-12 col-lg-6">
              <h6 className="text-primary m-0 bg-light d-inline-block p-2">
              Rejected GF-Numbers
            </h6>
            <div style={{
                maxHeight:"200px", overflowY:"auto", overflowX:"auto"
              }} className="accept-log">

            {ele?.rejected?.length !== 0 &&(
              <table className="logTable">
            <thead style={{position:"sticky", top:0}}>
              <tr>
                <th>GF Number</th>
                <th>Reference Number</th>
                <th>Payment Type</th>
                <th>Payment Date</th>
              </tr>
            </thead>
            <tbody>
             {
              ele?.rejected?.map((info,index)=>{
                return(
                  <tr key={index}>
                  <td><Tag color="red">{info?.gf_no}</Tag></td>
                  <td>{info?.ref_no}</td>
                  <td>{info?.payment_type}</td>
                  <td>{info?.paid_date}</td>
                  </tr>
                )
              })
             }
            </tbody>
              </table>
            )
              }

            </div>
            
              </div>
              <div className="col-12 col-lg-6">
              <h6 className="text-primary m-0 bg-light d-inline-block p-2">
              Accepted GF-Numbers
            </h6><div style={{
                maxHeight:"200px", overflow:"auto"
              }}>

            {ele?.accepted?.length !== 0 &&(
              <table className="logTable">
            <thead style={{position:"sticky", top:0}}>
              <tr>
                <th>GF Number</th>
                <th>Reference Number</th>
                <th>Payment Type</th>
                <th>Payment Date</th>
              </tr>
            </thead>
            <tbody>
             {
              ele?.accepted?.map((info,index)=>{
                return(
                  <tr key={index}>
                  <td><Tag color="green">{info?.gf_no}</Tag></td>
                  <td>{info?.ref_no}</td>
                  <td>{info?.payment_type}</td>
                  <td>{info?.paid_date}</td>
                  
                  </tr>
                )
              })
             }
            </tbody>
              </table>
            )
              }

            </div>
            
              </div>
          </div>
          </div>
          {/* <div className="gray-table-col border p-1 m-1">
            <h6 className="text-primary m-0 bg-light d-inline-block p-2 ">
              Accepted GF-Numbers
            </h6>
            <br />
            {ele?.accepted_orders?.length !== 0 &&
              // ele?.accepted_orders?.map((info) => {
              //   return (
              //     <Tag color="green" className="mb-1" key={info}>
              //       {info}
              //     </Tag>
              //   );
              // })
               <AppTable columns={RejectListColumn} dataSource={acceptGfList}/>
              // console.log(ele?.accepted_orders)
              }
          </div> */}
        </>
      );
    },
  };
  const TabChange = (key) => {
    setActiveTab(key);
  };

  return (
    <>
      <div className="d-flex align-items-center gap-1 mb-2 history-tabble-tabs">
        <h6 className="text-primary m-0 bg-light d-inline-block p-2 rounded-3 shadow-sm border">
          Payment History
        </h6>
      </div>
      <Tabs
        activeKey={activeTab}
        defaultActiveKey="1"
        className="title-container app-tab payment-tabs"
        onChange={TabChange}
        key={activeTab}
      >
        {
          <TabPane tab="Document Upload History" key={"1"}>
            <NormalTable
              loading={loading}
              sort={sort}
              activeState={false}
              dataSource={data}
              columns={columns}
              expandable={defaultExpandable}
              defaultExpandable
              scroll={{ x: true }}
              rowClassName={rowClassName}
              pageSizeOptions={[5, 10, 20, 50, 100, 200, 500]}
              defaultPageSize={20}
              defaultExpandAllRows={isSubmit}
            />
            {/* <MultipleTable/> */}
          </TabPane>
        }
        {
          <TabPane tab="Manual Payment History" key={"2"}>
            <NormalTable
              loading={loading}
              sort={sort}
              activeState={false}
              dataSource={manualLogData}
              columns={columnsManualLog}
              expandable={defaultExpandable}
              defaultExpandable
              scroll={{ x: true }}
              rowClassName={rowClassName}
              pageSizeOptions={[5, 10, 20, 50, 100, 200, 500]}
              defaultPageSize={20}
              defaultExpandAllRows={isSubmit && true}
            />
          </TabPane>
        }
      </Tabs>
    </>
  );
}
