import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json");
export const ReportsListApi = createApi({
	reducerPath: "reports",
	tagTypes: ["reports"],
	baseQuery: fetchBaseQuery({ baseUrl: config.url }),
	endpoints: (builder) => ({
		orderReportFilterList: builder.mutation({
			query: (data) => ({
				url: `/order-reports-filter`,
				mode: "cors",
				method: "POST",
				body: data.data,
				headers: { Authorization: `Bearer ${data.token}` },
			}),
			invalidatesTags: ["reports"],
		}),
		invoiceReportList: builder.query({
			query: (token) => ({
				url: `/invoice-report`,
				method: "GET",
				mode: "cors",
				headers: { Authorization: `Bearer ${token}` },
			}),
			providesTags: ["reports"],
		}),
		invoiceReportFilterList: builder.mutation({
			query: (data) => ({
				url: `/invoice-report-filter`,
				mode: "cors",
				method: "POST",
				body: data.data,
				headers: { Authorization: `Bearer ${data.token}` },
			}),
			invalidatesTags: ["reports"],
		}),
		ordersTaxStatusList: builder.mutation({
			query: (data) => ({
				url: `/employee-tax-order-status`,
				mode: "cors",
				method: "POST",
				body: data.data,
				headers: { Authorization: `Bearer ${data.token}` },
			}),
			invalidatesTags: ["reports"],
		}),
		ordersHoaStatusList: builder.mutation({
			query: (data) => ({
				url: `/employee-hoa-order-status`,
				mode: "cors",
				method: "POST",
				body: data.data,
				headers: { Authorization: `Bearer ${data.token}` },
			}),
			invalidatesTags: ["reports"],
		}),
		employeeOrdersTaxStatusList: builder.mutation({
			query: (data) => ({
				url: `/employee-tax-status-count`,
				mode: "cors",
				method: "POST",
				body: data.data,
				headers: { Authorization: `Bearer ${data.token}` },
			}),
			invalidatesTags: ["reports"],
		}),
		employeeOrdersHoaStatusList: builder.mutation({
			query: (data) => ({
				url: `/employee-hoa-status-count`,
				mode: "cors",
				method: "POST",
				body: data.data,
				headers: { Authorization: `Bearer ${data.token}` },
			}),
			invalidatesTags: ["reports"],
		}),
		getPreDefaultUserTypes: builder.query({
			query: (data) => ({
				url: `/employee-user-type-config-list/${data?.defualtType}`,
				mode: "cors",
				method: "GET",
				headers: { Authorization: `Bearer ${data.token}` },
			}),
		}),
		employeeListByCustomer: builder.mutation({
			query: (data) => ({
			  url: `/customerwise-employee-list/${data.id}`,
			  mode: "cors",
			  method: "GET",
			  headers: { Authorization: `Bearer ${data.token}` },
			}),
		  }),
		  paymentInvoiceList: builder.mutation({
			query: (data) => ({
				url: `/payment_status_list`,
				mode: "cors",
				method: "POST",
				body: data.data,
				headers: { Authorization: `Bearer ${data.token}` },
			}),
			invalidatesTags: ["reports"],
		}),
		// editPaymentInvoice: builder.mutation({
		// 	query: (data) => ({
		// 		url: `/edit_payment_status`,
		// 		mode: "cors",
		// 		method: "POST",
		// 		body: data.data,
		// 		headers: { Authorization: `Bearer ${data.token}` },
		// 	}),
		// 	invalidatesTags: ["reports"],
		// }),
		editPaymentInvoice: builder.query({
			query: (data) => ({
			   url: `/edit_payment_status/${data.id}`,
			  method: "GET",
			  mode: "cors",
			  headers: { Authorization: `Bearer ${data.token}` },
			}),
			invalidatesTags: ["reports"],
		  }),
		  updatePaymentInvoice: builder.mutation({
			query: (data) => ({
			    url: `/update_payment_data/${data.id}`,
			  method: "POST",
			  mode: "cors",
			  body: data.data,
			  headers: { Authorization: `Bearer ${data.token}` },
			}),
			invalidatesTags: ["reports"],
		  }),
		  paymentReportLogsByCustomer: builder.query({
			query: (token) => ({
				url: `/payment_upload_details`,
				method: "GET",
				mode: "cors",
				headers: { Authorization: `Bearer ${token}` },
			}),
			 providesTags: ["reports"],
		}),
		addPaymentList: builder.mutation({
			query: (data) => (
			  {
				url: `/upload_payments/${data.id}`,
				method: "POST",
				mode: "cors",
				body: data.data,
				headers: { Authorization: `Bearer ${data.token}` },
			  }
			),
			invalidatesTags: ["reports"],
		  }),
		  addBulkPayments: builder.mutation({
			query: (data) => ({
			    url: `/bulk_payments_update`,
			  method: "POST",
			  mode: "cors",
			  body: data.data,
			  headers: { Authorization: `Bearer ${data.token}` },
			}),
			invalidatesTags: ["reports"],
		  }),
		  paymentModes: builder.query({
			query: (token) => ({
				url: `/payments-mode-list`,
				method: "GET",
				mode: "cors",
				headers: { Authorization: `Bearer ${token}` },
			}),
			providesTags: ["reports"],
		}),
	}),
});

export const {
	useOrderReportFilterListMutation,
	useInvoiceReportListQuery,
	useInvoiceReportFilterListMutation,
	useOrdersHoaStatusListMutation,
	useOrdersTaxStatusListMutation,
	useEmployeeOrdersHoaStatusListMutation,
	useEmployeeOrdersTaxStatusListMutation,
	useGetPreDefaultUserTypesQuery,
	useEmployeeListByCustomerMutation,
	usePaymentInvoiceListMutation,
	 useEditPaymentInvoiceQuery,
	useUpdatePaymentInvoiceMutation,
	 usePaymentReportLogsByCustomerQuery,
	useAddPaymentListMutation,
	useAddBulkPaymentsMutation,
	usePaymentModesQuery
} = ReportsListApi;