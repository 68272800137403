import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json");
export const status = createApi({
  reducerPath: "status",
  tagTypes: ["status"],
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  endpoints: (builder) => ({
    statusList: builder.query({
      query: (token) => ({
        url: `/status-list`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ["status"],
    }),
    addNewStatus: builder.mutation({
      query: (data) => ({
        url: `/add-status`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["status"],
    }),
    editStatus: builder.query({
      query: (data) => ({
        url: `/edit-status/${data.id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["status"],
    }),
    updateStatus: builder.mutation({
      query: (data) => ({
        url: `/update-status/${data.id}`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["status"],
    }),
    orderStatus: builder.query({
      query: (token) => ({
        url: `/order-status-list`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
        providesTags: ["status"],
    }),
    orderStatusListPost: builder.mutation({
      query: (token) => ({
        url: `/order-status-list`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    
    addNewOrderStatus: builder.mutation({
      query: (data) => ({
        url: `/add-order-status`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["status"],
    }),
    editOrderStatus: builder.query({
      query: (data) => ({
        url: `/edit-order-status/${data.id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["status"],
    }),
    updateOrderStatus: builder.mutation({
      query: (data) => ({
        url: `/update-order-status_name/${data.id}`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["status"],
    }),

  }),
});

export const {
  useStatusListQuery,
  useEditStatusQuery,
  useAddNewStatusMutation,
  useUpdateStatusMutation,
  useOrderStatusQuery,
  useAddNewOrderStatusMutation,
  useUpdateOrderStatusMutation,
  useEditOrderStatusQuery,
  useOrderStatusListPostMutation
} = status;
