import { createSlice } from "@reduxjs/toolkit";

const userTypeList = createSlice({
  name: "userTypeList",
  initialState: {
    userTypeList: [],
    userPrdefault: { taxStatus: [], hoaStatus: [] }
  },
  reducers: {
    userTypeListAction: (state, action) => {
      state.userTypeList = action.payload;
    },
    userPrdefaulTaxStatustAction: (state, action) => {
      state.userPrdefault = { ...state.userPrdefault, taxStatus: action.payload }
    },
    userPrdefaulHoaStatustAction: (state, action) => {
      state.userPrdefault = { ...state.userPrdefault, hoaStatus: action.payload }
    }
  },
});

export const { userTypeListAction, userPrdefaulHoaStatustAction, userPrdefaulTaxStatustAction } = userTypeList.actions;

export default userTypeList.reducer;
