import { createSlice } from "@reduxjs/toolkit";
import { act } from "@testing-library/react";

const flagRoles = createSlice({
	name: "flagRoles",
	initialState: {
		authRoles: [],
		tabs: [],
		settingsModule: [],
		userRoutes: [],
		add_button: [],
		vendorModule: [],
		customerModule: [],
		branchModule: [],
		usersModule: [],
		typeModule: [],
		userTypeModule: [],
		tableColumn: [],
		labelModule: [],
		hoaMgmtModule: [],
		hoaModule: [],
		taxCollectorModule: [],
		jurisdictionModule: [],
		parcelStatusModule: [],
		orderStatusModule: [],
		hoaStatusModule: [],
		exemptionModule: [],
		notificationModule: [],
		commentsModule: [],
		cadModule: [],
		taxcadModule: [],
		orderModule: [],
		orderListToolbar: [],
		internalOrder: [],
		parcelEditInOrder: [],
		add_labels: [],
		edit_keylabels: [],
		edit_valuelabels: [],
		settingsTabs: [],
		authColumn: [],
		fieldFlag: [],
		supportTabs: [],
		useTypeListFlag: [],
		typeTabs: [],
		toolsTabs: [],
		noticeFlag: [],
		jurisdictionTabs: [],
		reportsTabs: [],
		parcelTabs: [],
		cadTabs: [],
		taxcadTabs: [],
		orderStatusTabs: [],
		newInterNalOrder: {},
		newInterNalParcel: {},
		orderTableColumn:[],
		employeeOrderStatusTabs: [],
		subdivisionModule: []
	},
	reducers: {
		flagRolesAction: (state, action) => {
			state.authRoles = action.payload.flags;
			state.tabs = action.payload.tabs;
			state.add_button = action.payload.add_button;
			state.vendorModule = action.payload.vendorModule;
			state.customerModule = action.payload.customerModule;
			state.branchModule = action.payload.branchModule;
			state.usersModule = action.payload.usersModule;
			state.typeModule = action.payload.typeModule;
			state.userTypeModule = action.payload.userTypeModule;
			state.tableColumn = action.payload.tableColumn;
			state.labelModule = action.payload.labelModule;
			state.hoaMgmtModule = action.payload.hoaMgmtModule;
			state.hoaModule = action.payload.hoaModule;
			state.taxCollectorModule = action.payload.taxCollectorModule;
			state.jurisdictionModule = action.payload.jurisdictionModule;
			state.parcelStatusModule = action.payload.parcelStatusModule;
			state.orderStatusModule = action.payload.orderStatusModule;
			state.hoaStatusModule = action.payload.hoaStatusModule;
			state.exemptionModule = action.payload.exemptionModule;
			state.notificationModule = action.payload.notificationModule;
			state.commentsModule = action.payload.commentsModule;
			state.cadModule = action.payload.cadModule;
			state.taxcadModule = action.payload.taxcadModule;
			state.orderModule = action.payload.orderModule;
			state.orderListToolbar = action.payload.orderListToolbar;
			state.internalOrder = action.payload.internalOrder;
			state.parcelEditInOrder = action.payload.parcelEditInOrder;
			state.add_labels = action.payload.add_labels;
			state.userRoutes = action.payload.userRoutes;
			state.edit_keylabels = action.payload.edit_keylabels;
			state.edit_valuelabels = action.payload.edit_valuelabels;
			state.settingsTabs = action.payload.settingsTabs;
			state.authColumn = action.payload.authColumn;
			state.fieldFlag = action.payload.fieldFlag;
			state.supportTabs = action.payload.supportTabs;
			state.useTypeListFlag = action.payload.useTypeListFlag;
			state.typeTabs = action.payload.typeTabs;
			state.toolsTabs = action.payload.toolsTabs;
			state.jurisdictionTabs = action.payload.jurisdictionTabs;
			state.noticeFlag = action.payload.noticeFlag;
			state.reportsTabs = action.payload.reportsTabs;
			state.parcelTabs = action.payload.parcelTabs;
			state.cadTabs = action.payload.cadTabs;
			state.taxcadTabs = action.payload.taxcadTabs;
			state.newInterNalOrder = action.payload.newInterNalOrder;
			state.newInterNalParcel = action.payload.newInterNalParcel;
			state.orderStatusTabs = action.payload.orderStatusTabs;
			state.orderTableColumn =action.payload.orderTableColumn;
			state.employeeOrderStatusTabs =action.payload.employeeOrderStatusTabs;
			state.subdivisionModule = action.payload.subdivisionModule;
		},
	},
});

export const { flagRolesAction } = flagRoles.actions;

export default flagRoles.reducer;
