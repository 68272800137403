import React, { useState } from "react";
import { FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import AppTable from "../../../../Components/AppTable/AppTable";
import SearchColumns from "../../../../Components/SearchColumns";
import { usDateTimeFormat } from "../../../../Controllers/Functions";
import { dateSorted } from "../../../OrderEditor/mainOrder/DynamicContent/functions";
const HoaExpandableTable = ({ search, HoaExpandData, optionKey }) => {
  //set  ascend or descend sorter state
  const [getsortorder, setSortOrder] = useState(false);
  const [getsortfield, setSortField] = useState("");
  //function
  const sort = (sortOrder, sortfield) => {
    // console.log(sortOrder, sortfield);
    if (sortOrder) {
      setSortOrder(sortOrder);
      setSortField(sortfield);
    } else {
      setSortOrder(false);
    }
  };
  
  const columns = [
    {
      title: (
        <span>
          File Number{" "}
          {getsortfield === "file_number" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" &&
            getsortfield === "file_number" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" &&
            getsortfield === "file_number" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "file_number",
      key: "file_number",
      sorter: (a, b) =>
        a.file_number.localeCompare(b.file_number),
      width: 200,
      align: "center",
      ...SearchColumns("file_number", "File Number"),
    },
    {
      title: (
        <span>
          Created{" "}
          {getsortfield === "created_at" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "created_at" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" && getsortfield === "created_at" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "created_at",
      key: "created_at",

      ...dateSorted("created_at"),
      width: 200,

      align: "center",
      ...SearchColumns("created_at", "Created At"),
      // render: (value) => {
      //   return <>{usDateTimeFormat(value)}</>
      // },
    },
    ...(optionKey != 1 ? [{
      title: (
        <span>
          Delivered{" "}
          {getsortfield === "delivered_date" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "delivered_date" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" && getsortfield === "delivered_date" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "delivered_date",
      key: "delivered_date",

      ...dateSorted("delivered_date"),
      width: 200,

      align: "center",
      ...SearchColumns("delivered_date", "Delivered date"),
    },
    {
      title: (
        <span>
          Delivered By{" "}
          {getsortfield === "delivered_by" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "delivered_by" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" && getsortfield === "delivered_by" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "delivered_by",
      key: "delivered_by",

      sorter: (a, b) => {
        const deliveredA = a?.delivered_by || "";
        const deliveredB = b?.delivered_by || "";
        return deliveredA.localeCompare(deliveredB)
      },
      width: 200,

      align: "center",
      ...SearchColumns("delivered_by", "Delivered By"),
    }] : [{
      title: (
        <span>
          Completed{" "}
          {getsortfield === "completed_date" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "completed_date" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" && getsortfield === "completed_date" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "completed_date",
      key: "completed_date",

      ...dateSorted("completed_date"),
      width: 200,

      align: "center",
      ...SearchColumns("completed_date", "Completed date"),
    },
    {
      title: (
        <span>
          Completed By{" "}
          {getsortfield === "completed_by" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "completed_by" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" && getsortfield === "completed_by" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "completed_by",
      key: "completed_by",

      sorter: (a, b) => {
        const completedA = a?.completed_by || "";
        const completedB = b?.completed_by || "";
        return completedA.localeCompare(completedB)
      },
      width: 200,

      align: "center",
      ...SearchColumns("completed_by", "Completed By"),
    }]),
    {
      title: (
        <span>
         Order Status{" "}
          {getsortfield === "status" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "status" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" && getsortfield === "status" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "status",
      key: "status",

      sorter: (a, b) => {
        const oederStatusA=a?.status || "";
        const oederStatusB=b?.status || "";
        return oederStatusA.localeCompare(oederStatusB)},
      width: 200,

      align: "center",
      ...SearchColumns("status", "Order Status"),
    },
  ];
  return (
    <>
      <AppTable
        sort={sort}
        search={search}
        columns={columns}
        dataSource={HoaExpandData}
      />
    </>
  );
}

export default HoaExpandableTable;