import React from "react";
// import { DefaultEditor } from "react-simple-wysiwyg";
// import "./appTextEditor.css";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
export default function AppTextEditor({ value = "", onChange }) {
  return (
    <>
      <ReactQuill
        value={value}
        onChange={(value) => {
          onChange(value);
        }}
      />
    </>
  );
}
