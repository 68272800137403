import { Col, Row, Alert, notification } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import AppButton from "../../../Components/AppButton/AppButton";
import AppInput from "../../../Components/AppInput/AppInput";
import AppSwitch from "../../../Components/AppSwitch/AppSwitch";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import InputColor from "../../../Components/InputColor/InputColor";
import { convertBase64 } from "../../../Controllers/Functions";
import {
  useEditNotificationQuery,
  useUpdateNotificationMutation,
} from "../../../Redux/Services/notification";
import { AppFile } from "../../../Components/AppFile/AppFile";

export default function NotificationModal({ afterClose, setShowHide, editId }) {
  const [values, setValues] = useState({
    header: "",
    description: "",
    logo: "",
    coulou_code: "",
    is_active: "",
  });
  //status
  const [getError, setGetError] = useState(null);
  const [,setGetSuccess] = useState(null);
  //setfile Error
  const [,setFileError] = useState(null);

  //clear error function
  function clearStatus() {
    setGetError(null);
    setGetSuccess(null);
  }

  //SETdEFAULT
  useEffect(() => {
    setValues({
      header: "",
      description: "",
      logo: "",
      coulou_code: "",
      is_active: "",
    });
    setFileError(null);
  }, [afterClose]);

  const onChange = async (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });

    if (e.target?.files) {
      const file = e.target.files[0];
      //base64 covert function
      const base64 = await convertBase64(file);

      //values of file validations
      if (file.size >= 2097152) {
        setFileError("File size must be less 2mb! ");
      } else if (
        file.type !== "image/png" ||
        file.type !== "image/jpg" ||
        file.type !== "image/jpeg"
      ) {
        setFileError("File must be jpg | png | jpeg format ! ");
      }
      if (
        (file.type === "image/png" ||
          !file.type === "image/jpg" ||
          file.type === "image/jpeg") &&
        file.size <= 2097152
      ) {
        setFileError(null);
        //asign to blob to logo
        setValues({ ...values, logo: base64 });
      }
    }
  };
  const handleReset = (e) => {
    e.preventDefault();
    setValues({ ...values, colour_code: "", name: "" });
  };

  // calling redux store
  const { token, user } = useSelector((state) => state.auth);

  //calling rtk query hook for update
  const updateNotificationApi = useUpdateNotificationMutation();
  const [updateNotification] = updateNotificationApi;
  //calling rtk query hook for geting Notification
  const editNotificationApi = useEditNotificationQuery(
    { id: editId, token: token },
    { skip: !editId }
  );

  //useEffect for editNotification
  useEffect(() => {
    if (editNotificationApi.isSuccess) {
      //filter values from user branchList and customer list for initials values
      const { logo, ...rest } = editNotificationApi.data?.data[0];
      setValues({...rest,logo:atob(String(logo))});

      clearStatus();
    }
    if (editNotificationApi.isError) {
      var errorData = [];
      for (const prop in editNotificationApi[1].error.data?.error) {
        errorData.push(editNotificationApi[1].error.data?.error[prop]);
      }
      setGetError(
        errorData[0] ||
          editNotificationApi.error.data.message ||
          editNotificationApi.error.data ||
          editNotificationApi.error.error ||
          editNotificationApi.error.data?.msg.sqlMessage
      );
    }
  }, [editNotificationApi]);

  //update notification
  useEffect(() => {
    if (updateNotificationApi[1].isSuccess) {
      setShowHide(false);
      clearStatus();
      ///after submit details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: updateNotificationApi[1].data?.message,
      });
    }
    if (updateNotificationApi[1].error) {
      var errorData = [];
      for (const prop in updateNotificationApi[1].error.data?.error) {
        errorData.push(updateNotificationApi[1].error.data?.error[prop]);
      }
      setGetError(
        errorData[0] ||
          updateNotificationApi[1].error.data.message ||
          updateNotificationApi[1].error.error ||
          updateNotificationApi[1].error.data?.msg.sqlMessage
      );
    }
  }, [updateNotificationApi[1].isLoading]);

  const appSwitchHandler = (checked, value) => {
    setValues({ ...values, [checked]: value === true ? 1 : 0 });
    //claser status values
    clearStatus();
  };

  const inputs = [
    {
      id: 1,
      name: "title",
      type: "text",
      column: "4",
      // color: true,
      value: values.title || "",
      placeholder: "Enter Header",
      errorMessage: "Please enter header!",
      label: "Header",
      required: true,
    },
    {
      id: 2,
      name: "description",
      type: "text",
      column: "4 px-2",
      // color: true,
      value: values.description || "",
      placeholder: "Enter Description",
      errorMessage: "Please enter description!",
      label: "Description",
      required: true,
    },
    {
      id: 12,
      type: "file",
      column: "4",
      preview: values.logo,
      errorMessage: "Please add Logo!",
      label: "Logo",
      required: true,
    },
    {
      id: 13,
      name: "is_active",
      type: "switch",
      checked: values.is_active || "",
      column: "4 ",
      placeholder: "Active",
      label: "Active",
      required: true,
      margin: "3",
    },
  ];

  //colorchange
  const onColorChange = (color) => {
    setValues({ ...values, colour_code: color.color });
  };

  //adding new color Notification with name and color
  const handleSubmit = (e) => {
    e.preventDefault();
    if (editId) {
      updateNotification({
        data: {
          ...values,
          updated_by: user?.user_id,
        },
        id: editId,
        token: token,
      });
    }
  };
  //useEffect for editCustomer refecth
  useEffect(() => {
    editNotificationApi.refetch();
    clearStatus();
  }, [afterClose]);

  return (
    <>
      <div className="my-4 border-bottom border-2 ">
        <h5>Edit Notification</h5>
      </div>
      <div className="my-3">
        {getError && (
          <Alert type="error" message={getError} closable showIcon />
        )}
      </div>
      <Form onSubmit={handleSubmit}>
        <Row className="w-100  ">
          {inputs.map((input) => (
            <>
              {input.type === "text" ? (
                <AppInput
                  key={input.id}
                  {...input}
                  defaultSet={afterClose}
                  value={values[input.name]}
                  onChange={onChange}
                  required={input.required}
                />
              ) : (
                ""
              )}
              {input.type === "switch" ? (
                <AppSwitch
                  className="mt-5"
                  key={input.id}
                  {...input}
                  value={values[input.name]}
                  onChange={appSwitchHandler}
                />
              ) : (
                ""
              )}
              {/* //input file  */}
              {input.type === "file" && (
                <AppFile
                  fileVal={(e) =>
                    setValues({ ...values, logo: e.logo, logo_name: e.name })
                  }
                  size={100000}
                  required={input.required}
                  label={"Logo"}
                  afterClose={afterClose}
                  initailVal={
                    editId
                      ? {
                          fileName: values?.logo_name,
                          imgPreview: values?.logo,
                        }
                      : ""
                  }
                  column={4}
                />
              )}
            </>
          ))}
          {/* //color change component */}
          <InputColor
            initialColor={values.colour_code}
            onColorChange={onColorChange}
            title="Background Color"
          />
        </Row>

        <Row>
          <Col className="my-3">
            <div className="d-flex justify-content-center">
              <div className="me-md-2 ">
                <AppButton
                  onClick={handleReset}
                  title={"CLEAR"}
                  variant={"secondary-md"}
                />
              </div>

              <AppButton title={"SAVE"} variant={"primary-md"} />
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
}
