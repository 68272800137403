import React, { useState, useEffect } from "react";
import { Col, Row, Card } from "react-bootstrap";
import "./ManualEntry.css";
import AppSelect from "../../../../../Components/AppSelect/AppSelect";
import AppButton from "../../../../../Components/AppButton/AppButton";
import AppInput from "../../../../../Components/AppInput/AppInput";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { useGetCountyListMutation } from "../../../../../Redux/Services/county";
import {
  removeArrayBlank,
  removeBlank,
  scrollComp,
} from "../../../../../Controllers/Functions";
import { useLocation } from "react-router-dom";
import AppSwitch from "../../../../../Components/AppSwitch/AppSwitch";
import AppTextArea from "../../../../../Components/AppTextArea/AppText";
import MultiOwners from "../../../../../Components/MultiOwners/MultiOwners";
import { useAddParcelMutation } from "../../../../../Redux/Services/manualEntry";
import { Alert, Button, Collapse, notification, Radio, Tabs } from "antd";
import AppTextEditor from "../../../../../Components/AppTextEditor/AppTextEditor";

import {
  useAddParcelUpdateMutation,
  useParcelCornJobUpdatedMutation,
  useUpdateParcelCommentsMutation,
  useUpdateParcelInfoMutation,
  useUpdateParcelStatusMutation,
} from "../../../../../Redux/Services/Parcels";
import { useCallback } from "react";
import MultiAssessed from "../../../../../Components/MultiAssessed/MultiAssessed";
import { useStatusListQuery } from "../../../../../Redux/Services/status";
import { GlobalContext } from "../../../../../App";
import { useContext } from "react";
import { parcelContext } from "../../OrderEditor";
import MultiLinkParcels from "../../../../../Components/MultiLinkParcels/MultiLinkParcels";
import {
  useAutoConflictsStatusUpdateMutation,
  useOrderDisplayCertCommentListQuery,
  useOrderDisplayCertCommentListQueryMutation,
  useOrderReadOnlyCommentListQuery,
  useOrderReadOnlyCommentMutation,
  useUpdateCalculationsMutation,
  useValidateConflictsMutation,
} from "../../../../../Redux/Services/Orders";
import { FiEdit } from "react-icons/fi";
import { bakendAutomationHandler } from "../../../../../Redux/reducers/Order";
const parse = require("html-react-parser");
const { Panel } = Collapse;
const { TabPane } = Tabs;
export default function ManualEntry({
  refresh,
  editId = null,
  mode = "add",
  setKey,
  data = [],
  toolsData = {},
  parcelInfoResult,
  validateConflicts,
  refecthParcelStatus,
  setShowYearFlag,
  EditHandlerComp,
}) {
  //context api
  const global = useContext(GlobalContext);
  const [globalVal, setGlobalVal] = global.state;

  //context api parcel
  const { controller } = useContext(parcelContext);
  const [parcelController, setParcelController] = controller;

  //redux
  const { orderDATA } = useSelector((state) => state?.OrderData);
  //destructure the order data
  const { county_id, state_id } = orderDATA;
  //context api

  // state
  const { token } = useSelector((state) => state.auth);
  const { parcelEditInOrder } = useSelector((state) => state.flagRoles);

  const initialState = {
    parcel_number: "",
    processor_type_id: 1,
    state_id: state_id,
    status_id: "",
    county_id: county_id,
    cad_property_id: "",
    cross_county_account_number: "",
    geographic_id: "",
    tax_collector_id: "",
    acreage: "",
    tax_suit_info: "",
    subdivision: "",
    taxing_units: "",
    deed_reference: "",
    situs_address: "",
    linked_parcels: [],
    is_future_review: false,
    ag_cleared: false,
    legal_description: "",
    is_hoa: false,
    year: "",
    add_linked_parcels: [],
    delete_linked_parcels: [],
    add_ownership_information: [],
    add_assessed_information: [],
    ownership_information_list: [],
  };

  const options = [];
  let currentYear = new Date().getFullYear();

  for (let i = currentYear; i > currentYear - 5; i--) {
    options.push(i);
  }

  const [values, setValues] = useState(initialState);
  const [ownership_information, setOwnership_information] = useState([]);
  const [assessed_information, setAssessed_information] = useState([]);
  const [CountyOptions, setCountyOptions] = useState([]);
  const [statusListOptions, setStatusListOptions] = useState([]);
  const [ediStatusShow, setEdiStatusShow] = useState(false);

  const { stateList } = useSelector((state) => state.stateList);
  const dispatch = useDispatch()
  //contentsxt

  const [read_only_comments, setRead_only_comments] = useState("");
  const [display_oncert_comments, setDisplay_oncert_comments] = useState("");

  const { state } = useLocation();
  const orderId = state?.id;

  const [getError, setGetError] = useState(null);
  const [commetsView, setCommetsView] = useState("display-cert");

  //calling rtk query hook for geting County list
  const countyList2 = useGetCountyListMutation();
  const [getCountyList] = countyList2;

  const [parcelReadOnlyCommentListApi, parcelReadOnlyCommentList] = useOrderReadOnlyCommentMutation()
  const [parcelDisplayCertCommentListApi, parcelDisplayCertCommentList] = useOrderDisplayCertCommentListQueryMutation()

  //api status list
  const statusApi = useStatusListQuery(token, { skip: !editId });


  //update parcel coments info
  const [updateParcelComments, updateParcelCommentsResult] =
    useUpdateParcelCommentsMutation();

  useEffect(() => {
    if (updateParcelCommentsResult.isSuccess) {
      //redirect to summary tab
      // setKey("summary");
      //reftch parcel info
      setParcelController({
        ...parcelController,
        mode: "view",
        refetch: true,
        year: data.year,
        parcelTwoView: false,
      });
      parcelController?.storeCertificateInDbFun &&
        parcelController?.storeCertificateInDbFun();
    }
    setGlobalVal({ ...globalVal, loader: updateParcelCommentsResult.isLoading })
  }, [updateParcelCommentsResult.isLoading])


  //update parcel info
  const [updateParcel, updateParcelResult] = useUpdateParcelInfoMutation();
  //update corn job
  const [updateParcelCornJob] = useParcelCornJobUpdatedMutation();

  //update conflicts info
  // const [validateConflicts, validateConflictsResult] =
  //   useValidateConflictsMutation();


  const [updateCalculationsApi,] =
    useUpdateCalculationsMutation();

  //update status base on conflict for auto update status info
  const [autoStatusUpdate, autoStatusUpdateResult] =
    useAutoConflictsStatusUpdateMutation();

  //update parcel info
  const [updateYaerParcel, updateYaerParcelResult] =
    useAddParcelUpdateMutation();

  //update parcel info
  const [updateParcelStatus, updateParcelStatusResult] =
    useUpdateParcelStatusMutation();

  // useEffect for get parcel  status
  useEffect(() => {
    if (autoStatusUpdateResult.isSuccess) {
      refecthParcelStatus();
    }
  }, [autoStatusUpdateResult.isLoading]);

  // useEffect for countylist based on state selection
  useEffect(() => {
    if (countyList2[1].isSuccess) {
      setCountyOptions(countyList2[1].data?.data);
    }
    if (countyList2[1].isError) {
      setGetError(
        countyList2[1].error?.data?.message ||
        countyList2[1].error.error ||
        countyList2[1].error.data?.msg.sqlMessage
      );
      setCountyOptions([]);
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: countyList2[1].isLoading });
  }, [countyList2[1].isLoading]);

  // useEffect for countylist based on state selection
  // useEffect(() => {
  //   if (validateConflictsResult.isSuccess) {
  //     //auto update status
  //     //run new api
  //     updateCalculationsApi({ order_id: editId, token })
  //     autoStatusUpdate({ data: { order_id: orderId }, token });
  //     updateParcelComments({ data: { order_id: orderId }, token })
  //     //save certificate store in DB

  //   }
  //   if (validateConflictsResult.isError) {
  //     setGetError(
  //       validateConflictsResult.error?.data?.message ||
  //       validateConflictsResult.error.error ||
  //       validateConflictsResult.error.data?.msg.sqlMessage
  //     );
  //   }
  // }, [validateConflictsResult.isLoading]);

  // useEffect for status list
  useEffect(() => {
    if (statusApi.isSuccess) {
      setStatusListOptions(statusApi.data?.data);
    }
    if (statusApi.isError) {
      setGetError(
        statusApi.error?.data?.message ||
        statusApi.error.error ||
        statusApi.error.data?.msg.sqlMessage
      );
      setStatusListOptions([]);
    }
  }, [statusApi.isFetching]);

  // useEffect for status list
  useEffect(() => {
    if (updateParcelStatusResult.isSuccess) {
      setEdiStatusShow(false);

      if (orderDATA?.status_id !== 13) {
        //validate conflicts
        validateConflicts && validateConflicts({
          data: { order_id: orderId },
          token,
        });
        setParcelController({
          ...parcelController,
          mode: "view",
          refetch: true,
          year: data.year,
          parcelTwoView: false,
        });
      } else {
        //reftch parcel info
        setParcelController({
          ...parcelController,
          mode: "view",
          refetch: true,
          year: data.year,
          parcelTwoView: false,
        });
        // change flags 
        // setTimeout(() => {
        //   //reftch parcel info
        //   setParcelController({
        //     ...parcelController,
        //     mode: "view",
        //     refetch: false,
        //     year: data.year,
        //     parcelTwoView: false,
        //   });
        // }, 5000);
      }

    }
    if (updateParcelStatusResult.isError) {
      setGetError(
        updateParcelStatusResult.error?.data?.message ||
        updateParcelStatusResult.error.error ||
        updateParcelStatusResult.error.data?.msg.sqlMessage
      );
      setStatusListOptions([]);
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: updateParcelStatusResult.isLoading });
  }, [updateParcelStatusResult.isLoading]);

  // useEffect for update parcel info
  useEffect(() => {
    if (updateParcelResult.isSuccess) {
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: updateParcelResult.data?.message,
      });
      //calling api for parcel api validate
      if (orderDATA?.status_id !== 13) {
        validateConflicts && validateConflicts({
          data: { order_id: orderId },
          token,
        });
        setParcelController({
          ...parcelController,
          mode: "view",
          refetch: true,
          year: data.year,
          parcelTwoView: false,
        });
      } else {
        //reftch parcel info
        setParcelController({
          ...parcelController,
          mode: "view",
          refetch: true,
          year: data.year,
          parcelTwoView: false,
        });
        // change flags 
        setTimeout(() => {
          //reftch parcel info
          setParcelController({
            ...parcelController,
            mode: "view",
            refetch: false,
            year: data.year,
            parcelTwoView: false,
          });
        }, 5000);
      }
      setDisplay_oncert_comments("");
      setRead_only_comments("");
      //update parcel corn job
      updateParcelCornJob({ token, order_id: orderId })
    }
    if (updateParcelResult.isError) {
      setGetError(
        updateParcelResult.error?.data?.message ||
        updateParcelResult.error.error ||
        updateParcelResult.error.data?.msg.sqlMessage
      );
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: updateParcelResult.isLoading });
  }, [updateParcelResult.isLoading]);

  // useEffect for update parcel info
  useEffect(() => {
    if (updateYaerParcelResult.isSuccess) {
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: updateYaerParcelResult.data?.message,
      });
      //redirect to summary tab
      setDisplay_oncert_comments("");
      setRead_only_comments("");
      setParcelController({
        ...parcelController,
        mode: "view",
        refetch: true,
        year: values.year,
        parcelTwoView: false,
      });
      setShowYearFlag(true);
    }
    if (updateYaerParcelResult.isError) {
      setGetError(
        updateYaerParcelResult.error?.data?.message ||
        updateYaerParcelResult.error.error ||
        updateYaerParcelResult.error.data?.msg.sqlMessage
      );
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: updateYaerParcelResult.isLoading });
  }, [updateYaerParcelResult.isLoading]);

  useEffect(() => {
    if (mode === "add") {
      setValues(initialState);
      //send reqest for county
      if (refresh === "Manual Entry") {
        getCountyList({ state_id: { state_id: state_id }, token });
        parcelReadOnlyCommentListApi({ token })
        parcelDisplayCertCommentListApi({ token })
      }
      setOwnership_information([]);
      setAssessed_information([]);
    }

    setGetError(false);
  }, [refresh]);

  ///while parcel detail

  useEffect(() => {
    const {
      state_id,
      situs_address,
      read_only_comments,
      display_oncert_comments,
      county_id,
      cad_property_id,
      ownership_information_list,
      assessed_information_list,
      ...ohters
    } = data;

    if ((data && mode === "edit") || mode === "view") {


      setDisplay_oncert_comments(display_oncert_comments);
      setRead_only_comments(read_only_comments);

      setEdiStatusShow(false);
      setValues({
        ...ohters,
        state_id,
        county_id,
        situs_address,
        cad_property_id,
        assessed_information_list,
        ownership_information_list,
        add_ownership_information: [],
        add_linked_parcels: [],
        edit_ownership_information: [],
        delete_ownership_information: [],
        add_assessed_information: [],
        edit_assessed_information: [],
        edit_linked_parcels: [],
        delete_assessed_information: [],
        delete_linked_parcels: [],
      });
    } else if (data && mode === "addyear") {
      setDisplay_oncert_comments("");
      setRead_only_comments("");
      getCountyList({ state_id: { state_id: state_id }, token });
      setValues({
        ...data,
        add_ownership_information: [],
        add_linked_parcels: [],
        edit_ownership_information: [],
        delete_ownership_information: [],
        add_assessed_information: [],
        edit_assessed_information: [],
        edit_linked_parcels: [],
        delete_assessed_information: [],
        delete_linked_parcels: [],
      });
    }
  }, [data, mode]);

  useEffect(() => {
    if (mode === "edit") {
      const { state_id } = data
      if (state_id) {
        //send reqest for county
        getCountyList({ state_id: { state_id: state_id || orderDATA?.state_id }, token });
        parcelReadOnlyCommentListApi({ token })
        parcelDisplayCertCommentListApi({ token })
      }
    }
  }, [mode, orderDATA?.state_id])

  //add parcel info
  const [addParcel, addParcelResult] = useAddParcelMutation();

  // useEffect for add hoa
  useEffect(() => {
    if (addParcelResult.isSuccess) {
      ///after submit details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: addParcelResult.data?.message,
      });
      //redirect to summary tab
      setKey("summary");
      dispatch(bakendAutomationHandler(false))
      parcelController.storeCertificateInDbFun &&
        parcelController.storeCertificateInDbFun();
    }
    if (addParcelResult.isError) {
      setGetError(
        addParcelResult.error?.data?.error ||
        addParcelResult.error?.data?.message ||
        addParcelResult.error.data?.msg?.sqlMessage ||
        (addParcelResult.error.error && "server unreachable!")
      );
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: addParcelResult.isLoading });
  }, [addParcelResult.isLoading]);

  const inputs = [
    {
      id: 1,
      name: "state_id",
      type: "select",
      options: stateList,
      value:
        (mode === "edit" || mode === "addyear" || mode === "add"
          ? values.state_id || orderDATA?.state_id
          : values.state_name) || "",
      column: "3",
      placeholder: "certSimple",
      errorMessage: "Please select state!",
      label: "State",
      required: true,
      disabled: true,
    },
    {
      id: 2,
      name: "county_id",
      type: "select",
      value:
        (mode === "edit" || mode === "addyear" || mode === "add"
          ? values.county_id
          : values.county_name) ||
        "" ||
        "",
      options: CountyOptions,
      column: "3",
      placeholder: "Select County",
      errorMessage: "Please select county !",
      label: "County",
      required: true,
      disabled: mode !== "add",
    },
    {
      id: 5464511,
      name: "cad_property_id",
      value: values.cad_property_id || "",
      type: "text",
      column: "3",
      placeholder: "Enter Cad Property ID ",
      errorMessage: "Please cad property id!",
      label: "Cad Property ID ",
      required: true,
      disabled: mode === "addyear" ? true : false,
    },
    // {
    //   id: 5454545540,
    //   name: "is_future_review",
    //   checked: values.is_future_review || "",
    //   type: "switch",
    //   column: "3",
    //   label: "Future Review",
    //   margin: "4",
    //   disabled: mode === "view" ? true : false,
    // },
    {
      id: 11,
      name: "geographic_id",
      value: values.geographic_id || "",
      type: "text",
      column: "3",
      placeholder: "Enter Geo ID",
      errorMessage: "Please enter geo id!",
      label: "Geo ID",
      required: true,
    },
    {
      id: 163550,
      name: "tax_collector_id",
      value: values.tax_collector_id || "",
      type: "text",
      column: "3",
      placeholder: "Enter Tax Collector ID",
      errorMessage: "Please tax collector ID!",
      label: "Tax Collector ID ",
    },
    {
      id: 1550,
      name: "cross_county_account_number",
      value: values.cross_county_account_number || "",
      type: "text",
      column: "3",
      placeholder: "Enter Cross County ID",
      errorMessage: "Please cross county ID!",
      label: "Cross County ID",
      // required: true,
    },
    // {
    //   id: 9455,
    //   name: "linked_parcels",
    //   type: "text",
    //   column: "3",
    // },
    {
      id: 45442122,
      name: "situs_address",
      value: values.situs_address || "",
      type: "text",
      column: "3",
      placeholder: "Enter Situs Address",
      errorMessage: "Please situs address!",
      label: "Situs Address",
      required: true,
      disabled: mode === "addyear" ? true : false,
    },
    {
      id: 13,
      name: "acreage",
      value: values.acreage || "",
      type: "text",
      column: "3",
      placeholder: "Enter Acreage",
      errorMessage: "Please enter acreage!",
      label: "Acreage",
      required: "",
    },
    {
      id: 5623,
      name: "ag_cleared",
      checked: values.ag_cleared || "",
      type: "switch",
      column: "3",
      label: "AG Cleared",
      margin: "4",
      disabled: mode === "view" ? true : false,
    },
    {
      id: 1564,
      name: "subdivision",
      value: values.subdivision || "",
      type: "text",
      column: "3",
      placeholder: "Enter Subdivision",
      errorMessage: "Please enter subdivision!",
      label: "Subdivision",
      required: true,
    },
    {
      id: 15,
      name: "taxing_units",
      value: values.taxing_units || "",
      type: "text",
      column: "3",
      placeholder: "Enter Taxing Units",
      errorMessage: "Please enter taxing units!",
      label: "Jurisdiction Codes",
      maxLength: 250,
      required: true,
    },
    {
      id: 1545,
      name: "tax_suit_info",
      value: values.tax_suit_info || "",
      type: "text",
      column: "3",
      placeholder: "Enter Tax Suite Info",
      label: "Tax Suite Info",
      maxLength: 250,
    },
    {
      id: 1564565,
      name: "year",
      value: values?.year || "",
      type: "select",
      column: `3 ${mode === "addyear" ? "d-none" : ""}`,
      placeholder: "Enter year",
      errorMessage: "Please enter valid year!",
      label: "Year",
      options: options,
      maxLength: 4,
      minLength: 4,
      required: mode !== "addyear",
      disabled: mode === "edit",
    },

    {
      id: 7,
      name: "deed_reference",
      value: values.deed_reference || "",
      type: "text",
      column: "3",
      placeholder: "Enter Deed Reference",
      errorMessage: "Please enter deed reference!",
      label: "Deed Reference",
      required: true,
    },
    {
      id: 9524455,
      name: "legal_description",
      value: values.legal_description || "",
      type: "textarea",
      column: "6",
      placeholder: "Enter Legal Description",
      errorMessage: "Please enter legal description!",
      label: " Legal Description ",
      required: true,
    },
  ];

  const statusInput = [
    {
      id: 2,
      name: "status_id",
      type: "select",
      value: values.status_id || "",
      options: statusListOptions,
      column: "3",
      placeholder: "Select County",
      errorMessage: "Please select county !",
      label: "Status",
      required: true,
    },
  ];
  const handleSubmit = (e) => {
    e.preventDefault();
    //remove blank values
    removeBlank(values);
    function removeAssedBlank() {
      removeArrayBlank(values?.assessed_information);
      removeArrayBlank(values?.add_assessed_information);
      removeArrayBlank(values?.edit_assessed_information);
    }
    if (!editId && mode === "add") {
      removeAssedBlank();
      addParcel({
        data: {
          ...values,
          order_id: orderId,
          display_oncert_comments,
          read_only_comments,
          ownership_information,
          is_hoa: false,
          assessed_information,
        },
        token,
      });
    } else if (mode === "edit") {
      removeAssedBlank();
      updateParcel({
        data: {
          ...values,
          order_id: orderId,
          display_oncert_comments,
          read_only_comments,
          ownership_information,
          assessed_information,
        },
        token,
        id: editId,
      });
    } else {
      if (!toolsData.year) {
        setGetError("Please add year");
      } else if (mode === "addyear") {
        removeAssedBlank();
        updateYaerParcel({
          data: {
            ...values,
            parcel_id: values.id,
            display_oncert_comments,
            year: toolsData.year,
            assessed_information,
          },
          token,
          id: editId,
        });
      }
    }
  };
  const handleReset = (e) => {
    e.preventDefault();
    setValues(initialState);
    setGetError(false);
  };

  const onChange = async (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (name === "year") {
      if (Number(value) > new Date().getFullYear()) {
        notification.open({
          type: "error",
          style: { color: "red", marginTop: "50px" },
          description: "Year should be less than or equal to current year",
        });
        setValues({ ...values, year: "" });
      } else {
        setValues({
          ...values,
          [name]: value.replace(/[^0-9.]/gi, ""),
        });
      }
    }
    setGetError(false);
    // //text and number
    // if (name === "address" || name === "legal_description ") {
    //   setValues({
    //     ...values,
    //     [name]: value,
    //   });
    // }
    // //number only
    // else if (name === "parcel_number") {
    //   setValues({
    //     ...values,
    //     [name]: value.replace(/[^0-9]/gi, ""),
    //   });
    // }
    // //zip code
    if (name === "zip_code") {
      const value1 = value.replace(/[^0-9.]/g, "");
      var foo = value1.split("-").join("");
      if (foo.length > 0) {
        foo = foo.match(new RegExp(".{1,5}", "g")).join("-");
      }
      // value1.replace(/(\d{5})/, "$1-")
      setValues({ ...values, zip_code: foo });
    }
  };

  //select handler
  const selectHandler = (property, value) => {
    setValues({ ...values, [property]: value });
    setGetError(false);
    if (property === "state_id") {
      if (value) {
        getCountyList({ state_id: { state_id: value }, token });
        setValues({ ...values, state_id: value });
      }

      if (!value) {
        setCountyOptions([]);
        setValues({ ...values, state_id: value, county_id: "" });
      }
    }
    if (property === "county_id") {
      setValues({ ...values, county_id: value });
    }
  };

  useEffect(() => {
    setCountyOptions([]);
  }, [refresh]);

  //AppSwitch box
  const appSwitchHanler = (property, value) => {
    setValues({ ...values, [property]: value });
  };

  //error
  useEffect(() => {
    if (getError) {
      scrollComp("propertyBrwoser");
    }
  }, [getError]);

  //useCllback for owners
  const OwnerComp = useCallback(() => {
    return (
      !countyList2[1].isLoading && (
        <MultiOwners
          disabled={mode === "view" ? true : false}
          afterClose={mode}
          addFormVal={(value) => {
            if (!editId) {
              setOwnership_information(value);
            }
          }}
          //add new
          whileEdit={(value) => {
            if (editId) {
              values["add_ownership_information"] = [
                ...values?.add_ownership_information,
                value,
              ];
              // setValues({
              //   ...values,
              //   add_ownership_information: [
              //     ...values?.add_ownership_information,
              //     value,
              //   ],
              // });
            }
          }}
          //update
          updateContact={(value) => {
            if (editId) {
              if (
                values.add_ownership_information?.length > 0 &&
                values.add_ownership_information?.find(
                  (val) => val["temp_id"] === value.temp_id
                )
              ) {
                values.add_ownership_information.forEach((item) => {
                  if (item.temp_id === value.temp_id) {
                    item.address = value.address;
                    item.ownership_percentage = value.ownership_percentage;
                    item.owner_name = value.owner_name;
                    item.year = value.year;
                  }
                });
              }

              if (
                values.edit_ownership_information?.length > 0 &&
                values.edit_ownership_information?.find(
                  (val) => val["id"] === value.id
                )
              ) {
                values.edit_ownership_information.forEach((item) => {
                  if (item.id === value.id) {
                    item.address = value.address;
                    item.ownership_percentage = value.ownership_percentage;
                    item.owner_name = value.owner_name;
                    item.year = value.year;
                  }
                });
              } else if (!value?.temp_id) {
                setValues({
                  ...values,
                  edit_ownership_information: [
                    ...values?.edit_ownership_information,
                    value,
                  ],
                });
              }
            }
          }}
          //delete
          deleteContact={(value) => {
            if (editId) {
              if (value?.id) {
                setValues({
                  ...values,
                  delete_ownership_information: [
                    ...values?.delete_ownership_information,
                    String(value.id),
                  ],
                });
              } else {
                setValues({
                  ...values,
                  add_ownership_information:
                    values.add_ownership_information?.filter((e) => {
                      return e.temp_id !== value.temp_id;
                    }),
                });
              }
            }
          }}
          initailValArr={editId ? values?.ownership_information_list : []}
        />
      )
    );
  }, [!countyList2[1].isLoading, mode, JSON.stringify(values?.ownership_information_list), values]);

  //useCallback for multiAssessed
  const AssessedComp = useCallback(() => {
    return (
      !countyList2[1].isLoading && (
        <MultiAssessed
          disabled={mode === "view" ? true : false}
          afterClose={mode}
          addFormVal={(value) => {
            if (!editId) {
              setAssessed_information(value);
            }
          }}
          //add new
          whileEdit={(value) => {
            if (editId) {
              values["add_assessed_information"] = [
                ...values?.add_assessed_information,
                value,
              ];
            }
          }}
          //update
          updateContact={(value) => {
            if (editId) {
              if (
                values.add_assessed_information?.length > 0 &&
                values.add_assessed_information?.find(
                  (val) => val["temp_id"] === value.temp_id
                )
              ) {
                values.add_assessed_information.forEach((item) => {
                  if (item.temp_id === value.temp_id) {
                    item.land_value = value.land_value;
                    item.improvement_value = value.improvement_value;
                    item.appraised_value = value.appraised_value;
                    item.year = value.year;
                    item.cap_loss = value.cap_loss;
                    item.exemptions = value.exemptions;
                    item.is_proposed = value.is_proposed;
                  }
                });
              }

              if (
                values.edit_assessed_information?.length > 0 &&
                values.edit_assessed_information?.find(
                  (val) => val["id"] === value.id
                )
              ) {
                values.edit_assessed_information.forEach((item) => {
                  if (item.id === value.id) {
                    item.land_value = value.land_value;
                    item.improvement_value = value.improvement_value;
                    item.appraised_value = value.appraised_value;
                    item.year = value.year;
                    item.cap_loss = value.cap_loss;
                    item.exemptions = value.exemptions;
                    item.is_proposed = value.is_proposed;
                  }
                });
              } else if (!value?.temp_id) {
                setValues({
                  ...values,
                  edit_assessed_information: [
                    ...values?.edit_assessed_information,
                    value,
                  ],
                });
              }
            }
          }}
          //delete
          deleteContact={(value) => {
            if (editId) {
              if (value?.id) {
                setValues({
                  ...values,
                  delete_assessed_information: [
                    ...values?.delete_assessed_information,
                    String(value.id),
                  ],
                });
              } else {
                setValues({
                  ...values,
                  add_assessed_information:
                    values.add_assessed_information?.filter((e) => {
                      return e.temp_id !== value.temp_id;
                    }),
                });
              }
            }
          }}
          initailValArr={editId ? values?.assessed_information_list : []}
        />
      )
    );
  }, [!countyList2[1].isLoading, mode, JSON.stringify(values?.assessed_information_list), values]);

  const LinkParcelsComp = useCallback(() => {
    return (
      !countyList2[1].isLoading && (
        <div className="mt-2">
          <AppSwitch
            disabled={mode === "view"}
            label="Future Review"
            onChange={(_, value) => {
              setValues({ ...values, is_future_review: value });
            }}
            name="is_future_review"
            checked={values.is_future_review}
          />
          <MultiLinkParcels
            disabled={mode === "view" ? true : false}
            afterClose={mode}
            addFormVal={(value) => {
              if (!editId) {
                values.linked_parcels = value;
              }
            }}
            //add new
            whileEdit={(value) => {
              if (editId) {
                setValues({
                  ...values,
                  add_linked_parcels: [...values?.add_linked_parcels, value],
                });
              }
            }}
            //update
            updateLinkParcels={(value) => {
              if (editId) {
                if (
                  values.add_linked_parcels?.length > 0 &&
                  values.add_linked_parcels?.find(
                    (val) => val["temp_id"] === value.temp_id
                  )
                ) {
                  values.add_linked_parcels.forEach((item) => {
                    if (item.temp_id === value.temp_id) {
                      item.linked_parcel = value.linked_parcel;
                    }
                  });
                }

                if (
                  values.edit_linked_parcels?.length > 0 &&
                  values.edit_linked_parcels?.find(
                    (val) => val["id"] === value.id
                  )
                ) {
                  values.edit_linked_parcels.forEach((item) => {
                    if (item.id === value.id) {
                      item.linked_parcel = value.linked_parcel;
                    }
                  });
                } else if (value?.id) {
                  setValues({
                    ...values,
                    edit_linked_parcels: [
                      ...values?.edit_linked_parcels,
                      value,
                    ],
                  });
                }
              }
            }}
            //delete
            deleteLinkParcels={(value) => {
              if (editId) {
                if (value?.id) {
                  setValues({
                    ...values,
                    delete_linked_parcels: [
                      ...values?.delete_linked_parcels,
                      String(value.id),
                    ],
                  });
                } else {
                  setValues({
                    ...values,
                    add_linked_parcels: values.add_linked_parcels?.filter(
                      (e) => {
                        return e.temp_id !== value.temp_id;
                      }
                    ),
                  });
                }
              }
            }}
            initailValArr={editId ? values?.linked_parcel_information_list : []}
          />
        </div>
      )
    );
  }, [!countyList2[1].isLoading, mode, values]);

  //status comperision
  const isCompleteOrder = String(parcelController?.orderData?.status_id) === "6" || String(parcelController?.orderData?.status_id) === "15"


  return (
    <form onSubmit={handleSubmit}>
      {mode !== "add" && (
        <div className=" border bg-light p-2">
          <div className="row">
            <div className="col-12 col-md-4 text-center d-flex gap-2 align-items-center">
              <div className="text-center ms-2 fs-11 text-dark d-flex gap-2 align-items-center">
                <label htmlFor="status" className="text-dark fw-bold fs-6 m-0">
                  Parcel Status :
                </label>
                <div>
                  <AppSelect
                    // isEdit={editId}
                    classStyle={`${mode === "edit" || ediStatusShow
                      ? "border px-2 py-1  "
                      : "form-control border-0 bg-white"
                      }`}
                    //   defaultSet={afterClose}

                    name="status"
                    disabled={mode === "edit" ? false : !ediStatusShow}
                    onChange={(e, value) => {
                      setValues({ ...values, status_id: value });
                    }}
                    value={values.status_id}
                    options={
                      <>
                        {(parcelInfoResult?.isLoading || !values.status_id) ? <option value={""}>
                          Loading...
                        </option> : statusListOptions?.map((ele) => {
                          return (
                            <option key={ele.id} value={ele.id}>
                              {ele.name}
                            </option>
                          );
                        })}

                      </>
                    }
                  />
                </div>
                {ediStatusShow && mode === "view" ? (
                  <>
                    <button
                      type="button"
                      onClick={() => {
                        updateParcelStatus({
                          status_id: values.status_id,
                          id: values.id,
                          token,
                        });
                      }}
                      className=" border bg-primary  fs-12 text-white"
                    >
                      Update
                    </button>
                    <button
                      onClick={() => {
                        setEdiStatusShow(false);
                        setValues({ ...values, status_id: data.status_id });
                      }}
                      className=" border fs-12 text-dark"
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  mode === "view" && (
                    <FiEdit
                      color={!isCompleteOrder ? "#3388FF" : "#808080"}
                      onClick={!isCompleteOrder ? () => {
                        setEdiStatusShow(true);
                      } : undefined}
                      size={12}
                      className="mt-1"
                      cursor={!isCompleteOrder ? "pointer" : "not-allowed"}
                    />
                  )
                )}
              </div>
            </div>
            {parcelEditInOrder.map((item, index) => {
              if (item.edit_parcel) {
                return (
                  <div key={index} className="col-lg-3 col-12 ms-auto d-flex justify-content-end">
                    <div className="d-flex align-items-center gap-1">
                      {mode === "edit" && (
                        <Button htmlType="submit" size="small" type="primary">
                          UPDATE
                        </Button>
                      )}
                      {EditHandlerComp && <EditHandlerComp />}
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      )}
      <div className="col-12  " id="propertyBrwoser">
        {getError && <Alert message={getError} type="error" showIcon />}

        <Card className="bg-light-blue-300 p-0">
          <Card.Body className={`py-0 pb-2 bg-light-blue-300 `}>
            <div className="row">
              <div
                className={`col-12 col-lg-${parcelController?.parcelTwoView ? 12 : 10
                  }`}
              >
                <Row>
                  {inputs.map((input) => {
                    if (input.type === "switch" && !input.showType) {
                      return (
                        <AppSwitch
                          key={input.id}
                          onChange={appSwitchHanler}
                          {...input}
                          column={
                            parcelController?.parcelTwoView ? "4" : input.column
                          }
                        />
                      );
                    } else if (input.type === "textarea") {
                      return (
                        <AppTextArea
                          isEdit={
                            editId && mode !== "edit" && mode !== "addyear"
                          }
                          key={input.id}
                          defaultSet={mode}
                          onChange={(e) =>
                            setValues({
                              ...values,
                              [e.target.name]: e.target.value,
                            })
                          }
                          {...input}
                          column={
                            parcelController?.parcelTwoView ? "4" : input.column
                          }
                        />
                      );
                    } else if (
                      input.type === "select" &&
                      input.name === "state_id"
                    ) {
                      return (
                        <AppSelect
                          isEdit={
                            editId && mode !== "edit" && mode !== "addyear"
                          }
                          key={input.id}
                          {...input}
                          column={
                            parcelController?.parcelTwoView ? "4" : input.column
                          }
                          defaultSet={mode}
                          onChange={selectHandler}
                          options={
                            <>
                              <option value={""}>Select State</option>
                              {input.options.map((ele) => {
                                return (
                                  <option key={ele.id} value={ele.id}>
                                    {ele.state_name}
                                  </option>
                                );
                              })}
                            </>
                          }
                        />
                      );
                    } else if (
                      input.type === "select" &&
                      input.name === "county_id"
                    ) {
                      return (
                        <AppSelect
                          isEdit={
                            editId && mode !== "edit" && mode !== "addyear"
                          }
                          key={input.id}
                          {...input}
                          column={
                            parcelController?.parcelTwoView ? "4" : input.column
                          }
                          defaultSet={refresh}
                          onChange={selectHandler}
                          options={
                            <>
                              <option value={""}>Select county</option>
                              {input.options.map((ele) => {
                                return (
                                  <option key={ele.id} value={ele.id}>
                                    {ele.county_name}
                                  </option>
                                );
                              })}
                            </>
                          }
                        />
                      );
                    } else if (
                      input.type === "select" &&
                      input.name === "year"
                    ) {
                      return (
                        <AppSelect
                          isEdit={
                            editId && mode !== "edit" && mode !== "addyear"
                          }
                          key={input.id}
                          {...input}
                          column={
                            parcelController?.parcelTwoView ? "4" : input.column
                          }
                          defaultSet={refresh}
                          onChange={selectHandler}
                          options={
                            <>
                              <option value={""}>Select Year</option>
                              {input.options.map((ele) => {
                                return (
                                  <option key={ele} value={ele}>
                                    {ele}
                                  </option>
                                );
                              })}
                            </>
                          }
                        />
                      );
                    } else if (input.type === "text" && !input.showType) {
                      return (
                        <AppInput
                          isEdit={
                            editId && mode !== "edit" && mode !== "addyear"
                          }
                          defaultSet={mode}
                          key={input.id}
                          {...input}
                          column={
                            parcelController?.parcelTwoView ? "4" : input.column
                          }
                          value={values[input.name]}
                          onChange={onChange}
                        />
                      );
                    }
                  })}
                </Row>
              </div>
              <div
                className={`col-12 col-lg-${parcelController?.parcelTwoView ? 12 : 2
                  } bg-light-yello-200 `}
              >
                {LinkParcelsComp()}
              </div>
            </div>

            {mode === "view" &&
              (read_only_comments || display_oncert_comments) ? (
              <Collapse
                defaultActiveKey={"1"}
                accordion
                className="comments-collase my-1"
              >
                <Panel header="Comments" key="1">
                  <Tabs
                    activeKey={commetsView}
                    // className="title-container app-tab"
                    onChange={(k) => {
                      setCommetsView(k);
                    }}
                  >
                    <TabPane tab="Display On Certificate" key="display-cert">
                      <div className="p-2">
                        {parse(display_oncert_comments || " ")}
                      </div>
                    </TabPane>
                    <TabPane tab="Read Only" key="read-only">
                      <div className="p-2">
                        {parse(read_only_comments || " ")}
                      </div>
                    </TabPane>
                  </Tabs>
                  {/* <div className="border rounded bg-white p-2 mt-2">
                      {display_oncert_comments && commetsView === 2 && (
                        <div>{parse(display_oncert_comments)}</div>
                      )}
                    </div> */}
                </Panel>
              </Collapse>
            ) : null}

            {
              // (mode === "addyear" && editId) ||{}
              (mode === "add" || mode === "edit") && (
                <div
                  className="row mt-3 gray-table-col border rounded-4 mb-2 p-2 new-order-comments "
                  id={"_id"}
                >
                  <div
                    className={`d-flex ${"justify-content-between"} align-items-center my-2 contact-forms`}
                  >
                    <span>Comments</span>
                  </div>
                  <div>
                    <div className="col-12 ">
                      <div className="row editor">
                        <div
                          className={`${mode === "view" ? "bg-light-yello-200" : ""
                            } col-12 col-lg-${((editId && mode === "edit") ||
                              mode === "addyear" ||
                              mode === "add") &&
                              !parcelController.parcelTwoView
                              ? "6"
                              : "12"
                            } px-2 `}
                        >
                          {!parcelDisplayCertCommentList.isLoading && (
                            <div className="row align-items-center">
                              <div className="col-12 col-lg-3">
                                <h6
                                  className={`${editId && mode === "view"
                                    ? " pb-2 heading-5 bg-light d-inline-block p-2 text-dark border mt-2 rounded-pill px-3"
                                    : ""
                                    } `}
                                >
                                  Display On Cert
                                </h6>
                              </div>
                              {(editId && mode === "edit") ||
                                mode === "addyear" ||
                                mode === "add" ? (
                                <div className="col-12 col-lg-9">
                                  <AppSelect
                                    value={values?.selDisplay}
                                    classStyle="border w-100 p-1 my-2"
                                    onChange={(_, value) => {
                                      setValues({
                                        ...values,
                                        selDisplay: value,
                                      });
                                      setDisplay_oncert_comments(
                                        display_oncert_comments + value
                                      );
                                      setGetError(false);
                                    }}
                                    options={
                                      <>
                                        <option value={""}>
                                          Select Display On Cert
                                        </option>
                                        {
                                          parcelDisplayCertCommentList?.data?.data?.map(
                                            ({ title, comments, id }) => {
                                              return (
                                                <option
                                                  key={id}
                                                  value={comments}
                                                >
                                                  {title}
                                                </option>
                                              );
                                            }
                                          )}
                                      </>
                                    }
                                  />
                                </div>
                              ) : (
                                <>
                                  {" "}
                                  {editId && (
                                    <div className="col-12 py-1  ">
                                      <pre className="py-1  px-2">
                                        {parse(display_oncert_comments || " ")}
                                      </pre>
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          )}
                          {(editId && mode === "edit") ||
                            mode === "addyear" ||
                            mode === "add" ? (
                            <AppTextEditor
                              value={display_oncert_comments}
                              onChange={(newContent) => {
                                setGetError(false);

                                setDisplay_oncert_comments(newContent);
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div
                          className={` ${mode === "view" ? "bg-light-blue-300" : ""
                            } col-12 col-lg-${((editId && mode === "edit") ||
                              mode === "addyear" ||
                              mode === "add") &&
                              !parcelController.parcelTwoView
                              ? "6"
                              : "12 mb-2"
                            } px-2 `}
                        >
                          {!parcelReadOnlyCommentList.isLoading && (
                            <div className="row align-items-center">
                              <div className="col-12 col-lg-3">
                                <h6
                                  className={`${editId && mode === "view"
                                    ? " pb-2 heading-5 bg-light d-inline-block p-2 text-dark border mt-2 rounded-pill px-3"
                                    : ""
                                    } `}
                                >
                                  Read Only
                                </h6>
                              </div>
                              {(editId && mode === "edit") ||
                                mode === "addyear" ||
                                mode === "add" ? (
                                <div className="col-12 col-lg-9 ">
                                  <AppSelect
                                    value={values?.selReadOnly}
                                    classStyle="border w-100 p-1 my-2"
                                    onChange={(_, value) => {
                                      setValues({
                                        ...values,
                                        selReadOnly: value,
                                      });
                                      setRead_only_comments(
                                        read_only_comments + value
                                      );
                                      setGetError(false);
                                    }}
                                    options={
                                      <>
                                        <option value={""}>
                                          Select Read Only
                                        </option>
                                        {
                                          parcelReadOnlyCommentList?.data?.data.map(
                                            ({ title, comments, id }) => {
                                              return (
                                                <option
                                                  key={id}
                                                  value={comments}
                                                >
                                                  {title}
                                                </option>
                                              );
                                            }
                                          )}
                                      </>
                                    }
                                  />
                                </div>
                              ) : (
                                <div className="col-12  col-lg-12    ">
                                  {editId && (
                                    <pre className=" py-1 px-2">
                                      {parse(read_only_comments)}
                                    </pre>
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                          {(editId && mode === "edit") ||
                            mode === "addyear" ||
                            mode === "add" ? (
                            <AppTextEditor
                              value={read_only_comments}
                              onChange={(newContent) => {
                                setGetError(false);
                                setRead_only_comments(newContent);
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }

            <div className="row mt-1">
              <div className="col-12">
                {/* //multiownersList */} {OwnerComp()}
              </div>
            </div>
            <div className=" row  mt-1">
              <div className="col-12">{AssessedComp()}</div>
            </div>
            {(mode === "addyear" || mode === "add") && (
              <Row>
                <Col className="my-3 mt-5">
                  <div className="d-flex justify-content-center align-items-center">
                    {mode === "add" && (
                      <div className="me-md-2 ">
                        <AppButton
                          onClick={handleReset}
                          title={"CLEAR"}
                          variant={"secondary-md"}
                          type={"button"}
                        />
                      </div>
                    )}

                    <AppButton
                      type="submit"
                      title={
                        mode === "add"
                          ? "ADD"
                          : mode === "addyear"
                            ? "ADD PRIOR YEAR"
                            : null
                      }
                      variant={"primary-md"}
                    />
                  </div>
                </Col>
              </Row>
            )}
          </Card.Body>
        </Card>
      </div>
    </form>
  );
}
