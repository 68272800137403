import React from 'react';
import { Select, Space } from 'antd';
import { useUserTypeListQuery } from '../../../Redux/Services/User';
import { useSelector } from 'react-redux';


const UserTypesData = ({ onChange, value }) => {
    //calling redux stor for vendors list
    const { token } = useSelector((state) => state.auth);

    const userTypeListFetch = useUserTypeListQuery(token);


    const options = (userTypeListFetch.isSuccess && userTypeListFetch?.data?.data?.map(({ id, name }) => ({ label: name, value: id }))) || [];

    return <Select
        mode="multiple"
        style={{
            width: '100%',
        }}

        loading={userTypeListFetch?.isFetching}
        placeholder="Select User Type"
        value={value}
        onChange={onChange}
        optionLabelProp="label"
        options={options}
        optionRender={(option) => (
            <Space>
                <span role="img" aria-label={option.data.label}>
                    {option.data.label}
                </span>
            </Space>
        )}
    />
};
export default UserTypesData;