import React from "react";
import { useSelector } from "react-redux";

export default function OrderInfoCopyModal() {
  const { orderDATA } = useSelector((state) => state.OrderData);
  const {
    customer_name,
    branch_name,
    closer_name,
    product_type_name,
    county_name,
    state_name,
    notice_name,
    legal_description,
    property_address,
    seller,
    buyer,
    file_number,
  } = orderDATA;

  return (
    <div className="py-4 px-2">
        <h6 className="bg-light p-2 text-success border ">Order Information</h6>
      <div className="row  ">
        <div className="col-12 col-lg-6">
          <div className="my-1">
            <label htmlFor="customer" className="ms-1 heading-5 text-dark ">
              Customer :
            </label>
            <div className={`mx-1 heading-5 mt-0`} style={{ marginTop: 16 }}>
              {customer_name || " "}
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-6">
          <div className="my-1">
            <label htmlFor="customer" className="ms-1 heading-5 text-dark ">
              Branch :
            </label>
            <div className={`mx-1 heading-5 mt-0`} style={{ marginTop: 16 }}>
              {branch_name || " "}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="my-1">
            <label htmlFor="customer" className="ms-1 heading-5 text-dark ">
              Product Type :
            </label>
            <div className={`mx-1 heading-5 mt-0`} style={{ marginTop: 16 }}>
              {product_type_name || " "}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="my-1">
            <label htmlFor="customer" className="ms-1 heading-5 text-dark ">
              Closer :
            </label>
            <div className={`mx-1 heading-5 mt-0`} style={{ marginTop: 16 }}>
              {closer_name || " "}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="my-1">
            <label htmlFor="customer" className="ms-1 heading-5 text-dark ">
              File # :
            </label>
            <div className={`mx-1 heading-5 mt-0`} style={{ marginTop: 16 }}>
              {file_number || " "}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="my-1">
            <label htmlFor="customer" className="ms-1 heading-5 text-dark ">
              State :
            </label>
            <div className={`mx-1 heading-5 mt-0`} style={{ marginTop: 16 }}>
              {state_name || " "}
            </div>
          </div>
        </div>

        <div className="col-12 col-lg-6">
          <div className="my-1">
            <label htmlFor="customer" className="ms-1 heading-5 text-dark ">
              County :
            </label>
            <div className={`mx-1 heading-5 mt-0`} style={{ marginTop: 16 }}>
              {county_name || " "}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="my-1">
            <label htmlFor="customer" className="ms-1 heading-5 text-dark ">
              Notice Flag :
            </label>
            <div className={`mx-1 heading-5 mt-0`} style={{ marginTop: 16 }}>
              {notice_name || " "}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="my-1">
            <label htmlFor="customer" className="ms-1 heading-5 text-dark ">
              Owner/Seller(s) :
            </label>
            <div className={`mx-1 heading-5 mt-0`} style={{ marginTop: 16 }}>
              {seller || " "}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="my-1">
            <label htmlFor="customer" className="ms-1 heading-5 text-dark ">
              Buyer(s) :
            </label>
            <div className={`mx-1 heading-5 mt-0`} style={{ marginTop: 16 }}>
              {buyer || " "}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="my-1">
            <label htmlFor="customer" className="ms-1 heading-5 text-dark ">
              Property Address(s) :
            </label>
            <div
              className={`mx-1 heading-5 mt-0 px-1`}
              style={{ marginTop: 16 }}
            >
              {property_address || " "}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="my-1">
            <label htmlFor="customer" className="ms-1 heading-5 text-dark ">
              Legal Description(s) :
            </label>
            <div
              className={`mx-1 heading-5 mt-0 px-1`}
              style={{ marginTop: 16 }}
            >
              {legal_description || " "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
