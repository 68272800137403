import React from "react";
import { mobileFormat, priceFormater } from "../../../../Controllers/Functions";
const parse = require("html-react-parser");

export default function HoaPage({ data }) {
  return (
    <>
      <div className="k-pdf-export certificate ">
        {/* //header  */}

        {/* //date  */}
        <div className="disclaimer mt-4">
          <h4>HOA Certificate</h4>
        </div>
        {<div className="my-1 ">
          <table>
            <thead>
              <tr>
                <th style={{ width: 69 }}><h5>HOA Completed:</h5></th>
                <th><h5>{data?.completed_date}</h5></th>
              </tr>
            </thead>
          </table>

        </div>}
        {/* //property details  */}
        <div className="property bg-outline">
          {data?.property_address && <div className="info d-flex gap-1 hoa-key-names">
            <h5 style={{ width: 150 }}>Property Address:</h5>
            <h5 style={{ width: 300 }}>{data?.property_address}</h5>
          </div>}

          <div className="info d-flex hoa-key-names gap-1">
            <h5 style={{ width: 150 }}>Owner Name:</h5>
            <h5 style={{ width: 300 }}>{data?.owner || ""} </h5>
          </div>

          {/* {<div className="info d-flex hoa-key-names gap-1">
            <h5 style={{ width: 150 }}>Situs Address:</h5>
            <h5 style={{ width: 300 }}>{data?.situs_adderess || ""}</h5>
          </div>} */}

          <div className="info d-flex hoa-key-names gap-1">
            <h5 style={{ width: 150 }}>Legal Description:</h5>
            <h5 style={{ width: 300 }}>{data?.legal_description || ""}</h5>
          </div>

          {/* <div className="info d-flex hoa-key-names gap-1">
            <h5 style={{ width: 150 }}>Tax Account Number:</h5>
            <h5 style={{ width: 300 }}>{data?.tax_account_number || ""}</h5>
          </div> */}

          <div className="info d-flex hoa-key-names gap-1">
            <h5 style={{ width: 150 }}>Property ID:</h5>
            <h5 style={{ width: 300 }}>{data?.parcel_id || ""}</h5>
          </div>

          <div className="info d-flex hoa-key-names gap-1">
            <h5 style={{ width: 150 }}>Acreage:</h5>
            <h5 style={{ width: 300 }}>{data?.acreage || ""}</h5>
          </div>
          <div className="info d-flex hoa-key-names gap-1">
            <h5 style={{ width: 150 }}>Subcode:</h5>
            <h5 style={{ width: 300 }}>{data?.subcode || ""}</h5>
          </div>
        </div>
      </div>
      <div className="notice my-2 fst-italic text-red">
        <h6 style={{ fontSize: 7 }} className="text-red">
          IF INFORMATION IN THE HOA ADDENDUM, OWNER OR SELLERS AGENT, INDICATES THERE MAY BE AN ADDITIONAL MASTER OR SUB-ASSOCIATION FOR THIS SUBDIVISION, PLEASE CONTACT THE TAX SERVICE FOR REVIEW AND UPDATE
        </h6>
      </div>

      {/* //Hoa details  */}
      <div className="Hoa bg-outline my-2">
        <div className="info d-flex hoa-key-names gap-1">
          <h5 style={{ width: 150 }}>HOA:</h5>
          <h5 style={{ width: 300 }}>{data?.hoa || "HOA NOT FOUND - VERIFY HOA ADDENDUM DOCUMENT"}</h5>
        </div>

        <div className="info d-flex hoa-key-names gap-1">
          <h5 style={{ width: 150 }}>Date Verified</h5>
          <h5 style={{ width: 300 }}>{data?.date_verified || ""}</h5>
        </div>

        <div className="info d-flex hoa-key-names gap-1">
          <h5 style={{ width: 150 }}>Collection Cycle:</h5>
          <h5 style={{ width: 300 }}>{data?.collection_cycle || ""}</h5>
        </div>

        {/* <div className="info d-flex hoa-key-names">
          <h5>HOA Notes</h5>
          <h5>:&nbsp;</h5>
          <h5>{parse(data?.hoa_notes)}</h5>
        </div> */}

        <div className="info d-flex hoa-key-names gap-1">
          <h5 style={{ width: 150 }}>Address:</h5>
          <h5 style={{ width: 300 }}>{data?.hoa_address || ""}</h5>
        </div>
        <div className="info d-flex hoa-key-names gap-1">
          <h5 style={{ width: 150 }}>Contact:</h5>
          <h5 style={{ width: 300 }}>{data?.contact || ""} </h5>
        </div>
        <div className="info d-flex hoa-key-names gap-1">
          <h5 style={{ width: 150 }}>Phone:</h5>
          <h5 style={{ width: 300 }}>{data?.hoa_phone
            ? mobileFormat(data?.hoa_phone)
            : ""} </h5>
        </div>
        <div className="info d-flex hoa-key-names gap-1">
          <h5 style={{ width: 150 }}>Email:</h5>
          <h5 style={{ width: 300 }}>{data?.hoa_email || ""} </h5>
        </div>
        <div className="info d-flex hoa-key-names gap-1">
          <h5 style={{ width: 150 }}>Website:</h5>
          <h5 style={{ width: 300 }}>{data?.website || ""}</h5>
        </div>
        <div className="info d-flex hoa-key-names gap-1">
          <h5 style={{ width: 150 }}>HOA Notes:</h5>
          <h5 style={{ width: 450 }}>{parse(data?.hoa_notes) || ""}</h5>
        </div>
        {data?.hoa_fees?.length > 0 &&
          data?.hoa_fees.map((ele, i) => {
            return (
              <div className="master-fess d-flex hoa-key-names" key={i}>
                <h5>{Object.keys(ele)}</h5>
                <h5>&nbsp;=&nbsp;</h5>
                <h5>{priceFormater(ele[Object.keys(ele)])}</h5>
              </div>
            );
          })}
      </div>

      {/* //Hoa MGMT Details  */}
      {data?.collector_mgmt && (
        <div className="Hoa bg-outline my-2">
          <div className="info d-flex hoa-key-names gap-1">
            <h5 style={{ width: 150 }}>Collector/Management Company:</h5>
            <h5 style={{ width: 300 }}>{data?.collector_mgmt || ""}</h5>
          </div>

          {/* <div className="info d-flex hoa-key-names">
          <h5>HOA Notes</h5>
          <h5>:&nbsp;</h5>
          <h5>{parse(data?.hoa_notes)}</h5>
        </div> */}
          <div className="info d-flex hoa-key-names gap-1">
            <h5 style={{ width: 150 }}>Address</h5>
            <h5 style={{ width: 300 }}>{data?.collector_mgmt_address || ""}</h5>
          </div>
          <div className="info d-flex hoa-key-names gap-1">
            <h5 style={{ width: 150 }}>Contact:</h5>
            <h5 style={{ width: 300 }}>{data?.contact || ""}</h5>
          </div>
          <div className="info d-flex hoa-key-names gap-1">
            <h5 style={{ width: 150 }}>Phone:</h5>
            <h5 style={{ width: 300 }}>
              {data?.hoa_collector_phone
                ? mobileFormat(data?.hoa_collector_phone)
                : ""}
            </h5>
          </div>
          <div className="info d-flex hoa-key-names gap-1">
            <h5 style={{ width: 150 }} >Email:</h5>
            <h5 style={{ width: 300 }}>{data?.hoa_collector_email || ""}</h5>
          </div>
          <div className="info d-flex hoa-key-names gap-1">
            <h5 style={{ width: 150 }}>Website:</h5>
            <h5 style={{ width: 300 }}>{data?.hoa_collector_website || ""}</h5>
          </div>
          <div className="info d-flex hoa-key-names gap-1">
            <h5 style={{ width: 150 }}>General Management Company Notes:</h5>
            <h5 style={{ width: 450 }}>{parse(data?.mgmt_notes) || ""}</h5>
          </div>
          {data?.hoa_mgmt_fees?.length > 0 &&
            data?.hoa_mgmt_fees.map((ele, i) => {
              return (
                <div className="master-fess d-flex hoa-key-names" key={i}>
                  <h5>{Object.keys(ele)}</h5>
                  <h5>&nbsp;=&nbsp;</h5>
                  <h5>{priceFormater(ele[Object.keys(ele)])}</h5>
                </div>
              );
            })}
        </div>
      )}
      {data?.comments && <div className="Hoa bg-outline my-2"><div className="info d-flex hoa-key-names gap-1">
        <h5 style={{ width: 150 }}>Comments:</h5>
        <h5 style={{ width: 450 }}>{parse(data?.comments) || ""}</h5>
      </div> </div>}
    </>
  );
}
