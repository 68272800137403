import { AiOutlinePlus } from "react-icons/ai";
import {  Upload, Button } from "antd";
import { useState, React } from "react";
import "./AppExcelUpload.css";
import { Spinner } from "react-bootstrap";

// const props = {
// 	action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
// };

const AppExcelUpload = ({
	onChange,
	upload,
	showRemoveIcon,
}) =>
{

	const onDrop = (e) => {
		console.log("Dropped files", e.dataTransfer.files);
	};
	const SetonChange = (info) => {
		onChange(info);
	};
	const beforeUpload = (file) => {
		const filetype = ".xlsx";
		console.log(file.type);
		if (file.type === filetype) {
			return true;
		} else
		{
			// setFileError("You can only upload excel file!");
			return false;
		}
	};
	return (
		<>
			<div 
			>
				<Upload
					// {...props}
					name="file"
					multiple={false}
					listType="text"
					maxCount={1}
					accept=".xlsx"
					onChange={SetonChange}
					onDrop={onDrop}
					beforeUpload={beforeUpload}
					iconRender={() => {
						return <>{!upload && <Spinner/>}</>;
					}}
					 //showUploadList={{ showRemoveIcon: showRemoveIcon }}
					showUploadList={showRemoveIcon}
					progress={{
						type:"line",
						showInfo:true,
						strokeWidth: 3,
					}}
					// {...props}
				>

					<Button type="default" className="upload-btn">
						<p>
							{" "}
							<AiOutlinePlus /> Click or Drag to File Upload
						</p>
					</Button>
				</Upload>
			</div>
		</>
	);
};

export default AppExcelUpload;
