import AppTable from "../../../Components/AppTable/AppTable";
import AppModal from "../../../Components/AppModal/AppModal";
import {
	FaPlus,
	FaSortAmountDown,
	FaSearch,
	FaSortAmountUp,
} from "react-icons/fa";
import StatusModal from "../Status/StatusModal";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {Input} from "antd";
import { useStatusListQuery } from "../../../Redux/Services/status";
import SearchColumns from "../../../Components/SearchColumns";
import { EditOutlined } from "@ant-design/icons";
// import {
//     data,
//     preDefinedData,
// } from "../../../ConfigData/UserTypeConfig";

export default function Status({ refresh }) {
	const [showHide, setShowHide] = useState(null);
	const [data, setData] = useState([]);
	const [search, setSearch] = useState("");
	const [editId, setEditId] = useState(null);
	const [initialState, setInitialState] = useState(false);

	//set  ascend or descend sorter state
	const [getsortorder, setSortOrder] = useState(false);
	const [getsortfield, setSortField] = useState("");

	function afterClose() {
		setInitialState(!initialState);
	}
	const AddVendorHandler = () => {
		setShowHide(true);
		setEditId(null);
	};
	function closeModal(data) {
		setShowHide(data);
	}
	const sort = (sortOrder, sortfield) => {
		// console.log(sortOrder, sortfield);
		if (sortOrder) {
			setSortOrder(sortOrder);
			setSortField(sortfield);
		} else {
			setSortOrder(false);
		}
	};
	const { parcelStatusModule } = useSelector((state) => state.flagRoles);
	//create function for customer fields
	function matchColumn(value) {
		// if (authColumn.indexOf(value) === -1) {
		//   return false;
		// } else {
		//   return true;
		// }
	}

	const preDefinedData = [
		{
			dataIndex: "id",
			key: "id",
			sorter: true,
			render: (value) => <span data-idfe={value}>{""}</span>,
			width: 1,
		},
		{
			dataIndex: "colour_code",
			key: "colour_code",
			render: (value) => <span>{""}</span>,
			width: 1,
		},
		{
			title: (
				<span>
					Status{" "}
					{getsortfield === "name" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "name" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "name" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			sorter: (a, b) => a.name.localeCompare(b.name),
			dataIndex: "name",
			key: "name",
			className: `${parcelStatusModule[1].action ? "" : "border-endstyle"}`,
			align: "start",
			 width: 1150,
			...SearchColumns("name", "name"),
			editable: true,
			render: (value, row) => (
				<button
					className="rounded-pill btn btn-sm text-white px-3 py-0 border-0  status-button"
					style={{ background: row.colour_code }}
				>
					{value}
				</button>
			),
		},
		{
			title: "Action",
			dataIndex: "edit",
			key: "edit",
			align: "center",
			className: `${parcelStatusModule[1].action ? "" : "d-none"}`,
			width: matchColumn(" ") && 50  ,
			render: () => (
				<div className="text-center">
					<EditOutlined
						onClick={editHandler}
						cursor={"pointer"}
						size={"20px"}
						color="gray"

					/>
				</div>
			),
		},
	];

	const { token } = useSelector((state) => state.auth);
	const statusApi = useStatusListQuery(token);

	useEffect(() => {
		if (statusApi.isSuccess) {
			setData(statusApi.data?.data);
		}
	}, [statusApi]);

	//edit vendor module
	const editHandler = (e) => {
		const id = e.target
			.closest("tr")
			.querySelector("td:first-child>span")
			.getAttribute("data-idfe");
		setShowHide(true);
		// //sending id to edit vendor modal
		setEditId(id);
	};

	useEffect(() => {
		setSearch("");
		//request
		if (refresh === "status") {
			statusApi.refetch();
		}
	}, [refresh]);

	return (
		<div className="non-seprate">
			<div className="d-flex align-items-center float-end w-25 mb-1 ">
				<AppModal
					showModal={showHide}
					onClose={closeModal}
					afterClose={afterClose}
					element={
						<StatusModal
							//passing row customer editId
							editId={editId}
							afterClose={initialState}
							setShowHide={setShowHide}
						/>
					}
					width={560}
				/>
				<div className=" me-1 w-100 ">
					<Input
						placeholder="Search here"
						className="global-search "
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						prefix={<FaSearch color="lightgray" />}
					/>
				</div>
				{parcelStatusModule.map((item,index) => {
					if (item.add_button) {
						return (
							<div key={index} className="add_icon" onClick={AddVendorHandler}>
								<FaPlus />
							</div>
						);
					}
					return null;
				})}
			</div>
			<div style={{ clear: "both" }}></div>
			<AppTable
				sort={sort}
				loading={statusApi.isLoading}
				dataSource={data}
				columns={preDefinedData}
				search={search}
			/>
		</div>
	);
}
