import { Col, Row, Alert, notification } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import AppButton from "../../../Components/AppButton/AppButton";
import AppInput from "../../../Components/AppInput/AppInput";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import InputColor from "../../../Components/InputColor/InputColor";

import AppSpinner from "../../../Components/AppSpinner/AppSpinner";
import { useAddNewExemptionMutation, useEditExemptionQuery,useUpdateExemptionMutation} from "../../../Redux/Services/ExemptionApi";

export default function ExemptionModal({ afterClose, setShowHide, editId }) {
  const [values, setValues] = useState({ colour_code: "", name: "" });

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value.replace(/[^a-zA-Z." "]/gi, ""),
    });
  };
  const handleReset = (e) => {
    e.preventDefault();
    setValues({ ...values, name: "" });
  };

  //status
  const [getError, setGetError] = useState(null);

  //clear error function
  function clearStatus() {
    setGetError(null);
  }

  // calling redux store
  const { token, user } = useSelector((state) => state.auth);

  //calling rtk query hook for adding new labels
  const addStatusApi = useAddNewExemptionMutation();
  const [addNewStatus] = addStatusApi;

  //calling rtk query hook for update
  const updateStatusApi = useUpdateExemptionMutation();
  const [updateStatus] = updateStatusApi;
  //calling rtk query hook for geting status
  const editStatusApi = useEditExemptionQuery(
    { id: editId, token: token },
    { skip: !editId }
  );

  //SETdEFAULT
  useEffect(() => {
    setValues({
      name: "",
    });
  }, [afterClose]);

  //useEffet for  add labels
  useEffect(() => {
    if (addStatusApi[1].isSuccess) {
      setShowHide(false);
      clearStatus();
      ///after add details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: addStatusApi[1].data?.message,
      });
    }
    if (addStatusApi[1].error) {
      var errorData = [];
      for (const prop in addStatusApi[1].error.data?.error) {
        errorData.push(addStatusApi[1].error.data?.error[prop]);
      }
      setGetError(
        errorData[0] ||
          addStatusApi[1].error.data.message ||
          addStatusApi[1].error.error ||
          addStatusApi[1].error.data?.msg.sqlMessage
      );
    }
  }, [addStatusApi[1].isLoading]);

  //useEffect for editCustomer
  useEffect(() => {
    if (editStatusApi.isSuccess) {
      //filter values from user branchList and customer list for initials values
      setValues(editStatusApi.data?.data[0]);
      clearStatus();
    }
    if (editStatusApi.isError) {
      var errorData = [];
      for (const prop in editStatusApi[1].error.data?.error) {
        errorData.push(editStatusApi[1].error.data?.error[prop]);
      }
      setGetError(
        errorData[0] ||
          editStatusApi.error.data.message ||
          editStatusApi.error.data ||
          editStatusApi.error.error ||
          editStatusApi.error.data?.msg.sqlMessage
      );
    }
  }, [editStatusApi]);

  //update user details
  useEffect(() => {
    if (updateStatusApi[1].isSuccess) {
      setShowHide(false);
      clearStatus();
      ///after submit details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: updateStatusApi[1].data?.message,
      });
    }
    if (updateStatusApi[1].error) {
      var errorData = [];
      for (const prop in updateStatusApi[1].error.data?.error) {
        errorData.push(updateStatusApi[1].error.data?.error[prop]);
      }
      setGetError(
        errorData[0] ||
          updateStatusApi[1].error.data.message ||
          updateStatusApi[1].error.error ||
          updateStatusApi[1].error.data?.msg.sqlMessage
      );
    }
  }, [updateStatusApi[1].isLoading]);
  const inputs = [
    {
      id: 1,
      name: "name",
      col: 12,
      color: true,
      value: values.name || "",
      placeholder: "Enter Exemption Name",
      errorMessage: "Please Enter Exemption Name!",
      required: true,
    },
  ];


  //adding new color status with name and color
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!editId) {
      addNewStatus({
        data: { ...values, },
        token: token,
      });
    }
    if (editId) {
      updateStatus({
        data: {
          ...values,
        //   updated_by: user?.user_id,
        },
        id: editId,
        token: token,
      });
    }
  };

  //useEffect for editCustomer refecth
  useEffect(() => {
    if (editId) {
      editStatusApi.refetch();
    }
    clearStatus();
  }, [afterClose]);

  return (
    <>
      <div className="my-4 border-bottom border-2">
        <h5>{editId ? "Edit Exemption" : "Add Exemption"}</h5>
      </div>
      <div className="my-3">
        {getError && (
          <Alert type="error" message={getError} closable showIcon />
        )}
      </div>

      <Form onSubmit={handleSubmit}>
        {(editStatusApi.isFetching || addStatusApi[1].isLoading || updateStatusApi[1].isLoading) && (
          <AppSpinner />
        )}

        <div className={`${editStatusApi.isFetching ? "invisible" : ""} `}>
       
          <Row className="w-100 position-relative align-items-center d-flex">
            {inputs.map((input) => (
              <div className="w-75" key={input.id}>
                <AppInput
                  {...input}
                  defaultSet={afterClose}
                  value={values[input.name]}
                  onChange={onChange}
                />
              </div>
            ))}
         
          </Row>

          <Row>
            <Col className="my-3">
              <div className="d-flex justify-content-center">
                <div className="me-md-2 ">
                  <AppButton
                    onClick={handleReset}
                    title={"CLEAR"}
                    variant={"secondary-md"}
                  />
                </div>

                <AppButton title={"SAVE"} variant={"primary-md"} />
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    </>
  );
}
