import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import "./AppSelect.css";
import { BsExclamationCircle } from "react-icons/bs";
import { FaAngleDown } from "react-icons/fa";
export default function AppSelect(props) {
  const [focused, setFocused] = useState(false);

  const {
    subhead,
    column,
    label,
    name,
    errorMessage,
    onChange,
    id,
    _id,
    classlabelstyle,
    hide,
    margin,
    defaultSet,
    options,
    classStyle,
    disabled,
    ...inputProps
  } = props;

  const handleFocus = (e) => {
    setFocused(true);
  };

  const setOnChange = (e) => {
    onChange(name, e.target.value, e.target?.selectedOptions[0]);
  };

  useEffect(() => {
    setFocused(false);
  }, [defaultSet]);

  return (
    <div className={`col-12 col-md-${column}`}>
      {/* {subhead && <h6 className="mt-3">{subhead}</h6>} */}
      {label && (
        <Row className={margin ? `mt-${margin}` : "mt-3"}>
          <label
            className={`${classlabelstyle || "ms-1 heading-5 text-dark"} `}
          >
            {label}
            {inputProps.required && <span className="text-danger">*</span>}
            {inputProps.lableIcon}{" "}
            {inputProps.isEdit && <span className="text-dark fw-bold"> :</span>}
          </label>
        </Row>
      )}
      <div className="position-relative">
        {!inputProps.isEdit ? (
          <>
            <select
              id={_id}
              name={name}
              className={`${classStyle || "form-control  w-100 heading-5"}  ${disabled ? "disabled" : ""
                } ${options?.length === 0 && "not-avail"}`}
              disabled={disabled}
              focused={focused.toString()}
              {...inputProps}
              onChange={setOnChange}
              onBlur={handleFocus}
            >
              {options?.length === 0 && (
                <option
                  className="bg-dannger"
                  value={""}
                >{` ${label} list not available`}</option>
              )}
              {options}
              {/* {inputProps?.options.map((ele) => {
          return (
            <option value={Number(ele.name)} key={ele.name}>
              {ele.name}
            </option>
          );  
        })} */}
            </select>
            <span className="input-erro">
              <BsExclamationCircle className="alertIcon" />
              {errorMessage}
            </span>
            {!classStyle && (
              <div className="select_icon">
                <FaAngleDown />
              </div>
            )}
          </>
        ) : (
          <div className="mx-1 mt-0 w-100 heading-5" style={{ marginTop: 15 }}>
            {inputProps.value || "NA"}
          </div>
        )}
      </div>
    </div>
  );
}
