import { each, groupBy } from "@antv/util";
import { FiFilter, FiRotateCw } from "react-icons/fi";
import { useEffect, useState } from "react";
import { Button, Alert, Card, Empty } from "antd";
import { Excel } from "antd-table-saveas-excel";
import { useSelector } from "react-redux";
import AppSpinner from "../../../../Components/AppSpinner/AppSpinner";
import { FiRefreshCcw } from "react-icons/fi";
import { useCustomerListQuery } from "../../../../Redux/Services/Customers";
import { scrollComp } from "../../../../Controllers/Functions";
import { useCustomerWiseUserListMutation } from "../../../../Redux/Services/User";
import "../../Reports.css";
import ColumnBarChart from "../../../../Components/ColumnBarChart/ColumnBarchart";
import AppFilterSelect from "../../../../Components/AppFilterSelect/AppFilterSelect";
import { DatePicker } from "antd";
import { useGetPreDefaultUserTypesMutation, useOrdersTaxStatusListMutation } from "../../../../Redux/Services/ReportsListApi";
const { RangePicker } = DatePicker;

export const TaxStatusReport = ({ refresh, childTab }) => {
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(true);
  const [getError, setGetError] = useState(false);

  const [stateData, setStateData] = useState([{ id: "", state_name: "" }]);
  //calling redux stor for vendors list
  const { token } = useSelector((state) => state.auth);
  const [customertypeList, setCustomerTypeList] = useState([]);

  // const [form] = Form.useForm();
  const [filetrVal, setFiletrVal] = useState({
    state: 51,
    employee: "",
    customer: "",
  });
  const [userOptions, setUserOptions] = useState([]);
  //calling redux stor for vendors list

  //calling redux stor for vendors list
  const { stateList } = useSelector((state) => state.stateList);

  const [employeeList, employeeListResult] = useCustomerWiseUserListMutation();


  
  // useEffect for stateslist
  useEffect(() => {
    if (employeeListResult.isSuccess) {
      setUserOptions(employeeListResult.data?.data);
    }
  }, [employeeListResult.isLoading]);
  const [ordersTaxStatusList, OrdersTaxStatusListResult] =
    useOrdersTaxStatusListMutation();
  useEffect(() => {
    if (OrdersTaxStatusListResult.isSuccess) {
      const newData = OrdersTaxStatusListResult.data?.data?.map(
        ({ id, employee_name, type, value, ...others }) => {
          return {
            ...others,
            id,
            key: id,
            employee_name: employee_name,
            type: type,
            value: value,
          };
        }
      );
      setData(newData);
    } else {
      setData([]);
    }
  }, [OrdersTaxStatusListResult.isLoading]);

//
useEffect(()=>{
  if(refresh==="OrdersStatus"){
    ordersTaxStatusList({
      data: {
        state_id: Number(filetrVal.state),
        customer_id: Number(filetrVal.customer),
        employee_id: Number(filetrVal.employee),
        start_date: selectedDateRange[0]?.format("YYYY-MM-DD"),
        end_date: selectedDateRange[1]?.format("YYYY-MM-DD"),
      },
      token: token,
    });
    setOpen(true);
  }
},[refresh])


  //demo data
  const dataWithLabels = data.map(
    ({ employee_name, customer_name, value, type }) => ({
      category: employee_name || customer_name,
      value,
      type,
    })
  );
  // total counts
  const annotations = [];
  each(groupBy(dataWithLabels, "category"), (values, k) => {
    const value = values.reduce((a, b) => a + b.value, 0);
    annotations.push({
      type: "text",
      position: [k, value],
      content: `${value}`,
      style: {
        textAlign: "center",
        fontSize: 14,
        fill: "rgba(0,0,0,0.85)",
      },
      offsetY: -10,
    });
  });
  const config1 = {
    data: dataWithLabels,
    xField: "category",
    yField: "value",
    seriesField: "type",
    isGroup: false,
    isStack: true,
    formatter: (text) => text.slice(0, 10),
    xAxis: {
      tickCount: 5,
    },
    smooth: true,
    padding: "15",
    height: 300,
    width: 300,
    annotations: annotations,
  };

  const Customer = useCustomerListQuery(token);
  useEffect(() => {
    if (Customer.isSuccess) {
      setCustomerTypeList(Customer.data?.data);
    }
  }, [Customer.isFetching]);

  //date handler
  function handleDateRangeChange(dates) {
    setSelectedDateRange(dates);
    ordersTaxStatusList({
      data: {
        state_id: Number(filetrVal.state),
        customer_id: Number(filetrVal.customer),
        employee_id: Number(filetrVal.employee),
        start_date: dates[0]?.format("YYYY-MM-DD"),
        end_date: dates[1]?.format("YYYY-MM-DD"),
      },
      token: token,
    });
  }

  //useEffect for setting default values
  useEffect(() => {
    setStateData(stateList);
  }, []);

  const Excelcolumns = [
    {
      title: `Customer Name`,
      dataIndex: "customer_name",
      key: "customer_name",
    },
    {
      title: `Employee Name`,
      dataIndex: "employee_name",
      key: "employee_name",
    },
    {
      title: "Tax Status",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Total Orders",
      dataIndex: "value",
      key: "value",
    },
  ];

  const filtercolumns = Excelcolumns.filter((column) => {
    const dataIndex = column.dataIndex;
    return data.every((item) => item[dataIndex] !== null);
  });

  useEffect(() => {
    //request
    if (childTab === "TaxStatus") {
      setGetError(false);
    }
  }, [childTab]);

  //options for showing filters
  const options = [
    {
      name: "state",
      value: filetrVal.state,
      data: stateData?.map(({ state_name, id }) => {
        return { name: state_name, id };
      }),
    },
    {
      name: "customer",
      value: filetrVal.customer,
      data: customertypeList?.map(({ name, id }) => {
        return { name: name, id };
      }),
    },
    {
      name: "employee",
      value: filetrVal.employee,
      data: userOptions?.map(({ first_name, last_name, id }) => {
        const fullname = `${first_name} ${last_name || ""}`;
        return { name: fullname, id };
      }),
    },
  ];

  //function
  function clearState() {
    setFiletrVal({
      ...filetrVal,
      state: "",
    });
  }
  //function
  function clearCustomer() {
    setUserOptions([]);
    setFiletrVal({
      ...filetrVal,
      customer: "",
    });
  }
  //function
  function clearEmployee() {
    //reset
    setFiletrVal({
      ...filetrVal,
      employee: "",
    });
  }
  //function
  function ResetData() {
    //reset
    setFiletrVal({
      ...filetrVal,
      state: "",
      customer: "",
      employee: "",
    });
    setUserOptions([]);
  }

  //error
  useEffect(() => {
    if (getError) {
      scrollComp("OdersStatusReportError");
    }
  }, [getError]);

  const handleClick = () => {
    const excel = new Excel();
    excel
      .addSheet("test")
      .addColumns(filtercolumns)
      .addDataSource(data, {
        str2Percent: true,
      })
      .saveAs("OrderStatusReport.xlsx");
  };



  return (
    <>
      <div id="OdersStatusReportError">
        {getError && <Alert message={getError} type="error" showIcon />}
      </div>
      {(OrdersTaxStatusListResult.isLoading ||
        employeeListResult.isLoading) && <AppSpinner />}

      <div className="d-flex align-items-center  mb-1 gap-2 justify-content-end">
        <>
          <div
            className="file-icons"
            data-toggle="tooltip"
            title="Excel Export"
          >
            <Button size="small" type="primary" onClick={handleClick}>
              Export Data
            </Button>
          </div>

          <div className="filter-button ">
            <Button
              className="filter-btn"
              onClick={() => {
                setOpen(!open);
                setFiletrVal({ state: 51, customer: "", employee: "" });
                setSelectedDateRange([]);
                setUserOptions([]);
              }}
              type={open ? "primary" : "default"}
            >
              <FiFilter color={open ? "#fff" : ""} />{" "}
              <span className="ms-1">Filter</span>
            </Button>
          </div>
          <FiRefreshCcw
            type="button"
            id="template"
            onClick={() => {
              ResetData();
              setSelectedDateRange([]);
            }}
            title="Refresh"
            size={20}
          />
        </>
      </div>
      <div>
        {open && (
          <div className="p-2 bg-white border shadow-sm">
            <div className="my-2  d-flex align-items-center ">
              <div className="row w-100 ">
                {options?.map(({ value, name, data, disabled }, index) => {
                  return (
                    <div className="col-12 col-lg-3">
                      <AppFilterSelect
                        key={index}
                        name={name}
                        disabled={disabled}
                        value={value}
                        options={data}
                        onClear={() => {
                          if (name === "state") {
                            clearState();
                          } else if (name === "customer") {
                            clearCustomer();
                          } else if (name === "employee") {
                            clearEmployee();
                          }
                        }}
                        onChange={(e) => {
                          setFiletrVal({
                            ...filetrVal,
                            [name]: e.target.value,
                          });

                          if (name === "state" && e.target.value) {
                            setFiletrVal({
                              ...filetrVal,
                              state: e.target.value,
                            });
                            ordersTaxStatusList({
                              data: {
                                state_id: e.target.value,
                                customer_id: Number(filetrVal.customer),
                                employee_id: Number(filetrVal.employee),
                                start_date: selectedDateRange[0]?.format("YYYY-MM-DD"),
                                end_date: selectedDateRange[1]?.format("YYYY-MM-DD"),
                              },
                              token: token,
                            });
                          } else if (name === "customer" && e.target.value) {
                            setFiletrVal({
                              ...filetrVal,
                              customer: e.target.value,
                            });
                            employeeList({
                              data: { customer_id: e.target.value },
                              token,
                            });
                            ordersTaxStatusList({
                              data: {
                                state_id: Number(filetrVal.state),
                                customer_id: e.target.value ,
                                employee_id: Number(filetrVal.employee),
                                start_date: selectedDateRange[0]?.format("YYYY-MM-DD"),
                                end_date: selectedDateRange[1]?.format("YYYY-MM-DD"),
                              },
                              token: token,
                            });
                          } else if (name === "employee" && e.target.value) {
                            setFiletrVal({
                              ...filetrVal,
                              employee: e.target.value,
                            });
                            ordersTaxStatusList({
                              data: {
                                state_id: Number(filetrVal.state),
                                customer_id: Number(filetrVal.customer),
                                employee_id: e.target.value,
                                start_date: selectedDateRange[0]?.format("YYYY-MM-DD"),
                                end_date: selectedDateRange[1]?.format("YYYY-MM-DD"),
                              },
                              token: token,
                            });
                          }
                        }}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="row w-50">
                <div className="col-lg-8">
                  <RangePicker
                    value={selectedDateRange}
                    format={"YYYY-MM-DD"}
                    onChange={handleDateRangeChange}
                    allowClear={false}
                  />
                </div>
              </div>
              <div className="d-flex justify-centent-end ms-md-2">
                <Button
                  className="reset-btn"
                  onClick={() => {
                    ResetData();
                    setSelectedDateRange([]);
                  }}
                  type="default"
                >
                  <FiRotateCw /> <span className="ms-1"> Reset</span>
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div style={{ clear: "both" }}></div>

      <Card className="invoice-chart-row mt-3">
        <div className="d-flex align-items-center  mb-1 gap-2  justify-content-between">
          <div>
            <h5 align="left" className="h-5 ">
              Orders Tax Status Graph
            </h5>
          </div>
        </div>
        <div className="row mt-3">
          {data?.length !== 0 ? (
            <ColumnBarChart {...config1} />
          ) : (
            <Empty className="mt-4 mb-3" />
          )}
        </div>
      </Card>
    </>
  );
};
