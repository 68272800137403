import React, { useState } from "react";
import { Alert, Collapse, Input, Popconfirm } from "antd";
import AppTable from "../AppTable/AppTable";
import { FaPlus, FaSearch, FaStarOfLife } from "react-icons/fa";
import "./MultiSubdivisions.css";
import {
  CaretRightOutlined,
  EditOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

import AppInput from "../AppInput/AppInput";
import AppCheckbox from "../AppCheckbox/AppCheckbox";
import { MdOutlineDelete } from "react-icons/md";
import { useEffect } from "react";
import AppSelect from "../AppSelect/AppSelect";
import { asendingList, removeBlank } from "../../Controllers/Functions";
import { HiOutlineDocumentText } from "react-icons/hi";
const randomPass = require("secure-random-password");
const { Panel } = Collapse;
export default function MultiSubdivisions({
  afterClose = false,
  addFormVal = false,
  initailValArr = [],
  updateContact = false,
  deleteContact = false,
  countyList = [],
  whileEdit = false,
  _id,
}) {
  const initailVal = {
    name: "",
    keyword: "",
    county_id: "",
    county_name: "",
    code: "",
  };
  const [values, setValues] = useState(initailVal);
  const [data, setData] = useState(initailValArr);
  const [addRowToggler, setAddRowToggler] = useState(false);
  const [editId, setEditId] = useState(null);
  const [getErr, setGetErr] = useState(false);
  const [active, setActive] = useState("2");
  const { name, keyword, county_name, code } = values;
  const [search, setSearch] = useState("");
  //edit row
  const editHandler = (record) => {
    setEditId(record);
    setAddRowToggler(true);
    setValues(record);
  };

  const columns = [
    {
      title: <span>Subdivision County</span>,
      dataIndex: "county_name",
      key: "county_name",
      inputType: "text",
      required: true,

      align: "center",
    },
    {
      title: <span>Subdivision Name</span>,
      dataIndex: "name",
      key: "name",
      inputType: "text",
      required: true,
      render: (value) => {
        return <>{value || " "}</>;
      },
      align: "center",
    },

    {
      title: <span>Subdivision Code</span>,
      dataIndex: "code",
      key: "code",
      inputType: "text",
      render: (value) => {
        return <>{value || " "}</>;
      },
      align: "center",
    },
    {
      title: <span>Subdivision Name Keywords</span>,
      dataIndex: "keyword",
      key: "keyword",
      render: (value) => {
        return <>{value || " "}</>;
      },
      inputType: "text",
      align: "center",
    },

    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      width: 130,
      align: "center",
      render: (_, record) => {
        return (
          <>
            <span>
              <EditOutlined
                style={{ cursor: "pointer" }}
                onClick={() => editHandler(record)}
                size={50}
              />
            </span>
            <Popconfirm
              title="Are you sure you want to delete？"
              onConfirm={() => {
                deleteHandler(record);
              }}
              getPopupContainer={(trigger) => trigger.parentElement}
              placement="topLeft"
              zIndex={100}
              icon={
                <QuestionCircleOutlined
                  style={{
                    color: "red",
                  }}
                />
              }
              okText="Yes"
              cancelText="No"
            >
              <span className={"ms-2 "}>
                <MdOutlineDelete style={{ cursor: "pointer" }} size={15} />
              </span>
            </Popconfirm>
          </>
        );
      },
    },
  ];
  // const countyNames = [];
  // data?.map(({ county_name }) => {
  //   countyNames.push(county_name);
  // });

  // const myArrayFiltered = countyList?.filter((el) => {
  //   return !countyNames.includes(el.county_name);
  // });

  const inputs = [
    {
      id: 455,
      name: "county_name",
      _id: "county_name",
      type: "select",
      value: county_name || "",
      column: "3",
      options: countyList || [],
      errorMessage: "Please select county name!",
      required: true,
      icon: <FaStarOfLife size={5} color="red" />,
    },
    {
      id: 1,
      name: "name",
      _id: "name",
      type: "text",
      value: name || "",
      column: "3",
      placeholder: "Enter Subdivision Name",
      errorMessage: "Please enter subdivision name!",
      required: true,
      icon: <FaStarOfLife size={5} color="red" />,
    },
    {
      id: 49855,
      name: "code",
      _id: "code",
      type: "text",
      value: code || "",
      column: "3",
      placeholder: "Enter Subdivision Code",
      errorMessage: "Please enter subdivision code!",
    },
    {
      id: 45489855,
      name: "keyword",
      _id: "keyword",
      type: "text",
      value: keyword || "",
      column: "3",
      placeholder: "Enter Subdivision Keywords",
      errorMessage: "Please enter keyword!",
    },
  ];
  const onChange = (e) => {
    setGetErr(false);
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  //check bbox
  const handleChange = (property, value) => {
    setValues({ ...values, [property]: value });
    setGetErr(false);
    ///
  };
  //sending request

  const handleSubmit = () => {
    if (editId) {
      const newData = data.filter((ele) => {
        return (ele.temp_id || ele.id) !== (editId.temp_id || editId.id);
      });
      setData([...newData, values]);
      setAddRowToggler(false);
      updateContact && updateContact(values);
      setEditId(null);
      setValues(initailVal);
    } else {
      //remove blank values
      removeBlank(values);
      const randomId = randomPass.randomPassword({
        length: 9,
        characters: randomPass.digits,
      });
      setData([
        ...data,
        {
          ...values,
          temp_id: randomId,
        },
      ]);
      //while edit and ading new record directly
      whileEdit({ ...values, temp_id: randomId });
      setValues(initailVal);
      setAddRowToggler(false);
    }
  };

  const cancelHandler = () => {
    setValues(initailVal);
    addFormVal(data);
    setAddRowToggler(false);
  };
  const addNewRecord = () => {
    setAddRowToggler(!addRowToggler);
  };

  const deleteHandler = (id) => {
    setData(
      data.filter((ele) => {
        return (ele?.id || ele?.temp_id) !== (id?.id || id?.temp_id);
      })
    );
    //sending delete id
    deleteContact(id);
  };

  //select handler
  const selectHandler = (property, value, arrtibute) => {
    setValues({
      ...values,
      county_name: value,
      county_id: arrtibute?.getAttribute("data_name"),
    });
  };

  useEffect(() => {
    setAddRowToggler(false);
    setData(initailValArr);
    setValues(initailVal);
    setEditId(null);
    setActive("2");
  }, [afterClose]);

  useEffect(() => {
    addFormVal && addFormVal(data);
  }, [data]);

  return (
    
    <Collapse
      className="m-0 py-0 gray-table site-collapse-custom-collapse"
      expandIcon={({ isActive }) => (
        <CaretRightOutlined
          style={{ fontSize: 14 }}
          className="mt-1"
          rotate={isActive ? 90 : 0}
        />
      )}
      expandIconPosition={"end"}
      activeKey={active}
      accordion
      onChange={(value) => {
        setActive(value);
      }}
    >
      <Panel
        className="my-1 border-0 site-collapse-custom-panel "
        header={
          <h6 className=" mb-0 fw-bold me-2 d-flex align-items-center gap-1">
            <HiOutlineDocumentText size={15} /> <span>Subdivision</span>
          </h6>
        }
        key="1"
      >
        {active === "1" && (
          <div className="gray-table-col  mb-2 p-2" id={_id}>

            <div
              className={`d-flex ${"justify-content-end"} align-items-center my-2 contact-forms`}
            >
              {!addRowToggler && <div className=" me-1 w-25 ">
                <Input
                  placeholder="Search here"
                  className="global-search "

                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  prefix={<FaSearch size={15} color="lightgray" />}
                />
              </div>}

              {!addRowToggler && (
                <div className={`add_icon`} onClick={addNewRecord}>
                  <FaPlus />
                </div>
              )}
            </div>
            {getErr && (
              <div className="mt-3">
                <Alert
                  message={getErr}
                  className="py-1 mx-3"
                  type="error"
                  showIcon
                />{" "}
              </div>
            )}
            {/* //loading  */}
            {/* {emailCheckApi[1].isLoading && <AppSpinner size="small" />} */}

            {(addRowToggler || !initailValArr) && (
              <div className="row bg-white p-2 mx-auto shadow-sm" id="addRow">
                {inputs.map((input) => (
                  <>
                    {input.type === "checkbox" && (
                      <AppCheckbox {...input} handleChange={handleChange} />
                    )}
                    {input.type === "select" && input.name === "county_name" && (
                      <AppSelect
                        key={input.id}
                        {...input}
                        defaultSet={afterClose}
                        onChange={selectHandler}
                        options={
                          <>
                            <option values="">Select Subdivision County</option>
                            {input.options.map((ele) => {
                              return (
                                <option
                                  key={ele.id}
                                  value={ele.county_name}
                                  data_name={ele.id}
                                >
                                  {ele.county_name}
                                </option>
                              );
                            })}
                          </>
                        }
                      />
                    )}
                    {input.type === "text" && (
                      <AppInput
                        defaultSet={afterClose}
                        key={input.id}
                        {...input}
                        value={values[input.name]}
                        onChange={onChange}
                      />
                    )}
                  </>
                ))}
                <div className="col-12 col-lg-1 mt-3 p-0">
                  <div className="d-flex">
                    <span
                      onClick={handleSubmit}
                      className={`btn btn-primary  btn-sm edit-buttons ${!county_name || !name ? "disabled" : "btn-success"
                        } `}
                    >
                      {editId?.id ? "Update" : "Save"}
                    </span>
                    <span
                      onClick={cancelHandler}
                      className="btn btn-primary ms-1 btn-sm edit-buttons"
                    >
                      Cancel
                    </span>
                  </div>
                </div>
              </div>
            )}

            <AppTable
              search={search}
              pagination={data?.length > 50}
              dataSource={asendingList(data, "county_name")}
              columns={columns}
              {...(data?.length > 50  ? { scroll: { y: 240 } } : {})}
            />
          </div>
        )}
      </Panel>
    </Collapse>
  );
}
