import { Alert, Button, Collapse } from "antd";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GlobalContext } from "../../../../../App";
import MergeDocs from "../../../../../Components/MergeDocs/MergeDocs";
import MultiFileUpload from "../../../../../Components/MultifileUpload/MultiFileUpload";
import PDFMerger from "pdf-merger-js/browser";
import {
  blobToBase64,
  dateFun,
  scrollComp,
} from "../../../../../Controllers/Functions";
import {
  useAddParcelDoucmentMutation,
  useDeleteSupportingDocMutation,
  useSupportingDocumentsMutation,
} from "../../../../../Redux/Services/Parcels";
import { parcelContext } from "../../OrderEditor";
import { parcelViewDocHandler } from "../../../../../Redux/reducers/ParcelReducer/Parcel.reducer";
import { md5 } from 'js-md5';
const { Panel } = Collapse;
export default function SupportDocs({ addAutomationDocumentsResult, parcelDocumentCountApi,
  parcelDocumentCountResult }) {
  //context api
  const { state } = useContext(GlobalContext);
  const [globalVal, setGlobalVal] = state;
  const dispatch = useDispatch()
  //context api
  const { controller } = useContext(parcelContext);
  const [parcelController, setParcelController] = controller;

  const [list, setList] = useState([]);

  const [mergeList, setMergeList] = useState([]);
  const [documentCount, setDocumentCount] = useState(0);
  const [getError, setGetError] = useState(false);
  const [getSuccess, setGetSuccess] = useState(false);
  const [document_classification_id, setDocument_classification_id] =
    useState("");
  //redux
  const { token } = useSelector((state) => state.auth);
  const { parceData } = useSelector((state) => state.parcelReducer);

  //api
  const [addDocuments, addDocumentsResult] = useAddParcelDoucmentMutation();

  //api
  const [supportingDocList, supportingDocListResult] =
    useSupportingDocumentsMutation();
  //delete api
  const [deleteDocs, deleteDocsResult] = useDeleteSupportingDocMutation();

  //function
  function clearStatus() {
    setGetSuccess(false);
    setGetError(false);
  }

  const mergeHandler = async (files) => {
    const merger = new PDFMerger();
    for (const file of files) {
      await merger.add(file);
    }
    let fileName = `MergeDocs${dateFun("datetime")}`;
    const mergedPdf = await merger.saveAsBlob();
    addDocuments({
      data: {
        document_list: [
          {
            document: await blobToBase64(mergedPdf),
            document_classification_id: 2,
            name: fileName,
            type: "application/pdf",
          },
        ],
        parcel_id: parceData?.id,
      },
      token,
    });
    // await merger.save(fileName);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const docs = list.map(({ base64, file, size, ...ele }) => {
      const splitBase64 = base64?.split("data:application/pdf;base64,")[1]
      const hash_document = md5(splitBase64);
       console.log(hash_document, 90, "SupportDocs.js"); 
      return { ...ele, document_classification_id, document: base64, ...(document_classification_id == 4 ? { hash_document } : {}) };
    });

   
    const addDocumentType = document_classification_id==4?({cad_info_supporting_files_list:docs}):({document_list:docs});
    addDocuments({
      data: { ...addDocumentType , parcel_id: parceData?.id },
      token,
    });
  };

  //useffects
  // const [parcelDocumentCountApi, parcelDocumentCountResult] = useParcelDocumentCountMutation()

  function parcelDocumentCountApiRefetch() {
    parcelDocumentCountApi({ parcel_id: parceData?.id, token });
    clearStatus();
  }

  useEffect(() => {
    if (addAutomationDocumentsResult?.isSuccess) {
      setMergeList([]);
      if (parceData?.id) {
        if (parceData?.id === parcelController?.parcelId) {
          parcelDocumentCountApiRefetch()
        }
      }
    }
  }, [addAutomationDocumentsResult?.isLoading]);


  useEffect(() => {
    if (parcelDocumentCountResult.isSuccess) {
      setDocumentCount(parcelDocumentCountResult.data.data)
    } if (parcelDocumentCountResult.isError) {
      setDocumentCount(0)
    }
    //global spinner
    setGlobalVal({
      ...globalVal,
      loader: parcelDocumentCountResult.isLoading,
    });
  }, [parcelDocumentCountResult.isLoading]);

  useEffect(() => {
    if (getError) {
      scrollComp("documents_list");
    } else if (getSuccess) {
      scrollComp("documents_list");
    }
  }, [getError, getSuccess]);

  //useEffects for document list info
  useEffect(() => {
    if (addDocumentsResult.isSuccess) {
      setGetSuccess(addDocumentsResult.data?.message);
      setGetError(false);
      setList([]);
      parcelDocumentCountApi({ parcel_id: parceData?.id, token });
      supportingDocList({ parcelId: parceData?.id, token });
      setTimeout(() => {
        setGetSuccess(false);
      }, 15000);
    }
    if (addDocumentsResult.isError) {
      setGetError(
        addDocumentsResult.error?.data?.error ||
        addDocumentsResult.error?.data?.message ||
        addDocumentsResult.error.error ||
        addDocumentsResult.error.data?.msg.sqlMessage
      );
      setGetSuccess(false);
    }
    //global spinner
    setGlobalVal({
      ...globalVal,
      loader: addDocumentsResult.isLoading,
    });
  }, [addDocumentsResult.isLoading]);

  //useEffects for delet document
  useEffect(() => {
    if (deleteDocsResult.isSuccess) {
      setGetSuccess(deleteDocsResult.data?.message);
      setGetError(false);
      supportingDocList({ parcelId: parceData?.id, token });
      parcelDocumentCountApi({ parcel_id: parceData?.id, token });
      setList([]);
      setTimeout(() => {
        setGetSuccess(false);
      }, 15000);
    }
    if (deleteDocsResult.isError) {
      setGetError(
        deleteDocsResult.error?.data?.error ||
        deleteDocsResult.error?.data?.message ||
        deleteDocsResult.error.error ||
        deleteDocsResult.error.data?.msg.sqlMessage
      );
      setGetSuccess(false);
    }
    //global spinner
    setGlobalVal({
      ...globalVal,
      loader: deleteDocsResult.isLoading,
    });
  }, [deleteDocsResult.isLoading]);

  //useEffects for document list info
  useEffect(() => {
    if (supportingDocListResult.isSuccess) {
      const newData = supportingDocListResult.data.data.map(
        ({
          document,
          document_classification_id,
          document_classification_name,
          ...rest
        }) => {
          return { ...rest, base64: document };
        }
      );
      setMergeList(newData);

      setParcelController({ ...parcelController, supportigDocList: newData });
    }
    if (supportingDocListResult.isError) {
      setParcelController({ ...parcelController, supportigDocList: false });
      setGetSuccess(false);
      setMergeList([]);
    }
    // //global spinner
    setGlobalVal({
      ...globalVal,
      loader: supportingDocListResult.isLoading,
    });
  }, [supportingDocListResult.isLoading]);

  //status comperision
  const isCompleteOrder = String(parcelController?.orderData?.status_id) === "6" || String(parcelController?.orderData?.status_id) === "15"

  return (
    <Collapse
      className="rounded-3 border-0 text-white"
      expandIcon={() => <></>}
      accordion
      defaultActiveKey={"1"}
    >
      <Panel
        className="main-bg-color rounded-3 "
        header={
          <div
            className=" text-white d-flex justify-content-between "
            style={{ fontSize: 18, marginBottom: 0 }}
          >
            Supporting Documents
          </div>
        }
        style={{ fontSize: 18 }}
        key="1"
      >
        <div className="row px-2" id="documents_list">
          <div className=" my-2 ">
            {getError && <Alert message={getError} type="error" showIcon />}
            {getSuccess && (
              <Alert message={getSuccess} type="success" showIcon />
            )}
          </div>
          <div className="col-12 col-lg-8 fs-12 text-dark ">
            <div className="view-docs-btn">
              <button className={`btn btn-sm btn-light border`} onClick={() => {
                setMergeList([]);
                if (parceData?.id) {
                  supportingDocList({ parcelId: parceData?.id, token });
                }
              }}>View Documents ({documentCount})</button>
            </div>
            <div className="my-2 p-1 ">
              <MergeDocs
                showCheckBox={!isCompleteOrder}
                onview={(value, type, name) => {
                  dispatch(parcelViewDocHandler(value))

                  setParcelController({
                    ...parcelController,
                    parcelPdfDoc: { type, base64: value, name },
                    parcelTwoView: true,
                    supportigDocListShowFromPdfBtn: false,
                  });
                }}
                onChange={mergeHandler}
                //delete
                ondelete={(value) => {
                  deleteDocs({ id: value, token });
                  setParcelController({
                    ...parcelController,
                    parcelPdfDoc: false,
                    parcelTwoView: false,
                  });
                }}
                setList={setMergeList}
                list={mergeList}
              />
            </div>
          </div>
          {<div className="col-12 col-lg-4">
            <div className="upload-docs">
              <form onSubmit={handleSubmit}>
                <select
                  disabled={isCompleteOrder}
                  onChange={(e) => {
                    setDocument_classification_id(e.target.value);
                  }}
                  required
                  className="border w-100 fs-6 p-1 my-1"
                  name="document_type"
                >
                  <option value={""}>Select Document Type</option>
                  {[{ id: 2, name: "Supporting Documents" }, { id: 4, name: "Cad Data " }]?.map((ele) => {
                    return (
                      <option key={ele.id} value={ele.id}>
                        {ele.name}
                      </option>
                    );
                  })}
                </select>

                { (
                  <MultiFileUpload
                    onChange={(value) => {
                      {
                        clearStatus();
                        setList(value);
                      }
                    }}
                    limit={100}
                    disabled={isCompleteOrder}
                    initialFiles={list}
                    aftreClose={!supportingDocListResult.isLoading}
                    component={
                      <div>
                        <button type="button">Choose Documents</button>
                      </div>
                    }
                  />
                )}

                <Button
                  disabled={list.length === 0}
                  className="my-2 w-100"
                  htmlType="submit"
                  type="primary"
                >
                  SUBMIT
                </Button>
              </form>
            </div>
          </div>}
        </div>
      </Panel>
    </Collapse>
  );
}
