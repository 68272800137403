import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json");
export const customers = createApi({
  reducerPath: "customers",
  tagTypes: ["customers"],
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  endpoints: (builder) => ({
    customerList: builder.query({
      query: (token) => ({
        url: `/customer-list`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ["customers"],
    }),
    addCustomer: builder.mutation({
      query: (data) => ({
        url: `/add-customer`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["customers"],
    }),
    editCustomer: builder.mutation({
      query: (data) => ({
        url: `/edit-customer/${data.id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    updateCustomer: builder.mutation({
      query: (data) => ({
        url: `/update-customer/${data.id}`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["customers"],
    }),
    updateCustomerStatus: builder.mutation({
      query: (data) => ({
        url: `/update-customer-status/${data.id}`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["customers"],
    }),
    updateCustomerContacts: builder.mutation({
      query: (data) => ({
        url: `/update-customer-contact/${data.id}`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    deleteCustomerContacts: builder.mutation({
      query: (data) => ({
        url: `/delete-customer-contact/${data.id}`,
        method: "POST",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    addCustomerContact: builder.mutation({
      query: (data) => ({
        url: `/add-customer-contact`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    customerOptions: builder.mutation({
      query: (data) => ({
        url: `/customer-options/${data.id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    custmerProductType: builder.mutation({
      query: (data) => ({
        url: `/customer-product-type/${data.id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),

  }),
});

export const {
  useCustomerListQuery,
  useAddCustomerMutation,
  useUpdateCustomerMutation,
  useCustomerOptionsMutation,
  useUpdateCustomerStatusMutation,
  useUpdateCustomerContactsMutation,
  useDeleteCustomerContactsMutation,
  useAddCustomerContactMutation,
  useCustmerProductTypeMutation,
  useEditCustomerMutation
} = customers;

//customers type
export const customersType = createApi({
  reducerPath: "customersType",
  tagTypes: ["customersType"],
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  endpoints: (builder) => ({
    addCustomerType: builder.mutation({
      query: (data) => ({
        url: `/add-customertype`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["customersType"],
    }),
    customerTypeList: builder.query({
      query: (token) => ({
        url: `/customertype-list`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ["customersType"],
    }),
    updateCustomerType: builder.mutation({
      query: (data) => ({
        url: `/update-customertype/${data.id}`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["customersType"],
    }),
    deleteCustomerType: builder.mutation({
      query: (data) => ({
        url: `/delete-customertype/${data.id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["customersType"],
    }),
  }),
});

export const {
  useAddCustomerTypeMutation,
  useCustomerTypeListQuery,
  useUpdateCustomerTypeMutation,
  useDeleteCustomerTypeMutation,
} = customersType;
