import { createSlice } from "@reduxjs/toolkit";

const exemptionList = createSlice({
  name: "exemptionList",
  initialState: {
    exemptionList: [],
  },
  reducers: {
    exemptionListAction: (state, action) => {
      state.exemptionList = action.payload;
    },
  },
});

export const {exemptionListAction} = exemptionList.actions;

export default exemptionList.reducer;
