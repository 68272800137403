import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json");
export const Taxcad = createApi({
  reducerPath: "taxcad",
  tagTypes: ["taxcad"],
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  endpoints: (builder) => ({
    addTaxCad: builder.mutation({
      query: (data) => ({
        url: `/tax-cad-upload`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["taxcad"],
    }),
    fulltaxcadList: builder.query({
      query: (token) => ({
        url: `/full-tax-cad-list`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ["taxcad"],
    }),
    taxcadList: builder.mutation({
      query: (data) => ({
        url: `/tax-cad-list/${data.id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      // invalidatesTags:["taxcad"]
    }),
    editTaxCad: builder.query({
      query: (data) => ({
        url: `/edit-tax-cad/${data.id}`,
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      providesTags: ["taxcad"],
    }),
    UpdateTaxCadList: builder.mutation({
      query: (data) => ({
        url: `/update-tax-cad/${data.id}`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["taxcad"],
    }),
    DeleteSingleTaxCadList: builder.mutation({
      query: (data) => ({
        url: `/tax-cad-single-delete/${data.id}`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["taxcad"],
    }),
    batchTaxCadList: builder.query({
      query: (token) => ({
        url: `/batch-list/1`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ["taxcad"],
    }),

    DeleteTaxCadBatch: builder.mutation({
      query: (data) => ({
        url: `/tax-cad-batch-delete/${data.id}`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["taxcad"],
    }),
    taxcadFilterList: builder.mutation({
      query: (data) => ({
        url: `/tax-cad-filter`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    addSingleTaxCad: builder.mutation({
      query: (data) => ({
        url: `/single-tax-cad-upload`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["taxcad"],
    }),
  }),
});

export const {
  useAddTaxCadMutation,
  useFulltaxcadListQuery,
  useTaxcadListMutation,
  useBatchTaxCadListQuery,
  useDeleteSingleTaxCadListMutation,
  useDeleteTaxCadBatchMutation,
  useEditTaxCadQuery,
  useUpdateTaxCadListMutation,
  useTaxcadFilterListMutation,
  useAddSingleTaxCadMutation,
} = Taxcad;
