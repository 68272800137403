import AppTable from "../../../Components/AppTable/AppTable";
import AppModal from "../../../Components/AppModal/AppModal";
import {
  FaSortAmountDown,
  FaSearch,
  FaSortAmountUp,
} from "react-icons/fa";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import SearchColumns from "../../../Components/SearchColumns";
import {
  Input,
  notification,
  Switch,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useUpdateactiveCountyMutation } from "../../../Redux/Services/ActiveCountyApi";
import ActiveCountyModal from "./ActiveCountyModal";
import { useActiveCountyListQuery } from "../../../Redux/Services/ActiveCountyApi";
// import {
//     data,
//     preDefinedData,
// } from "../../../ConfigData/UserTypeConfig";

export default function ActiveCounty({ refresh }) {
  const [showHide, setShowHide] = useState(null);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [editId, setEditId] = useState(null);
  const [initialState, setInitialState] = useState(false);
  //set  ascend or descend sorter state
  const [getsortorder, setSortOrder] = useState(false);
  const [getsortfield, setSortField] = useState("");

  //calling rtk query hook
  const updateActiveCountyStatusApi = useUpdateactiveCountyMutation();
  const [updateActiveCountiesStatus] = updateActiveCountyStatusApi;

  function afterClose() {
    setInitialState(!initialState);
  }

  function closeModal(data) {
    setShowHide(data);
  }

  const sort = (sortOrder, sortfield) => {
    // console.log(sortOrder, sortfield);
    if (sortOrder) {
      setSortOrder(sortOrder);
      setSortField(sortfield);
    } else {
      setSortOrder(false);
    }
  };

  const {notificationModule } = useSelector((state) => state.flagRoles);
  //create function for customer fields
  function matchColumn(value) {
    // if (authColumn.indexOf(value) === -1) {
    //   return false;
    // } else {
    //   return true;
    // }
  }
  const preDefinedData = [
    {
      title: (
        <span>
          State{" "}
          {getsortfield === "state_name" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "state_name" ? (
              <span className="sort-svg">
            <FaSortAmountUp className="text-info" /></span>
            ) : getsortorder === "descend" && getsortfield === "state_name" ? (
                <span className="sort-svg">
            <FaSortAmountDown className="text-info" /></span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "state_name",
      key: "state_name",
      align: "center",
      sorter: (a, b) => a.state_name.localeCompare(b.state_name),
      width: 250,
      ...SearchColumns("stete_name", "Stete Name"),
      editable: true,
    },
    {
      title: (
        <span>
          County{" "}
          {getsortfield === "county_name" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "county_name" ? (
              <span className="sort-svg">
            <FaSortAmountUp className="text-info" /></span>
            ) : getsortorder === "descend" && getsortfield === "county_name" ? (
                <span className="sort-svg">
            <FaSortAmountDown className="text-info" /></span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "county_name",
      key: "county_name",
      align: "center",
      sorter: (a, b) => a.county_name.localeCompare(b.county_name),
      ...SearchColumns("county_name", "County Name"),
      editable: true,
    },
    {
      title: <span>Active Status</span>,
     className: `${notificationModule[2].active_status ? "" : "d-none"}`,
			width: matchColumn(" ") && 100,
      align:"center",
      dataIndex: "activity_status",
      key: "activity_status",
      render: (value, row) => (
        <div className="d-flex justify-content-center">
          <Switch
            onClick={(e) => switchHandler(e, row)}
            className="my-switch"
            size="small"
            checked={value}
          />
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "edit",
      key: "edit",
      className: `${notificationModule[1].action ? "" : "d-none"}`,
			width: matchColumn(" ") && 50,
      align: "center",
      render: (_,row) => (
        <EditOutlined
        onClick={() => editHandler(row.id)}
          size={50}
        />
      ),
    },
  ];

  //redux data store
  const { token, user } = useSelector((state) => state.auth);

  const activeCountyListApi = useActiveCountyListQuery(token);

  useEffect(() => {
    if (activeCountyListApi.isSuccess) {
      setData(activeCountyListApi.data?.data);
    }
  }, [activeCountyListApi]);

  //switch handler
  function switchHandler(value, row) {
    console.log("value",row)
    const data = {
      activity_status: value === true ? 1 : 0,
      updated_by: user?.user_id,
    };
    updateActiveCountiesStatus({
      data,
      id: row.id,
      token: token,
    });
  }
  //update notification
  useEffect(() => {
    if (updateActiveCountyStatusApi[1].isSuccess) {
      ///after submit details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: updateActiveCountyStatusApi[1].data?.message,
      });
    }
  }, [updateActiveCountyStatusApi[1].isLoading]);
  // //edit Notification module
  // const editHandler = (id) => {
  //   setShowHide(true);
  //   // //sending id to edit vendor modal
  //   setEditId(id);
  // };

 
	//edit vendor module
	const editHandler = (id) => {
		setShowHide(true);
		// //sending id to edit vendor modal
		setEditId(id);
    console.log("id",id)
	};

  useEffect(() => {
    setSearch("");
    //request
    if (refresh === "activeCounty") {
      activeCountyListApi.refetch();
    }
  }, [refresh]);

  return (
    <>
      <div className="d-flex align-items-center float-end w-25 mb-1 ">
        <AppModal
          showModal={showHide}
          onClose={closeModal}
          afterClose={afterClose}
          element={
            <ActiveCountyModal
              //passing row customer editId
              editId={editId}
              afterClose={initialState}
              setShowHide={setShowHide}
            />
          }
          width={960}
        />
        <div className=" me-1 w-100 ">
          <Input
            placeholder="Search here"
            className="global-search "
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            prefix={<FaSearch color="lightgray" />}
          />
        </div>
      </div>
      <div style={{ clear: "both" }}></div>
      <AppTable
        sort={sort}
        loading={activeCountyListApi.isLoading}
        dataSource={data}
        columns={preDefinedData}
        search={search}
      />
    </>
  );
}
