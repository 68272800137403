import { createSlice } from "@reduxjs/toolkit";

const countyList = createSlice({
  name: "countyList",
  initialState: {
    countyList: [],
  },
  reducers: {
    countyListAction: (state, action) => {
      state.countyList = action.payload;
    },
  },
});

export const {countyListAction} = countyList.actions;

export default countyList.reducer;
