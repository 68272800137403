import { createSlice } from "@reduxjs/toolkit";

const thirdPartyDocList = createSlice({
  name: "thirdPartyDocList",
  initialState: {
    thirdPartyDocList: [],
  },
  reducers: {
    thirdPartyDocListAction: (state, action) => {
      state.thirdPartyDocList = action.payload;
    },
  },
});

export const {thirdPartyDocListAction} = thirdPartyDocList.actions;

export default thirdPartyDocList.reducer;


