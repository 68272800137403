import AppTable from "../../../Components/AppTable/AppTable";
import SearchColumns from "../../../Components/SearchColumns";
import {
  FaPlus,
  FaSearch,
  FaSortAmountDown,
  FaSortAmountUp,
} from "react-icons/fa";
import { useEffect, useState, useMemo } from "react";
import { Input, Button, Alert, notification } from "antd";
import { Excel } from "antd-table-saveas-excel";
import { useSelector } from "react-redux";
import AppSpinner from "../../../Components/AppSpinner/AppSpinner";
import { FiRefreshCcw } from "react-icons/fi";
import { useCustomerListQuery } from "../../../Redux/Services/Customers";
import { scrollComp } from "../../../Controllers/Functions";
import AppSelect from "../../../Components/AppSelect/AppSelect";

import "../Reports.css";
import {
  useAddPaymentListMutation,
  usePaymentInvoiceListMutation,
  usePaymentReportLogsByCustomerQuery,
} from "../../../Redux/Services/ReportsListApi";
import "../Reports.css";
import { EditOutlined } from "@ant-design/icons";
import AppModal from "../../../Components/AppModal/AppModal";
import PaymentReportModal from "./PaymentReportModule";
import AppExcelUpload from "../../../Components/AppExcelUpload/AppExcelUpload";
import { SiMicrosoftexcel } from "react-icons/si";
import upload_order_file from "../../../Images/paymentsSampleFiles.xlsx";
import PaymentLogs from "./PaymentLogs";
import { AiOutlineClose } from "react-icons/ai";
export const PaymentReportMain = ({ refresh }) => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const[showSpin,setShowSpin]=useState(false)
  const [values, setValues] = useState({
    customer_id: "",
    gf_number: "",
  });
  const [showHide, setShowHide] = useState(null);
  const [upload] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState("1");
  const [initialState, setInitialState] = useState(false);
  const [getError, setGetError] = useState(false);
  const [ShowPaymentLogs, setShowPaymentLogs] = useState(false);
  const [editId, setEditId] = useState(null);
  const [customerData, setCustometData] = useState([{ id: "", name: "" }]);
  //set  ascend or descend sorter state
  const [getsortorder, setSortOrder] = useState(false);
  const [getsortfield, setSortField] = useState("");
  //calling redux stor for vendors list
  const { token } = useSelector((state) => state.auth);
  const [isSubmit, setIsSubmit] = useState(false);
  //   const { paymentList } = useSelector((state) => state?.ReportsList);
  //   console.log("jghdh",paymentList)
  const [excelLogData, setExcelLogData] = useState([]);
  const [normalLogData, setNormalLogData] = useState([]);
  //calling rtk query hook for upload tax-cad data
  const [addPaymentListFile, addPaymentListResult] =
    useAddPaymentListMutation();
  //calling rtk query hook for customer-list options
  const Customer = useCustomerListQuery(token);
  useEffect(() => {
    if (Customer?.isSuccess) {
      setCustometData(Customer?.data?.data);
    }
  }, [Customer.isFetching]);


  const paymentLogListResult = usePaymentReportLogsByCustomerQuery(token);

  //log list api
  useEffect(() => {
    if (paymentLogListResult.isSuccess) {
      setExcelLogData(paymentLogListResult?.data?.excel_updates);
      setNormalLogData(paymentLogListResult?.data?.noraml_updates);
      setShowSpin(false)
      // setShowPaymentLogs(true);
    } else {
      setExcelLogData([]);
      setNormalLogData([]);
      setShowSpin(false)
      // setShowPaymentLogs(false);
    }
    //global spinner
    // setGlobalVal({ ...globalVal, loader: paymentLogListResult.isLoading });
  }, [paymentLogListResult.isFetching]);



  const sort = (sortOrder, sortfield) => {
    // console.log(sortOrder, sortfield);
    if (sortOrder) {
      setSortOrder(sortOrder);
      setSortField(sortfield);
    } else {
      setSortOrder(false);
    }
  };

  // order list query
  function afterClose() {
    setInitialState(!initialState);
  }

  const AddPaymentHandler = () => {
    setShowHide(true);
    setEditId(null);
  };

  function closeModal(data) {
    setShowHide(data);
  }


  const Excelcolumns = [
    {
      title: "Customer",
      dataIndex: "customer_name",
      key: "customer_name",
    },

    {
      title: "GF Number",
      dataIndex: "gf_number",
      key: "gf_number",
    },
    {
      title: "Status",
      dataIndex: "payment_status_check",
      key: "payment_status_check",
      render: (_, row) =>
        row?.payment_status_check === 1 ? "Paid" : "Not Paid",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Mode",
      dataIndex: "mode",
      key: "mode",
    },
    {
      title: "Reference Number",
      dataIndex: "reference_number",
      key: "reference_number",
    },
    {
      title: "Paid Date",
      dataIndex: "paid_date",
      key: "paid_date",
    },
  ];

  const ExcelcolumnsAllReport = [
    {
      title: "Customer",
      dataIndex: "customer_name",
      key: "customer_name",
    },

    {
      title: "Total Orders",
      dataIndex: "total_orders",
      key: "total_orders",
    },
    {
      title: "Paid Orders",
      dataIndex: "paid_orders",
      key: "paid_orders",
      render: (_, row) =>
        row?.paid_orders === 1 ? "Paid" : "Not Paid",
    },
    {
      title: "Not Paid Orders",
      dataIndex: "notpaid_orders",
      key: "notpaid_orders",
    },
  ];

  const [getPaymetInvoice, paymentInvoiceResult] =
    usePaymentInvoiceListMutation();
  useEffect(() => {
    if (paymentInvoiceResult.isSuccess) {
      const newData = paymentInvoiceResult.data?.data?.map(
        ({
          order_id,
          customer_name,
          amount,
          reference_number,
          mode,
          payment_status_check,
          gf_number,
          paid_date,
          ...others
        }) => {
          return {
            ...others,
            order_id,
            key: order_id,
            customer_name: customer_name,
            amount: amount,
            reference_number: reference_number,
            mode: mode,
            gf_number: gf_number,
            paid_date: paid_date,
            payment_status_check,
          };
        }
      );
      setData(newData);
    } else {
      setData([]);
    }
  }, [paymentInvoiceResult.isLoading]);

  //columns

  const FilterPaymet = () => {
    if (values?.gf_number) {
      getPaymetInvoice({
        data: {
          customer_id: Number(values.customer_id),
          gf_number: values.gf_number,
        },
        token: token,
      });
    }
    setGetError(false);
  };

  const columns = [
    {
      title: (
        <span>
          Customer Name{" "}
          {getsortfield === "customer_name" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "customer_name" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" && getsortfield === "customer_name" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "customer_name",
      key: "customer_name",
      sorter: (a, b) => a.customer_name.localeCompare(b.customer_name),
      width: 200,
      align: "center",
      ...SearchColumns("customer_name", "Customer Name"),
    },
    {
      title: (
        <span>
          GF Number{" "}
          {getsortfield === "gf_number" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "gf_number" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" && getsortfield === "gf_number" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "gf_number",
      key: "gf_number",
      sorter: (a, b) => a.gf_number.localeCompare(b.gf_number),
      width: 200,
      align: "center",
      ...SearchColumns("gf_number", "GF Number"),
    },
    {
      title: (
        <span>
          Status{" "}
          {getsortfield === "payment_status_check" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" &&
            getsortfield === "payment_status_check" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" &&
            getsortfield === "payment_status_check" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "payment_status_check",
      key: "payment_status_check",
      sorter: (a, b) => a?.payment_status_check - b?.payment_status_check,
      width: 200,
      align: "center",

      ...SearchColumns("payment_status_check","Status by using 0 and 1"),
      //render:(payment_status_check)=>payment_status_check===1?'Paid':'Not Paid'
      render: (value) => (value ? "Paid" : "Not Paid"),
    },
    {
      title: (
        <span>
          Mode{" "}
          {getsortfield === "mode" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "mode" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" && getsortfield === "mode" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "mode",
      key: "mode",
      sorter: (a, b) => {
        const modeA = a?.mode || "";
        const modeB = b?.mode || "";
        return modeA.localeCompare(modeB);
      },
      width: 200,
      align: "center",
      ...SearchColumns("mode", "Mode"),
    },
    {
      title: (
        <span>
          Amount{" "}
          {getsortfield === "amount" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "amount" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" && getsortfield === "amount" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "amount",
      key: "amount",
      // sorter: (a, b) => a?.amount - b?.amount,
      sorter: (a, b) => {
        const amountA = a?.amount || "";
        const amountB = b?.amount || "";
        return amountA.localeCompare(amountB);
      },
      width: 200,
      align: "center",
      ...SearchColumns("amount", "Amount"),
    },
    {
      title: (
        <span>
          Reference No{" "}
          {getsortfield === "reference_number" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" &&
            getsortfield === "reference_number" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" &&
            getsortfield === "reference_number" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "reference_number",
      key: "reference_number",
      sorter: (a, b) => a?.reference_number - b?.reference_number,
      width: 200,
      align: "center",
      ...SearchColumns("reference_number", "Reference number"),
      render: (value) => (
        <span>{value === null || value === "null" ? "" : value}</span>
      ),
    },
    {
      title: (
        <span>
          Paid Date{" "}
          {getsortfield === "paid_date" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "paid_date" ? (
            <span className="sort-svg">
              <FaSortAmountUp className="text-info" />
            </span>
          ) : getsortorder === "descend" && getsortfield === "paid_date" ? (
            <span className="sort-svg">
              <FaSortAmountDown className="text-info" />
            </span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "paid_date",
      key: "paid_date",
      sorter: (a, b) => {
        const paid_dateA = a?.paid_date || "";
        const paid_dateB = b?.paid_date || "";
        return paid_dateA.localeCompare(paid_dateB);
      },
      // ...dateSorted ("created_at"),
      width: 200,
      align: "center",
      ...SearchColumns("paid_date", "Paid Date"),
    },
    {
      title: "Action",
      dataIndex: "edit",
      key: "edit",
      align: "center",
      // className: `${hoaStatusModule[1].action ? "" : "d-none"}`,
      width: 50,
      render: (_, row) => (
        <div className="text-center">
          <EditOutlined
            onClick={() => editHandler(row.order_id)}
            cursor={"pointer"}
            size={"20px"}
            color="gray"
          />
        </div>
      ),
    },
  ];

  //edit vendor module
  const editHandler = (id) => {
    setShowHide(true);
    // //sending id to edit vendor modal
    setEditId(id);
  };

  const RefetchData = () => {
    getPaymetInvoice({
      data: {
        customer_id: Number(values.customer_id),
        gf_number: values.gf_number,
      },
      token: token,
    });
    paymentLogListResult.refetch();
    setGetError(false);
  };

  useEffect(() => {
    setSearch("");
    if (refresh === "Payment") {
      getPaymetInvoice({
        data: {
          customer_id: Number(values.customer_id),
          gf_number: values.gf_number,
        },
        token: token,
      });
      setGetError(false);
    }
  }, [refresh]);

  const inputs = [
    {
      id: 4,
      name: "customer_id",
      type: "select",
      value: values.customer_id || "",
      placeholder: "Select Customer Type",
      errorMessage: "Please select customer!",
      // label: "Customer",
      options: customerData,
      column: "3",
      required: false,
    },
    {
      id: 5,
      name: "gf_number",
      type: "text",
      placeholder: "Enter gf_number",
      // errorMessage: "Please enter gf_number!",
      label: "GF Number",
      required: false,
      maxLength: 250,
      column: "3",
    },
  ];

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  //select handler
  const selectHandler = (property, value) => {
    // clearStatus();
    if (property === "customer_id") {
      if (Number(value) !== 0) {
        getPaymetInvoice({
          data: {
            customer_id: Number(value),
            // gf_number: values.gf_number,
          },
          token: token,
        }).then(() => {
          setValues({
            customer_id: Number(value),
            gf_number: "",
          });
        });
        setValues({
          ...values,
          [property]: Number(value),
        });
        setGetError(false);
      } else {
        setValues({
          ...values,
          [property]: null,
        });
      }
    }
    setValues({
      ...values,
      [property]: Number(value),
    });
    setGetError(false);
  };

  //useEffect for upload-tax-cad file
  useEffect(() => {
    if (addPaymentListResult.isSuccess) {
      ///after submit details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: addPaymentListResult.data?.message,
        // duration: null,
      });
      setGetError(false);
      setShowSpin(true)
      setActiveTabKey("1");
      paymentLogListResult.refetch();
      getPaymetInvoice({
        data: {
          customer_id: Number(values.customer_id),
          gf_number: values.gf_number,
        },
        token: token,
      })
      setIsSubmit(true);
     
      setTimeout(() => {
        setShowSpin(false)
        paymentLogListResult.refetch();
        setExcelLogData(excelLogData?.slice(0, 1));
        // setIsSubmit(true);
        setShowPaymentLogs(true);
        <AppSpinner/>
      }, 3000);    
    }
    if (addPaymentListResult.isError) {
      setShowSpin(false)
      setGetError(
        addPaymentListResult.error?.data?.error ||
          addPaymentListResult.error?.data?.msg ||
          addPaymentListResult.error.data?.message?.sqlMessage ||
          addPaymentListResult.error.data.message ||
          (addPaymentListResult.error.error && "server unreachable!")
      );

      setIsSubmit(false);
      setShowPaymentLogs(false);
    }
  }, [addPaymentListResult.isLoading]);

  //uploading tax cad file
  const handleUpload = (file) => {
    const new_paymentFile = new FormData();
    new_paymentFile.append("file", file);
    addPaymentListFile({
      data: new_paymentFile,
      token: token,
      id: values.customer_id,
    });
  };

  //onchange of file-upload
  const onChangeFileUpload = (info) => {
    if (values.customer_id) {
      handleUpload(info.file);
    } else if (info.file !== "uploading" || !values.customer_id) {
      console.log("Error");
      setGetError("Select Customer");
      setShowPaymentLogs(false);
      setIsSubmit(false);
      setExcelLogData([]);
    }
  };
  //error
  useEffect(() => {
    if (getError) {
      scrollComp("PaymentReportError");
    }
  }, [getError]);

  const handleClick = () => {
    const excel = new Excel();
    excel
      .addSheet("test")
      .addColumns(Excelcolumns)
      .addDataSource(data, {
        str2Percent: true,
      })
      .saveAs("PaymentReport.xlsx");
  };

  const handleClickAllReport = () => {
    const excel = new Excel();
    excel
      .addSheet("test")
      .addColumns(ExcelcolumnsAllReport)
      .addDataSource(data, {
        str2Percent: true,
      })
      .saveAs("PaymentReport.xlsx");
  };
  
  const cols = useMemo(() => columns, [columns]);
  const data1 = useMemo(() => data, [data]);

  const logDataFetch = () => {
    setShowPaymentLogs(true);
    setIsSubmit(false);
    paymentLogListResult.refetch();
    setGetError(false);
  };

  const LogRefetchSingleOrder = () => {
    setIsSubmit(true);
    // setShowPaymentLogs(true);
    setActiveTabKey("2");
    setTimeout(() => {
    setShowSpin(true)
      paymentLogListResult.refetch();
      //  setIsSubmit(true);
       setShowPaymentLogs(true);
      // setActiveTabKey("2");
      setNormalLogData(normalLogData?.slice(0, 1));
      <AppSpinner/>
    }, 2000);
    paymentLogListResult.refetch();
  };

  //sample upload
  const downloadFile = () => {
    window.location.href = upload_order_file;
  };
  return (
    <>
      <div id="PaymentReportError">
        {getError && <Alert message={getError} type="error" showIcon />}
      </div>
      {(paymentInvoiceResult?.isLoading ||
        addPaymentListResult?.isLoading ||showSpin||
        paymentLogListResult.isFetching && <AppSpinner />)}

      <div className="d-flex align-items-center  mb-1 gap-2 justify-content-end">
        {/* changing input values globaly  */}
        <>
          {showHide && (
            <AppModal
              showModal={showHide}
              onClose={closeModal}
              confirmProp={true}
              afterClose={afterClose}
              element={
                <PaymentReportModal
                  RefetchData={RefetchData}
                  //passing row customer editId
                  editId={editId}
                  afterClose={initialState}
                  setShowHide={setShowHide}
                  refetchLogs={LogRefetchSingleOrder}
                />
              }
              width={960}
            />
          )}
          {ShowPaymentLogs && (
            <AppModal
              width={"85%"}
              onClose={(value) => {
                setShowPaymentLogs(value);
                setIsSubmit(false);
              }}
              showModal={ShowPaymentLogs}
              element={
                <div className="">
                  <PaymentLogs
                    title="Payment Log"
                    data={!isSubmit ? excelLogData : excelLogData?.slice(0, 1)}
                    loading={paymentLogListResult.isFetching}
                    rowClassName={(record, index) =>
                      index === 0 && !isSubmit ? "paylog-row" : ""
                    }
                    manualLogData={
                      !isSubmit ? normalLogData : normalLogData?.slice(0, 1)
                    }
                    activeTab={activeTabKey}
                    setActiveTab={setActiveTabKey}
                    isSubmit={isSubmit}
                  />
                </div>
              }
            />
          )}
          <SiMicrosoftexcel
            size={20}
            color="green"
            title="Sample Template"
            onClick={downloadFile}
          />
          <div
            className="file-icons ml-0 pl-0"
            data-toggle="tooltip"
            title="Payment History"
          >
            <Button size="small" type="primary" onClick={logDataFetch}>
              History
            </Button>
          </div>
          <div
            className="file-icons ml-0 pl-0"
            data-toggle="tooltip"
            title="Export History"
          >
            <Button size="small" type="primary" onClick={handleClickAllReport}>
              Export All
            </Button>
          </div>
          <div
            className="file-icons ml-0 pl-0"
            data-toggle="tooltip"
            title="Export Table"
          >
            <Button size="small" type="primary" onClick={handleClick}>
              Export
            </Button>
          </div>
          <AppExcelUpload
            showRemoveIcon={false}
            margin={"0"}
            upload={upload}
            onChange={onChangeFileUpload}
            // onDrop={onDrop}
            multiple={false}
          />

          {inputs.map((input) => (
            <>
              {input.type === "text" && (
                <div className=" me-0 w-25 ">
                  <Input
                    key={input.id}
                    {...input}
                    value={values[input.name]}
                    onKeyUp={() => {
                      if (!values.gf_number) {
                        getPaymetInvoice({
                          data: {
                            customer_id: Number(values.customer_id),
                            gf_number: values.gf_number,
                          },
                          token: token,
                        });
                        setGetError(false);
                        //   setValues({ ...values, state_id: state_id });
                      }
                    }}
                    onChange={onChange}
                    suffix={
                      <>
                        {" "}
                        {values?.gf_number && (
                          <Button
                            className="m-0"
                            onClick={() => {
                              if (values?.gf_number) {
                                getPaymetInvoice({
                                  data: {
                                    customer_id: Number(values.customer_id),
                                    gf_number: "",
                                  },
                                  token: token,
                                });

                                setGetError(false);
                                //   setValues({ ...values, state_id: state_id, search_column: "" });
                                setValues({
                                  // customer_id: "",
                                  ...values,
                                  gf_number: "",
                                });
                              }
                            }}
                            type="ghost"
                          >
                            <AiOutlineClose />
                          </Button>
                        )}
                        <Button
                          type="primary"
                          disabled={
                            !values?.gf_number || paymentInvoiceResult.isLoading
                          }
                          onClick={() => {
                            // if  search avail
                            if (values?.gf_number) {
                              getPaymetInvoice({
                                data: {
                                  customer_id: Number(values?.customer_id),
                                  gf_number: values?.gf_number,
                                },
                                token: token,
                              });
                            }
                          }}
                        >
                          {paymentInvoiceResult.isLoading ? (
                            "Loading..."
                          ) : (
                            <FaSearch color="#fff" />
                          )}
                        </Button>
                      </>
                    }
                  />
                </div>
              )}
              {input.type === "select" && input.name === "customer_id" && (
                //based on user field flags
                <AppSelect
                  classStyle={
                    "w-100 px-2 py-1 border rounded fs-12 text-dark"
                  }
                  style={{ marginBottom: 1, height: 35 }}
                  key={input.id}
                  {...input}
                  onChange={selectHandler}
                  // defaultSet={afterClose}
                  options={
                    <>
                      <option value={null}> Select Customer</option>
                      {input?.options?.map((ele) => {
                        return (
                          <option key={ele.id} value={ele.id}>
                            {ele.name}
                          </option>
                        );
                      })}
                    </>
                  }
                />
              )}
            </>
          ))}
          <div
            className="add_icon"
            onClick={AddPaymentHandler}
            title="Add New Payments"
          >
            <FaPlus />
          </div>
          <FiRefreshCcw
            type="button"
            id="template"
            onClick={() => {
              setValues({
                customer_id: "",
                gf_number: "",
              });
              getPaymetInvoice({
                data: {
                  customer_id: "",
                  gf_number: "",
                },
                token: token,
              });
            }}
            title="Refresh"
            size={20}
          />
        </>
      </div>
      <div style={{ clear: "both" }}></div>

      <AppTable
        sort={sort}
        loading={
          paymentInvoiceResult.isLoading || addPaymentListResult.isLoading||paymentLogListResult.isFetching
        }
        search={search}
        columns={cols}
        dataSource={data1}
      />
    </>
  );
};
