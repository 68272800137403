import { notification } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useSendEmailCertificateMutation } from "../../../../../Redux/Services/Orders";

import AppSpinner from "../../../../../Components/AppSpinner/AppSpinner";
import { AntdMultiSelectDropdown } from "../../../../../Components/antdMultiSelectDropdown/AntdMultiSelectDropdown";
import { parcelContext } from "../../OrderEditor";
import AppTextEditor from "../../../../../Components/AppTextEditor/AppTextEditor";

const defaultText = `<p><span style="color: inherit">Hello!</span></p>
<p>
  <span style="color: inherit"
    >We would like to request HOA status information for the above listed
    property. <br />
    We have attached the following document to fill out and send back for
    your convenience. <br />
    This is for a TRANSACTION TYPE______</span
  >
</p>
<p>
  <span style="color: inherit"
    >Or if you have internal documents or forms to return to us instead,
    please be sure to include the following information:</span
  >
</p>
<ul>
  <li><span style="color: inherit">Billing Cycle</span></li>
  <li><span style="color: inherit">Assessment Amount</span></li>
  <li><span style="color: inherit">Transfer Fee</span></li>
  <li>
    <span style="color: inherit">Refinance Fee(if applicable)</span>
  </li>
  <li><span style="color: inherit">Resale Certificate Fee</span></li>
  <li><span style="color: inherit">Special Assessments</span></li>
  <li><span style="color: inherit">Special Instructions</span></li>
  <li>
    <span style="color: inherit"
      >Total Assessments Due (payment status)</span
    >
  </li>
</ul>
<p>
  <span style="color: inherit"
    >Title Company and Transaction information is listed in the attached
    document.</span
  >
</p>`
// const defaultText = `<p><span style="color: inherit;">Hello!</span></p><p><span style="color: inherit;">We would like to request HOA status information for the above listed property.We have attached the following document to fill out and send back for your convenience. This is for a TRANSACTION TYPE.</span></p><p><span style="color: inherit;">Or if you have internal documents or forms to return to us instead, please be sure to include the following information:</span></p><ul><li><span style="color: inherit;">Billing Cycle</span></li><li><span style="color: inherit;">Assessment Amount</span></li><li><span style="color: inherit;">Transfer Fee</span></li><li><span style="color: inherit;">Refinance Fee(if applicable)</span></li><li><span style="color: inherit;">Resale Certificate Fee</span></li><li><span style="color: inherit;">Special Assessments</span></li><li><span style="color: inherit;">Special Instructions</span></li><li><span style="color: inherit;">Total Assessments Due (payment status)</span></li></ul><p><span style="color: inherit;">Title Company and Transaction information is listed in the attached document.</span></p>`
export default function EmailModal({
  email,
  random,
  editId,
  afterSubmit,
  refetchApi,
  options = [],
  isLoading,
  showCheckBox,
  onSubmitHandler
}) {
  //context api
  const { controller } = useContext(parcelContext);
  const [parcelController] = controller;

  const [values, setValues] = useState({ email: [], message: (onSubmitHandler && defaultText) || "", is_checkbox: true });
  // const [emailData, setEmailData] = useState("");
  // const [extraEmails, setExtraEmails] = useState([]);
  // const [emailOptions, setEmailOptions] = useState([]);

  // const [addEmail, setAddEmail] = useState(false);
  const { token } = useSelector((state) => state.auth);
  //send email certificate
  const [sendEmailCertificate, sendEmailCertificateResult] =
    useSendEmailCertificateMutation();

  //certifcate send on emal
  useEffect(() => {
    if (sendEmailCertificateResult.isSuccess) {
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: sendEmailCertificateResult.data?.Message,
      });
      //   close modal
      afterSubmit(false);
      //refetcing order
      refetchApi && refetchApi()
    }
    if (sendEmailCertificateResult.isError) {
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description:
          sendEmailCertificateResult.error.data?.Message ||
          "Something went wrong!",
      });
    }
  }, [sendEmailCertificateResult.isLoading]);

  useEffect(() => {
    setValues({ ...values, message: (onSubmitHandler && defaultText) || "", email: [email], is_checkbox: showCheckBox });
  }, [random]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (values.email.length !== 0) {
      if (!onSubmitHandler) {
        sendEmailCertificate({
          order_id: editId,
          token,
          data: {
            to_email_list: values.email,
            message: values.message,
            is_checkbox: values.is_checkbox
          },
        });
      } else {
        onSubmitHandler && onSubmitHandler({
          to_email_list: values.email,
          message: values.message,
          is_checkbox: values.is_checkbox
        })
      }

      // console.log(values);
    }
  };

  // useEffect(() => {
  //   if (values.email.length === 0) {
  //     setAddEmail(true);
  //     setEmailData("");
  //   } else {
  //     setAddEmail(false);
  //     setEmailData("");
  //   }
  // }, [values.email, random]);

  return (
    <div className="position-relative">
      {sendEmailCertificateResult.isLoading && <AppSpinner size="small" />}
      {/* <Multiselect
        isObject={false}
        onKeyPressFn={(e) => {
          e.stopPropagation();
        }}
        onRemove={function noRefCheck(value) {
          setExtraEmails(value);
        }}
        onSelect={function noRefCheck(value) {
          setExtraEmails(value);
        }}
        selectedValues={extraEmails}
        options={emailOptions}
      /> */}
      <label htmlFor="">
        Email <span className="text-danger">*</span>
      </label>
      <br />
      <AntdMultiSelectDropdown
        random={random}
        selected={email ? [email] : undefined}
        options={options}
        setValues={setValues}
        showCheckBox={showCheckBox}
        values={values}
        onChangeVal={(value) => setValues({ ...values, email: value })}
      />

      <form onSubmit={handleSubmit} action="">

        <label htmlFor="" className="mt-2">
          Message
        </label>
        {/* <textarea
          value={values.message || ""}
          onChange={(e) => {
            setValues({ ...values, message: e.target.value });
          }}
          name="message"
          className="w-100 border rounded"
          cols="10"
          rows="4"
        /> */}
        <AppTextEditor
          value={values.message}
          onChange={(newContent) => {
            setValues({ ...values, message: newContent })
            console.log(newContent)
          }}
        />

        {/* {!addEmail && (
          <button
            onClick={() => {
              setAddEmail(true);
            }}
            type="button"
            className="btn btn-secondary btn-sm mt-2 me-1"
          >
            Add Email
          </button>
        )} */}
        <button
          type="submit"
          className={`btn btn-primary btn-sm mt-2 ${values.email.length === 0 ? "cursor-disabled" : null
            }`}
        >
          {sendEmailCertificateResult.isLoading || isLoading ? "Loading..." : " Submit"}
        </button>
      </form>
    </div>
  );
}
