import { createSlice } from "@reduxjs/toolkit";

const taxcollectorList = createSlice({
  name: "taxcollectorList",
  initialState: {
    taxcollectorList: [],
  },
  reducers: {
    taxcollectorListAction: (state, action) => {
      state.taxcollectorList = action.payload;
    },
  },
});

export const {taxcollectorListAction} = taxcollectorList.actions;

export default taxcollectorList.reducer;