import React from "react";
import "./AppButton.css";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
export default function AppButton(props) {
  const { variant, title, isLoading, onClick, type, disabled } = props;
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "white",
      }}
      spin
    />
  );

  return (
    <div >
      <button
        disabled={disabled}
        onClick={onClick}
        type={type}
        className={`${variant}-button  ${disabled && "disabled"} `}
      >
        {isLoading ? <Spin indicator={antIcon} /> : <>{title}</>}
      </button>
    </div>
  );
}
