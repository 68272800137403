import React, { useState } from "react";
import "../order.css"
import { useEffect } from "react";
import { AiOutlineEye } from "react-icons/ai";

import { FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import SearchColumns from "../../../Components/SearchColumns";
import {

    useGetNoiticeNotesMutation,
    useOrderExaminerUpdateMutation,
    useOrderHoaExaminerUpdateMutation,

    useGetDataByStatusMutation
} from "../../../Redux/Services/Orders";
import { Table, Tabs, Typography, Input, Checkbox } from 'antd';

import { HiFlag, } from "react-icons/hi";
import { GlobalContext } from "../../../App";
import { useContext } from "react";

import { resertPacrelData } from "../../../Redux/reducers/ParcelReducer/Parcel.reducer";
import { addOrderData, bakendAutomationHandler } from "../../../Redux/reducers/Order";
import { dateSorted } from "../mainOrder/DynamicContent/functions";

export const integrationOptions = [{ name: "Select Order Type", id: 0, }, { name: "Ramquest", id: 3, }, { name: "Resware", id: 2, }, { name: "Softpro", id: 1 }, { name: "Qualia", id: 4 }]

const { TabPane } = Tabs;
const { Title } = Typography;


const OrderStatus = () => {


    const { token, user } = useSelector((state) => state.auth);
    const [examinerId, setExaminerId] = useState("");
    const { orderTableColumn } = useSelector(
        (state) => state.flagRoles
    );

    const { state } = useLocation();

    const [getsortfield, setSortField] = useState("");
    const [getsortorder, setSortOrder] = useState(false);
    const [noticeId, setNoticeId] = useState();
    const [statusColor, setStatusColor] = useState([]);

    const navigate = useNavigate();

    const dispatch = useDispatch()

    const [searchData, { data: statusData, error, isLoading: loadingStatusData }] = useGetDataByStatusMutation();

    const [getNoiticeNotes, getNoiticeNotesResult] = useGetNoiticeNotesMutation();


    useOrderExaminerUpdateMutation();
    useOrderHoaExaminerUpdateMutation();


    const columns = [
        {
            title: " ",
            data: "flag",
            width: 10,
            render: (value, record) => {
                return (
                    <>
                        {record?.notice_name !== null && record?.notice_comments &&
                            record?.notice_id && (
                                <span className="fs-13 me-2 d-flex gap-1 align-items-center">
                                    <AiOutlineEye
                                        color="green"
                                        onClick={() => {
                                            getNoiticeNotes({ order_id: record.id, token })
                                        }}
                                        cursor={"pointer"}
                                    />
                                    <div className="hover-text-comp position-relative d-inline-block">
                                        <HiFlag
                                            color={
                                                record.notice_id === 1
                                                    ? "#279EFF " // Blue 
                                                    : record.notice_id === 2
                                                        ? "#FFC436" // Yellow 
                                                        : record.notice_id === 3
                                                            ? "#FE0000" //Red
                                                            : record.notice_id === 4
                                                                ? "#1A5D1A" // Green
                                                                : record.notice_id === 5
                                                                    ? "#793FDF" // Purple
                                                                    : record.notice_id === 6
                                                                        ? "#FD8D14" // Orange
                                                                        : record.notice_id === 7
                                                                            ? "#AAFF00" //bright green
                                                                            : record.notice_id === 8
                                                                                ? "#FE0000" //red
                                                                                : record.notice_id === 9
                                                                                    ? "#EA4F0C" //ornage
                                                                                    : ""
                                            }
                                        />
                                        {record?.notice_comments && (
                                            <div className="hover-text-content  border p-2 rounded-3 shadow-sm">
                                                <span className="fs-7">
                                                    {record?.notice_name} :{" "}
                                                    <span>{record?.notice_comments}</span>
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </span >
                            )
                        }
                    </>
                );
            },
        },
        ...(orderTableColumn[0]?.notice ? [
            {
                title: (
                    <span>
                        Notice{" "}
                        {getsortfield === "notice_name" && !getsortorder ? (
                            <FaSortAmountUp />
                        ) : getsortorder === "ascend" && getsortfield === "notice_name" ? (
                            <span className="sort-svg">
                                <FaSortAmountUp className="text-info" />
                            </span>
                        ) : getsortorder === "descend" && getsortfield === "notice_name" ? (
                            <span className="sort-svg">
                                <FaSortAmountDown className="text-info" />
                            </span>
                        ) : (
                            <FaSortAmountUp />
                        )}
                    </span>
                ),
                dataIndex: "notice_name",
                key: "notice_name",
                width: 150,
                align: "left",
                render: (value, record) => {
                    return <>{value}</>;
                },
                filters: noticeId?.map(({ name }) => {
                    return { text: name, value: name };
                }),

                filterMode: "tree",
                ...(state?.notice_name && !state.status_name
                    ? { defaultFilteredValue: [state?.notice_name] }
                    : ""),
            },
        ] : []),
        {
            title: (
                <span>
                    File #{" "}
                    {getsortfield === "file_number" && !getsortorder ? (
                        <FaSortAmountUp />
                    ) : getsortorder === "ascend" && getsortfield === "file_number" ? (
                        <span className="sort-svg">
                            <FaSortAmountUp className="text-info" />
                        </span>
                    ) : getsortorder === "descend" && getsortfield === "file_number" ? (
                        <span className="sort-svg">
                            <FaSortAmountDown className="text-info" />
                        </span>
                    ) : (
                        <FaSortAmountUp />
                    )}
                </span>
            ),
            dataIndex: "file_number",
            key: "file_number",
            sorter: (a, b) => a.file_number.localeCompare(b.file_number),
            width: 200,
            align: "start",
            ...SearchColumns("file_number", "File #"),
            render: (value, record) => {
                return (
                    <>
                        <span
                            className="cursor-pointer p-1 ps-4 d-inline-block w-100"
                            onClick={() => {
                                dispatch(resertPacrelData())
                                dispatch(addOrderData({}))
                                dispatch(bakendAutomationHandler(false))
                                // dispatch(certificateDownloadLoadingHandler(false))
                                // dispatch(viewPdfDocuementHandler(null))
                                navigate("/order-editor", {
                                    state: {
                                        id: record.id,
                                        orderData: record,
                                        orderStatus: record.status_id,
                                        orderNumber: record.file_number,
                                        orderEditableFlag: true,
                                        orderEvent: "view",
                                    },
                                });
                                setExaminerId(record.examiner_id);
                            }}
                        >
                            {record?.parcel_count !== 0 && (
                                <div title={`${record?.parcel_count} Parcels`} className="d-flex flex-wrap gap-1 align-items-center">
                                    <span className=" p-1 text-white justify-content-center align-items-center" style={{ width: record?.parcel_count > 9 ? 20 : 15, height: record?.parcel_count > 9 ? 20 : 15, background: "red", fontSize: record?.parcel_count > 9 ? 7 : 9, borderRadius: "50%", display: "flex" }}>{record?.parcel_count}</span>
                                    <span className="text-start">{value}</span>
                                </div>

                            )}
                            {record?.parcel_count === 0 && <span>{value}</span>}
                        </span>
                    </>
                );
            },
        },
        {
            title: (
                <span>
                    Customer{" "}
                    {getsortfield === "customer_name" && !getsortorder ? (
                        <FaSortAmountUp />
                    ) : getsortorder === "ascend" && getsortfield === "customer_name" ? (
                        <span className="sort-svg">
                            <FaSortAmountUp className="text-info" />
                        </span>
                    ) : getsortorder === "descend" && getsortfield === "customer_name" ? (
                        <span className="sort-svg">
                            <FaSortAmountDown className="text-info" />
                        </span>
                    ) : (
                        <FaSortAmountUp />
                    )}
                </span>
            ),
            dataIndex: "customer_name",
            key: "customer_name",
            sorter: (a, b) => a.customer_name.localeCompare(b.customer_name),
            width: 200,
            align: "center",
            ...SearchColumns("customer_name", "Customer Name"),
        },
        {
            title: (
                <span>
                    County{" "}
                    {getsortfield === "county_name" && !getsortorder ? (
                        <FaSortAmountUp />
                    ) : getsortorder === "ascend" && getsortfield === "county_name" ? (
                        <span className="sort-svg">
                            <FaSortAmountUp className="text-info" />
                        </span>
                    ) : getsortorder === "descend" && getsortfield === "county_name" ? (
                        <span className="sort-svg">
                            <FaSortAmountDown className="text-info" />
                        </span>
                    ) : (
                        <FaSortAmountUp />
                    )}
                </span>
            ),
            dataIndex: "county_name",
            key: "county_name",
            sorter: {
                compare: (a, b) => {
                    const aValue = (a.address || '');
                    const bValue = (b.address || '');
                    return aValue.localeCompare(bValue);
                },

            },
            width: 150,
            align: "center",
            ...SearchColumns("county_name", "County Name"),
        },
        {
            title: (
                <span>
                    Address{" "}
                    {getsortfield === "address" && !getsortorder ? (
                        <FaSortAmountUp />
                    ) : getsortorder === "ascend" && getsortfield === "address" ? (
                        <span className="sort-svg">
                            <FaSortAmountUp className="text-info" />
                        </span>
                    ) : getsortorder === "descend" && getsortfield === "address" ? (
                        <span className="sort-svg">
                            <FaSortAmountDown className="text-info" />
                        </span>
                    ) : (
                        <FaSortAmountUp />
                    )}
                </span>
            ),
            dataIndex: "address",
            key: "address",
            sorter: (a, b) => {
                const valueA = a.address || "";
                const valueB = b.address || "";
                if (!valueA && !valueB) return 0;
                if (!valueA && valueB) return 1;
                if (valueA && !valueB) return -1;
                return valueA.localeCompare(valueB)
            },

            width: 200,
            align: "center",
            ...SearchColumns("address", "Address"),
            render: (value) => {
                return (
                    <>
                        <div className="hover-text-comp position-relative">
                            {" "}
                            {value?.slice(0, 15)} {value?.length > 15 ? "..." : ""}
                            {value?.length > 15 && (
                                <div className="hover-text-content border p-2 rounded-3 shadow-sm">
                                    <p className="d-flex justify-content-center align-items-center ">
                                        {value}
                                    </p>
                                </div>
                            )}
                        </div>
                    </>
                );
            },
        },
        {
            title: (
                <span>
                    Legal{" "}
                    {getsortfield === "legal" && !getsortorder ? (
                        <FaSortAmountUp />
                    ) : getsortorder === "ascend" && getsortfield === "legal" ? (
                        <span className="sort-svg">
                            <FaSortAmountUp className="text-info" />
                        </span>
                    ) : getsortorder === "descend" && getsortfield === "legal" ? (
                        <span className="sort-svg">
                            <FaSortAmountDown className="text-info" />
                        </span>
                    ) : (
                        <FaSortAmountUp />
                    )}
                </span>
            ),
            dataIndex: "legal",
            key: "legal",
            sorter: (a, b) => {
                const valueA = a.legal || "";
                const valueB = b.legal || "";
                if (!valueA && !valueB) return 0;
                if (!valueA && valueB) return 1;
                if (valueA && !valueB) return -1;
                return valueA.localeCompare(valueB)
            },
            width: 300,
            align: "center",
            ...SearchColumns("legal", "Legal"),
            render: (value) => {
                return (
                    <>
                        <div className="hover-text-comp position-relative ">
                            {" "}
                            {value?.slice(0, 55)} {value?.length > 55 ? "..." : ""}
                            {value?.length > 55 && (
                                <div className="hover-text-content  border p-2 rounded-3 shadow-sm">
                                    <p>{value}</p>
                                </div>
                            )}
                        </div>
                    </>
                );
            },
        },


        {
            title: (
                <span>
                    Status{" "}
                    {getsortfield === "status" && !getsortorder ? (
                        <FaSortAmountUp />
                    ) : getsortorder === "ascend" && getsortfield === "status" ? (
                        <span className="sort-svg">
                            <FaSortAmountUp className="text-info" />
                        </span>
                    ) : getsortorder === "descend" && getsortfield === "status" ? (
                        <span className="sort-svg">
                            <FaSortAmountDown className="text-info" />
                        </span>
                    ) : (
                        <FaSortAmountUp />
                    )}
                </span>
            ),
            dataIndex: "status",
            key: "status",
            sorter: (a, b) => a.status.localeCompare(b.status),
            width: 100,
            align: "center",
            // ...SearchColumns("status", "Status"),
            render: (value) => {
                const colorCode = statusColor?.filter((ele) => {
                    return ele.name === value;
                });

                return (
                    <>
                        <button
                            className="text-black rounded-pill fw-bold"
                            style={{
                                background: colorCode[0]?.colour_code,
                                border: `1px solid ${colorCode[0]?.colour_code}`,
                                width: 100,
                            }}
                        >
                            {value}
                        </button>
                    </>
                );
            },

            filters: statusColor?.map(({ name }) => {
                return { text: name, value: name };
            }),
            onFilter: (value, filters) =>
                filters.status && !filters.status.indexOf(value),
            filterMode: "tree",
            ...(state?.status_name && !state.notice_name
                ? { defaultFilteredValue: [state?.status_name] }
                : ""),
        },


        {
            title: (
                <span>
                    Created{" "}
                    {getsortfield === "created" && !getsortorder ? (
                        <FaSortAmountUp />
                    ) : getsortorder === "ascend" && getsortfield === "created" ? (
                        <span className="sort-svg">
                            <FaSortAmountUp className="text-info" />
                        </span>
                    ) : getsortorder === "descend" && getsortfield === "created" ? (
                        <span className="sort-svg">
                            <FaSortAmountDown className="text-info" />
                        </span>
                    ) : (
                        <FaSortAmountUp />
                    )}
                </span>
            ),
            dataIndex: "created",
            key: "created",
            //sort date 
            ...dateSorted("created"),
            width: 120,
            align: "center",
            ...SearchColumns("created", "Created Date"),
        },
        {
            title: (
                <span>
                    Examiner{" "}
                    {getsortfield === "researcher" && !getsortorder ? (
                        <FaSortAmountUp />
                    ) : getsortorder === "ascend" && getsortfield === "researcher" ? (
                        <span className="sort-svg">
                            <FaSortAmountUp className="text-info" />
                        </span>
                    ) : getsortorder === "descend" && getsortfield === "researcher" ? (
                        <span className="sort-svg">
                            <FaSortAmountDown className="text-info" />
                        </span>
                    ) : (
                        <FaSortAmountUp />
                    )}
                </span>
            ),
            dataIndex: "researcher",
            key: "researcher",
            sorter: (a, b) => {
                const valueA = a.researcher || "";
                const valueB = b.researcher || "";
                if (!valueA && !valueB) return 0;
                if (!valueA && valueB) return 1;
                if (valueA && !valueB) return -1;
                return valueA.localeCompare(valueB)
            },
            width: 200,
            align: "center",
            ...SearchColumns("researcher", "Researcher"),
        },
    ];



    console.log(statusData, 383, "OrderStatus.js")
    // Effect to update filtered data when API data changes
    useEffect(() => {
        searchData({ data: { status_id: 0 }, token: token })
    }, []);

    // Handle tab change
    const handleTabChange = async (key) => {
        try {
            await searchData({ data: { status_id: key }, token: token })   //.unwrap(); Adjust the API call as needed
        } catch (error) {
            console.error(error);
        }
    };

    // Handle sorting
    const handleChange = (pagination, sorter) => {
        setSortOrder({
            columnKey: sorter.columnKey,
            order: sorter.order,
        });
    };

    // All possible statuses
    const allStatuses = [
        { id: 0, name: 'All' },
        { id: 1, name: 'Open' },
        { id: 2, name: 'Assigned' },
        { id: 3, name: 'Acreage' },
        { id: 4, name: 'AG Check' },
        { id: 5, name: 'Confidential' },
        { id: 6, name: 'Completed' },
        { id: 7, name: 'Delq Tax Suit' },
        { id: 8, name: 'InActive' },
        { id: 9, name: 'Info Rec\'d' },
        { id: 10, name: 'Info Req\'d' },
        { id: 11, name: 'Pending' },
        { id: 12, name: 'Quality Assurance' },
        { id: 13, name: 'Tax Update' },
        { id: 14, name: 'Deleted' },
        { id: 15, name: 'Delivered' },
        { id: 17, name: 'New Subd' },
        { id: 18, name: 'Delq Reqd' },
        { id: 19, name: 'Needs Base File' },
        { id: 20, name: 'Outside Texas' },
        { id: 21, name: 'Locate Help' },
    ];

    return (
        <div id="order-status">
            <Title level={2}>Order Status</Title>
            <Tabs defaultActiveKey="All" onChange={handleTabChange}>
                {allStatuses.map(({ name, id }) => (
                    <TabPane tab={name} key={id.toString()} />
                ))}
            </Tabs>
            <Table
                columns={columns}
                dataSource={statusData?.data}
                onChange={handleChange}
                pagination={{ pageSize: 10 }}
                // scroll={{ x: 1500 }}
                loading={loadingStatusData}
                rowKey="id"
            />
        </div>
    );
};

export default OrderStatus;