import React, { useState } from "react";
import { Alert, Collapse, Popconfirm } from "antd";
import AppTable from "../AppTable/AppTable";

import { FaPlus, FaStarOfLife } from "react-icons/fa";
import "./MultiHoaFiscalPeriod.css";
import {
  CaretRightOutlined,
  EditOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import AppInput from "../AppInput/AppInput";
import { MdOutlineDelete } from "react-icons/md";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { useEffect } from "react";
import { removeBlank } from "../../Controllers/Functions";
import AppNumeric from "../AppNumeric/AppNumeric";
import AppSelect from "../AppSelect/AppSelect";
const randomPass = require("secure-random-password");
const { Panel } = Collapse;

const MonthName = [ 
  {id:"January", name:"January"}, 
  {id:"February", name:"February"}, 
  {id:"March", name:"March"}, 
  {id:"April", name:"April"}, 
  {id:"May", name:"May"}, 
  {id:"June", name:"June"}, 
  {id:"July", name:"July"}, 
  {id:"August", name:"August"}, 
  {id:"September", name:"September"}, 
  {id:"October", name:"October"}, 
  {id:"November", name:"November"}, 
  {id:"December", name:"December"}, 
]

export default function MultiHoaFiscalPeriod({
  afterClose = false,
  addFormVal = false,
  initailValArr = [],
  updateContact = false,
  deleteContact = false,
  whileEdit = false,
  getDate = false,
  _id,
}) {
  const [active, setActive] = useState("2");

  const initailVal = {
    hoa_info_subdivision_id:"",
    period_start_day:"",
    period_end_day:"",
    period_start_month:"",
    period_end_month:""
  };
  const [values, setValues] = useState(initailVal);
  // const [,setShowDate] = useState(true);
  const [data, setData] = useState(initailValArr);
  const [addRowToggler, setAddRowToggler] = useState(false);

  const [editId, setEditId] = useState(null);
  const [getErr, setGetErr] = useState(false);

  const { period_end_day, period_start_day, period_end_month, period_start_month } = values;

  //edit row
  const editHandler = (record) => {
    setEditId(record);
    setAddRowToggler(true);
    setValues(record);
  };

  const columns = [
    // {
    //   title: <span>ManagementCompany</span>,
    //   dataIndex: "management_company",
    //   key: "management_company",
    //   align: "center",
    // },
    {
      title: <span>Period Start Month</span>,
      dataIndex: "period_start_month",
      key: "period_start_month",
      align: "center",
      render: (value) => {
        return MonthName?.find(item=>item?.id==value)?.name
      }
    },
    {
      title: <span>Period End Month</span>,
      dataIndex: "period_end_month",
      key: "period_end_month",
      align: "center",
      render: (value) => {
        return MonthName?.find(item=>item?.id==value)?.name
      }
    },
    {
      title: <span>Period Start Day</span>,
      dataIndex: "period_start_day",
      key: "period_start_day",
      align: "center",
    },
    {
      title: <span>Period End Day</span>,
      dataIndex: "period_end_day",
      key: "period_end_day",
      align: "center",
    },
    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      align: "center",
      width: 130,
      render: (_, record) => {
        return (
          <>
            <span>
              <EditOutlined
                style={{ cursor: "pointer" }}
                onClick={() => editHandler(record)}
                size={50}
              />
            </span>

            <Popconfirm
              getPopupContainer={(trigger) => trigger.parentElement}
              title="Are you sure you want to delete？"
              onConfirm={() => {
                deleteHandler(record);
              }}
              placement="topLeft"
              icon={
                <QuestionCircleOutlined
                  style={{
                    color: "red",
                  }}
                />
              }
              okText="Yes"
              cancelText="No"
            >
              <span className={"ms-2 "}>
                <MdOutlineDelete style={{ cursor: "pointer" }} size={15} />
              </span>
            </Popconfirm>
          </>
        );
      },
    },
  ];


  const inputs = [
    {
        id: 454455,
        name: "period_start_month",
        _id: "period_start_month",
        type: "select",
        usaFormat: true,
        value: period_start_month || "",
        options: MonthName,
        errorMessage: "Please enter start month!",
    },
    {
        id: 454456,
        name: "period_end_month",
        _id: "period_end_month",
        type: "select",
        options: MonthName,
        usaFormat: true,
        value: period_end_month || "",
        errorMessage: "Please enter end month!",
    },
    {
      id: 454457,
      name: "period_start_day",
      _id: "period_start_day",
      type: "number",
      usaFormat: true,
      value: period_start_day || "",
      column: "3",
      placeholder: "Enter period start day",
      errorMessage: "Please enter start day!",
    },
    {
      id: 454458,
      name: "period_end_day",
      _id: "period_end_day",
      type: "number",
      value: period_end_day || "",
      column: "3",
      maxLength: 9,
      placeholder: "Enter period end day",
      errorMessage: "Please enter period end day!",
    }
  ];

  const onChange = (e, date, dateFormat) => {
    setGetErr(false)
    if (dateFormat) {
      setValues({
        ...values,
        [e]: date,
      });
    }
    else {
      const { name, value } = e.target;
      setValues({
        ...values,
        [name]: value,
      });
    //   if (name === "fees") {
    //     setValues({
    //       ...values,
    //       [name]: value.replace(/[^0-9.]/gi, ""),
    //     });
    //   }
    }
  };

  //sending request

  const handleSubmit = () => {
    if (editId) {
      const newData = data.filter((ele) => {
        return (ele.temp_id || ele.id) !== (editId.temp_id || editId.id);
      });

      setData([...newData, { ...values}]);
      updateContact && updateContact({ ...values });
      setAddRowToggler(false);
      setEditId(null);
      // setValues(initailVal);
    } else {
      //remove blank values
      removeBlank(values);
      const randomId = randomPass.randomPassword({
        length: 9,
        characters: randomPass.digits,
      });
      setData([
        ...data,
        {
          ...values,
          // fees: values?.fees,
          temp_id: randomId,
        },
      ]);
      //while edit and ading new record directly
      whileEdit({ ...values, temp_id: randomId });
      setAddRowToggler(false);
    }
  };

  const cancelHandler = () => {
    setValues(initailVal);
    addFormVal(data);
    setAddRowToggler(false);
  };

  const addNewRecord = () => {
    setAddRowToggler(!addRowToggler);
    setValues({ ...initailVal })
  };

  const deleteHandler = (id) => {
    setData(
      data.filter((ele) => {
        return (ele?.id || ele?.temp_id) !== (id?.id || id?.temp_id);
      })
    );
    //sending delete id
    deleteContact(id);
  };
  const selectHandler = (property, value, arrtibute) => {
		setValues({
			...values,
			[property]: value,
			// integration_bridge_id: arrtibute?.getAttribute("data_name"),
		});
	};

  useEffect(() => {
    setAddRowToggler(false);
    setData(initailValArr);
    setValues(initailVal);
    setEditId(null);
    setActive("2");
  }, [afterClose]);

  useEffect(() => {
    addFormVal && addFormVal(data);
  }, [data]);

  return (
    <Collapse
      className="m-0 py-0 gray-table site-collapse-custom-collapse"
      expandIcon={({ isActive }) => (
        <CaretRightOutlined
          style={{ fontSize: 14 }}
          className="mt-1"
          rotate={isActive ? 90 : 0}
        />
      )}
      expandIconPosition={"end"}
      activeKey={active}
      accordion
      onChange={(value) => {
        setActive(value);
      }}
    >
      <Panel
        className="my-1 border-0 site-collapse-custom-panel "
        header={
          <h6 className=" mb-0 fw-bold me-2 d-flex align-items-center gap-1">
            <RiMoneyDollarCircleLine size={15} /> <span>Fiscal Period</span>
          </h6>
        }
        key="1"
      >
        {active === "1" && (
          <div className="gray-table-col  mb-2 p-2" id={_id}>
            <div
              className={`d-flex ${"justify-conten"} align-items-center my-2 contact-forms`}
            >
              
              {inputs.map((input) => (
                <>
                  {input.type === "date" && (
                    <AppInput
                      defaultSet={afterClose}
                      key={input.id}
                      {...input}
                      value={values[input.name]}
                      onChange={(name, date, dateFormat) => {
                        // setShowDate(true);

                        if (dateFormat) {
                          getDate && getDate(date);
                          setValues({ ...values });
                        }


                      }}
                    />
                  )}
                </>
              ))}
              {!addRowToggler && (
                <div className={`add_icon ms-auto`} onClick={addNewRecord}>
                  <FaPlus />
                </div>
              )}
            </div>

            <AppTable
              pagination={false}
              dataSource={data}
              columns={columns}
            />

            {getErr && (
              <div className="mt-3">
                <Alert
                  message={getErr}
                  className="py-1 mx-3"
                  type="error"
                  showIcon
                />{" "}
              </div>
            )}
            {/* //loading  */}
            {/* {emailCheckApi[1].isLoading && <AppSpinner size="small" />} */}

            {addRowToggler && (
              <div className="row bg-white p-2 mx-auto shadow-sm" id="addRow">
                {inputs.map((input) => (
                  <>
                    {input.type === "text" && (
                      <AppInput
                        defaultSet={afterClose}
                        key={input.id}
                        {...input}
                        value={values[input.name]}
                        onChange={onChange}
                      />
                    )}
                      {input.type === "number" && (
                      <AppInput
                        defaultSet={afterClose}
                        key={input.id}
                        {...input}
                        value={values[input.name]}
                        onChange={onChange}
                      />
                    )}
                    {input.type === "select" && (<div className="col-12 col-md-3">
                      <AppSelect
                        key={input.id}
                        value={values[input.name]}
                        onChange={selectHandler}
                        name ={input?.name}
                        options = {<>
                        <option value={""}>
                          {input.id===454455?"Select Period Start Month":"Select Period End Month"}
                        </option>
                           {input.options.map((ele) => {
                                return (
                            <option
                              key={ele.id}
                              data_name={ele.id}
                              value={ele.id}
                            >
                              {ele.name}
                            </option>
                          );
                        })}
                      </>}
                        />
                    </div>)}
                    {/* {input.name === "fees" && (
                      <AppNumeric
                        {...input}
                        type="text"
                        defaultSet={afterClose}
                        value={values[input.name]}
                        thousandSeparator={true}
                        placeholder={input.placeholder}
                        prefix={"$"}
                        decimalScale={7}
                        onChange={(value) => {
                          setValues({ ...values, fees: value });
                        }}
                      />
                    )} */}
                  </>
                ))}
                <div className="col-12 col-lg-1 mt-3 p-0">
                  <div className="d-flex">
                    <span
                      onClick={handleSubmit}
                      className={`btn btn-primary  btn-sm edit-buttons ${!period_end_day || !period_start_day ? "disabled" : "btn-success"
                        } `}
                    >
                      {editId?.id ? "Update" : "Save"}
                    </span>
                    <span
                      onClick={cancelHandler}
                      className="btn btn-primary ms-1 btn-sm edit-buttons"
                    >
                      Cancel
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </Panel>
    </Collapse>
  );
}
