import React from 'react';
import { Column } from '@ant-design/plots';

const ColumnBarChart = (props) => {
    const{
        data=[],
      xField,
      yField,
      isGroup=false,
      isStack=false,
      seriesField,
      formatter,
      annotations=[]
    }=props;
  const config = {
    data:data,
    isGroup:isGroup,
    xField: xField,
    yField: yField,
    seriesField: seriesField,
    autoFit: true,
    color:['#1C39BB','#FF9F00','#20B2AA','#0492C2','#23297A'],
    width:360,
    height:360,
    isStack:isStack,
    scrollbar: {
      type: 'horizontal',
    },
    legend: {
      layout: "horizontal",
      position: "top-left",
    },
    // columnStyle: {
    // padding:[5,5,5,5]
    // },
    intervalPadding: 20,
    minColumnWidth: 20,
    maxColumnWidth: 20,
    dodgePadding: 20,
    xAxis: {
      label: {
        autoHide: false,
        autoRotate: true,
        style:{
          padding:[0,50,0,50],
        },
        formatter:formatter
        
      },
    },
    columnWidthRatio:5,
    barCategoryGap:"50%",
 
  annotations
  };
  return <Column {...config} />;
};

export default ColumnBarChart;
