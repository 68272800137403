import React, { useState, useEffect } from 'react';
import { Checkbox, Input, List } from 'antd';

const { Search } = Input;
export default function AutoDilevery({ data = [], isLoading = false, checkedList=[] , setCheckedList}) {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState(data);
    // const [checkedList, setCheckedList] = useState([]);
    const [checkAll, setCheckAll] = useState(false);
    useEffect(() => {
        setFilteredData(
            data.filter(item =>
                item.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, data]);

    useEffect(()=>{
    setSearchTerm("")
    },[isLoading])
    const onChange = (checkedValues) => {
        setCheckedList(checkedValues);
        setCheckAll(checkedValues.length === filteredData.length);
    };

    useEffect(()=>{
         const allChecked= filteredData?.length>0 && filteredData?.every(item=>checkedList?.includes(item.id));
         setCheckAll(allChecked)
    },[filteredData,checkedList])


    const onCheckAllChange = (e) => {
        const checked = e.target.checked;
        setCheckAll(checked);
        // if(checked){
        // setCheckedList(filteredData.map(item => item.id));
        // }
        // else{
        //     setCheckedList([]);
        // }
        if(checked){
           
                const newchecheckedlist=[
                ...checkedList,
                ...filteredData.filter(item=>!checkedList.includes(item.id)).map(item=>item.id)
            ]
            setCheckedList(newchecheckedlist)
        }
        else{
          
              const newchecheckedlist=  checkedList.filter(id=>!filteredData.some(item=>item.id===id));
              setCheckedList(newchecheckedlist)
        }   
    };

    // const onItemCheckChange=(itemId,checked)=>{
    //     setCheckedList(prevselectedList=>{
    //     const newList=checked
    //     ?[...prevselectedList,itemId]
    //     :prevselectedList.filter(id=>id!==itemId);
    //    return newList;
    //     });
    // }
  

    if (isLoading) return <div  className='mt-4'>Loading...</div>
    return (
        <div  className='mt-1 border p-1 rounded-3'>
            <Input
                placeholder="Search"
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm||""}
                style={{ marginBottom: 8 }}
                
            />
            <div style={{ maxHeight: 200, overflow: "auto"  }} className='ps-2'>
                {data?.length > 0 && <Checkbox
                    onChange={onCheckAllChange}
                    checked={checkAll}
                    indeterminate={checkedList.length > 0 && checkedList.length < filteredData.length}
                    style={{fontSize:"12px",fontWeight:"bolder"}}
                >
                    Check all
                </Checkbox>}
                <List
                    dataSource={filteredData}
                    renderItem={item => (
                        <List.Item>
                            <Checkbox
                                value={item.id}
                                style={{fontSize:"12px"}}
                                checked={checkedList.includes(item.id)}
                                onChange={(e) => {
                                    const newList = e.target.checked
                                        ? [...checkedList, item.id]
                                        : checkedList.filter(id => id !== item.id);
                                    setCheckedList(newList);
                                    setCheckAll(newList.length === filteredData.length);
                                }}
                            >
                                {item.name}
                            </Checkbox>
                        </List.Item>
                    )}
                />
            </div>

        </div>
    )
}