import React, { useEffect, useState } from "react";
import FormInput from "../../../Components/AppInput/AppInput";
import MyButton from "../../../Components/AppButton/AppButton";
import { useSelector } from "react-redux";
import AppSpinner from "../../../Components/AppSpinner/AppSpinner";
import { Form, Col, Row } from "react-bootstrap";
import { Alert, notification } from "antd";
import { useAddlabelsMutation } from "../../../Redux/Services/Labels";

export const LabelModal = ({ afterClose, setShowHide, ...props }) => {
  const [values, setValues] = useState({
    customer_id: "",
    label_key: "",
    label_value: "",
  });

  //status
  const [getError, setGetError] = useState(null);

  //clear error function
  function clearStatus() {
    setGetError(null);
  }

  //setDefault values
  useEffect(() => {
    setValues({
      customer_id: "",
      label_key: "",
      label_value: "",
    });
  }, [afterClose]);

  // calling redux store
  const { token } = useSelector((state) => state.auth);

  //calling rtk query hook for adding new labels
  const addNewLabel = useAddlabelsMutation();
  const [addLabel] = addNewLabel;

  //useEffet for  add labels
  useEffect(() => {
    if (addNewLabel[1].isSuccess) {
      setShowHide(false);
      clearStatus();
      ///after add details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: addNewLabel[1].data?.message,
      });
    }
    if (addNewLabel[1].error) {
      var errorData = [];
      for (const prop in addNewLabel[1].error.data?.error) {
        errorData.push(addNewLabel[1].error.data?.error[prop]);
      }
      setGetError(
        errorData[0] ||
          addNewLabel[1].error.data.message ||
          addNewLabel[1].error.error ||
          addNewLabel[1].error.data?.msg.sqlMessage
      );
    }
  }, [addNewLabel[1].isLoading]);

  const handleReset = (e) => {
    e.preventDefault();
    setValues({
      customer_id: "",
      label_key: "",
      label_value: "",
    });
    // claer status
    clearStatus();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addLabel({
      data: { ...values },
      token: token,
    });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value.replace(/[^a-zA-Z." "]/gi, ""),
    });

    //in labele allow iput char allowed
    if (name === "label_value") {
      setValues({
        ...values,
        [name]: value,
      });
    }

    clearStatus();
  };
  const inputs = [
    {
      id: 5,
      name: "label_key",
      type: "text",
      column: "6",
      placeholder: "Enter label key name",
      errorMessage: "Please enter label key!",
      label: "Key",
      required: true,
    },

    {
      id: 2,
      name: "label_value",
      type: "text",
      column: "6",
      placeholder: "Enter label value",
      errorMessage: "Please enter label value!",
      label: "Value",
      required: true,
    },
  ];

  return (
    <div>
      <div className="my-4 border-bottom border-2">
        <h5>Add Label</h5>
      </div>

      <div className="my-3">
        {getError && (
          <Alert type="error" message={getError} closable showIcon />
        )}
      </div>
      <Form onSubmit={handleSubmit}>
        {addNewLabel[1].isLoading && <AppSpinner />}
        <Row>
          {inputs.map((input) => (
            <FormInput
              key={input.id}
              {...input}
              defaultSet={afterClose}
              value={values[input.name]}
              onChange={onChange}
            />
          ))}
        </Row>

        <Row>
          <Col className="my-3">
            <div className="d-flex justify-content-center">
              <div className="me-md-2 ">
                <MyButton
                  onClick={handleReset}
                  title={"CLEAR"}
                  variant={"secondary-md"}
                />
              </div>

              <MyButton title={"SAVE"} variant={"primary-md"} />
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
