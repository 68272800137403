import React, { useState } from "react";
import { Radio } from "antd";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { List } from "./List/List";
import { Batch } from "./Batch/Batch";

export const TaxCad = ({ refresh }) => {
	const [key, setKey] = useState("List");
	const OnradiohandleChange = (e) => {
		setKey(e.target.value);
	};

	// create function for update tabs
	function updateTab(data) {
		setKey(data);
	}

	//redux store
	const { taxcadTabs } = useSelector((state) => state.flagRoles);
	useEffect(() => {
		setKey(taxcadTabs[0]);
	}, [taxcadTabs]);

	return (
		<div className="col-12 col-lg-12">
			<Radio.Group onChange={OnradiohandleChange} value={key}>
				{/* tabs showing base on users roles  */}
				{taxcadTabs &&
					taxcadTabs.map((ele) => {
						return (
							<Radio key={ele} value={ele} defaultChecked>
								{ele}
							</Radio>
						);
					})}
			</Radio.Group>
			{key === "List" && (
				<List refresh={refresh} childTab={key} updateTab={updateTab} />
			)}
			{key === "Batch" && (
				<Batch refresh={refresh} childTab={key} updateTab={updateTab} />
			)}
		</div>
	);
};
