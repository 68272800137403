import React, { useEffect } from 'react'
import { useStopBackendAutomationMutation } from '../Redux/Services/Orders'
import { BsStopCircle } from "react-icons/bs"
import OrderBtns from '../Components/OrderBtns'
import { useSelector } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin, notification } from 'antd'
export const useAutomationStop = (token) => {
    const [stopBackednAutomation, stopBackednAutomationResult] = useStopBackendAutomationMutation()
    useEffect(() => {
        if (stopBackednAutomationResult.isSuccess) {
            notification.open({
                type: "success",
                style: { color: "green", marginTop: "50px" },
                description: stopBackednAutomationResult.data?.message,
            });
        }
        if (stopBackednAutomationResult.isError) {
            notification.open({
                type: "error",
                style: { color: "red", marginTop: "50px" },
                description: stopBackednAutomationResult.error?.data?.message || "Something went wrong!",
            });
        }
    }, [stopBackednAutomationResult.isLoading])
    //redux
    const { orderDATA, bakendAutomation } = useSelector((state) => state.OrderData);

    const StopAutomationComp = ({ callback }) => (
        bakendAutomation ? <OrderBtns title={stopBackednAutomationResult.isLoading ? "Stoping Automation" : "Stop Automation"}>
            <div
                onClick={() => stopBackednAutomation({ order_id: orderDATA?.id, token }).then(() => callback && callback())}
                className="add_icon"
            >
                {stopBackednAutomationResult.isLoading ? <Spin
                    indicator={
                        <LoadingOutlined
                            style={{
                                fontSize: 24,
                            }}
                            spin
                        />
                    }
                /> : <BsStopCircle color="red" />}
            </div>
        </OrderBtns> : null
    )
    return { StopAutomationComp }
}

