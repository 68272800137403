import { createSlice } from "@reduxjs/toolkit";

const productTypeList = createSlice({
  name: "productTypeList",
  initialState: {
    productTypeList: [],
  },
  reducers: {
    productTypeListAction: (state, action) => {
      state.productTypeList = action.payload;
    },
  },
});

export const {productTypeListAction} = productTypeList.actions;

export default productTypeList.reducer;