import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json")
export const contacttype = createApi({
  reducerPath: "contacttype",
  tagTypes: ["contacttype"],
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  endpoints: (builder) => ({
    contactTypeList: builder.query({
      query: (token) => ({
        url: `/contact-type-list`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ["contacttype"],
    }),
    addContactType: builder.mutation({
      query: (data) => ({
        url: `/add-contact-type`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["contacttype"],
    }),
    editContactType: builder.query({
      query: (data) => ({
        url: `/edit-contact-type/${data.id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["contacttype"],
    }),
    updateContactType: builder.mutation({
      query: (data) => ({
        url: `/update-contact-type/${data.id}`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["contacttype"],
    }),
    deleteContactType: builder.mutation({
      query: (data) => ({
         url: `/delete-contact-type/${data.id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["contacttype"],
    }),
  }),
});

export const {
  useContactTypeListQuery,
  useAddContactTypeMutation,
  useEditContactTypeQuery,
  useUpdateContactTypeMutation,
  useDeleteContactTypeMutation,
} = contacttype;
