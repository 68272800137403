import { Input, notification, Popconfirm, Tooltip } from "antd";
import React, { useMemo, useState } from "react";
import { FaPlus, FaSearch } from "react-icons/fa";

import OrderDashboard from "./orderDashboard/OrderDashboard";
import { useNavigate } from "react-router-dom";
import { HiOutlineDocumentText } from "react-icons/hi";
import {
  MdAssignment,
  MdOutlineAssignment,
  MdOutlineDeleteSweep,
} from "react-icons/md";
import AppModal from "../../Components/AppModal/AppModal";
import NoticeAssignModal from "./orderDashboard/NoticeAssignModal";
import {
  useDeleteOrderMutation,
  useOrderExaminerUpdateMutation,
  useOrderHoaExaminerUpdateMutation,
  useOrdersListMutation,
  useOualiaRequestMutation,
  useQualiaOrderStatusMutation,
} from "../../Redux/Services/Orders";
import { useEffect } from "react";
import ExaminerAssignModal from "./orderDashboard/ExaminerAssignModal";
import { useDispatch, useSelector } from "react-redux";
import { GlobalContext } from "../../App";
import { useContext } from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  AiOutlineFileSearch,
} from "react-icons/ai";
import { showalertAssignHandler } from "../../Redux/reducers/Order";

export default function Order() {
  //context api
  const { state } = useContext(GlobalContext);
  const [globalVal, setGlobalVal] = state;
  const [assingType, setAssingType] = useState("tax");
  const [rowSelection, setRowSelection] = useState([]);

  //redux
  const { token } = useSelector((state) => state.auth);
  const { orderModule, orderListToolbar } = useSelector(
    (state) => state.flagRoles
  );

  //router
  const navigate = useNavigate();
  const dispatch = useDispatch()
  //notice
  const [showAddNoticeModal, setShowAddNoticeModal] = useState(false);
  const [showAddNoticeModalInitial, setShowAddNoticeModalInitial] =
    useState(false);

  //assign
  const [showAddAssignModal, setShowAddAssignModal] = useState(false);
  const [examinerId, setExaminerId] = useState("");
  const [showAddAssignModalInitial, setShowAddAssignModalInitial] =
    useState(false);

  //sending request to  examiner order
  const [orderExaminerUpdate, orderExaminerUpdateResult] =
    useOrderExaminerUpdateMutation();
  // sending request to  resubmit order
  const [orderHoaExaminerUpdate, orderHoaExaminerUpdateResult] =
    useOrderHoaExaminerUpdateMutation();

  //sending request to  delete multiple orders
  const [deleteOrders, deleteOrdersResult] = useDeleteOrderMutation();

  //sending qualia to orders
  const [QualiaRequest, QualiaRequestResult] = useOualiaRequestMutation();

  //sending qualia to orders status
  const [QualiaOrderStatus, QualiaOrderStatusResult] =
    useQualiaOrderStatusMutation();


  const [ordersListApi] = useOrdersListMutation();

  //useEffect
  useEffect(() => {
    setGlobalVal({ ...globalVal, loader: QualiaRequestResult.isLoading });
  }, [QualiaRequestResult.isLoading]);

  useEffect(() => {
    if (orderExaminerUpdateResult.isSuccess) {
      setShowAddAssignModal(false);
      ///after resubmit details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: orderExaminerUpdateResult.data?.message,
      });
      //rest order list
      ordersListApi({ token, allOrder: globalVal?.orderAllStatusToggle });
      setExaminerId("");
      setRowSelection([]);
    }
    if (orderExaminerUpdateResult.isError) {
      ///after submit details
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description:
          orderExaminerUpdateResult.error?.data?.error ||
          orderExaminerUpdateResult.error?.data?.message ||
          (orderExaminerUpdateResult.error.error && "server unreachable!") ||
          orderExaminerUpdateResult.error.data?.msg.sqlMessage,
      });
    }
    setGlobalVal({ ...globalVal, loader: orderExaminerUpdateResult.isLoading });
  }, [orderExaminerUpdateResult.isLoading]);

  useEffect(() => {
    if (orderHoaExaminerUpdateResult.isSuccess) {
      setShowAddAssignModal(false);
      ///after resubmit details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: orderHoaExaminerUpdateResult.data?.message,
      });
      //rest order list
      ordersListApi({ token, allOrder: globalVal?.orderAllStatusToggle });
      setExaminerId("");
      setRowSelection([]);
    }
    if (orderHoaExaminerUpdateResult.isError) {
      ///after submit details
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description:
          orderHoaExaminerUpdateResult.error?.data?.error ||
          orderHoaExaminerUpdateResult.error?.data?.message ||
          (orderHoaExaminerUpdateResult.error.error && "server unreachable!") ||
          orderHoaExaminerUpdateResult.error.data?.msg.sqlMessage,
      });
    }
    setGlobalVal({
      ...globalVal,
      loader: orderHoaExaminerUpdateResult.isLoading,
    });
  }, [orderHoaExaminerUpdateResult.isLoading]);

  //useEffect for update asignee examiner
  useEffect(() => {
    if (deleteOrdersResult.isSuccess) {
      ///after resubmit details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: deleteOrdersResult.data?.message,
      });
      //rest order list
      // ordersListApi({ token, allOrder: globalVal?.orderAllStatusToggle });
      setRowSelection([]);
    }
    if (deleteOrdersResult.isError) {
      ///after submit details
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description:
          deleteOrdersResult.error?.data?.error ||
          deleteOrdersResult.error?.data?.message ||
          (deleteOrdersResult.error.error && "server unreachable!") ||
          deleteOrdersResult.error.data?.msg.sqlMessage,
      });
    }
    setGlobalVal({ ...globalVal, loader: deleteOrdersResult.isLoading });
  }, [deleteOrdersResult.isLoading]);

  //adding assignees
  const assigExaminer = (value) => {
    if (assingType === "tax") {
      orderExaminerUpdate({
        data: {
          order_id: rowSelection.map(({ id }) => {
            return id;
          }),
          examiner_id: value,
        },
        token,
      });
    } else {
      orderHoaExaminerUpdate({
        data: {
          order_id: [
            ...new Set(
              rowSelection.map(({ id, order_id }) => {
                return globalVal?.hoaOrdersListToggle ? order_id : id;
              })
            ),
          ],
          hoa_examiner_id: value,
        },
        token,
      });
    }
  };

  const addNewOrder = () => {
    navigate(`/order-editor`, { state: { orderEvent: "add" } });
    dispatch(showalertAssignHandler(false))
  };

  const QualiaOrder = () => {
    QualiaRequest({ token });
  };

  //onload
  useEffect(() => {
    QualiaOrderStatus({ token });
  }, [QualiaOrderStatus, token]);

  return (
    <>
      {/* //add multiple notice flags  */}

      <AppModal
        width={"40%"}
        height={300}
        onClose={(value) => {
          setShowAddNoticeModal(value);
        }}
        afterClose={() => {
          setShowAddNoticeModalInitial(!showAddNoticeModalInitial);
          setRowSelection([]);
        }}
        showModal={showAddNoticeModal}
        element={
          <NoticeAssignModal
            setShowAddNoticeModal={setShowAddNoticeModal}
            orderId={rowSelection.map(({ id }) => {
              return id;
            })}
            showModalInitalState={showAddNoticeModalInitial}
          />
        }
      />
      {/* //add multiple assign  */}

      <AppModal
        width={"40%"}
        height={300}
        onClose={(value) => {
          setShowAddAssignModal(value);
        }}
        showModal={showAddAssignModal}
        afterClose={() =>
          setShowAddAssignModalInitial(!showAddAssignModalInitial)
        }
        element={
          <ExaminerAssignModal
            title={assingType === "tax" ? "Tax" : "HOA"}
            isLoading={
              orderExaminerUpdateResult.isLoading ||
              orderHoaExaminerUpdateResult.isLoading
            }
            value={examinerId}
            showModalInitalState={showAddAssignModalInitial}
            onSubmit={assigExaminer}
            onChange={(e, value) => {
              setExaminerId(value);
            }}
          />
        }
      />

      {/* //dashboard  */}
      <h5 className="settings-container ">Tax Certificate Portal | Orders</h5>
      <div className="row  ">
        {/* {tools showing after row selection } */}
        {
          orderModule.map((item, index) => {
            if (item.qualia_button) {
              return (
                <div className="col-12 col-lg-1" key={index}>
                  <div className="add_icon " onClick={QualiaOrder}>
                    <Tooltip
                      overlayInnerStyle={{
                        fontSize: 10,
                      }}
                      placement="left"
                      title={
                        <>
                          {" "}
                          {QualiaOrderStatusResult?.isLoading ? (
                            "Loading"
                          ) : (
                            <>
                              Add Qualia Order <br />
                              <span>
                                {QualiaOrderStatusResult?.data?.data?.order_count ||
                                  null}
                              </span>{" "}
                              <br />
                              <span>
                                {QualiaOrderStatusResult?.data?.data?.updated_date ||
                                  null}
                              </span>
                            </>
                          )}
                        </>
                      }
                    >
                      <AiOutlineFileSearch />
                    </Tooltip>
                  </div>
                </div>
              )
            }
          })
        }

        {
          <div className="tools col-12 col-lg-6 ms-auto d-flex gap-1 align-items-center justify-content-end">
            {rowSelection.length > 0 && (
              <div className="d-flex gap-1 align-items-center">
                {" "}
                {orderListToolbar.map((item, index) => {
                  if (item.add_notice_flag && !globalVal?.hoaOrdersListToggle) {
                    return (
                      <Tooltip
                        key={index}
                        overlayInnerStyle={{
                          fontSize: 10,
                        }}
                        placement="right"
                        title={"Add Notice Flag"}
                      >
                        <div
                          className="add_icon "
                          onClick={() => {
                            setShowAddNoticeModal(true);
                          }}
                        >
                          <HiOutlineDocumentText color="#0d6efd" />
                        </div>
                      </Tooltip>
                    );
                  } else if (
                    item.assign_order &&
                    !globalVal?.hoaOrdersListToggle
                  ) {
                    return (
                      <Tooltip
                        key={index}
                        overlayInnerStyle={{
                          fontSize: 10,
                        }}
                        placement="top"
                        title={"Assign Tax"}
                      >
                        <div
                          onClick={() => {
                            setAssingType("tax");
                            setShowAddAssignModal(true);
                          }}
                          className="add_icon "
                        >
                          <MdOutlineAssignment color="#0d6efd" />
                        </div>
                      </Tooltip>
                    );
                  } else if (item.hoa_assign_order) {
                    return (
                      <Tooltip
                        key={index}
                        overlayInnerStyle={{
                          fontSize: 10,
                        }}
                        placement="top"
                        title={"Assign HOA"}
                      >
                        <div
                          onClick={() => {
                            setAssingType("hoa");
                            setShowAddAssignModal(true);
                          }}
                          className="add_icon "
                        >
                          <MdAssignment color="#0d6efd" />
                        </div>
                      </Tooltip>
                    );
                  } else if (
                    item.delete_order &&
                    !globalVal?.hoaOrdersListToggle
                  ) {
                    return (
                      <Popconfirm
                        key={index}
                        getPopupContainer={(trigger) => trigger.parentElement}
                        title="Are you sure you want to delete？"
                        onConfirm={() => {
                          deleteOrders({
                            order_id: rowSelection.map(({ id }) => {
                              return id;
                            }),
                            token,
                          });
                        }}
                        placement="top"
                        icon={
                          <QuestionCircleOutlined
                            style={{
                              color: "red",
                            }}
                          />
                        }
                        okText="Yes"
                        cancelText="No"
                      >
                        <Tooltip
                          overlayInnerStyle={{
                            fontSize: 10,
                          }}
                          placement="top"
                          title={"Delete Selected Orders"}
                        >
                          <div className="add_icon ">
                            <MdOutlineDeleteSweep color="#0d6efd" />
                          </div>
                        </Tooltip>
                      </Popconfirm>
                    );
                  }
                })}
              </div>
            )}
            <div className=" d-flex align-items-center gap-1 ">
              <div>
                <Input
                  placeholder="Search here..."
                  className="global-search "
                  value={globalVal.orderSearch}
                  onChange={(e) => {
                    setGlobalVal({ ...globalVal, orderSearch: e.target.value });
                  }}
                  prefix={<FaSearch color="lightgray" />}
                />
              </div>
              {orderModule.map((item, index) => {
                if (item.add_button) {
                  return (
                    <div
                      key={index}
                      className="add_icon ms-auto "
                      onClick={addNewOrder}
                    >
                      <Tooltip
                        overlayInnerStyle={{
                          fontSize: 10,
                        }}
                        placement="bottom"
                        title={"Add New Order"}
                      >
                        <FaPlus />
                      </Tooltip>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        }
      </div>
      {(!orderExaminerUpdateResult?.isLoading && !orderHoaExaminerUpdateResult?.isLoading) && <div className="row mt-2">
        {!deleteOrdersResult.isLoading && <OrderDashboard
          setRowSelection={setRowSelection}
          search={globalVal.orderSearch}
        />}
      </div>}
    </>
  );
}
