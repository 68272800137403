import React, { useState, useEffect, useMemo } from "react";
import AppInput from "../../../../Components/AppInput/AppInput";
import AppSelect from "../../../../Components/AppSelect/AppSelect";
import AppTextArea from "../../../../Components/AppTextArea/AppText";
import AppModal from "../../../../Components/AppModal/AppModal";
import AppTextEditor from "../../../../Components/AppTextEditor/AppTextEditor";
import { useDispatch, useSelector } from "react-redux";
import {
  Collapse,
  Alert,
  notification,
  // Input,
  Radio,
  Tabs,
  Popconfirm,
} from "antd";
import MyButton from "../../../../Components/AppButton/AppButton";
import { Form, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useUsersProductTypeMutation } from "../../../../Redux/Services/User";
import { NoticeFlagModal } from "./NoticeFlagModal";

import "./neworder.css";
import {
  useOrdercustomerListMutation,
  useOrdercloserListMutation,
  useOrderReadOnlyCommentListResultQuery,
  useOrderDisplayCertCommentListQuery,
  useAddOrderMutation,
  useExaminerOrderQuery,
  useOrderEditorQuery,
  useOrderUpdateMutation,
  // useOrderSearchMutation,
  useOrderEditorPostMutation,
  useOrderuserProductTypeListMutation,
  useOrderProductListPricingMutation,
  useOrderProductlistAMutation,
  useOrderDocsListMutation,
  useOrderStatusUpdateMutation,
  useOrderReadOnlyCommentMutation,
  useOrderDisplayCertCommentListQueryMutation,
  useNoticeFlagListMutation,
  useTransactionTypeListMutation,
  useExaminerOrderListMutation,
  useOrderDocumentDownloadMutation,
} from "../../../../Redux/Services/Orders";
import {
  useBranchOptionsMutation,
  useUserIdBaseBranchMutation,
} from "../../../../Redux/Services/Branch";
import { useGetcountyBaseCustomerAndStateMutation } from "../../../../Redux/Services/county";
import AppNumeric from "../../../../Components/AppNumeric/AppNumeric";
import {
  convertDateFormat,
  removeBlank,
  scrollComp,
} from "../../../../Controllers/Functions";
import { useGetStatesBaseOnCustomerMutation } from "../../../../Redux/Services/state";
import { AiOutlineEye } from "react-icons/ai";
import { BiHistory } from "react-icons/bi";
import { CgImport } from "react-icons/cg";
import { useCallback } from "react";
import { useContext } from "react";
import { parcelContext } from "../OrderEditor";
// import MultiFileUpload from "../../../../Components/MultifileUpload/MultiFileUpload";
import { GlobalContext } from "../../../../App";
import { useHoaStatusListQuery } from "../../../../Redux/Services/Hoa";
import OrderList from "../../Component/OrderList";
import OrderDocuments from "./OrderDocuments/OrderDocuments";
import { useTransactionTypeListQuery } from "../../../../Redux/Services/transactionTypeApi";
import ViewNoticeFlagModal from "./ViewNoticeModal/ViewNoticeFlagModal";
import { addOrderData, bakendAutomationHandler, certificateDownloadLoadingHandler, showalertAssignHandler, viewPdfDocuementHandler } from "../../../../Redux/reducers/Order";
import { integrationOptions } from "../../orderDashboard/OrderDashboard";
const { Panel } = Collapse;
var moment = require("moment-business-days");
const parse = require("html-react-parser");
// const { Search } = Input;
const { TabPane } = Tabs;
export default function Order({
  setLoading,
  orderEditorApi,
  orderEditor,
  editId,
  extraData,
  editable = false,
  setShowToollBar,
  refresh,
  setEditable,
  statusOptionsData,
  setOrderStatus,
  orderDocumentDownload,
  orderEvent,
  parcelList,
  validateConflicts = false,
  addCertificateDocumentsResult = false,
}) {
  //context api
  const { controller, orderContorllerState } = useContext(parcelContext);
  const [parcelController, setParcelController] = controller;
  //context api
  const global = useContext(GlobalContext);
  const [globalVal, setGlobalVal] = global.state;

  const [orderContorller, setOrderController] = orderContorllerState;

  const [getError, setGetError] = useState(false);

  const navigate = useNavigate();
  const [, setOrder_doc_list] = useState([]);
  const [customerOptionsData, setCustomerOptionsData] = useState([]);
  const [closerOptionsData, setCloserOptionsData] = useState([]);
  const [stateOptionsData, setStateOptionsData] = useState([]);
  const [examinerOptionsData, setExaminerOptionsData] = useState([]);
  const [noticeflagOptionsData, setNoticeFlagOptionsData] = useState([]);
  const [productTypeOptionsData, setproductTypeOptionsData] = useState([]);
  const [countyOptionsData, setCountyOptionsData] = useState([]);
  const [branchOptionsData, setBranchOptionsData] = useState([]);
  // const [, setSearchOrderData] = useState([]);
  const [hoaStatusOptions, setHoaStatusOptions] = useState([]);

  const [read_only_comments, setRead_only_comments] = useState("");
  const [display_on_cert_comments, setDisplay_on_cert_comments] = useState("");
  const [commetsView, setCommetsView] = useState("display-cert");
  const [, setDocument_lists] = useState([]);

  const [additionNotes, setAdditionNotes] = useState("");
  const [showNoticeModal, setshowNoticeModal] = useState(false);
  const [showNoticeCommentModal, setShowNoticeCommentModal] = useState(false);
  const [onHoverComment, setOnHoverComment] = useState(false);

  const [showNoticeCommetInitalState, setShowNoticeCommetInitalState] =
    useState(false);
  const [showEditMode, setShowEditMode] = useState(false);
  //testing radio
  const [orderTypeFlag, setOrderTypeFlag] = useState(2);
  const [showcomment, setshowComment] = useState(false);

  const { token, user } = useSelector((state) => state.auth);
  const { internalOrder } = useSelector((state) => state.flagRoles);
  const { orderDATA } = useSelector((state) => state.OrderData);

  // const orderEditor = useOrderEditorQuery(
  //   { id: editId, token: token },
  //   { skip: !editId }
  // );

  const dispatch = useDispatch()

  //api hoa status list
  const HoaStatus = useHoaStatusListQuery(token);

  const [orderEditorPost, orderEditorPostResult] = useOrderEditorPostMutation();

  const [closerBranchId, closerBranchIdResult] = useUserIdBaseBranchMutation();
  const [orderDocsList, orderDocsListResult] = useOrderDocsListMutation();

  // useEffect for status list
  useEffect(() => {
    if (HoaStatus.isSuccess) {
      setHoaStatusOptions(HoaStatus.data?.data);
    }
    if (HoaStatus.isError) {
      setGetError(
        HoaStatus.error?.data?.message ||
        HoaStatus.error.error ||
        HoaStatus.error.data?.msg?.sqlMessage
      );
      setHoaStatusOptions([]);
    }
    //global spinner
  }, [HoaStatus.isFetching]);

  //current date

  var current_date = convertDateFormat(new Date());
  var due_date =
    convertDateFormat(moment(new Date()).businessAdd(3))

  const initialVal = {
    product_type_id: "",
    order_doc_list: [],
    customer_id: "",
    state_name: "",
    county_name: "",
    closer_name: "",
    product_type_name: "",
    selDisplay: "",
    selReadOnly: "",
    closer_id: "",
    buyer: "",
    state_id: "",
    county_id: "",
    branch_id: "",
    hoa_status_id: "",
    file_number: "",
    seller: "",
    property_address: "",
    legal_description: "",
    examiner_id: "",
    wholesale_fee: "",
    retail_fee: "",
    order_created_date: current_date,
    due_date: due_date,
    completion_date: "",
    updated_date: "",
    delivered_date: "",
    is_integration: 0,
    integration_order_id_no: "",
    transaction_type_id: "",
    transaction_type_name: "",
    notice_id: "",
    hoa_opened_by: "",
    payment_status_check: "0",
    payment_status_name: "",
    status_id: editId ? null : 1,
    user_id: user.user_id,
    version: orderEvent === "resubmit" ? 2 : "1",
    hoa_examiner_id: "",
    hoa_due_date: due_date,
    hoa_delivered_date: "",
    hoa_issued_date: "",
    hoa_created_date: current_date,
  };


  const [values, setValues] = useState(initialVal);
  const [document_list, setDocument_list] = useState([]);
  const [delete_document_list, setDelete_document_list] = useState([]);

  // //clear error function
  // function clearStatus() {
  //   setGetError(null);
  // }
  //variables
  const isHOAFlag =
    values.product_type_id === 4 ||
    values.product_type_id === 2 ||
    values.product_type_id === 9 ||
    values.product_type_id === 5;

  //base on cusmer values
  function clearCustomer() {
    setproductTypeOptionsData([]);
    setCountyOptionsData([]);
    setStateOptionsData([]);
    setBranchOptionsData([]);
    // setCloserOptionsData([]);
  }
  //base on branch values
  function clearbranch() {
    setproductTypeOptionsData([]);
  }

  useEffect(() => {
    if (editId) {
      setShowEditMode(editable);
    } else {
      setShowEditMode(true);
    }
    if (!editable && orderEvent !== "add") {
      //  orderEditorApi({id:editId, token});
      // orderDocsList({ order_id: editId, token });
    }
    setGetError(false);
  }, [editable]);

  useEffect(() => {
    if (orderEvent === "add") {
      masterOptionsList()
      setValues(initialVal)
    }
  }, [orderEvent]);

  //refetch when click on summary tab
  useEffect(() => {
    if (refresh === "summary" && editId) {
      orderEditorApi({ id: editId, token });
      //get document list
    }
    setGetError(false);
  }, [refresh]);


  //calling rtk query hook for customer-list options
  const [customerOptions, customerOptionsResult] =
    useOrdercustomerListMutation();

  //update conflicts info

  //calling rtk query hook for branch-list options
  const [branchOptions, branchOptionsResult] = useBranchOptionsMutation();


  //calling rtk query hook for prdocttype list options based on branchid
  const [branchProductTypeOptions, branchProductTypeOptionsResult] =
    useUsersProductTypeMutation();

  //calling rtk query hook for state list options based on customer
  const [getStatesBaseOnCustomer, getStatesBaseOnCustomerResult] =
    useGetStatesBaseOnCustomerMutation();

  //calling rtk query hook for county list options based on customer and state_id
  const [getcountyBaseCustomerAndState, getcountyBaseCustomerAndStateResult] =
    useGetcountyBaseCustomerAndStateMutation();

  //calling rtk query hook for closer-list options
  const [closerOptions, closerOptionsResult] = useOrdercloserListMutation();

  //calling rtk query hook for import-list search options
  // const [orderSearch, orderSearchResult] = useOrderSearchMutation();

  //calling rtk query for readonly & display cert comment list
  const [orderReadOnlyCommentListApi, orderReadOnlyCommentListResult] = useOrderReadOnlyCommentMutation();

  const [orderDisplayCertCommentListApi, orderDisplayCertCommentListResult] =
    useOrderDisplayCertCommentListQueryMutation();

  const [noticeflagApi, noticeflagResult] = useNoticeFlagListMutation();
  //calling transition  list
  const [TransactionTypeListApi, TransactionTypeListResult] = useTransactionTypeListMutation();
  //calling rtk query for read
  const [orderExaminerApi, orderExaminerResult] = useExaminerOrderListMutation();

  function masterOptionsList() {
    TransactionTypeListApi({ token })
    noticeflagApi({ token })
    orderExaminerApi({ token })
    orderReadOnlyCommentListApi({ token })
    orderDisplayCertCommentListApi({ token })
  }

  //calling rtk query for readonly & display cert wholesale pricre

  const [orderProductListPricing, orderProductListPricingResult] =
    useOrderProductListPricingMutation();

  //calling rtk query hook for state list options based on customer
  const [orderProductlistA, orderProductlistAResult] =
    useOrderProductlistAMutation();

  //calling rtk query hook for state list options based on customer
  const [orderuserProductTypeList, orderuserProductTypeListResult] =
    useOrderuserProductTypeListMutation();





  ///useEffect for customer list
  useEffect(() => {
    if (customerOptionsResult.isSuccess) {
      setCustomerOptionsData(customerOptionsResult.data.data);

    }
    if (customerOptionsResult.isError) {
      setCustomerOptionsData([]);
    }
    setLoading(customerOptionsResult.isLoading);
  }, [customerOptionsResult.isLoading]);

  ///useEffect for default data
  useEffect(() => {
    if (orderuserProductTypeListResult.isSuccess) {
      const { product_list, customer_id } =
        orderuserProductTypeListResult.data.data;
      setproductTypeOptionsData(product_list);
      getStatesBaseOnCustomer({ id: customer_id, token: token });
    }
    if (orderuserProductTypeListResult.isError) {
      setproductTypeOptionsData([]);
    }
    setLoading(orderuserProductTypeListResult.isLoading);
  }, [orderuserProductTypeListResult.isLoading]);

  ///useEffect for branch list
  useEffect(() => {
    if (branchOptionsResult.isSuccess) {
      setBranchOptionsData(branchOptionsResult.data.data);
    }
    if (branchOptionsResult.isError) {
      setBranchOptionsData([]);
    }
    setLoading(branchOptionsResult.isLoading);
  }, [branchOptionsResult.isLoading]);

  ///useEffect for productype list
  useEffect(() => {
    if (branchProductTypeOptionsResult.isSuccess) {
      setproductTypeOptionsData(branchProductTypeOptionsResult.data.data);
    }
    if (branchProductTypeOptionsResult.isError) {
      setproductTypeOptionsData([]);
    }
    setLoading(branchProductTypeOptionsResult.isLoading);
  }, [branchProductTypeOptionsResult.isLoading]);

  ///useEffect for Order document list
  useEffect(() => {
    if (orderDocsListResult.isSuccess) {


      setOrder_doc_list(orderDocsListResult.data.data);
      dispatch(addOrderData({ ...orderDATA, order_doc_list: orderDocsListResult.data.data }))
      setParcelController({
        ...parcelController,
        orderData: {
          ...parcelController.orderData,
          order_doc_list: orderDocsListResult.data.data,
        },
        orderRefecth: orderEditor,
      });
    }
    if (orderDocsListResult.isError) {
      setOrder_doc_list([]);
      dispatch(addOrderData({ ...orderDATA, order_doc_list: [] }))
    }
    setGlobalVal({ ...globalVal, loader: orderDocsListResult.isLoading });
  }, [orderDocsListResult.isLoading]);

  ///useEffect for Order document list refech after download certificate

  // useEffect(() => {
  //   if (addCertificateDocumentsResult.isSuccess) {
  //     if (refresh === "summary") {
  //       orderDocsList({ order_id: editId, token });
  //     }
  //   }
  // }, [addCertificateDocumentsResult.isLoading]);

  ///useEffect for closer list
  useEffect(() => {
    if (closerOptionsResult.isSuccess) {
      setCloserOptionsData(closerOptionsResult.data.data);
    }
    if (closerOptionsResult.isError) {
      setCloserOptionsData([]);
    }
    setLoading(closerOptionsResult.isLoading);
  }, [closerOptionsResult.isLoading]);



  ///useEffect for state list
  useEffect(() => {
    if (getStatesBaseOnCustomerResult.isSuccess) {
      setStateOptionsData(getStatesBaseOnCustomerResult.data.data);
    }
    if (getStatesBaseOnCustomerResult.isError) {
      setStateOptionsData([]);
    }
    setLoading(getStatesBaseOnCustomerResult.isLoading);
  }, [getStatesBaseOnCustomerResult.isLoading]);

  ///useEffect for county list
  useEffect(() => {
    if (getcountyBaseCustomerAndStateResult.isSuccess) {
      setCountyOptionsData(getcountyBaseCustomerAndStateResult.data.data);
    }
    if (getcountyBaseCustomerAndStateResult.isError) {
      setCountyOptionsData([]);
    }
    setLoading(getcountyBaseCustomerAndStateResult.isLoading);
  }, [getcountyBaseCustomerAndStateResult.isLoading]);



  useEffect(() => {
    if (orderProductListPricingResult.isSuccess) {
      const { retail, wholesale } = orderProductListPricingResult?.data?.data;

      setValues({
        ...values,
        retail_fee: retail || "",
        wholesale_fee: wholesale || "",
      });
    }
    if (orderProductListPricingResult.isError) {
      setValues({
        ...values,
        retail_fee: values.retail_fee || "",
        wholesale_fee: values.wholesale_fee || "",
      });
    }
    setLoading(orderProductListPricingResult.isLoading);
  }, [orderProductListPricingResult.isLoading]);

  //useEffect  for closer branch id

  useEffect(() => {
    if (closerBranchIdResult.isSuccess) {
      let branchId = closerBranchIdResult.data?.data[0].branch_id;
      setValues({
        ...values,
        branch_id: branchId,
      });
      branchProductTypeOptions({ id: branchId, token: token });
    }
    if (closerBranchIdResult.isError) {
      setValues({
        ...values,
        branch_id: "",
      });
    }
  }, [closerBranchIdResult.isLoading]);

  useEffect(() => {
    if (orderProductlistAResult.isSuccess) {
      const { retail, wholesale } = orderProductlistAResult?.data?.data;

      setValues({ ...values, retail_fee: retail, wholesale_fee: wholesale });
    }
    if (orderProductlistAResult.isError) {
      setValues({
        ...values,
        retail_fee: values.retail_fee || "",
        wholesale_fee: values.wholesale_fee || "",
      });
    }
    setLoading(orderProductlistAResult.isLoading);
  }, [orderProductlistAResult.isLoading]);

  ///useEffect for county list
  useEffect(() => {
    if (orderEditor?.isSuccess) {
      const {
        read_only_comments,
        additional_notes,
        branch_id,
        status_id,
        order_notice_list,
        examiner_id,
        state_id,
        file_number,
        notice_id,
        notice_comments,
        order_created_date,
        customer_id,
        hoa_opened_by_first_name,
        order_opened_by_first_name,
        order_opened_by_last_name,
        hoa_due_date,
        hoa_opened_by,
        due_date,
        hoa_opened_by_last_name,
        display_on_cert_comments,
        ...data
      } = orderEditor.data.data[0];
      setValues({
        ...data,
        status_id,
        branch_id,
        customer_id,
        due_date,
        hoa_due_date,
        state_id,
        file_number: orderEvent === "clone" ? "" : file_number,
        notice_id:
          orderEvent === "edit" || orderEvent === "view" ? notice_id : "",
        order_notice_list:
          orderEvent === "edit" || orderEvent === "view"
            ? order_notice_list
            : [],
        notice_comments:
          orderEvent === "edit" || orderEvent === "view" ? notice_comments : "",
        examiner_id:
          (orderEvent === "edit" || orderEvent === "view") ? examiner_id : "",
        order_created_date:
          orderEvent === "edit" || orderEvent === "view"
            ? order_created_date
            : current_date,
        hoa_opened_by:
          (hoa_opened_by_first_name || hoa_opened_by_last_name) ? (hoa_opened_by_first_name || "") + " " + hoa_opened_by_last_name : "",
        hoa_created_date: order_created_date,
        opened_by: order_opened_by_first_name
          ? (order_opened_by_first_name || " ") +
          " " +
          order_opened_by_last_name
          : null,
        add_document_list: [],
      });
      //set Status
      setOrderStatus(status_id);
      if (examiner_id) {
        dispatch(showalertAssignHandler(false))
      } else if (examiner_id && (examiner_id !== user?.user_id)) {
        dispatch(showalertAssignHandler(true))
      } else {
        dispatch(showalertAssignHandler(true))
      }

      if (orderEvent !== "add") {
        //hit order document list
        orderDocsList({ order_id: editId, token });
      }
      //adding order data
      dispatch(addOrderData(orderEditor.data.data[0]))

      setDisplay_on_cert_comments(display_on_cert_comments);
      setRead_only_comments(read_only_comments);
      setAdditionNotes(additional_notes);
      setDelete_document_list([]);
      setDocument_list([]);
      //set the data as globaly of order for parcel

      setParcelController({
        ...parcelController,
        orderData: orderEditor.data.data[0]
      });

    }

    if (orderEditor.isError) {
      ///after submit details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: orderEditor.error?.data?.error ||
          orderEditor.error?.data?.message ||
          orderEditor.error?.data?.msg?.sqlMessage ||
          (orderEditor.error?.error && "server unreachable!") ||
          orderEditor.err //here created for in loop for catch server errorsor.data?.msg.sqlMessage
      });

    }

    // setGlobalVal({ ...globalVal, loader: orderEditor.isLoading });
  }, [orderEditor?.isLoading]);

  //useEffect for OrderEditor
  useEffect(() => {
    if (orderDATA?.id && orderEvent === "edit") {

      const { branch_id, customer_id, state_id } = orderDATA
      if (editId) {
        masterOptionsList()
        //calling apis
        branchOptions({ id: customer_id, token });
        branchProductTypeOptions({ id: branch_id, token: token });
        getStatesBaseOnCustomer({ id: customer_id, token: token });

        closerOptions({ id: customer_id, token: token });
      }
      getcountyBaseCustomerAndState({
        stateId: state_id,
        customerId: customer_id,
        token,
      });
    }

  }, [orderDATA?.id, orderEvent])


  ///useEffect for edit list
  useEffect(() => {
    if (orderEditorPostResult.isSuccess) {
      const {
        read_only_comments,
        additional_notes,
        file_number,
        branch_id,
        notice_id,
        notice_comments,
        state_id,
        customer_id,
        order_notice_list,
        examiner_id,
        display_on_cert_comments,
        order_created_date,
        ...data
      } = orderEditorPostResult.data.data[0];
      setValues({
        ...data,
        file_number: file_number + "-1",
        status_id: 1,
        branch_id,
        customer_id,
        state_id,
        order_created_date: current_date,
      });
      setDisplay_on_cert_comments(display_on_cert_comments);
      setRead_only_comments(read_only_comments);
      setAdditionNotes(additional_notes);

      //calling apis
      branchOptions({ id: customer_id, token });
      branchProductTypeOptions({ id: branch_id, token: token });
      getStatesBaseOnCustomer({ id: customer_id, token: token });
      getcountyBaseCustomerAndState({
        stateId: state_id,
        customerId: customer_id,
        token,
      });
      closerOptions({ id: customer_id, token: token });
    }

    if (orderEditorPostResult.isError) {

      setGetError(
        orderEditorPostResult.error?.data?.error ||
        orderEditorPostResult.error?.data?.message ||
        orderEditorPostResult.error.data?.msg?.sqlMessage ||
        (orderEditorPostResult.error.error && "server unreachable!") ||
        orderEditorPostResult.err //here created for in loop for catch server errorsor.data?.msg.sqlMessage
      );
    }

    setGlobalVal({ ...globalVal, loader: orderEditorPostResult.isLoading });
  }, [orderEditorPostResult.isLoading]);

  //useEffect for customerbranchListOptions & based on customer-branch-list api call triggers for customer list & branch list

  //calling rtk query hook for customer-list options

  // calling rtk query hook for userorder-product-type-list options based on user-id for first time load

  //calling rtk query hook for adding order
  //add order
  const [addOrder, addOrderResult] = useAddOrderMutation();

  // //getting status api
  // const orderStatusApi = useOrderStatusQuery(token);

  //update order
  const [orderUpdate, orderUpdateResult] = useOrderUpdateMutation();

  // ///useEffect for status list for hoa status
  // useEffect(() => {
  //   if (orderStatusApi.isSuccess) {
  //     setStatusOptionsData(orderStatusApi.data.data);
  //   } else {
  //     setStatusOptionsData([]);
  //   }
  // }, [orderStatusApi.isFetching]);

  useEffect(() => {
    internalOrder.map((item, index) => {
      if (item.read_comments || item.display_comments || item.additional_notes)
        setshowComment(true)
    })

  }, [internalOrder])
  useEffect(() => {
    if (addOrderResult.isSuccess) {
      ///after submit details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: addOrderResult.data.message,
      });

      setGetError(false);
      setShowEditMode(false);
      setEditable(false);
      dispatch(certificateDownloadLoadingHandler(true))
      dispatch(bakendAutomationHandler(false))
      const { id } = addOrderResult.data.data;
      navigate(`/order-editor`, {
        state: {
          id,
          orderNumber: values.file_number,
          orderStatus: 1,
          orderEditableFlag: false,
          orderEvent: "afterAddOrder",
        },
      });
      setOrderStatus(1);
      //call edit api
      orderEditorApi({ id, token });

    }
    if (addOrderResult.error) {
      //here created for in loop for catch server errors
      setGetError(
        addOrderResult.error?.data?.error ||
        addOrderResult.error?.data?.message ||
        (addOrderResult.error.error && "server unreachable!") ||
        addOrderResult.error.data?.msg.sqlMessage
      );
    }

    // setGlobalVal({ ...globalVal, loader: addOrderResult.isLoading });
  }, [addOrderResult.isLoading]);

  useEffect(() => {
    if (orderUpdateResult.isSuccess) {
      ///after submit details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: orderUpdateResult.data.message,
      });

      setGetError(false);
      setShowEditMode(false);
      setEditable(false);

      //calling api for parcel api validate from props its getting
      validateConflicts({
        data: { order_id: editId },
        token,
      });

      const { status_id } = orderUpdateResult.data.data;
      orderDocsListResult.isLoading = true;
      navigate(`/order-editor`, {
        state: {
          id: editId,
          orderNumber: values.file_number,
          orderStatus: status_id,
          orderEditableFlag: true,
          orderEvent: orderEvent,
        },
      });
    }
    if (orderUpdateResult.error) {
      //here created for in loop for catch server errors
      setGetError(
        orderUpdateResult.error?.data?.error ||
        orderUpdateResult.error?.data?.message ||
        (orderUpdateResult.error.error && "server unreachable!") ||
        orderUpdateResult.error.data?.msg.sqlMessage
      );
    }

    // setGlobalVal({ ...globalVal, loader: orderUpdateResult.isLoading });
  }, [orderUpdateResult.isLoading]);

  //useEffect for customerlist
  useEffect(() => {
    if (user.user_id && (orderEvent === "edit" || orderEvent == "add")) {
      if (user.user_type_id === 6) {
        closerOptions({ id: user.customer_id, token });
        getStatesBaseOnCustomer({ id: user.customer_id, token });
      } else {
        customerOptions({ id: user.user_id, token: token });
      }
    }

    //setting fields !editable only for super admin ,vendor admin & vendor user

    setNoticeFlagOptionsData([]);

    setDocument_lists([]);
    clearCustomer();
    if (editId) {
      setShowToollBar(true);
    } else {
      setShowToollBar(false);

    }
    if (orderEvent === "add") {
      setRead_only_comments("");
      setDisplay_on_cert_comments("");
      if (
        user.user_type_id === 1 ||
        user.user_type_id === 2 ||
        user.user_type_id === 3 ||
        user.user_type_id === 8
      ) {
        setOrderTypeFlag(2);
        //calling status api
      } else if (user.user_type_id === 6) {
        setOrderTypeFlag(1);
        orderuserProductTypeList({ id: user.user_id, token });
      } else {
        setOrderTypeFlag(2);
      }
    }
  }, [user.user_id, orderEvent]);


  //select handler
  const selectHandler = (property, value, attributeselect) => {
    clearStatus();

    setValues({
      ...values,
      [property]: Number(value),
    });

    //
    if (property === "closer_id" && value) {
      closerBranchId({ id: value, token });
    }

    if (property === "customer_id") {
      if (Number(value) !== 0) {
        getStatesBaseOnCustomer({ id: Number(value), token: token });
        branchOptions({ id: Number(value), token: token });

        //closer api
        closerOptions({ id: Number(value), token: token });

        setproductTypeOptionsData([]);
        setValues({
          ...values,
          [property]: Number(value),
          branch_id: "",
          product_type_id: "",
          county_id: "",
          wholesale_fee: "",
          retail_fee: "",
          state_id: "",
          closer_id: "",
        });
      } else {
        setValues({
          ...values,
          [property]: "",
          branch_id: "",
          product_type_id: "",
          county_id: "",
          state_id: "",
          wholesale_fee: "",
          retail_fee: "",
          closer_id: "",
        });
        clearCustomer();
      }
    }
    if (property === "branch_id") {
      if (Number(value) !== 0) {
        branchProductTypeOptions({ id: Number(value), token: token });

        setValues({
          ...values,
          [property]: Number(value),
          closer_id: closerOptionsData[0].id,
          product_type_id: "",
          wholesale_fee: "",
          retail_fee: "",
        });
      } else {
        setValues({
          ...values,
          [property]: "",
          closer_id: closerOptionsData[0].id,
          product_type_id: "",
          wholesale_fee: "",
          retail_fee: "",
        });
        clearbranch();
      }
    }
    if (property === "state_id") {
      if (value) {
        getcountyBaseCustomerAndState({
          stateId: value,
          customerId: values.customer_id || user.customer_id,
          token,
        });
        setValues({
          ...values,
          [property]: Number(value),
          county_id: "",
        });
      } else {
        setValues({
          ...values,
          [property]: Number(value),
          county_id: "",
        });
        setCountyOptionsData([]);
      }
    }
    if (property === "product_type_id") {
      if (value) {
        if (orderTypeFlag === 1) {
          orderProductlistA({
            data: {
              user_id: user.user_id,
              product_type_id: value,
              state_id: values.state_id || 99,
              county_id: values.county_id || 9999,
            },
            token,
          });
        } else if (orderTypeFlag === 2) {
          orderProductListPricing({
            data: {
              branch_id: values.branch_id,
              product_type_id: value,
              state_id: values.state_id || 99,
              county_id: values.county_id || 9999,
            },
            token,
          });
        }
        setValues({
          ...values,
          [property]: Number(value),
          hoa_status_id:
            value === "4" || value === "2" || value === "9" || value === "5"
              ? statusOptionsData[0].id
              : "",
        });
      } else {
        setValues({
          ...values,
          product_type_id: "",
          wholesale_fee: "",
          retail_fee: "",
        });
      }
    }
    if (property === "notice_id") {
      if (value) {
        setShowNoticeCommentModal(true);
        setValues({
          ...values,
          [property]: Number(value),
        });
      } else {
        setShowNoticeCommentModal(false);
        setValues({
          ...values,
          [property]: Number(value),
          comments: "",
        });
      }
    }

    if (property === "county_id") {
      if (value) {
        if (orderTypeFlag === 1) {
          orderProductlistA({
            data: {
              user_id: user.user_id,
              product_type_id: values.product_type_id,
              state_id: values.state_id,
              county_id: value,
            },
            token,
          });
        } else if (orderTypeFlag === 2) {
          orderProductListPricing({
            data: {
              branch_id: values.branch_id,
              product_type_id: values.product_type_id,
              state_id: values.state_id,
              county_id: value,
            },
            token,
          });
        }

        setValues({
          ...values,
          [property]: Number(value),
        });
      } else {
        setShowNoticeCommentModal(false);
        setValues({
          ...values,
          [property]: Number(value),
        });
      }
    }
  };

  const inputs = [
    {
      id: 2,
      name: "customer_id",
      type: "select",
      value:
        (editId && !editable ? values?.customer_name : values?.customer_id) ||
        "",
      placeholder: "Select Customer ",
      ...(customerOptionsResult?.isLoading ? {} : { required: true, errorMessage: "Please select customer !" }),
      label: "Customer",
      options: customerOptionsData,
      column: "6",
      rowcolor: true,
      disabled: editId && parcelList?.length > 0,
    },
    {
      id: 3,
      name: "closer_id",
      type: "select",
      value:
        (editId && !editable
          ? values?.closer_name || " "
          : values?.closer_id) || "",
      options: closerOptionsData,
      column: "6",
      placeholder: "Select Closer",
      ...(closerOptionsResult?.isLoading ? {} : { required: true, errorMessage: "Please select closer!" }),
      label: "Closer",
      rowcolor: true,
      disabled: editId && parcelList?.length > 0,
    },
    {
      id: 6,
      name: "branch_id",
      type: "select",
      value:
        (editId && !editable ? values?.branch_name : values?.branch_id) || "",
      options: branchOptionsData,
      column: "6",
      placeholder: "Select Branch",
      ...(branchOptionsResult?.isLoading ? {} : { required: true, errorMessage: "Please select branch !" }),
      label: "Branch",
      rowcolor: true,
      disabled: editId && parcelList?.length > 0,
    },
    {
      id: 1,
      name: "product_type_id",
      type: "select",
      options: productTypeOptionsData,
      value:
        (editId && !editable
          ? values?.product_type_name
          : values?.product_type_id) || "",
      column: "6",
      placeholder: "Select Product Type",
      ...(branchProductTypeOptionsResult?.isLoading ? {} : { required: true, errorMessage: "Please select product type!" }),
      label: "Product Type",
      rowcolor: true,
      disabled: editId && parcelList?.length > 0,
    },
    {
      id: 1659,
      name: "transaction_type_id",
      type: "select",
      options: TransactionTypeListResult.isLoading ? [] : TransactionTypeListResult?.data?.data || [],
      value:
        (editId && !editable
          ? values?.transaction_type_name
          : values?.transaction_type_id) || "",
      column: "6",
      placeholder: "Select Transaction Type",
      errorMessage: "Please select transaction type!",
      label: "Transaction Type",
      rowcolor: true,
      disabled: editId && parcelList?.length > 0,
    },

    {
      id: 7,
      name: "file_number",
      type: "text",
      label: "File#",
      value: values?.file || "",
      placeholder: "Enter File Number",
      column: "6",
      errorMessage: "Please enter file number!",
      visible: true,
      rowcolor: true,
      required: true,
      disabled: editId && parcelList?.length > 0 && orderEvent !== "clone",
    },

    {
      id: 75,
      name: "state_id",
      type: "select",
      value: (editId && !editable ? values.state_name : values?.state_id) || "",
      options: stateOptionsData,
      column: "6",
      placeholder: "Select State",
      errorMessage: "Please select state!",
      label: "State",
      rowcolor: true,
      required: true,
      disabled: editId && parcelList?.length > 0,
    },
    {
      id: 5,
      name: "county_id",
      type: "select",
      value:
        (editId && !editable ? values.county_name : values?.county_id) || "",
      options: countyOptionsData || [],//asendingList(countyOptionsData, "county_name"),
      column: "6",
      placeholder: "Select County",
      errorMessage: "Please select county!",
      label: "County",
      rowcolor: true,
      required: true,
      disabled: editId && parcelList?.length > 0,
    },
    {
      id: 66,
      name: "notice_id",
      type: "select",
      value:
        (editId && !editable
          ? values?.notice_name || " "
          : values?.notice_id) || "",
      options: noticeflagResult?.isLoading ? [] : noticeflagResult.data?.data || [],
      column: "6",
      placeholder: "Select Notice",
      errorMessage: "Please select notice!",
      label: (
        <>
          Notice Flag{" "}
          {((editId && orderEvent === "edit") || orderEvent === "view") && values?.order_notice_list?.length > 0 && (
            <>
              <AiOutlineEye
                color="green"
                onClick={() => {
                  setshowNoticeModal(true);
                }}
                cursor={"pointer"}
              />
              <div
                onMouseEnter={() => {
                  if (values.notice_comments) {
                    setOnHoverComment(true);
                  }
                }}
                onMouseLeave={() => setOnHoverComment(false)}
                className="position-relative d-inline"
              >
                <BiHistory
                  className="ms-1"
                  color="#000"
                  size={11}
                  cursor={"pointer"}
                />
                <div
                  className={`showcurrent_comments p-2 border rounded-3 ${onHoverComment ? "active" : ""
                    }`}
                >
                  <span className="fw-bold d-block">
                    Date: {values.notice_created_at}
                  </span>
                  <span> {values?.notice_comments}</span>
                </div>
              </div>
            </>
          )}
        </>
      ),

      rowcolor: true,
    },
    {
      id: 8,
      name: "seller",
      type: "text",
      label: "Owner/Seller(s)",
      value: values?.seller || "",
      placeholder: "Enter Seller",
      column: "6",
      maxLength: 300,
      errorMessage: "Please enter seller!",
      visible: true,
      rowcolor: true,
    },
    {
      id: 34,
      name: "buyer",
      type: "text",
      value: values?.buyer || "",
      label: "Buyer(s)",
      maxLength: 300,
      placeholder: "Enter Buyer",
      column: "6",
      errorMessage: "Please enter buyer!",
      visible: true,
      rowcolor: true,
    },

    {
      id: 18,
      name: "wholesale_fee",
      label: "Wholesale Fee",
      value: values?.wholesale_fee || "",
      placeholder: "Enter Wholesale Fee",
      column: "6",
      errorMessage: "Please enter wholesale fee!",
      comments: false,
      visible: true,
      required: true,
    },
    {
      id: 19,
      name: "retail_fee",
      label: "Retail Fee",
      value: values?.retail_fee || "",
      placeholder: "Enter Retail Fee",
      column: "6",
      errorMessage: "Please enter retail fee!",
      comments: false,
      required: true,
      visible: true,
    },

    {
      id: 7,
      name: "property_address",
      type: "textArea",
      label: "Property Address(s)",
      value:
        (editId && !editable
          ? values?.property_address || " "
          : values?.property_address) || "",
      placeholder: "Enter Property Address",
      column: "6",
      errorMessage: "Please enter property address!",
      visible: true,
      rowcolor: true,
    },
    {
      id: 17,
      name: "legal_description",
      type: "textArea",
      label: "Legal Description(s)",
      value:
        (editId && !editable
          ? values?.legal_description || " "
          : values?.legal_description) || "",
      placeholder: "Enter Legal Description",
      column: "6",
      errorMessage: "Please enter legal description!",
      visible: true,
      rowcolor: true,
    },

    {
      id: 45420,
      name: "completion_date",
      type: "date",
      label: "Completed Date",
      value: values?.completion_date || "",
      column: "6",
      usaFormat: true,
      errorMessage: "Please enter copmleted date!",
      comments: false,
      // required: orderEvent !== "add" ? true : false,
      visible: orderEvent !== "add" ? true : false,
      disabled: true,
      rowcolor: false,
    },
    {
      id: 45424520,
      name: "order_update_date",
      type: "date",
      usaFormat: true,
      label: "Updated/Modify Date",
      value: orderEvent === "view" ? values.order_update_date : current_date,
      column: "6",
      errorMessage: "Please enter updated/modify date!",
      comments: false,
      // required: orderEvent !== "add" ? true : false,
      visible: orderEvent !== "add" ? true : false,
      disabled: true,
      rowcolor: false,
    },
    {
      id: 20,
      name: "order_created_date",
      type: "date",
      usaFormat: true,
      label: "Created/Open Order Date",
      placeholder: "Enter Created/Open Order Date",
      value: values?.order_created_date || "",
      column: "6",
      errorMessage: "Please enter created/open order date!",
      comments: false,
      // required: true,
      visible: true,
      disabled: true,
      rowcolor: false,
    },
    {
      id: 2145,
      name: "deliver_back_date",
      type: "date",
      usaFormat: true,
      value: values?.deliver_back_date || "",
      errorMessage: "Please select deliver back date!",
      label: "Delivered Back Date",
      column: "6",
      comments: false,
      // required: orderEvent !== "add" ? true : false,
      visible: orderEvent !== "add" ? true : false,
      rowcolor: false,
      disabled:
        user.user_type_id === 1 ||
          user.user_type_id === 2 ||
          user.user_type_id === 8 ||
          user.user_type_id === 13 ||
          user.user_type_id === 14 ||
          user.user_type_id === 3
          ? false
          : true,
    },

    {
      id: 65,
      name: "opened_by",
      type: "text",
      value: values?.opened_by || "",
      // options: [],
      column: "6",
      // errorMessage: "Please select opened by !",
      label: "Opened By",
      disabled: true,
      required: orderEvent !== "add" ? true : false,
      visible: orderEvent !== "add" ? true : false,
      rowcolor: false,
    },
    {
      id: 31,
      name: "integration_order_id_no",
      type: "text",
      label: "Integration #",
      value: values?.integration_order_id_no || "",
      placeholder: "Enter Integration #",
      column: "6",
      errorMessage: "Please enter integration #!",
      rowcolor: false,
      visible: true,
    },
    ...(values.integration_order_id_no ? [{
      id: 31,
      name: "is_integration",
      type: "select",
      label: "Order Type",
      value: (editId && !editable ? values?.is_integration_name : values?.is_integration) || "",
      options: integrationOptions,
      column: "6",
      rowcolor: false,
      visible: true,
    }] : []),
    {
      id: 21,
      name: "due_date",
      type: "date",
      usaFormat: true,
      value: values?.due_date || "",
      errorMessage: "Please select due date!",
      label: "Due Date",
      min: current_date,
      column: `6`,
      comments: false,
      visible:
        user.user_type_id === 1 ||
          user.user_type_id === 2 ||
          user.user_type_id === 8 ||
          user.user_type_id === 13 ||
          user.user_type_id === 14 ||
          user.user_type_id === 3
          ? true
          : false,
      // required:
      //   user.user_type_id === 1 ||
      //   user.user_type_id === 2 ||
      //   user.user_type_id === 8 ||
      //   user.user_type_id === 3
      //     ? true
      //     : false,
      rowcolor: false,
    },

    {
      id: 67,
      name: "payment_status_check",
      type: "select",
      value: (editId && !editable ? values?.payment_status_name : values?.payment_status_check) || "",
      options: [
        {
          id: 0,
          name: "Not Paid",
        },
        {
          id: 1,
          name: "Paid",
        },
      ],
      column: "6",
      placeholder: "Select Paid Status",
      errorMessage: "Please select paid status !",
      label: "Order Paid Status",
      disabled: orderEvent !== "add" ? false : true,
    },
    {
      id: 35,
      name: "examiner_id",
      type: "select",
      value:
        (editId && !editable
          ? values?.examiner_name || ""
          : values?.examiner_id) || "",
      options: orderExaminerResult?.isLoading ? [] : orderExaminerResult?.data?.data || [],
      column: `6 ${user.user_type_id === 1 ||
        user.user_type_id === 2 ||
        user.user_type_id === 8 ||
        user.user_type_id === 13 ||
        user.user_type_id === 14 ||
        user.user_type_id === 20 ||
        user.user_type_id === 3
        ? ""
        : "d-none"
        }`,
      placeholder: "Select Examiner",
      errorMessage: "Please select examiner !",
      label: "Examiner Assigned",
      comments: false,
      disabled:
        user.user_type_id === 1 ||
          user.user_type_id === 2 ||
          user.user_type_id === 8 ||
          user.user_type_id === 13 ||
          user.user_type_id === 14 ||
          user.user_type_id === 20 ||
          user.user_type_id === 3
          ? false
          : true,
    },

    {
      id: 51,
      name: "read_only",
      type: "textArea",
      value: values?.read_only || "",
      column: "6",
      label: "Read Only",
      comments: true,
      icon: true,
    },
    {
      id: 52,
      name: "display_on_cert",
      type: "textArea",
      value: values?.display_on_cert || "",
      column: "6",
      label: "Display on Cert",
      comments: true,
      icon: true,
    },
    {
      id: 53,
      name: "additional_notes",
      type: "textArea",
      value: values?.additional_notes || "",
      column: "12",
      comments: true,
      placeholder: "Enter note",
      icon: false,
      // label: "Additional Notes",
    },

    //hoa fields
    {
      id: 145008,
      name: "hoa_status_id",
      type: "select",
      label: "HOA Status",
      value:
        (editId && !editable
          ? values?.hoa_status_name
          : values?.hoa_status_id) || "",
      column: "6",
      classlabelstyle: "hoastatus fs-6 fw-bold text-primary",
      options: hoaStatusOptions,
      comments: false,
      disabled: orderEvent === "add" ? true : false,
      showType: "hoa",
    },
    {
      id: 145235454008,
      name: "hoa_examiner_id",
      type: "select",
      label: "HOA Assigned",
      value:
        (editId && !editable
          ? values.hoa_examiner_first_name + values.hoa_examiner_last_name
          : values?.hoa_examiner_id) || "",
      column: "6",
      options: orderExaminerResult?.isLoading ? [] : orderExaminerResult?.data?.data || [],
      showType: "hoa",
    },
    {
      id: 31445,
      name: "hoa_created_date",
      type: "date",
      usaFormat: true,
      label: "Created Date",
      value: values?.hoa_created_date || "",
      column: "6",
      errorMessage: "Please enter created date!",
      showType: orderEvent !== "add" ? "hoa" : false,
      // required: orderEvent !== "add" ? "hoa" : false,
      disabled: true,
    },

    {
      id: 315242,
      name: "hoa_due_date",
      type: "date",
      usaFormat: true,
      label: "Due Date",
      value: values?.hoa_due_date || "",
      column: "6",
      errorMessage: "Please enter due date!",
      showType: "hoa",
      min: current_date,
      visible:
        user.user_type_id === 1 ||
          user.user_type_id === 2 ||
          user.user_type_id === 8 ||
          user.user_type_id === 14 ||
          user.user_type_id === 13 ||
          user.user_type_id === 3
          ? true
          : false,
      // required:
      //   user.user_type_id === 1 ||
      //   user.user_type_id === 2 ||
      //   user.user_type_id === 8 ||
      //   user.user_type_id === 3
      //     ? true
      //     : false,
    },
    {
      id: 315242,
      name: "hoa_delivered_date",
      type: "date",
      label: "Delivered Date",
      value: values?.hoa_delivered_date || "",
      column: "6",
      usaFormat: true,
      errorMessage: "Please enter delivered date!",
      showType: orderEvent !== "add" ? "hoa" : false,
      // required: orderEvent !== "add" ? "hoa" : false,
      disabled:
        user.user_type_id === 1 ||
          user.user_type_id === 2 ||
          user.user_type_id === 8 ||
          user.user_type_id === 14 ||
          user.user_type_id === 13 ||
          user.user_type_id === 3
          ? false
          : true,
    },
    {
      id: 46233,
      name: "hoa_issued_date",
      type: "date",
      usaFormat: true,
      label: "Completed Date",
      value: values?.hoa_issued_date || "",
      column: "6",
      errorMessage: "Please enter isseued date!",
      showType: orderEvent !== "add" ? "hoa" : false,
      // required: orderEvent !== "add" ? "hoa" : false,
      disabled:
        user.user_type_id === 1 ||
          user.user_type_id === 2 ||
          user.user_type_id === 8 ||
          user.user_type_id === 14 ||
          user.user_type_id === 13 ||
          user.user_type_id === 3
          ? false
          : true,
    },
    {
      id: 55008,
      name: "hoa_opened_by",
      type: "text",
      label: "Opened By",
      value: values?.hoa_opened_by,
      column: "6",
      comments: false,
      showType: orderEvent !== "add" ? "hoa" : false,
      required: orderEvent !== "add" ? "hoa" : false,
      disabled: true,
    },
    { id: 5656, type: "file", name: "document_list" },
  ];
  //clear error function
  function clearStatus() {
    setGetError(false);
  }
  //Onchange for AppInput
  const onChange = async (e, date, dateFormat) => {
    clearStatus();
    if (dateFormat) {
      setValues({
        ...values,
        [e]: date,
      });
    } else {
      const { name, value } = e.target;
      setValues({
        ...values,
        [name]: value,
        // ...(name === "integration_order_id_no" && value ? { is_integration: 1 } : !value ? { is_integration: 0 } : {})
      });
    }

  };

  function resetFun() {
    clearStatus();
    if (orderEvent === "edit") {
      orderEditorApi({ id: editId, token });
    }
    if (orderEvent === "clone") {
      orderEditorPost({ id: editId, token });
    } else {
      clearCustomer();
      setValues(initialVal);
      setRead_only_comments("");
      setDisplay_on_cert_comments("");
      setAdditionNotes("");
      setDocument_lists([]);
    }
  }
  const handleReset = () => {
    resetFun();
  };

  //getting status api
  const [orderStatusUpdate, orderStatusUpdateResult] =
    useOrderStatusUpdateMutation();

  ///order status
  useEffect(() => {
    if (orderStatusUpdateResult.isSuccess) {
      removeBlank(values);
      delete values["selDisplay"];
      delete values["selReadOnly"];
      orderUpdate({
        data: {
          ...values,
          is_hoa: isHOAFlag,
          status_id: extraData?.status_id || values?.status_id,
          additional_notes: additionNotes,
          delete_document_list,
          read_only_comments,
          display_on_cert_comments,
          user_id: user.user_id,
        },
        id: editId,
        token: token,
      });

    } else if (orderStatusUpdateResult.isError) {
      setGetError(orderStatusUpdateResult.error?.data?.message)
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: orderStatusUpdateResult.isLoading });
  }, [orderStatusUpdateResult.isLoading]);

  const handleSubmit = (e) => {
    e.preventDefault();

    removeBlank(values);
    delete values["selDisplay"];
    delete values["selReadOnly"];


    if (!isHOAFlag) {
      delete values["hoa_due_date"];
      delete values["hoa_examiner_id"];
      delete values["hoa_issued_date"];
      delete values["hoa_status_id"];
      delete values["hoa_opened_by"];
      delete values["hoa_delivered_date"];
    }
    //commented temporary 
    // else if (
    //   user.user_type_id !== 1 &&
    //   user.user_type_id !== 2 &&
    //   user.user_type_id !== 3 &&
    //   user.user_type_id !== 8
    // ) {
    //   delete values["examiner_id"];
    // }
    // if (!read_only_comments || read_only_comments === "<p><br></p>") {
    //   setGetError("Please enter read only comments!");
    // } else if (
    //   !display_on_cert_comments ||
    //   display_on_cert_comments === "<p><br></p>"
    // ) {
    //   setGetError("Please enter display on cert comments!");
    // } else if (!additionNotes || additionNotes === "<p><br></p>") {
    //   setGetError("Please enter additional notes!");
    // }

    if (!editId || orderEvent === "clone") {
      addOrder({
        data: {
          ...values,
          file_number: values.file_number.trim(),
          is_hoa: isHOAFlag,
          additional_notes: additionNotes,
          read_only_comments,
          opened_by: user?.user_id,
          hoa_opened_by: user?.user_id,
          display_on_cert_comments,
          document_list,
          user_id: user?.user_id,
        },
        token: token,
      });
    } else if (
      orderEvent === "edit" ||
      orderEvent === "view" ||
      orderEvent === "afterAddOrder" ||
      orderEvent === "resubmit"
    ) {
      if (String(extraData?.status_id) === "6") {
        orderStatusUpdate({
          status_id: extraData?.status_id,
          id: editId,
          token,
        });
      } else {
        orderUpdate({
          data: {
            ...values,
            is_hoa: isHOAFlag,
            status_id: extraData?.status_id || values?.status_id,
            // hoa_opened_by: user.user_id,
            // opened_by: user.user_id,
            additional_notes: additionNotes,
            delete_document_list,
            read_only_comments,
            display_on_cert_comments,
            user_id: user.user_id,
          },
          id: editId,
          token: token,
        });
      }

    }
  };

  const onChangeHandler = (e) => {
    // clearStatus("");
    setValues({ ...values, [e.target.name]: e.target.value });
    clearStatus();
  };
  //show errors
  useEffect(() => {
    if (getError) {
      scrollComp("order_Error");
    }
  }, [getError]);

  // const searchOrderColumns = [
  //   {
  //     title: "Order Number",
  //     dataIndex: "reference_no",
  //     key: "reference_no",
  //     align: "center",
  //   },
  //   {
  //     title: "File Number",
  //     dataIndex: "file_number",
  //     key: "file_number",
  //     align: "center",
  //   },
  //   // {
  //   //   title: "Import",
  //   //   dataIndex: "operation",
  //   //   key: "operation",
  //   //   align: "center",
  //   //   width: 50,
  //   //   render: (_, record) => {
  //   //     return (
  //   //       <span>
  //   //         <DownloadOutlined
  //   //           style={{ cursor: "pointer" }}
  //   //           onClick={() => {
  //   //             orderEditorPost({ id: record.id, token });
  //   //             setActiveImport(false);
  //   //           }}
  //   //           size={50}
  //   //         />
  //   //       </span>
  //   //     );
  //   //   },
  //   // },
  // ];

  //useCallback for HOA fields
  const hoaForComp = useCallback(() => {
    return (
      !orderProductListPricingResult.isLoading &&
      internalOrder.map((item, index) => {
        if (item.hoa) {
          return (
            <div key={index} className="row border-dotted-2 rounded-4 m-2 py-2">
              <div className="h6 pb-2 mb-2 text-danger border-bottom border-danger">
                For HOA
              </div>
              {inputs.map((input) => (
                <>
                  {(input.type === "text" ||
                    input.type === "date" ||
                    input.type === "datetime") &&
                    input.showType === "hoa" && (
                      <AppInput
                        isEdit={editId && !editable}
                        defaultSet={""}
                        key={input.id}
                        {...input}
                        value={values[input.name]}
                        onChange={onChange}
                      />
                    )}

                  {input.type === "select" && input.name === "hoa_status_id" && (
                    <AppSelect
                      isEdit={editId && !editable}
                      key={input.id}
                      {...input}
                      //   defaultSet={afterClose}
                      onChange={selectHandler}
                      options={
                        <>
                          <option value={""}>Select Status</option>
                          {input.options.map((ele) => {
                            return (
                              <option key={ele.id} value={ele.id}>
                                {ele.name}
                              </option>
                            );
                          })}
                        </>
                      }
                    />
                  )}

                  {input.type === "select" &&
                    input.name === "hoa_examiner_id" && (
                      <AppSelect
                        isEdit={editId && !editable}
                        key={input.id}
                        {...input}
                        //   defaultSet={afterClose}
                        onChange={selectHandler}
                        options={
                          <>
                            <option value={""}>Select Assigned</option>
                            {input.options.map((ele) => {
                              return (
                                <option key={ele.id} value={ele.id}>
                                  {ele.user_name}
                                </option>
                              );
                            })}
                          </>
                        }
                      />
                    )}

                </>
              ))}
            </div>
          );
        }
        return null;
      })
    );
  }, [!orderProductListPricingResult.isLoading, values, editable]);

  //file comp here
  const editmodeMemo = useMemo(() => {
    return editable || !editId;
  }, [editable, editId]);

  //file comp here
  const initialFilesMemo = useMemo(() => {
    return (
      editId &&
      orderDocsListResult?.data?.data?.map(
        ({ document_name, id, type, documents, latest }) => {
          return { name: document_name, type, id, base64: documents, latest };
        }
      )
    ) || []
  }, [orderDocsListResult.isLoading]);

  const whileupdateCallback = useCallback(
    (value) => {
      if (editId) {
        setValues({
          ...values,
          add_document_list: value.map(({ name, base64, type }) => {
            return {
              file: base64,
              document_name: name,
              type,
            };
          }),
        });
      }
    },
    [values.add_document_list]
  );



  const onviewCallback = useCallback(
    (value, type, id) => {

      setGlobalVal({ ...globalVal, loader: true });
      orderDocumentDownload({ id, token }).then((orderDocumentDownloadResult) => {
        if (orderDocumentDownloadResult?.data) {
          dispatch(viewPdfDocuementHandler(orderDocumentDownloadResult?.data?.data))
        }
        setOrderController({ ...orderContorller, orderTwoView: true });

      }).finally(() => {
        setGlobalVal({ ...globalVal, loader: false });
      })

    },
    [orderContorller.pdfDoc, orderContorller, viewPdfDocuementHandler]
  );

  const deleteFileCallback = useCallback(
    (value) => {
      if (!delete_document_list.includes(value)) {
        setDelete_document_list([...delete_document_list, value]);
      }
      setOrderController({ ...orderContorller, pdfDoc: false });
      setParcelController({
        ...parcelController,
        orderTwoView: orderContorller.orderConflicts ? true : false,
      });
    },
    [
      orderContorller.pdfDoc,
      orderContorller?.orderTwoView,
      delete_document_list,
    ]
  );
  const onChangeCallback = useCallback(
    (value) => {
      if (!editId) {
        setDocument_list(
          value.map(({ name, base64, type }) => {
            return {
              file: base64,
              document_name: name,
              type,
            };
          })
        );
      }
    },
    [document_list, editable]
  );

  return (
    <div>
      {/* //clone order  */}
      {orderEvent === "add" && (
        <OrderList
          title={"Import Order"}
          icon={<CgImport color="#0d6efd" />}
          onChange={(id) => {
            orderEditorPost({ id, token });
          }}
        />
      )}

      {!orderEditor.isLoading && (
        <>
          {" "}
          <AppModal
            width={400}
            onClose={(value) => {
              setShowNoticeCommentModal(value);
              setShowNoticeCommetInitalState(!showNoticeCommetInitalState);
            }}
            showModal={showNoticeCommentModal}
            element={
              <NoticeFlagModal
                setShowHide={setShowNoticeCommentModal}
                afterClose={showNoticeCommetInitalState}
                comments={(value) => {
                  setValues({ ...values, comments: value });
                }}
              />
            }
          />
          <AppModal
            width={"50%"}
            onClose={setshowNoticeModal}
            showModal={showNoticeModal}
            element={
              <ViewNoticeFlagModal values={values?.order_notice_list || []} />
            }
          />
        </>
      )}
      <div className=" mb-2" id="order_Error">
        {getError && <Alert message={getError} type="error" showIcon />}
      </div>
      <Collapse
        className="rounded-3 border-0 text-white"
        expandIcon={() => <></>}
        accordion
        defaultActiveKey={"1"}
      >
        <Panel
          className="main-bg-color rounded-3 "
          header="Order Information"
          style={{ fontSize: 18 }}
          key="1"
        >
          {orderContorller?.orderTwoView && orderEvent !== "add" && (
            <div className="mb-2 mt-1 documet-collapse ">
              <Collapse
                className=" border-0 text-dark "
                expandIcon={() => <></>}
                accordion
                defaultActiveKey={"1"}
              >
                <Panel
                  className="rounded-3 text-dark"
                  header="Documents List"
                  style={{ fontSize: 18 }}
                  key="2"
                >
                  <OrderDocuments
                    isLoading={orderDocsListResult.isLoading}
                    mode={editmodeMemo}
                    whileupdate={whileupdateCallback}
                    onview={onviewCallback}
                    deleteFile={deleteFileCallback}
                    onChange={onChangeCallback}
                    initialFiles={initialFilesMemo}
                  />

                  {values?.add_document_list?.length === 0 &&
                    initialFilesMemo?.length === 0 && (
                      <h6 className="text-danger text-center my-1">
                        No Doucuments
                      </h6>
                    )}
                </Panel>
              </Collapse>
            </div>
          )}

          <div className="row align-items-center">
            <div
              className={`col-12 col-lg-${"12"} ${orderContorller?.orderTwoView && orderEvent !== "add" ? "p-0" : null
                } `}
            >
              <Form
                onSubmit={handleSubmit}
                className={`${orderContorller?.orderTwoView && orderEvent !== "add" ? "order-two-view" : null
                  }`}
              >
                {(orderEvent !== "view" || orderEvent !== "afterAddOrder") && <div className="row">
                  <Col className="my-3">
                    <div className="d-flex justify-content-center">
                      {editId && editable && orderEvent !== "clone" && (
                        <>
                          <Popconfirm
                            title="Are you sure you want to clear this form?"
                            description=""
                            onConfirm={() => {
                              orderEditorApi({ id: editId, token });
                            }}
                            okText="Yes"
                            cancelText="No"
                          >
                            <div className="me-md-2 ">
                              <MyButton
                                title={"CLEAR"}
                                variant={"secondary-md"}
                              />
                            </div>
                          </Popconfirm>
                          <MyButton
                            title={(addOrderResult.isLoading || orderUpdateResult.isLoading) ? "Loading..." : "UPDATE ORDER"}
                            variant={"primary-md"}
                          />
                        </>
                      )}
                      {((orderEvent !== "edit" && !editId && !editable) ||
                        orderEvent === "clone") && (
                          <>
                            <Popconfirm
                              title="Are you sure you want to close this form?"
                              description=""
                              onConfirm={() => {
                                handleReset();
                              }}
                              okText="Yes"
                              cancelText="No"
                            >
                              <div className="me-md-2 ">
                                <MyButton
                                  title={"CLEAR"}
                                  variant={"secondary-md"}
                                />
                              </div>
                            </Popconfirm>
                            <MyButton
                              title={(addOrderResult.isLoading || orderUpdateResult.isLoading) ? "Loading..." : "CREATE ORDER"}
                              variant={"primary-md"}
                            />
                          </>
                        )}
                    </div>
                  </Col>
                </div>}
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <div className="row secondary-bg-color w-100 ">
                      {orderEvent === "add" &&
                        user.user_type_id !== 2 &&
                        user.user_type_id !== 3 &&
                        user.user_type_id !== 1 &&
                        user.user_type_id !== 8 && (
                          <Radio.Group
                            onChange={(e) => {
                              const { value } = e.target;
                              setOrderTypeFlag(value);
                              if (value === 1) {
                                orderuserProductTypeList({
                                  id: user.user_id,
                                  token,
                                });
                              } else {
                                resetFun();
                              }
                            }}
                            value={orderTypeFlag}
                          >
                            {(user.user_type_id === 4 ||
                              user.user_type_id === 5 ||
                              user.user_type_id === 8) && (
                                <Radio value={1}>As User</Radio>
                              )}
                            {(user.user_type_id === 4 ||
                              user.user_type_id === 5) && (
                                <Radio value={2}>As Admin</Radio>
                              )}
                          </Radio.Group>
                        )}

                      {inputs.map((input) => (
                        <>
                          {(input.type === "text" || input.type === "date") &&
                            input.rowcolor &&
                            input.visible && (
                              <AppInput
                                isEdit={editId && !editable}
                                key={input.id}
                                {...input}
                                column={
                                  orderContorller?.orderTwoView && orderEvent !== "add"
                                    ? "12"
                                    : input.column
                                }
                                value={values[input.name]}
                                onChange={onChange}
                              />
                            )}
                          {internalOrder.map((item, index) => {
                            if (item.upload_file) {
                              return (
                                <>
                                  {input.type === "file" &&
                                    (!orderContorller?.orderTwoView || orderEvent === "add") && (

                                      <div className="col-12 col-6">
                                        <label
                                          htmlFor="file"
                                          className="heading-5 text-dark"
                                        >
                                          Documents List
                                        </label>
                                        <OrderDocuments
                                          isLoading={orderDocsListResult.isLoading}
                                          mode={editmodeMemo}
                                          whileupdate={whileupdateCallback}
                                          onview={onviewCallback}
                                          deleteFile={deleteFileCallback}
                                          onChange={onChangeCallback}
                                          initialFiles={initialFilesMemo}
                                        />
                                      </div>
                                    )}
                                </>
                              )
                            }

                          })}



                          {input.type === "textArea" &&
                            !input.comments &&
                            input.rowcolor && (
                              <AppTextArea
                                {...input}
                                isEdit={editId && !editable}
                                key={input.id}
                                column={
                                  orderContorller?.orderTwoView && orderEvent !== "add"
                                    ? "12"
                                    : input.column
                                }
                                onChange={onChangeHandler}
                                classStyle="secondary-bg-color border-color w-100 "
                              />
                            )}
                          {input.type === "select" &&
                            input.name === "state_id" &&
                            input.rowcolor && (
                              <AppSelect
                                isEdit={editId && !editable}
                                key={input.id}
                                {...input}
                                column={
                                  orderContorller?.orderTwoView && orderEvent !== "add"
                                    ? "12"
                                    : input.column
                                }
                                classStyle="secondary-bg-color form-control1 w-100 heading-5"
                                //   defaultSet={afterClose}

                                onChange={selectHandler}
                                options={
                                  <>
                                    <option value={""}>Select State</option>
                                    {input.options.map((ele) => {
                                      if (
                                        !getStatesBaseOnCustomerResult.isLoading
                                      ) {
                                        return (
                                          <option
                                            key={ele.state_id}
                                            value={ele.state_id}
                                          >
                                            {ele.state_name}
                                          </option>
                                        );
                                      }
                                      return null;
                                    })}
                                  </>
                                }
                              />
                            )}
                          {input.type === "select" &&
                            input.name === "county_id" &&
                            input.rowcolor && (
                              <AppSelect
                                isEdit={editId && !editable}
                                key={input.id}
                                {...input}
                                column={
                                  orderContorller?.orderTwoView && orderEvent !== "add"
                                    ? "12"
                                    : input.column
                                }
                                //   defaultSet={afterClose}
                                classStyle="secondary-bg-color form-control1 w-100 heading-5"
                                onChange={selectHandler}
                                options={
                                  <>
                                    <option value={""}>Select County</option>
                                    {input.options.map((ele) => {
                                      if (
                                        !getcountyBaseCustomerAndStateResult.isLoading
                                      ) {
                                        return (
                                          <option
                                            key={ele.county_id}
                                            value={ele.county_id}
                                          >
                                            {ele.county_name}
                                          </option>
                                        );
                                      }
                                      // return null;
                                    })}
                                  </>
                                }
                              />
                            )}
                          {input.type === "select" &&
                            input.name === "customer_id" &&
                            input.rowcolor &&
                            orderTypeFlag === 2 && (
                              <AppSelect
                                isEdit={editId && !editable}
                                key={input.id}
                                {...input}
                                column={
                                  orderContorller?.orderTwoView && orderEvent !== "add"
                                    ? "12"
                                    : input.column
                                }
                                //   defaultSet={afterClose}
                                classStyle="secondary-bg-color form-control1 w-100 heading-5"
                                onChange={selectHandler}
                                options={
                                  <>
                                    <option value={""}>Select customer</option>
                                    {input.options.map((ele) => {
                                      if (!customerOptionsResult.isLoading) {
                                        return (
                                          <option key={ele.id} value={ele.id}>
                                            {ele.name}
                                          </option>
                                        );
                                      }
                                      return null;
                                    })}
                                  </>
                                }
                              />
                            )}

                          {input.name === "product_type_id" &&
                            input.rowcolor && (
                              <AppSelect
                                isEdit={editId && !editable}
                                key={input.id}
                                {...input}
                                column={
                                  orderContorller?.orderTwoView && orderEvent !== "add"
                                    ? "12"
                                    : input.column
                                }
                                classStyle="secondary-bg-color form-control1 w-100 heading-5"
                                //   defaultSet={afterClose}
                                onChange={selectHandler}
                                options={
                                  <>
                                    <option value={""}>
                                      Select Product Type
                                    </option>
                                    {input?.options?.map((ele) => {
                                      if (
                                        !branchProductTypeOptionsResult.isLoading
                                      ) {
                                        return (
                                          <option
                                            // data_retail={ele.retail}
                                            // data_wholesale={ele.wholesale}
                                            key={ele.id}
                                            value={ele.id}
                                          >
                                            {ele.name}
                                          </option>
                                        );
                                      }
                                      return null;
                                    })}
                                  </>
                                }
                              />
                            )}
                          {input.name === "transaction_type_id" &&
                            input.rowcolor && (
                              <AppSelect
                                isEdit={editId && !editable}
                                key={input.id}
                                {...input}
                                column={
                                  orderContorller?.orderTwoView && orderEvent !== "add"
                                    ? "12"
                                    : input.column
                                }
                                classStyle="secondary-bg-color form-control1 w-100 heading-5"
                                //   defaultSet={afterClose}
                                onChange={selectHandler}
                                options={
                                  <>
                                    <option value={""}>
                                      Select Transaction Type
                                    </option>
                                    {input?.options?.map((ele) => {
                                      if (
                                        !TransactionTypeListResult.isLoading
                                      ) {
                                        return (
                                          <option
                                            key={ele.id}
                                            value={ele.id}
                                          >
                                            {ele.name}
                                          </option>
                                        );
                                      }
                                      return null;
                                    })}
                                  </>
                                }
                              />
                            )}

                          {input.type === "select" &&
                            input.name === "branch_id" &&
                            input.rowcolor &&
                            orderTypeFlag === 2 && (
                              <AppSelect
                                isEdit={editId && !editable}
                                key={input.id}
                                {...input}
                                column={
                                  orderContorller?.orderTwoView && orderEvent !== "add"
                                    ? "12"
                                    : input.column
                                }
                                //   defaultSet={afterClose}
                                classStyle="secondary-bg-color form-control1 w-100 heading-5"
                                onChange={selectHandler}
                                options={
                                  <>
                                    <option value={""}>Select branch</option>
                                    {input.options.map((ele) => {
                                      if (!branchOptionsResult.isLoading) {
                                        return (
                                          <option key={ele.id} value={ele.id}>
                                            {ele.name}
                                          </option>
                                        );
                                      }
                                      return null;
                                    })}
                                  </>
                                }
                              />
                            )}
                          {input.type === "select" &&
                            input.name === "closer_id" &&
                            input.rowcolor && (
                              <AppSelect
                                isEdit={editId && !editable}
                                key={input.id}
                                {...input}
                                column={
                                  orderContorller?.orderTwoView && orderEvent !== "add"
                                    ? "12"
                                    : input.column
                                }
                                //   defaultSet={afterClose}
                                classStyle="secondary-bg-color form-control1 w-100 heading-5"
                                onChange={selectHandler}
                                options={
                                  <>
                                    <option value={""}>Select Closer</option>
                                    {input.options.map((ele) => {
                                      if (!closerOptionsResult.isLoading) {
                                        return (
                                          <option key={ele.id} value={ele.id}>
                                            {ele.closer_name}
                                          </option>
                                        );
                                      }
                                      return null;
                                    })}
                                  </>
                                }
                              />
                            )}

                          {/* appselect for noticeflag */}
                          {input.type === "select" &&
                            input.name === "notice_id" && (
                              <AppSelect
                                isEdit={editId && !editable}
                                key={input.id}
                                {...input}
                                classStyle="secondary-bg-color form-control1 w-100 heading-5"
                                //   defaultSet={afterClose}
                                column={
                                  orderContorller?.orderTwoView && orderEvent !== "add"
                                    ? "12"
                                    : input.column
                                }
                                onChange={selectHandler}
                                options={
                                  <>
                                    <option value={""}>
                                      Select Notice Flag
                                    </option>
                                    {input.options.map((ele) => {
                                      return (
                                        <option key={ele.id} value={ele.id}>
                                          {ele.name}
                                        </option>
                                      );
                                    })}
                                  </>
                                }
                              />
                            )}
                        </>
                      ))}
                    </div>
                  </div>
                  <div className="col-12 col-lg-6  bg-light-yello-200">
                    {
                      <div className="row">
                        {inputs.map((input) => (
                          <>
                            {(input.type === "text" || input.type === "date") &&
                              values.product_type_id !== 4 &&
                              values.product_type_id !== 9 &&
                              values.product_type_id !== 5 &&
                              !input.rowcolor &&
                              input.visible &&
                              !input.showType && (
                                <AppInput
                                  isEdit={editId && !editable}
                                  defaultSet={""}
                                  key={input.id}
                                  {...input}
                                  column={
                                    orderContorller?.orderTwoView && orderEvent !== "add"
                                      ? "12"
                                      : input.column
                                  }
                                  value={values[input.name]}
                                  onChange={onChange}
                                />
                              )}
                            {(input.name === "wholesale_fee" ||
                              input.name === "retail_fee") && (
                                <AppNumeric
                                  isEdit={editId && !editable}
                                  {...input}
                                  column={
                                    orderContorller?.orderTwoView && orderEvent !== "add"
                                      ? "12"
                                      : input.column
                                  }
                                  maxLength={15}
                                  value={values[input.name]}
                                  thousandSeparator={true}
                                  placeholder={input.placeholder}
                                  prefix={"$"}
                                  decimalScale={6}
                                  onChange={(value) => {
                                    setValues({
                                      ...values,
                                      [input.name]: value,
                                    });
                                  }}
                                />
                              )}
                            {input.type === "select" &&
                              input.name === "is_integration" && (
                                <AppSelect
                                  isEdit={editId && !editable}
                                  key={input.id}
                                  {...input}
                                  //   defaultSet={afterClose}
                                  onChange={selectHandler}
                                  options={
                                    <>
                                      {input.options.map((ele) => {
                                        return (
                                          <option key={ele.id} value={ele.id}>
                                            {ele.name}
                                          </option>
                                        );
                                      })}
                                    </>
                                  }
                                />
                              )}
                            {/* AppSelect for Examiner */}
                            {input.type === "select" &&
                              input.name === "examiner_id" &&
                              values.product_type_id !== 4 &&
                              values.product_type_id !== 9 &&
                              values.product_type_id !== 5 && (
                                <AppSelect
                                  isEdit={editId && !editable}
                                  key={input.id}
                                  {...input}
                                  column={
                                    orderContorller?.orderTwoView && orderEvent !== "add"
                                      ? "12"
                                      : input.column
                                  }
                                  //   defaultSet={afterClose}
                                  onChange={selectHandler}
                                  options={
                                    <>
                                      <option value={""}>
                                        Select Examiner
                                      </option>
                                      {input.options.map((ele) => {
                                        return (
                                          <option key={ele.id} value={ele.id}>
                                            {ele.user_name}
                                          </option>
                                        );
                                      })}
                                    </>
                                  }
                                />
                              )}

                            {/* appselect for openedby */}

                            {/* appselect for order paid status */}
                            {input.type === "select" &&
                              input.name === "payment_status_check" &&
                              // values.product_type_id !== 4 &&
                              // values.product_type_id !== 9 &&
                              // values.product_type_id !== 5 && 
                              (
                                <AppSelect
                                  isEdit={editId && !editable}
                                  key={input.id}
                                  {...input}
                                  column={
                                    orderContorller?.orderTwoView && orderEvent !== "add"
                                      ? "12"
                                      : input.column
                                  }
                                  //   defaultSet={afterClose}
                                  onChange={selectHandler}
                                  options={
                                    <>
                                      {input?.options?.map((ele) => {
                                        return (
                                          <option key={ele.id} value={ele.id}>
                                            {ele.name}
                                          </option>
                                        );
                                      })}
                                    </>
                                  }
                                />
                              )}
                          </>
                        ))}
                      </div>
                    }
                    {/* //calling for hao  */}
                    {isHOAFlag && hoaForComp()}
                  </div>
                </div>
                {!showEditMode &&
                  (read_only_comments ||
                    display_on_cert_comments ||
                    additionNotes) && (
                    <Collapse
                      defaultActiveKey={"1"}
                      accordion
                      className="comments-collase my-1"
                    >
                      <Panel header="Comments" key="1">
                        <Tabs
                          activeKey={commetsView}
                          onChange={(k) => {
                            setCommetsView(k);
                          }}
                        >
                          <TabPane
                            tab="Display On Certificate"
                            key="display-cert"
                          >
                            <div className="p-2">
                              {parse(display_on_cert_comments)}
                            </div>
                          </TabPane>
                          <TabPane tab="Read Only" key="read-only">
                            <div className="p-2">
                              {parse(read_only_comments)}
                            </div>
                          </TabPane>
                          <TabPane
                            tab="Integration Notes"
                            key="additional-notes"
                          >
                            <div className="p-2">{parse(additionNotes)}</div>
                          </TabPane>
                        </Tabs>
                      </Panel>
                    </Collapse>
                  )}


                {(showEditMode && showcomment) &&


                  <div
                    className="row mt-3 gray-table-col border rounded-4 mb-2 p-2 new-order-comments "
                    id={"_id"}
                  >
                    <div
                      className={`d-flex ${"justify-content-between"} align-items-center my-2 contact-forms`}
                    >
                      <span>Comments</span>
                    </div>
                    <div>
                      <div className="col-12 ">
                        <div className="row editor">
                          {internalOrder.map((item, index) => {
                            if (item.display_comments) {
                              return (
                                <div
                                  key={index}
                                  className={`bg-light-yello-200 col-12 col-lg-${((editId && editable) || showEditMode) &&
                                    (!orderContorller?.orderTwoView || orderEvent === "add")
                                    ? "6"
                                    : "12"
                                    } px-2 `}
                                >
                                  {!orderDisplayCertCommentListResult?.isLoading && (
                                    <div className="row align-items-center">
                                      <div className="col-12 col-lg-3">
                                        <h6
                                          className={`${editId && !editable
                                            ? " pb-2 heading-5 bg-light d-inline-block p-2 text-dark border mt-2 rounded-pill px-3"
                                            : ""
                                            } `}
                                        >
                                          Display On Cert
                                        </h6>
                                      </div>
                                      {((editId && editable) ||
                                        showEditMode) && (
                                          <div className="col-12 col-lg-9">
                                            <AppSelect
                                              value={values?.selDisplay}
                                              classStyle="border w-100 p-1 my-2"
                                              onChange={(_, value) => {
                                                setValues({
                                                  ...values,
                                                  selDisplay: value,
                                                });
                                                setDisplay_on_cert_comments(
                                                  display_on_cert_comments + value
                                                );
                                                setGetError(false);
                                              }}
                                              options={
                                                <>
                                                  <option value={""}>
                                                    Select Display On Cert
                                                  </option>
                                                  {
                                                    orderDisplayCertCommentListResult?.data?.data?.map(
                                                      ({
                                                        title,
                                                        comments,
                                                        id,
                                                      }) => {
                                                        return (
                                                          <option
                                                            key={id}
                                                            value={comments}
                                                          >
                                                            {title}
                                                          </option>
                                                        );
                                                      }
                                                    )}
                                                </>
                                              }
                                            />
                                          </div>
                                        )}
                                    </div>
                                  )}
                                  {(editId && editable) || showEditMode ? (
                                    <AppTextEditor
                                      value={display_on_cert_comments}
                                      onChange={(newContent) => {
                                        setGetError(false);

                                        setDisplay_on_cert_comments(newContent);
                                      }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              );
                            }
                            if (item.read_comments) {
                              return (
                                <div
                                  key={index}
                                  className={`bg-light-blue-300 col-12 col-lg-${((editId && editable) || showEditMode) &&
                                    (!orderContorller?.orderTwoView || orderEvent === "add")
                                    ? "6"
                                    : "12 mb-2"
                                    } px-2 `}
                                >
                                  {!orderReadOnlyCommentListResult?.isLoading && (
                                    <div className="row align-items-center">
                                      <div className="col-12 col-lg-3">
                                        <h6
                                          className={`${editId && !editable
                                            ? " pb-2 heading-5 bg-light d-inline-block p-2 text-dark border mt-2 rounded-pill px-3"
                                            : ""
                                            } `}
                                        >
                                          Read Only
                                        </h6>
                                      </div>
                                      {((editId && editable) ||
                                        showEditMode) && (
                                          <div className="col-12 col-lg-9 ">
                                            <AppSelect
                                              value={values?.selReadOnly}
                                              classStyle="border w-100 p-1 my-2"
                                              onChange={(_, value) => {
                                                setValues({
                                                  ...values,
                                                  selReadOnly: value,
                                                });
                                                setRead_only_comments(
                                                  read_only_comments + value
                                                );
                                                setGetError(false);
                                              }}
                                              options={
                                                <>
                                                  <option value={""}>
                                                    Select Read Only
                                                  </option>
                                                  {
                                                    orderReadOnlyCommentListResult?.data?.data.map(
                                                      ({
                                                        title,
                                                        comments,
                                                        id,
                                                      }) => {
                                                        return (
                                                          <option
                                                            key={id}
                                                            value={comments}
                                                          >
                                                            {title}
                                                          </option>
                                                        );
                                                      }
                                                    )}
                                                </>
                                              }
                                            />
                                          </div>
                                        )}
                                    </div>
                                  )}
                                  {(editId && editable) || showEditMode ? (
                                    <AppTextEditor
                                      value={read_only_comments}
                                      onChange={(newContent) => {
                                        setGetError(false);
                                        setRead_only_comments(newContent);
                                      }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              );
                            }
                            return null;
                          })}
                        </div>
                        {internalOrder.map((item, index) => {
                          if (item.additional_notes) {
                            return (
                              <div key={index} className="mt-2">
                                <h6
                                  className={`${editId && !editable
                                    ? " pb-2 heading-5 bg-light d-inline-block p-2 text-dark border mt-2 rounded-pill px-3"
                                    : ""
                                    } `}
                                >
                                  Integration Notes
                                </h6>
                                <div className=" px-2 bg-light-blue-300 mt-2">
                                  {(editId && editable) || showEditMode ? (
                                    <AppTextEditor
                                      value={additionNotes}
                                      onChange={(value) => {
                                        setAdditionNotes(value);
                                        setGetError(false);
                                      }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            );
                          }
                          return null;
                        })}
                      </div>
                    </div>
                  </div>
                }
              </Form>
            </div>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
}
