import { createSlice } from "@reduxjs/toolkit";

const transactionTypeList = createSlice({
  name: "transactionTypeList",
  initialState: {
    transactionTypeList: [],
  },
  reducers: {
    transactionTypeListAction: (state, action) => {
      state.transactionTypeList = action.payload;
    },
  },
});

export const {transactionTypeListAction} = transactionTypeList.actions;

export default transactionTypeList.reducer;
