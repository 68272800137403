import AppTable from "../../../../Components/AppTable/AppTable";
import SearchColumns from "../../../../Components/SearchColumns";
import { FaSearch, FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import { useEffect, useState } from "react";
import { Input, Popconfirm, notification } from "antd";
import { useDeleteProductTypeMutation, useGetProductTypeQuery } from "../../../../Redux/Services/productType";
import { useSelector } from "react-redux";
import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
export const ProductType = ({ childTab }) => {
  const [data, setData] = useState();
  const [search, setSearch] = useState("");
  //set  ascend or descend sorter state
  const [getsortorder, setSortOrder] = useState(false);
  const [getsortfield, setSortField] = useState("");

  const { token } = useSelector((state) => state.auth);
  const productTypeListFetch = useGetProductTypeQuery(token);
  const { typeModule } = useSelector((state) => state.flagRoles);
  const sort = (sortOrder, sortfield) => {
    // console.log(sortOrder, sortfield);
    if (sortOrder) {
      setSortOrder(sortOrder);
      setSortField(sortfield);
    } else {
      setSortOrder(false);
    }
  };

	const deleteProductType = useDeleteProductTypeMutation();
	const[deleteProductTypeData]= deleteProductType;
  
  
	 //delete taxcad data
	 const handleDelete = (key) => {
	  const newData = data.filter((item) => item.key !== key);
	   deleteProductTypeData({
		id: key,
		token: token,
	  });
	  setData(newData);
	};

  const columns = [
    {
      dataIndex: "id",
      key: "id",
      sorter: true,
      render: (value) => <span data-idfe={value}>{""}</span>,
      width: 1,
    },
    {
      title: (
        <span>
          Product Type Name{" "}
          {getsortfield === "name" && !getsortorder ? (
            <FaSortAmountUp />
          ) : getsortorder === "ascend" && getsortfield === "name" ? (
               <span className="sort-svg">
            <FaSortAmountUp className="text-info" /></span>
            ) : getsortorder === "descend" && getsortfield === "name" ? (
                 <span className="sort-svg">
            <FaSortAmountDown className="text-info" /></span>
          ) : (
            <FaSortAmountUp />
          )}
        </span>
      ),
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      editable: true,
      ...SearchColumns("name", "Product Type"),
    },
    
		{
			title: <span>Actions</span>,
			width:  50,
			className: `${typeModule[1].action ? "" : "d-none"}`,
			align: "center",
			dataIndex: "operation",
			key: "operation",
			render: (_, record) => {
				// const editable = isEditing(record);
				 return  (
				// 	<span>
				// 		<Typography.Link
				// 			onClick={() => save(record.id)}
				// 			style={{
				// 				marginRight: 8,
				// 			}}
				// 		>
				// 			<button className="btn btn-primary btn-sm edit-buttons">
				// 				{" "}
				// 				Save
				// 			</button>
				// 		</Typography.Link>
				// 		<Typography.Link>
				// 			<button
				// 				onClick={cancel}
				// 				className="btn btn-sm btn-danger edit-buttons"
				// 			>
				// 				Cancel
				// 			</button>
				// 		</Typography.Link>
				// 	</span>
				// ) : (
				// 	<>
				// 		<span disabled={editingKey !== ""} onClick={() => edit(record)}>
				// 			<EditOutlined
				// 				style={{ cursor: "pointer" }}
				// 				onClick={editHandler}
				// 				size={50}
				// 			/>
				// 			{ " "}
				// 		</span>
						<Popconfirm
              title="Are you sure you want to delete？"
              onConfirm={() => {
                 handleDelete(record.id);
              }}
              getPopupContainer={(trigger) => trigger.parentElement}
              placement="topLeft"
              icon={
                <QuestionCircleOutlined
                  style={{
                    color: "red",
                    cursor: "pointer",
                  }}
                />
              }
              okText="Yes"
              cancelText="No"
            >
              <span className="gap-2">
                <DeleteOutlined
                  className="text-danger"
                  style={{ cursor: "pointer" }}
                />
              </span>
            </Popconfirm>
					// </>
				);
			},
		},
  ];

  //useEfffect for creating for when productTypeList is loaded
  useEffect(() => {
    if (productTypeListFetch.isSuccess) {
      setData(productTypeListFetch.data?.data);
    }
  }, [productTypeListFetch, search]);

  	   // //useEffect for delete
		   useEffect(() => {
        if (deleteProductType[1].isSuccess) {
          notification.open({
          type: "success",
          style: { color: "green", marginTop: "50px" },
          description: deleteProductType[1].data?.message,
          });
        }
        if (deleteProductType[1].error) {
          /// erros details
          notification.open({
          type: "error",
          style: { color: "red", marginTop: "50px" },
          description:
            deleteProductType[1].error.data.message ||
            deleteProductType[1].error.error ||
            deleteProductType[1].error.data?.msg.sqlMessage,
          });
        }
        }, [deleteProductType[1].isLoading]);

  useEffect(() => {
    setSearch("");
    if (childTab === "Product Type") {
      productTypeListFetch.refetch();
    }
  }, [childTab]);

  return (
    <>
      <div className="d-flex align-items-center float-end w-25 mb-1 ">
        {/* changing input values globaly  */}
        <div className=" me-1 w-100 mt-2">
          <Input
            placeholder="Search here"
            className="global-search "
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            prefix={<FaSearch color="lightgray" />}
          />
        </div>
      </div>
      <div style={{ clear: "both" }}></div>

      <AppTable
        sort={sort}
        loading={productTypeListFetch.isLoading || deleteProductType[1].isLoading} 
        search={search}
        dataSource={data}
        columns={columns}
      />
    </>
  );
};
