import React, { useState } from "react";
import { Tabs, Radio } from "antd";
import { useSelector } from "react-redux";
import ManualEntry from "./ManualEntry/ManualEntry";
import PropertyBrowser from "./PropertyBrowser/PropertyBrowser";
import { useEffect } from "react";


const { TabPane } = Tabs;
export const NewParcel = ({ setParcelAddFlag, tabKey }) => {
  const [key, setKey] = useState("Property Browser");
  const { parcelTabs } = useSelector((state) => state.flagRoles);
  //create function for settings tabs
  function matchTab(value) {
    if (parcelTabs.indexOf(value) === -1) {
      return false;
    } else {
      return true;
    }
  }
  useEffect(() => {
    setKey(parcelTabs[0]);
  }, [parcelTabs]);

  return (
    <>
      <div className="p-2  border rounded-3 bg-white d-none">
        <Radio.Group onChange={(e) => setKey(e.target.value)} value={key}>
          {/* tabs showing base on users roles  */}
          {parcelTabs &&
            parcelTabs.map((ele) => {
              return (
                <Radio key={ele} value={ele} defaultChecked>
                  {ele}
                </Radio>
              );
            })}
        </Radio.Group>
        {/* {key === "Property Browser" && (
        <PropertyBrowser setKey={tabKey} refresh={key} />
      )}
      {key === "Manual Entry" && (
        <ManualEntry
          setKey={tabKey}
          refresh={key}
          setParcelAddFlag={setParcelAddFlag}
        />
      )} */}
      </div>
      <Tabs
        activeKey={key}
        defaultActiveKey="1"
        onChange={(k) => {
          setKey(k);
        }}
      >
        {matchTab("Property Browser") && (
          <TabPane tab="Property Browser" key="Property Browser">
            <PropertyBrowser setKey={tabKey} refresh={key} />
          </TabPane>
        )}
        {/* {matchTab("Property Browser") && (
          <TabPane tab="Property Browser2" key="Property Browser2">
            <PropertyBrowser2 setKey={tabKey} refresh={key} />
          </TabPane>
        )} */}
        {matchTab("Manual Entry") && (
          <TabPane tab="Manual Entry" key="Manual Entry">
            <ManualEntry
              setKey={tabKey}
              refresh={key}
              
              setParcelAddFlag={setParcelAddFlag}
            />
          </TabPane>
        )}
      </Tabs>
    </>
  );
};

export default NewParcel;
