import { createSlice } from "@reduxjs/toolkit";

const stateList = createSlice({
  name: "stateList",
  initialState: {
    stateList: [],
  },
  reducers: {
    stateListAction: (state, action) => {
      state.stateList = action.payload;
    },
  },
});

export const {stateListAction} = stateList.actions;

export default stateList.reducer;
