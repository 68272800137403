import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json")
export const notification = createApi({
    reducerPath: "notification",
    tagTypes: ["notification"],
    baseQuery: fetchBaseQuery({ baseUrl: config.url }),
    endpoints: (builder) => ({
        notificationList: builder.query({
            query: (token) => ({
                url: `/maintenance-notification-list`,
                method: "GET",
                mode: "cors",
                headers: { Authorization: `Bearer ${token}` },
            }),
            providesTags: ["notification"],
        }),
        activeMaintance: builder.query({
            query: () => ({
                url: `/active-maintenance-notification-list`,
                method: "GET",
                mode: "cors",
            }),
        }),
        editNotification: builder.query({
            query: (data) => ({
                url: `/edit-maintenance-notification/${data.id}`,
                method: "GET",
                mode: "cors",
                headers: { Authorization: `Bearer ${data.token}` },
            }),
            invalidatesTags: ["notification"],
        }),
        updateNotification: builder.mutation({
            query: (data) => ({
                url: `/update-maintenance-notification/${data.id}`,
                method: "POST",
                mode: "cors",
                body: data.data,
                headers: { Authorization: `Bearer ${data.token}` },
            }),
            invalidatesTags: ["notification"],
        }),
        updateNotificationStatus: builder.mutation({
            query: (data) => ({
                url: `/update-status-maintenance-notification/${data.id}`,
                method: "POST",
                mode: "cors",
                body: data.data,
                headers: { Authorization: `Bearer ${data.token}` },
            }),
            invalidatesTags: ["notification"],
        }),
    }),
});

export const {
    useNotificationListQuery,
    useEditNotificationQuery,
    useUpdateNotificationMutation,
    useUpdateNotificationStatusMutation,
    useActiveMaintanceQuery
} = notification;
