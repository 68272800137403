import { createSlice } from "@reduxjs/toolkit";

const parcelReducer = createSlice({
    name: "parcelReducer",
    initialState: {
        parceData: {},
        parcelList: [],
        parcelViwDoc: null,
        parcelId: null
    },
    reducers: {
        addParcelData: (state, action) => {
            state.parceData = action.payload;
        },
        resertPacrelData: (state) => {
            state.parceData = {}
        },
        parcelListHandler: (state, action) => {
            state.parcelList = action.payload;
        },
        parcelViewDocHandler: (state, action) => {
            state.parcelViwDoc = action.payload;
        },
        addParcelIdHandler: (state, action) => {
            state.parcelId = action.payload;
        },


    },
});

export const { addParcelData, resertPacrelData, parcelListHandler, parcelViewDocHandler, addParcelIdHandler } = parcelReducer.actions;

export default parcelReducer.reducer;
