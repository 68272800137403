import React, { useEffect, useState } from 'react'
import { SiJsonwebtokens } from "react-icons/si";
import AppModal from '../Components/AppModal/AppModal';
import { useGetOrderDeilverJsonMutation } from '../Redux/Services/Parcels';
import Loading from '../Components/Loading';
import { useSelector } from 'react-redux';
import OrderBtns from '../Components/OrderBtns';
import { notification } from 'antd';
import JSONPretty from 'react-json-pretty';
function useSeeJson() {
    const [open, setOpen] = useState(false)
    //update automation status
    const [getDeliverJson, { isLoading, data }] =
        useGetOrderDeilverJsonMutation();
    const { token } = useSelector((state) => state.auth);
    function GetJsonComp({ order_id }) {
        function OpenHandler() {
            try {
                getDeliverJson({ token, order_id }).then((output) => {
                    if (output?.data?.data) {
                        setOpen(true)
                    }
                    if(output?.error){
                        const {error} = output
                        setOpen(false)
                        notification.open({
                            type: "error",
                            style: { color: "red", marginTop: "50px" },
                            description: error?.data?.message || "not found",
                        });
                    }
                })
            } catch (error) {
                setOpen(false)
            }

        }
        function onClose() {
            setOpen(false)
        }



        if (open) {
            return <AppModal width={"80%"} showModal={open} onClose={onClose} element={<JSONPretty data={data?.data} theme={{
                main: 'line-height:1.3;color:#000;background:#fff;overflow:auto;',
                error: 'line-height:1.3;color:#000;background:#fff;overflow:auto;',
                key: 'color:#000;',
                string: 'color:#000;',
                value: 'color:#000;',
                boolean: 'color:#000;',
            }} themeClassName="custom-json-pretty"></JSONPretty>} />
        }

        return (
            <>
                <Loading isLoading={isLoading}>
                    <OrderBtns title="View Data">
                        <div
                            onClick={OpenHandler}
                            className={`border add_icon  border-danger`}
                        >
                            <SiJsonwebtokens color='red' />
                        </div>

                    </OrderBtns>
                </Loading>
            </>

        )
    }

    return { GetJsonComp }
}

export default useSeeJson