import React from "react";
import { Switch } from "antd";
import "./AppSwitch.css";

import { BsExclamationCircle } from "react-icons/bs";
import { AiFillExclamationCircle } from "react-icons/ai";

export default function AppSwitch(props) {
  const {
    placeholder,
    subhead,
    column,
    label,
    name,
    errorMessage,
    id,
    _id,
    checked,
    hide,
    labelclass,
    margin,
    tooltip = false,
    disabled,
    ...inputProps
  } = props;
  const onChange = (e) => {
    // passing values with properties
    props.onChange(name, e);
  };
  return (
    <div className={`mt-${margin} col-12 col-md-${column} app-swicth d-flex align-items-center`}>
      {subhead && <h6 className="mt-3">{subhead}</h6>}

      <label className={`${labelclass || "ms-1 heading-5 text-dark "}`}>
        {!label ? <>&nbsp;</> : <> {label}&nbsp;</>}
      </label>
      <Switch
        disabled={disabled}
        id={_id}
        size="small"
        checked={checked}
        onChange={onChange}
      />
      {tooltip && (
        <div className="position-relative d-inline-block">
          <div className="hover-text">
            <AiFillExclamationCircle color="gray" />
            <div className="text-hover">{tooltip}</div>
          </div>
        </div>
      )}
      <span className="input-error">
        <BsExclamationCircle className="alertIcon" />
        {errorMessage}
      </span>
    </div>
  );
}
