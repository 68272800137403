import { Col, Row, Alert, notification } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import AppButton from "../../../Components/AppButton/AppButton";
import AppInput from "../../../Components/AppInput/AppInput";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import AppSelect from "../../../Components/AppSelect/AppSelect";
import AppSpinner from "../../../Components/AppSpinner/AppSpinner";
import AppNumeric from "../../../Components/AppNumeric/AppNumeric";
import { useCustomerListQuery } from "../../../Redux/Services/Customers";
import {
  useAddBulkPaymentsMutation,
  useEditPaymentInvoiceQuery,
  usePaymentModesQuery,
  useUpdatePaymentInvoiceMutation,
} from "../../../Redux/Services/ReportsListApi";
import AppTextArea from "../../../Components/AppTextArea/AppText";
import moment from "moment-business-days";
import dayjs from "dayjs";
export default function PaymentReportModal({
  afterClose,
  setShowHide,
  editId,
  RefetchData,
  refetchLogs,
}) {
  const today = moment().format("MM-DD-YYYY")
  // const today = new Date().toISOString().split('T')[0]
  const [values, setValues] = useState({
    mode: "",
    amount: "",
    payment_status_check: 1,
    paid_date: today,
    gf_number: "",
    reference_number: "",
    customer_id: "",
  });

  //status
  const [getError, setGetError] = useState(null);
  const [StatusData, setStatusData] = useState([
    { id: 1, payment_status_check: "Paid" },
    { id: 0, payment_status_check: "Not Paid" },
  ]);
  const [transferMode, setTransferMode] = useState([]);

  const [, setGetSuccess] = useState(null);
  //setfile Error
  const [, setFileError] = useState(null);
  // calling redux store
  const { token, user } = useSelector((state) => state.auth);
  const [customertypeList, setCustomerTypeList] = useState([
    { id: "", name: "" },
  ]);

  //redux
  const Customer = useCustomerListQuery(token);
  useEffect(() => {
    if (Customer.isSuccess) {
      setCustomerTypeList(Customer.data?.data);
    }
  }, [Customer.isFetching]);


  const paymentModeApi = usePaymentModesQuery(token);
  useEffect(() => {
    if (paymentModeApi?.isSuccess) {
      setTransferMode(paymentModeApi?.data?.data);
    }
  }, [paymentModeApi.isFetching]);

  //clear error function
  function clearStatus() {
    setGetError(null);
    setGetSuccess(null);
  }
  // disable date

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };
  //SETdEFAULT
  useEffect(() => {
    if (editId) {
      setValues({
        reference_number: "",
        mode: "",
        customer_id: "",
        payment_status_check: 1,
        paid_date: "",
        gf_number: "",
        amount: "",
      });
      editPaymentResult.refetch();
    }
    setFileError(null);
  }, [afterClose]);

  const handleReset = (e) => {
    setValues({
      ...values,
      paid_date: "",
      payment_status_check: "",
      gf_number: "",
      mode: "",
      amount: "",
      reference_number: "",
      customer_id: "",
    });
    if (editId) {
      editPaymentResult.refetch();
    }
    clearStatus();
  };

  //calling rtk query hook for update
  const addBulkPaymentInvoiceApi = useAddBulkPaymentsMutation();
  const [addBulkPaymentInvoice] = addBulkPaymentInvoiceApi;

  //update notification
  useEffect(() => {
    if (addBulkPaymentInvoiceApi[1].isSuccess) {
      setShowHide(false);
      clearStatus();
      const refethFunction = async () => {
        try {
          await RefetchData();
          await refetchLogs();
        } catch (error) {
          console.log(error);
        }
      };
      ///after submit details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: addBulkPaymentInvoiceApi[1].data?.message,
      });
      refethFunction();
    }
    if (addBulkPaymentInvoiceApi[1].error) {
      var errorData = [];
      for (const prop in addBulkPaymentInvoiceApi[1].error.data?.error) {
        errorData.push(addBulkPaymentInvoiceApi[1].error.data?.error[prop]);
      }
      setGetError(
        errorData[0] ||
        addBulkPaymentInvoiceApi[1].error.data.message ||
        addBulkPaymentInvoiceApi[1].error.error ||
        addBulkPaymentInvoiceApi[1].error.data?.msg.sqlMessage
      );
    }
  }, [addBulkPaymentInvoiceApi[1].isLoading]);

  //calling rtk query hook for update
  const updatePaymentInvoiceApi = useUpdatePaymentInvoiceMutation();
  const [updatePaymentInvoice] = updatePaymentInvoiceApi;
  //calling rtk query hook for geting Notification
  const editPaymentResult = useEditPaymentInvoiceQuery(
    { id: editId, token: token },
    { skip: !editId }
  );

  console.log("")
  //useEffect for editNotification
  useEffect(() => {
    if (editPaymentResult.isSuccess) {
      const {  payment_status_check,reference_number } = editPaymentResult?.data?.data[0]
      setValues({ ...editPaymentResult?.data?.data[0], payment_status_check: 1, ...(!payment_status_check ? { paid_date: today } : {}),...(reference_number==="null"?{reference_number:""}:{}) });
      clearStatus();
    }
    if (editPaymentResult.isError) {
      var errorData = [];
      for (const prop in editPaymentResult[1].error.data?.error) {
        errorData.push(editPaymentResult[1].error.data?.error[prop]);
      }
      setGetError(
        errorData[0] ||
        editPaymentResult.error.data.message ||
        editPaymentResult.error.data ||
        editPaymentResult.error.error ||
        editPaymentResult.error.data?.msg.sqlMessage
      );
    }
  }, [editPaymentResult.isFetching]);

  //update notification
  useEffect(() => {
    if (updatePaymentInvoiceApi[1].isSuccess) {
      setShowHide(false);
      clearStatus();
       RefetchData()
      ///after submit details
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: updatePaymentInvoiceApi[1].data?.message,
      });
      // refethFunction();
    }

    if (updatePaymentInvoiceApi[1].error) {
      var errorData = [];
      for (const prop in updatePaymentInvoiceApi[1].error.data?.error) {
        errorData.push(updatePaymentInvoiceApi[1].error.data?.error[prop]);
      }
      setGetError(
        errorData[0] ||
        updatePaymentInvoiceApi[1].error.data.message ||
        updatePaymentInvoiceApi[1].error.error ||
        updatePaymentInvoiceApi[1].error.data?.msg.sqlMessage
      );
    }
  }, [updatePaymentInvoiceApi[1].isLoading]);

  const inputs = [
    {
      id: 65,
      name: "customer_id",
      type: "select",
      value: values.customer_id || "",
      placeholder: "Select Customer Type",
      errorMessage: "Please select customer!",
      label: "Customer",
      options: customertypeList,
      column: 3,
      required: true,
      disabled: editId,
    },
    {
      id: 4,
      name: "gf_number",
      type: editId ? "text" : "textArea",
      value: values?.gf_number?.toString() || "",
      placeholder: "Enter file number",
      errorMessage: "Please file number!",
      label: "GF Number",
      required: true,
      // maxLength: 250,
      column: "3",
      disabled: editId ? true : false,
    },

    {
      id: 1,
      name: "mode",
      type: "select",
      value: values.mode || "",
      column: "3",
      placeholder: "Select payment mode ",
      errorMessage: "Please payment mode!",
      disabled: false,
      label: "Payment Mode",
      options: transferMode,
      required: false,
    },

    {
      id: 19,
      name: "amount",
      label: "Amount",
      //   type: "text",
      value: values?.amount,
      placeholder: "Enter Amount",
      column: "3",
      errorMessage: "Please enter amount!",
      required: true,
      disabled: editId,
    },

    {
      id: 78,
      name: "paid_date",
      type: "date",
      usaFormat: true,
      value: (editId && values.paid_date === null ? today : values?.paid_date) || today,
      errorMessage: "Please select Date!",
      label: "Paid Date",
      column: "3",
      required: true,
      disabledDate: disabledDate,
    },
    {
      id: 43,
      name: "payment_status_check",
      type: "select",
      value: values.payment_status_check,
      column: "3",
      placeholder: "Select status ",
      errorMessage: "Please select status!",
      disabled: false,
      label: "Status",
      options: StatusData,
      required: true,
    },
    {
      id: 13,
      name: "reference_number",
      type: "text",
      values: values.reference_number || "",
      placeholder: "Enter reference number",
      errorMessage: "Please enter reference num!",
      label: "Reference Number",
      required: false,
      //  maxLength: 25,
      column: "3",
    },
  ];

  const onChange = async (e, date, dateFormat) => {
    if (dateFormat) {
      setValues({
        ...values,
        [e]: date,
      });
    } else {
      const { name, value } = e.target;
      setValues({
        ...values,
        [name]: value,
      });
    }
    //clear Status
    clearStatus();
  };

  //colorchange
  const selectHandler = (property, value) => {
    clearStatus();

    //calling rtk  query for geting county list for options
    if (property === "payment_status_check") {
      if (value) {
        setValues({
          ...values,
          [property]: value,
        });
      } else {
        setValues({
          ...values,
          [property]: value,
        });
      }
    }
    setValues({ ...values, [property]: value });
  };

  //adding new color Notification with name and color
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!values.paid_date) {
      setGetError("Please enter date!");
    } else {
      if (!editId) {
        if (values?.gf_number) {
          //  values["gf_number"]=values?.gf_number?.split(',');
        }
        addBulkPaymentInvoice({
          data: {
            customer_id: values?.customer_id,
            payment_status_check: values?.payment_status_check,
            // amount: values?.amount,
            reference_number: values?.reference_number,
            paid_date: values?.paid_date,
            payment_mode_id: values?.mode,
            gf_number: values?.gf_number?.split(","),
          },
          token: token,
        });
      }
      if (editId) {
        updatePaymentInvoice({
          data: {
            customer_id: values?.customer_id,
            gf_number: values.gf_number,
            payment_status_check: values?.payment_status_check,
            amount: values?.amount,
            reference_number: values?.reference_number,
            paid_date: values?.paid_date,
            payment_mode_id: values?.mode,
          },
          id: editId,
          token: token,
        });
      }
    }
  };
  //useEffect for editCustomer refecth
  useEffect(() => {
    if (editId) {
      editPaymentResult.refetch();
    }
    clearStatus();
  }, [afterClose]);
  return (
    <>
      <div className="my-4 border-bottom border-2 ">
        <h5>{editId ? `Update Payment` : `Update Payment's`}</h5>
      </div>
      <div className="my-3">
        {getError && (
          <Alert type="error" message={getError} closable showIcon />
        )}
      </div>
      <Form onSubmit={handleSubmit}>
        {(editPaymentResult.isFetching ||
          updatePaymentInvoiceApi[1].isLoading ||
          addBulkPaymentInvoiceApi[1].isLoading) && <AppSpinner />}
        <Row className="w-100  ">
          <div className="col-12 col-lg-12">
            <div className="row">
              {inputs.map((input) => (
                <>
                  {input.type === "select" && input.name === "customer_id" && (
                    //based on user field flags
                    <AppSelect
                      key={input.id}
                      {...input}
                      onChange={selectHandler}
                      defaultSet={afterClose}
                      options={
                        <>
                          <option value={""}> Select Customer</option>
                          {input?.options?.map((ele) => {
                            return (
                              <option key={ele.id} value={ele.id}>
                                {ele.name}
                              </option>
                            );
                          })}
                        </>
                      }
                    />
                  )}
                  {input.type === "select" && input.name === "mode" && (
                    <AppSelect
                      defaultSet={afterClose}
                      key={input.id}
                      {...input}
                      onChange={selectHandler}
                      options={
                        <>
                          <option value={0}>Select Payment Mode</option>
                          {input?.options?.map((ele) => {
                            return (
                              <option key={ele.id} value={ele.id} selected>
                                {ele.name}
                              </option>
                            );
                          })}
                        </>
                      }
                    />
                  )}
                  {input.type === "select" &&
                    input.name === "payment_status_check" && (
                      <AppSelect
                        defaultSet={afterClose}
                        key={input.id}
                        {...input}
                        onChange={selectHandler}
                        options={
                          <>
                            {/* <option value={""}>Select Status </option> */}
                            {input?.options?.map((ele) => {
                              return (
                                <option key={ele.id} value={ele?.id}>
                                  {ele?.payment_status_check}
                                </option>
                              );
                            })}
                          </>
                        }
                      />
                    )}
                  {(input.type === "text" ||
                    input.type === "date" ||
                    input.type === "number") && (
                      <AppInput
                        key={input.id}
                        {...input}
                        defaultSet={afterClose}
                        value={values[input.name]}
                        onChange={onChange}
                      />
                    )}

                  {input.name === "amount" && editId && (
                    <AppNumeric
                      {...input}
                      defaultSet={afterClose}
                      value={values[input.name]}
                      thousandSeparator={true}
                      placeholder={input.placeholder}
                      prefix={"$"}
                      decimalScale={6}
                      maxLength={15}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          [input.name]: value,
                        });
                      }}
                    />
                  )}

                  {input.type === "textArea" && (
                    <AppTextArea
                      {...input}
                      defaultSet={afterClose}
                      value={values[input.name]}
                      onChange={onChange}
                      classStyle="secondary-bg-color border-color w-100 "
                    />
                  )}
                </>
              ))}
            </div>
          </div>
        </Row>

        <Row>
          <Col className="my-3">
            <div className="d-flex justify-content-center">
              <div className="me-md-2 ">
                <AppButton
                  onClick={handleReset}
                  title={"CLEAR"}
                  variant={"secondary-md"}
                />
              </div>

              <AppButton title={"SAVE"} variant={"primary-md"} />
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
}