import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json");
export const orders = createApi({
  reducerPath: "orders",
  tagTypes: ["orders"],
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  endpoints: (builder) => ({
    orderuserProductTypeList: builder.mutation({
      query: (data) => ({
        url: `/order-user-product-type/${data.id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      providesTags: ["orders"],
    }),
    getDataByStatus: builder.mutation({
      query: ({ token, data }) => ({
        url: '/order-status',
        method: 'POST',
        mode: "cors",
        body: data,
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    ordercustomerbranchList: builder.mutation({
      query: (data) => ({
        url: `/order-customer-branch-list/${data.id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      providesTags: ["orders"],
    }),
    ordercustomerList: builder.mutation({
      query: (data) => ({
        url: `/order-customer-list/${data.id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      providesTags: ["orders"],
    }),
    ordercloserList: builder.mutation({
      query: (data) => ({
        url: `/order-closer-list/${data.id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      providesTags: ["orders"],
    }),
    orderReadOnlyCommentList: builder.query({
      query: (token) => ({
        url: `/order-read-only-comments`,
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    orderDisplayCertCommentList: builder.query({
      query: (token) => ({
        url: `/order-display-cert-comments`,
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    addOrder: builder.mutation({
      query: (data) => ({
        url: `/add-order`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["orders"],
    }),
    examinerOrder: builder.query({
      query: (token) => ({
        url: `/order-examiner-list`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    orderEditor: builder.query({
      query: (data) => ({
        url: `/edit-order/${data.id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    orderEditorPost: builder.mutation({
      query: (data) => ({
        url: `/edit-order/${data.id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    ordersList: builder.mutation({
      query: ({ token, allOrder, is_intergration = 0, data }) => ({
        url: allOrder ? `full-order-lists` : `/order-list`,
        // url: allOrder ? `full-order-lists?is_intergration=${is_intergration}` : `/order-list?is_intergration=${is_intergration}`,
        method: "GET",
        // method: allOrder ? "GET" : "POST",
        mode: "cors",
        // ...(!allOrder ? { body: data } : {}),
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    orderUpdate: builder.mutation({
      query: (data) => ({
        url: `/update-order/${data.id}`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    orderStatusUpdate: builder.mutation({
      query: (data) => ({
        url: `/update-order-status/${data.id}`,
        method: "POST",
        mode: "cors",
        body: { status_id: data.status_id },
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    orderSearch: builder.mutation({
      query: (data) => ({
        url: `/search-order-file-number`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    orderResubmit: builder.mutation({
      query: (data) => ({
        url: `/resubmit-order/${data.id}`,
        method: "POST",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    orderExaminerUpdate: builder.mutation({
      query: (data) => ({
        url: `/update-examiner`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    orderHoaExaminerUpdate: builder.mutation({
      query: (data) => ({
        url: `/update-hoa-examiner`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    orderProductListPricing: builder.mutation({
      query: (data) => ({
        url: `/order-branch-wise-product-pricing`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    orderProductlistA: builder.mutation({
      query: (data) => ({
        url: `/order-user-product-pricing-list`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    deleteOrder: builder.mutation({
      query: (data) => ({
        url: `/soft-delete-order`,
        method: "POST",
        mode: "cors",
        body: { order_id: data?.order_id },
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    stopBackendAutomation: builder.mutation({
      query: (data) => ({
        url: `/stop-auotmation/${data?.order_id}`,
        method: "POST",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    //delete multiple orders
    deleteMultipleOrder: builder.mutation({
      query: (data) => ({
        url: `/delete-multiple-order`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    auditLogsList: builder.mutation({
      query: (data) => ({
        url: `/orders-audit-log-list/${data.id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    // order docs list
    orderDocsList: builder.mutation({
      query: ({ order_id, token }) => order_id && ({
        url: `/order-document-list/${order_id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    // get order Doc List
    Conflicts: builder.mutation({
      query: (data) => ({
        url: `/conflicts-error-list`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    // get order Doc List
    afterUpdateConflicts: builder.mutation({
      query: (data) => ({
        url: `/conflicts-error-list`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    // conflicts
    validateConflicts: builder.mutation({
      query: (data) => ({
        url: `/validation-parcel-order`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    // auto update status
    autoConflictsStatusUpdate: builder.mutation({
      query: (data) => ({
        url: data?.isManual ? "/manual-auto-update-status" : `/auto-update-status`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    // conflicts
    reviewConflicts: builder.mutation({
      query: (data) => ({
        url: `/update-conflicts`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    // orders
    orderCertificate: builder.mutation({
      query: (data) => ({
        url: `/certificate-${data?.type}/${data.id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    // update calculations
    updateCalculations: builder.mutation({
      query: (data) => ({
        url: `/update-calculation-info/${data.order_id}`,
        mode: "cors",
        method: "POST",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    //update supporting documents
    updateCertifciateDoc: builder.mutation({
      query: ({ id, token, data }) => ({
        url: `/update-order-supporting-documents/${id}`,
        mode: "cors",
        method: "POST",
        body: data,
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    //update supporting documents 2
    uploadCertificate: builder.mutation({
      query: ({ order_id, token, data }) => ({
        url: `/add-update-certificate/${order_id}`,
        mode: "cors",
        method: "POST",
        body: data,
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    // orders
    orderClone: builder.mutation({
      query: ({ id, token, data }) => ({
        url: `/order-clone/${id}`,
        mode: "cors",
        method: "POST",
        body: data,
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    //parcelwise orderlist
    parcelHoaOrderList: builder.mutation({
      query: ({ token, hoaFlag, is_intergration = 0 }) => ({
        url: `/parcelwise-hoa-list/${Number(hoaFlag == 0 ? 1 : 0)}`,
        // url: `/parcelwise-hoa-list/${Number(hoaFlag == 0 ? 1 : 0)}?is_intergration=${is_intergration}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    //orderlis History
    orderHistoryListLogs: builder.mutation({
      query: ({ token, id }) => ({
        url: `/orders-audit-log-list/${id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    //send email certificate
    sendEmailCertificate: builder.mutation({
      query: ({ token, order_id, data }) => ({
        url: `/email-tax-certificate/${order_id}`,
        mode: "cors",
        method: "POST",
        body: data,
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),

    //get email of closer
    getCloserEmail: builder.mutation({
      query: ({ token, order_id }) => ({
        url: `/order-closer-email/${order_id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    //order download certificate logs insert api
    certificateDownloadLogs: builder.mutation({
      query: ({ token, order_id }) => ({
        url: `/certificate-download/${order_id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    //order list based on status
    orderListByStatus: builder.mutation({
      query: ({ token, order_id }) => ({
        url: `/order-closer-email/${order_id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    ordersDueDateStatus: builder.mutation({
      query: (data) => ({
        url: `/statuswise-order-list`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    //get the latest flags of Preliminary
    checkPrelimanryStatus: builder.mutation({
      query: ({ order_id, token }) => ({
        url: `/order-pdf-document-name/${order_id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    //download the latest certificate
    downloadCertificate: builder.mutation({
      query: ({ order_id, token }) => ({
        url: `/order-latest-certificate/${order_id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    //deliver the latest certificate
    deliverLatestCertificate: builder.mutation({
      query: ({ order_id, token, type = "softpro" }) => ({
        url: `/${type}-transaction/${order_id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    //deliver the latest certificate
    showThirPartyXmlData: builder.mutation({
      query: ({ order_id, token }) => ({
        url: `/order-third-party-request/${order_id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    //Get Orders json data
    getOrderJsonData: builder.mutation({
      query: ({ order_id, token }) => ({
        url: `/order-customer-json/${order_id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    //Request qulia orde
    OualiaRequest: builder.mutation({
      query: ({ token }) => ({
        // url: `/qualia/initiate-automation`,
        url: `/qualia/pending-orders`,
        mode: "cors",
        // method: "POST",
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    OualiaRequestUpdate: builder.mutation({
      query: ({ values, token }) => ({
        // url: `/qualia/update-certificate`,
        url: `/qualia/submit-order-document/${values?.order_id}`,
        mode: "cors",
        // method: "POST",
        method:"GET",
        // body: values,
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    //Show Notice flag modal
    getNoiticeNotes: builder.mutation({
      query: ({ order_id, token }) => ({
        url: `/order-notice-lists/${order_id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    //Show Date Time qualiaOrder 
    QualiaOrderStatus: builder.mutation({
      query: ({ token }) => ({
        // url: `/qualia-order-count`,
        url: `/pending-orders`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    //Get Orders json data
    sendOrderToFastrack: builder.mutation({
      query: ({ order_id, token }) => ({
        url: `/add-order-fastrack/${order_id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    //Genrate backed certificate
    backendCertificateGenerate: builder.mutation({
      query: ({ token, data }) => ({
        url: `${data?.isDownloadCertificate ? "/order-latest-certificate/" : "/certificate-api-call/"}${data?.order_id}`,
        mode: "cors",
        method: data?.isDownloadCertificate ? "GET" : "POST",
        ...(data?.isDownloadCertificate ? {} : { body: data }),
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    //Get Orders json data
    sendOrderToFastrack: builder.mutation({
      query: ({ order_id, token }) => ({
        url: `/add-order-fastrack/${order_id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),

    //order editorder
    OrderReadOnlyComment: builder.mutation({
      query: ({ token }) => ({
        url: `/order-read-only-comments`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    transactionTypeList: builder.mutation({
      query: ({ token }) => ({
        url: `/transaction-type-list`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    examinerOrderList: builder.mutation({
      query: ({ token }) => ({
        url: `/order-examiner-list`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    orderDisplayCertCommentListQuery: builder.mutation({
      query: ({ token }) => ({
        url: `/order-display-cert-comments`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    noticeFlagList: builder.mutation({
      query: ({ token }) => ({
        url: `/notice-list`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    orderDocumentDownload: builder.mutation({
      query: ({ token, id }) => ({
        url: `/order-document/${id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),

  }),
});

export const {
  useAddOrderMutation,
  useOrderuserProductTypeListMutation,
  useOrdercustomerbranchListMutation,
  useOrdercustomerListMutation,
  useOrdercloserListMutation,
  useOrderReadOnlyCommentListQuery,
  useOrderDisplayCertCommentListQuery,
  useExaminerOrderQuery,
  useOrderEditorQuery,
  useOrdersListMutation,
  useOrderUpdateMutation,
  useOrderStatusUpdateMutation,
  useOrderSearchMutation,
  useOrderEditorPostMutation,
  useOrderResubmitMutation,
  useOrderExaminerUpdateMutation,
  useOrderHoaExaminerUpdateMutation,
  useOrderProductListPricingMutation,
  useOrderProductlistAMutation,
  useDeleteOrderMutation,
  useAuditLogsListMutation,
  useConflictsMutation,
  useValidateConflictsMutation,
  useReviewConflictsMutation,
  useOrderCertificateMutation,
  useDeleteMultipleOrderMutation,
  useOrderCloneMutation,
  useUpdateCertifciateDocMutation,
  useAutoConflictsStatusUpdateMutation,
  useParcelHoaOrderListMutation,
  useOrderHistoryListLogsMutation,
  useUploadCertificateMutation,
  useOrderDocsListMutation,
  useSendEmailCertificateMutation,
  useGetCloserEmailMutation,
  useCertificateDownloadLogsMutation,
  useOrdersDueDateStatusMutation,
  useCheckPrelimanryStatusMutation,
  useDownloadCertificateMutation,
  useDeliverLatestCertificateMutation,
  useShowThirPartyXmlDataMutation,
  useGetOrderJsonDataMutation,
  useSendOrderToFastrackMutation,
  useGetNoiticeNotesMutation,
  useOualiaRequestMutation,
  useOualiaRequestUpdateMutation,
  useQualiaOrderStatusMutation,
  useStopBackendAutomationMutation,
  useUpdateCalculationsMutation,
  useAfterUpdateConflictsMutation,
  useBackendCertificateGenerateMutation,
  useOrderDisplayCertCommentListQueryMutation,
  useOrderReadOnlyCommentMutation,
  useNoticeFlagListMutation,
  useTransactionTypeListMutation,
  useOrderListByStatusMutation,
  useExaminerOrderListMutation,
  useOrderDocumentDownloadMutation,
  useGetDataByStatusMutation,

} = orders;
