import React from "react";
import "./test.css";
import "./certificate.css";
import {
  mobileFormat,
  priceFormater,
  priceFormater2,
  roundedPrice,
} from "../../../../Controllers/Functions";
import MainCertLayout from "../DynamicContent/MainCertLayout";
import {
  changeHeadingBg,
  checkCustomer,
  disabledByer,
  getCustomerName,
} from "../DynamicContent/functions";
import IndependenceAddressInfo from "../DynamicContent/IndependenceTitle/IndependenceAddressInfo";
const parse = require("html-react-parser");

export default function CertificateContent({ values = {} }) {
  const {
    input_Order,
    parcel_Information,
    parcel_information_comments,
    total_tax_summary_bill_information,
  } = values;
  
  function OrderComments() {
    return input_Order?.comments && (
      <div className="certificate-comments bg-outline pb-1 mt-3">
        <div
          className={`${changeHeadingBg(
            input_Order?.client_Name
          )} comments-header  px-1 d-flex gap-1`}
        >
          <h3 className="text-red">IMPORTANT CERTIFICATE COMMENTS</h3>
          {/* <h3 className="text-red">CERTIFICATE &nbsp;</h3>
                    <h3 className="text-red">COMMENTS</h3> */}
        </div>
        <div className="comments-para">
          <p>{parse(input_Order?.comments) || "NA"}</p>
        </div>
      </div>
    )
  }

  function ParcelComments() {
    if (checkCustomer(input_Order?.client_Name)) return null
    return parcel_information_comments?.length > 0 &&
      parcel_information_comments?.map((ele, i) => {
        if (!ele?.parcel_comments || ele?.parcel_comments === " ") return null

        return <div key={i} className="certificate-comments bg-outline pb-1 mt-3">
          <div
            className={`${changeHeadingBg(
              input_Order?.client_Name
            )} comments-header  px-1 d-flex gap-1`}
          >
            <h3 className="text-red">
              IMPORTANT PROPERTY COMMENTS - {ele?.parcel_cad_id}
            </h3>
          </div>
          <div className="comments-para">
            <p>{parse(ele?.parcel_comments) || "NA"}</p>
          </div>
        </div>
      })
  }
  function TotalTaxSummary() {
    return <div>
      <div className="tax-bill-summary bg-outline mt-3">
        <div
          className={`${changeHeadingBg(
            input_Order?.client_Name
          )} tax-bill-summary-header px-1`}
        >
          <span className="main-heading-table">
            Total Taxes Summary for CAD Account(s):
            <span>
              &nbsp;{" "}
              {values?.total_tax_summary_bill_information?.parcelids
                ?.split(",")
                ?.join(", ") || " "}
            </span>
          </span>
        </div>

        <table
          style={{ marginBottom: 0 }}
          className="table  taxing-bill-summary"
        >
          <thead className="bg-light-gray info ">
            <tr>
              <th>
                <h4>Collector</h4>
              </th>
              <th>
                <h4>Tax Year</h4>
              </th>
              <th style={{ width: 130, textAlign: "right" }}>
                <h4>Base Tax</h4>
              </th>
              <th style={{ width: 130, textAlign: "right" }}>
                <h4>Base Due</h4>{" "}
              </th>
              <th style={{ width: 130, textAlign: "right" }}>
                <h4>
                  Due By <br />{" "}
                  {total_tax_summary_bill_information?.due_date_one ||
                    "Date-1"}
                </h4>
              </th>
              <th style={{ width: 130, textAlign: "right" }}>
                <h4>
                  Due By <br />{" "}
                  {total_tax_summary_bill_information?.due_date_two ||
                    "Date-2"}
                </h4>
              </th>
              {/* <th style={{ width: 80, textAlign: "right" }}>
          <h4>Status</h4>
        </th> */}
            </tr>
          </thead>
          <tbody>
            {values?.total_tax_summary_bill_information?.parcel_total_tax_summary?.map(
              (collector, i) => {
                return (
                  <tr>
                    <td>
                      {" "}
                      <h4>{collector?.name || " "}</h4>
                      {collector?.payable_to && (
                        <h5> {collector?.payable_to}</h5>
                      )}
                      <h5>Acct #: {collector?.account_No}</h5>
                      <h5>{collector?.mail_To || " "}</h5>
                      {collector?.phone_No && (
                        <h5>Ph: {mobileFormat(collector?.phone_No)}</h5>
                      )}
                    </td>

                    <td>
                      {collector.total_bill_summary?.map((ele, i) => {
                        return (
                          <h5
                            key={i}
                            className={` ${Number(
                              ele?.basedue?.replace(
                                /[|&;$%@"<>()+,]/g,
                                ""
                              )
                            ) > 0
                              ? "text-danger"
                              : null
                              }`}
                          >
                            {ele?.taxyear || " "}
                          </h5>
                        );
                      })}
                    </td>
                    <td style={{ width: 130, textAlign: "right" }}>
                      {" "}
                      {collector.total_bill_summary?.map((ele, i) => {
                        return (
                          <h5
                            key={i}
                            className={` ${Number(
                              ele?.basedue?.replace(
                                /[|&;$%@"<>()+,]/g,
                                ""
                              )
                            ) > 0
                              ? "text-danger"
                              : null
                              }`}
                          >
                            {ele?.basetax
                              ? priceFormater(ele?.basetax)
                              : "$0"}
                          </h5>
                        );
                      })}
                    </td>
                    <td style={{ width: 130, textAlign: "right" }}>
                      {collector.total_bill_summary?.map((ele, i) => {
                        return (
                          <h5
                            key={i}
                            className={` ${Number(
                              ele?.basedue?.replace(
                                /[|&;$%@"<>()+,]/g,
                                ""
                              )
                            ) > 0
                              ? "text-danger"
                              : null
                              }`}
                          >
                            {ele?.basedue
                              ? priceFormater(ele?.basedue)
                              : "$0"}
                          </h5>
                        );
                      })}
                    </td>
                    <td style={{ width: 130, textAlign: "right" }}>
                      {" "}
                      {collector.total_bill_summary?.map((ele, i) => {
                        return (
                          <h5
                            key={i}
                            className={` ${Number(
                              ele?.basedue?.replace(
                                /[|&;$%@"<>()+,]/g,
                                ""
                              )
                            ) > 0
                              ? "text-danger"
                              : null
                              }`}
                          >
                            {ele?.duedate1
                              ? priceFormater(ele?.duedate1)
                              : "$0"}
                          </h5>
                        );
                      })}
                    </td>
                    <td style={{ width: 130, textAlign: "right" }}>
                      {" "}
                      {collector.total_bill_summary?.map((ele, i) => {
                        return (
                          <h5
                            key={i}
                            className={` ${Number(
                              ele?.basedue?.replace(
                                /[|&;$%@"<>()+,]/g,
                                ""
                              )
                            ) > 0
                              ? "text-danger"
                              : null
                              }`}
                          >
                            {ele?.duedate2
                              ? priceFormater(ele?.duedate2)
                              : "$0"}
                          </h5>
                        );
                      })}
                    </td>
                    {/* <td style={{ width: 80, textAlign: "right" }}>
                {" "}
                {collector.total_bill_summary?.map((ele, i) => {
                  return (
                    <h5
                      key={i}
                      className={` ${ele?.status === "Due" ||
                        Number(
                          ele?.basedue?.replace(
                            /[|&;$%@"<>()+,]/g,
                            ""
                          )
                        ) > 0
                        ? "text-danger"
                        : null
                        }`}
                    >
                      {ele?.status || " "}
                    </h5>
                  );
                })}
              </td> */}
                  </tr>
                );
              }
            )}
          </tbody>
          <tfoot>
            <tr className="bg-light-gray">
              <th style={{ border: "none " }}></th>
              <th>
                <h4>Total Taxes :</h4>
              </th>
              <th style={{ width: 130, textAlign: "right" }}>
                <h4>
                  {values?.total_tax_summary_bill_information
                    ?.parcel_total_taxes_summary?.total_summary_tax_amount
                    ? priceFormater(
                      values?.total_tax_summary_bill_information
                        ?.parcel_total_taxes_summary
                        ?.total_summary_tax_amount
                    )
                    : "$0"}
                </h4>
              </th>
              <th style={{ width: 130, textAlign: "right" }}>
                <h4>
                  {values?.total_tax_summary_bill_information
                    ?.parcel_total_taxes_summary?.total_summary_total_due
                    ? priceFormater(
                      values?.total_tax_summary_bill_information
                        ?.parcel_total_taxes_summary
                        ?.total_summary_total_due
                    )
                    : "$0"}
                </h4>
              </th>
              <th style={{ width: 130, textAlign: "right" }}>
                {" "}
                <h4>
                  {values?.total_tax_summary_bill_information
                    ?.parcel_total_taxes_summary
                    ?.total_summary_total_balance
                    ? priceFormater(
                      values?.total_tax_summary_bill_information
                        ?.parcel_total_taxes_summary
                        ?.total_summary_total_balance
                    )
                    : "$0"}
                </h4>
              </th>
              <th style={{ width: 130, textAlign: "right" }}>
                <h4>
                  {values?.total_tax_summary_bill_information
                    ?.parcel_total_taxes_summary
                    ?.total_summary_total_next_balance
                    ? priceFormater(
                      values?.total_tax_summary_bill_information
                        ?.parcel_total_taxes_summary
                        ?.total_summary_total_next_balance
                    )
                    : "$0"}
                </h4>
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  }
  return (
    <div className="k-pdf-export certificate">
      {/* <div className="mb-3">
        <h2 className="w-100 text-center main-cert-heading-line">
          TAX CERTIFICATE
        </h2>
      </div> */}
      {/* //issue date  */}
      <div
        className={`${changeHeadingBg(
          input_Order?.client_Name
        )} d-flex justify-content-between align-items-center my-2 mt-3 px-1`}
      >
        <div style={{ lineHeight: "13px", marginBottom: "1px" }}>
          <span className="main-heading-table">Completed Date :</span>
          <span className="main-heading-table">
            {input_Order?.completed_date || " "}
          </span>
        </div>
        <div style={{ lineHeight: "13px", marginBottom: "1px" }}>
          <span className="main-heading-table">Certificate Fee :</span>
          <span className="main-heading-table">
            {priceFormater(input_Order?.cert_Fee)}
          </span>
        </div>
      </div>

      {/* //gf Number  */}

      {/* //gf Number  */}
      {/* //gf Number  */}
      {getCustomerName(input_Order?.client_Name) !== 5 ? (
        <div className="d-flex justify-content-between">
          <div
            className=" buyer-seller  justify-content-between"
            style={{ width: "45%" }}
          >
            {input_Order?.client_Name && <div className="gf-number d-flex my-1">
              <h5 style={{ width: 85 }}> Customer </h5>
              <h5 style={{ width: 5 }}>:</h5>
              <h5 style={{ width: 200 }}>&nbsp;{input_Order?.client_Name}</h5>
            </div>}{" "}
            {input_Order?.closer_name && <div className="gf-number d-flex my-1">
              <h5 style={{ width: 85 }}> Closer </h5>
              <h5 style={{ width: 5 }}>:</h5>
              <h5 style={{ width: 200 }}>&nbsp;{input_Order?.closer_name}</h5>
            </div>}{" "}
            <div className="gf-number d-flex my-1">
              <h5 style={{ width: 85 }}> GF # </h5>
              <h5 style={{ width: 5 }}>:</h5>
              <h5 style={{ width: 200 }}>&nbsp;{input_Order?.file_Number}</h5>
            </div>{" "}
            {disabledByer(input_Order?.client_Name) && (
              <div className="buyer d-flex my-1">
                <h5 style={{ width: 85 }}> Buyer(s) </h5>
                <h5 style={{ width: 5 }}>:</h5>
                <h5 style={{ width: 200 }}>
                  &nbsp;{input_Order?.buyer_Name || " "}
                </h5>
              </div>
            )}
            <div className="seller d-flex my-1">
              <h5 style={{ width: 85 }}> Owner(s) / Seller(s) </h5>
              <h5 style={{ width: 5 }}>:</h5>
              <h5 style={{ width: 200 }}>
                &nbsp;{input_Order?.seller_Name || " "}
              </h5>
            </div>
            <div className="buyer d-flex my-1">
              <h5 style={{ width: 85 }}>Property ID </h5>
              <h5 style={{ width: 5 }}>:</h5>
              <h5 style={{ width: 200, wordBreak: "break-all" }}>
                &nbsp;
                {input_Order?.parcel_cad_property_id?.split(",")?.join(", ") ||
                  " "}
              </h5>
            </div>
            <div className="buyer d-flex my-1">
              <h5 style={{ width: 85 }}>Situs Address </h5>
              <h5 style={{ width: 5 }}>:</h5>
              <h5 style={{ width: 200 }}>
                &nbsp;{input_Order?.property_Address || " "}
              </h5>
            </div>
          </div>
          <div className="to w-50">
            <MainCertLayout
              type={getCustomerName(input_Order?.client_Name)}
              input_Order={input_Order}
            />
          </div>
        </div>
      ) : (
        <IndependenceAddressInfo input_Order={input_Order} />
      )}
      {/* //sellar  */}
      {/* <div className="d-flex justify-content-between">
        <div style={{ width: "45%" }} >
          <div className="buyer d-flex mt-3">
            <h5 style={{ width: 40 }}> Buyer(s) </h5>
            <h5 style={{ width: 5 }}>:</h5>
            <h5 style={{ width: 200 }}>
              &nbsp;{input_Order?.buyer_Name || " "}
            </h5>
          </div>
          <div className="buyer d-flex mt-3">
            <h5 style={{ width: 40 }}>Situs Address </h5>
            <h5 style={{ width: 5 }}>:</h5>
            <h5 style={{ width: 200 }}>
              &nbsp;{input_Order?.property_Address || " "}
            </h5>
          </div>
        </div>
        <div className="to w-50" style={{ width: "40%" }}>
          <div className="seller d-flex mt-3">
            <h5 style={{ width: 80 }}> Owner(s) / Seller(s) </h5>
            <h5 style={{ width: 5 }}>:</h5>
            <h5 style={{ width: 200 }}>
              &nbsp;{input_Order?.seller_Name || " "}
            </h5>
          </div>
          <div className="seller d-flex mt-3">
            <h5 style={{ width: 40 }}> Account # </h5>
            <h5 style={{ width: 5 }}>:</h5>
            <h5 style={{ width: 200 }}>
              &nbsp;{input_Order?.account_number || " "}
            </h5>
          </div>
        </div>
      </div> */}

      {/* <div className="fst-italic" style={{ fontSize: 6.1, fontWeight: 400, textAlign: "center", color: "red", display: "flex", alignItems: "center", justifyContent: "center" }}>
        2023 Tax amounts for ISD may be subject to increase pending the outcome of the November election on Proposition 4. If the vote fails , a supplemental bill increasing taxes will be mailed out.
      </div> */}


      {/* <p href="/" style={{ fontSize: 6.7, fontWeight: 400, textAlign:"center" }} className="text-red  d-inline-block bg-success">Tax amounts for ISD may be subject to increase pending the outcome of the November election on Proposition 4. If the vote fails</p> */}
      {/* //parcel data and assesed data */}
      {total_tax_summary_bill_information &&
        parcel_Information?.length > 0 &&
        getCustomerName(input_Order?.client_Name) !== 5 && checkCustomer(input_Order?.client_Name) && (
          <TotalTaxSummary />
        )}
      {checkCustomer(input_Order?.client_Name) && <OrderComments />}
      {parcel_Information?.length > 0 &&
        parcel_Information?.map((parcel, index) => {
          return (
            <div key={parcel?.parcel_No} className="mt-2">
              <div
                className={`${index === 0 && !checkCustomer(input_Order?.client_Name) ? null : "page-break mt-3"
                  } `}
              >
                <div className={`parcel-assessed d-flex mt-${index === 0 ? "1" : "3"} bg-outline`}>
                  {/* //parcel data  */}
                  <div className="parcel">
                    <div
                      className={`${changeHeadingBg(
                        input_Order?.client_Name
                      )} p-1`}
                    >
                      <h4 style={{ color: "inherit" }}>
                        Parcel{index + 1} of {input_Order?.no_of_Parcels || " "}
                      </h4>
                    </div>

                    <div className="p-1">
                      <div className="info d-flex">
                        <h5>Geographic ID </h5>
                        <h5>:&nbsp;</h5>
                        <h5>{parcel?.geographic_Id || " "}</h5>
                      </div>

                      <div className="info d-flex">
                        <h5>Property ID </h5>
                        <h5>:&nbsp;</h5>
                        <h5>{parcel?.parcel_No || " "}</h5>
                      </div>

                      <div className="info d-flex">
                        <h5>Situs Address </h5>
                        <h5>:&nbsp;</h5>
                        <h5>
                          {parcel?.property_Address || " "}
                          {/* {parcel?.property_City +
                          ", " +
                          parcel?.property_State +
                          ", " +
                          parcel?.property_Zip} */}
                        </h5>
                      </div>

                      <div className="info d-flex mt-2">
                        <h5>Assessed Owner(s) </h5>
                        <h5>:&nbsp;</h5>
                        {/* <h5>JERNIGAN-BILLUPS. JUSTIN NABE(100%)</h5> */}
                        <h5>
                          {parcel?.owner?.map((owner) => {
                            return (
                              <span key={owner.owner_Name + owner?.percentage}>
                                {owner.owner_Name} ({owner?.percentage}){" "}
                                {parcel?.owner?.length > 1 ? "," : null}
                              </span>
                            );
                          })}
                        </h5>
                      </div>

                      <div className="info d-flex mb-2 ">
                        <h5> Mailing Address(es) </h5>
                        <h5>:&nbsp;</h5>
                        <h5>
                          {parcel?.owner?.map((owner) => {
                            return (
                              <span key={owner.owner_Name + owner?.percentage}>
                                {owner.address}
                                {parcel?.owner?.length > 1 ? "," : null}
                              </span>
                            );
                          })}
                        </h5>
                      </div>

                      <div className="info d-flex">
                        <h5> Deed Reference </h5>
                        <h5>:&nbsp;</h5>
                        <h5>{parcel?.deed_Ref}</h5>
                      </div>

                      <div className="info d-flex">
                        <h5> Subdivision </h5>
                        <h5>:&nbsp;</h5>
                        <h5>{parcel?.subdivision}</h5>
                      </div>

                      <div className="info d-flex">
                        <h5> Acreage </h5>
                        <h5>:&nbsp;</h5>
                        <h5>{parcel?.acreage}</h5>
                      </div>
                    </div>
                  </div>
                  {/* //assessed data  */}
                  <div className="assessed">
                    <div
                      className={`${changeHeadingBg(
                        input_Order?.client_Name
                      )} d-flex gap-2 p-1`}
                    >
                      <h4 style={{ color: "inherit" }}>Assessment Data</h4>
                    </div>
                    <div className="p-1 pt-0">
                      {/* //header  */}
                      <div className="info header d-flex bg-light-gray">
                        <h4>&nbsp;</h4>
                        <h4>&nbsp;</h4>
                        {parcel.assessed_Information?.map((header) => {
                          return (
                            <h4
                              key={header.assessment_Year}
                              style={{ textAlign: "right" }}
                            >
                              {header.assessment_Year || " "}{" "}
                              {header?.is_proposed ? "Proposed" : "VALUES"}
                            </h4>
                          );
                        })}
                      </div>
                      {/* //row data  */}
                      <div className="info d-flex">
                        <h5>Land</h5>
                        <h5>:&nbsp;</h5>
                        {parcel.assessed_Information?.map((header) => {
                          return (
                            <h5
                              key={header.assessment_Year}
                              style={{ textAlign: "right" }}
                            >
                              {header.land_Value
                                ? roundedPrice(header.land_Value)
                                : "$0"}
                            </h5>
                          );
                        })}
                      </div>

                      <div className="info d-flex">
                        <h5>Improvement</h5>
                        <h5>:&nbsp;</h5>
                        {parcel.assessed_Information?.map((header) => {
                          return (
                            <h5
                              key={header.assessment_Year}
                              style={{ textAlign: "right" }}
                            >
                              {header.improvement_Value
                                ? roundedPrice(header.improvement_Value)
                                : "$0"}
                            </h5>
                          );
                        })}
                      </div>

                      <div className="info d-flex">
                        <h5>Agricultural</h5>
                        <h5>:&nbsp;</h5>
                        {parcel.assessed_Information?.map((header) => {
                          return (
                            <h5
                              key={header.assessment_Year}
                              style={{ textAlign: "right" }}
                            >
                              {header.agricultural_Value
                                ? roundedPrice(header.agricultural_Value)
                                : "$0"}
                            </h5>
                          );
                        })}
                      </div>

                      <div className="info d-flex">
                        <h5>Appraised</h5>
                        <h5>:&nbsp;</h5>
                        {parcel.assessed_Information?.map((header) => {
                          return (
                            <h5
                              key={header.assessment_Year}
                              style={{ textAlign: "right" }}
                            >
                              {header.appraised
                                ? roundedPrice(header.appraised)
                                : "$0"}
                            </h5>
                          );
                        })}
                      </div>

                      <div className="info d-flex">
                        <h5>10% Cap</h5>
                        <h5>:&nbsp;</h5>
                        {parcel.assessed_Information?.map((header) => {
                          return (
                            <h5
                              key={header.assessment_Year}
                              style={{ textAlign: "right" }}
                            >
                              {header.capped_Value === "$null"
                                ? "$0"
                                : header.capped_Value
                                  ? roundedPrice(header.capped_Value)
                                  : "$0"}
                            </h5>
                          );
                        })}
                      </div>

                      <div className="info d-flex">
                        <h5>Assessed</h5>
                        <h5>:&nbsp;</h5>
                        {parcel.assessed_Information?.map((header) => {
                          return (
                            <h5
                              key={header.assessment_Year}
                              style={{ textAlign: "right" }}
                            >
                              {header.total_assessed
                                ? roundedPrice(header.total_assessed)
                                : "$0"}
                            </h5>
                          );
                        })}
                      </div>
                      <div className="info d-flex my-2">
                        <h5>Est Taxes w/o Exemptions</h5>
                        <h5>:&nbsp;</h5>
                        {parcel?.assessed_Information?.map((header, i) => {
                          return (
                            <h5
                              key={i}
                              style={{ textAlign: "right" }}
                            >
                              {header.est_tax_without_exemption
                                ? roundedPrice(header.est_tax_without_exemption)
                                : "$0"}
                            </h5>
                          );
                        })}
                      </div>

                      <div className="info d-flex">
                        <h5>Exemptions</h5>
                        <h5>:&nbsp;</h5>
                        {parcel.assessed_Information?.map((header) => {
                          return (
                            <h5
                              key={header.assessment_Year}
                              style={{ textAlign: "right" }}
                            >
                              {header.exemption_Amount || "No Exemptions"}
                            </h5>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              {/* //legal description */}
              <div className="legal-description bg-outline mt-3 ">
                <div className="legal-description-header bg-gray px-1 ">
                  <h4>Legal Description</h4>
                </div>
                <div className="legal-description-para d-flex">
                  <p>{parcel?.legal_Description || " "}</p>
                </div>
              </div>
              {/* //parcel wise comments  */}
              {checkCustomer(input_Order?.client_Name) && parcel?.parcel_comments && parcel?.parcel_comments !== " " && <div className="certificate-comments bg-outline pb-1 mt-3">
                <div
                  className={`${changeHeadingBg(
                    input_Order?.client_Name
                  )} comments-header  px-1 d-flex gap-1`}
                >
                  <h3 className="text-red">
                    IMPORTANT PROPERTY COMMENTS - {parcel?.parcel_No}
                  </h3>
                </div>
                <div className="comments-para">
                  <p>{parse(parcel?.parcel_comments) || "NA"}</p>
                </div>
              </div>}
              {/* //tax bill summary*/}

              {parcel?.tax_Information?.collectorInfo?.length > 0 && (
                <div>
                  <div className="tax-bill-summary bg-outline mt-3">
                    <div
                      className={`${changeHeadingBg(
                        input_Order?.client_Name
                      )} tax-bill-summary-header px-1`}
                    >
                      <table>
                        <thead>
                          <tr>
                            <th style={{ width: 87 }}>
                              <span className="main-heading-table">
                                Tax Bill Summary:
                              </span>
                            </th>
                            <th>
                              <span className="main-heading-table">
                                {parcel?.parcel_No}
                              </span>
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </div>

                    <table
                      style={{ marginBottom: 0 }}
                      className="table  taxing-bill-summary"
                    >
                      <thead className="bg-light-gray info ">
                        <tr>
                          <th>
                            <h4>Collector</h4>
                          </th>
                          <th>
                            <h4>Tax Year</h4>
                          </th>
                          <th style={{ width: 80, textAlign: "right" }}>
                            <h4>Base Tax</h4>
                          </th>
                          <th style={{ width: 80, textAlign: "right" }}>
                            <h4>Base Due</h4>{" "}
                          </th>
                          <th style={{ width: 120, textAlign: "right" }}>
                            <h4>
                              Due By <br />{" "}
                              {parcel?.tax_Information?.due_by_date_one ||
                                "Date-1"}
                            </h4>
                          </th>
                          <th style={{ width: 120, textAlign: "right" }}>
                            <h4>
                              Due By <br />{" "}
                              {parcel?.tax_Information?.due_by_date_two ||
                                "Date-2"}
                            </h4>
                          </th>
                          <th style={{ width: 80, textAlign: "right" }}>
                            <h4>Status</h4>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {parcel?.tax_Information?.collectorInfo?.map(
                          (collector, i) => {
                            return (
                              <tr>
                                <td>
                                  {" "}
                                  <h4>{collector?.name || " "}</h4>
                                  {collector?.payable_to && (
                                    <h5> {parse(collector?.payable_to)}</h5>
                                  )}
                                  <h5>Acct #: {collector?.account_number}</h5>
                                  <h5>{collector?.mail_To || " "}</h5>
                                  {collector?.phone_No && (
                                    <h5>
                                      Ph: {mobileFormat(collector?.phone_No)}
                                    </h5>
                                  )}
                                </td>

                                <td>
                                  {collector.bill_summary?.map((ele, i) => {
                                    return (
                                      <h5
                                        key={i}
                                        className={` ${ele?.status === "Due" ||
                                          Number(
                                            ele?.basedue?.replace(
                                              /[|&;$%@"<>()+,]/g,
                                              ""
                                            )
                                          ) > 0
                                          ? "text-danger"
                                          : null
                                          }`}
                                      >
                                        {ele?.taxyear || " "}
                                      </h5>
                                    );
                                  })}
                                </td>
                                <td style={{ width: 80, textAlign: "right" }}>
                                  {" "}
                                  {collector.bill_summary?.map((ele, i) => {
                                    return (
                                      <h5
                                        key={i}
                                        className={` ${ele?.status === "Due" ||
                                          Number(
                                            ele?.basedue?.replace(
                                              /[|&;$%@"<>()+,]/g,
                                              ""
                                            )
                                          ) > 0
                                          ? "text-danger"
                                          : null
                                          }`}
                                      >
                                        {ele?.basetax
                                          ? priceFormater(ele?.basetax)
                                          : "$0"}
                                      </h5>
                                    );
                                  })}
                                </td>
                                <td style={{ width: 80, textAlign: "right" }}>
                                  {collector.bill_summary?.map((ele, i) => {
                                    return (
                                      <h5
                                        key={i}
                                        className={` ${ele?.status === "Due" ||
                                          Number(
                                            ele?.basedue?.replace(
                                              /[|&;$%@"<>()+,]/g,
                                              ""
                                            )
                                          ) > 0
                                          ? "text-danger"
                                          : null
                                          }`}
                                      >
                                        {ele?.basedue
                                          ? priceFormater(ele?.basedue)
                                          : "$0"}
                                      </h5>
                                    );
                                  })}
                                </td>
                                <td style={{ width: 120, textAlign: "right" }}>
                                  {" "}
                                  {collector.bill_summary?.map((ele, i) => {
                                    return (
                                      <h5
                                        key={i}
                                        className={` ${ele?.status === "Due" ||
                                          Number(
                                            ele?.basedue?.replace(
                                              /[|&;$%@"<>()+,]/g,
                                              ""
                                            )
                                          ) > 0
                                          ? "text-danger"
                                          : null
                                          }`}
                                      >
                                        {ele?.duedate1
                                          ? priceFormater(ele?.duedate1)
                                          : "$0"}
                                      </h5>
                                    );
                                  })}
                                </td>
                                <td style={{ width: 120, textAlign: "right" }}>
                                  {" "}
                                  {collector.bill_summary?.map((ele, i) => {
                                    return (
                                      <h5
                                        key={i}
                                        className={` ${ele?.status === "Due" ||
                                          Number(
                                            ele?.basedue?.replace(
                                              /[|&;$%@"<>()+,]/g,
                                              ""
                                            )
                                          ) > 0
                                          ? "text-danger"
                                          : null
                                          }`}
                                      >
                                        {ele?.duedate2
                                          ? priceFormater(ele?.duedate2)
                                          : "$0"}
                                      </h5>
                                    );
                                  })}
                                </td>
                                <td style={{ width: 80, textAlign: "right" }}>
                                  {" "}
                                  {collector.bill_summary?.map((ele, i) => {
                                    return (
                                      <h5
                                        key={i}
                                        className={` ${ele?.status === "Due" ||
                                          Number(
                                            ele?.basedue?.replace(
                                              /[|&;$%@"<>()+,]/g,
                                              ""
                                            )
                                          ) > 0
                                          ? "text-danger"
                                          : null
                                          }`}
                                      >
                                        {ele?.status || " "}
                                      </h5>
                                    );
                                  })}
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                      <tfoot>
                        <tr className="bg-light-gray">
                          <th style={{ border: "none " }}></th>
                          <th>
                            <h4>Total Taxes :</h4>
                          </th>
                          <th style={{ width: 80, textAlign: "right" }}>
                            <h4>
                              {parcel?.tax_Information?.totalAmt?.basetax
                                ? priceFormater(
                                  parcel?.tax_Information?.totalAmt?.basetax
                                )
                                : "$0"}
                            </h4>
                          </th>
                          <th style={{ width: 80, textAlign: "right" }}>
                            <h4>
                              {parcel?.tax_Information?.totalAmt?.basedue
                                ? priceFormater(
                                  parcel?.tax_Information?.totalAmt?.basedue
                                )
                                : "$0"}
                            </h4>
                          </th>
                          <th style={{ width: 120, textAlign: "right" }}>
                            {" "}
                            <h4>
                              {parcel?.tax_Information?.totalAmt?.duedate1
                                ? priceFormater(
                                  parcel?.tax_Information?.totalAmt?.duedate1
                                )
                                : "$0"}
                            </h4>
                          </th>
                          <th style={{ width: 120, textAlign: "right" }}>
                            <h4>
                              {parcel?.tax_Information?.totalAmt?.duedate2
                                ? priceFormater(
                                  parcel?.tax_Information?.totalAmt?.duedate2
                                )
                                : "$0"}
                            </h4>
                          </th>
                          <th style={{ width: 80, textAlign: "right" }}></th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              )}

              {/* //Notice  */}
              {parcel?.tax_Information?.collectorInfo?.length > 0 && (
                <div className="notice mt-3 fst-italic text-red">
                  <h6 className="text-red">
                    This Section is for Information Purposes Only - Tax Bill
                    Payment Status is Not reflected Below
                  </h6>
                </div>
              )}

              {/* //taxing juri  */}
              {parcel?.tax_Information?.collectorInfo?.length > 0 && (
                <div className=" tax-bill-summary bg-outline mt-2">
                  <div
                    className={`${changeHeadingBg(
                      input_Order?.client_Name
                    )} taxing-juri-header px-1`}
                  >
                    <table>
                      <thead>
                        <tr>
                          <th style={{ width: 175 }}>
                            <span className="main-heading-table">
                              Taxing Jurisdictions - Total Tax Rate:-
                            </span>
                          </th>
                          <th>
                            <span className="main-heading-table">
                              {parcel?.jurisdiction_info?.total_taxrate ||
                                "$0.00"}
                            </span>
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  <table style={{ marginBottom: 0 }} className="table  ">
                    <thead className="bg-light-gray info ">
                      <tr>
                        <th>
                          <h4>Taxing Jurisdiction</h4>
                        </th>
                        <th style={{ textAlign: "left" }}>
                          <h4>Rate Year</h4>
                        </th>
                        <th style={{ textAlign: "right" }}>
                          <h4>Tax Rate</h4>
                        </th>
                        <th style={{ textAlign: "right" }}>
                          <h4>Est Taxes</h4>
                        </th>
                        <th style={{ textAlign: "right" }}>
                          <h4>Bill Year</h4>{" "}
                        </th>
                        <th style={{ textAlign: "right" }}>
                          <h4>Base Amount</h4>{" "}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {parcel?.jurisdiction_info?.juri_data?.map(
                        (juricollector, index) => {
                          return (
                            <>
                              {juricollector?.jurisdiction_data?.map(
                                (data, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <h5>{data?.name || " "}</h5>
                                      </td>
                                      <td style={{ textAlign: "left" }}>
                                        <h5>{data?.tax_year || ""}</h5>
                                      </td>
                                      <td style={{ textAlign: "right" }}>
                                        <h5>
                                          {data?.tax_rate
                                            ? data?.tax_rate
                                            : "$0.00"}
                                        </h5>
                                      </td>
                                      <td style={{ textAlign: "right" }}>
                                        <h5>
                                          {data?.estimated_tax_without_exemption
                                            ? priceFormater2(data?.estimated_tax_without_exemption) : "$0"}
                                        </h5>
                                      </td>
                                      <td style={{ textAlign: "right" }}>
                                        <h5>{data?.billyear || " "}</h5>
                                      </td>
                                      <td style={{ textAlign: "right" }}>
                                        <h5>
                                          {data?.baseamount
                                            ? priceFormater(data?.baseamount)
                                            : "$0"}
                                        </h5>
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                              <tr className="bg-gray">
                                <td>
                                  <h4>
                                    {juricollector?.collector_name || " "}
                                  </h4>
                                </td>
                                <td style={{ textAlign: "left" }}>
                                  <h4></h4>
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  <h4>
                                    {juricollector?.tax_rate
                                      ? juricollector?.tax_rate
                                      : "$0.00"}
                                  </h4>
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  <h4></h4>
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  <h4></h4>
                                </td>
                                <td style={{ textAlign: "right" }}>
                                  <h4></h4>
                                </td>
                              </tr>
                            </>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              )}

              {parcel?.jurisdiction_info?.display_on_cert && <div className="legal-description bg-outline mt-3 ">
                <div className="legal-description-header bg-gray px-1 py-1 ">
                  <h4>Taxing Jurisdiction Comments</h4>
                </div>
                <div className="legal-description-para juricomments-fonts ps-1 ">
                  {parse(parcel?.jurisdiction_info?.display_on_cert)}
                </div>
              </div>
              }
              {/* //Bonds */}
              {parcel?.bonds?.length > 0 && (
                <div className="bonds tax-bill-summary bg-outline mt-3">
                  <div
                    className={`${changeHeadingBg(
                      input_Order?.client_Name
                    )} bonds-header px-1`}
                  >
                    <h4 style={{ color: "inherit" }}>Bond Information</h4>
                  </div>

                  <table style={{ marginBottom: 0 }} className="table">
                    <thead className="bg-light-gray info bonds">
                      <tr>
                        <th style={{ textAlign: "left" }}>
                          <h4>Name</h4>
                        </th>
                        <th style={{ textAlign: "right" }}>
                          <h4>Bonds Approved</h4>
                        </th>
                        <th style={{ textAlign: "right" }}>
                          <h4>Bonds Issued</h4>
                        </th>
                        <th style={{ textAlign: "right" }}>
                          <h4>Standby Fees</h4>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {parcel?.bonds?.map((bond, i) => {
                        return (
                          <tr key={i}>
                            <td style={{ textAlign: "left" }}>
                              <h5>{bond?.name || " "}</h5>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              <h5>
                                {bond?.approved
                                  ? priceFormater(bond?.approved)
                                  : "$0"}
                              </h5>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              <h5>
                                {bond?.issued
                                  ? priceFormater(bond?.issued)
                                  : "$0"}
                              </h5>
                            </td>
                            <td style={{ textAlign: "right" }}>
                              <h5>
                                {bond?.standfees
                                  ? priceFormater(bond?.standfees)
                                  : "$0"}
                              </h5>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {/* <div className="bonds-header-content bg-light-gray info d-flex px-1 ">
                  <h4>Name</h4>
                  <h4>Bonds Approved</h4>
                  <h4>Bonds Issued</h4>
                  <h4>Standby Fees</h4>
                </div> */}

                  {/* {parcel?.bonds?.map((bond, i) => {
                  return (
                    <div
                      key={i}
                      className="bonds-header-content  info d-flex px-1 "
                    >
                      <h5>{bond?.name || "-"}</h5>
                      <h5>{bond?.approved || "-"}</h5>
                      <h5>{bond?.issued || "-"}</h5>
                      <h5>{bond?.standfees || "-"}</h5>
                    </div>
                  );
                })} */}
                </div>
              )}

              {/* //disable Veteran */}
              {parcel?.exemption?.length > 0 && (
                <div className="Veteran bg-outline mt-3">
                  <div className="d-flex Veteran-header">
                    <div className="Veteran-header p-1 ">
                      <h4>Exemptions by Jurisdictions</h4>
                    </div>
                    <div
                      className={`${changeHeadingBg(
                        input_Order?.client_Name
                      )} p-1 text-center w-50`}
                    >
                      <h4 style={{ color: "inherit" }}>Disabled Veteran</h4>
                    </div>
                  </div>
                  <table
                    style={{ marginBottom: 0 }}
                    className="table  exemptions"
                  >
                    <thead className="bg-light-gray info ">
                      <tr>
                        <th>
                          <h4>Collector</h4>
                        </th>
                        <th>
                          <h4>HOM</h4>
                        </th>
                        <th>
                          <h4>OV65</h4>
                        </th>
                        <th>
                          <h4>DP</h4>
                        </th>
                        <th>
                          <h4>10-30%</h4>
                        </th>
                        <th>
                          <h4>31-50%</h4>
                        </th>
                        <th>
                          <h4>51-70%</h4>
                        </th>
                        <th>
                          <h4>71-100%</h4>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {parcel?.exemption?.map((excemp, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <h5>{excemp?.name || " "}</h5>
                            </td>
                            <td>
                              <h5>{excemp?.hom || " "}</h5>
                            </td>
                            <td>
                              <h5>{excemp?.ov65 || " "}</h5>
                            </td>
                            <td>
                              <h5>{roundedPrice(excemp?.dp)}</h5>
                            </td>
                            <td>
                              <h5>{excemp["10to30"] || "5K"}</h5>
                            </td>
                            <td>
                              <h5>{excemp["31to50"] || "7.5K"}</h5>
                            </td>
                            <td>
                              <h5>{excemp["51to70"] || "10K"}</h5>
                            </td>
                            <td>
                              <h5>{excemp["71to100"] || "12K"}</h5>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {/* //certificate comments */}

                  {/* <div className="Veteran-header-content bg-light-gray info d-flex px-1 ">
                    <h4>Collector</h4>
                    <h4>HOM</h4>
                    <h4>OV65</h4>
                    <h4>DP</h4>
                    <h4>10-30%</h4>
                    <h4>31-50%</h4>
                    <h4>51-70%</h4>
                    <h4>71-100%</h4>
                  </div> */}

                  {/* {parcel?.exemption?.map((excemp, i) => {
                    return (
                      <div
                        key={i}
                        className="Veteran-header-content  info d-flex px-1 "
                      >
                        <h5>{excemp?.name || "-"}</h5>
                        <h5>{excemp?.hom || "-"}</h5>
                        <h5>{excemp?.ov65 || "-"}</h5>
                        <h5>{excemp?.dp || "-"}</h5>
                        <h5>{excemp["10to30"] || "5k"}</h5>
                        <h5>{excemp["31to50"] || "7.5K"}</h5>
                        <h5>{excemp["51to70"] || "10K"}</h5>
                        <h5>{excemp["71to100"] || "12K"}</h5>
                      </div>
                    );
                  })} */}
                </div>
              )}
            </div>
          );
        })}
      <ParcelComments />
      {!checkCustomer(input_Order?.client_Name) && <OrderComments />}
      {total_tax_summary_bill_information &&
        parcel_Information?.length > 0 &&
        getCustomerName(input_Order?.client_Name) !== 5 && !checkCustomer(input_Order?.client_Name) && (
          <TotalTaxSummary />
        )}
      {parcel_Information?.length > 0 &&
        Object.keys(parcel_Information[0]?.tax_Information).length > 0 && (
          <div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>

            <div className="disclaimer mb-3 tax-bill-summary">
              <strong>Tax Certificate Disclaimer :</strong>
              <div className="content mt-2">
                <p className="ps-0">
                  All applicable ad valorem taxes on the above referenced
                  property have been checked and are found to have the status
                  provided except: Status does not cover any changes made to the
                  tax records of the agencies listed after the “Order Completed”
                  date hereof. Does not include and is not a certification of
                  any mineral taxes, personal property taxes( including mobile
                  homes), or any other non-ad valorem taxes.
                  <br />
                  In the event a parcel or tract of real property covered in a
                  request had been previously re-subdivided, split out, our
                  deleted( killed), whereas previous tax account was assigned,
                  and/or is to be or was split, or was an undivided interest
                  account, or creating a new tax account, and specific parcel or
                  tract information is not readily available on the county
                  appraisal district records; the Property Tax Service shall
                  only be required to, and claims limited to, furnish Appraisal
                  and Tax Information for the parcel or tract as shown on the
                  Current Records of the Appraisal District.
                  <br />
                  Proposed Values are strictly preliminary and may
                  change.Proposed Values are obtained directly from the
                  appraisal districts and are provided for information purposes
                  only.The Property Tax Service is not liable for any pro-ration
                  shortage or overage based on these Proposed Values.
                </p>
              </div>
            </div>
          </div>
        )}
    </div>
  );
}
