import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json");
export const manualEntry = createApi({
  reducerPath: "manualEntry",
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  tagTypes: ["manualEntry"],
  endpoints: (builder) => ({
    addParcel: builder.mutation({
      query: (data) => ({
        url: `/add-parcel`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
  }),
});

export const { useAddParcelMutation } = manualEntry;
