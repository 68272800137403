import React, { useState, useEffect, createContext, useContext } from "react";
import { Tabs, Tooltip, notification, Popconfirm, Spin } from "antd";

import Order from "../Order";
import NewOrder from "./Summary/newOrder";

import "./orderEditor.css";
import { FaPlus, FaRegClone } from "react-icons/fa";

import {
  CloseOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  AiOutlineAudit,
  AiOutlineDeliveredProcedure,
  AiOutlineEdit,
  AiOutlineEye,
} from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import ViewParcel from "./ParcelViewListDrawer/ViewParcel";
import AppSelect from "../../../Components/AppSelect/AppSelect";
import { useDispatch, useSelector } from "react-redux";
import { useOrderStatusListPostMutation, useOrderStatusQuery } from "../../../Redux/Services/status";
import {
  useCloneParcelFromOrderMutation,
  useGetHoaParcelStatusMutation,
  useParcelDocumentCountMutation,
  useParcelInfoMutation,
  useParcelStatusInfoMutation,
  useParcelStatusListMutation,
  useUpdateParcelCommentsMutation,
} from "../../../Redux/Services/Parcels";
import { FiDownload, FiEdit } from "react-icons/fi";

import {
  useAfterUpdateConflictsMutation,
  useAuditLogsListMutation,
  useAutoConflictsStatusUpdateMutation,
  useBackendCertificateGenerateMutation,
  useCertificateDownloadLogsMutation,
  useCheckPrelimanryStatusMutation,
  useConflictsMutation,
  useDeleteOrderMutation,
  useDeliverLatestCertificateMutation,
  useGetCloserEmailMutation,
  useGetOrderJsonDataMutation,
  useOrderCertificateMutation,
  useOrderDocumentDownloadMutation,
  useOrderEditorPostMutation,
  useOrderExaminerUpdateMutation,
  useOrderStatusUpdateMutation,
  useOualiaRequestUpdateMutation,
  useReviewConflictsMutation,
  useSendOrderToFastrackMutation,
  useShowThirPartyXmlDataMutation,
  useUpdateCalculationsMutation,
  useUploadCertificateMutation,
  useValidateConflictsMutation,
} from "../../../Redux/Services/Orders";
import NewParcel from "./NewParcel/NewParcel";
import ParcelCard from "./ParcelCards/ParcelCard";
import ParcelsMain from "./Parcels/ParcelsMain";

import { useCallback } from "react";
import { HiOutlineMail } from "react-icons/hi";
import { GlobalContext } from "../../../App";
import {
  MdDeleteOutline,
  MdInput,
  MdOutlineAssignmentInd,
  MdOutlinePreview,
  MdOutlineSendAndArchive,
  MdOutlineSettings,
} from "react-icons/md";
import AppModal from "../../../Components/AppModal/AppModal";

import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import OrderInfoCopyModal from "./OrderInfoCopyModal/OrderInfoCopyModal";
import {
  blobToBase64,
  checkIsHoaProduct,
  dateFun,
  downloadBase64PDF,
  downloadJSON,
  downloadPDF,
  downloadPdf,
  scrollComp,
} from "../../../Controllers/Functions";
import axios from "axios";
import Conflicts from "./Conflicts/Conflicts";
import { CgViewSplit } from "react-icons/cg";
import CertificateContent from "./PDF/CertificateContent";
import { VscJson, VscRunErrors } from "react-icons/vsc";
import OrderList from "../Component/OrderList";
import AuditLogs from "../AuditLogs/AuditLogs";
import { BsFilePdf } from "react-icons/bs";
import EmailModal from "./Summary/Email/EmailModal";
import HoaCertificateInfoMain from "./PDF/HoaCertificateInfoMain";
import base64ToBlob from "base64toblob";
import PDFMerger from "pdf-merger-js";
import XmlDataFile from "./XmlData/XmlDataFile";
import { dataTEst } from "./orderJson";
import { MainCertLayoutHOAHeader, MainCertLayoutHOAHeaderMargin, MainCertLayoutHeader, MainCertLayoutHeaderMargin } from "./DynamicContent/MainCertLayout";
import { getCustomerName } from "./DynamicContent/functions";
import { useAutomatiomStatusCheck } from "../../../Hooks/useAutomatiomStatusCheck";
import OrderBtns from "../../../Components/OrderBtns";
import { useAutomationStop } from "../../../Hooks/useAutomationStop";
import { AssignmeComp } from "./AssignmeComp";
import { certificateDownloadLoadingHandler, viewPdfDocuementHandler } from "../../../Redux/reducers/Order";
import { addParcelIdHandler, parcelListHandler } from "../../../Redux/reducers/ParcelReducer/Parcel.reducer";
import { useParcelHoaListMutation } from "../../../Redux/Services/Hoa";
import useSeeJson from "../../../Hooks/useSeeJson";
// import { dataTEst } from "./orderJson";
export const parcelContext = createContext();
const { TabPane } = Tabs;
const randomPass = require("secure-random-password");
const config = require("../../../config.json");

const OrderEditor = () => {
  //context api
  const spinner = useContext(GlobalContext);
  const [globalVal, setGlobalVal] = spinner.state;

  const dispatch = useDispatch()

  const [key, setKey] = useState("summary");
  const [, setLoading] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [newParcelTab, setNewParcelTab] = useState(false);
  const [ediStatusShow, setEdiStatusShow] = useState(false);
  const [parcelSwitchFlag] = useState(false);
  const [parcelAddFlag, setParcelAddFlag] = useState(false);
  const [isStoreInDB, setIsStoreInDB] = useState(true);
  const [idDownloadBtn, setIsDownloadBtn] = useState(false);
  const [xmlData, setXmlData] = useState(false);
  const [showAutomationBtn, setshowAutomationBtn] = useState(false);

  const [viewParcelData, setViewParcelData] = useState(false);
  const [showToollBar, setShowToollBar] = useState(false);
  const [automationProcess, setAutomationProcess] = useState(false);
  const [status, setStatus] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [statusOptionsData, setStatusOptionsData] = useState([]);
  const [orderConflicts, setOrderConflicts] = useState([]);
  const [showAuditLogModal, setShowAuditLogModal] = useState(false);
  const [auditLogData, setAuditLogData] = useState([]);
  //context api
  const parcelState = {
    toolbar: true,
    mode: "view",
    key: "summary",
    parcelId: null,
    parcelList: [],
    refetch: false,
    orderData: {},
    year: "",
    orderTwoView: false,
    parcelTwoView: false,
    parcelPdfDoc: false,
    parcelConflicts: false,
    supportingTwoView: false,
    supportigDocList: false,
    isAutomationRunning: false,
    supportigDocListShowFromPdfBtn: false,
    orderEditorApi: false,
    storeCertificateInDbFun: function () { },
  };
  const [parcelController, setParcelController] = useState(parcelState);

  const [orderContorller, setOrderController] = useState({
    pdfDoc: "",
    orderConflicts: false,
  });
  // console.log(parcelController?.orderData?.branch_name);
  const { state } = useLocation();
  const editId = state?.id;
  const orderNumber = state?.orderNumber;
  const orderEditableFlag = state?.orderEditableFlag;
  const [orderEvent, setOrderEvent] = useState();

  useEffect(() => { setOrderEvent(state?.orderEvent) }, [state?.orderEvent])

  const { internalOrder, newInterNalOrder } = useSelector((state) => state.flagRoles);
  const assign_me_btn = internalOrder?.find((info) => info?.assign_me_btn)
  //redux
  const { orderDATA, viewPdfDocument, isDownloadingCertificate, bakendAutomation, showalertAssign } = useSelector((state) => state.OrderData);
  const { parcelList } = useSelector((state) => state.parcelReducer);

  const navigate = useNavigate();
  //new parcel tab flags
  const [newParcelTabFlag, setNewParcelTabFlag] = useState(false);
  const [orderEditorApi, orderEditorResult] = useOrderEditorPostMutation();
  //default
  const [parcelInfo, parcelInfoResult] = useParcelInfoMutation();
  //order conflicts m
  const [parcelConflicts, parcelConflictsResult] = useConflictsMutation();
  const [getParcelStatusInfo, getParcelStatusInfoResult] =
    useParcelStatusInfoMutation();
  const [parcelDocumentCountApi, parcelDocumentCountResult] = useParcelDocumentCountMutation()
  const [parcelHoaList, parcelHoaListResult] = useParcelHoaListMutation();
  //update parcel info
  const [getParcelHoaStatus, getParcelHoaStatusResult] =
    useGetHoaParcelStatusMutation();
  //download  certificate
  const [deliverCertificateToSoftPro, deliverCertificateToSoftProResult] =
    useDeliverLatestCertificateMutation();
  const [orderDocumentDownload, orderDocumentDownloadResult] = useOrderDocumentDownloadMutation();
  const [deliverCertificateToQualia, deliverCertificateToQualiaResult] =
    useOualiaRequestUpdateMutation();
  //View Xml Data
  const [viewXmlDataApi, viewXmlDataApiResult] =
    useShowThirPartyXmlDataMutation();

  const [updateCalculationsApi,] =
    useUpdateCalculationsMutation();

  //order info for copy details
  const [orderInfoCopyModal, setOrderInfoCopyModal] = useState(false);
  //responsice
  const [res] = useState(false);
  //drawer
  const [open, setOpen] = useState(null);

  //audit logs
  const [auditLogsListCall, auditLogsListResult] = useAuditLogsListMutation();
  const [addCertifciateDownloadLogs] = useCertificateDownloadLogsMutation();

  const [editable, setEditable] = useState(false);
  const [conflictsNotification, setConflictsNotification] = useState(false);
  const [conflictsProcess, setConflictProcess] = useState(false);
  const [validateConflictsRunnig, setValidateConflictsRunnig] = useState(false);

  const { token, user } = useSelector((state) => state.auth);

  //review conflicts
  const [reviewConflicts, reviewConflictsResult] = useReviewConflictsMutation();
  const { StopAutomationComp } = useAutomationStop(token);

  //Get email certificate
  const [getCloserEmail, getCloserEmailResult] = useGetCloserEmailMutation();
  //see json for details
  const { GetJsonComp } = useSeeJson()
  //api
  const [addCertificateDocuments, addCertificateDocumentsResult] =
    useUploadCertificateMutation();

  //api
  const [sendOrderToFastrack, sendOrderToFastrackResult] =
    useSendOrderToFastrackMutation();

  //certificate
  const [certificate, certificateResult] = useOrderCertificateMutation();
  //parcels api
  const [parcelStatusList, parcelStatusListResult] =
    useParcelStatusListMutation();

  //get order json data
  const [orderJsonData, orderJsonDataResult] = useGetOrderJsonDataMutation();
  //order conflicts m
  const [conflicts, conflictsResult] = useConflictsMutation();

  function confilctsRefecth() {
    if (editId)
      conflicts({ data: { order_id: editId }, token });
  }

  const [downloadCertificateApi, downloadCertificateApiResult] = useBackendCertificateGenerateMutation()

  //download certificate
  async function downloadCertificate(isDownloadCertificate=false) {
    let query = { data: { order_id: editId }, token }
    //if the certificate isDownload
    if (isDownloadCertificate) {
      query["data"]["isDownloadCertificate"] = true
      dispatch(certificateDownloadLoadingHandler(true))
    }

    //fetch certificate api download request
    downloadCertificateApi(query).then(({ data }) => {
      try {
        if (data && isDownloadCertificate) {
          downloadPDF(atob(data?.data?.documents), data?.data?.document_name)
        }
        if (data && key === "summary" && !isDownloadCertificate) {
          //refetch order api
          orderEditorApi({ id: orderDATA?.id, token }).then(() => {
            console.log("successfully worked")
          })
        }

      } catch (error) {
        console.log(error)
      }
    }).finally(() => {
      dispatch(certificateDownloadLoadingHandler(false))
      setValidateConflictsRunnig(false)
      //false
      setConflictProcess(false)
    })
  }

  useEffect(() => {
    //order states
    orderStatusApi(token)
  }, [window.location.pathname])

  //check this products for automation
  const { product_type_id } = parcelController?.orderData;
  const taxProduct =
    product_type_id !== 4 && product_type_id !== 9 && product_type_id !== 5;

  //auditlist
  useEffect(() => {
    if (auditLogsListResult.isSuccess) {
      setAuditLogData(auditLogsListResult.data?.data);
      setShowAuditLogModal(true);
    } else {
      setAuditLogData([]);
      setShowAuditLogModal(false);
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: auditLogsListResult.isLoading });
  }, [auditLogsListResult.isLoading]);

  function refetchApiAuto() {
    parcelStatusList({ id: editId, token });
  }


  //sending request to  resubmit order
  const [orderExaminerUpdate, orderExaminerUpdateResult] =
    useOrderExaminerUpdateMutation();

  function validateOrderAndDowloadCertificate() {
    if (key === "summary") {
      // dispatch(certificateDownloadLoadingHandler(true))
      validateConflicts({
        data: { order_id: editId },
        token,
      });
    } else {
      //parcel informational
      if (parcelInfoResult.data?.data) {
        const parcel_id = parcelInfoResult.data?.data?.id
        setConflictProcess(true)
        dispatch(certificateDownloadLoadingHandler(true))
        getParcelStatusInfo({ token, parcel_id }).then(() => {
          //false
          if (parcelController?.parcelTwoView) {
            parcelConflicts({ data: { parcel_id }, token })
          }
          parcelDocumentCountApi({ token, parcel_id });
          setConflictProcess(false)
          dispatch(certificateDownloadLoadingHandler(false))
        })
      }
    }
  }

  async function withoutGenerateCerticate() {
    if (key === "summary") {
      //refetch order api
      orderEditorApi({ id: orderDATA?.id, token }).then(() => {
        if (orderContorller.orderTwoView) {
          conflicts({ data: { order_id: editId }, token });
        }
        parcelStatusList({ id: editId, token });
        //false
        setConflictProcess(false)
        dispatch(certificateDownloadLoadingHandler(false))
      })
    } else {
      //parcel informational
      if (parcelInfoResult.data?.data) {
        const parcel_id = parcelInfoResult.data?.data?.id
        setConflictProcess(true)
        dispatch(certificateDownloadLoadingHandler(true))
        getParcelStatusInfo({ token, parcel_id }).then(() => {
          //false
          if (parcelController?.parcelTwoView) {
            parcelConflicts({ data: { parcel_id }, token })
          }
          parcelDocumentCountApi({ token, parcel_id });
          setConflictProcess(false)
          dispatch(certificateDownloadLoadingHandler(false))
        })
      }
    }
  }

  const { mutateAsyncCallBakendAutomation } = useAutomatiomStatusCheck(editId, token, refetchApiAuto, setOrderStatus, validateOrderAndDowloadCertificate, withoutGenerateCerticate)


  //auditlist
  useEffect(() => {
    if (viewXmlDataApiResult.isSuccess) {
      setXmlData(viewXmlDataApiResult.data?.data);
    }
    if (viewXmlDataApiResult.isError) {
      setXmlData(false);
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description:
          viewXmlDataApiResult?.error?.data?.message || "Something went wrong!",
      });
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: viewXmlDataApiResult.isLoading });
  }, [viewXmlDataApiResult.isLoading]);

  //auditlist
  useEffect(() => {

    if (downloadCertificateApiResult.isError) {
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description:
          downloadCertificateApiResult?.error?.data?.message || "Something went wrong!",
      });
    }

  }, [downloadCertificateApiResult.isLoading]);

  //auditlist
  useEffect(() => {
    if (orderJsonDataResult.isSuccess) {
      const jsonData = orderJsonDataResult.data;
      const filename = `data_${dateFun("datetime")}.json`;
      downloadJSON(jsonData, filename);

      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: "Json data download successfully",
      });
    }
    if (orderJsonDataResult.isError) {
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description: "Not able to genarate the order",
      });
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: orderJsonDataResult.isLoading });
  }, [orderJsonDataResult.isLoading]);

  //send fastrack order
  useEffect(() => {
    if (sendOrderToFastrackResult.isSuccess) {
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description:
          sendOrderToFastrackResult?.data?.message || "Order Sent Successfully",
      });
    }
    if (sendOrderToFastrackResult.isError) {
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description:
          sendOrderToFastrackResult?.error?.data?.message ||
          "Order could not send!",
      });
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: sendOrderToFastrackResult.isLoading });
  }, [sendOrderToFastrackResult.isLoading]);

  //auditlist
  useEffect(() => {
    if (deliverCertificateToSoftProResult.isSuccess) {
      const isError = deliverCertificateToSoftProResult?.data?.message === "Certificate not delivered"
      notification.open({
        type: isError ? "error" : "success",
        style: { color: isError ? "red" : "green", marginTop: "50px" },
        description: deliverCertificateToSoftProResult?.data?.message || "Certificate Delivered Successfully",
      });
      //refetcing order
      orderEditorApi({ id: orderDATA?.id, token });
    }
    if (deliverCertificateToSoftProResult.isError) {
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description:
          deliverCertificateToSoftProResult?.error?.data?.message ||
          "Tax Certificate is not available for this order",
      });
    }
    //global spinner
    setGlobalVal({
      ...globalVal,
      loader: deliverCertificateToSoftProResult.isLoading,
    });
  }, [deliverCertificateToSoftProResult.isLoading]);

  //auditlist
  useEffect(() => {
    if (orderExaminerUpdateResult.isSuccess) {
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: orderExaminerUpdateResult.data?.message,
      });
      //refetcing order
      orderEditorApi({ id: orderDATA?.id, token });
    }
    if (orderExaminerUpdateResult.isError) {
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description:
          orderExaminerUpdateResult?.error?.data?.message
      });
    }
    //global spinner
    setGlobalVal({
      ...globalVal,
      loader: orderExaminerUpdateResult.isLoading,
    });
  }, [orderExaminerUpdateResult.isLoading]);

  //auditlist
  useEffect(() => {
    if (deliverCertificateToQualiaResult.isSuccess) {
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: deliverCertificateToQualiaResult?.data?.message || "Certificate Delivered Successfully",
      });
      //refetcing order
      orderEditorApi({ id: orderDATA?.id, token });
    }
    if (deliverCertificateToQualiaResult.isError) {
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description:
          deliverCertificateToQualiaResult?.error?.data?.message ||
          "Tax Certificate is not available for this order",
      });
    }
    //global spinner
    setGlobalVal({
      ...globalVal,
      loader: deliverCertificateToQualiaResult.isLoading,
    });
  }, [deliverCertificateToQualiaResult.isLoading]);



  //duplicate
  const [ConflictsListForUpdate, ConflictsListForUpdateResult] = useAfterUpdateConflictsMutation()

  useEffect(() => {
    if (ConflictsListForUpdateResult.isSuccess) {
      const newData = ConflictsListForUpdateResult.data.data;
      setOrderConflicts(newData);
      if (ConflictsListForUpdateResult?.data?.is_fixed) {
        //flag available run this validation
        validateConflicts({
          data: { order_id: editId },
          token,
        });

      } else {
        conflicts({ data: { order_id: editId }, token });
      }
    }
    setGlobalVal({ ...globalVal, loader: ConflictsListForUpdateResult.isLoading });
  }, [ConflictsListForUpdateResult.isLoading])

  useEffect(() => {
    if (reviewConflictsResult.isSuccess) {
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: reviewConflictsResult.data?.message,
      });
      // //call api for conflicts
      //i will run duplicate
      ConflictsListForUpdate({ data: { order_id: editId }, token })
      // validateConflicts({
      //   data: { order_id: editId },
      //   token,
      // });
    }

    // //global spinner
    setGlobalVal({ ...globalVal, loader: reviewConflictsResult.isLoading });
  }, [reviewConflictsResult.isLoading]);


  //certifcate send on emal
  useEffect(() => {
    if (getCloserEmailResult.isSuccess) {
      setShowEmailModal(getCloserEmailResult.data.data);
    }
    if (getCloserEmailResult.isError) {
      setShowEmailModal(true);
    }
    // //global spinner
    setGlobalVal({
      ...globalVal,
      loader: getCloserEmailResult.isLoading,
    });
  }, [getCloserEmailResult.isLoading]);

  useEffect(() => {
    setNewParcelTab(newParcelTab);
    setEditable(false);
    setParcelAddFlag(parcelAddFlag);
    if (newParcelTab && !parcelAddFlag) setKey("newparcel");
    else setKey("summary");
  }, [window.location.pathname]);

  useEffect(() => {
    //making empty parcel list
    if (orderEvent === "add") {
      dispatch(parcelListHandler([]))
      setOrderController({ orderConflicts: false, pdfDoc: false });
      setParcelController({
        ...parcelState,
        orderData: parcelController.orderData,
      });
    }
  }, [orderEvent]);

  //after changing path
  useEffect(() => {
    setParcelController({
      ...parcelController,
      parcelConflicts: false,
      orderData: {},
    });
  }, [window.location.pathname]);

  //getting status api
  const [orderStatusApi, orderStatusResult] = useOrderStatusListPostMutation();
  //update conflicts info
  const [validateConflicts, validateConflictsResult] =
    useValidateConflictsMutation();

  //update parcel coments info
  const [updateParcelComments, updateParcelCommentsResult] =
    useUpdateParcelCommentsMutation();
  //update status base on conflict for auto update status info
  const [autoStatusUpdate, autoUpdateStatusResult] =
    useAutoConflictsStatusUpdateMutation();

  const [autoStatusUpdate2, autoStatusUpdate2Result] =
    useAutoConflictsStatusUpdateMutation();

  //update status base on conflict for auto update status info
  const [autoStatusUpdateRestart, autoStatusUpdateRestartResult] =
    useAutoConflictsStatusUpdateMutation();

  //getting Delete order api
  const [orderDelete, orderDeleteResult] = useDeleteOrderMutation();

  //order parcel clone  m
  const [parcelClone, parcelCloneResult] = useCloneParcelFromOrderMutation();

  const [checkPrelimanryStatusApi, checkPrelimanryStatusResult] =
    useCheckPrelimanryStatusMutation();

  function storeCertificateInDb() {
    parcelStatusList({ id: editId, token });
  }

  useEffect(() => {
    if (autoStatusUpdateRestartResult.isSuccess) {
      //save certificate in db
      //store certificate in db
      checkPrelimanryStatusApi({ order_id: editId, token });
    }
    //global spinner
    setGlobalVal({
      ...globalVal,
      loader: autoStatusUpdateRestartResult.isLoading,
    });
  }, [autoStatusUpdateRestartResult.isLoading]);

  useEffect(() => {
    if (autoStatusUpdate2Result.isSuccess) {
      //save certificate in db
      //store certificate in db
      setIsStoreInDB(true);
      checkPrelimanryStatusApi({ order_id: editId, token });
    }
    //global spinner
    setGlobalVal({
      ...globalVal,
      loader: autoStatusUpdate2Result.isLoading,
    });
  }, [autoStatusUpdate2Result.isLoading]);

  useEffect(() => {
    if (checkPrelimanryStatusResult.isSuccess) {
      if (isStoreInDB) {
        setIsDownloadBtn(false);
      } else {
        setIsDownloadBtn(true);
      }

      setTimeout(() => {
        certificate({ id: editId, token, type: isStoreInDB ? "json-info" : "json" });
      }, 600);
    }
  }, [checkPrelimanryStatusResult.isLoading]);

  //useEffect for cloning parcel from order
  useEffect(() => {
    if (parcelCloneResult.isSuccess) {
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: parcelCloneResult.data.message,
      });
      orderEditorApi({ id: orderDATA?.id, token });
      //fecthing again parcelstatus list
      parcelStatusList({ id: editId, token });
    }
    if (parcelCloneResult.isError) {
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description: parcelCloneResult.error?.data?.message,
      });
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: parcelCloneResult.isLoading });
  }, [parcelCloneResult.isLoading]);

  //getting status api
  const [orderStatusUpdate, orderStatusUpdateResult] =
    useOrderStatusUpdateMutation();

  useEffect(() => {
    if (orderDATA?.id === editId) {
      //fecthing again parcelstatus list
      parcelStatusList({ id: editId, token });
      if (orderEvent === "clone") {
        setEditable(true);
      } else if (orderEvent === "edit") {
        setEditable(orderEditableFlag);
      } else if (orderEvent === "afterAddOrder") {
        setEditable(orderEditableFlag);
        //after add order event
        // validateConflicts({
        //   data: { order_id: editId },
        //   token,
        // });
      }
      // orderStatusResult?.refetch();
      setEdiStatusShow(false);
      //getting pacels list

      //calling api
      setAutomationProcess(false);

      setParcelController({
        ...parcelController,
        isAutomationRunning: false,
        parcelTwoView: false,
        parcelConflicts: false,
      });
      //geting conflicts
      // conflicts({ data: { order_id: editId }, token });
      setOrderController({ orderConflicts: false, pdfDoc: false });
    }
  }, [orderDATA?.id, editId]);



  useEffect(() => {
    setStatus(orderStatus);
  }, [orderStatus]);

  useEffect(() => {
    if (orderStatusResult?.isSuccess) {
      setStatusOptionsData(orderStatusResult?.data.data);
      setParcelController({
        ...parcelController,
        orderTwoView: false,
        parcelTwoView: false,
      });
    } else {
      setStatusOptionsData([]);
    }
  }, [orderStatusResult?.isLoading]);

  // useEffect(() => {
  //   if (autoUpdateStatusResult.isSuccess) {
  //     setTimeout(() => {
  //       storeCertificateInDb();
  //     }, 5000);
  //   }
  //   //global spinner
  //   setGlobalVal({ ...globalVal, loader: autoUpdateStatusResult.isLoading });
  // }, [autoUpdateStatusResult.isLoading]);

  ///order status
  useEffect(() => {
    if (orderStatusUpdateResult.isSuccess) {
      //refrcth Order api
      //when the status is tax update
      if (String(orderStatus) === "13") {
        orderEditorApi({ id: orderDATA?.id, token });
      }
      if (String(orderStatus) !== "13") {
        // setTimeout(() => {

        // }, config.validationDelay);
        validateConflicts({
          data: { order_id: editId },
          token,
        });
      }

      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: orderStatusUpdateResult.data.message,
      });
      setEdiStatusShow(false);
    } else if (orderStatusUpdateResult.isError) {
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description: orderStatusUpdateResult.error?.data?.message,
      });
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: orderStatusUpdateResult.isLoading });
  }, [orderStatusUpdateResult.isLoading]);

  ///conflicts status
  useEffect(() => {
    if (conflictsResult.isSuccess) {
      const newData = conflictsResult.data.data;
      setOrderConflicts(newData);
      // let isError = newData.filter(({ is_fixed }) => {
      //   return !is_fixed;
      // });

      // if (isError.length) {
      //   setConflictsNotification(true);
      // } else {
      //   setConflictsNotification(false);
      // }
    }
    if (conflictsResult.isError) {
      setOrderConflicts([]);
      // setConflictsNotification(false);

    }

    //global spinner
    setGlobalVal({ ...globalVal, loader: conflictsResult.isLoading });
  }, [conflictsResult.isLoading]);

  ///order delete
  useEffect(() => {
    if (orderDeleteResult.isSuccess) {
      notification.open({
        type: "success",
        style: { color: "green", marginTop: "50px" },
        description: orderDeleteResult.data.message,
      });
      //navigate to dashboard
      navigate("/order");
    } else if (orderDeleteResult.isError) {
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description: orderDeleteResult.error?.data?.message,
      });
    }
    //global spinner
    setGlobalVal({ ...globalVal, loader: orderDeleteResult.isLoading });
  }, [orderDeleteResult.isLoading]);

  // useEffect for countylist based on state selection
  useEffect(() => {
    if (validateConflictsResult.isLoading === true) {
      setValidateConflictsRunnig(true)
    }
    if (validateConflictsResult.isSuccess) {
      setConflictProcess(true)
      conflicts({ data: { order_id: editId }, token });
      downloadCertificate()

      // setTimeout(() => {
      //   setIsDownloadBtn(false);
      //   setIsStoreInDB(true);
      //   //run new api
      //   // updateCalculationsApi({ order_id: editId, token })
      //   //geting conflicts
      //   // conflicts({ data: { order_id: editId }, token });
      //   // updateParcelComments({ data: { order_id: editId }, token });

      //   //auto update status
      //   // autoStatusUpdate({ data: { order_id: editId }, token, isManual: validateConflictsResult?.data?.isManual });
      // }, 5000);
    }

    if (validateConflictsResult.isError) {
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description:
          validateConflictsResult.error?.data?.message ||
          validateConflictsResult.error.error ||
          validateConflictsResult.error.data?.msg.sqlMessage,
      });
      setValidateConflictsRunnig(false)
    }

  }, [validateConflictsResult.isLoading]);

  const apiCalljuriAutomationDocsUpdate = async (array) => {
    await axios.all(
      array.map(
        async (ele) =>
          await axios.post(
            `${config.url}/add-parcel-documents`,
            {
              document_list: ele.supporting_files,
              parcel_id: ele.parcel_id,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
      )
    );
  };

  const apiCalljuriAutomationUpdate = async (array) => {
    await axios
      .all(
        array.map(
          async (ele) =>
            await axios.post(
              `${config.url}/update-parcel-jurisdiction`,
              {
                taxes: ele?.result?.taxes,
                parcel_id: ele?.parcel_id,
                priorYears: ele?.result?.priorYears,
                DeliquentTax: ele?.result?.delinquentStatus,
                automation_tax_error: ele?.result?.taxError,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            )
        )
      )
      .then((data) => {
        //fecthing again parcelstatus list
        parcelStatusList({ id: editId, token });
        // reviewConflicts({
        //   data: { user_id: user?.user_id },
        //   id: editId,
        //   token,
        // });
        setAutomationProcess(false);
        setParcelController({
          ...parcelController,
          isAutomationRunning: false,
        });
      });
  };

  const apiCallAutomationStatus = async (array, refecth) => {
    await axios
      .all(
        array.map(
          async (ele) =>
            await axios.post(
              `${config.url}/update-parcel-automation/${ele.parcel_id}`,
              { automation_status_id: ele.OrderStatus },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            )
        )
      )
      .then((data) => {
        if (refecth) {
          //fecthing again parcelstatus list
          parcelStatusList({ id: editId, token });
          // reviewConflicts({
          //   data: { user_id: user?.user_id },
          //   id: editId,
          //   token,
          // });
        }
      });
  };

  var testCalls = 0;
  const apiCall = async (array) => {
    setAutomationProcess(true);
    setParcelController({
      ...parcelController,
      isAutomationRunning: true,
    });
    //axios request
    await axios
      .all(
        array.map(
          async (ele) =>
            await axios.get(`${config.url}/get-order-status/${ele}`, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            })
        )
      )
      .then((data) => {
        testCalls = testCalls + 1;
        const refData = data.map((ele) => {
          const orderId = ele.config.url.split("/");
          const { result, supporting_files, OrderStatus, parcel_id } =
            ele.data.data;

          let docs = supporting_files?.map(({ file, file_name }) => {
            return {
              document_classification_id: 2,
              document: `data:application/pdf;base64,${file}`,
              name: file_name + "_" + dateFun("datetime"),
              type: "application/pdf",
            };
          });

          return {
            refno: Number(orderId[orderId.length - 1]),
            supporting_files: docs?.length ? docs : null,
            result,
            OrderStatus,
            parcel_id: parcel_id,
          };
        });

        //make req for failed
        const filterFailDatareq = refData?.filter((ele) => {
          return ele.OrderStatus === "4";
        });

        if (filterFailDatareq.length) {
          apiCallAutomationStatus(filterFailDatareq);
          apiCalljuriAutomationUpdate(filterFailDatareq);
        }
        //make req for success
        const filterDatareq = refData?.filter((ele) => {
          return ele.OrderStatus === "3";
        });

        if (filterDatareq.length) {
          apiCallAutomationStatus(filterDatareq);
          apiCalljuriAutomationUpdate(filterDatareq);
          apiCalljuriAutomationDocsUpdate(filterDatareq);
        }

        //make req for Inprogress
        const filterProgressDatareq = refData?.filter((ele) => {
          return ele.OrderStatus === "2";
        });
        //when we don/t have the inprogress status
        if (!filterProgressDatareq.length) {
          setTimeout(() => {
            validateConflicts({
              data: { order_id: editId },
              token,
            });
          }, config.validationDelay);
        }

        if (filterProgressDatareq.length && testCalls <= 6) {
          setTimeout(
            () => {
              apiCall(
                filterProgressDatareq.map((ele) => {
                  return ele.refno;
                })
              );
            },
            testCalls <= 3 ? 20000 : 10000
          );
        } else if (filterProgressDatareq.length) {
          apiCalljuriAutomationUpdate(
            filterProgressDatareq.map(({ OrderStatus, ...other }) => {
              return {
                OrderStatus: 4,
                ...other,
                result: { taxError: "Automation Timed Out!" },
              };
            })
          );
          apiCallAutomationStatus(
            filterProgressDatareq.map(({ OrderStatus, ...other }) => {
              return {
                OrderStatus: 4,
                ...other,
                result: { taxError: "Automation Timed Out!" },
              };
            }),
            true
          );
          //automation run validation
          setTimeout(() => {
            validateConflicts({
              data: { order_id: editId },
              token,
            });
          }, config.validationDelay);
        }
      })
      .catch((error) => {
        setAutomationProcess(false);
        setParcelController({
          ...parcelController,
          isAutomationRunning: false,
        });
      });
  };

  useEffect(() => {
    if (parcelStatusListResult.isSuccess) {
      const dataList = parcelStatusListResult.data.data;
      dispatch(parcelListHandler(dataList.map(({ cad_property_id, ...rest }, i) => ({ ...rest, cad_property_id: `${cad_property_id}(${i + 1})` }))))

      //global spinner
      //global spinner
      const findFailed = dataList?.filter(
        ({ automation_status_id, automation_ref_no }) =>
          automation_status_id === 4 || !automation_ref_no
      );

      if (findFailed?.length) {
        setshowAutomationBtn(true);
      } else {
        setshowAutomationBtn(false);
      }
      //request for multiple api
      const apiData = [];
      dataList?.map(({ automation_status_id, automation_ref_no }) => {
        if (automation_status_id === 2) {
          apiData.push(automation_ref_no);
        }
      });
    } else if (parcelStatusListResult.isError) {
      dispatch(parcelListHandler([]))
    }

    //global spinner
    setGlobalVal({
      ...globalVal,
      loader: parcelStatusListResult.isLoading,
      orderCertificate: !globalVal.orderCertificate,
    });
  }, [parcelStatusListResult.isLoading]);


  const addNewParcel = () => {
    setNewParcelTabFlag(true);
    setKey("new_parcel");
  };

  //const tab change handler
  const tabChangeHandler = (value) => {
    setKey(value);
    const parcelData = parcelList.find(({ cad_property_id }) => cad_property_id === value)
    if (parcelData) {
      // console.log("parcel id changed", parcelData)
      parcelInfo({
        data: {
          parcel_id: parcelData.id,
          year: parcelData.parcel_year,
        },
        token,
      }).then(({ data }) => {
        if (data) {
          const parcel_id = data?.data?.id
          getParcelStatusInfo({ token, parcel_id });
          parcelDocumentCountApi({ token, parcel_id });
          parcelHoaList({ token, parcel_id })
          dispatch(addParcelIdHandler(parcel_id))
        }
      })
    }
    if (value === "summary") {
      parcelStatusList({ id: editId, token });
      if (orderContorller.orderConflicts) {
        conflicts({ data: { order_id: editId }, token });
      }
    }
  };

  //deleteorder
  const deleteOrderHandler = () => {
    //calling delete api
    orderDelete({ order_id: [editId], token });
  };

  //useEefect 
  useEffect(() => {
    if (orderEditorResult.isSuccess) {
      setConflictsNotification(!orderEditorResult?.data?.isConflictFixed);
    }
  }, [orderEditorResult.isLoading])

  useEffect(() => {
    if (key === "summary" && !bakendAutomation) {
      mutateAsyncCallBakendAutomation(editId).then(({ data }) => {
        if (data?.data) {
          dispatch(certificateDownloadLoadingHandler(true))
        }

      })
    }
  }, [bakendAutomation, key, editId])


  useEffect(() => {
    if (key === "summary") {
      setNewParcelTabFlag(false);

      setViewParcelData(false);
    }
    ///default mode pr parcel controller
    setParcelController({
      ...parcelController,
      mode: "view",
      key: key,
      refetch: false,
    });
    if (key !== "summary" && parcelList.length <= 5) {
      const data = parcelList.filter(({ cad_property_id }) => {
        return cad_property_id === key;
      });
      setViewParcelData(data[0]);
      setParcelController({
        ...parcelController,
        storeCertificateInDbFun: storeCertificateInDb,
      });
    }
  }, [key]);

  //fetch history auditlogs
  function fecthingAuditHistoryLogs() {
    auditLogsListCall({ id: editId, token });
  }

  //call back to function
  const parcleListComp = useCallback(() => {
    return (
      <div
        className={`row mt-3 mb-2 mt-2${orderContorller?.orderTwoView ? "bg-white border" : null
          }`}
      >
        {parcelList.map((ele, index) => {
          return (
            <div key={ele.id} className="col-12 col-md-6 my-2">
              <ParcelCard
                hoaData={ele}
                number={ele.cad_property_id}
                parcel={index + 1}
                status={ele.status}
                automation={ele.automation_status_id}
              />
            </div>
          );
        })}
      </div>
    );
  }, [parcelStatusListResult.isLoading, parcelList, parcelSwitchFlag]);

  useEffect(() => {
    if (orderContorller.pdfDoc) {
      scrollComp("embed");
    }
  }, [orderContorller.pdfDoc]);

  const conflictsComp = useCallback(() => {
    if (conflictsResult.isLoading) {
      return null
    }
    return (
      <Conflicts
        onchek={(value) => {
          reviewConflicts({
            data: { user_id: user?.user_id, order_id: editId, id: value },
            token,
          });
        }}
        viewPdfDocument={viewPdfDocument}
        onclose={(value, type) => {
          if (value) {
            dispatch(viewPdfDocuementHandler(null))
          }
          setOrderController({
            ...orderContorller,
            ...(value ? { orderTwoView: orderContorller.orderConflicts ? true : false, } : { orderConflicts: viewPdfDocument && value ? true : false, orderTwoView: viewPdfDocument }),

          });
        }}
        isconflicts={orderContorller.orderConflicts && orderContorller?.orderTwoView}
        conflicts={orderConflicts}
        orderContorller={orderContorller}
      />
    );
  }, [
    viewPdfDocument,
    orderContorller,
    orderContorller.orderConflicts,
    orderContorller?.orderTwoView,
    JSON.stringify(orderConflicts),
    !conflictsResult.isLoading,
    !parcelStatusListResult.isLoading,
  ]);

  //start initialization
  const startAutmation = async () => {
    const list = parcelList.filter(
      ({ automation_ref_no, automation_status_id }) => {
        return !automation_ref_no || automation_status_id === 4;
      }
    );
    if (list.length) {
      setAutomationProcess(true);
      setParcelController({ ...parcelController, isAutomationRunning: true });
      await axios
        .all(
          list.map(
            async (ele) =>
              await axios.post(
                `${config.url}/initiate-automation`,
                { order_id: editId, parcel_id: ele.id },
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
          )
        )
        .then(() => {
          //fecthing again parcelstatus list
          setTimeout(() => {
            parcelStatusList({ id: editId, token });
          }, 3000);
        })
        .finally(() => {
          setAutomationProcess(false);
          setParcelController({
            ...parcelController,
            isAutomationRunning: false,
            orderTwoView: parcelController.orderTwoView,
          });
        });
    } else {
      notification.open({
        type: "error",
        style: { color: "red", marginTop: "50px" },
        description:
          "Only for not initiated or faild parcels, automation will start!",
      });
    }
  };


  useEffect(() => {
    if (orderDATA?.hoa_status_id !== 3 && orderDATA?.parcel_hoa_status) {
      autoStatusUpdate2({ data: { order_id: editId }, token })
    }
  }, [orderDATA?.hoa_status_id, orderDATA?.parcel_hoa_status])
  // console.log(orderDATA?.hoa_status_id, orderDATA?.parcel_hoa_status, "autoStatusUpdate")
  const SendEmailComp = () => {
    let disable = parcelController?.orderData?.order_doc_list?.filter(
      ({ document_classification_id }) => document_classification_id === 3
    );


    return (
      <div
        className={`add_icon ${(!disable?.length || bakendAutomation || conflictsProcess) ? "border-secondary" : null}`}
        onClick={() => {
          if (disable?.length && !bakendAutomation && !conflictsProcess) {
            // sendEmailCertificate({
            //   order_id: editId,
            //   token,
            // });
            getCloserEmail({ order_id: editId, token });
          }
        }}
      >
        <HiOutlineMail color={(!disable?.length || bakendAutomation || conflictsProcess) ? "#808080" : "#0d6efd"} />
      </div>
    );
  };

  //status comperision
  const isCompleteOrder =
    orderDATA?.status_id === 6 ||
    orderDATA?.status_id === 15;

  return (
    <parcelContext.Provider
      value={{
        controller: [parcelController, setParcelController],
        orderContorllerState: [orderContorller, setOrderController],
        confilctsDataRefecth: confilctsRefecth,
      }}
    >


      <div className="order">
        <ViewParcel
          onselect={(values) => console.log(values)}
          open={open}
          onChange={(value) => {
            const parcelData = parcelList.find(({ cad_property_id }) => cad_property_id === value?.cad_property_id)
            if (parcelData) {
              // console.log("parcel id changed", parcelData)
              parcelInfo({
                data: {
                  parcel_id: parcelData.id,
                  year: parcelData.parcel_year,
                },
                token,
              }).then(({ data }) => {
                if (data) {
                  const parcel_id = data?.data?.id
                  getParcelStatusInfo({ token, parcel_id });
                  parcelDocumentCountApi({ token, parcel_id });
                  parcelHoaList({ token, parcel_id })
                  dispatch(addParcelIdHandler(parcel_id))
                }
              })
            }
            const { cad_property_id, ...data } = value;
            setViewParcelData({ cad_property_id, ...data });

            setKey(key === "parcel_1" ? "parcel_2" : "parcel_1");
          }}
          setOpen={setOpen}
          list={parcelList}
          parcelStatusListRefetch={parcelStatusList}
        />

        {/* //order info  */}
        <AppModal
          width={"80%"}
          showModal={orderInfoCopyModal}
          onClose={setOrderInfoCopyModal}
          element={<OrderInfoCopyModal />}
        />
        {/* //xmlData  */}
        <AppModal
          width={"80%"}
          showModal={xmlData}
          onClose={setXmlData}
          element={<XmlDataFile data={xmlData} />}
        />

        {/* //Email Modal  */}
        <AppModal
          width={"50%"}
          showModal={showEmailModal}
          onClose={setShowEmailModal}
          element={
            <EmailModal
              afterSubmit={setShowEmailModal}
              refetchApi={() => orderEditorApi({ id: orderDATA?.id, token })}
              email={showEmailModal?.closer_email}
              showCheckBox={showEmailModal?.order_status_flag || false}
              options={showEmailModal?.user_closer_list || []}
              random={randomPass.randomPassword({
                length: 9,
                characters: randomPass.digits,
              })}
              editId={editId}
            />
          }
        />

        {/* //history  */}
        <AppModal
          width={"85%"}
          onClose={(value) => {
            setShowAuditLogModal(value);
          }}
          showModal={showAuditLogModal}
          // afterClose={() => {
          //   setShowAuditLogModal(!showAuditLogModal);
          // }}
          element={
            <div className="p-3">
              <AuditLogs title="Order" data={auditLogData} />
            </div>
          }
        />

        {window.location.pathname.split("/")[1] === "order-editor" &&
          !window.location.pathname.split("/")[2] && (
            <Tabs
              activeKey={key}
              className="title-container app-tab"
              defaultActiveKey="summary"
              onChange={tabChangeHandler}
              tabBarExtraContent={
                editId && (
                  <div className="ant-tabs-nav-wrap py-3 d-flex align-items-center">
                    <span className=" text-success fw-bold fs-12 h-100">
                      {orderNumber}
                    </span>
                    <Tooltip
                      overlayInnerStyle={{
                        fontSize: 10,
                      }}
                      placement="top"
                      title={"Order Summary"}
                    >
                      <div
                        onClick={() => {
                          setOrderInfoCopyModal(true)
                          parcelStatusList({ id: editId, token });
                        }}
                        className="add_icon ms-2"
                      >
                        <MdOutlinePreview color="#0d6efd" />
                      </div>
                    </Tooltip>
                  </div>
                )
              }
            >
              <TabPane tab="Summary" key={"summary"}>
                <>
                  {/* //showing import order record  */}

                  {((showToollBar && orderEditableFlag) ||
                    orderEvent === "afterAddOrder") && (
                      <div className="row mb-2 bg-white p-2">
                        <div className="col-12">
                          <div className="d-flex flex-wrap align-items-center justify-content-between">
                            <div className="text-center ms-2 fs-11 text-dark d-flex gap-2 align-items-center">
                              <label
                                htmlFor="status"
                                className="text-dark fw-bold fs-6 m-0"
                              >
                                Tax Status : {orderStatusResult?.isLoading ? <span className="fs-11 text-secondary">Loading...</span> : ""}
                              </label>
                              <div>
                                {orderStatus && !orderStatusResult?.isLoading && (
                                  <AppSelect
                                    // isEdit={editId}
                                    classStyle={`${editable || ediStatusShow
                                      ? "border px-2 py-1  "
                                      : "form-control border-0 bg-white"
                                      }`}
                                    //   defaultSet={afterClose}

                                    name="status"
                                    disabled={editable ? false : !ediStatusShow}
                                    onChange={(e, value) => {
                                      setOrderStatus(value);
                                    }}
                                    value={bakendAutomation ? "11" : (orderStatus || "")}
                                    options={
                                      <>
                                        {statusOptionsData
                                          .filter((ele) => {
                                            if (isCompleteOrder && !bakendAutomation) {
                                              return (
                                                ele.id === 6 ||
                                                ele.id === 11 ||
                                                ele.id === 13 ||
                                                ele.id === 15
                                              );
                                            } else {
                                              return ele;
                                            }
                                          })
                                          ?.map((ele) => {
                                            return (
                                              <option key={ele.id} value={ele.id}>
                                                {ele.name}
                                              </option>
                                            );
                                          })}
                                      </>
                                    }
                                  />
                                )}
                              </div>
                              {ediStatusShow && !editable ? (
                                <>
                                  <button
                                    onClick={() => {
                                      orderStatusUpdate({
                                        status_id: status,
                                        id: editId,
                                        token,
                                      });
                                    }}
                                    className=" border bg-primary  fs-12 text-white"
                                  >
                                    Update
                                  </button>
                                  <button
                                    onClick={() => {
                                      setEdiStatusShow(false);
                                      setOrderStatus(
                                        parcelController.orderData.status_id
                                      );
                                    }}
                                    className=" border fs-12 text-dark"
                                  >
                                    Cancel
                                  </button>
                                </>
                              ) : (
                                !editable && (
                                  <FiEdit
                                    onClick={() => {
                                      setEdiStatusShow(true);
                                    }}
                                    size={12}
                                    className="mt-1"
                                    cursor="pointer"
                                  />
                                )
                              )}
                              {(conflictsNotification ||
                                bakendAutomation) &&
                                orderEvent !== "add" && (
                                  <div>
                                    <h6
                                      className={`animate-pop fs-10 mb-0 d-inline-block ${bakendAutomation
                                        ? "text-orange"
                                        : ""
                                        }`}
                                    >
                                      {bakendAutomation
                                        ? "Backend automation running!"
                                        : "Please Review the conflicts"}
                                      {/* {parcelController?.isAutomationRunning
                                        ? "Waiting for automation"
                                        : "Please Review the conflicts"} */}
                                    </h6>
                                  </div>
                                )}
                              {/* //order status I am using as edit values are loaded
                            or not */}
                              {orderStatus &&
                                !bakendAutomation &&
                                !conflictsNotification &&
                                orderEvent !== "add" && (
                                  <div>
                                    <h6 className="fs-10 mb-0 d-inline-block fw-bold text-success">
                                      No conflicts found
                                    </h6>
                                  </div>
                                )}
                            </div>
                            <div className="action-buttons flex-wrap d-flex align-items-center gap-2 fw-bold">

                              {/* // StopAutomation */}
                              <StopAutomationComp callback={() => {
                                validateConflicts({
                                  data: { order_id: editId },
                                  token,
                                })
                              }
                              } />
                              {/* // deliver/email */}

                              {newInterNalOrder?.DeleiverEmail?.length > 0 &&
                                <OrderBtns title={`${newInterNalOrder?.DeleiverEmail?.includes("Deliver") && orderDATA?.is_integration > 0 && isCompleteOrder ? "Deliver/" : ""} Email`}>
                                  {orderDATA?.is_integration > 0 && newInterNalOrder?.DeleiverEmail?.includes("Deliver") && isCompleteOrder && <div
                                    onClick={() => {
                                      if (orderDATA?.is_integration <= 3 || orderDATA?.is_integration === 5) {
                                        deliverCertificateToSoftPro({
                                          order_id: editId,
                                          token,
                                          type: orderDATA?.is_integration === 3 ? "ramquest" : orderDATA?.is_integration === 2 ? "resware" : orderDATA?.is_integration === 5 ? "unitedtax" : "softpro",
                                        });
                                      } else if (orderDATA?.is_integration === 4) {
                                        deliverCertificateToQualia({
                                          values: {
                                            // integration_order_id_no:
                                            //   parcelController?.orderData
                                            //     ?.integration_order_id_no,
                                            order_id:orderDATA?.id
                                          },
                                          token,
                                        });
                                      }
                                    }}
                                    className={`add_icon`}
                                  >
                                    <AiOutlineDeliveredProcedure
                                      color={"#0d6efd"}
                                    />
                                  </div>}
                                  {newInterNalOrder?.DeleiverEmail?.includes("Email") && <SendEmailComp />}
                                </OrderBtns>
                              }
                              {/* // AutomationSend */}
                              {newInterNalOrder?.AutomationSend?.length > 0 &&
                                <OrderBtns title={`${orderDATA?.is_integration > 0 && newInterNalOrder?.AutomationSend?.includes("Automation") ? "Automation" : ""}${newInterNalOrder?.AutomationSend?.includes("Send") ? `${orderDATA?.is_integration > 0 && newInterNalOrder?.AutomationSend?.includes("Automation") ? "/" : ""}Send Order` : ""}`}>
                                  {orderDATA?.is_integration > 0 && newInterNalOrder?.AutomationSend?.includes("Automation") && <>{automationProcess ? <Spin
                                    indicator={
                                      <LoadingOutlined
                                        style={{
                                          fontSize: 24,
                                        }}
                                        spin
                                      />
                                    }
                                  /> : <div
                                    onClick={!taxProduct || bakendAutomation
                                      ? undefined
                                      : startAutmation
                                    }
                                    className={`${!taxProduct || bakendAutomation
                                      ? "border-secondary"
                                      : null
                                      } add_icon`}
                                  >
                                    <MdOutlineSettings
                                      color={!taxProduct || bakendAutomation
                                        ? "#808080"
                                        : "#0d6efd"
                                      }
                                    />
                                  </div>}</>}

                                  {newInterNalOrder?.AutomationSend?.includes("Send") && <div
                                    onClick={() =>
                                      sendOrderToFastrack({
                                        order_id: editId,
                                        token,
                                      })
                                    }
                                    className={`border add_icon  border-${"primary"}`}
                                  >
                                    <MdOutlineSendAndArchive
                                      color={"#3388FF"}
                                    />
                                  </div>}
                                </OrderBtns>
                              }

                              {/* //get json data  */}

                              {/* InputHistory  */}
                              {newInterNalOrder?.InputHistory?.length > 0 &&
                                <OrderBtns title={`${newInterNalOrder?.InputHistory?.includes("Input") ? "Input/" : ""}History`}>
                                  {newInterNalOrder?.InputHistory?.includes("Input") && orderDATA?.is_integration > 0 && orderDATA?.is_integration <= 5 && <div
                                    onClick={async () => {
                                      !ediStatusShow &&
                                        viewXmlDataApi({
                                          token,
                                          order_id: editId,
                                        });
                                    }}
                                    className={`add_icon border-success`}
                                  >
                                    <MdInput color={"green"} />
                                  </div>}
                                  {newInterNalOrder?.InputHistory?.includes("History") && <div
                                    className="add_icon"
                                    onClick={() => {
                                      fecthingAuditHistoryLogs();
                                    }}
                                  >
                                    <AiOutlineAudit
                                      color="#3388FF"
                                      cursor="pointer"
                                    />
                                  </div>}

                                  {newInterNalOrder?.InputHistory?.includes("JSON") && orderDATA?.customer_id === 20 && <div
                                    onClick={async () => {
                                      !ediStatusShow &&
                                        orderJsonData({
                                          token,
                                          order_id: editId,
                                        });
                                    }}
                                    className={`add_icon border-success`}
                                  >
                                    <VscJson color={"green"} />
                                  </div>}
                                </OrderBtns>
                              }
                              {/* Assign  */}
                              {newInterNalOrder?.Assign?.length > 0 &&
                                <OrderBtns title="Assign">
                                  {newInterNalOrder?.Assign?.includes("Assign") && <div
                                    onClick={
                                      () => {
                                        //validatio start
                                        orderExaminerUpdate({
                                          data: { order_id: [editId], examiner_id: user?.user_id },
                                          token,
                                        });
                                      }
                                    }
                                    className={`border add_icon  border-primary`}
                                  >
                                    <MdOutlineAssignmentInd
                                      color={"#3388FF"}
                                      cursor="pointer"
                                    />
                                  </div>}
                                </OrderBtns>
                              }
                              {/* Download/View  */}
                              {newInterNalOrder?.DownloadView?.length > 0 &&
                                <OrderBtns title="Download/View">
                                  {newInterNalOrder?.DownloadView?.includes("Download") && <div
                                    onClick={async () => {
                                      !ediStatusShow && !bakendAutomation && downloadCertificate(true);
                                    }}
                                    className={` add_icon  border-${!bakendAutomation
                                      ? "primary"
                                      : "secondary"
                                      } ${isDownloadingCertificate ? " " : "border"}`}
                                  >
                                    {isDownloadingCertificate && !conflictsProcess ? <Spin
                                      indicator={
                                        <LoadingOutlined
                                          style={{
                                            fontSize: 24,
                                          }}
                                          spin
                                        />
                                      }
                                    /> : <FiDownload color={
                                      !bakendAutomation
                                        ? "#3388FF"
                                        : "#808080"
                                    } />}

                                  </div>}

                                  {newInterNalOrder?.DownloadView?.includes("View") && <div
                                    onClick={() => {
                                      if (orderDATA?.order_doc_list
                                        ?.length !== 0) {
                                        const certificate =
                                          orderDATA?.order_doc_list.find(
                                            ({
                                              latest,
                                            }) =>
                                              latest === true
                                          );
                                        if (certificate) {
                                          setGlobalVal({ ...globalVal, loader: true });
                                          orderDocumentDownload({ id: certificate?.id, token }).then((orderDocumentDownloadResult) => {
                                            if (orderDocumentDownloadResult?.data) {
                                              dispatch(viewPdfDocuementHandler(orderDocumentDownloadResult?.data?.data))
                                            }
                                            setOrderController({ ...orderContorller, orderTwoView: true });
                                          }).finally(() => {
                                            setGlobalVal({ ...globalVal, loader: false });
                                          })
                                        }
                                      }
                                    }}
                                    className={`${orderDATA?.order_doc_list
                                      ?.length === 0 ? "border-secondary" : null
                                      } add_icon`}
                                  >
                                    <BsFilePdf
                                      color={orderDATA?.order_doc_list
                                        ?.length === 0 ? "#808080" : "#0d6efd"}
                                    />
                                  </div>}
                                </OrderBtns>
                              }
                              {/* validation  */}
                              {newInterNalOrder?.Validation?.length > 0 &&
                                <OrderBtns title="Validation/Conflicts">
                                  {newInterNalOrder?.Validation?.includes("Validation") && !conflictsProcess && !validateConflictsRunnig && <div
                                    onClick={
                                      !bakendAutomation
                                        ? () => {
                                          setValidateConflictsRunnig(true)
                                          setConflictProcess(true)
                                          //validatio start
                                          validateConflicts({
                                            data: { order_id: editId, isManual: true },
                                            token,
                                          });
                                        }
                                        : undefined
                                    }
                                    className={`border add_icon  border-${!bakendAutomation
                                      ? "primary"
                                      : "secondary"
                                      }`}
                                  >
                                    <VscRunErrors
                                      color={
                                        !bakendAutomation
                                          ? "#3388FF"
                                          : "#808080"
                                      }
                                    // color={"#3388FF"}
                                    />
                                  </div>}
                                  {newInterNalOrder?.Validation?.includes("Conflicts") && <>
                                    {conflictsProcess ? <Spin
                                      indicator={
                                        <LoadingOutlined
                                          style={{
                                            fontSize: 24,
                                          }}
                                          spin
                                        />
                                      }
                                    />
                                      : <div
                                        onClick={async () => {

                                          if (!orderContorller.orderConflicts) {
                                            conflicts({ data: { order_id: editId }, token })
                                          }
                                          // setParcelController({
                                          //   ...parcelController,
                                          //   orderTwoView: orderContorller.pdfDoc
                                          //     ? true
                                          //     : !parcelController.orderTwoView,
                                          // });
                                          setOrderController({
                                            ...orderContorller,
                                            orderConflicts: true,
                                            orderTwoView: true
                                          });
                                        }}
                                        className="add_icon border-danger"
                                      >
                                        <CgViewSplit color="red" />
                                      </div>}
                                  </>}
                                </OrderBtns>
                              }
                              {/* DeleteEdit  */}
                              {newInterNalOrder?.DeleteEdit?.length > 0 &&
                                <OrderBtns title="Delete/Edit">
                                  {newInterNalOrder?.DeleteEdit?.includes("Delete") && <Popconfirm
                                    getPopupContainer={(trigger) =>
                                      trigger.parentElement
                                    }
                                    title="Are you sure you want to delete？"
                                    onConfirm={deleteOrderHandler}
                                    placement="bottom"
                                    icon={
                                      <QuestionCircleOutlined
                                        style={{
                                          color: "red",
                                        }}
                                      />
                                    }
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <div className="add_icon ">
                                      <MdDeleteOutline color="#0d6efd" />
                                    </div>
                                  </Popconfirm>}

                                  {newInterNalOrder?.DeleteEdit?.includes("Edit") && <>{!editable ? <div
                                    className={`border add_icon  border-primary`}
                                    onClick={() => {
                                      setEditable(true)
                                      setOrderEvent("edit")

                                    }
                                    }
                                  >
                                    <AiOutlineEdit
                                      color={"#3388FF"}
                                    />
                                  </div> : <div
                                    className="add_icon "
                                    onClick={() => {
                                      setEditable(false)
                                      setOrderEvent("view")
                                      orderEditorApi({ id: orderDATA?.id, token });
                                    }}
                                  >
                                    <CloseOutlined color="#0d6efd" />
                                  </div>}</>}
                                </OrderBtns>
                              }
                              {/* //add/clone  */}
                              {newInterNalOrder?.AddClone?.length > 0 &&
                                <OrderBtns title={`Add/Clone${parcelList?.length > 5 ? "/View Parcel's" : ""}`}>
                                  {newInterNalOrder?.AddClone?.includes("Add") && <div
                                    className={`${!orderDATA?.county_id
                                      ? "border-secondary"
                                      : null
                                      } add_icon`}
                                    onClick={
                                      !orderDATA?.county_id
                                        ? undefined
                                        : addNewParcel
                                    }
                                  >
                                    <FaPlus
                                      color={
                                        !orderDATA?.county_id
                                          ? "#808080"
                                          : "#0d6efd"
                                      }
                                    />
                                  </div>}
                                  {newInterNalOrder?.AddClone?.includes("Clone") && <OrderList
                                    isCompleteOrder={isCompleteOrder}
                                    title={""}
                                    icon={
                                      <FaRegClone
                                        color={"#3388FF"}
                                      />
                                    }
                                    onChange={(cloneOrderId) => {
                                      //will get ordder id from cliked list
                                      parcelClone({
                                        cloneOrderId,
                                        currentOrderId: editId,
                                        token,
                                      });
                                    }
                                    }
                                  />}
                                  {newInterNalOrder?.AddClone?.includes("View") && parcelList?.length > 5 && <div className="add_icon">
                                    <AiOutlineEye
                                      onClick={() => setOpen(true)}
                                      color="#0d6efd"
                                    />
                                  </div>}
                                </OrderBtns>
                              }
                              <GetJsonComp order_id={orderDATA?.id} />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}


                  {!orderDATA?.examiner_id && showalertAssign && assign_me_btn && <div className="d-flex justify-items-end w-full">
                    <AssignmeComp onClick={
                      () => {
                        //validatio start
                        orderExaminerUpdate({
                          data: { order_id: [editId], examiner_id: user?.user_id },
                          token,
                        });
                      }
                    } />
                  </div>}

                  <div className="row align-items-stretch" id="embed">
                    {orderContorller?.orderTwoView && orderEvent !== "add" && (
                      <div className="col-12 col-lg-6 ">
                        {/* <div className="w-25 ms-auto d-flex justify-content-end">
                          <button
                            onClick={() => {
                              setParcelController({
                                ...parcelController,
                                orderTwoView: false,
                              });
                            }}
                            className="btn btn-outline-pirmary border-primary btn-sm mb-2   px-4"
                          >
                            X
                          </button>
                        </div> */}

                        {conflictsComp()}
                      </div>
                    )}

                    <div
                      className={`col-12 col-lg-${orderContorller?.orderTwoView && orderEvent !== "add"
                        ? 6
                        : null
                        }`}
                    >
                      <NewOrder
                        extraData={{ status_id: orderStatus }}
                        setOrderStatus={setOrderStatus}
                        res={res}
                        orderDocumentDownload={orderDocumentDownload}
                        orderDocumentDownloadResult={orderDocumentDownloadResult}
                        editId={editId}
                        orderEditorApi={orderEditorApi}
                        orderEditor={orderEditorResult}
                        conflictsList={orderConflicts}
                        statusOptionsData={statusOptionsData || []}
                        refresh={key}
                        setShowToollBar={setShowToollBar}
                        orderEvent={orderEvent}
                        editable={editable}
                        parcelList={parcelList}
                        setEditable={setEditable}
                        setLoading={setLoading}
                        orderEditableFlag={orderEditableFlag}
                        addCertificateDocumentsResult={
                          addCertificateDocumentsResult
                        }
                        validateConflicts={validateConflicts}
                      />
                    </div>
                  </div>
                </>

                {/* //parcel cards  */}
                {parcleListComp()}
              </TabPane>

              {newParcelTabFlag && (
                <TabPane tab="New Parcel" key="new_parcel">
                  <NewParcel tabKey={setKey} />
                </TabPane>
              )}
              {parcelList.length <= 5 &&
                parcelList?.map((ele, index) => {
                  if (ele.cad_property_id !== key) {
                    return (
                      <TabPane

                        tab={ele.cad_property_id}
                        key={ele.cad_property_id}
                      ></TabPane>
                    );
                  }
                })}
              {/* //parcelList if more than 5 parcels */}
              {viewParcelData && key !== "summary" && key !== "new_parcel" && (
                <TabPane tab={viewParcelData.cad_property_id} key={key}>
                  <ParcelsMain
                    getParcelStatusInfo={getParcelStatusInfo}
                    getParcelStatusInfoResult={getParcelStatusInfoResult}
                    addCertificateDocumentsResult={addCertificateDocumentsResult}
                    SendEmailComp={SendEmailComp}
                    fecthingAuditHistoryLogs={fecthingAuditHistoryLogs}
                    certficateDownload={downloadCertificate}
                    downloadCertificateApiResult={downloadCertificateApiResult}
                    parcelList={parcelList}
                    refresh={key}
                    parcelInfo={parcelInfo}
                    parcelInfoResult={parcelInfoResult}
                    conflicts={parcelConflicts}
                    conflictsResult={parcelConflictsResult}
                    parcelHoaList={parcelHoaList}
                    parcelHoaListResult={parcelHoaListResult}
                    setOpen={setOpen}
                    setNewParcelTabFlag={setNewParcelTabFlag}
                    setKey={setKey}
                    parcelDocumentCountApi={parcelDocumentCountApi}
                    parcelDocumentCountResult={parcelDocumentCountResult}
                    info={viewParcelData}
                    getParcelHoaStatus={getParcelHoaStatus}
                    getParcelHoaStatusResult={getParcelHoaStatusResult}
                    //refetch api for parcel list
                    parcelStatusList={parcelStatusList}
                    validateConflicts={validateConflicts}
                    validateConflictsResult={validateConflictsResult}
                    setConflictProcess={setConflictProcess}
                    conflictsProcess={conflictsProcess}
                  />
                </TabPane>
              )}
            </Tabs>
          )}

        {window.location.pathname === "/order" && <Order />}
      </div>
    </parcelContext.Provider>
  );
};

export default OrderEditor;
