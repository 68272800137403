import { Form, Select, Input, Button, notification } from 'antd'; // Added Spin for loading state
import React, { useState} from 'react';
// import { FaSearch } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useGetCountyListMutation } from '../../../../../Redux/Services/county';
import "./hoa.css"
const { Option } = Select;

const HoaSubDivision = ({ onCallBack }) => {
    const [form] = Form.useForm();
    const [selectedSubd, setSelectedSubd] = useState(null);
    const [countyLoading, setCountyLoading] = useState(false); // Loading state for counties
    const { stateList } = useSelector(state => state.stateList);
    const { token } = useSelector(state => state.auth);
    const [getCountyList, { data: CountyListData, isLoading: CountyListIsLoading, error }] = useGetCountyListMutation();

    const fetchCountyList = async (value) => {
        setCountyLoading(true); // Set loading state
        setSelectedSubd(true); // Reset selected subdivision when state changes
        form.setFieldValue("county_id", null);
        // setCountyLoading(true); // Set loading state
        try {
            await    getCountyList({ state_id: { state_id: value }, token: token });
        } catch (err) {
            notification.error({
                message: 'Error',
                description: 'Failed to fetch county data. Please try again.',
            });
        } finally {
            setCountyLoading(false); // Reset loading state
        }
    };

    const handleSelectChange = (value) => {
        setSelectedSubd(value);
        form.setFieldValue("subdSearch", "");
    };

    const onFinish = (values) => {
        onCallBack(values);
        notification.success({
            message: 'Form submitted',
            description: 'Your form has been submitted successfully!',
        });
    };

    return (
        <Form
            form={form}
            className='d-flex gap-2 justify-content-center align-items-center'
            layout="vertical"
            onFinish={onFinish}
        >
            <Form.Item className='m-0 px-2'
                name="state_id"
                style={{ width: 150 }}
             >
                <Select placeholder="State"
                    onChange={(value) => { fetchCountyList(value); }}>
                    {stateList?.map(object => (<Option key={object.id} value={object?.id}>{object?.state_name}</Option>))}
                </Select>
            </Form.Item>
            <Form.Item className='m-0 pe-2'
                name="county_id"
                style={{ width: 150 }}
    >
                <Select placeholder="County" loading={countyLoading} // Show loading state
                >
                    {CountyListData?.data?.map(object => (<Option key={object.id} value={object?.id}>{object?.county_name}</Option>))}
                </Select>
            </Form.Item>
            {/* Dropdown for SUBD_CODE and SUBD_NAME */}
            {/* <Form.Item
                name="subd"
                className='m-0'
                rules={[{ required: true, message: 'Please select a subdivision!' }]}
            >
                <Select
                    placeholder="Select Subdivision"
                    onChange={handleSelectChange}
                >
                    <Option value="SUBD_CODE">SUBD_CODE</Option>
                    <Option value="SUBD_NAME">SUBD_NAME</Option>
                </Select>
            </Form.Item> */}

            {/* Conditionally show the input and button */}
            {selectedSubd && (
                <div className='d-flex gap-2 align-items-center'>
                    <Form.Item
                        className='m-0'
                        name="subdSearch"
                        rules={[
                            {
                                required: true,
                                message: 'Search value should not be blank!',
                            },
                        ]}
                    >
                        <Input
                            className="global-search"
                            placeholder="Enter subdivison code"
                            // prefix={<FaSearch color="lightgray" />}
                        />
                    </Form.Item>

                    <Form.Item className='m-0'>
                        <Button htmlType="submit" type="primary">
                            <span className="ms-1"> SUBMIT</span>
                        </Button>
                    </Form.Item>
                </div>
            )}
        </Form>
    );
};

export default HoaSubDivision;