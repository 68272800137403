import { createSlice } from "@reduxjs/toolkit";

const vendorCustomerList = createSlice({
  name: "vendorCustomerList",
  initialState: {
    vendorCustomerList: [],
  },
  reducers: {
    vendorCustomerListAction: (state, action) => {
      state.vendorCustomerList = action.payload;
    },
  },
});

export const {vendorCustomerListAction} = vendorCustomerList.actions;

export default vendorCustomerList.reducer;
