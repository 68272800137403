import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json");
export const transactionType = createApi({
    reducerPath: "transactionType",
    tagTypes: ["transactionType"],
    baseQuery: fetchBaseQuery({ baseUrl: config.url }),
    endpoints: (builder) => ({
        transactionTypeList: builder.query({
        query: (token) => ({
          url: `/transaction-type-list`,
          mode: "cors",
          headers: { Authorization: `Bearer ${token}` },
        }),
        providesTags: ["transactionType"],
      }),
      addTransactionType: builder.mutation({
        query: (data) => ({
          url: `/add-transaction-type`,
          mode: "cors",
          method: "POST",
          body: data.data,
          headers: { Authorization: `Bearer ${data.token}` },
        }),
        invalidatesTags: ["transactionType"],
      }),
      updateTransactionType: builder.mutation({
        query: (data) => ({
          url: `/update-transaction-type/${data.id}`,
          mode: "cors",
          method: "POST",
          body: data.data,
          headers: { Authorization: `Bearer ${data.token}` },
        }),
        invalidatesTags: ["transactionType"],
      }),
      deleteTransaction: builder.mutation({
        query: (data) => ({
          url: `/delete-transaction-type/${data.id}`,
          method: "GET",
          mode: "cors",
          headers: { Authorization: `Bearer ${data.token}` },
        }),
         invalidatesTags: ["transactionType"],
      }),
    }),
  });
  
  export const {
    useAddTransactionTypeMutation,
    useUpdateTransactionTypeMutation,
    useTransactionTypeListQuery,
    useDeleteTransactionMutation,

  } = transactionType;
  