import { createSlice } from "@reduxjs/toolkit";

const dashBoardApi = createSlice({
  name: "dashBoardApi",
  initialState: {
    dashBoardApi: [],
  },
  reducers: {
    dashBoardApiAction: (state, action) => {
      state.dashBoardApi = action.payload;
    },
  },
});

export const {dashBoardApiAction} = dashBoardApi.actions;

export default dashBoardApi.reducer;