import { Button } from "antd";
import { useDispatch } from "react-redux";
import { showalertAssignHandler } from "../../../Redux/reducers/Order";

export const AssignmeComp = ({ onClick }) => {
    const dispatch = useDispatch()
    return (
        <div style={{ background: "#FFFBE6", border: "1px solid #FFF0BA" }} className=' p-2 rounded-3 d-inline-block '>
            <div className='d-flex align-items-center gap-2 flex-wrap'>
                <p>Order is not yet assigned. Would you like to assign it yourself?</p>
                <Button
                    onClick={onClick}
                    size="small"
                    type="primary"
                    danger
                    ghost
                >
                    Assign Me
                </Button>
                <Button
                    onClick={() => {
                        dispatch(showalertAssignHandler(false))
                    }}
                    className="ms-2"
                    size="small"
                    type="primary"
                    ghost
                >
                    Decline
                </Button>
            </div>
        </div >
    )
}