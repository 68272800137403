import React from 'react'
import "./hoa-certificate.css"
import { mobileFormat } from '../../../../../../Controllers/Functions'
// import { rawData } from './rawData'
// import { drawDOM, exportPDF } from "@progress/kendo-drawing";
const HoaCertificate = ({ rawData }) => {

    // const content = useRef(null)
    // const exportPDFWithMethod = () => {
    //     let element = content.current;
    //     if (element !== null) {
    //         drawDOM(element, {
    //             paperSize: "A4",
    //             fileName: `testing.pdf`,
    //             margin: {
    //                 top: "1cm",
    //                 left: "0.8cm",
    //                 right: "0.8cm",
    //                 bottom: "0.8cm",
    //             },
    //         })
    //             .then((group) => {
    //                 return exportPDF(group);
    //             })
    //             .then((dataUri) => {
    //                 //saving certificate only in local machine
    //                 console.log(dataUri)
    //             });
    //     }
    // };
    return (
        <>
            <div className='hoa-certificate'>
                <div className="header-conetent w-50 mx-auto text-center">
                    <span>CertSimple, Inc </span>
                    <span>Phone: 214-812-9428 Fax: 512-777-4928</span>
                    <span>17250 Dallas Pkwy, Dallas,
                        TX 75248 </span>
                    <span>  Email: taxcerts@certsimpleusa.com</span>
                </div>
                {/* //conetent  */}
                <div className="content mt-2">
                    {/* //gf  */}
                    <div className="gf p-1 py-1 cert-border-bottom">
                        <div>
                            <span className='cert-heading'>GF # </span>
                            <span className='cert-desc'>{rawData?.gf_number}</span>
                        </div>
                        <div className="d-flex wrap-heading ">
                            <div className='w-50'>
                                <span className='cert-heading'>TITLE COMPANY<span className='d-inline-block ms-1'>{" : "}</span> </span>
                                <span className='cert-desc'> {rawData?.company}</span>
                            </div>
                            <div className='w-50'>
                                <span className='cert-heading'>CLOSER <span className='d-inline-block ms-1'>{" : "}</span> </span>
                                <span className='cert-desc'> {rawData?.closer}</span>
                            </div>
                        </div>
                        <div className="d-flex wrap-heading pb-4">
                            <div className='w-50'>
                                <span className='cert-heading'>SELLER <span className='d-inline-block ms-1'>{" : "}</span> </span>
                                <span className='cert-desc'> {rawData?.sellar}</span>
                            </div>
                            <div className='w-50'>
                                <span className='cert-heading'>BUYER<span className='d-inline-block ms-1'>{" : "}</span></span>
                                <span className='cert-desc'> {rawData?.buyer}</span>
                            </div>
                        </div>
                    </div>
                    {/* //apraisal  */}
                    <div className='appraisal py-1 pb-3 px-1 cert-border-bottom'>
                        <div className='text-center'>
                            <span className='cert-heading '>GENERAL APPRAISAL INFORMATION - PARCEL 0 OF 1</span>
                        </div>
                        <div>
                            <div className='wrap-heading'>
                                <span className='cert-heading'>Tax Account Number : </span>
                                <span className='cert-desc'>  {rawData?.tax_account_number}</span>
                            </div>
                            <div className='wrap-heading'>
                                <span className='cert-heading'>Property Site Address<span className='d-inline-block ms-1'>{": "}</span></span>
                                <span className='cert-desc'> {rawData?.property_site_adderess}</span>
                            </div>
                            <div className='wrap-heading'>
                                <span className='cert-heading'>Owner Name <span className='d-inline-block'>{" : "}</span></span>
                                <span className='cert-desc'> {rawData?.owner_name}</span>
                            </div>
                            <div className='wrap-heading'>
                                <span className='cert-heading'>Mailing Address<span className='d-inline-block ms-1'>{": "}</span></span>
                                <span className='cert-desc'> {rawData?.mailing_address}</span>
                            </div>
                            <div className='wrap-heading'>
                                <span className='cert-heading'>Legal Description<span className='d-inline-block ms-1'>{": "}</span></span>
                                <span className='cert-desc'>  {rawData?.legal_description}</span>
                            </div>
                            <div className='wrap-heading'>
                                <span className='cert-heading'>Parcel ID<span className='d-inline-block ms-1'>{": "}</span></span>
                                <span className='cert-desc'>  {rawData?.parcel_id}
                                </span>
                            </div>
                        </div>
                    </div>
                    {/* //HOA/COLLECTOR INFORMATION  */}
                    <div className='hoa-collector-information py-1 pb-3 px-1 cert-border-bottom'>
                        <div className='text-center'>
                            <span className='cert-heading '>HOA/COLLECTOR INFORMATION</span>
                        </div>
                        <div>
                            <div className='wrap-heading'>
                                <span className='cert-heading'>HOA : </span>
                                <span className='cert-desc'>  {rawData?.hoa}</span>
                            </div>
                            <div className='wrap-heading'>
                                <span className='cert-heading'>Collector/Management Co : </span>
                                <span className='cert-desc'>  {rawData?.hoa_collector_company}</span>
                            </div>
                            <div className='wrap-heading'>
                                <span className='cert-heading'>Address<span className='d-inline-block ms-1'>{": "}</span></span>
                                <span className='cert-desc'>  {rawData?.hoa_address}</span>
                            </div>
                            <div className='wrap-heading'>
                                <span className='cert-heading'>Contact Name : </span>
                                <span className='cert-desc'>  {rawData?.hoa_contact_name}</span>
                            </div>
                            <div className='wrap-heading'>
                                <span className='cert-heading'>Phone<span className='d-inline-block ms-1'>{": "}</span></span>
                                <span className='cert-desc'>  {mobileFormat(rawData?.hoa_phone)}</span>
                            </div>
                            <div className='wrap-heading'>
                                <span className='cert-heading'>Fax<span className='d-inline-block ms-1'>{": "}</span></span>
                                <span className='cert-desc'>  {rawData?.hoa_fax}
                                </span>
                            </div>
                            <div className='wrap-heading'>
                                <span className='cert-heading'>Email<span className='d-inline-block ms-1'>{": "}</span></span>
                                <span className='cert-desc'> {rawData?.hoa_email}
                                </span>
                            </div>
                        </div>
                    </div>
                    {/* /statement  */}
                    <div className='statement py-1 px-1 cert-border-bottom '>
                        <div className='text-center mb-1'>
                            <span className='cert-heading text-decoration-underline'>Please provide a Statement of Account or please notate the following information:</span>
                        </div>
                        <div>
                            <div className=' d-flex align-items-center'>
                                <span className='cert-heading' style={{ width: 100 }}>Billing Cycle :  </span>
                                <div className='d-flex justify-content-between ' style={{ width: 420 }}>
                                    <span className='cert-desc'>Monthly</span>
                                    <span className='cert-desc'>Quarterly</span>
                                    <span className='cert-desc'>Annually</span>
                                    <span className='cert-desc'>Semi-Annually</span>
                                    <span className='cert-desc'>Annually in Arrears</span>
                                    <span className='cert-desc'>Fiscal Year</span>
                                </div>
                            </div>
                            <div className='my-3 d-flex gap-2'>
                                <span className='cert-heading' >Builder Base Assessment Amount per Cycle : </span>
                                <span className='cert-desc-line' style={{ width: 380 }}></span>
                            </div>
                            <div className='my-3 d-flex gap-2' >
                                <span className='cert-heading'>Home Owner Base Assessment Amount per Cycle : </span>
                                <span className='cert-desc-line' style={{ width: 361 }}></span>
                            </div>
                            <div className='my-3 d-flex gap-2'>
                                <span className='cert-heading'>Transfer Fee : </span>
                                <span className='cert-desc-line' style={{ width: 483 }}></span>
                            </div>
                            <div className='my-3 d-flex gap-2'>
                                <span className='cert-heading'>Refinance Fee (if applicable) : </span>
                                <span className='cert-desc-line' style={{ width: 430 }}></span>
                            </div>
                            <div className='my-3 d-flex gap-2'>
                                <span className='cert-heading'>Resale Certificate Fee : </span>
                                <span className='cert-desc-line' style={{ width: 453 }}></span>
                            </div>
                            <div className='my-3 d-flex gap-2'>
                                <span className='cert-heading'>Special Assessments Due : </span>
                                <span className='cert-desc-line' style={{ width: 441 }}></span>
                            </div>
                            <div className='my-3 d-flex gap-2'>
                                <span className='cert-heading'>Total Amount Due : </span>
                                <span className='cert-desc-line' style={{ width: 463 }}></span>
                            </div>
                        </div>
                    </div>
                    {/* //special comments  */}
                    <div className="special-comments px-1 py-1">
                        <span className='cert-heading text-decoration-underline'>Special Instructions or Comments : </span>
                    </div>
                </div>
            </div >
        </>

    )
}

export default HoaCertificate