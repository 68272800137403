import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json");
export const closer = createApi({
  reducerPath: "closer",
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  endpoints: (builder) => ({
    closerList: builder.query({
      query: (token) => ({
        url: `/closer-list`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ["closer"],
    }),
    editCloserList: builder.query({
      query: (data) => ({
        url: `/edit-closer/${data.id}`,
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    addNewCloser: builder.mutation({
      query: (data) => ({
        url: `/add-closer`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["closer"],
    }),
    updateCloser: builder.mutation({
      query: (data) => ({
        url: `/update-closer/${data.id}`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["closer"],
    }),
  }),
});

export const {
  useCloserListQuery,
  useAddNewCloserMutation,
  useEditCloserListQuery,
  useUpdateCloserMutation,
} = closer;
