import React, { useState } from "react";
import { Radio } from "antd";
import { Row, Col } from "react-bootstrap";
import { Customer } from "./Customer/Customer";
import Branch from "./Branch/Branch";
import { Vendor } from "./Vendor/Vendor";
import { useSelector } from "react-redux";
import { useEffect } from "react";

export const Support = ({ refresh }) => {
  const [key, setKey] = useState("Vendor");
  const OnradiohandleChange = (e) => {
    setKey(e.target.value);
  };

  //redux store
  const { supportTabs } = useSelector((state) => state.flagRoles);

  useEffect(() => {
    setKey(supportTabs[0]);
  }, [supportTabs, refresh]);

  return (
    <Row>
      <Col lg="12">
        <Radio.Group onChange={OnradiohandleChange} value={key}>
          {/* tabs showing base on users roles  */}
          {supportTabs &&
            supportTabs.map((ele) => {
              if (ele !== "Branch") {
                return (
                  <Radio key={ele} value={ele} defaultChecked>
                    {ele}
                  </Radio>
                );
              }
              return null;
            })}
        </Radio.Group>
        {key === "Vendor" && <Vendor refresh={refresh} childTab={key} />}
        {key === "Customer" && <Customer refresh={refresh} childTab={key} />}
        {key === "Branch" && <Branch refresh={refresh} childTab={key} />}
      </Col>
    </Row>
  );
};
