import AppTable from "../../../../Components/AppTable/AppTable";
import { FaSearch, FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
	useSupportVendorListQuery,
	useSupportVendorStatusMutation,
} from "../../../../Redux/Services/supportVendor";
import AppSpinner from "../../../../Components/AppSpinner/AppSpinner";
import { Input, notification, Switch } from "antd";
import { useEffect } from "react";
import SearchColumns from "../../../../Components/SearchColumns";
export const Vendor = ({ childTab }) => {
	const [data, setData] = useState();
	const [search, setSearch] = useState("");
	//set  ascend or descend sorter state
	const [getsortorder, setSortOrder] = useState(false);
	const [getsortfield, setSortField] = useState("");

	//redux data store
	const { token } = useSelector((state) => state.auth);
	const { authColumn } = useSelector((state) => state.flagRoles);

	//calling rtk query hook for geting support-vendor list
	const supportVendor = useSupportVendorListQuery(token);

	//calling rtk query hook for updating Support Vendors status
	const updateSupportVendorStatusApi = useSupportVendorStatusMutation();
	const [updateSupportVendorStatus] = updateSupportVendorStatusApi;

	const sort = (sortOrder, sortfield) => {
		// console.log(sortOrder, sortfield);
		if (sortOrder) {
			setSortOrder(sortOrder);
			setSortField(sortfield);
		} else {
			setSortOrder(false);
		}
	};

	//create function for customer fields
	function matchColumn(value) {
		if (authColumn.indexOf(value) === -1) {
			return false;
		} else {
			return true;
		}
	}

	const columns = [
		{
			title: (
				<span>
					Vendor Name{" "}
					{getsortfield === "name" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "name" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "name" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "name",
			key: "name",
			align: "center",
			width: 200,
			sorter: (a, b) => a.name.localeCompare(b.name),
			...SearchColumns("name", "Vendor Name"),
		},

		{
			title: (
				<span>
					State{" "}
					{getsortfield === "state_name" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "state_name" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "state_name" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "state_name",
			key: "state_name",
			width: 200,
			align: "center",
			sorter: (a, b) => a.state_name.localeCompare(b.state_name),
			...SearchColumns("state_name", "State Name"),
		},
		{
			title: (
				<span>
					County{" "}
					{getsortfield === "county_name" && !getsortorder ? (
						<FaSortAmountUp />
					) : getsortorder === "ascend" && getsortfield === "county_name" ? (
						<span className="sort-svg">
							<FaSortAmountUp className="text-info" />
						</span>
					) : getsortorder === "descend" && getsortfield === "county_name" ? (
						<span className="sort-svg">
							<FaSortAmountDown className="text-info" />
						</span>
					) : (
						<FaSortAmountUp />
					)}
				</span>
			),
			dataIndex: "county_name",
			key: "county_name",
			width: 200,
			align: "center",
			sorter: (a, b) => a.county_name.localeCompare(b.county_name),
			...SearchColumns("county_name", "County Name"),
		},
		{
			title: <span>Active Status</span>,
			className: `${matchColumn("is_active") ? "" : "d-none"}`,
			dataIndex: "is_active",
			key: "is_active",
			align: "center",
			width: matchColumn("is_active") && 50,
			render: (value, row) => (
				<div className="d-flex justify-content-center">
					<Switch
						onClick={(e) => switchHandler(e, row)}
						className="my-switch"
						size="small"
						checked={value}
					/>
				</div>
			),
		},
	];

	useEffect(() => {
		if (supportVendor.isSuccess) {
			setData(supportVendor.data?.data);
			// setColumn(columns);
		}
	}, [supportVendor]);

	//switch handler
	function switchHandler(value, row) {
		const data = {
			is_active: value === true ? 1 : 0,
		};
		updateSupportVendorStatus({
			data,
			id: row.id,
			token: token,
		});
	}
	//update Customers details
	useEffect(() => {
		if (updateSupportVendorStatusApi[1].isSuccess) {
			///after update details
			notification.open({
				type: "success",
				style: { color: "green", marginTop: "50px" },
				description: updateSupportVendorStatusApi[1].data?.message,
			});
		}
		if (updateSupportVendorStatusApi[1].error) {
			notification.open({
				type: "error",
				style: { color: "red", marginTop: "50px" },
				description:
					updateSupportVendorStatusApi[1].error.data.message ||
					updateSupportVendorStatusApi[1].error.error ||
					updateSupportVendorStatusApi[1].error.data?.msg.sqlMessage,
			});
		}
	}, [updateSupportVendorStatusApi[1].isLoading]);

	useEffect(() => {
		setSearch("");
		if (childTab === "Vendor") {
			supportVendor.refetch();
		}
	}, [childTab]);
	return (
		<>
			{supportVendor.isLoading ? (
				<AppSpinner />
			) : (
				<div className="vendor">
					<div className="d-flex align-items-center float-end w-25 mb-1 ">
						{/* changing input values globaly  */}
						<div className=" me-1 w-100 ">
							<Input
								placeholder="Search here"
								className="global-search "
								value={search}
								onChange={(e) => setSearch(e.target.value)}
								prefix={<FaSearch color="lightgray" />}
							/>
						</div>
					</div>
					<div style={{ clear: "both" }}></div>
					<AppTable
						sort={sort}
						loading={updateSupportVendorStatusApi[1].isLoading}
						search={search}
						dataSource={data}
						columns={columns}
					/>
				</div>
			)}
		</>
	);
};
