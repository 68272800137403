import React, { useEffect, useState } from 'react'
import { useGetFeedbackListMutation, useParcelGetFeedbackMutation, useParcelUpdateFeedbackMutation } from '../Redux/Services/Parcels';
import { VscFeedback } from "react-icons/vsc";
import OrderBtns from '../Components/OrderBtns';
import AppModal from '../Components/AppModal/AppModal';
import Loading from '../Components/Loading';
import { Checkbox, Input, List, notification } from 'antd';
import { useSelector } from 'react-redux';
function useSendFeedBack() {
    const [open, setOpen] = useState(false)
    const [checkedList, setCheckedList] = useState([])
    const { token } = useSelector((state) => state.auth);
    //update automation status
    const [feedBackList, { isLoading, data }] =
        useGetFeedbackListMutation();

    const [parcelFeedBackSelected, { isLoading: isLoadingParcelSelected, data: dataParcelSelected }] =
        useParcelGetFeedbackMutation();


    function SendFeedBackComponent({ parcel_id }) {
        async function OpenHandler() {
            try {
                await feedBackList({ token })
                await parcelFeedBackSelected({ token, parcel_id }).then(({ data }) => {
                    if (data?.data) {
                        setCheckedList(data?.data)
                    }
                })

                setOpen(true)

            } catch (error) {

                setOpen(false)
            }
        }
        function onClose() {
            setOpen(false)
            setCheckedList([])
        }
        return (
            <>
                <Loading isLoading={isLoading || isLoadingParcelSelected}>
                    <OrderBtns title="Send Feedback">
                        <div
                            onClick={OpenHandler}
                            className={`border add_icon  border-primary`}
                        >
                            <VscFeedback />
                        </div>

                    </OrderBtns>
                </Loading>
                {open && <AppModal showModal={open} onClose={onClose} element={<FeedBackComp parcel_id={parcel_id} onClose={onClose} checkedList={checkedList} data={data?.data || []} setCheckedList={setCheckedList} />} />}
            </>

        )
    }

    return { SendFeedBackComponent }
}

export default useSendFeedBack



function FeedBackComp({ data = [], checkedList = [], setCheckedList, parcel_id, onClose }) {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState(data);
    const { token } = useSelector((state) => state.auth);
    // const [checkedList, setCheckedList] = useState([]);
    const [checkAll, setCheckAll] = useState(false);
    useEffect(() => {
        setFilteredData(
            data.filter(item =>
                item.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, data]);


    const onCheckAllChange = (e) => {
        const checked = e.target.checked;
        setCheckAll(checked);
        setCheckedList(checked ? filteredData.map(item => item.id) : []);
    };


    const [updateParcelFeedBack, { isLoading }] =
        useParcelUpdateFeedbackMutation();

    function handleSubmit() {
        try {
            updateParcelFeedBack({ token, parcel_id, data: { feedback_ids: checkedList } }).then(({ error }) => {

                if (error) {
                    notification.open({
                        type: "error",
                        style: { color: "red", marginTop: "50px" },
                        description: error?.data?.message || "Not able to update",
                    });
                }
                onClose && onClose()
            })
        } catch (error) {
            console.log(error)
        }

    }

    return <div>
        <div className='d-flex align-items-center gap-2'>
            <h5>Feedback </h5>
            <button disabled={!checkedList.length || isLoading} onClick={handleSubmit} className="btn btn-sm btn-success my-2">{isLoading ? "Loading..." : "Update Feedback"}</button>
        </div>

        <Input
            placeholder="Search"
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ marginBottom: 8 }}

        />
        <div style={{ maxHeight: 200, overflow: "auto" }} className='ps-2'>
            {data?.length > 0 && <Checkbox
                onChange={onCheckAllChange}
                checked={checkAll || (checkedList.length === filteredData.length)}
                indeterminate={checkedList.length > 0 && checkedList.length < filteredData.length}
                style={{ fontSize: "12px", fontWeight: "bolder" }}
            >
                Check all
            </Checkbox>}
            <List
                dataSource={filteredData}
                renderItem={item => (
                    <List.Item>
                        <Checkbox
                            value={item.id}
                            style={{ fontSize: "12px" }}
                            checked={checkedList.includes(item.id)}
                            onChange={(e) => {
                                console.log(item?.id, checkedList)
                                const newList = e.target.checked
                                    ? [...checkedList, item.id]
                                    : checkedList.filter(id => id !== item.id);
                                console.log(newList)
                                setCheckedList([...newList]);
                                setCheckAll(newList.length === filteredData.length);
                            }}
                        >
                            {item.name}
                        </Checkbox>
                    </List.Item>
                )}
            />
        </div>
    </div>
}
