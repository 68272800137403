import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json");
export const thirdParty = createApi({
  reducerPath: "thirdParty",
  tagTypes: ["third_party"],
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  endpoints: (builder) => ({
    thirdPartyList: builder.query({
      query: (token) => ({
        url: `/third-party-document-list`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ["third_party"],
    }),
    // addNewStatus: builder.mutation({
    //   query: (data) => ({
    //     url: `/add-status`,
    //     method: "POST",
    //     mode: "cors",
    //     body: data.data,
    //     headers: { Authorization: `Bearer ${data.token}` },
    //   }),
    //   invalidatesTags: ["status"],
    // }),
  }),
});

export const { useThirdPartyListQuery } = thirdParty;
