import React, { useState } from "react";
import { Alert, Collapse, Popconfirm } from "antd";

import { FaPlus, FaStarOfLife } from "react-icons/fa";
// import "./MultiHoaContact.css";
import {
  CaretRightOutlined,
  EditOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { MdOutlineContactSupport, MdOutlineDelete } from "react-icons/md";
// import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { useEffect } from "react";
import { removeBlank } from "../../../Controllers/Functions";
import AppTable from "../../../Components/AppTable/AppTable";
import AppInput from "../../../Components/AppInput/AppInput";
import AppSelect from "../../../Components/AppSelect/AppSelect";
const randomPass = require("secure-random-password");
const { Panel } = Collapse;
export default function MultiDivisioinHoa({
  hoaListOptions,
  afterClose = false,
  addFormVal = false,
  initailValArr = [],
  updateContact = false,
  deleteContact = false,
  whileEdit = false,
  _id,
}) {
  console.log(initailValArr, hoaListOptions, 30)
  const [active, setActive] = useState("2");
  const contact_methods = hoaListOptions || [];
  const initailVal = {
    hoa_information_id:""
  };
  const [values, setValues] = useState(initailVal);
  // const [,setShowDate] = useState(true);
  const [data, setData] = useState(initailValArr);
  const [addRowToggler, setAddRowToggler] = useState(false);
  console.log(data, 39)
  const [editId, setEditId] = useState(null);
  const [getErr, setGetErr] = useState(false);

  const {hoa_information_id} = values;

  //edit row
  const editHandler = (record) => {
    setEditId(record);
    setAddRowToggler(true);
    setValues(record);
  };

  const columns = [
    {
        title: <span>HOA Information</span>,
        dataIndex: "hoa_information_id",
        key: "hoa_information_id",
        align: "left",
        render:(value)=>{
          return contact_methods?.find(object=> object.id == value)?.name;
        }
    },
    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      align: "center",
      width: 130,
      render: (_, record) => {
        return (
          <>
            <span>
              <EditOutlined
                style={{ cursor: "pointer" }}
                onClick={() => editHandler(record)}
                size={50}
              />
            </span>

            <Popconfirm
              getPopupContainer={(trigger) => trigger.parentElement}
              title="Are you sure you want to delete？"
              onConfirm={() => {
                deleteHandler(record);
              }}
              placement="topLeft"
              icon={
                <QuestionCircleOutlined
                  style={{
                    color: "red",
                  }}
                />
              }
              okText="Yes"
              cancelText="No"
            >
              <span className={"ms-2 "}>
                <MdOutlineDelete style={{ cursor: "pointer" }} size={15} />
              </span>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const inputs = [
      {
        id: 4547357,
        name: "hoa_information_id",
        _id: "hoa_information_id",
        type: "select",
        value: hoa_information_id || "",
        options: contact_methods,
        errorMessage: "Please enter email!",
      },
   
  ];

  const onChange = (e, date, dateFormat) => {
      setGetErr(false)
      const { name, value } = e.target;
      setValues({
        ...values,
        [name]: value,
      })
  };

  //sending request

  const handleSubmit = () => {
    if (editId) {
      const newData = data.filter((ele) => {
        return (ele.temp_id || ele.id) !== (editId.temp_id || editId.id);
      });

      setData([...newData, { ...values, name:values?.name, phone:values?.phone, email:values?.email, contact_method_id:values?.contact_method_id}]);
      updateContact && updateContact({ ...values, name:values?.name, phone:values?.phone, email:values?.email, contact_method_id:values?.contact_method_id });
      setAddRowToggler(false);
      setEditId(null);
      // setValues(initailVal);
    } else {
      //remove blank values
      removeBlank(values);
      const randomId = randomPass.randomPassword({
        length: 9,
        characters: randomPass.digits,
      });
      setData([
        ...data,
        {
          ...values,
          hoa_information_id:values?.hoa_information_id,
          temp_id: randomId
        },
      ]);
      //while edit and ading new record directly
      whileEdit({ ...values, 
		 hoa_information_id:values?.hoa_information_id,
         temp_id: randomId });
      setAddRowToggler(false);
    }
  };

  const cancelHandler = () => {
    setValues(initailVal);
    addFormVal(data);
    setAddRowToggler(false);
  };

  const addNewRecord = () => {
    setAddRowToggler(!addRowToggler);
    setValues({ ...initailVal})
  };

  const deleteHandler = (id) => {
    setData(
      data.filter((ele) => {
        return (ele?.id || ele?.temp_id) !== (id?.id || id?.temp_id);
      })
    );
    //sending delete id
    deleteContact(id);
  };

  useEffect(() => {
    setAddRowToggler(false);
    setData(initailValArr);
    setValues(initailVal);
    setEditId(null);
    setActive("2");
  }, [afterClose, initailValArr]);

  useEffect(() => {
    addFormVal && addFormVal(data);
  }, [data]);

  const selectHandler = (property, value, arrtibute) => {
		setValues({
			...values,
			hoa_information_name: value,
			hoa_information_id: arrtibute?.getAttribute("data_name"),
		});
	};

  return (
    <Collapse
      className="m-0 py-0 gray-table site-collapse-custom-collapse"
      expandIcon={({ isActive }) => (
        <CaretRightOutlined
          style={{ fontSize: 14 }}
          className="mt-1"
          rotate={isActive ? 90 : 0}
        />
      )}
      expandIconPosition={"end"}
      activeKey={active}
      accordion
      onChange={(value) => {
        setActive(value);
      }}
    >
      <Panel
        className="my-1 border-0 site-collapse-custom-panel "
        header={
          <h6 className=" mb-0 fw-bold me-2 d-flex align-items-center gap-1">
             <span>HOA</span>
          </h6>
        }
        key="1"
      >
        {active === "1" && (
          <div className="gray-table-col  mb-2 p-2" id={_id}>
            <div
              className={`d-flex ${"justify-conten"} align-items-center my-2 contact-forms`}
            >
              {!addRowToggler && (
                <div className={`add_icon ms-auto`} onClick={addNewRecord}>
                  <FaPlus />
                </div>
              )}
            </div>

            <AppTable pagination={false} dataSource={data?.map(object=>({...object, hoa_information_id:object?.hoa_information_id}))} columns={columns} />

            {getErr && (
              <div className="mt-3">
                <Alert
                  message={getErr}
                  className="py-1 mx-3"
                  type="error"
                  showIcon
                />{" "}
              </div>
            )}

            {addRowToggler && (
              <div className="row bg-white p-2 mx-auto shadow-sm" id="addRow">
                {inputs.map((input) => (
                  <>
                    {input.type === "select" && (
                      <AppSelect
                        defaultSet={afterClose}
                        key={input.id}
                        {...input}
                        value={values[input.name]}
                        onChange={selectHandler}
                        options={
                          <>
                            <option value={""}>Select hoa Information</option>
                            {input.options.map((ele) => {
                              return (
                                <option
                                  key={ele.id}
                                  data_name={ele.id}
                                  value={ele.id}
                                >
                                  {ele.name}
                                </option>
                              );
                            })}
                          </>
                        }
                      />
                    )}
                  </>
                ))}
                <div className="col-12 col-lg-1 mt-3 p-0">
                  <div className="d-flex">
                    <span
                      onClick={handleSubmit}
                      className={`btn btn-primary  btn-sm edit-buttons ${
                        !hoa_information_id? "disabled" : "btn-success"
                      } `}
                    >
                      {editId?.id ? "Update" : "Save"}
                    </span>
                    <span
                      onClick={cancelHandler}
                      className="btn btn-primary ms-1 btn-sm edit-buttons"
                    >
                      Cancel
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </Panel>
    </Collapse>
  );
}
