import React, { useState } from "react";
import { Tabs } from "antd";
import { Support } from "./Support/Support";
import { useSelector } from "react-redux";
import { Type } from "./Type/Type";
import Vendors from "./Customers/Vendor/Vendor";
import { Customer } from "./Customers/Customer/Customer";
import Branch from "./Customers/Branch/Branch";
import User from "./Customers/User/User";
import { useEffect } from "react";

const { TabPane } = Tabs;

export const SettingsTab = () => {
  const [key, setKey] = useState("customers");
  //redux store for labels
  const { labelList } = useSelector((state) => state.labelList);

  //redux store using

  const { settingsTabs } = useSelector((state) => state.flagRoles);
  //create function for settings tabs
  function matchTab(value) {
    if (settingsTabs.indexOf(value) === -1) {
      return false;
    } else {
      return true;
    }
  }
  useEffect(() => {
    setKey(settingsTabs[0]);
  }, [settingsTabs]);

  return (
    <>
      <Tabs
        activeKey={key}
        className="title-container app-tab"
        defaultActiveKey="1"
        onChange={(k) => {
          setKey(k);
        }}
      >
        {matchTab("vendors") && (
          <TabPane tab="Vendors" key="vendors">
            <Vendors refresh={key} />{" "}
          </TabPane>
        )}

        {matchTab("customers") && (
          <TabPane tab="Customers" key="customers">
            {" "}
            <Customer refresh={key} />{" "}
          </TabPane>
        )}

        {matchTab("branches") && (
          <TabPane tab="Branches" key="branches">
            {" "}
            <Branch refresh={key} />{" "}
          </TabPane>
        )}

        {matchTab("users") && (
          <TabPane tab="Users" key="users">
            <User refresh={key} />{" "}
          </TabPane>
        )}

        {
          //matching base on roles settingsTab Flag
          matchTab("support") && (
            <TabPane tab="Support" key="support">
              <Support refresh={key} />
            </TabPane>
          )
        }

        {
          //matching base on roles settingsTab Flag
          matchTab("type") && (
            <TabPane tab={labelList["cs.settings.menu.type"]} key="type">
              <Type refresh={key} />{" "}
            </TabPane>
          )
        }
      </Tabs>
    </>
  );
};

export default SettingsTab;
