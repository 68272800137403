import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json");
export const exemption = createApi({
  reducerPath: "exemption",
  tagTypes: ["exemption"],
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  endpoints: (builder) => ({
    exemptionList: builder.query({
      query: (token) => ({
        url: `/exemption-list`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ["exemption"],
    }),
    addNewExemption: builder.mutation({
      query: (data) => ({
        url: `/add-exemption`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["exemption"],
    }),
    editExemption: builder.query({
      query: (data) => ({
        url: `/edit-exemption/${data.id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["exemption"],
    }),
    updateExemption: builder.mutation({
      query: (data) => ({
        url: `/update-exemption/${data.id}`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["exemption"],
    }),
    showExemptionList: builder.mutation({
      query: (data) => ({
        url: `/exemption-mapping-list`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    })
  }),
});

export const {
useExemptionListQuery,
useEditExemptionQuery,
useAddNewExemptionMutation,
useUpdateExemptionMutation,
useShowExemptionListMutation
} = exemption;
