import React from 'react';
import { Bar } from '@ant-design/plots';

const StackedBarChart = (props) => {
    const {
       data=[],
        isStack=true,
        xField,
        yField,
        seriesField,
        colorField,
    }=props;

 
  const config = {
    data: data.reverse(),
    isStack: isStack,
    xField: xField,
    yField: yField,
    legend: {
      layout: "horizontal",
      position: "top-left",
    },
 
    seriesField: seriesField,
    padding:"15",
    height:420,
    width:420,
    appendPadding:10,
    barWidthRation:{
      Range:[0-1]
    },
    autoFit:true,
    colorField:colorField,
    color: ['#d1d107c5', '#222253'],
    label: {
      
      // content: ({ content }) => { return content },
      
      position: 'middle',
      layout: [
        {
          type: 'interval-adjust-position',
        }, 
        {
          type: 'interval-hide-overlap',
        }, 
        {
          type: 'adjust-color',
        },
      ],
    },
  };
  return <Bar {...config}{...props} />;
};

export default StackedBarChart;
