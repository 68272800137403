import { createSlice } from "@reduxjs/toolkit";

const cadList = createSlice({
  name: "cadList",
  initialState: {
    cadList: [],
  },
  reducers: {
      cadListAction: (state, action) => {
      state.cadList = action.payload;
    },
  },
});

export const {cadListAction} = cadList.actions;

export default cadList.reducer;