import { createSlice } from "@reduxjs/toolkit";

const stateCountyReducer = createSlice({
  name: "productTypeList",
  initialState: {
    stateCountyList: [],
  },
  reducers: {
    stateCountyAction: (state, action) => {
      state.stateCountyList = action.payload;
    },
  },
});

export const {stateCountyAction} = stateCountyReducer.actions;

export default stateCountyReducer.reducer;