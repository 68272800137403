import React from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import "@progress/kendo-theme-default/dist/all.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Theme/DarkMode.css";
import { useSelector } from "react-redux";
import Login from "./View/Login/Login";
import MyDashBoard from "./View/Dashboard/MyDashBoard";
import ForgotPassword from "./View/ForgetPassword/ForgotPassword";
import Settings from "./View/Settings/Settings";
import Tools from "./View/Tools/Tools";
import Reports from "./View/Reports/Reports";
import OrderEditor from "./View/OrderEditor/mainOrder/OrderEditor";
import OrderWizard from "./View/OrderWizard/OrderWizard";
import ResetPassword from "./View/ResetPassword/ResetPassword";
import Helmet from "react-helmet";
import Aside from "./Layouts/Aside/Aside";
import SessionExpire from "./View/SessionExpire/SessionExpire";
import Logout from "./View/Logout/Logout";
import PagenotFound from "./View/PagenotFound/PagenotFound";
// import Notice from "./View/Notice/Notice";

import { createContext } from "react";
import { useState } from "react";
import AppSpinner from "./Components/AppSpinner/AppSpinner";

import Testing from "./Testing/Testing";
import Notice from "./View/Notice/Notice";
import NoticeNotification from "./View/Notification/NoticeNotification";
import HoaCertificate from "./View/OrderEditor/mainOrder/Parcels/MyHOA/HoaCertificate/HoaCertificate";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import InternalOrdersCount from "./View/InternalOrdersCount/internalOrders";
import OrderStatus from "./View/OrderEditor/orderDashboard/OrderStatus";
export const GlobalContext = createContext();
const queryClient = new QueryClient();

export default function App() {
  const { token, user } = useSelector((state) => state.auth);
  const [globalVal, setGlobalVal] = useState({
    loader: false,
    orderSearch: "",
    hoaOrdersListToggle: false,
    orderAllStatusToggle: false,
    ordedashboardProductTypeId: "",
    orderCertificate: false,
    orderListStatus: [],
    is_intergration: 0,
  });
  const { dark } = useSelector((state) => state.theme);
  const { userRoutes } = useSelector((state) => state.flagRoles);

  //creating function for user roles routes
  function matchPath(path) {
    if (userRoutes.indexOf(path) === -1) {
      return false;
    } else {
      return true;
    }
  }

  //progress initial state automation
  // const progressData = {
  //   loading: false,
  //   isFecthParcel: false,
  //   isFetchingSupport: false,
  //   isFetchingSupportError: false,
  //   isCertificate: false,
  //   currentTask: "",
  // };

  // const reducer = (state, action) => {
  //   switch (action.type) {
  //     case "is":
  //       return {
  //         ...state,
  //         currentTask:"login"
  //       }
  //     default:
  //       return state;
  //   }
  // };

  // const [parcelAutomation, dispatch] = useReducer(reducer, progressData);

  return (
    <GlobalContext.Provider value={{ state: [globalVal, setGlobalVal] }}>
      {globalVal.loader && <AppSpinner position="fixed" />}
      <QueryClientProvider client={queryClient}>
        <div>
          <Helmet>
            <body className={` ${dark ? "dark" : ""}-theme view`} />
          </Helmet>
          <Router>
            {/* <AutoLogout minutes={1} /> */}
            <Routes>
              <Route path="/session-expired" element={<SessionExpire />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/test" element={<Testing />} />
              <Route path="/hoa-certificate" element={<HoaCertificate />} />
              <Route
                path="/internal-orders"
                element={<InternalOrdersCount />}
              />
              <Route
                path="/login"
                element={
                  token && user.first_time_login === 0 ? (
                    <Navigate
                      to={(user?.user_type_id === 15 || user?.user_type_id == 5 || user?.user_type_id == 20) ? "order" : "/dashboard"}
                    />
                  ) : (
                    <Login />
                  )
                }
              />
              {/* //page not found  */}
              <Route path="*" element={<PagenotFound />} />
              <Route
                path="/"
                element={
                  token && user.first_time_login === 0 ? (
                    <Navigate
                      to={
                        (user?.user_type_id === 15 || user?.user_type_id == 5 || user?.user_type_id == 20)
                          ? "order"
                          : "/dashboard"
                      }
                    />
                  ) : (
                    <div
                      className="login"
                      style={{ background: "url(../Assets/login/back.png)" }}
                    >
                      {" "}
                      <NoticeNotification /> <br /> <Login />{" "}
                    </div>
                  )
                }
              />
              <Route
                path="/"
                element={
                  <Navigate
                    to={
                      (user?.user_type_id === 15 || user?.user_type_id == 5 || user?.user_type_id == 20)
                        ? "order"
                        : "/dashboard"
                    }
                  />
                }
              />
              <Route
                path="/forgot-password"
                element={
                  token && user.first_time_login === 0 ? (
                    <Navigate
                      to={user?.user_type_id === 15 ? "order" : "/dashboard"}
                    />
                  ) : (
                    <ForgotPassword />
                  )
                }
              />
              <Route
                path="/resetpassword/:reset_token/:user_id"
                element={<ResetPassword />}
              />

              <Route
                path="/first-time-login"
                element={
                  //firstTime
                  token && user.first_time_login === 1 ? (
                    <ResetPassword />
                  ) : (
                    <Navigate
                      to={(user?.user_type_id === 15 || user?.user_type_id == 5 || user?.user_type_id == 20) ? "order" : "/dashboard"}
                    />
                  )
                }
              />
              <Route
                path="/"
                element={
                  token && user.first_time_login === 0 ? (
                    <Aside />
                  ) : (
                    <Navigate
                      to={`${!token ? "/login" : "/first-time-login"}`}
                    />
                  )
                }
              >
                <Route
                  path={"dashboard"}
                  element={
                    matchPath("dashboard") ? <MyDashBoard /> : <>&nbsp;</>
                  }
                />

                <Route path="order" element={<OrderEditor />} />
                <Route path="order-editor" element={<OrderEditor />} />
                <Route path="order-editor/:id" element={<OrderEditor />} />
                <Route path="order-status" element={<OrderStatus />} />
                <Route
                  path="settings"
                  element={matchPath("settings") ? <Settings /> : <>&nbsp;</>}
                />
                <Route
                  path="tools"
                  element={matchPath("tools") ? <Tools /> : <>&nbsp;</>}
                />
                <Route
                  path="reports"
                  element={matchPath("reports") ? <Reports /> : <>&nbsp;</>}
                />
                <Route
                  path="notice"
                  element={matchPath("notice") ? <Notice /> : <>&nbsp;</>}
                />
                <Route
                  path="order-wizard"
                  element={
                    matchPath("order-wizard") ? <OrderWizard /> : <>&nbsp;</>
                  }
                />
              </Route>
            </Routes>
          </Router>
        </div>
      </QueryClientProvider>
    </GlobalContext.Provider>
  );
}
