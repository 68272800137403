import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json");
export const taxcollector = createApi({
  reducerPath: "taxcollector",
  tagTypes: ["taxcollector"],
  baseQuery: fetchBaseQuery({ baseUrl: config.url }),
  endpoints: (builder) => ({
    taxCollectorList: builder.query({
      query: (token) => ({
        url: `/tax-collector-list`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ["taxcollector"],
    }),
    editTaxCollector: builder.query({
      query: (data) => ({
        url: `/edit-tax-collector/${data.id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    editTaxCollectorPost: builder.mutation({
      query: (data) => ({
        url: `/edit-tax-collector/${data.id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    updateTaxCollector: builder.mutation({
      query: (data) => ({
        url: `/update-tax-collector/${data.id}`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["taxcollector"],
    }),
    updateTaxCollectorStatus: builder.mutation({
      query: (data) => ({
        url: `/update-tax-collector-status/${data.id}`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["taxcollector"],
    }),
    addTaxCollector: builder.mutation({
      query: (data) => ({
        url: `/add-tax-collector`,
        method: "POST",
        mode: "cors",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
      invalidatesTags: ["taxcollector"],
    }),
    searchTaxCollector: builder.mutation({
      query: (data) => ({
        url: `/search-tax-colector-list`,
        method: "POST",
        mode: "cors",
        body: data.value,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    autoUpdateDetailsTaxCollector: builder.mutation({
      query: (data) => ({
        url: `/auto-update-tax-collector-details/${data.id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),

    taxListSelect: builder.mutation({
      query: (token) => ({
        url: `/tax-list`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    taxReadOnlyList: builder.query({
      query: (token) => ({
        url: `/tax-collector-read-only-comment-list`,
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    taxReadOnlyListPost: builder.mutation({
      query: (token) => ({
        url: `/tax-collector-read-only-comment-list`,
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    taxDisplayCertList: builder.query({
      query: (token) => ({
        url: `/tax-collector-display-cert-comment-list`,
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    taxDisplayCertListPost: builder.mutation({
      query: (token) => ({
        url: `/tax-collector-display-cert-comment-list`,
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    taxCollectorTableFilterList: builder.mutation({
      query: (data) => ({
        url: `/tax-collector-list-based-state-county`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    taxCollectorThirdPartFlag: builder.mutation({
      query: (data) => ({
        url: `/tax-collector-third-party-flag/${data.id}`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    //automation status check token
    automationToken: builder.mutation({
      query: (token) => ({
        url: `/active-token`,
        mode: "cors",
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    //update automation status
    automationStatusUpdate: builder.mutation({
      query: (data) => ({
        url: `/update-parcel-automation/${data.id}`,
        mode: "cors",
        method: "POST",
        body: data.data,
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    testOrderDetails: builder.mutation({
      query: (data) => ({
        url: `/get-order-status/${data.data.order_id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    updateLatestData: builder.mutation({
      query: (data) => ({
        url: `/update-parcel-tax-year/${data.id}`,
        method: "POST",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    taxCollectorListBaseOnCounty: builder.mutation({
      query: (data) => ({
        url: `/taxcollector-list/${data.county_id}`,
        method: "GET",
        mode: "cors",
        headers: { Authorization: `Bearer ${data.token}` },
      }),
    }),
    taxCollectorDelete: builder.mutation({
      query: ({ colloctorId, token }) => ({
        url: `/delete-parcel-tax-information/${colloctorId}`,
        method: "POST",
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
    updateJuriZero: builder.mutation({
      query: ({ parcelId, token }) => ({
        url: `/update-jurisdiction-zero/${parcelId}`,
        method: "POST",
        mode: "cors",
        headers: { Authorization: `Bearer ${token}` },
      }),
    }),
  }),
});

export const {
  useAddTaxCollectorMutation,
  useEditTaxCollectorQuery,
  useTaxCollectorListQuery,
  useUpdateTaxCollectorMutation,
  useUpdateTaxCollectorStatusMutation,
  useSearchTaxCollectorMutation,
  useAutoUpdateDetailsTaxCollectorMutation,
  useTaxListSelectMutation,
  useTaxDisplayCertListQuery,
  useTaxReadOnlyListQuery,
  useTaxCollectorTableFilterListMutation,
  useTaxCollectorThirdPartFlagMutation,
  useEditTaxCollectorPostMutation,
  useAutomationTokenMutation,
  useAutomationStatusUpdateMutation,
  useTestOrderDetailsMutation,
  useTaxCollectorListBaseOnCountyMutation,
  useUpdateLatestDataMutation,
  useTaxCollectorDeleteMutation,
  useUpdateJuriZeroMutation,
  useTaxDisplayCertListPostMutation,
  useTaxReadOnlyListPostMutation
} = taxcollector;
