import { Alert } from "antd";
import React, { memo, useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import AppButton from "../../../Components/AppButton/AppButton";
import AppInput from "../../../Components/AppInput/AppInput";
import { useOrderCloneMutation } from "../../../Redux/Services/Orders";

export default memo(function CloneOrder({
  id,
  refetchOrder,
  afterClose,
  closeModal,

  initialVals,
}) {
  const [orderClone, orderCloneResult] = useOrderCloneMutation();
  const [values, setValues] = useState("");
  const { user, token } = useSelector((state) => state.auth);
  const [getError, setGetError] = useState(false);

  //useEffect
  useEffect(() => {
    if (orderCloneResult.isSuccess) {
      refetchOrder && refetchOrder()
      closeModal(false);
    }
    if (orderCloneResult.isError) {
      setGetError(
        orderCloneResult.error?.data?.message ||
        orderCloneResult.error.error ||
        orderCloneResult.error.data?.msg.sqlMessage
      );
    }
  }, [orderCloneResult.isLoading]);

  //function clone order
  const handleSubmit = (e) => {
    e.preventDefault();
    //clone Order
    orderClone({
      id,
      data: { opened_by: user.user_id, file_number: values },
      token,
    });
  };
  //chnage values
  const onChange = (e) => {
    const { value } = e.target;
    setValues(value.replace(/\s/g, ""));
  };

  useEffect(() => {
    setValues(initialVals + "-1");
    setGetError();
  }, [afterClose]);

  return (
    <div>
      <h6>Order Clone</h6>

      <form onSubmit={handleSubmit}>
        <div className="row">
          {getError && <Alert message={getError} type="error" showIcon />}
          <div className="col-12 mt-1">
            <AppInput
              defaultSet={afterClose}
              value={values}
              onChange={onChange}
              label="File Name"
              required
              errorMessage="file name is required!"
              placeholder="Enter File Name"
            />
          </div>
          <div className="col-12 col-md-6 mx-auto  mt-2">
            <AppButton
              isLoading={orderCloneResult.isLoading}
              type="submit"
              variant={"primary-md"}
              title="ClONE ORDER"
            />
          </div>
        </div>
      </form>
    </div>
  );
});
