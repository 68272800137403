import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const config = require("../../config.json");
export const InternalOrdersList = createApi({
	reducerPath: "internal",
	tagTypes: ["internal"],
	baseQuery: fetchBaseQuery({ baseUrl: config.url }),
	endpoints: (builder) => ({
		  automationCountList: builder.mutation({
			query: (data) => ({
			  url: `/automation-count`,
			  mode: "cors",
			  method: "POST",
			  body: data.data,
			  headers: { Authorization: `Bearer ${data.token}` },
			}),
			invalidatesTags: ["internal"],
		  }),
          tenDaysAtomationCountList: builder.query({
            query: (token) => ({
              url: `/ten-days-automation-count-list`,
              method: "GET",
              mode: "cors",
              headers: { Authorization: `Bearer ${token}` },
            }),
             invalidatesTags: ["internal"]
          }),
	}),
 
});

export const {
useAutomationCountListMutation,
useTenDaysAtomationCountListQuery
} = InternalOrdersList;